import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { NotificationTray } from "../../../components/notifcationtray/NotificationTray";
import { IAppState } from "../../../store";
import { userLogout } from "../../../store/login/actions";
import firebase from "firebase";
import configs from "../../configs/apiConfigs";
import { CHAT } from "../../../constants";
import moment from "moment";
import $ from "jquery";
import { DialIcon } from "../dial/DialIcon";
import { PatientReports } from "../patientreports";
import Logo from "../../assets/images/logo.svg";

let unsubscribe: any = null;
export const Header = () => {
  const [showPatientReports, setShowPatientReports] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  var searchplaceholder: any;
  const userData: any = useSelector(
    (state: IAppState) => state.userData.userDataResponse,
    shallowEqual
  );
  const { register, handleSubmit, watch, setValue } = useForm();
  const logout = () => {
    dispatch(userLogout());
    history.replace("/login");
  };
  const onSubmit = (data: any) => {
    if (
      location.pathname === "/message-center/campaigns/patients" ||
      location.pathname === "/message-center/notification/patients" ||
      location.pathname === "/message-center/administrative/patients" ||
      location.pathname === "/message-center/payment/patients" ||
      location.pathname === "/message-center/followup/patients"
    ) {
      history.push(
        location.pathname + "?q=" + encodeURIComponent(data.patient)
      );
    } else {
      history.push("/patient?q=" + encodeURIComponent(data.patient));
    }
  };

  if (
    location.pathname === "/message-center/campaigns/patients" ||
    location.pathname === "/message-center/notification/patients" ||
    location.pathname === "/message-center/administrative/patients" ||
    location.pathname === "/message-center/payment/patients" ||
    location.pathname === "/message-center/followup/patients"
  ) {
    searchplaceholder = "Search with name";
  } else {
    searchplaceholder = "Search with name, email, dob(MM-DD-YYYY) and mobile#";
  }
  useEffect(() => {
    if (location && location.search) {
      setValue("patient", new URLSearchParams(location.search).get("q"));
    }
  }, [location]);

  const [notifications, setNotifications]: any = useState([]);
  const notificationRef = useRef([]);
  const setMyNotificationRef = (data: any) => {
    notificationRef.current = data;
    setNotifications(data);
  };
  useEffect(() => {
    if (userData) {
      unsubscribe = firebase
        .firestore()
        .collection(configs.CHAT_COLLECTION)
        .where("sendToCId", "==", userData.clinics[0]?.uniqueId)
        .where("actionType", "in", [
          CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT,
          CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT,
          CHAT.ACTION_TYPE.SCHEDULE_REQUEST,
          CHAT.ACTION_TYPE.RESCHEDULE_REQUEST,
        ])
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (snapshot) => {
            let docs = snapshot.docs.map((doc) => {
              return { id: doc.id, ...doc.data() };
            });
            setMyNotificationRef(
              docs
                .map((doc: any) => ({
                  ...doc,
                  time: doc.createdAt
                    ? moment(moment.unix(doc?.createdAt?.seconds)).format(
                        "MM-DD-YYYY LT"
                      )
                    : undefined,
                }))
                .filter((doc: any) => {
                  if (doc.metadata && doc.metadata.markReadByClinicAt) {
                    return false;
                  }
                  if (
                    (doc.addedFromMessageCenter ||
                      doc.addedFromProvider ||
                      doc.addedFromPatient) &&
                    doc.time
                  ) {
                    return true;
                  }
                  return false;
                })
            );
          },
          (error: any) => {
            console.log(error);
          }
        );
    }
  }, [userData]);
  useEffect(() => {
    $(".hmbg").on("click", function () {
      $(".main-sidebar").toggleClass("shrink");
      $(".main-middle-sec").toggleClass("shrink");
    });

    return () => {
      unsubscribe && unsubscribe();
    };
  }, []);
  return (
    <>
      <header className="main-header d-flex flex-column flex-md-row  align-items-center shadow-sm">
        <div className="me-auto">
          <a>
            <img src={Logo} />
          </a>
          <button type="button" className="btn ms-4 btn-sm hmbg">
            <i className="bi bi-list fs-5"></i>
          </button>
        </div>
        {/* {userData?.role !== "agency" && ( */}
        <button
          className="btn btn-link"
          onClick={() => {
            setShowPatientReports(true);
          }}
        >
          Patient Reports
        </button>
        {/* )} */}
        {userData?.role !== "agency" && <DialIcon />}
        {showPatientReports && (
          <PatientReports
            onHide={() => {
              setShowPatientReports(false);
            }}
          />
        )}
        {userData &&
          userData.accessEnabled &&
          userData.accessEnabled.patient_search &&
          userData.accessEnabled.patient_search.patient_search &&
          userData?.role !== "agency" &&
          !(
            location.pathname === "/message-center/campaigns/patients" ||
            location.pathname === "/message-center/notification/patients" ||
            location.pathname === "/message-center/payment/patients" ||
            location.pathname === "/message-center/followup/patients"
          ) && (
            <div className="ms-3 globe-search">
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  className="form-control"
                  style={{ width: "400px" }}
                  placeholder={searchplaceholder}
                  name="patient"
                  ref={register}
                />
                <button className="btn" type="submit">
                  <i className="bi bi-search"></i>
                </button>
              </form>
            </div>
          )}
        {(location.pathname === "/message-center/campaigns/patients" ||
          location.pathname === "/message-center/notification/patients" ||
          location.pathname === "/message-center/payment/patients" ||
          location.pathname === "/message-center/followup/patients") && (
          // userData?.role !== "agency" &&
          <div className="ms-3 globe-search">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                className="form-control"
                style={{ width: "400px" }}
                placeholder={searchplaceholder}
                name="patient"
                ref={register}
              />
              <button className="btn" type="submit">
                <i className="bi bi-search"></i>
              </button>
            </form>
          </div>
        )}
        {userData?.role !== "agency" && (
          <button
            className="text-black btn btn-warning ms-4"
            onClick={(e) => {
              history.push("/subscription/upgrade/plan");
            }}
          >
            Manage Plan
          </button>
        )}
        {userData?.role !== "agency" && (
          <div className="dropdown dropdown-animated scale-left">
            <button
              type="button"
              data-toggle="dropdown"
              className="btn btn-light btn-circle position-relative ms-4"
            >
              <span>
                <i className="bi bi-bell-fill"></i>
              </span>
              {notificationRef.current.length > 0 && (
                <span className="badge rounded-pill bg-danger badge-floating border-white">
                  {notificationRef.current.length}
                </span>
              )}
            </button>
            <NotificationTray
              notifications={notificationRef.current}
            ></NotificationTray>
          </div>
        )}
        <div className="dropdown">
          <div
            className="profile-header-box d-flex align-items-center dropdown-toggle ms-4"
            id="admindrop"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {/* <div className="img-box-header overflow-hidden rounded-circle me-2">
                        <img src="images/walter-heidary.jpg" className="img-fluid avatar rounded-circle" />
                    </div> */}
            <span className="avatar flex-shrink-0 avatar-48 img-circle me-2">
              {userData?.firstName?.charAt(0)}
              {userData?.lastName?.charAt(0)}
            </span>
            <div className="txt-header-box">
              <div className="fw-medium">
                {userData.firstName + " " + userData.lastName}
              </div>
              {userData.userAccess === "1" && (
                <span className="text-muted" style={{ fontSize: "12px" }}>
                  Admin
                </span>
              )}
            </div>
          </div>
          <ul className="dropdown-menu" aria-labelledby="admindrop">
            {userData?.role !== "agency" && (
              <li>
                <NavLink className="dropdown-item" to="/user/account">
                  Edit profile
                </NavLink>
              </li>
            )}
            {/* <li><NavLink className="dropdown-item" to="/user/account">My account</NavLink></li> */}
            <li>
              <NavLink className="dropdown-item" to="/login" onClick={logout}>
                Logout
              </NavLink>
            </li>
          </ul>
          <div></div>
        </div>
      </header>
    </>
  );
};
