import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { createStep, getSpeciality } from '../../services/clinicOnboard';
export function PracticeApp8(props: any) {
	const [speciality, setSpeciality]: any = useState([]);
	const dispatch = useDispatch();
	const onNext = () => {
		const request = {
			specialities: speciality.filter((item: any) => item.isChecked)
		};
		const requestData = {
			speciality: speciality.filter((item: any) => item.isChecked).map((item: any) => item.id).toString(),
			uniqueid: props.clinicUniqueId
		};
		dispatch(fetchLoadingPending());
		createStep({ stepNumber: 6, formData: requestData }).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				props.onNext(6, { specialities: request });
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});
	}
	useEffect(() => {
		const domainId = props.practiceType ? props.practiceType.id : 1;
		dispatch(fetchLoadingPending());
		getSpeciality(domainId).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				if (props.data && props.data.specialities && Array.isArray(props.data.specialities)) {
					setSpeciality(success.specialities.map((item: any) => {
						if (props.data.specialities.find((sItem: any) => item.id === sItem.id)) {
							return { ...item, isChecked: true };
						}
						return { ...item, isChecked: false };
					}));
				} else {
					setSpeciality(success.specialities.map((item: any) => ({ ...item, isChecked: false })));
				}
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		})
	}, []);
	useEffect(() => {
		if (props.data) {
			setSpeciality(speciality.map((item: any) => {
				if (props.data.specialities.find((sItem: any) => sItem.id === item.id)) {
					return {
						...item,
						isChecked: true
					}
				}
				return item;
			}))
		}
	}, [props.data]);
	return (
		<>
			<div className="row justify-content-center">
				<div className="col-12">
					<h2 className="mb-3">Add speciality</h2>
					<div className="fw-medium fs-5">Choose Your Speciality.</div>
					{/* <p className="mt-1">Please set the office hours to let patients know when you're open</p> */}
					<hr className="my-4" />
				</div>
			</div>
			<div className="col-12 mb-5">
				<div className="fw-medium mb-3">Practice speciality</div>
				<div className="features-check-list custom-check-list d-flex flex-wrap">
					{
						speciality.map((item: any, index: number) => {
							return (
								<div className="form-check" key={item.id + item.name}>
									<input className="form-check-input" type="checkbox" value={item.id} id={"speciality" + item.id} checked={item.isChecked} onChange={(e) => {
										setSpeciality(speciality.map((sItem: any, sIndex: number) => {
											if (sIndex === index) {
												return {
													...sItem,
													isChecked: !item.isChecked
												}
											}
											return sItem;
										}));
									}} />
									<label className="form-check-label rounded-2 border w-100" htmlFor={"speciality" + item.id}>
										{item.name}
									</label>
								</div>
							)
						})
					}
				</div>
			</div>

			<div className="col-12 my-4">
				<div className="bottom-btn-sec d-flex justify-content-between">
					<button className="btn border me-1" onClick={() => { props.onPrev(4) }}>Back</button>
					<button className="btn btn-primary ms-1" disabled={speciality.filter((item: any) => item.isChecked === true).length === 0} onClick={() => {
						onNext();
					}}>Next</button>
				</div>
			</div>
		</>
	);
}
