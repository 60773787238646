import React, { useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Helmet from 'react-helmet';
import Pagination from 'react-js-pagination';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CHAT } from '../../../constants';
import { IAppState } from '../../../store';
import { storeChatData } from '../../../store/chat/action';
import { AppointmentEditModal } from '../../components/mySchedule/AppointmentEditModal';
import { CancelAppointmentModal } from '../../components/mySchedule/CancelAppointmentModal';
import { MyScheduleHeader } from '../../components/mySchedule/MyScheduleHeader';
import configs from '../../configs/apiConfigs';
import firebaseInstance from '../../helpers/firebase';
import { appointmentUnverified, getAppointments, firestoreChangeNotificationToServer, getMasterPMSStatus, changeAppointmentPMSStatus } from '../../services/appointment';
import { ScheduleReScheduleModal } from './ScheduleReScheduleModal';
import moment from 'moment';
import { RequestForMedicalHistoryComp } from '../../components/mySchedule/RequestMedicalHistory';
import { toast } from 'react-toastify';
import { getCookie } from '../../utils/cookies';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { ViewPrescreeningModal } from '../../components/mySchedule/ViewPrescreeningModal';
import { PatientDetailModel } from '../../components/mySchedule/PatientDetailModel';
import { confirmAlert } from 'react-confirm-alert';
import { DigitalDoc } from '../../components/digitaldoc/DigitalDoc';
import CONSTANT_LABELS from '../../../constants/labels';
import { removeCountryCode } from '../../../utils/global';
import { NoteModal } from '../../../components/note/NoteModal';
import { DocumentUpload } from '../../components/documentsUpload/DocumentUpload';
let subscriptionToAppointmentChange: any = null;
export const Upcoming = () => {
    const [providerList, setProviderList] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(30);
    const [totalCount, setTotalCount] = useState(0);
    const [appointmentItemList, setAppointmentItemList] = useState([]);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [currentActiveModalAppointment, setCurrentActiveModalAppointment]: any = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [showAppointmentEditModal, setShowAppointmentEditModal] = useState(false);
    const [showScheduleReScheduleModal, setShowScheduleReScheduleModal] = useState(false);
    const [showPrescreeningModal, setShowPrescreeningModal] = useState(false);
    const [digitalDocShow, setShowDigitalDocShow] = useState(false);
    const [masterPMSStatusList, setMasterPMSStatusList] = useState([]);
    const [showPatientDetailModal, setShowPatientDetailModal] = useState(false);
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    useEffect(() => {
        return () => {
            if (subscriptionToAppointmentChange) subscriptionToAppointmentChange();
        }
    }, []);
    useEffect(() => {
        console.log("userdata", userData.clinics[0]);
        if (userData && userData.clinics && userData.clinics[0] && userData.status && userData.status.error === false && userData.status.msg !== "") {
            let providers = userData.clinics[0].provider;
            providers = providers.filter((item: any) => item.onBoarding.status === 1 && item.enableAppointment === "1");
            setProviderList(providers);
            listenRealtimeAppointmentChange();
            getMasterPMSStatusListFunc(userData.clinics[0].id);
        }
    }, [userData]);
    useEffect(() => {
        fecthAppointmentListAsync();
    }, [selectedProvider, page, results, date]);
    useEffect(() => {
        if (name.length > 3) {
            fecthAppointmentListAsync();
        } else if (name === '') {
            fecthAppointmentListAsync();
        }
    }, [name]);
    const fecthAppointmentListAsync = async (realTime = false) => {
        try {
            let modelType = 'clinic';
            let Id = userData.clinics[0].uniqueId;
            if (selectedProvider !== '') {
                Id = selectedProvider;
                modelType = 'doctor';
            }
            const request = {
                appointmentType: 'scheduled',
                modelType: modelType,
                page: page,
                results: results,
                Id: Id
            };
            if (name !== '' && name.length > 3) {
                Object.assign(request, { fullname: name });
            }
            if (date !== '') {
                Object.assign(request, { searchdate: date });
            }
            if (realTime === true) {
                setIsRefreshing(true);
            } else {
                setIsLoading(true);
            }
            const response = await getAppointments(request);
            if (response && response.status && response.status.error === false) {
                setAppointmentItemList(response.appointments);
                if (page === 1) {
                    setTotalCount(response.total);
                }
            }
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
        } catch (err) {
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
            console.log(err);
        }
    }
    const handlePageChange = (page: any) => {
        setPage(page);
    }
    const addChatPin = (item: any) => {
        dispatch(storeChatData({
            ...item,
            fetched: true,
            pinType: "chat",
            openPin: true,
            appointmentId: item.uniqueid,
            sendToCId: item.clinic.uniqueid
        }));
    }
    const cancelAppointment = (item: any) => {
        setShowCancelModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const handleEditAppointmentClick = (item: any) => {
        setShowAppointmentEditModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const handleScheduleAppointmentClick = (item: any) => {
        setShowScheduleReScheduleModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const handlePatientDetailAppointmentClick = (item: any) => {
        setShowPatientDetailModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const listenRealtimeAppointmentChange = () => {
        subscriptionToAppointmentChange && subscriptionToAppointmentChange();
        subscriptionToAppointmentChange = firebaseInstance.db.collection(configs.CHAT_COLLECTION).where('actionType', 'in', [CHAT.ACTION_TYPE.STATUS,
        CHAT.ACTION_TYPE.PRESCREENING, CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT, CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT, CHAT.ACTION_TYPE.PRESCREENING_STATUS,
        CHAT.ACTION_TYPE.CHECKIN
        ])
            .where('sendToCId', '==', userData.clinics[0].uniqueId)
            .onSnapshot((snapshot: any) => {
                fecthAppointmentListAsync(true);
            });
    }
    const handleAppointmentUnverified = async (item: any) => {
        try {
            const response = await appointmentUnverified(item.clinic.uniqueid, item.uniqueid);
            if (response && response.status && response.status.error === false) {
                toast.success(response.status.msg);
                fecthAppointmentListAsync(true);
            } else {
                toast.error('Appointment unverified failed.');
            }
        } catch (err) {
            console.log(err);
            toast.error('Appointment unverified failed.');
        }
    }
    const handleRequestForPrescreening = async (item: any) => {
        const obj = {
            "appointmentId": Number(item.uniqueid),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": CHAT.ACTION.PRBC,
            "actionType": CHAT.ACTION_TYPE.PRESCREENING_REQUEST,
            "text": 'Requested',
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": item.clinic.uniqueid,
            "sendToDId": item.doctor.uniqueid,
            "dependantId": Number(item.dependant.uniqueid),
            "dateTime": {
                "date": item.date,
                "time": item.time
            },
            "firstName": userData.firstName,
            "lastName": userData.lastName,
        };
        try {
            dispatch(fetchLoadingPending());
            await firebaseInstance.setDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
            toast.success('Prescreening reminder initiated successfully.');
            dispatch(fetchLoadingSuccess(false));
        } catch (err) {
            dispatch(fetchLoadingSuccess(false));
            toast.success('Prescreening reminder initiation failed.');
        }
    }
    const handleViewPrescreening = (item: any) => {
        setShowPrescreeningModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const handleAppointmentVerify = (item: any) => {
        let message = 'Are you sure you want to complete this appointment.';
        if ((!(item.metadata && item.metadata.appointment &&
            item.metadata.appointment.prescreening &&
            item.metadata.appointment.prescreening.attempt)) &&
            !(item.metadata && item.metadata.appointment &&
                item.metadata.appointment.prescreening &&
                item.metadata.appointment.prescreening.verifiedOn)) {
            message = ' Are you sure you want to mark this appointment as complete, we see that prescreening for this appointment is in pending state.';
        }
        confirmAlert({
            title: '',
            message: message,
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                        verifyAppointmentDirect(item);
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                    }
                }
            ]
        });
    }
    const verifyAppointmentDirect = async (appointment: any) => {
        dispatch(fetchLoadingPending());
        var objIndex: string = appointment.currentStatus.toUpperCase();
        const fromStatus = CHAT.FROM_STATUS[objIndex];
        const obj = {
            "appointmentId": Number(appointment.uniqueid),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": `Changed status from ${fromStatus} to ${CHAT.TO_STATUS.COMPLETED}.`,
            "actionType": 'STATUS',
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "fromStatus": fromStatus,
            "toStatus": CHAT.TO_STATUS.COMPLETED,
            "sendToCId": appointment.clinic.uniqueid,
            "dependantId": Number(appointment.dependant.uniqueid),
            "dateTime": {
                "date": appointment.date,
                "time": appointment.time
            },
            "firstName": userData.firstName,
            "lastName": userData.lastName,
        };
        try {
            const response = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
            if (response) {
                response.get().then((docRef: any) => {
                    let data = {
                        ...docRef.data(),
                        mode: "create",
                        id: docRef.id
                    };
                    firestoreChangeNotificationToServer(data).then((success) => {
                        dispatch(fetchLoadingSuccess(false));
                        fecthAppointmentListAsync(true);
                        toast.success('Appointment verified, select if you want to send invoice.');
                    }).catch((err) => {
                        dispatch(fetchLoadingSuccess(false));
                        toast.error('Appointment verify error.');
                        console.log(err);
                    })
                });
            }
        } catch (err) {
            dispatch(fetchLoadingSuccess(false));
            toast.error('Appointment verify error.');
        }
    }
    const doManualPrescreenVerification = async (item: any) => {
        dispatch(fetchLoadingPending());
        const obj = {
            "appointmentId": Number(item.uniqueid),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": CHAT.ACTION.PVBC,
            "actionType": CHAT.ACTION_TYPE.PRESCREENING_STATUS,
            "text": 'Manual prescreen verified',
            "isPrescreenManual": true,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": item.clinic.uniqueid,
            "sendToDId": item.doctor.uniqueid,
            "dependantId": Number(item.dependant.uniqueid),
            "dateTime": {
                "date": item.date,
                "time": item.time
            }
        };
        try {
            firebaseInstance.getPrescreeningWithViewByNull(item.uniqueid).then((querySnapshot: any) => {
                querySnapshot.forEach((doc: any) => {
                    const docRef = firebaseInstance.getDocRef(doc);
                    docRef.update({
                        "metadata.viewByClinicAt": firebase.firestore.FieldValue.serverTimestamp(),
                    });
                });
            });
            const response = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
            if (response) {
                response.get().then((docRef: any) => {
                    let data = {
                        ...docRef.data(),
                        mode: "create",
                        id: docRef.id
                    };
                    firestoreChangeNotificationToServer(data).then((success) => {
                        dispatch(fetchLoadingSuccess(false));
                        fecthAppointmentListAsync(true);
                        toast.success('Manual prescreening verification successfull.');
                    }).catch((err) => {
                        console.log(err);
                        toast.error('Manual prescreening verification failed.');
                    });
                });
            }
        } catch (err) {
            toast.error('Manual prescreening verification failed.');
            console.log(err);
            dispatch(fetchLoadingSuccess(false));
        }
    }
    const handleManualPrescreeningVerification = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Are you sure you have completed manual prescreening for this patient.',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                        doManualPrescreenVerification(item);
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                    }
                }
            ]
        });
    }
    const handleConfirmByPatient = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to confirm patient visit?',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-outline-primary',
                    onClick: async () => {
                        dispatch(fetchLoadingPending());
                        const obj = {
                            "appointmentId": Number(item.uniqueid),
                            "senderId": Number(getCookie().xpr_user_id),
                            "sender": CHAT.SENDER.USER,
                            "action": CHAT.ACTION.CBP,
                            "actionType": CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT,
                            "text": 'Confirm by patient',
                            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                            "sendToCId": item.clinic.uniqueid,
                            "sendToDId": item.doctor.uniqueid,
                            "dependantId": Number(item.dependant.uniqueid),
                            "dateTime": {
                                "date": item.date,
                                "time": item.time
                            },
                            "firstName": userData.firstName,
                            "lastName": userData.lastName,
                        };
                        try {
                            const response = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
                            if (response) {
                                response.get().then((docRef: any) => {
                                    let data = {
                                        ...docRef.data(),
                                        mode: "create",
                                        id: docRef.id
                                    };
                                    firestoreChangeNotificationToServer(data).then((success) => {
                                        dispatch(fetchLoadingSuccess(false));
                                        fecthAppointmentListAsync(true);
                                        toast.success('Appt. confirm by patient successful.');
                                    }).catch((err) => {
                                        toast.error('Appt. confirm by patient failed.');
                                    });
                                });
                            }
                        } catch (err) {
                            toast.error('Appt. confirm by patient failed.');
                            dispatch(fetchLoadingSuccess(false));
                        }
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                    }
                }
            ]
        });
    }
    const handleDigitalDocClick = (appointment: any) => {
        setCurrentActiveModalAppointment(appointment);
        setShowDigitalDocShow(true);
    }
    const getMasterPMSStatusListFunc = async (id: string) => {
        try {
            const response = await getMasterPMSStatus(id, true);
            if (response && response.status && response.status.error === false) {
                setMasterPMSStatusList(response.pmsStatuses);
            }
        } catch (err) {
            console.log(err);
        }
    }
    const handlePMSStatusChange = async (item: any, oldvalue: any, newvalue: any) => {
        try {
            const response = await changeAppointmentPMSStatus(userData.clinics[0].uniqueId, item.uniqueid, {
                oldValue: oldvalue, newValue: newvalue
            });
            if (response && response.status && response.status.error === false) {
                fecthAppointmentListAsync(true);
                toast.success('Appointment pms status change success.');
            }
        } catch (err: any) {
            if (err.msg) {
                toast.error(err.msg);
            } else {
                toast.error('Appointment pms status change failed.');
            }
        }
    }
    const handleShowNote = (appointment: any) => {
        setCurrentActiveModalAppointment(appointment);
        setShowNoteModal(true);
    }
    const handleShowDocuments = (appointment: any) => {
        setCurrentActiveModalAppointment(appointment);
        setShowDocumentsModal(true);
    }
    return (
        <React.Fragment>
            <Helmet><title>Upcoming</title></Helmet>
            <MyScheduleHeader onFetchAppointment={fecthAppointmentListAsync}></MyScheduleHeader>
            <section className="schedule-sec px-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <table className="table upcoming-table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div>NAME</div>
                                            <div className="d-flex">
                                                <input className="form-control form-control-sm" placeholder="Search by name" value={name} onChange={(e) => {
                                                    setPage(1);
                                                    setName(e.target.value);
                                                }} />
                                                <button className="btn btn-sm btn-secondary ms-2" onClick={(e) => {
                                                    setPage(1);
                                                    setName('');
                                                }}><i className="bi bi-x"></i></button>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div>Provider</div>
                                            {
                                                useMemo(() => {
                                                    return (
                                                        <select className="form-select form-select-sm" style={{ width: "180px" }} value={selectedProvider} onChange={(e) => {
                                                            setPage(1);
                                                            setSelectedProvider(e.target.value);
                                                        }}>
                                                            <option value=''>All provider</option>
                                                            {
                                                                providerList.map((value: any, index) => {
                                                                    return (
                                                                        <option value={value.uniqueid} key={value.uniqueid + "provider"}>{value.fullname}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    )
                                                }, [providerList, selectedProvider])
                                            }
                                        </th>
                                        <th scope="col">
                                            <div>Scheduled On</div>
                                            <div className="d-flex">
                                                <input className="form-control form-control-sm" type="date" id="datePicker" name="dateSchedule" value={date} onChange={(e) => {
                                                    setPage(1);
                                                    setDate(e.target.value);
                                                }}></input>
                                                <button className="btn btn-sm btn-secondary ms-2" onClick={(e) => {
                                                    setPage(1);
                                                    setDate('');
                                                    fecthAppointmentListAsync(true);
                                                }}><i className="bi bi-x"></i></button>
                                            </div>
                                        </th>
                                        {
                                            userData && userData.clinics[0] && userData.clinics[0].covid_pre_screen && <th scope="col" className="text-center">Pre-screening status</th>
                                        }
                                        {
                                            (userData && userData.accessEnabled && userData.accessEnabled.pms_integration &&
                                                userData.clinics[0] &&
                                                (userData.clinics[0].writeBack === "on" ||
                                                    userData.clinics[0].tempwriteBack === "on") && userData.clinics[0].statuswriteback === "on") &&
                                            <th scope="col" className="text-center">PMS</th>
                                        }
                                        <th className="text-end" scope="col" style={{ width: "215px" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isLoading && !isRefreshing &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        isRefreshing && !isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        isRefreshing && isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        useMemo(() => {
                                            if (!isLoading && appointmentItemList.length === 0) {
                                                return <tr>No records found</tr>
                                            }
                                            if (userData && userData.clinics[0] && !isLoading) {
                                                return (
                                                    <>
                                                        {
                                                            appointmentItemList.map((item: any) => {
                                                                return (
                                                                    <ItemRow item={item} key={item.uniqueid}
                                                                        addChatPin={addChatPin}
                                                                        cancelAppointment={cancelAppointment}
                                                                        handleEditAppointmentClick={handleEditAppointmentClick}
                                                                        handleScheduleAppointmentClick={handleScheduleAppointmentClick}
                                                                        handleAppointmentUnverified={handleAppointmentUnverified}
                                                                        handleRequestForPrescreening={handleRequestForPrescreening}
                                                                        handleViewPrescreening={handleViewPrescreening}
                                                                        handleAppointmentVerify={handleAppointmentVerify}
                                                                        handleManualPrescreeningVerification={handleManualPrescreeningVerification}
                                                                        handleConfirmByPatient={handleConfirmByPatient}
                                                                        handleDigitalDocClick={handleDigitalDocClick}
                                                                        masterPMSStatusList={masterPMSStatusList}
                                                                        handlePMSStatusChange={handlePMSStatusChange}
                                                                        handlePatientDetailAppointmentClick={handlePatientDetailAppointmentClick}
                                                                        handle
                                                                        userData={userData}
                                                                        handleShowNote={handleShowNote}
                                                                        handleShowDocuments={handleShowDocuments}
                                                                    ></ItemRow>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            }
                                        }, [appointmentItemList, isLoading])
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    hideDisabled
                                    activePage={page}
                                    itemsCountPerPage={results}
                                    totalItemsCount={Number(totalCount)}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Appointment Cancel Modal */}
            {
                useMemo(() => {
                    if (currentActiveModalAppointment && showCancelModal) {
                        return (
                            <CancelAppointmentModal appointment={currentActiveModalAppointment}
                                hideModal={() => {
                                    setShowCancelModal(false);
                                }}
                                fetchAppointment={fecthAppointmentListAsync}
                            ></CancelAppointmentModal>
                        )
                    }
                }, [currentActiveModalAppointment, showCancelModal])
            }
            {/* Appointment Edit Modal */}
            {
                useMemo(() => {
                    if (currentActiveModalAppointment && showAppointmentEditModal) {
                        return (
                            <AppointmentEditModal appointment={currentActiveModalAppointment}
                                hideModal={() => {
                                    setShowAppointmentEditModal(false);
                                }}
                                fetchAppointment={fecthAppointmentListAsync}
                            ></AppointmentEditModal>
                        )
                    }
                }, [currentActiveModalAppointment, showAppointmentEditModal])
            }
            {
                useMemo(() => {
                    if (currentActiveModalAppointment && showScheduleReScheduleModal) {
                        return (
                            <ScheduleReScheduleModal appointment={currentActiveModalAppointment}
                                hideModal={() => {
                                    setShowScheduleReScheduleModal(false);
                                }}
                                fetchAppointment={fecthAppointmentListAsync}
                                isReschedule={true}
                            ></ScheduleReScheduleModal>
                        )
                    }
                }, [currentActiveModalAppointment, showScheduleReScheduleModal])
            }
            {
                useMemo(() => {
                    if (showPrescreeningModal && currentActiveModalAppointment) {
                        return (
                            <ViewPrescreeningModal
                                hideModal={() => {
                                    setShowPrescreeningModal(false);
                                }}
                                appointment={currentActiveModalAppointment}
                                fetchAppointment={fecthAppointmentListAsync}
                            ></ViewPrescreeningModal>
                        )
                    }
                }, [showPrescreeningModal, currentActiveModalAppointment])
            }
            {
                useMemo(() => {
                    if (showPatientDetailModal && currentActiveModalAppointment) {
                        return (
                            <PatientDetailModel
                                hideModal={() => {
                                    setShowPatientDetailModal(false);
                                }}
                                appointment={currentActiveModalAppointment}
                            >
                            </PatientDetailModel>
                        )
                    }
                }, [showPatientDetailModal, currentActiveModalAppointment])
            }
            <iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe>
            {
                useMemo(() => {
                    if (digitalDocShow && currentActiveModalAppointment) {
                        return (
                            <DigitalDoc
                                appointment={currentActiveModalAppointment}
                                hideModal={() => {
                                    setShowDigitalDocShow(false);
                                }}
                                fetchAppointment={fecthAppointmentListAsync}
                            ></DigitalDoc>
                        )
                    }
                }, [digitalDocShow, currentActiveModalAppointment])
            }
            {
                showNoteModal &&
                <NoteModal
                    data={currentActiveModalAppointment}
                    handleCancel={() => {
                        setShowNoteModal(false);
                    }} handleOk={() => {
                        setShowNoteModal(false);
                        toast.success("Note added successfully");
                    }}
                ></NoteModal>
            }
            {
                showDocumentsModal &&
                <DocumentUpload
                    data={currentActiveModalAppointment} 
                    type="appointment"
                    id={currentActiveModalAppointment?.uniqueid}
                    hideModal={() => {
                        setShowDocumentsModal(false);
                    }}
                />
            }
        </React.Fragment>
    );
};
const ItemRow = ({ item, addChatPin, cancelAppointment,
    handleEditAppointmentClick,
    handleScheduleAppointmentClick,
    handleAppointmentUnverified,
    handleRequestForPrescreening,
    handleViewPrescreening,
    handleAppointmentVerify,
    handleManualPrescreeningVerification,
    handleConfirmByPatient,
    handleDigitalDocClick,
    masterPMSStatusList,
    handlePMSStatusChange,
    handlePatientDetailAppointmentClick,
    userData,
    handleShowNote,
    handleShowDocuments
}: any) => {
    let takeWritebackBool = false;
    let apptScheduled = true;
    let apptReSchedule = true;
    let apptConfirmPatient = true;
    let apptComplete = true;
    let apptEdit = true;
    let apptCancel = true;
    let apptPMSStatus = true;
    let tempWriteBack = false;
    if (userData && userData.clinics && userData.clinics[0] &&
        userData.clinics[0].tempwriteBack === "on") {
        tempWriteBack = true;
    }
    if (userData?.accessEnabled?.pms_integration === true) {
        if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].appointmentwriteback === "on") {
            apptReSchedule = false;
        }
        if (userData.clinics[0].writeBack === "off") {
            apptScheduled = false;
            // apptReSchedule = true; // enabled for special case of SIKKA status sync issue
            apptConfirmPatient = false;
            apptComplete = true; // enabled for special case of SIKKA status sync issue
            apptEdit = true; // enabled for special case of SIKKA status sync issue
            apptCancel = true; //enabled for special case of SIKKA status sync issue
            apptPMSStatus = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "off" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "off") {
            apptScheduled = false;
            // apptReSchedule = false;
            apptConfirmPatient = false;
            apptComplete = false;
            apptEdit = false;
            apptCancel = false;
            apptPMSStatus = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "on" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "off") {
            apptScheduled = false;
            // apptReSchedule = false;
            apptConfirmPatient = false;
            apptComplete = false;
            apptEdit = false;
            apptCancel = false;
            apptPMSStatus = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "on" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "on") {
            apptScheduled = false;
            // apptReSchedule = false;
            apptEdit = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "off" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "on") {
            apptScheduled = false;
            // apptReSchedule = false;
            apptEdit = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "off" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "off") {
            apptScheduled = false;
            // apptReSchedule = false;
            apptConfirmPatient = false;
            apptComplete = false;
            apptEdit = false;
            apptCancel = false;
            apptPMSStatus = false;
        } else if (userData.clinics[0].statuswriteback === "off") {
            apptPMSStatus = false;
        }
    }
    if (userData.clinics[0] &&
        userData?.accessEnabled?.pms_integration === true &&
        (userData.clinics[0].writeBack === "on") &&
        item.metadata && item.metadata.pms && item.metadata.pms.writeBackStatus && item.metadata.pms.writeBackStatus === "Success") {
        takeWritebackBool = true;
    } else if (userData.clinics[0] &&
        userData?.accessEnabled?.pms_integration === true &&
        (userData.clinics[0].writeBack === "off")) {
        takeWritebackBool = true; // enabled for special case sikka sync issue.
    } else if (userData.clinics[0] &&
        userData?.accessEnabled?.pms_integration === false &&
        userData.clinics[0].writeBack === "off" &&
        (!(item.metadata && item.metadata.pms && item.metadata.pms.writeBackStatus) ||
            (item.metadata && item.metadata.pms && item.metadata.pms.writeBackStatus === "Success"))) {
        takeWritebackBool = true;
    }
    const getBadge = () => {
        const data = item;
        let badges = [];
        if (data?.metadata?.appointment?.floating) {
            console.log("data?.metadata?.appointment?.floating", data?.metadata?.appointment?.floating);
            badges.push(<span className="badge ms-2 rounded-pill bg-warning">Floating</span>)
        } else {
            if (!data?.metadata?.appointment?.prescreening?.attempt &&
                !data?.metadata?.appointment?.confirmation?.attempt) {
                badges.push(<span className="badge ms-2 rounded-pill bg-secondary">Scheduled</span>);
            }
            if (data?.metadata?.appointment?.checkin && data?.metadata?.appointment?.checkin.attempt === true) {
                badges.push(<span className="badge ms-2 rounded-pill bg-success">Checked-in</span>)
            } else {
                if (data?.metadata?.appointment?.confirmation?.attempt &&
                    data?.metadata?.appointment?.confirmation?.status === "confirmed") {
                    badges.push(<span className="badge ms-2 rounded-pill bg-darkgreen">Confirmed</span>)
                }
            }
        }
        console.log("badges", badges);
        return badges;
    }
    return (
        <tr className={"table-row have-msg"} key={item.uniqueid}>
            <td>
                <span className="d-block fw-medium p-0 cursor-pointer btn-link text-capitalize" onClick={() => {
                    handlePatientDetailAppointmentClick(item)
                }}>
                    {
                        (item.dependant && item.dependant.fname && item.dependant.lname) ?
                            (item.dependant.fname + ' ' + item.dependant.lname).substring(0, 15) : ''
                    }
                </span>
                <span className="d-block text-muted">
                    {
                        item.dependant.phone && item.dependant.phone !== "" ? (CONSTANT_LABELS.PLUS_ONE + removeCountryCode(item.dependant.phone)) : item.dependant.email
                    }
                </span>
            </td>
            <td>
                <div className="d-flex p-0">
                    <div className="avatar flex-shrink-0 avatar-lg border rounded-circle me-3">
                        {
                            (item.doctor && item.doctor.photo && item.doctor.photo.name &&
                                item.doctor.photo.type) ?
                                <img src={configs.IMAGE_URL + 'doctor/' +
                                    item.doctor.photo.name + '-200x200.' + item.doctor.photo.type} alt="profile image" className="img-fluid" />
                                :
                                <>{item.doctor.fullname.split(" ")[0] ? item.doctor.fullname.split(" ")[0].charAt(0) : ''}
                                    {item.doctor.fullname.split(" ")[1] ? item.doctor.fullname.split(" ")[1].charAt(0) : ''}</>
                        }
                    </div>
                    <div>
                        <span className="d-block fw-medium"> {(item.doctor && item.doctor.fullname) ?
                            item.doctor.fullname : ''}
                            {
                                (!(item.metadata
                                    && item.metadata.appointment && item.metadata.appointment.prescreening
                                    && item.metadata.appointment.prescreening.attempt) && !(item.metadata
                                        && item.metadata.appointment && item.metadata.appointment.prescreening
                                        && item.metadata.appointment.prescreening.verifiedOn) &&
                                    apptEdit &&
                                    (takeWritebackBool || tempWriteBack)) &&
                                <button className="btn p-0 btn-link"
                                    type="button"
                                    onClick={(e) => { handleEditAppointmentClick(item); }}>(Edit)</button>
                            }</span>
                        <small className="d-block text-muted">{item.appointmentType ? item.appointmentType : ''}</small>
                    </div>
                </div>
            </td>
            <td>
                <small className="d-block p-0">{moment(item.date + " " + item.time, 'YYYY-MM-DD LT').format("MMM DD, YYYY - LT")}</small>
                {
                    (!(item.metadata
                        && item.metadata.appointment && item.metadata.appointment.prescreening
                        && item.metadata.appointment.prescreening.attempt) && !(item.metadata
                            && item.metadata.appointment && item.metadata.appointment.prescreening
                            && item.metadata.appointment.prescreening.verifiedOn) && apptReSchedule) &&
                    <button className="btn p-0 btn-link" type="button" onClick={() => {
                        handleScheduleAppointmentClick(item);
                    }}>Reschedule</button>
                }
            </td>
            {userData && userData.clinics[0] && userData.clinics[0].covid_pre_screen && <td className="text-center">
                {
                    (!(item.metadata
                        && item.metadata.appointment && item.metadata.appointment.prescreening
                        && item.metadata.appointment.prescreening.attempt) && !(item.metadata
                            && item.metadata.appointment && item.metadata.appointment.prescreening
                            && item.metadata.appointment.prescreening.verifiedOn) &&
                        userData?.clinics[0]?.covid_pre_screen) &&
                    <>
                        <span className="badge rounded-pill bg-danger">Pending</span>
                        {
                            ((!(item.metadata
                                && item.metadata.appointment && item.metadata.appointment.prescreening
                                && item.metadata.appointment.prescreening.attempt) &&
                                moment.duration(moment(item.date + " " + "11:59:59 PM", 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() <= 48
                                && moment.duration(moment(item.date + " " + "11:59:59 PM", 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() >= 0)) &&
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="tooltip">
                                        Prescreening manual verification
                                    </Tooltip>
                                }
                            >
                                <button className="btn p-0 btn-link d-block mx-auto mt-1" onClick={(e) => {
                                    handleManualPrescreeningVerification(item);
                                }}>
                                    Manual verification
                                </button>
                            </OverlayTrigger>
                        }
                        {
                            ((!(item.metadata
                                && item.metadata.appointment && item.metadata.appointment.prescreening
                                && item.metadata.appointment.prescreening.attempt) &&
                                moment.duration(moment(item.date + " " + item.time, 'YYYY-MM-DD LT').add(item.duration, 'minutes').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() <= 48
                                && moment.duration(moment(item.date + " " + item.time, 'YYYY-MM-DD LT').add(item.duration, 'minutes').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() >= 0)) &&
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="tooltip">
                                        Prescreening Request
                                    </Tooltip>
                                }
                            >
                                <button className="btn p-0 btn-link d-block mx-auto mt-1" onClick={(e) => {
                                    handleRequestForPrescreening(item)
                                }}>
                                    Remind
                                </button>
                            </OverlayTrigger>
                        }
                    </>
                }
                {
                    (item.metadata
                        && item.metadata.appointment && item.metadata.appointment.prescreening
                        && item.metadata.appointment && item.metadata.appointment.prescreening.attempt
                        && item.metadata.appointment.prescreening.verifiedOn &&
                        (item.metadata && item.metadata.appointment &&
                            item.metadata.appointment.prescreening &&
                            item.metadata.appointment.prescreening.attempt &&
                            item.metadata.appointment.prescreening.result === 'Pass') &&
                        userData?.clinics[0]?.covid_pre_screen) &&
                    <>
                        <span className="badge rounded-pill bg-success">
                            {
                                item.metadata && item.metadata.appointment &&
                                item.metadata.appointment.prescreening &&
                                item.metadata.appointment.prescreening.isPrescreenManual &&
                                <>Manully verified</>
                            }
                            {
                                item.metadata && item.metadata.appointment &&
                                item.metadata.appointment.prescreening &&
                                !item.metadata.appointment.prescreening.isPrescreenManual &&
                                <>Verified</>
                            }
                        </span>
                        {
                            (item.metadata
                                && item.metadata.appointment && item.metadata.appointment.prescreening
                                && item.metadata.appointment.prescreening.attempt &&
                                !item.metadata.appointment.prescreening.isPrescreenManual) &&
                            <button className="btn p-0 btn-link d-block mx-auto mt-1" onClick={(e) => {
                                handleViewPrescreening(item);
                            }}>View</button>
                        }
                    </>
                }
                {
                    ((item.metadata && item.metadata.appointment &&
                        item.metadata.appointment.prescreening &&
                        item.metadata.appointment.prescreening.attempt &&
                        item.metadata.appointment.prescreening.result === 'Fail') &&
                        userData?.clinics[0]?.covid_pre_screen) &&
                    <div>
                        <span className="badge rounded-pill bg-danger">Fail <i className="bi bi-exclamation-triangle"></i></span>
                        {
                            (item.metadata
                                && item.metadata.appointment && item.metadata.appointment.prescreening
                                && item.metadata.appointment.prescreening.attempt) &&
                            <button className="btn p-0 btn-link d-block mx-auto mt-1" onClick={(e) => {
                                handleViewPrescreening(item);
                            }}>View</button>
                        }
                    </div>
                }
            </td>}
            {
                (userData && userData.accessEnabled && userData.accessEnabled.pms_integration && userData.clinics[0] &&
                    (userData.clinics[0].writeBack === "on" ||
                        userData.clinics[0].tempwriteBack === "on") && (apptPMSStatus || userData.clinics[0].tempwriteBack === "on")) &&
                <td className="text-center">
                    {
                        (item && item.metadata && item.metadata.pms && item.metadata.pms.writeBackStatus &&
                            item.metadata.pms.writeBackStatus === "Success") ?
                            <>
                                <select className="form-select" defaultValue={item.metadata.pms.appointmentStatus ? item.metadata.pms.appointmentStatus : ""} onChange={(e: any) => {
                                    if (e.target.value === "") return;
                                    handlePMSStatusChange(item, item.metadata.pms.appointmentStatus, e.target.value);
                                }}>
                                    <option value="" disabled>Not available</option>
                                    {
                                        masterPMSStatusList.map((item: any) => {
                                            return <option value={item.status} key={item.id + "" + item.status}>{item.status}</option>
                                        })
                                    }
                                </select>
                            </>
                            :
                            <>
                                <label>Pending</label>
                            </>
                    }
                </td>
            }
            <td className="text-end">
                {
                    item.dependant && !item.dependant.phone && !item.patient.phone &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">Manual followup</Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { }}>
                            <i className="bi bi-telephone"></i></button>
                    </OverlayTrigger>
                }
                {
                    item.dependant && (item.dependant.isSignInUser === true || item.dependant.phone) &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">Quick chat</Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { addChatPin(item); }}>
                            <i className="bi bi-chat-right-text"></i></button>
                    </OverlayTrigger>
                }
                {
                    (item.dependant && item.dependant.isSignInUser === true &&
                        userData.accessEnabled?.digitaldoc === true) &&
                    < OverlayTrigger placement="auto" overlay={(props: any) => {
                        return (
                            <Tooltip id="button-tooltip" {...props}>Digital forms</Tooltip>
                        )
                    }}>
                        <button onClick={() => {
                            handleDigitalDocClick(item);
                        }} className="btn mx-1 p-1">
                            <i className="bi bi-file-medical"></i>
                        </button>
                    </OverlayTrigger>
                }
                {/*  {
                    ((item && item.metadata &&
                        item.metadata.patient &&
                        item.metadata.patient.isFromPms) || (item && item.metadata &&
                            item.metadata.appointment &&
                            item.metadata.appointment.isMerging)) &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">Unverify</Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1" onClick={(e) => {
                            handleAppointmentUnverified(item);
                        }}>
                            <i className="bi bi-arrow-clockwise"></i>
                        </button>
                    </OverlayTrigger>
                } */}
                {
                    ((takeWritebackBool || tempWriteBack) && apptCancel) &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Cancel appointment
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { cancelAppointment(item); }}>
                            <i className="bi bi-calendar-x"></i>
                        </button>
                    </OverlayTrigger>
                }
                {
                    ((takeWritebackBool || tempWriteBack) && apptComplete && moment(item.date + " " + item.time, 'YYYY-MM-DD LT').isBefore(moment()) &&
                        !(item.metadata && item.metadata.appointment &&
                            item.metadata.appointment.confirmation &&
                            item.metadata.appointment.confirmation.status === 'cancelled') &&
                        !(item.metadata && item.metadata.appointment &&
                            item.metadata.appointment.prescreening &&
                            item.metadata.appointment.prescreening.attempt &&
                            item.metadata.appointment.prescreening.result === 'Fail')) &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Mark Appt. Complete
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={() => {
                                handleAppointmentVerify(item);
                            }}>
                            <i className="bi bi-patch-check"></i></button>
                    </OverlayTrigger>
                }
                {
                    ((moment(moment(item.date + " " + item.time, 'YYYY-MM-DD LT').add(item.duration, 'minutes').format('YYYY-MM-DD LT')).isAfter(moment().format('YYYY-MM-DD LT')) &&
                        (!(item.metadata && item.metadata.appointment &&
                            item.metadata.appointment.prescreening &&
                            item.metadata.appointment.prescreening.attempt &&
                            item.metadata.appointment.prescreening.result === 'Fail') &&
                            !(item.metadata && item.metadata.appointment &&
                                item.metadata.appointment.prescreening &&
                                item.metadata.appointment.prescreening.attempt)) &&
                        !(item.metadata && item.metadata.appointment &&
                            item.metadata.appointment.confirmation &&
                            (item.metadata.appointment.confirmation.status === 'confirmed' ||
                                item.metadata.appointment.confirmation.status !== 'cancelled'))) && apptConfirmPatient && (takeWritebackBool)) &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Appt. confirmed by patient
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={() => {
                                handleConfirmByPatient(item);
                            }}>
                            <i className="bi bi-patch-check"></i></button>
                    </OverlayTrigger>
                }
                {
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Note
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={() => {
                                handleShowNote(item);
                            }}>
                            <i className="bi bi-pencil-square"></i></button>
                    </OverlayTrigger>
                }
                 {
                    !userData?.accessEnabled?.pms_integration &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                 Documents
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={() => {
                                handleShowDocuments(item);
                            }}>
                             <i className="bi bi-file-earmark"></i></button>
                    </OverlayTrigger>
                }
                <div className="inline-badges">
                    {getBadge()}
                </div>
            </td>
        </tr >
    )
}