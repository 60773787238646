import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { Redirect, NavLink, useHistory } from 'react-router-dom';
import CONSTANT_LABELS from '../../../../constants/labels';
import { addSettings, emailVerification, notificationChangeSettings, removeSettings } from '../../../services/settings';
import { toast } from 'react-toastify';
import { checkIfEmptyOrNull, emailValidator } from '../../../../utils/validators';
import { ERROR_CONSTANTS, TITLE_CONSTANTS } from '../../../../constants';
import { fetchUserDataPending } from '../../../../store/userData/actions';
import { fetchSettingsPending } from '../../../../store/settings/actions';
import { fetchLoadingSuccess, fetchLoadingPending } from '../../../../store/loadingIndicator/actions';
const ROLE_MAPPING: any = {
    agency: 'Agency'
}
export const AdminList = (props: any) => {
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const settingData = useSelector((state: IAppState | any) => state.settings.settingsResponse, shallowEqual);
    const [showAddAdministrator, setShowAddAdministrator] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [invalidField, setInvalidField] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [activeRemoveId, setActiveRemoveId] = useState('');
    const dispatch = useDispatch();
    useEffect(() => {
        const type = 'clinic';
        const Id = userData.clinics[0].uniqueId;
        dispatch(fetchSettingsPending({ type: type, uniqueId: Id }));
    }, []);
    const handleShowAdministratorChange = () => {
        setShowAddAdministrator(!showAddAdministrator);
    }
    const handleResendClick = (email: string, uniqueId: string) => {
        let request = {
            email: email
        }
        dispatch(fetchLoadingPending());
        emailVerification(request, uniqueId, 'user').then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                toast.success(success.status.msg);
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        });
    }
    const handleChangeEmailNotify = (e: any, value: any, index: any) => {
        const type = 'clinic';
        const Id = userData.clinics[0].uniqueId;
        const permission: any = {
            email: value.email,
            id: value.id,
            uniqueId: value.uniqueid,
            userid: value.userid,
            emailNotification: value.emailNotification,
            emailVerified: value.emailVerified,
            firstName: value.firstName,
            lastName: value.lastName,
            userAccess: value.userAccess
        }
        // this.props.notificationChangeSettings({
        //     type: type, uniqueId: Id, permission: permission
        // });
        dispatch(fetchLoadingPending());
        notificationChangeSettings(type, Id, permission,).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                dispatch(fetchSettingsPending({ type: type, uniqueId: Id }));
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            if (err && err.error === true) {
                toast.error(err.msg);
            }
            console.log(err);
        })
    }
    const handleAdministratorClick = () => {
        if (checkIfEmptyOrNull(firstName)) {
            setInvalidField(true);
            setErrorMsg(CONSTANT_LABELS.REQUIRED.FIRST_NAME_REQUIRED)
            return;
        } else if (checkIfEmptyOrNull(lastName)) {
            setInvalidField(true);
            setErrorMsg(CONSTANT_LABELS.REQUIRED.LAST_NAME_REQUIRED)
            return;
        } else if (checkIfEmptyOrNull(email)) {
            setInvalidField(true);
            setErrorMsg(CONSTANT_LABELS.REQUIRED.EMAIL_REQUIRED)
            return;
        } else if (!emailValidator(email)) {
            setInvalidField(true);
            setErrorMsg(ERROR_CONSTANTS.LABELS.EMAIL);
            return;
        }
        const type = 'clinic';
        const Id = userData.clinics[0].uniqueId;
        const body = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            role: role === '' ? undefined : role
        }
        dispatch(fetchLoadingPending());
        addSettings(type, Id, body).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                toast.success(success.status.msg);
                setFirstName('');
                setLastName('');
                setEmail('');
                setShowAddAdministrator(false);
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        });
    }
    const handleRemoveAdministrator = (value: any) => {
        const type = 'clinic';
        const Id = userData.clinics[0].uniqueId;
        const permission: any = {
            email: value.email,
            id: value.id,
            uniqueId: value.uniqueid,
            userid: value.userid,
            emailNotification: value.emailNotification,
            emailVerified: value.emailVerified,
            firstName: value.firstName,
            lastName: value.lastName,
            userAccess: value.userAccess
        }
        dispatch(fetchLoadingPending());
        removeSettings(type, Id, permission).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                toast.success(success.status.msg);
                dispatch(fetchSettingsPending({ type: type, uniqueId: Id }));
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        });
    }

    const handleFirstNameChange = (e: any) => {
        setInvalidField(false);
        setErrorMsg('');
        setFirstName(e.target.value);
    }
    const handleLastNameChange = (e: any) => {
        setInvalidField(false);
        setErrorMsg('');
        setLastName(e.target.value);
    }
    const handleEmailChange = (e: any) => {
        setInvalidField(false);
        setErrorMsg('');
        setEmail(e.target.value);
    }

    return (
        <div className="card set_admin cslist_view my-4">
            <div className="card-header">
                <div className="d-flex align-items-center justify-content-between">
                    <h4 className="card-title align-self-center m-0">{CONSTANT_LABELS.LABELS.ADMINISTRATOR}</h4>
                    <div className="card-toolbar">
                        {
                            (userData.userAccess === "1") &&
                            <ul className="m-0 p-0">
                                <li>
                                    <a className="btn btn-primary" onClick={() => { handleShowAdministratorChange() }}>
                                    <i className="bi bi-plus"></i><span> Add</span>
                                    </a>
                                </li>
                            </ul>
                        }
                    </div>
                </div>
            </div>
            <div className="card-body p-0 adminListTable">
                <div className="table-responsive">
                    <table className="table table-hover m-b-0">
                        <thead>
                            <tr>
                                <th>{CONSTANT_LABELS.LABELS.ADMINISTRATOR_TABLE.NAME}</th>
                                <th>{CONSTANT_LABELS.LABELS.ADMINISTRATOR_TABLE.EMAIL}</th>
                                <th>{CONSTANT_LABELS.LABELS.ADMINISTRATOR_TABLE.ROLE}</th>
                                {
                                    <th>{CONSTANT_LABELS.LABELS.ADMINISTRATOR_TABLE.NOTIFICATION}</th>
                                }
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="tbcs">
                            {
                                settingData.permission.length === 0 &&
                                <>
                                    <div className="col-sm-12 p-20">
                                        No Data Present
                                    </div>
                                </>
                            }
                            {
                                settingData.permission.length > 0 &&
                                <>
                                    {
                                        settingData.permission.map((value: any, index: number) => {
                                            //@ts-ignore
                                            const uniqueId: string = value.uniqueid;
                                            return (

                                                <tr key={index + Math.random()}>
                                                    <td>{value.firstName + " " + value.lastName}</td>
                                                    <td><span className="d-block m-b-5">{value.email}</span>
                                                        {
                                                            (value.emailVerified !== null && value.emailVerified !== "") &&
                                                            <div className="badge rounded-pill bg-success small">VERIFIED</div>
                                                        }
                                                        {
                                                            (value.emailVerified === null || value.emailVerified === "") &&
                                                            <>
                                                                <div className="badge badge-pill badge-red small">UNVERIFIED</div>
                                                                <div className="badge badge-pill cursor-pointer small" onClick={(e) => { handleResendClick(value.email, uniqueId); }}>Resend email</div>
                                                            </>
                                                        }
                                                    </td>
                                                    <td>{ROLE_MAPPING[value.role] ? ROLE_MAPPING[value.role] : '-'}</td>
                                                    {
                                                        <td>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" type="checkbox" id={value.id} checked={(value.emailNotification === "1") ? true : false} onChange={(e) => handleChangeEmailNotify(e, value, index)} />
                                                                <label className="form-check-label small">
                                                                {
                                                                            (value.emailNotification === "" || value.emailNotification === "0") &&
                                                                            <span className="text-muted">DISABLED</span>
                                                                        }
                                                                        {
                                                                            (value.emailNotification === "1") &&
                                                                            <span className="text-dark">ENABLED</span>
                                                                        }
                                                                </label>
                                                            </div>
                                                            
                                                            {/* <div className="d-flex align-items-center">
                                                                <div className="form-group d-flex align-items-center m-b-0">
                                                                    <div className="switch m-r-10">
                                                                        <input type="checkbox" id={value.id} checked={(value.emailNotification === "1") ? true : false} onChange={(e) => handleChangeEmailNotify(e, value, index)} />
                                                                        <label htmlFor={value.id}></label>
                                                                    </div>


                                                                    <div className="font-size-12">
                                                                        {
                                                                            (value.emailNotification === "" || value.emailNotification === "0") &&
                                                                            <span className="text-danger">DISABLED</span>
                                                                        }
                                                                        {
                                                                            (value.emailNotification === "1") &&
                                                                            <span className="text-success">ENABLED</span>
                                                                        }
                                                                         <label>Email Notifications</label> 



                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </td>
                                                    }
                                                    {
                                                        value.userAccess === "1" &&
                                                        <td className="text-center">
                                                            <div className="badge rounded-pill bg-dark">OWNER</div>
                                                            {/* <span className="cursor-pointer badge badge-danger">Remove</span> */}
                                                        </td>
                                                    }
                                                    {
                                                        value.userAccess === "0" &&
                                                        <td className="text-center">
                                                            {
                                                                activeRemoveId !== value.id &&
                                                                <a className="text-danger" href="javascript:void(0);" onClick={() => { setActiveRemoveId(value.id) }}>Delete</a>
                                                            }
                                                            {
                                                                activeRemoveId === value.id &&
                                                                <span className="cursor-pointer badge badge-danger" onClick={() => { handleRemoveAdministrator(value) }}>Remove</span>
                                                            }
                                                        </td>
                                                    }
                                                </tr>
                                            );
                                        })
                                    }
                                </>
                            }

                        </tbody>
                    </table>

                </div>

                {
                    showAddAdministrator === true &&
                    <>
                        <div className="as_bottom p-30 ">
                            <form>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <input type="text" className="form-control" placeholder="First name" value={firstName} onChange={(e) => handleFirstNameChange(e)} />
                                    </div>
                                    <div className="col-sm-3">
                                        <input type="text" className="form-control" placeholder="Last name" value={lastName} onChange={(e) => handleLastNameChange(e)} />
                                    </div>
                                    <div className="col-sm-3">
                                        <input type="text" className="form-control" placeholder="Email" value={email} onChange={(e) => handleEmailChange(e)} />
                                    </div>
                                    <div className="col-sm-3">
                                        <select className='form-select' value={role} onChange={(e) => {
                                            setRole(e.target.value);
                                        }}>
                                            <option value="">Admin</option>
                                            <option value="agency">{ROLE_MAPPING['agency']}</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-3 align-self-center">
                                        <button type="button" className="btn btn-primary " onClick={() => handleAdministratorClick()}><i className="bi bi-plus"></i><span> Add</span></button>
                                    </div>
                                </div>
                            </form>

                            {
                                invalidField === true &&
                                <>
                                    <div className="is-invalid">{errorMsg}</div>
                                </>
                            }
                            <div className="text-dark m-t-10 mt-2 small text-muted">If a user with this email is not already registered, we will send them an invitation.</div>

                        </div>
                    </>
                }
            </div>
        </div>
    )
}
