import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
export const ProgressBar = (props: any) => {
  const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  const [totalStep, setTotalStep] = useState(6);
  useEffect(() => {
    let isPmsTaken = userData.accessEnabled.pms_integration;
    if (!isPmsTaken) {
      setTotalStep(5);
    }
  }, [userData]);
  return (
    <div className="row">
      <div className="col-12">
        <div className="top-menu-sec">
          <ul className="p-0 m-0 d-flex justify-content-center">
            <li className={"step-list" + (props.step === 0 ? ' active' : '') + (props.step > 0 ? ' completed' : '')}>
              <div className="d-xl-flex">
                <div className="img-box">
                  <i className="bi bi-person-plus-fill"></i>
                </div>
                <div className="txt-box">
                  <small>Step 1/{totalStep}</small>
                  <span className="d-block fw-medium">Personal Info</span>
                </div>
              </div>
            </li>
            <li className={"step-list" + (props.step === 1 ? ' active' : '') + (props.step > 1 ? ' completed' : '')} >
              <div className="d-xl-flex">
                <div className="img-box">
                  <i className="bi bi-asterisk"></i>
                </div>
                <div className="align-self-center txt-box">
                  <small>Step 2/{totalStep}</small>
                  <span className="d-block fw-medium">Services</span>
                </div>
              </div>
            </li>
            <li className={"step-list" + (props.step === 2 ? ' active' : '') + (props.step > 2 ? ' completed' : '')}>
              <div className="d-xl-flex">
                <div className="img-box">
                  <i className="bi bi-clock-fill"></i>
                </div>
                <div className="align-self-center txt-box">
                  <small>Step 3/{totalStep}</small>
                  <span className="d-block fw-medium">Availablity</span>
                </div>
              </div>
            </li>
            {
              totalStep === 6 &&
              <li className={"step-list" + (props.step === 3 ? ' active' : '') + (props.step > 3 ? ' completed' : '')}>
                <div className="d-xl-flex">
                  <div className="img-box">
                    <i className="bi bi-arrow-left-right"></i>
                  </div>
                  <div className="align-self-center txt-box">
                    <small>Step 4/{totalStep}</small>
                    <span className="d-block fw-medium">Patient Management System Mapping</span>
                  </div>
                </div>
              </li>
            }
            <li className={"step-list" + (props.step === 4 ? ' active' : '') + (props.step > 4 ? ' completed' : '')}>
              <div className="d-xl-flex">
                <div className="img-box">
                  <i className="bi bi-search"></i>
                </div>
                <div className="align-self-center txt-box">
                  <small>{totalStep === 6 ? 'Step 5/' + totalStep : 'Step 4/' + totalStep}</small>
                  <span className="d-block fw-medium">Discoverability Score</span>
                </div>
              </div>
            </li>
            <li className={"step-list" + (props.step === 5 ? ' active' : '') + (props.step > 5 ? ' completed' : '')}>
              <div className="d-xl-flex">
                <div className="img-box">
                  <i className="bi bi-award-fill"></i>
                </div>
                <div className="align-self-center txt-box">
                  <small>{totalStep === 6 ? 'Step 6/' + totalStep : 'Step 5/' + totalStep}</small>
                  <span className="d-block fw-medium">Education & Certifications</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}