import { getChartData } from "../../../services/dashboardCharts";
export const getChart = async (data: any, type: any) => {
    return getChartData(data, type).then((success) => {
        if (success) {
            return success.data;
        }
    }).catch((err) => {
        console.log(err);
    })
}

export const chartOptions = (data: any,chartTitle:any) => {
    return {
        animationEnabled: true,
        theme: "light2",
        title: {
            text: chartTitle,
            fontSize: 16,
        },
        legend: {
            fontFamily: "calibri",
            fontSize: 10,
            dockInsidePlotArea: true,
            verticalAlign: "center",
            horizontalAlign: "center",
            itemWidth: 75,
            // itemTextFormatter: function (e:any) {
            //     return e.dataPoint.name + ": " + Math.round(e.dataPoint.y);
            // }
        },
        data: [{
            cursor: "pointer",
            explodeOnClick: false,
            innerRadius: "68%",
            // legendMarkerType: "square",
            name: chartTitle,
            radius: "80%",
            showInLegend: true,
            startAngle: 90,
            type: "doughnut",
            // indexLabel: "{y}",
            indexLabelFormatter: function (e: any) {
                if (e.dataPoint.y) {
                    return e.dataPoint.y;
                }
            },
            indexLabelTextAlign: "left",
            indexLabelPlacement: "inside",
            indexLabelFontColor: "black",
            dataPoints: data
        }]
    }
}

export const CHART_LABEL = {
    APP: 'App/Web',
    EMAIL: 'Email',
    SMS: 'sms',
    PRESCREENING_REQUEST: 'Prescreening request',
    PRESCREENING_CONFIRM: 'Prescreening confirm'
}

export const COLORS = {
    APP: '#FFCD46',
    EMAIL: '#4c8BF5',
    SMS: '#ed7d31'
}

export const chartDataConfigure = (data: any) => {
    let dataP = data.map((item: any) => {
        let color: any;
        if (item.label === CHART_LABEL.APP) {
            color = COLORS.APP;
        } else if (item.label === CHART_LABEL.EMAIL || item.label === CHART_LABEL.PRESCREENING_REQUEST) {
            color = COLORS.EMAIL;
        } else {
            color = COLORS.SMS;
        }
        return {
            y: item.value,
            name: item.label === CHART_LABEL.PRESCREENING_REQUEST ? 'PS Remind' : item.label === CHART_LABEL.PRESCREENING_CONFIRM ? 'PS Verified' : item.label,
            color: color
        }
    });
    return dataP;
}

