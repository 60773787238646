import {
    SETTINGS, FetchSettingsErrorAction, FetchSettingsPendingAction, FetchSettingsSuccessAction,
    SettingsRequest, SettingsResponse, SettingsAddRequest, SettingsAddRequestPendingAction,
    SettingsAddRequestSuccessAction, SettingsAddResponse, SettingsAddRequestErrorAction, SettingsRemoveResponse,
    SettingsRemoveRequest, SettingsRemoveRequestPendingAction, SettingsRemoveRequestSuccessAction,
    SettingsRemoveRequestErrorAction, SettingsNotificationChangeRequestPendingAction,
    SettingsNotificationChangeRequestSuccessAction,
    SettingsNotificationChangeRequestErrorAction, SpecialityActionPedingAction,
    SpecialityActionErrorAction, SpecialityRequest, SpecialityResponse,
    SpecialityActionSuccessAction, EmailVerificationErrorAction, EmailVerificationResponse,
    EmailVerificationSuccessAction, EmailVerificationPendingAction, EmailVerificationRequest,
    AcceptTCRequest, AcceptTCPendingAction, AcceptTCSuccessAction, AcceptTCResponse, AcceptTCErrorAction, ReviewSavePendingAction, ReviewSaveRequest, ReviewSaveResponse, ReviewSaveSuccessAction, ReviewSaveErrorAction,
    AdminSafetyFeePendingAction, AdminSafetyFeeRequest, AdminSafetyFeeResponse, AdminSafetyFeeSuccessAction, AdminSafetyFeeErrorAction
} from './types';
/* Action Creators */
export const fetchSettingsPending = (login: SettingsRequest): FetchSettingsPendingAction => {
    return {
        type: SETTINGS.LOAD_PENDING,
        payload: login
    };
};

export const fetchSettingsSuccess = (loginResponse: SettingsResponse): FetchSettingsSuccessAction => {
    return {
        type: SETTINGS.LOAD_SUCCESS,
        settings: loginResponse
    }
}

export const fetchSettingsError = (err: any): FetchSettingsErrorAction => {
    return {
        type: SETTINGS.LOAD_ERROR,
        error: err
    }
}

export const settingsAddPending = (request: SettingsAddRequest): SettingsAddRequestPendingAction => {
    return {
        type: SETTINGS.ADD_PENDING,
        payload: request
    }
}

export const settingsAddSucess = (response: SettingsAddResponse): SettingsAddRequestSuccessAction => {
    return {
        type: SETTINGS.ADD_SUCCESS,
        settingsAdd: response
    }
}

export const settignsAddError = (err: any): SettingsAddRequestErrorAction => {
    return {
        type: SETTINGS.ADD_ERROR,
        error: err
    }
}

export const settingsRemovePending = (request: SettingsRemoveRequest): SettingsRemoveRequestPendingAction => {
    return {
        type: SETTINGS.REMOVE_PENDING,
        payload: request
    }
}

export const settingsRemoveSucess = (response: SettingsRemoveResponse): SettingsRemoveRequestSuccessAction => {
    return {
        type: SETTINGS.REMOVE_SUCCESS,
        settingsRemove: response
    }
}

export const settignsRemoveError = (err: any): SettingsRemoveRequestErrorAction => {
    return {
        type: SETTINGS.REMOVE_ERROR,
        error: err
    }
}

export const settingsNotificationChangePending = (request: SettingsRemoveRequest): SettingsNotificationChangeRequestPendingAction => {
    return {
        type: SETTINGS.CHANGE_PENDING,
        payload: request
    }
}

export const settingsNotificationChangeSucess = (response: SettingsRemoveResponse): SettingsNotificationChangeRequestSuccessAction => {
    return {
        type: SETTINGS.CHANGE_SUCCESS,
        settingsNotification: response
    }
}

export const settignsNotificationChangeError = (err: any): SettingsNotificationChangeRequestErrorAction => {
    return {
        type: SETTINGS.CHANGE_ERROR,
        error: err
    }
}

export const specialityActionPending = (request: SpecialityRequest): SpecialityActionPedingAction => {
    return {
        type: SETTINGS.SPECIALITY_ACTION_PENDING,
        payload: request
    }
}

export const specialityActionError = (err: any): SpecialityActionErrorAction => {
    return {
        type: SETTINGS.SPECIALITY_ACTION_ERROR,
        error: err
    }
}

export const specialityActionSuccess = (response: SpecialityResponse): SpecialityActionSuccessAction => {
    return {
        type: SETTINGS.SPECIALITY_ACTION_SUCCESS,
        specialityAction: response
    }
}
export const emailVerificationPendingAction = (request: EmailVerificationRequest): EmailVerificationPendingAction => {
    return {
        type: SETTINGS.EMAIL_VERIFICATION_PENDING,
        payload: request
    }
}
export const emailVerificationSuccessAction = (response: EmailVerificationResponse): EmailVerificationSuccessAction => {
    return {
        type: SETTINGS.EMAIL_VERIFICATION_SUCCESS,
        emailVerification: response
    }
}
export const emailVerificationErrorAction = (error: any): EmailVerificationErrorAction => {
    return {
        type: SETTINGS.EMAIL_VERIFICATION_ERROR,
        error: error
    }
}
export const acceptTCPendingAction = (request: AcceptTCRequest): AcceptTCPendingAction => {
    return {
        type: SETTINGS.ACCEPT_TC_PENDING,
        payload: request
    }
}
export const acceptTCSuccessAction = (response: AcceptTCResponse): AcceptTCSuccessAction => {
    return {
        type: SETTINGS.ACCEPT_TC_SUCCESS,
        acceptTC: response
    }
}
export const acceptTCErrorAction = (error: any): AcceptTCErrorAction => {
    return {
        type: SETTINGS.ACCEPT_TC_ERROR,
        error: error
    }
}

export const reviewSavePendingAction = (request: ReviewSaveRequest): ReviewSavePendingAction => {
    return {
        type: SETTINGS.REVIEW_SAVE_PENDING,
        payload: request
    }
}
export const reviewSaveSuccessAction = (response: ReviewSaveResponse): ReviewSaveSuccessAction => {
    return {
        type: SETTINGS.REVIEW_SAVE_SUCCESS,
        reviewSave: response
    }
}
export const reviewSaveErrorAction = (error: any): ReviewSaveErrorAction => {
    return {
        type: SETTINGS.REVIEW_SAVE_ERROR,
        error: error
    }
}

//AdminSafety
export const adminSafetyFeePendingAction = (request: AdminSafetyFeeRequest): AdminSafetyFeePendingAction => {
    return {
        type: SETTINGS.ADMIN_SAFETY_FEE_PENDING,
        payload: request
    }
}
export const adminSafetyFeeSuccessAction = (response: AdminSafetyFeeResponse): AdminSafetyFeeSuccessAction => {
    return {
        type: SETTINGS.ADMIN_SAFETY_FEE_SUCCESS,
        AdminSafetyFeeResponse: response
    }
}
export const adminSafetyFeeErrorAction = (error: any): AdminSafetyFeeErrorAction => {
    return {
        type: SETTINGS.ADMIN_SAFETY_FEE_ERROR,
        error: error
    }
}