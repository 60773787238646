import React, { useEffect, useState } from "react";
import configs from "../../configs/apiConfigs";
import firebase from "firebase";
import { getPatientList } from "../../services/patientsearch";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { mangoVoiceCall } from "../../services/voicecall";
const db = firebase.firestore();
let unsubscribe: any;
export const VoiceCallMango = (props: any) => {
    const [status, setStatus] = useState("");
    const [direction, setDirection] = useState("");
    const [sourceNumber, setSourceNumber] = useState("");
    const [patientList, setPatientList]:any = useState([]);
    const [showPatientListModal, setShowPatientListModal] = useState(false);
    const state = useSelector((state: IAppState) => state);
    const clinic: any = state.userData.userDataResponse.clinics[0];
    useEffect(() => {
        listenerForMango();
        return () => {
            if (unsubscribe) unsubscribe();
        }
    }, []);
    useEffect(() => {
        console.log(props);
        if (props.appointment) {
            const dependant:any = props.appointment.dependant;
            mangoVoiceCall(clinic.clinicSetting.mangoToken, dependant.phone, clinic.clinicSetting.mangoCallUser);
            setPatientList([{...dependant, dependant: dependant}]);
            setStatus("Calling");
        }
    }, props);
    const listenerForMango = () => {
        unsubscribe = db.collection(configs.MANGO_WEBHOOK_COLLECTION).where("x-mango-remote-id", "==", clinic?.clinicSetting["remote-id"])
            .orderBy("createdAt", "desc").onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        const data = change.doc.data();
                        if (data.payload.source_number_e164 === clinic.clinicSetting.mangoCell ||
                            data.payload.destination_number_e164 === clinic.clinicSetting.mangoCell) {
                                console.log(data, clinic);
                            if (!data.processed && (data.type === "line_extension.ringing" ||
                                data.type === "line_extension.answered" ||
                                data.type === "line_extension.hangup")) {
                                const docRef = db.collection(configs.MANGO_WEBHOOK_COLLECTION).doc(change.doc.id);
                                docRef.update({
                                    processed: true
                                });
                                if (data.payload.direction === "inbound") {
                                    setSourceNumber(data.payload.source_number_e164);
                                } else {
                                    setSourceNumber(data.payload.destination_number_e164);
                                }
                                setDirection(data.payload.direction);
                                setStatus(data.payload.state);
                                if (data.payload.direction === "inbound") {
                                    getPatients(data.payload.source_number);
                                } else {
                                    getPatients(data.payload.destination_number);
                                }
                                if (data.payload.state === "hangup") {
                                    setDirection(""); setStatus(""); setSourceNumber("");
                                    setShowPatientListModal(false);
                                    setPatientList([]);
                                    props.closeModal();
                                    document.getElementById("position-rel")?.classList.remove("call-start");
                                }
                            }
                        }
                    }
                })
            });
    }
    const getPatients = async (number: string) => {
        try {
            const results = await getPatientList({ fullname: number, page: 1, results: 30 });
            setPatientList(results.patients);
            // setShowPatientListModal(true);
        } catch (err) {
            console.log(err);
        }
    }
    console.log(patientList);
    if (status !== "") {
        return (
            <>
                <div className="call-strip">
                    <div className="d-flex justify-content-start">
                        {
                            patientList.slice(0, 2).map((patient: any, index:number) => {
                                const name = patient?.dependant.fname + " " + patient?.dependant.lname;
                                return (
                                    <>
                                    <div className="d-flex align-items-center" key={index}>
                                        <div className="avatar rounded-circle bg-white text-dark text-uppercase flex-shrink-0">
                                            {patient?.dependant.fname.charAt(0)}{patient?.dependant.lname.charAt(0)}
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <div className="d-block fw-medium p-name lh-base" onClick={() => {
                                                setShowPatientListModal(true);
                                            }}>
                                                {name}
                                            </div>
                                            <small className="d-block text-white lh-base">{direction}</small>
                                            <>
                                                {
                                                status == 'Calling' ?
                                                    <small className="d-block text-white lh-base">{status}</small>
                                                :
                                                    <small className="d-block text-white lh-base">
                                                        <i className="bi bi-telephone"></i>
                                                        {status}
                                                    </small>
                                                }
                                            </>
                                        </div>
                                    </div>
                                    {
                                        patientList.length == 2 && index == 0 &&
                                        <div className="v-line"></div>
                                    }
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
};
