import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { shallowEqual, useSelector } from 'react-redux';
import CONSTANT_LABELS from '../../../constants/labels';
import { IAppState } from '../../../store';
import { removeCountryCode } from '../../../utils/global';
import { pmsAppointments } from '../../services/pms';
import moment from "moment";
export const PMSAppointments = () => {
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState([]);

    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);

    const getPMSAppointments = () => {

        const request = {
            page: page,
            results: 30,
            clinicUniqueId: userData.clinics[0].uniqueId
        }
        setIsLoading(true);
        pmsAppointments(request).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                setRecords(success.appointments);
                if (page === 1) {
                    setTotalCount(success.total);
                }
            }
        }).catch((err) => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getPMSAppointments();
    }, [page]);

    const handlePageChange = (pageNumber: number) => {
        setPage(pageNumber);
    }

    return (
        <section className="middle-navbar px-30">
            <div className="p-0 container-fluid">
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="nav-title-txt">PMS appointments</div>
                    </div>
                </div>
            </div>
            <section className="pmslistview-sec px-3 pt-4">
                <div className="container">
                    <div className="row">
                        <div className="advances-table">
                            {
                                isLoading &&
                                <span className="spinner-border" role="status"></span>
                            }
                            {
                                !isLoading && records.length > 0 &&
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Patient</th>
                                            <th>Guarantor</th>
                                            <th>Provider</th>
                                            <th>Date & Time</th>
                                            <th>Cancelled</th>
                                            <th className="text-end">Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            records.map((item: any) => {
                                                return (<React.Fragment key={item.uniqueid}>
                                                    <tr>
                                                        <td>
                                                            <div><span className="font-weight-bold">Name</span> : {item?.patient?.fname} {item?.patient?.lname}</div>
                                                            <div><span className="font-weight-bold">Email</span> : {item?.patient?.email}</div>
                                                            <div><span className="font-weight-bold">Mobile#</span> : {item && item.patient && item.patient.cell && CONSTANT_LABELS.PLUS_ONE + removeCountryCode(item.patient.cell)}</div>
                                                            <div><span className="font-weight-bold">DOB</span> : {item?.patient?.dob}</div>
                                                        </td>
                                                        <td>
                                                            <div><span className="font-weight-bold">Name</span> : {item?.guarantor?.fname} {item?.guarantor?.lname}</div>
                                                            <div><span className="font-weight-bold">Email</span> : {item?.guarantor?.email}</div>
                                                            <div><span className="font-weight-bold">Mobile#</span> : {item && item.guarantor && item.guarantor.cell && CONSTANT_LABELS.PLUS_ONE + removeCountryCode(item.guarantor.cell)}</div>
                                                            <div><span className="font-weight-bold">DOB</span> : {item?.guarantor?.dob}</div>
                                                        </td>
                                                        <td>
                                                            {
                                                                (item.provider) ?
                                                                    <div>
                                                                        {item?.provider?.fname + " " + item?.provider?.lname} - {item?.provider?.sikkaId}
                                                                    </div> :
                                                                    <>{item.providerid}</>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                item.date && item.time &&
                                                                <div>
                                                                    <small>{moment(item.date + " " + item.time).format("MMM DD, YYYY")}</small><br />
                                                                    <small>{moment(item.date + " " + item.time).format("LT")}</small>
                                                                </div>
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                item.cancelled ?
                                                                    <div>{item.cancelled ? item.cancelled : "-"}</div>
                                                                    : <div>-</div>
                                                            }
                                                        </td>
                                                        <td className="text-end">
                                                            {
                                                                item.error.map((item: any) => <label className="text-danger">{item}</label>)
                                                            }
                                                        </td>
                                                    </tr>
                                                </React.Fragment>)
                                            })
                                        }

                                    </tbody>
                                </table>
                            }
                            <div className="d-flex justify-content-end">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    hideDisabled
                                    activePage={page}
                                    itemsCountPerPage={30}
                                    totalItemsCount={totalCount}
                                    onChange={(pageNumber) => { handlePageChange(pageNumber) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    )
}