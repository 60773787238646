export const CHAT: any = {
  SENDER: {
    PATIENT: "patient",
    CLINIC: "clinic",
    USER: "user",
    REMINDER: "reminder",
    ADMIN: "admin",
  },
  ACTION: {
    SMTC: "Sent message to clinic.",
    SMTP: "Sent message to patient.",
    CCP: "clinic called to patient.",
    PCC: "patient called to clinic.",
    PVBC: "Prescreening Verify by clinic.",
    PRBC: "Prescreening requested by clinic.",
    PRFC: "payment request from clinic",
    CBP: "Confirm by patient",
    CTP: "Call to patient",
  },
  ACTION_TYPE: {
    MESSAGE: "MESSAGE",
    STATUS: "STATUS",
    RESCHEDULE_SLOTS: "RESCHEDULE_SLOTS",
    PHONE_CALL: "PHONE_CALL",
    RECONFIRMATION_SMS: "RECONFIRMATION_SMS",
    PRESCREENING: "PRESCREENING",
    CHECKIN: "CHECKIN",
    PRESCREENING_STATUS: "PRESCREENING_STATUS",
    PRESCREENING_REQUEST: "PRESCREENING_REQUEST",
    PAYMENT_REQUEST: "PAYMENT_REQUEST",
    PAYMENT_SUCCESSFUL: "PAYMENT_SUCCESSFUL",
    APPONINTMENT_ASSIGN: "APPONINTMENT_ASSIGN",
    CONFIRM_BY_PATIENT: "CONFIRM_BY_PATIENT",
    CANCELLED_BY_PATIENT: "CANCELLED_BY_PATIENT",
    MESSAGE_CENTER_TEXT: "MESSAGE_CENTER_TEXT",
    MESSAGE_TEXT: "MESSATE_TEXT",
    SCHEDULE_REQUEST: "SCHEDULE_REQUEST",
    RESCHEDULE_REQUEST: "RESCHEDULE_REQUEST",
    VOICE_CALL: "VOICE_CALL",
    TRIAGE_EMERGENCY: "TRIAGE_EMERGENCY"
  },
  FROM_STATUS: {
    CREATED: "CREATED",
    REQUESTED: "REQUESTED",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    SCHEDULED: "SCHEDULED",
    WAITLIST: "WAITLIST"
  },
  TO_STATUS: {
    CREATED: "CREATED",
    REQUESTED: "REQUESTED",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    SCHEDULED: "SCHEDULED",
    WAITLIST: "WAITLIST"
  },
  CANCEL_REASON: [
    {
      id: 1,
      reason: "Prescreening rejected.",
    },
    {
      id: 2,
      reason: "Patient did not respond.",
    },
    {
      id: 3,
      reason: "Desired appointment time is not available.",
    },
    {
      id: 4,
      reason: "Patient no longer needs an appointment.",
    },
    {
      id: 5,
      reason: "Patient is unable to attend their appointment.",
    },
    {
      id: 6,
      reason: "Patient is out-of-network.",
    },
    {
      id: 7,
      reason: "Patient has no insurance.",
    },
    {
      id: 8,
      reason: "Patient wants direct billing.",
    },
    {
      id: 9,
      reason: "Patient cannot afford treatment.",
    },
    {
      id: 10,
      reason: "Patient only wants a free consultation.",
    },
    {
      id: 11,
      reason: "Patient is under the age of 18.",
    },
    {
      id: 12,
      reason: "Patient has visited our practice within the past 2 years.",
    },
    {
      id: 13,
      reason: "Patient is on social assistance",
    },
    {
      id: 14,
      reason: "Practice does not provide the requested service.",
    },
    {
      id: 15,
      reason: "This is a duplicate appointment request.",
    },
    {
      id: 16,
      reason: "Patient did not show up for their appointment.",
    },
    {
      id: 17,
      reason: "Patient re-booked appointment in the future"
    }
  ],
  SEND_MEDIUM: {
    SMS: "SMS",
    APP: "APP",
    CHAT: "CHAT",
  },
};
