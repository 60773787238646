import * as _ from 'lodash';
const initialState:any = {
   data:''
}

const appointmentPrefrenceReducer=(state=initialState,action:any):any=>{
    if(action.type==='APPOINTMENT_PREFRENCE'){
        return {
            ...state,
            data:action.payload
            // data:[...state.data,action.payload]
        }
    }
    return state;
}
export default appointmentPrefrenceReducer;