import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import configs from '../../../configs/apiConfigs';
import { uploadBanner, uploadLogo, uploadLogo1 } from '../../../services/clinicProfile';
export const TitleBox = ({ name, primarySpeciality, uniqueId, logo, provider, fetchProfile, type, plan,
  logo1, banner
 }: any) => {
  const uploadRef: any = useRef();
  const uploadLogo1Ref: any = useRef();
  const uploadBannerRef: any = useRef();
  const [imageError, setImageError] = useState(false);
  const history = useHistory();
  const onChangeFile = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "image/png" ||
        file.type === 'image/jpg' ||
        file.type == 'image/jpeg') {
        var reader: any = new FileReader();
        var url: any = reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = function (e: any) {
          //Initiate the JavaScript Image object.
          var image = new Image();
          //Set the Base64 string return from FileReader as source.
          image.src = e.target.result;
          //Validate the File Height and Width.
          image.onload = function () {
            var height = image.height;
            var width = image.width;
            if (height < 256 || width < 256) {
              setImageError(true);
              toast.error("Profile image should be of minimum WIDTHxHEIGHT i.e. 256x256");
              return false;
            }
            setImageError(false);
            uploadLogo(uniqueId, { type: 'upload', uphoto: file }).then((success) => {
              if (success && success.status && success.status.error === false) {
                fetchProfile('logo');
                return;
              }
            })
            return true;
          };
        };
        return;
      } else {
        toast.error("Image valid extensions are png, jpg and jpeg");
        setImageError(true);
      }
    }
  }
  const onChangeLogo1File = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "image/png" ||
        file.type === 'image/jpg' ||
        file.type == 'image/jpeg') {
        var reader: any = new FileReader();
        var url: any = reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = function (e: any) {
          //Initiate the JavaScript Image object.
          var image = new Image();
          //Set the Base64 string return from FileReader as source.
          image.src = e.target.result;
          //Validate the File Height and Width.
          image.onload = function () {
            var height = image.height;
            var width = image.width;
            // if (height < 200 || width < 320) {
            //   setImageError(true);
            //   toast.error("Logo image should be of minimum WIDTHxHEIGHT i.e. 320x200");
            //   return false;
            // }
            setImageError(false);
            uploadLogo1(uniqueId, { type: 'upload', uphoto: file }).then((success) => {
              if (success && success.status && success.status.error === false) {
                fetchProfile('logo');
                return;
              }
            })
            return true;
          };
        };
        return;
      } else {
        toast.error("Image valid extensions are png, jpg and jpeg");
        setImageError(true);
      }
    }
  }
  const onChangeBannerFile = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "image/png" ||
        file.type === 'image/jpg' ||
        file.type == 'image/jpeg') {
        var reader: any = new FileReader();
        var url: any = reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = function (e: any) {
          //Initiate the JavaScript Image object.
          var image = new Image();
          //Set the Base64 string return from FileReader as source.
          image.src = e.target.result;
          //Validate the File Height and Width.
          image.onload = function () {
            var height = image.height;
            var width = image.width;
            // if (height < 200 || width < 320) {
            //   setImageError(true);
            //   toast.error("Banner image should be of minimum WIDTHxHEIGHT i.e. 320x200");
            //   return false;
            // }
            setImageError(false);
            uploadBanner(uniqueId, { type: 'upload', uphoto: file }).then((success) => {
              if (success && success.status && success.status.error === false) {
                fetchProfile('logo');
                return;
              }
            })
            return true;
          };
        };
        return;
      } else {
        toast.error("Image valid extensions are png, jpg and jpeg");
        setImageError(true);
      }
    }
  }
  return (
    <div className="practice-detail-sec mb-4">
      <div className="card bg-light p-4 py-3">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="position-relative">
              {
                (logo && logo.name && logo.type) ?
                  <>
                    <img src={configs.IMAGE_URL + 'clinic/' + logo.name + '-200x200.' + logo.type} className="img-fluid border rounded-3" style={{ width: '80px', height: '80px' }} />
                    <span className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger p-1" onClick={() => { uploadRef.current.click() }}>
                      <i className="bi bi-pencil font-size-12"></i>
                    </span>
                  </> :
                  <>
                  <div style={{
                    width: 80,
                    height: 80,
                    backgroundColor: "gray",
                    opacity: 0.2
                  }}></div>
                  <span className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger p-1" onClick={() => { uploadRef.current.click() }}>
                    <i className="bi bi-pencil font-size-12"></i>
                  </span>
                  </>
              }
              <input id="myInput" accept="image/png,image/gif,image/jpeg" multiple={false} type="file" ref={uploadRef}
                style={{ display: 'none' }} onChange={onChangeFile} />
            </div>
            <div className="position-relative ms-4">
              {
                (logo1 && logo1?.name && logo1?.type) ?
                  <>
                    <img src={configs.IMAGE_URL + 'clinic/' + logo1?.name + '-original.' + logo1?.type} className="img-fluid border rounded-3" style={{ width: '160px', height: '80px' }} />
                    <span className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger p-1" onClick={() => { uploadLogo1Ref.current.click() }}>
                      <i className="bi bi-pencil font-size-12"></i>
                    </span>
                  </> :
                  <>
                    <div style={{
                      width: 160,
                      height: 80,
                      backgroundColor: "gray",
                      opacity: 0.2
                    }}></div>
                    <span className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger p-1" onClick={() => { uploadLogo1Ref.current.click() }}>
                      <i className="bi bi-pencil font-size-12"></i>
                    </span>
                  </>
              }
              <input id="myInput" accept="image/png,image/gif,image/jpeg" multiple={false} type="file" ref={uploadLogo1Ref}
                style={{ display: 'none' }} onChange={onChangeLogo1File} />
            </div>
            <div className="position-relative ms-4">
              {
                (banner && banner?.name && banner?.type) ?
                  <>
                    <img src={configs.IMAGE_URL + 'clinic/' + banner?.name + '-original.' + banner?.type} className="img-fluid border rounded-3" style={{ width: '160px', height: '80px' }} />
                    <span className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger p-1" onClick={() => { uploadBannerRef.current.click() }}>
                      <i className="bi bi-pencil font-size-12"></i>
                    </span>
                  </> :
                  <>
                    <div style={{
                        width: 160,
                        height: 80,
                        backgroundColor: "gray",
                        opacity: 0.2
                    }}></div>
                    <span className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger p-1" onClick={() => { uploadBannerRef.current.click() }}>
                      <i className="bi bi-pencil font-size-12"></i>
                    </span>
                  </>
              }
              <input id="myInput" accept="image/png,image/gif,image/jpeg" multiple={false} type="file" ref={uploadBannerRef}
                style={{ display: 'none' }} onChange={onChangeBannerFile} />
            </div>
            <div className="mx-4 align-self-center">{name}<small className="text-muted d-block">Clinic ID - {uniqueId}</small></div>
            <div className="mx-4 align-self-center"><small className="text-muted d-block">Type</small>{type}</div>
            <div className="mx-4 align-self-center"><small className="text-muted d-block">Plan</small>{plan}</div>
          </div>
          {
            provider.length === 0 ?
              <div className="stp-action">
                <button type="button" className="btn btn-outline-primary" onClick={() => {
                  history.push('/doctor/create');
                }}>
                  <i className="bi bi-plus"></i><span> Add new provider</span>
                </button>
              </div>
              :
              <div className="d-flex providers-details">
                <span className="align-self-center me-4">Providers</span>
                <div className="align-self-center">
                  <div className="avatar-group">
                    {
                      provider.splice(0, 3).map((item: any, index: number) => {
                        return (
                          <a href="#" className="avatar rounded-circle">
                            {
                              (item.photo && item.photo.name && item.photo.type) ?
                                <img alt="Image placeholder" src={configs.IMAGE_URL + 'doctor/' + item.photo.name + '-200x200.' + item.photo.type} />
                                :
                                <div className="avatar avatar-lg border rounded-circle me-3 text-uppercase">
                                  {
                                    (item && item.name) &&
                                    <>
                                      {(item.name.split(" ")[0] && item.name.split(" ")[0].charAt(0))}
                                      {(item.name.split(" ")[1] && item.name.split(" ")[1].charAt(0))}
                                    </>
                                  }
                                </div>
                            }
                          </a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  )
}