import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router';
import { IAppState } from '../../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { NotificationHeaderFollowup } from '../../../components/messageCenter/NotificationHeaderFollowup';
import { getNotificationTypeList } from '../../../services/messageCenter';
import { NotificationPatients } from './NotificationPatients';
import { Reminder } from './Reminder';
export const Notification = () => {
  const match = useRouteMatch();
  const [isAuthorize, setAuthorize] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const userDataResponse = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  useEffect(() => {
    dispatch(fetchLoadingPending());
    getNotificationTypeList(userDataResponse.clinics[0].id).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      if (success.error == false) {
        setAuthorize(true);
        setIsLoaded(true);
      }
    }).catch((err) => {
      dispatch(fetchLoadingSuccess(false));
      setAuthorize(false);
      setIsLoaded(true);
    })
  }, []);
  return (
    <>
      <NotificationHeaderFollowup></NotificationHeaderFollowup>
      {
        isAuthorize && isLoaded &&
        <Switch>
          <Route path={match.url + '/patients'} component={NotificationPatients}></Route>
          <Route path={match.url + '/reminder'} component={Reminder}></Route>
        </Switch>
      }
      {
        !isAuthorize && isLoaded &&
        <div className="overlay-center">
          <div className="vertical-center">

            <div className="card">
              <div className="card-body text-center p-5">
                <div className="mb-3">Please contact admin to access reminder. You haven't subscribed to reminder section on message center.</div>
              </div>
            </div>

          </div>
        </div>
      }
    </>
  );
}