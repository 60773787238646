import React, { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import { updateProviderProfile } from '../../services/provider';
export const Description = ({ providerDescription, providerId, fetchProfile }: any) => {
    const [description, setDescription] = useState(RichTextEditor.createValueFromString(providerDescription ? providerDescription : '', 'html'));
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onCancel = () => {
        setDescription(RichTextEditor.createValueFromString(providerDescription ? providerDescription : '', 'html'));
        setIsEdit(false);
    };
    const onUpdate = () => {
        if (!description.getEditorState().getCurrentContent().hasText()) {
            return;
        } else {
            setIsLoading(true);
            updateProviderProfile('description', providerId, description.toString('html')).then((success) => {
                if (success && success.status && success.status.error === false) {
                    fetchProfile('description').then((success: any) => {
                        setIsEdit(false);
                        setTimeout(() => {
                            setIsLoading(false);
                            setIsEdit(false);
                        }, 2000);
                    });
                }
            }).catch((err) => { setIsLoading(false); setIsEdit(false); });
        }
    }
    return (
        <div className="practice-description-sec mb-4">
            <div className="card">
                <div className="card-header bg-light py-3 px-4">
                    <div className="d-flex justify-content-between">
                        <div className="fw-medium">Profile description</div>
                        <div>
                            {
                                isEdit && isLoading &&
                                <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
                            }
                            {
                                isLoading && !isEdit &&
                                <i className="bi bi-check-circle-fill text-success"></i>
                            }
                            {
                                isEdit && !isLoading &&
                                <>
                                    <button className="btn p-0 btn-link me-2 text-secondary" onClick={() => {
                                        onCancel();
                                    }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                    <button className="btn p-0 btn-link" onClick={() => {
                                        onUpdate();
                                    }}><i className="bi bi-check-circle-fill text-success"></i></button>
                                </>
                            }
                            {
                                !isEdit && !isLoading &&
                                <button className="btn p-0 btn-link" onClick={() => {
                                    setIsEdit(true);
                                }}>Edit</button>
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    <div className="editor-box">
                        <RichTextEditor
                            disabled={!isEdit}
                            value={description}
                            onChange={setDescription}
                        />
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                        <div className="align-self-center">
                            <small className="d-block text-muted">This bio will help new patients find your clinic on google.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}