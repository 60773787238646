import React, { useState } from 'react'
import { DialModal } from '../../components/dial/DialModal';
import DialPadIcon from '../../assets/images/dial-pad.png';
export const DialIcon = () => {
    const [isDial, setIsDial] = useState(false);
    return (
        <>
            <div className="dialpad_icon" onClick={() => { setIsDial(!isDial) }}>
                <img src={DialPadIcon} />
            </div>
            {
                (isDial) &&
                <DialModal hideModal={() => { setIsDial(false) }} />
            }
        </>
    )
}
