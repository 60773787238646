import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { fetchSettingsPending, reviewSavePendingAction } from '../../../store/settings/actions';
import { Controller, useForm } from 'react-hook-form';
import { reviewEdit } from '../../services/settings';
import { toast } from 'react-toastify';
import { AdminList } from '../../components/practice/configuration/AdminList';
export const Configuration = () => {
    const dispatch = useDispatch();
    const [isEdit, setIsEdit]: any = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const settingData = useSelector((state: IAppState) => state.settings.settingsResponse, shallowEqual);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            xcarereview: false,
            googlereview: false,
            apiKey: ''
        }
    });
    useEffect(() => {
        dispatch(fetchSettingsPending({
            type: 'clinic', uniqueId: userData.clinics[0].uniqueId
        }));
    }, []);
    useEffect(() => {
        setIsLoading(false);
        if (settingData && settingData.metadata && settingData.metadata.reviewOn) {
            setValue('xcarereview', settingData.metadata.reviewOn.includes("XCAREPRO"));
            setValue('googlereview', settingData.metadata.reviewOn.includes("GOOGLE"));
        }
        if (settingData && settingData.metadata && settingData.metadata.trustApiKey) {
            setValue('apiKey', settingData.metadata.trustApiKey);
        }
    }, [settingData]);
    useEffect(() => {
        if (settingData && settingData.metadata && settingData.metadata.trustApiKey) {
            setValue('apiKey', settingData.metadata.trustApiKey);
        }
    }, [watch('googlereview')]);

    const settingUpdate = (data: any) => {
        let reviews = [];
        if (getValues('xcarereview')) {
            reviews.push("XCAREPRO");
        }
        if (getValues('googlereview')) {
            reviews.push("GOOGLE");
        }
        let request: any = {
            reviewOn: reviews,
            trustApiKey: getValues('apiKey')
        }
        setIsLoading(true);
        reviewEdit(request, userData.clinics[0].uniqueId).then((success) => {
            console.log('success', success);
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                dispatch(fetchSettingsPending({
                    type: 'clinic', uniqueId: userData.clinics[0].uniqueId
                }));
                setIsEdit(false);
            } else if (success && success.status && success.status.error === true) {
                toast.error(success.status.msg);
                setIsLoading(false);
            }
        }).catch((err) => {
            setIsLoading(false);
            if (err.error === true) {
                toast.error(err.msg);
            }
            console.log(err);
        });
    }
    const handleCancel = () => {
        if (settingData && settingData.metadata && settingData.metadata.reviewOn) {
            setValue('xcarereview', settingData.metadata.reviewOn.includes("XCAREPRO"));
            setValue('googlereview', settingData.metadata.reviewOn.includes("GOOGLE"));
        }
        if (settingData && settingData.metadata && settingData.metadata.trustApiKey) {
            setValue('apiKey', settingData.metadata.trustApiKey);
        }
        setIsEdit(false);
    }
    let showReviewRating = false;
    if (userData?.accessEnabled?.trust_horizon) {
        showReviewRating = true;
    }
    console.log(watch('googlereview'), watch('apiKey') === '')
    return (
        <section className="practice-seod-sec px-3 pt-4">
            <div className="container">
                <div className="d-flex justify-content-between border-bottom pb-2 mb-3">
                    <h4 className="card-title">Review & Rating</h4>
                    {
                        (showReviewRating === false) &&
                        <>Please take subscription for reviews and ratings.</>
                    }
                    <div className={" " + (showReviewRating ? '' : 'd-none')}>
                        {
                            !isEdit && !isLoading &&
                            <button className="btn btn-outline-primary btn-sm ms-2" onClick={(e) => {
                                setIsEdit(true)
                            }}>Edit</button>
                        }
                        {
                            isEdit && !isLoading &&
                            <>
                                <button className="btn btn-outline-primary btn-sm ms-2" onClick={(e) => { handleCancel() }}>Cancel</button>
                            </>
                        }
                    </div>
                </div>
                <div className={"card " + (showReviewRating ? '' : 'd-none')}>
                    <div className="card-body p-0">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" disabled={!isEdit} id="xcarereview" name="xcarereview" ref={register} />
                                        <label className="form-check-label" htmlFor="xcarereview">XCarePro Review</label>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="d-flex align-items-center">

                                    <div className="form-check flex-shrink-0">
                                        <input className="form-check-input" type="checkbox" disabled={!isEdit} id="googlereview" name="googlereview" ref={register} />
                                        <label className="form-check-label" htmlFor="googlereview">Google Review</label>
                                    </div>
                                    <div className="ms-5 d-flex w-100 align-items-center">
                                        <label className="me-2">Enter your trust horizon api key</label>
                                        <input type="text" style={{ maxWidth: '400px' }} disabled={!isEdit} className="form-control" id="apiKey" name="apiKey" ref={register({ required: true })} />
                                    </div>
                                    {
                                        (errors && errors.apiKey && errors.apiKey.type === 'required') &&
                                        <div className="text-danger">Trust horizon api key is required</div>
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                    {
                        isEdit && !isLoading &&
                        <div className="card-footer">
                            <div className="bottom-btn-sec">
                                <button type="button" className="btn btn-default btn-sm" onClick={(e) => {
                                    handleCancel();
                                }}>Cancel</button>
                                <button type="submit" className="btn btn-primary btn-sm"
                                    disabled={isLoading && (watch('googlereview') === true && watch('apiKey') === '')}
                                    onClick={handleSubmit(settingUpdate)}
                                >Update</button>
                            </div>
                        </div>
                    }
                    {
                        isEdit && isLoading &&
                        <div className="bottom-btn-sec">
                            <p>Updating</p>
                        </div>
                    }
                </div>
                {
                    userData.userAccess === "1" &&
                    <AdminList />
                }
            </div>
        </section >
    )
}
