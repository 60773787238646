// import './_practiceSteps.scss';
import React, { useEffect, useState } from 'react';
import {
	KeyboardTimePicker
} from '@material-ui/pickers';
import moment from 'moment';
import { createStep } from '../../services/clinicOnboard';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { toast } from 'react-toastify';
export function PracticeApp4(props: any) {
	const Days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
	const [selectedDays, setSelectedDays]: any = useState([]);
	const dispatch = useDispatch();
	let toCloseTimePicker = false;
	const [hours, setHours] = useState([
		{
			id: 'MON',
			active: false,
			label: 'Monday',
			startHour: '07:00 AM',
			endHour: '07:00 PM'
		},
		{
			id: 'TUE',
			active: false,
			label: 'Tuesday',
			startHour: '07:00 AM',
			endHour: '07:00 PM'
		},
		{
			id: 'WED',
			active: false,
			label: 'Wednesday',
			startHour: '07:00 AM',
			endHour: '07:00 PM'
		},
		{
			id: 'THU',
			active: false,
			label: 'Thursday',
			startHour: '07:00 AM',
			endHour: '07:00 PM'
		},
		{
			id: 'FRI',
			active: false,
			label: 'Friday',
			startHour: '07:00 AM',
			endHour: '07:00 PM'
		},
		{
			id: 'SAT',
			active: false,
			label: 'Saturday',
			startHour: '07:00 AM',
			endHour: '07:00 PM'
		},
		{
			id: 'SUN',
			active: false,
			label: 'Sunday',
			startHour: 'Closed',
			endHour: 'Closed'
		}
	]);
	const handleDateChange = (date: any, hIndex: number, index: number) => {
		if (hIndex === 0) {
			const mappedHours = hours.map((hItem, hIndex) => {
				if (hIndex === index) {
					toCloseTimePicker = true;
					return {
						...hItem,
						openStart: false,
						startHour: moment(date).format("LT"),
						startShow: date,
						endHour: moment(date).format("LT"),
						endShow: date
					}
				}
				return hItem;
			});
			setHours(mappedHours);
		} else {
			let selectedDay: any = hours.find((item: any, hIndex: number) => hIndex === index);
			if (moment(selectedDay.startHour, 'LT').isAfter(moment(moment(date).format('LT'), 'LT'))) {
				toast.warn('End time shoud be next to start time');
				return;
			}
			const mappedHours = hours.map((hItem, hIndex) => {
				if (hIndex === index) {
					toCloseTimePicker = true;
					return {
						...hItem,
						openEnd: false,
						endHour: moment(date).format("LT"),
						endShow: date,
					}
				}
				return hItem;
			});
			setHours(mappedHours);
		}
	};
	const onNext = () => {
		const request = hours;
		const requestData = {
			hours: JSON.stringify(hours.map((item) => {
				if (selectedDays.find((sDay: any) => sDay === item.id)) {
					return {
						...item
					}
				}
				return {
					...item,
					startHour: 'Closed',
					endHour: 'Closed'
				}
			})),
			uniqueid: props.clinicUniqueId
		};
		dispatch(fetchLoadingPending());
		createStep({
			stepNumber: 4,
			formData: requestData
		}).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				props.onNext(4, { hours: request });
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});
	}
	useEffect(() => {
		if (props.data) {
			const filtered = props.data.filter((item: any) => item.active);
			setSelectedDays(filtered.map((item: any) => item.id));
			setHours(props.data);
		}
	}, [props.data]);
	return (

		<>
			<div className="row justify-content-center">

				<div className="col-12">
					<h2 className="mb-3">Open hours</h2>
					<div className="fw-medium fs-5">What hours are you open ?</div>
					<p className="mt-1">Please set the office hours to let patients know when you're open.</p>
					<hr className="my-4" />
				</div>

				<div className="col-12">
					{/* <p>What are we trying to say here? </p>	 */}
					<ul className="d-flex days-checklist">
						{
							Days.map((item) => {
								return (
									<li className="form-check" key={item}>
										<input className="form-check-input" type="checkbox" value={item}
											id={item} checked={selectedDays.find((day: any) => day === item) !== undefined}
											onChange={(e) => {
												const dayIndex = Days.findIndex((day: any) => day === item);
												if (selectedDays.find((day: any) => day === item) !== undefined) {
													setSelectedDays(selectedDays.filter((day: any) => day !== item));
													setHours(hours.map((item, index) => {
														if (dayIndex === index) {
															return {
																...item,
																startHour: '07:00 AM',
																endHour: '07:00 PM',
																active: false
															}
														}
														return item;
													}))
												} else {
													setSelectedDays([...selectedDays, item]);
													setHours(hours.map((item, index) => {
														if (dayIndex === index) {
															return {
																...item,
																active: true
															}
														}
														return item;
													}))
												}
											}}
										/>
										<label className="form-check-label rounded-2 border w-100" htmlFor={item}>
											{item}
										</label>
									</li>
								)
							})
						}
					</ul>
				</div>

				<div className="col-12">
					<form className="open-hours-info mt-md-5  mb-4">
						{
							hours.map((hour: any, index: number) => {
								if (hour.active === true) {
									return (
										<div className="d-md-flex d-block hours-box my-md-3 my-4" key={index + hour.id}>
											<label className="day-title fw-normal align-self-center">{hour.label}</label>
											<div className="input-group flex-nowrap">
												<input type="text" className="form-control border-end-0" placeholder="Open"
													aria-label="Recipient's username" aria-describedby="basic-addon2" value={hour.startHour} disabled />
												<span className="input-group-text bg-white" id="basic-addon2">
													{
														hour.startHour !== 'Closed' &&
														<i className="bi bi-x me-2 cursor-pointer" onClick={() => {
															setHours(hours.map((hItem, hIndex) => {
																if (index === hIndex) {
																	return {
																		...hItem,
																		startHour: 'Closed',
																		endHour: 'Closed',
																	}
																}
																return hItem;
															}));
														}}></i>
													}
													<i className="bi bi-clock cursor-pointer" onClick={() => {
														setHours(hours.map((hItem, hIndex) => {
															if (hIndex === index) {
																return {
																	...hItem,
																	openStart: true
																}
															}
															return {
																...hItem,
																openStart: false
															}
														}));
													}}></i>
													<KeyboardTimePicker
														margin="normal"
														id="time-picker"
														label="Time picker"
														value={hour.startShow}
														onChange={(date) => { handleDateChange(date, 0, index) }}
														TextFieldComponent={(props) => { return null }}
														open={hour.openStart ? true : false}
														KeyboardButtonProps={{
															'aria-label': 'change time',
														}}
														onClose={() => {
															if (!toCloseTimePicker) {
																setTimeout(() => {
																	setHours(hours.map((hItem: any, hIndex: number) => {
																		if (hIndex === index) {
																			return {
																				...hItem,
																				openStart: false
																			}
																		}
																		return hItem;
																	}));
																}, 1000);
																toCloseTimePicker = true;
															}
														}}
													/>
												</span>
											</div>
											<div className="mx-4 align-self-center text-muted text-center">To</div>
											<div className="input-group flex-nowrap">
												<input type="text" className="form-control border-end-0" placeholder="Closed" value={hour.endHour} disabled />
												<span className="input-group-text bg-white">
													{
														hour.endHour !== 'Closed' &&
														<i className="bi bi-x me-2 cursor-pointer" onClick={() => {
															setHours(hours.map((hItem, hIndex) => {
																if (index === hIndex) {
																	return {
																		...hItem,
																		startHour: 'Closed',
																		endHour: 'Closed',
																	}
																}
																return hItem;
															}));
														}}></i>
													}
													<i className="bi bi-clock cursor-pointer" onClick={() => {
														setHours(hours.map((hItem, hIndex) => {
															if (hIndex === index) {
																return {
																	...hItem,
																	openEnd: true
																}
															}
															return {
																...hItem,
																openEnd: false
															}
														}));
													}}></i>
													<KeyboardTimePicker
														margin="normal"
														id="time-picker"
														label="Time picker"
														value={hour.endShow}
														onChange={(date) => { handleDateChange(date, 1, index) }}
														TextFieldComponent={(props) => { return null }}
														open={hour.openEnd ? true : false}
														KeyboardButtonProps={{
															'aria-label': 'change time',
														}}
														onClose={() => {
															if (!toCloseTimePicker) {
																setTimeout(() => {
																	setHours(hours.map((hItem: any, hIndex: number) => {
																		if (hIndex === index) {
																			return {
																				...hItem,
																				openEnd: false
																			}
																		}
																		return hItem;
																	}));
																}, 1000);
																toCloseTimePicker = true;
															}
														}}
													/>
												</span>
											</div>
										</div>
									)
								}
								return null;
							})
						}
					</form>
				</div>
				<div className="col-12 my-4">
					<div className="bottom-btn-sec d-flex justify-content-between">
						<button className="btn border me-1" onClick={() => { props.onPrev(2) }}>Back</button>
						<button className="btn btn-primary ms-1" disabled={hours.find(item => item.active) === undefined} onClick={() => { onNext(); }}>Next</button>
					</div>
				</div>
			</div>
		</>
	);
}
