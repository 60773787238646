// import './_practiceSteps.scss';
import React, { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import CreatableSelect from 'react-select/creatable';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';

import { getQuestionsList, createStep } from '../../services/providerOnboard';

export function ProviderApp5(props: any) {

	const [practiceDescription, setpracticeDescription] = useState(RichTextEditor.createEmptyValue());
	const [practiceQuestions, setPracticeQuestions]: any = useState([]);
	const [seoTitle, setSEQTitle] = useState('');
	const [metaDescription, setMetaDescription] = useState('');
	const [practiceDescriptionValidation, setPracticeDescriptionValidation] = useState({ invalid: false, msg: '' });
	const dispatch = useDispatch();
	const components = {
		DropdownIndicator: null,
	};
	const [inputValue, setInputValue]: any = useState('');
	const [value, setValue]: any = useState('');

	useEffect(() => {
		dispatch(fetchLoadingPending());
		getQuestionsList().then((success: any) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				if (props.data && props.data.question.length > 0) {
					setPracticeQuestions(success.questions.map((item: any) => {
						const found = props.data.questions.find((qItem: any) => qItem.id === item.id);
						if (found) {
							return {
								...item,
								response: found.response
							};
						}
						return { ...item, response: "", error: false };
					}));
				}
				else {
					setPracticeQuestions(success.questions.map((item: any) => ({ ...item, response: "", error: false })));
				}
			}
		}).catch((err: any) => {
			dispatch(fetchLoadingSuccess(false));
		})
	}, []);
	const handleChange = (value: any, actionMeta: any) => {
		setValue(value);
	};
	const createOption = (label: string) => ({
		label,
		value: label,
	});
	const handleInputChange = (inputValue: string) => {
		setInputValue(inputValue);
	};
	const handleKeyDown = (event: any) => {
		if (!inputValue) return;
		switch (event.key) {
			case 'Enter':
			case 'Tab':
				setInputValue('');
				setValue([...value, createOption(inputValue)]);
				event.preventDefault();
			default:
				return;
		}
	};

	const onNext = (skip: boolean) => {
		if (skip === true) {
			props.nextStep(5, { profileDescription: null });
			props.setPercentage(80);
			return;
		}
		if (!practiceDescription.getEditorState().getCurrentContent().hasText()) {
			setPracticeDescriptionValidation({ invalid: true, msg: 'Required' });
			return;
		}
		setPracticeDescriptionValidation({ invalid: false, msg: '' });
		if (practiceQuestions.find((item: any) => item.response === '')) {
			setPracticeQuestions(practiceQuestions.map((item: any) => {
				if (item.response === '') {
					return {
						...item,
						error: true
					}
				} else {
					return { ...item, error: false };
				}
			}));
			return;
		}
		const request = {
			practiceDescription: practiceDescription.toString('html'),
			questions: practiceQuestions.map((item: any) => {
				delete item.error;
				return item;
			}),
			seoTitle: seoTitle,
			metaDescription: metaDescription,
			targetKeywords: value
		};
		// props.nextStep(5, { profileDescription: request });
		const requestBody = {
			description: practiceDescription.toString('html'),
			questionResponse: JSON.stringify(practiceQuestions.map((item: any) => {
				delete item.error;
				return item;
			})),
			metaTitle: seoTitle,
			metaDesc: metaDescription,
			metaKeywords: value ? value.map((item: any) => item.label).toString() : '',
			uniqueid: props.request.clinicUniqueId,
			providerId: props.request.providerId
		};
		dispatch(fetchLoadingPending());
		createStep({ stepNumber: 5, formData: requestBody }).then((success: any) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				props.nextStep(5, { profileDescription: request });
				props.setPercentage(80);
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});
	}
	useEffect(() => {
		if (props.request && props.request.profileDescription) {
			setpracticeDescription(RichTextEditor.createValueFromString(props.request.profileDescription.practiceDescription, 'html'));
			setPracticeQuestions(props.request.profileDescription.questions.map((item: any) => ({ ...item, error: false })));
			setSEQTitle(props.request.profileDescription.seoTitle);
			setMetaDescription(props.request.profileDescription.metaDescription);
			setValue(props.request.profileDescription.targetKeywords);
		}
	}, [props.data]);
	return (
		<>
			<div className="row">
				<div className="col-12">
					<h2 className="mb-3">Discoverablity score</h2>
					<div className="fw-medium fs-5">Profile description </div>
					<p className="mt-1">Please ensure that all of the following information is correct. We will use this profile for your listing and for marketing purposes.</p>
					<hr className="my-4" />
				</div>
			</div>
			<div className="row">
				<div className="col-12 mb-4">
					<label htmlFor="PracticeDescription" className="form-label">Profile Bio <span className="text-danger">*</span></label>
					<div className="editor-box form-control">
						<RichTextEditor
							value={practiceDescription}
							onChange={setpracticeDescription}
						/>
					</div>
					{
						practiceDescriptionValidation.invalid &&
						<div className="is-invalid">{practiceDescriptionValidation.msg}</div>
					}
					<small className="text-muted">This bio will help new patients find your clinic on google.</small>
				</div>
				{
					practiceQuestions.map((item: any, index: number) => {
						return (
							<div className="col-12 mb-4" key={item.id + index}>
								<label htmlFor={"Q" + (index + 1)} className="form-label">{item.title} <span className="text-danger">*</span></label>
								<textarea className="form-control" id={"Q" + (index + 1)} rows={3} value={item.response} onChange={(e) => {
									setPracticeQuestions(practiceQuestions.map((qItem: any) => {
										if (item.id === qItem.id) {
											return {
												...qItem,
												response: e.target.value
											}
										}
										return qItem
									}))
								}}></textarea>
								{
									item.error &&
									<div className="is-invalid">
										Required
										</div>
								}
							</div>
						)
					})
				}
				<div className="col-12">
					<div className="meta-data-sec mt-4">
						<div className="fw-bold fs-5">META data </div>
						{/* <p>Please set the office hours to let patients know when you're open</p> */}
						<hr />
						<div className="tdk-box mb-4">
							<label htmlFor="seoTitle" className="form-label">SEO title</label>
							<input className="form-control text-muted" id="seoTitle" type="text"
								value={seoTitle} onChange={(e) => { setSEQTitle(e.target.value) }} />
							<small className="text-muted">Your business must be listed on google maps.</small>
						</div>
						<div className="tdk-box mb-4">
							<label htmlFor="metaDescription" className="form-label">Meta description</label>
							<textarea className="form-control text-muted" id="metaDescription" rows={3}
								value={metaDescription} onChange={(e) => { setMetaDescription(e.target.value) }}
							/>
							<small className="text-muted">Your business must be listed on google maps.</small>
						</div>
						<div className="tdk-box mb-4">
							<label className="form-label">Target keyword</label>
							<CreatableSelect
								components={components}
								inputValue={inputValue}
								isClearable
								isMulti
								menuIsOpen={false}
								onChange={handleChange}
								onInputChange={handleInputChange}
								onKeyDown={handleKeyDown}
								placeholder="Keywords"
								value={value}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 my-4">
					<div className="bottom-btn-sec d-flex justify-content-between">
						<div>
						<button className="btn border me-1" onClick={() => { props.onPrev(3) }}>Back</button>
						</div>
						<div>
						<button className="btn btn-primary me-2" onClick={() => { onNext(true) }}>Skip</button>
						<button className="btn btn-primary ms-1" onClick={() => { onNext(false) }}>Next</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
