import React, { useEffect, useState } from 'react';
import { AvatarComp } from '../Avatar';
import configs from '../../../configs/apiConfigs';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { IAppState } from '../../../../store';
import { fetchLoadingPending } from '../../../../store/loadingIndicator/actions';
export const ChatDependent = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [unReadCount, setUnReadCount] = useState(0);
    const [lastMessage, setLastMessage] = useState('');
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const dispatch = useDispatch();
    useEffect(() => {
        getLastMessage();
        getUnReadCount();
    }, []);

    useEffect(() => {
        getUnReadCount();
    }, [props.selectedDependent]);

    const getLastMessage = () => {
        try {
            firebase.firestore().collection(configs.CONVERSATION_DEV)
                .where('clinicId', '==', Number(userData.clinics[0].uniqueId))
                .where('dependentUniqueId', '==', Number(props.dependent.uniqueid))
                .orderBy('createdAt', 'asc')
                .onSnapshot((querySnapshot: any) => {
                    if (querySnapshot.docs.reverse()[0]) {
                        const pLastMessage = querySnapshot.docs.reverse()[0].data();
                        setLastMessage(pLastMessage.message);
                    }
                });
        } catch (error) {
        }
    }

    const getUnReadCount = () => {
        try {
            // setIsLoading(true);
            firebase.firestore().collection(configs.CONVERSATION_DEV)
                .where('clinicId', '==', Number(userData.clinics[0].uniqueId))
                .where('dependentUniqueId', '==', Number(props.dependent.uniqueid))
                .where('metadata.viewByClinicAt', '==', null)
                .onSnapshot((querySnapshot: any) => {
                    if (props.dependent.uniqueid !== props.selectedDependent.uniqueid) {
                        setUnReadCount(querySnapshot.docs.length);
                    }
                    setIsLoading(false);
                });
        } catch (error) {
            console.log('error while get UnReadCount', error);
        }
    }

    const updateViewingAt = () => {
        firebase.firestore().collection(configs.CONVERSATION_DEV)
            .where("clinicId", "==", Number(userData.clinics[0].uniqueId))
            .where("dependentUniqueId", "==", Number(props.dependent.uniqueid))
            .where("metadata.viewByClinicAt", "==", null)
            .get().then((querySnapshot: any) => {
                querySnapshot.forEach((doc: any) => {
                    const docRef = firebase.firestore().collection(configs.CONVERSATION_DEV).doc(doc.id);
                    docRef.update({
                        "metadata.viewByClinicAt": firebase.firestore.FieldValue.serverTimestamp(),
                    });
                });
                setUnReadCount(0);
            }).catch((error: any) => {
                console.log('error while update view', error);
            });;
    }

    const handleMessage = (dependent: any) => {
        // updateViewingAt();
        dispatch(fetchLoadingPending());
        props.setSelectedDependent(dependent);
    }
    return (
        (props.dependent) &&
        <div id="userListBox" key={props.dependent.uniqueid}>
            <ul className="list-group">
                <li className="border-bottom">
                    <a className={(props.dependent.uniqueid === props.selectedDependent.uniqueid) ? "list-group-item list-group-item-action border-0 active" : 'list-group-item list-group-item-action border-0'}>
                        {
                            isLoading &&
                            <div className="d-flex align-items-center">
                                <div className="avatar avatar-48 img-circle me-3">
                                    <span className="spinner-border spinner-border-sm" role="status"></span>
                                </div>
                                <div className="flex-grow-1">
                                    Loading...
                                    <div className="small">Loading...</div>
                                </div>
                            </div>
                        }
                        {!isLoading &&
                            <>
                                <div className="d-flex align-items-center"
                                    onClick={(e) => {
                                        handleMessage(props.dependent);
                                    }}
                                >
                                    <AvatarComp name={props.dependent.fname + " " + props.dependent.lname}></AvatarComp>
                                    <div className="flex-grow-1">
                                        <div>{props.dependent.fname + " " + props.dependent.lname}</div>
                                        <div className="small chat-msg-inline">{lastMessage ? lastMessage : ''}</div>
                                    </div>
                                    {
                                        (unReadCount !== 0) &&
                                        <div className="badge rounded-pill bg-primary">{unReadCount ? unReadCount : ''}</div>
                                    }
                                </div>
                            </>
                        }
                    </a>
                </li>
            </ul>
        </div>
    )
}
