import React, { useEffect, useState } from 'react';
import { updateClinicProfile } from '../../../services/clinicProfile';
export const Questions = ({ practiceQuestions, clinicId, fetchProfile, questionList }: any) => {
  const [questions, setQuestions]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    setQuestions(questionList.map((item: any) => {
      if (practiceQuestions.find((qItem: any) => qItem.id === item.id)) {
        return {
          ...item,
          response: practiceQuestions.find((qItem: any) => qItem.id === item.id).response
        }
      }
      return item;
    }));
  }, [practiceQuestions, questionList]);
  const onCancel = () => {
    setQuestions(practiceQuestions);
    setIsEdit(false);
  };
  const onUpdate = () => {
    if (questions.find((item: any) => item.response === "")) {
      return;
    } else {
      setLoading(true);
      updateClinicProfile('question', clinicId, JSON.stringify(questions)).then((success) => {
        if (success && success.status && success.status.error === false) {
          fetchProfile('description').then((success: any) => {
            setLoading(false);
            setIsEdit(false);
          });
        }
      }).catch((err) => { setLoading(false); setIsEdit(false); });
    }
  }
  return (
    <div className="practice-questions-sec mb-4">
      <div className="card">

        <div className="card-header bg-light py-3 px-4">
          <div className="d-flex justify-content-between">
            <div className="fw-medium">Practice questions</div>
            <div>
              {
                !isEdit && !loading &&
                <button className="btn p-0 btn-link" onClick={(e) => {
                  setIsEdit(true);
                }}>Edit</button>
              }
              {
                isEdit && !loading &&
                <>
                  <button className="btn p-0 btn-link me-2 text-secondary" onClick={(e) => { onCancel() }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                  <button className="btn p-0 btn-link" onClick={(e) => { onUpdate() }}><i className="bi bi-check-circle-fill text-success"></i></button>
                </>
              }
              {
                loading &&
                <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
              }
            </div>
          </div>
        </div>

        <div className="card-body p-4">
          <div>
            {
              questions.map((item: any, index: number) => {
                if (item.id && item.title) {
                  return (
                    <div className="que-item mb-3" key={item.id}>
                      <label className="form-label fw-normal">{index + 1}. {item.title}</label>
                      <textarea disabled={!isEdit} className="form-control w-100" rows={4} value={item.response} onChange={(e) => {
                        setQuestions(questions.map((qItem: any) => {
                          if (qItem.id === item.id) {
                            return {
                              ...qItem,
                              response: e.target.value
                            }
                          }
                          return qItem;
                        }));
                      }}></textarea>
                    </div>
                  )
                }
                return null;
              })
            }
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div className="align-self-center">
              <small className="d-block text-muted">This bio will help new patients find your clinic on google.</small>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}