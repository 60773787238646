import React from 'react';

export const NoDataPresent = ({ name }: any) => {
    return (
        <div className='d-flex justify-content-center align-items-center'>
            <h6 className='d-flex justify-content-center align-items-center justify-content-center fw-bold position-absolute top-0 start-10 mt-5'>{name}</h6>
            <div className="text-center pt-4 mt-2">
                <div><i className="bi bi-exclamation-triangle text-center fs-6"></i></div>
                <span>No data present.</span>
            </div>
        </div>
    );
};
