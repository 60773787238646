import configs from "../../v2/configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../../constants/api"
import { commonApi } from "../../helpers/api";
import { attachHeader } from "../../utils/apiHandler";
import Axios from 'axios';

export const getChartData = (data: any,type:any) => {
    const request: CommonApiOptions = {
        url: configs.MESSAGE_CENTER_API +'dashboard/'+ type,
        method: METHOD_TYPE.POST,
        apiOptions: {
            headers: { 'Content-Type': 'application/json' },
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

// export const  getChart = (request:any,type:any) => {
//     return Axios.post(configs.MESSAGE_CENTER_API +'dashboard/'+ type,request,
//         { headers: attachHeader(true), withCredentials: true, }
//     ).then(response => ({ response }), error => ({ error }));
// }