import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { PaymentBillableAppointmentResponse, ServicesTypeResponse, CreateInvoiceRequest, PaymentState } from '../../store/payment/types';
import moment from 'moment';
import { roundNumberWithRoundingFactorDecimalFix } from '../../utils/global';
import * as _ from 'lodash';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { createInvoicePending } from '../../store/payment/actions';
import { toast } from 'react-toastify';
import Switch from '@material-ui/core/Switch';
import { allowOnlyDigit } from '../../utils/validators';
import { uploadFile } from '../../v2/services/payment';
import { getAppointmentCategoryList, getProcedureCodeList} from '../../../src/v2/services/messageCenter';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../src/store/loadingIndicator/actions';
import './invoiceModal.scss';
interface Props {
    viewPaymentModal: boolean;
    hideModal(recallList: boolean): void;
    invoiceOperation?: string;
    selectedAppointment?: PaymentBillableAppointmentResponse;
    servicesArray?: ServicesTypeResponse["serviceType"];
    createInvoice: typeof createInvoicePending;
    paymentState: PaymentState;
    isShow?: any;
    loadingStart: typeof fetchLoadingPending;
    loadingEnd: typeof fetchLoadingSuccess;
}
interface State {
    createServiceArray: any[];
    selectedService: any;
    serviceAmount: string;
    insuranceAmount: string;
    enableTax: boolean;
    totalAmount: string;
    memo: string;
    isAdminFee: boolean;
    adminFee: string;
    isShow: boolean;
    isRecurring: boolean;
    paymentModes: any;
    selectedPaymentMode: string;
    numberOfEmi: any;
    emiAmount: number;
    selectedFile: any;
    filePath: any;
    fileName: any;
    chooseFileName: any;
    appointmentCategory: any;
    categoryCode: any;
    selectedAppointmentCategory: any;
    selectedCategoryCode: any;
    isEdit: boolean;
}
class InvoiceModal extends React.Component<Props, State> {
    uploadRef: any;
    constructor(props: any) {
        super(props);
        this.state = {
            createServiceArray: [],
            selectedService: '', serviceAmount: '', insuranceAmount: '', enableTax: false,
            totalAmount: '', memo: '', isAdminFee: false, adminFee: '3', isShow: props.isShow ? props.isShow : false,
            isRecurring: false, paymentModes: ['Weekly', 'Monthly', 'Quarterly'], selectedPaymentMode: 'Weekly', numberOfEmi: 2, emiAmount: 0,
            selectedFile: '', filePath: '', fileName: '', chooseFileName: '',
            appointmentCategory:[],categoryCode:[],selectedAppointmentCategory:'',selectedCategoryCode:'',
            isEdit:false
        }
        this.addServiceToCreateArray = this.addServiceToCreateArray.bind(this);
        this.handleCreateInvoice = this.handleCreateInvoice.bind(this);
        this.resetEdit = this.resetEdit.bind(this);
        this.uploadRef = React.createRef();
    }
    componentDidMount() {
        if (this.props.selectedAppointment && this.props.selectedAppointment.clinic && this.props.selectedAppointment.clinic.id) {
            this.fetchAppointmentCategory();
        }
        
        if (this.props.invoiceOperation === "edit" && this.props.selectedAppointment) {
            if (this.props.selectedAppointment.billingdata.clinicToPatientBilling.isTaxable) {
                this.setState({ enableTax: true });
            }
            console.log('filePath', this.props.selectedAppointment);
            let file: any = '';
            if (this.props.selectedAppointment && this.props.selectedAppointment.billingdata && this.props.selectedAppointment.billingdata.clinicToPatientBilling &&
                this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoicedoc) {
                let lastIndex = this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoicedoc.split("/");
                file = lastIndex[lastIndex.length - 1];
            }

            this.setState({
                isAdminFee: this.props.selectedAppointment.billingdata.clinicToPatientBilling.isAdminFee ? this.props.selectedAppointment.billingdata.clinicToPatientBilling.isAdminFee : false,
                adminFee: this.props.selectedAppointment.billingdata.clinicToPatientBilling.adminFee ? this.props.selectedAppointment.billingdata.clinicToPatientBilling.adminFee : '',
                memo: this.props.selectedAppointment.billingdata.clinicToPatientBilling.memo ? this.props.selectedAppointment.billingdata.clinicToPatientBilling.memo : '',
                isRecurring:
                    this.props.selectedAppointment.billingdata && this.props.selectedAppointment.billingdata.recurring && this.props.selectedAppointment.billingdata.recurring.recurringStatus &&
                        this.props.selectedAppointment.billingdata.recurring.recurringStatus ? true : false,
                filePath: this.props.selectedAppointment && this.props.selectedAppointment.billingdata && this.props.selectedAppointment.billingdata.clinicToPatientBilling &&
                    this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoicedoc && this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoicedoc ? this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoicedoc : '',
                fileName: file,
                selectedPaymentMode: this.props.selectedAppointment.billingdata && this.props.selectedAppointment.billingdata.recurring && this.props.selectedAppointment.billingdata.recurring.recurringStatus ? this.props.selectedAppointment.billingdata.recurring.paymentMode : 'Weekly',
                numberOfEmi: this.props.selectedAppointment.billingdata && this.props.selectedAppointment.billingdata.recurring && this.props.selectedAppointment.billingdata.recurring.recurringStatus ? this.props.selectedAppointment.billingdata.recurring.numberOfEmi : 2,
                createServiceArray: this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoiceDetails.map((service) => {
                    return {
                        totalAmount: service.totalAmount,
                        amount: service.amount,
                        edit: false,
                        selectedService: service.service_id,
                        serviceType: service.serviceType,
                        insuranceAmount: service.insuranceAmount,
                        selectedAppointmentCategory: service.procedureCategory ? service.procedureCategory : '-',
                        selectedCategoryCode:service.procedureCode ? service.procedureCode : '-',
                        procedureCategory: service.procedureCategory ? service.procedureCategory : '-',
                        procedureCode:service.procedureCode ? service.procedureCode : '-',
                        
                    }
                })
            });
        } else if (this.props.invoiceOperation === "view" && this.props.selectedAppointment) {
            let file: any = '';
            if (this.props.selectedAppointment && this.props.selectedAppointment.billingdata && this.props.selectedAppointment.billingdata.clinicToPatientBilling &&
                this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoicedoc) {
                let lastIndex = this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoicedoc.split("/");
                file = lastIndex[lastIndex.length - 1];
            }
            if (this.props.selectedAppointment.billingdata && this.props.selectedAppointment.billingdata.recurring) {
                this.setState({
                    isRecurring:
                        this.props.selectedAppointment.billingdata && this.props.selectedAppointment.billingdata.recurring && this.props.selectedAppointment.billingdata.recurring.recurringStatus &&
                            this.props.selectedAppointment.billingdata.recurring.recurringStatus ? true : false,
                    selectedPaymentMode: this.props.selectedAppointment.billingdata && this.props.selectedAppointment.billingdata.recurring && this.props.selectedAppointment.billingdata.recurring.recurringStatus ? this.props.selectedAppointment.billingdata.recurring.paymentMode : 'Weekly',
                    numberOfEmi: this.props.selectedAppointment.billingdata && this.props.selectedAppointment.billingdata.recurring && this.props.selectedAppointment.billingdata.recurring.recurringStatus ? this.props.selectedAppointment.billingdata.recurring.numberOfEmi : 2,
                });
            }
            if (this.props.selectedAppointment.billingdata.clinicToPatientBilling.isTaxable) {
                this.setState({ enableTax: true });
            }
            this.setState({
                isAdminFee: this.props.selectedAppointment.billingdata.clinicToPatientBilling.isAdminFee ? this.props.selectedAppointment.billingdata.clinicToPatientBilling.isAdminFee : false,
                adminFee: this.props.selectedAppointment.billingdata.clinicToPatientBilling.adminFee ? this.props.selectedAppointment.billingdata.clinicToPatientBilling.adminFee : '',
                memo: this.props.selectedAppointment.billingdata.clinicToPatientBilling.memo ? this.props.selectedAppointment.billingdata.clinicToPatientBilling.memo : '',
                filePath: this.props.selectedAppointment && this.props.selectedAppointment.billingdata && this.props.selectedAppointment.billingdata.clinicToPatientBilling &&
                    this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoicedoc && this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoicedoc ? this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoicedoc : '',
                fileName: file,
            })
        }
        if (this.props.servicesArray) {
            this.setState({
                selectedService: this.props.servicesArray ? this.props.servicesArray[0].id : ''
            });
        }
        if ((this.props.invoiceOperation === "create" || this.props.invoiceOperation === "edit") && this.props.selectedAppointment &&
            this.props.selectedAppointment.clinic.metadata && this.props.selectedAppointment.clinic.metadata.adminsafetyfee === true && this.props.selectedAppointment.clinic.metadata.adminFee) {
            this.setState({
                isAdminFee: true,
                adminFee: this.props.selectedAppointment.clinic.metadata.adminFee
            });
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (this.props.servicesArray !== nextProps.servicesArray) {
            this.setState({
                selectedService: nextProps.servicesArray ? nextProps.servicesArray[0].id : ''
            });
        }
        if (this.props.invoiceOperation !== nextProps.invoiceOperation) {
            if (nextProps.invoiceOperation === "edit" && nextProps.selectedAppointment) {
                if (nextProps.selectedAppointment.billingdata.clinicToPatientBilling.isTaxable) {
                    this.setState({ enableTax: true });
                }
                this.setState({
                    isAdminFee: nextProps.selectedAppointment.billingdata.clinicToPatientBilling.isAdminFee ? nextProps.selectedAppointment.billingdata.clinicToPatientBilling.isAdminFee : false,
                    adminFee: nextProps.selectedAppointment.billingdata.clinicToPatientBilling.adminFee ? nextProps.selectedAppointment.billingdata.clinicToPatientBilling.adminFee : '',
                    memo: nextProps.selectedAppointment.billingdata.clinicToPatientBilling.memo ? nextProps.selectedAppointment.billingdata.clinicToPatientBilling.memo : '',
                    createServiceArray: nextProps.selectedAppointment.billingdata.clinicToPatientBilling.invoiceDetails.map((service) => {
                        return {
                            amount: service.amount,
                            edit: false,
                            selectedService: service.service_id,
                            serviceType: service.serviceType,
                            insuranceAmount: service.insuranceAmount,
                            selectedAppointmentCategory: service.procedureCategory ? service.procedureCategory : '-',
                            selectedCategoryCode: service.procedureCode ? service.procedureCode : '-',
                            procedureCategory: service.procedureCategory ? service.procedureCategory : '-',
                        procedureCode:service.procedureCode ? service.procedureCode : '-',
                        }
                    })
                });
            } else if (nextProps.invoiceOperation === "view" && nextProps.selectedAppointment) {
                if (nextProps.selectedAppointment.billingdata.clinicToPatientBilling.isTaxable) {
                    this.setState({ enableTax: true });
                }
                this.setState({
                    isAdminFee: nextProps.selectedAppointment.billingdata.clinicToPatientBilling.isAdminFee ? nextProps.selectedAppointment.billingdata.clinicToPatientBilling.isAdminFee : false,
                    adminFee: nextProps.selectedAppointment.billingdata.clinicToPatientBilling.adminFee ? nextProps.selectedAppointment.billingdata.clinicToPatientBilling.adminFee : '',
                    memo: nextProps.selectedAppointment.billingdata.clinicToPatientBilling.memo ? nextProps.selectedAppointment.billingdata.clinicToPatientBilling.memo : '',
                })
            }
        }
        if (this.props.paymentState !== nextProps.paymentState) {
            if (nextProps.paymentState.createInvoiceResponse !== this.props.paymentState.createInvoiceResponse &&
                nextProps.paymentState.pending === false && nextProps.paymentState.createInvoiceResponse?.status.error === false) {
                this.setState({
                    createServiceArray: [],
                    selectedService: '', serviceAmount: ''
                });
                this.props.hideModal(true);
                toast.success(nextProps.paymentState.createInvoiceResponse.status.msg);
            }
        }
        return true;
    }
    hideModal() {
        this.setState({
            createServiceArray: [],
            selectedService: '', serviceAmount: ''
        });
        this.props.hideModal(false);
    }
    addServiceToCreateArray() {
        if (Number(this.state.totalAmount) < Number(this.state.insuranceAmount)) {
            toast.warn('Insured amount should be less than total amount.');
            return;
        }
        const insertObject = {
            selectedService: this.state.selectedService,
            serviceType: this.props.servicesArray ? this.props.servicesArray.find((value, index) => { return value.id === this.state.selectedService })?.service_type : '',
            totalAmount: this.state.totalAmount,
            amount: this.state.serviceAmount,
            insuranceAmount: this.state.insuranceAmount,
            selectedAppointmentCategory: this.state.selectedAppointmentCategory,
            selectedCategoryCode: this.state.selectedCategoryCode,
            procedureCategory: this.state.selectedAppointmentCategory,
            procedureCode:this.state.selectedCategoryCode,
            edit: false,
        };
        this.setState({
            createServiceArray: [...this.state.createServiceArray, insertObject],
            selectedService: this.props.servicesArray ? this.props.servicesArray[0].id : '',
            serviceAmount: '', insuranceAmount: '', totalAmount: ''
        });
    }
    handleCreateInvoice() {
        const subTotal = Number(_.sumBy(this.state.createServiceArray, (item) => {
            return Number(item.amount)
        }));
        let tax = 0;
        if (this.props.invoiceOperation === "edit" && this.props.selectedAppointment && this.props.selectedAppointment.billingdata.clinicToPatientBilling && this.state.enableTax) {
            tax = ((_.sumBy(this.state.createServiceArray, (item) => {
                return Number(item.amount)
            })) / 100) * Number(this.props.selectedAppointment.billingdata.clinicToPatientBilling.billingTax);
        } else if (this.props.invoiceOperation === "create" && this.props.selectedAppointment &&
            this.props.selectedAppointment.clinic && this.state.enableTax) {
            tax = ((_.sumBy(this.state.createServiceArray, (item) => {
                return Number(item.amount)
            })) / 100) * Number(this.props.selectedAppointment.clinic.taxrate);
        }
        let adminFee: any = 0;
        if (this.state.isAdminFee) {
            adminFee = (subTotal / 100) * Number(this.state.adminFee);
        }
        const total = subTotal + tax + adminFee;
        if (this.props.selectedAppointment) {
            let invoiceCreateObj = {
                ...this.props.selectedAppointment?.billingdata,
                clinicToPatientBilling: {
                    ...this.props.selectedAppointment?.billingdata.clinicToPatientBilling,
                    memo: this.state.memo,
                    total: total,
                    appointmentId: this.props.selectedAppointment.id,
                    isTaxable: this.state.enableTax,
                    subtotal: subTotal,
                    billingTax: this.props.invoiceOperation === "edit" ? this.props.selectedAppointment.billingdata.clinicToPatientBilling.billingTax
                        : this.props.selectedAppointment?.clinic.taxrate,
                    status: this.props.invoiceOperation === "edit" ? this.props.selectedAppointment.billingdata.clinicToPatientBilling.status
                        : "pending",
                    invoiceCreatedDate: this.props.invoiceOperation === "create" ? moment().format("YYYY-MM-DD HH:mm:ss") : this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoiceCreatedDate,
                    invoiceNumber: this.props.invoiceOperation === "create" ? this.props.selectedAppointment?.invoiceNumber : this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoiceNumber,
                    invoiceDetails: this.state.createServiceArray.map((service) => {
                        return {
                            totalAmount: service.totalAmount,
                            amount: service.amount,
                            serviceType: service.serviceType,
                            service_id: service.selectedService,
                            insuranceAmount: service.insuranceAmount,
                            procedureCategory: service.procedureCategory ? service.procedureCategory : '-',
                            procedureCode:service.procedureCode ? service.procedureCode : '-'
                        }
                    }),
                    isAdminFee: this.state.isAdminFee,
                    adminFee: this.state.adminFee,
                    invoicedoc: this.state.filePath ? this.state.filePath : ''
                }
            }
            if (this.state.isRecurring) {
                Object.assign(invoiceCreateObj, {
                    recurring: {
                        recurringStatus: true, // 1:true
                        paymentMode: this.state.selectedPaymentMode,
                        numberOfEmi: this.state.numberOfEmi
                    }
                });
            } else {
                Object.assign(invoiceCreateObj, {
                    recurring: {
                        recurringStatus: false // 0:false
                    }
                });
            }
            console.log('InvoiceObj', invoiceCreateObj);
            this.props.createInvoice({
                type: "add",
                provider: "clinic",
                requestInvoiceData: invoiceCreateObj,
                uniqueId: this.props.selectedAppointment.clinic.uniqueid,
                appointmentId: this.props.selectedAppointment.uniqueid
            });
        }
    }
    resetEdit() {
        if (this.props.invoiceOperation === "edit" && this.props.selectedAppointment) {
            this.setState({
                enableTax: this.props.selectedAppointment.billingdata.clinicToPatientBilling.isTaxable,
                memo: this.props.selectedAppointment.billingdata.clinicToPatientBilling.memo,
                adminFee: this.props.selectedAppointment.billingdata.clinicToPatientBilling.adminFee,
                isAdminFee: this.props.selectedAppointment.billingdata.clinicToPatientBilling.isAdminFee,
                createServiceArray: this.props.selectedAppointment.billingdata.clinicToPatientBilling.invoiceDetails.map((service) => {
                    return {
                        totalAmount: service.totalAmount,
                        amount: service.amount,
                        edit: false,
                        selectedService: service.service_id,
                        serviceType: service.serviceType,
                        insuranceAmount: service.insuranceAmount,
                        selectedAppointmentCategory: service.procedureCategory ? service.procedureCategory : '-',
                        selectedCategoryCode:service.procedureCode ? service.procedureCode : '-'
                    }
                })
            });
        }
    }
    handleOnChangeFile = (files: any) => {
        if (files && files[0] && files[0].size && files[0].size > (1048576 * 5)) {
            toast.error('Max file size 5 MB.');
            return;
        }
        if (files[0].type === "application/pdf" || files[0].type === "application/doc" || files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            this.setState({ selectedFile: files[0], chooseFileName: files[0].name ? files[0].name : '' });
        } else {
            toast.error('File extension is not valid, allowed .pdf , .doc and .docx');
            return;
        }
    }

    handleSave = () => {
        if (this.state.selectedFile) {
            uploadFile({ upload: this.state.selectedFile }).then((success) => {
                if (success && success.error === false) {
                    this.setState({ filePath: success.data }, () => {
                        this.handleCreateInvoice();
                    });
                }
            }).catch((err) => {
                toast.error('document upload failed.');
            })
        } else {
            this.handleCreateInvoice();
        }
    }
    fetchAppointmentCategory(appointment?:any) {
        if (this.props.selectedAppointment && this.props.selectedAppointment.clinic && this.props.selectedAppointment.clinic.id) {
            this.props.loadingStart();
            getAppointmentCategoryList({
                clinicid: this.props.selectedAppointment.clinic.id,
            }).then((success) => {
                this.props.loadingEnd(false);
                if (success && success.error === false) {
                    this.setState({
                        appointmentCategory: success.data,
                        selectedAppointmentCategory:appointment?appointment.procedureCategory:this.state.selectedAppointmentCategory
                    });
                    if (appointment) {
                        this.fetchCategoryCode(appointment.procedureCategory, appointment);
                    }
                }
            }).catch((err) => {
                this.props.loadingEnd(false);
            })
        }
    }
    fetchCategoryCode(category:any,appointment?:any) {
        if (this.props.selectedAppointment && this.props.selectedAppointment.clinic && this.props.selectedAppointment.clinic.id) {
            this.props.loadingStart();
            getProcedureCodeList({
                clinicid: this.props.selectedAppointment.clinic.id,
                category: [category],
                type: 'custom'
            }).then((success) => {
                this.props.loadingEnd(false);
                if (success && success.error === false) {
                    // this.setState({ categoryCode: success.data[0] });
                    if (appointment) {
                        this.setState({
                            categoryCode: success.data[0],
                            selectedCategoryCode:appointment.procedureCode,
                            // selectedAppointmentCategory: appointment.procedureCategory,
                        });
                    } else {
                        this.setState({
                            categoryCode: success.data[0],
                            selectedCategoryCode: success.data[0][0].procedure_code_sikkaId,
                            totalAmount: success.data[0][0].procedure_code_price
                        });
                    }
                }
            }).catch((err) => {
                this.props.loadingEnd(false);
                //   dispatch(fetchLoadingSuccess(false));
            })
        }
    }

    isEditRow = (index: any, appointment: any) => {
        this.setState({
            isEdit:true,
            selectedAppointmentCategory: appointment.procedureCategory,
            createServiceArray: this.state.createServiceArray.map((service, sIndex) => {
                if (index === sIndex) {
                    return { ...service, edit: true }
                }
                return service;
            })
        }, () => {
            this.fetchCategoryCode(appointment.procedureCategory,appointment);
        });
    }
    render() {
        const subTotal = Number(_.sumBy(this.state.createServiceArray, (item) => {
            return Number(item.amount)
        }));
        let tax = 0;
        let adminFee = 0;
        if (this.props.invoiceOperation === "edit" && this.props.selectedAppointment && this.state.enableTax) {
            tax = ((_.sumBy(this.state.createServiceArray, (item) => {
                return Number(item.amount)
            })) / 100) * Number(this.props.selectedAppointment.billingdata.clinicToPatientBilling.billingTax);
        } else if (this.props.invoiceOperation === "create" && this.props.selectedAppointment && this.props.selectedAppointment.clinic.metadata &&
            this.state.enableTax) {
            tax = ((_.sumBy(this.state.createServiceArray, (item) => {
                return Number(item.amount)
            })) / 100) * Number(this.props.selectedAppointment.clinic.taxrate);
        }
        if (this.state.isAdminFee) {
            adminFee = (subTotal / 100) * Number(this.state.adminFee);
        }
        /* if (this.props.selectedAppointment && this.state.createServiceArray && this.props.selectedAppointment.clinic.metadata && this.state.enableTax) {
            tax = ((_.sumBy(this.state.createServiceArray, (item) => {
                return Number(item.amount)
            })) / 100) * Number(this.props.selectedAppointment.clinic.taxrate);
        } */
        const total = subTotal + tax + adminFee;
        return (
            <Modal show={this.props.viewPaymentModal} onHide={this.hideModal.bind(this)} centered className="newcommon req_invomodel" size="lg">

                <Modal.Header>
                    <div className="modal-title">{this.state.isShow ? 'Invoice request' : 'Invoice'}</div>
                    <button type="button" className="btn-close" aria-label="Close" onClick={this.hideModal.bind(this)} >
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <>
                        {
                            this.state.isShow ?
                                <div>
                                    <p>Would like to send invoice to patient?</p>
                                </div>
                                :
                                <div id="invoice" className="">
                                    <div className="row justify-content-between mb-4">
                                        <div className="col-6">
                                            <div className="">Invoice To:</div>
                                            <div className="fw-medium">{this.props?.selectedAppointment?.dependant.fname + ' ' + this.props?.selectedAppointment?.dependant.lname}</div>
                                        </div>
                                        <div className="col text-end">
                                            {
                                                (this.props.invoiceOperation === "view") ?
                                                    <>
                                                        <div>
                                                            <span className="text-muted">Invoice No :</span> #{this.props.selectedAppointment?.billingdata?.clinicToPatientBilling?.invoiceNumber}</div>
                                                    </> :
                                                    <>
                                                        <div>
                                                            <span className="text-muted">Invoice No :</span> #{
                                                                (this.props.invoiceOperation === "create") ?
                                                                    this.props.selectedAppointment?.invoiceNumber : this.props.selectedAppointment?.billingdata.clinicToPatientBilling.invoiceNumber
                                                            }</div>
                                                    </>
                                            }

                                            <div>
                                                <span className="text-muted">Date :</span> {
                                                    (this.props.invoiceOperation === "view") ?
                                                        <span>{moment(this.props.selectedAppointment?.billingdata?.clinicToPatientBilling?.invoiceCreatedDate).format("MMM DD, YYYY")}</span> :
                                                        <span>{moment().format("MMM DD, YYYY")}</span>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                    <div className="m-t-20">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th className="w22">Category</th>
                                                        <th className="w16">Code</th>
                                                        {/* <th className="w-30">Service type</th> */}
                                                        <th>Total ($)</th>
                                                        <th>Insurance ($)</th>
                                                        <th>Balance ($)</th>
                                                        {
                                                            this.props.invoiceOperation !== "view" &&
                                                            <th className="text-end">Action</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.props.invoiceOperation === "view" &&
                                                        this.props.selectedAppointment?.billingdata?.clinicToPatientBilling?.invoiceDetails.map((value, index) => {
                                                            return (
                                                                <tr>
                                                                    <th>{index + 1}</th>
                                                                    {/* <td>{value.serviceType}</td> */}
                                                                    <td>{value.procedureCategory ? value.procedureCategory :'-'}</td>
                                                                    <td>{value.procedureCode ? value.procedureCode:'-'}</td>
                                                                    <td>${roundNumberWithRoundingFactorDecimalFix(Number(value.totalAmount), 2)}</td>
                                                                    <td>${roundNumberWithRoundingFactorDecimalFix(Number(value.insuranceAmount), 2)}</td>
                                                                    <td>${roundNumberWithRoundingFactorDecimalFix(Number(value.amount), 2)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        (this.props.invoiceOperation === "create" || this.props.invoiceOperation === "edit") &&
                                                        <>
                                                            {
                                                                this.state.createServiceArray.map((value: any, index) => {
                                                                    if (value.edit) {
                                                                        return (
                                                                            <tr>
                                                                                <th>{index + 1}</th>
                                                                                <td>
                                                                                    {/* <select className="form-select" value={value.selectedService} onChange={(e) => {
                                                                                        this.setState({
                                                                                            createServiceArray: this.state.createServiceArray.map((service, sIndex) => {
                                                                                                if (index === sIndex) {
                                                                                                    return { ...service, selectedService: e.target.value }
                                                                                                }
                                                                                                return service;
                                                                                            })
                                                                                        });
                                                                                    }}>
                                                                                        {
                                                                                            this.props.servicesArray?.map((value, index) => {
                                                                                                return (<option value={value.id}>{value.service_type}</option>);
                                                                                            })
                                                                                        }
                                                                                    </select> */}
                                                                                    <select className="form-select" value={value.selectedAppointmentCategory} onChange={(e) => {
                                                                                        this.fetchCategoryCode(e.target.value);
                                                                                        this.setState({
                                                                                            selectedAppointmentCategory:e.target.value,
                                                                                            createServiceArray: this.state.createServiceArray.map((service:any, sIndex:any) => {
                                                                                                if (index === sIndex) {
                                                                                                    return { ...service, selectedAppointmentCategory: e.target.value }
                                                                                                }
                                                                                                return service;
                                                                                            })
                                                                                        });
                                                                                    }}>
                                                                                        {
                                                                                            this.state.appointmentCategory?.map((value:any, index:any) => {
                                                                                                return (<option value={value.procedure_code_category}>{value.procedure_code_category}</option>);
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </td>
                                                                                <td>
                                                                                    <select className="form-select" value={value.selectedCategoryCode} onChange={(e) => {
                                                                                        const category = this.state.categoryCode.find((item: any) =>  item.procedure_code_sikkaId === e.target.value);
                                                                                        this.setState({
                                                                                            selectedCategoryCode:e.target.value,
                                                                                            totalAmount: category?.procedure_code_price ?? 0,
                                                                                            createServiceArray: this.state.createServiceArray.map((service:any, sIndex:any) => {
                                                                                                if (index === sIndex) {
                                                                                                    const amount = Number(category?.procedure_code_price ?? 0) - Number(service.insuranceAmount);
                                                                                                    return { ...service, selectedCategoryCode: e.target.value, amount: amount, totalAmount: category?.procedure_code_price ?? 0 }
                                                                                                }
                                                                                                return service;
                                                                                            })
                                                                                        });
                                                                                    }}>
                                                                                        {
                                                                                            this.state.categoryCode?.map((value:any, index:any) => {
                                                                                                return (<option value={value.procedure_code_sikkaId}>{value.procedure_code_sikkaId}</option>);
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </td>
                                                                                <td>
                                                                                    <input type="text" className="form-control" placeholder="$450.00" value={value.totalAmount} onChange={(e) => {
                                                                                        this.setState({
                                                                                            createServiceArray: this.state.createServiceArray.map((service, sIndex) => {
                                                                                                const amount = Number(e.target.value) - Number(service.insuranceAmount);
                                                                                                if (index === sIndex && !isNaN(amount) && amount >= 0) {
                                                                                                    return { ...service, totalAmount: e.target.value, amount: amount }
                                                                                                }
                                                                                                return service;
                                                                                            })
                                                                                        });
                                                                                    }} />
                                                                                </td>
                                                                                <td>
                                                                                    <input type="text" className="form-control" placeholder="$450.00" value={value.insuranceAmount} onChange={(e) => {
                                                                                        this.setState({
                                                                                            createServiceArray: this.state.createServiceArray.map((service, sIndex) => {
                                                                                                const amount = Number(service.totalAmount) - Number(e.target.value);
                                                                                                if (index === sIndex && !isNaN(amount) && amount >= 0) {
                                                                                                    return { ...service, insuranceAmount: e.target.value, amount: amount }
                                                                                                }
                                                                                                return service;
                                                                                            })
                                                                                        });
                                                                                    }} />
                                                                                </td>
                                                                                <td>
                                                                                    <input type="text" className="form-control" placeholder="$450.00" value={value.amount} disabled onChange={(e) => {
                                                                                        this.setState({
                                                                                            createServiceArray: this.state.createServiceArray.map((service, sIndex) => {
                                                                                                if (index === sIndex) {
                                                                                                    return { ...service, amount: e.target.value }
                                                                                                }
                                                                                                return service;
                                                                                            })
                                                                                        });
                                                                                    }} />
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    <button className="btn btn-sm me-2" onClick={(e) => {
                                                                                        if (Number(value.totalAmount) < Number(value.insuranceAmount)) {
                                                                                            toast.warn('Insured amount should be less than total amount.');
                                                                                            return;
                                                                                        }
                                                                                        const insertObject = {
                                                                                            selectedService: value.selectedService,
                                                                                            serviceType: this.props.servicesArray ? this.props.servicesArray.find((service, index) => { return service.id === value.selectedService })?.service_type : '',
                                                                                            totalAmount: value.totalAmount,
                                                                                            amount: value.amount,
                                                                                            insuranceAmount: value.insuranceAmount,
                                                                                            procedureCategory:this.state.selectedAppointmentCategory,
                                                                                            procedureCode:this.state.selectedCategoryCode,
                                                                                            selectedAppointmentCategory:this.state.selectedAppointmentCategory,
                                                                                            selectedCategoryCode:this.state.selectedCategoryCode,
                                                                                            edit: false,
                                                                                        };
                                                                                        this.setState({
                                                                                            isEdit:false,
                                                                                            createServiceArray: this.state.createServiceArray.map((service, sIndex) => {
                                                                                                if (index === sIndex) {
                                                                                                    return insertObject
                                                                                                }
                                                                                                return service;
                                                                                            })
                                                                                        });
                                                                                    }}><i className="bi bi-save"></i></button>
                                                                                    <button className="btn btn-sm" onClick={(e) => {
                                                                                        this.setState({
                                                                                            isEdit:false,
                                                                                            createServiceArray: this.state.createServiceArray.filter((service, sIndex) => {
                                                                                                return sIndex !== index;
                                                                                            })
                                                                                        });
                                                                                    }}><i className="bi bi-trash"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    return (
                                                                        <tr>
                                                                            <th>{index + 1}</th>
                                                                            {/* <td>{value.serviceType}</td> */}
                                                                            <td>{value.procedureCategory ? value.procedureCategory :'-'}</td>
                                                                            <td>{value.procedureCode ? value.procedureCode:'-'}</td>
                                                                            <td>${roundNumberWithRoundingFactorDecimalFix(Number(value.totalAmount), 2)}</td>
                                                                            <td>${roundNumberWithRoundingFactorDecimalFix(Number(value.insuranceAmount), 2)}</td>
                                                                            <td>${roundNumberWithRoundingFactorDecimalFix(Number(value.amount), 2)}</td>
                                                                            <td className="text-end">
                                                                                <button className="btn btn-sm" onClick={(e) => {
                                                                                    this.isEditRow(index, value);
                                                                                }}><i className="bi bi-pencil"></i></button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                !this.state.isEdit &&
                                                                <tr>
                                                                    <th>{this.state.createServiceArray.length === 0 ? '1' : this.state.createServiceArray.length + 1}</th>
                                                                    <td>
                                                                        {/* <select className="form-select" value={this.state.selectedService} onChange={(e) => {
                                                                        this.setState({ selectedService: e.target.value });
                                                                    }}>
                                                                        {
                                                                            this.props.servicesArray?.map((value, index) => {
                                                                                return (<option value={value.id}>{value.service_type}</option>);
                                                                            })
                                                                        }
                                                                    </select> */}
                                                                        <select className="form-select" value={this.state.selectedAppointmentCategory} onChange={(e) => {
                                                                            this.fetchCategoryCode(e.target.value);
                                                                            this.setState({ selectedAppointmentCategory: e.target.value });
                                                                        }}>
                                                                            {
                                                                                this.state.appointmentCategory?.map((value: any, index: any) => {
                                                                                    return (<option key={value.procedure_code_category} value={value.procedure_code_category}>{value.procedure_code_category}</option>);
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <select className="form-select" value={this.state.selectedCategoryCode} onChange={(e) => {
                                                                            const category = this.state.categoryCode.find((item: any) =>  item.procedure_code_sikkaId === e.target.value);
                                                                            this.setState({ selectedCategoryCode: e.target.value, totalAmount: category?.procedure_code_price ?? 0 });
                                                                        }}>
                                                                            {
                                                                                this.state.categoryCode?.map((value: any, index: any) => {
                                                                                    return (<option key={value.procedure_code_sikkaId} value={value.procedure_code_sikkaId}>{value.procedure_code_sikkaId}</option>);
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" className="form-control" placeholder="$450.00" value={this.state.totalAmount}
                                                                            onChange={(e) => {
                                                                                if (!isNaN(Number(e.target.value) - Number(this.state.insuranceAmount)) && (Number(e.target.value) - Number(this.state.insuranceAmount)) >= 0) {
                                                                                    this.setState({ totalAmount: e.target.value, serviceAmount: "" + (Number(e.target.value) - Number(this.state.insuranceAmount)) });
                                                                                }
                                                                            }} />
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" className="form-control" placeholder="$450.00" value={this.state.insuranceAmount}
                                                                            onChange={(e) => {
                                                                                if (!isNaN(Number(this.state.totalAmount) - Number(e.target.value)) && (Number(this.state.totalAmount) - Number(e.target.value)) >= 0) {
                                                                                    this.setState({ insuranceAmount: e.target.value, serviceAmount: "" + (Number(this.state.totalAmount) - Number(e.target.value)) });
                                                                                }
                                                                            }} />
                                                                    </td>
                                                                    <td>
                                                                        <input type="text" className="form-control" placeholder="$450.00" value={this.state.serviceAmount} disabled
                                                                            onChange={(e) => {
                                                                                this.setState({ serviceAmount: e.target.value });
                                                                            }} />
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <button className="btn btn-sm" onClick={(e) => {
                                                                            this.addServiceToCreateArray();
                                                                        }}><i className="bi bi-plus-square"></i></button>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label">Memo:</label>
                                            <textarea className="form-control" rows={3}
                                                disabled={this.props.invoiceOperation === "view"}
                                                value={this.state.memo} onChange={(e: any) => {
                                                    this.setState({ memo: e.target.value })
                                                }}></textarea>
                                        </div>

                                        <div className="row lh-1-8">
                                            <div className="col-sm-12">
                                                {
                                                    this.props.invoiceOperation === "view" &&
                                                    <div className="float-right text-end">
                                                        <p>Sub - Total amount: ${roundNumberWithRoundingFactorDecimalFix(Number(this.props.selectedAppointment?.billingdata?.clinicToPatientBilling.subtotal), 2)}</p>
                                                        {
                                                            (this.props.selectedAppointment?.billingdata?.clinicToPatientBilling && this.state.enableTax) &&
                                                            <p>Tax ({this.props.selectedAppointment.billingdata.clinicToPatientBilling.billingTax}%) :
                                                                ${roundNumberWithRoundingFactorDecimalFix(Number(this.props.selectedAppointment.billingdata.clinicToPatientBilling.total) - Number(this.props.selectedAppointment.billingdata.clinicToPatientBilling.subtotal), 2)} </p>
                                                        }
                                                        {
                                                            (this.props.selectedAppointment && this.props.selectedAppointment.billingdata.clinicToPatientBilling.isAdminFee) &&
                                                            <>
                                                                <p>Admin & Safety Fee ({this.props.selectedAppointment.billingdata.clinicToPatientBilling.adminFee}%) :
                                                                    ${roundNumberWithRoundingFactorDecimalFix((Number(this.props.selectedAppointment.billingdata.clinicToPatientBilling.subtotal) / 100) * Number(this.props.selectedAppointment.billingdata.clinicToPatientBilling.adminFee), 2)}</p>
                                                            </>
                                                        }
                                                        <hr />
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex flex-column justify-content-start align-items-start">
                                                                {
                                                                    (this.state.fileName && this.state.filePath) &&
                                                                    <>
                                                                        <a target="_blank" href={this.state.filePath}>{this.state.fileName}</a>
                                                                    </>
                                                                }
                                                            </div>
                                                            <h3><span className="font-weight-semibold text-dark">Total :</span>
                                                                ${roundNumberWithRoundingFactorDecimalFix(Number(this.props.selectedAppointment?.billingdata.clinicToPatientBilling.total), 2)}</h3>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (this.props.invoiceOperation === "create" || this.props.invoiceOperation === "edit") &&
                                                    <div className="float-right text-end">
                                                        <p>Sub - Total amount: ${roundNumberWithRoundingFactorDecimalFix(subTotal, 2)}</p>
                                                        {
                                                            this.props.invoiceOperation === "edit" ?
                                                                (this.props.selectedAppointment && this.props.selectedAppointment.billingdata.clinicToPatientBilling && this.state.enableTax) &&
                                                                <>
                                                                    <p>Tax ({this.props.selectedAppointment.billingdata.clinicToPatientBilling.billingTax}%) :
                                                                        ${roundNumberWithRoundingFactorDecimalFix(tax, 2)} </p>
                                                                </>
                                                                :
                                                                (this.props.selectedAppointment && this.props.selectedAppointment.clinic && this.state.enableTax) &&
                                                                <>
                                                                    <p>Tax ({this.props.selectedAppointment.clinic.taxrate}%) :
                                                                        ${roundNumberWithRoundingFactorDecimalFix(tax, 2)} </p>
                                                                </>
                                                        }
                                                        {
                                                            (this.state.isAdminFee) && (this.state.adminFee) &&
                                                            <>
                                                                <p>Admin & Safety Fee ({this.state.adminFee}%) :
                                                                    ${roundNumberWithRoundingFactorDecimalFix(adminFee, 2)}</p>
                                                            </>
                                                        }
                                                        <hr />
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex flex-column justify-content-start align-items-start">
                                                                <label className="form-label">Insurance File/Document</label>
                                                                <div className="d-flex flex-row">
                                                                    <button className="btn p-1 px-3 btn-outline-primary" onClick={() => { this.uploadRef.current.click() }}>Browse</button>
                                                                    {
                                                                        (this.state.chooseFileName) &&
                                                                        <span className="mx-2 mt-1">{this.state.chooseFileName}</span>
                                                                    }
                                                                </div>
                                                                <input type="file" className="btn btn-upload" placeholder="Upload Completed Form" ref={this.uploadRef} style={{ display: 'none' }}
                                                                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={(e: any) => { this.handleOnChangeFile(e.target.files) }} />
                                                                {
                                                                    (this.state.fileName && this.state.filePath) &&
                                                                    <a className="mt-1" target="_blank" href={this.state.filePath}>{this.state.fileName}</a>
                                                                }
                                                            </div>
                                                            <h3><span className="font-weight-semibold text-dark">Total :</span>
                                                                ${roundNumberWithRoundingFactorDecimalFix(total, 2)}
                                                            </h3>
                                                        </div>

                                                    </div>
                                                }
                                            </div>
                                        </div>


                                    </div>
                                </div>
                        }
                    </>
                </Modal.Body>
                <Modal.Footer>
                    {
                        <>
                            <div className="container p-0">
                                <div className="row justify-content-end mb-2">

                                    {
                                        (this.state.isRecurring) &&
                                        <>
                                            <div className="col-3">
                                                <label className="form-label">Frequency</label>
                                                <select className="form-select form-select-sm" disabled={this.props.invoiceOperation === "view"} value={this.state.selectedPaymentMode} onChange={(e) => {
                                                    this.setState({ selectedPaymentMode: e.target.value });
                                                }}>
                                                    {(this.state.paymentModes.length > 0) &&
                                                        this.state.paymentModes.map((item: any, index: number) => {
                                                            return (
                                                                <option value={item} key={index + item}>{item}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-3">
                                                <label className="form-label">Number of Installment</label>
                                                <input type="text" className="form-control form-control-sm" value={this.state.numberOfEmi}
                                                    disabled={this.props.invoiceOperation === "view"}
                                                    onChange={(e) => {
                                                        if (allowOnlyDigit(e.target.value) && Number(e.target.value) > 1) {
                                                            this.setState({ numberOfEmi: e.target.value, emiAmount: total / Number(e.target.value) });
                                                        } else if (e.target.value === "") {
                                                            this.setState({ numberOfEmi: "" });
                                                        }
                                                    }}></input>
                                            </div>
                                            <div className="col-3">
                                                <label className="form-label">Installment amount</label>
                                                {this.props.invoiceOperation === "view" && <div>${roundNumberWithRoundingFactorDecimalFix(Number(this.props.selectedAppointment?.billingdata.clinicToPatientBilling.total) / Number(this.state.numberOfEmi), 2)}</div>}
                                                {this.props.invoiceOperation !== "view" && <div>${this.state.numberOfEmi && total ? roundNumberWithRoundingFactorDecimalFix(total / Number(this.state.numberOfEmi), 2) : roundNumberWithRoundingFactorDecimalFix(0, 2)}</div>}
                                            </div>

                                        </>
                                    }

                                    {
                                        (this.state.isAdminFee) &&
                                        <div className="col-3">
                                            <label className="form-label">Admin & Safety Fee </label>
                                            <select className="form-select form-select-sm"
                                                disabled={this.props.invoiceOperation === "view"}
                                                value={this.state.adminFee} onChange={(e) => {
                                                    this.setState({ adminFee: e.target.value });
                                                }}>
                                                <option value="2">2%</option>
                                                <option value="3">3%</option>
                                                <option value="4">4%</option>
                                                <option value="5">5%</option>
                                            </select>
                                        </div>
                                    }
                                </div>


                            </div>


                            {
                                this.state.isShow &&
                                <>
                                    <button className="btn btn-outline-primary" onClick={(e: any) => { this.hideModal() }}>No</button>
                                    <button className="btn btn-primary" onClick={(e: any) => { this.setState({ isShow: false }) }}>Yes</button>
                                </>
                            }

                            <div className="d-flex justify-content-left align-items-center">
                                {
                                    (this.props.invoiceOperation === "create" || this.props.invoiceOperation === "edit" || this.props.invoiceOperation === "view") &&
                                    <div className="d-flex justify-content-left align-items-center">
                                        <div>Recurring Payment</div>
                                        <Switch
                                            disabled={this.props.invoiceOperation === "view"}
                                            checked={this.state.isRecurring}
                                            onChange={(e) => {
                                                this.setState({ isRecurring: !this.state.isRecurring });
                                            }}
                                            color="primary"
                                            name="checkedB"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                }
                                {
                                    ((this.props.invoiceOperation === "create" || this.props.invoiceOperation === "edit") && this.props.selectedAppointment &&
                                        this.props.selectedAppointment.clinic.metadata && this.props.selectedAppointment.clinic.metadata.adminsafetyfee === true) ?
                                        null :
                                        !this.state.isShow &&
                                        <div className="d-flex justify-content-left align-items-center">
                                            <div>Admin & Safety Fee </div>
                                            <Switch
                                                disabled={this.props.invoiceOperation === "view"}
                                                checked={this.state.isAdminFee}
                                                onChange={(e) => {
                                                    this.setState({ isAdminFee: !this.state.isAdminFee, adminFee: '3' })
                                                }}
                                                color="primary"
                                                name="checkedB"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />

                                        </div>
                                }
                                {!this.state.isShow &&
                                    <div className="d-flex justify-content-left align-items-center">
                                        <div>Include Tax</div>
                                        <Switch
                                            disabled={this.props.invoiceOperation === "view"}
                                            checked={this.state.enableTax}
                                            onChange={(e) => {
                                                this.setState({ enableTax: !this.state.enableTax })
                                            }}
                                            color="primary"
                                            name="checkedB"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                }
                            </div>





                            {
                                (this.props.invoiceOperation && this.props.invoiceOperation === "create") && !this.state.isShow &&
                                <Button variant="primary" disabled={(this.state.createServiceArray.length === 0 && !total)} onClick={(e: any) => {
                                    // this.handleCreateInvoice();
                                    this.handleSave();
                                }}>Create</Button>
                            }
                            {
                                (this.props.invoiceOperation && this.props.invoiceOperation === "edit") && !this.state.isShow &&
                                <>
                                    <Button variant="secondary" onClick={(e: any) => {
                                        this.resetEdit();
                                    }}>Reset</Button>
                                    <Button disabled={!total} variant="primary" onClick={(e: any) => {
                                        // this.handleCreateInvoice();
                                        this.handleSave();
                                    }}>Save</Button>
                                </>
                            }
                        </>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    paymentState: state.payment
});
const mapDispatchToProps = (dispatch: any) => ({
    createInvoice: (request: CreateInvoiceRequest) => {
        dispatch(createInvoicePending(request));
    },
    loadingStart: () => {
        dispatch(fetchLoadingPending());
    },
    loadingEnd: (status: any) => {
        dispatch(fetchLoadingSuccess(status));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceModal);