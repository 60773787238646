export enum USER_PASSWORD {
    LOAD_PENDING = 'LOAD_PENDING_USER_PASSWORD',
    LOAD_ERROR = 'LOAD_FAILED_USER_PASSWORD',
    LOAD_SUCCESS = 'LOAD_SUCCESS_USER_PASSWORD'
};

export interface UserPasswordRequest {
    uniqueId?: number;
    cpassword: string;
    npassword: string;
    rpassword: string;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface UserPasswordResponse {
    status: Status
}
export interface UserPasswordState {
    pending: boolean;
    userPassword: UserPasswordRequest;
    userPasswordResponse: UserPasswordResponse;
    error: any;
}

export interface FetchUserPasswordPendingAction {
    type: USER_PASSWORD.LOAD_PENDING;
    payload: UserPasswordRequest
}

export interface FetchUserPasswordErrorAction {
    type: USER_PASSWORD.LOAD_ERROR;
    error: any;
}
export interface FetchUserPasswordSuccessAction {
    type: USER_PASSWORD.LOAD_SUCCESS;
    userPassword: UserPasswordResponse
}

export type UserPasswordActionTypes = FetchUserPasswordErrorAction | FetchUserPasswordPendingAction | FetchUserPasswordSuccessAction;