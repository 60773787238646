import { takeEvery, put } from "redux-saga/effects";
import { LOGINFORGOTSENT, FetchLoginForgotSentPendingAction } from "../store/loginforgotsent/types";
import { fetchLoginForgotSentSuccess } from "../store/loginforgotsent/actions";

function* loginForgotSentPendingRun(action: FetchLoginForgotSentPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoginForgotSentSuccess(action.payload));
}

export default function* loginForgotSentPendingWatcher() {
    yield takeEvery(LOGINFORGOTSENT.LOAD_PENDING, loginForgotSentPendingRun)
}