/**
 * 
 * @param email string
 * @returns true if valid email else false.
 */
export const emailValidator = (email: string) => {
    const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(email);
}
/**
 * 
 * @param text string
 * @returns true if given string is null|undefinde|empty
 */
export const checkIfEmptyOrNull = (text: string) => {
    text = text.trim();
    if (text === undefined || text === null || text === '') {
        return true;
    }
    return false;
}
export const passwordValidator = (password: string) => {
    const regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regEx.test(password);
}
export const phoneValidator = (phone: string) => {
    const regEx = /^(\([0 - 9]{ 3 } \) | [0 - 9]{ 3 } -)[0 - 9]{ 3 } -[0 - 9]{ 4 }$/;
    return regEx.test(phone);
}
/**
 * 
 * @param url string
 * @returns true if valid url i.e website address
 */
export const urlValidator = (url: string) => {
    const regEx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return regEx.test(url);
}
/**
 * 
 * @param text 
 * Function allows only alphabets and space
 */
export const allowOnlyAlpha = (text: string) => {
    text = text.trim();
    const regEx = /^[a-zA-Z ]+$/;
    return regEx.test(text);
}
export const allowOnlyDigit = (text: string) => {
    text = text.trim();
    const regEx = /^[0-9]+$/;
    return regEx.test(text);
}
export const allowAlphabetsAndSpace = (text: string) => {
    const regEx = /^[a-zA-Z ]*$/
    return regEx.test(text);
}
export const alphanumericAndSpace =(text:string)=>{
    const regEx = /^[a-zA-Z0-9\-\s]+$/;
    return regEx.test(text);
}