import {
    PROVIDER,
    ProviderState,
    ProviderActionTypes
} from './types';

export const initialState: ProviderState = {
    pending: false,
    provider: { type: '', uniqueId: '' },
    providerResponse: {
        doctors: [], clinics: [], status: { error: false, msg: '' }, providers: []
    },
    service: { speciality: '' },
    serviceResponse: { services: [], status: { error: false, msg: '' } },
    providerUpdate: { id: '', type: '', uniqueId: '', actionType: '', userType: '' },
    providerUpdateResponse: { status: { error: false, msg: '' }, udpateData: '' },
    providerMapping: { id: '', type: '', uniqueid: '', userUniqueId: '' },
    providerMappingResponse: { status: { error: false, msg: '' } },
    error: null
};
export const providerReducer = (state = initialState, action: ProviderActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case PROVIDER.LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROVIDER.LOAD_PENDING:
            return {
                ...state,
                pending: true,
                provider: action.payload
            }
        case PROVIDER.LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                providerResponse: action.provider
            }
        case PROVIDER.LOAD_SERVICE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROVIDER.LOAD_SERVICE_PENDING:
            return {
                ...state,
                pending: true,
                service: action.payload
            }
        case PROVIDER.LOAD_SERVICE_SUCCESS:
            return {
                ...state,
                pending: false,
                serviceResponse: action.service
            }
        case PROVIDER.PROVIDER_UPDATE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROVIDER.PROVIDER_UPDATE_PENDING:
            return {
                ...state,
                pending: true,
                providerUpdate: action.payload
            }
        case PROVIDER.PROVIDER_UPDATE_SUCCESS:
            if (action.providerUpdate.udpateData.type === "enableAppointment"
                && action.providerUpdate.udpateData.userType === "clinic") {
                return {
                    ...state,
                    pending: false,
                    providerUpdateResponse: action.providerUpdate,
                    providerResponse: {
                        ...state.providerResponse,
                        doctors: state.providerResponse.doctors.map((value, index) => {
                            if (value.id === action.providerUpdate.udpateData.id) {
                                if (action.providerUpdate.udpateData.enableAppointment === "0") {
                                    value.enableAppointment = "1";
                                } else if (action.providerUpdate.udpateData.enableAppointment === "1") {
                                    value.enableAppointment = "0";
                                }
                            }
                            return value;
                        })
                    }
                }
            } else if (action.providerUpdate.udpateData.type === "enableAppointment"
                && action.providerUpdate.udpateData.userType === "doctor") {
                return {
                    ...state,
                    pending: false,
                    providerUpdateResponse: action.providerUpdate,
                    providerResponse: {
                        ...state.providerResponse,
                        clinics: state.providerResponse.clinics.map((value, index) => {
                            if (value.id === action.providerUpdate.udpateData.id) {
                                if (action.providerUpdate.udpateData.enableAppointment === "0") {
                                    value.enableAppointment = "1";
                                } else if (action.providerUpdate.udpateData.enableAppointment === "1") {
                                    value.enableAppointment = "0";
                                }
                            }
                            return value;
                        })
                    }
                }
            } else if (action.providerUpdate.udpateData.type === "hours"
                && action.providerUpdate.udpateData.userType === "clinic") {
                return {
                    ...state,
                    pending: false,
                    providerUpdateResponse: action.providerUpdate,
                    providerResponse: {
                        ...state.providerResponse,
                        doctors: state.providerResponse.doctors.map((value, index) => {
                            if (value.id === action.providerUpdate.udpateData.id) {
                                value.hours = action.providerUpdate.udpateData.hours
                            }
                            return value;
                        })
                    }
                }
            } else if (action.providerUpdate.udpateData.type === "hours"
                && action.providerUpdate.udpateData.userType === "doctor") {
                return {
                    ...state,
                    pending: false,
                    providerUpdateResponse: action.providerUpdate,
                    providerResponse: {
                        ...state.providerResponse,
                        clinics: state.providerResponse.clinics.map((value, index) => {
                            if (value.id === action.providerUpdate.udpateData.id) {
                                value.hours = action.providerUpdate.udpateData.hours
                            }
                            return value;
                        })
                    }
                }
            } else if (action.providerUpdate.udpateData.type === "services"
                && action.providerUpdate.udpateData.userType === "clinic") {
                return {
                    ...state,
                    pending: false,
                    providerUpdateResponse: action.providerUpdate,
                    providerResponse: {
                        ...state.providerResponse,
                        services: state.providerResponse.doctors.map((value, index) => {
                            if (value.id === action.providerUpdate.udpateData.id) {
                                value.services = action.providerUpdate.udpateData.services
                            }
                            return value;
                        })
                    }
                }
            } else if (action.providerUpdate.udpateData.type === "services"
                && action.providerUpdate.udpateData.userType === "doctor") {
                return {
                    ...state,
                    pending: false,
                    providerUpdateResponse: action.providerUpdate,
                    providerResponse: {
                        ...state.providerResponse,
                        services: state.providerResponse.clinics.map((value, index) => {
                            if (value.id === action.providerUpdate.udpateData.id) {
                                value.services = action.providerUpdate.udpateData.services
                            }
                            return value;
                        })
                    }
                }
            } else if (action.providerUpdate.udpateData.type === "appointment"
                && action.providerUpdate.udpateData.userType === "clinic" &&
                action.providerUpdate.udpateData.actionType === "remove") {
                return {
                    ...state,
                    pending: false,
                    providerUpdateResponse: action.providerUpdate,
                    providerResponse: {
                        ...state.providerResponse,
                        doctors: state.providerResponse.doctors.map((value, index) => {
                            if (value.id === action.providerUpdate.udpateData.id) {
                                value.potentialAppointmentTypes = value.potentialAppointmentTypes.filter((appointment: any, aIndex: number) => {
                                    return appointment.id !== action.providerUpdate.udpateData.potentialAppointment.id;
                                })
                            }
                            return value;
                        })
                    }
                }
            } else if (action.providerUpdate.udpateData.type === "appointment"
                && action.providerUpdate.udpateData.userType === "clinic" &&
                action.providerUpdate.udpateData.actionType === "add") {
                return {
                    ...state,
                    pending: false,
                    providerUpdateResponse: action.providerUpdate,
                    providerResponse: {
                        ...state.providerResponse,
                        doctors: state.providerResponse.doctors.map((value, index) => {
                            if (value.id === action.providerUpdate.udpateData.id) {
                                value.potentialAppointmentTypes = action.providerUpdate.potentialAppointmentTypes
                            }
                            return value;
                        })
                    }
                }
            } else if (action.providerUpdate.udpateData.type === "appointment"
                && action.providerUpdate.udpateData.userType === "doctor" &&
                action.providerUpdate.udpateData.actionType === "remove") {
                return {
                    ...state,
                    pending: false,
                    providerUpdateResponse: action.providerUpdate,
                    providerResponse: {
                        ...state.providerResponse,
                        clinics: state.providerResponse.clinics.map((value, index) => {
                            if (value.id === action.providerUpdate.udpateData.id) {
                                value.potentialAppointmentTypes = value.potentialAppointmentTypes.filter((appointment: any, aIndex: number) => {
                                    return appointment.id !== action.providerUpdate.udpateData.potentialAppointment.id;
                                })
                            }
                            return value;
                        })
                    }
                }
            } else if (action.providerUpdate.udpateData.type === "appointment"
                && action.providerUpdate.udpateData.userType === "doctor" &&
                action.providerUpdate.udpateData.actionType === "add") {
                return {
                    ...state,
                    pending: false,
                    providerUpdateResponse: action.providerUpdate,
                    providerResponse: {
                        ...state.providerResponse,
                        clinics: state.providerResponse.clinics.map((value, index) => {
                            if (value.id === action.providerUpdate.udpateData.id) {
                                value.potentialAppointmentTypes = action.providerUpdate.potentialAppointmentTypes
                            }
                            return value;
                        })
                    }
                }
            }
            return { ...NewState }
        case PROVIDER.PROVIDER_MAPPING_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROVIDER.PROVIDER_MAPPING_PENDING:
            return {
                ...state,
                pending: true
            }
        case PROVIDER.PROVIDER_MAPPING_SUCCESS:
            return {
                ...state,
                pending: false,
                providerMappingResponse: action.providerMapping
            }
        case PROVIDER.PMS_PROVIDER_SCHEDULE_UPDATE:
            return {
                ...state,
                pending: true,
                error: null,
                pmsProviderScheduleUpdateRequest: action.payload,
                pmsProviderScheduleUpdateResponse: undefined
            }
        case PROVIDER.PMS_PROVIDER_SCHEDULE_UPDATE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                pmsProviderScheduleUpdateRequest: undefined,
                pmsProviderScheduleUpdateResponse: undefined
            }
        case PROVIDER.PMS_PROVIDER_SCHEDULE_UPDATE_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                pmsProviderScheduleUpdateRequest: undefined,
                pmsProviderScheduleUpdateResponse: action.payload
            }
        default:
            return NewState;

    }
};