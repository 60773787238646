import React from 'react';
import { NavLink } from 'react-router-dom';
class FooterBar extends React.Component {
    render() {
        return (
            <>
                <footer className="container footer py-3 border-top">
                    <div className="footer-content px-3">
                        <p className="m-0 text-black-50">Copyright &copy; {new Date().getFullYear()} XCARE PRO. All rights reserved.</p>
                        <span>
                            <NavLink to="/terms-condition" className="text-decoration-none me-2 text-black-50">Terms of Use</NavLink>
                            <span className="text-black-50"> | </span>
                            <NavLink to="/privacy-policy" className="text-decoration-none text-black-50"> Privacy &amp; Policy</NavLink>
                        </span>
                    </div>
                </footer>
            </>
        );
    }
}
export default FooterBar;