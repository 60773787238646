export enum APPOINTMENT {
    APPOINTMENT_LOAD_PENDING = 'APPOINTMENT_LOAD_PENDING',
    APPOINTMENT_LOAD_SUCCESS = 'APPOINTMENT_LOAD_SUCCESS',
    APPOINTMENT_LOAD_ERROR = 'APPOINTMENT_LOAD_ERROR',
    APPOINTMENT_DETAIL = 'APPOINTMENT_DETAIL',
    APPOINTMENT_DETAIL_FAILURE = 'APPOINTMENT_DETAIL_FAILURE',
    APPOINTMENT_DETAIL_SUCCESS = 'APPOINTMENT_DETAIL_SUCCESS',
    MODAL_EMAIL_INFO = 'MODAL_EMAIL_INFO',
    MODAL_EMAIL_INFO_SUCCESS = 'MODAL_EMAIL_SUCCESS',
    MODAL_EMAIL_INFO_FAILURE = 'MODAL_EMAIL_INFO_FAILURE',
    MANUAL_APPOINTMENT = 'MANUAL_APPOINTMENT',
    MANUAL_APPOINTMENT_FAILURE = 'MANUAL_APPOINTMENT_FAILURE',
    MANUAL_APPOINTMENT_SUCCESS = 'MANUAL_APPOINTMENT_SUCCESS',
    REQUEST_FOR_MEDICAL_HISTORY = 'REQUEST_FOR_MEDICAL_HISTORY',
    REQUEST_FOR_MEDICAL_HISTORY_ERROR = 'REQUEST_FOR_MEDICAL_HISTORY_ERROR',
    REQUEST_FOR_MEDICAL_HISTORY_SUCCESS = 'REQUEST_FOR_MEDICAL_HISTORY_SUCCESS',
    EDIT_APPOINTMENT = 'EDIT_APPOINTMENT',
    EDIT_APPOINTMENT_SUCCESS = 'EDIT_APPOINTMENT_SUCCESS',
    EDIT_APPOINTMENT_FAILURE = 'EDIT_APPOINTMENT_FAILURE',
    BOOK_SLOT = 'BOOK_SLOT',
    BOOK_SLOT_FAILURE = 'BOOK_SLOT_FAILURE',
    BOOK_SLOT_SUCCESS = 'BOOK_SLOT_SUCCESS',
    APPOINTMENT_UNVERIFIED = 'APPOINTMENT_UNVERIFIED',
    APPOINTMENT_UNVERIFIED_FAILED = 'APPOINTMENT_UNVERIFIED_FAILED',
    APPOINTMENT_UNVERIFIED_SUCCESS = 'APPOINTMENT_UNVERIFIED_SUCCESS',
    CALL_WAITLIST_API = 'CALL_WAITLIST_API',
    CALL_EMERGENCY_API = 'CALL_EMERGENCY_API'
}

export interface AppointmentRequest {
    uniqueId: string; // clinic or doctor unique id
    modelType: string; // clinic or doctor
    appointmentType: string; // requeste or scheduled or completed
    page: number; // eg 1 or 2 or 3.
    results?: number; // eg 10 or 20 or 30.
    loader?: boolean;
    realTimeCall?: boolean;
    fname?: string;
    lname?: string;
    viewCalendar?: boolean;
    from?: string;
    to?: string;
}
export interface Appointment {
    id: string;
    uniqueid: string;
    status: string;
    requests: any;
    appointmentType: string;
    clinic: any;
    doctor: { fullname: string, id: string, email: string, uniqueid: string, gender: string },
    patient: { phone: string, email: string, lname: string, fname: string, id: string, uniqueid: string },
    metadata: any;
    date: string;
    time: string;
}
export interface AppointmentResponse {
    status: { error: boolean, msg: string };
    appointments: Appointment[];
    ratings: string;
    total: string;
    appointmentType: string;
    realTimeCall?: boolean;
}
export interface AppointmentDetailRequest {
    appointmentId: string;
    clinicId: string;
    providerType: string;
}
export interface AppointmentDetailResponse {
    status: { error: boolean, msg: string };
}
export interface ModalEmailInfoRequest {
    email?: string;
    fname?: string;
    lname?: string;
}
export interface ModalEmailInfoResponse {
    id: string;
    uniqueid: string;
    fname: string;
    lname: string;
    email: string;
    phone: string;
}
export interface ManualAppointmentRequest {
    body: any;
    type: string;
    uniqueId: string;
    isLastItem?: boolean;
}
export interface ManualAppointmentResponse {
    status: { error: boolean, msg: string };
}
export interface RequestForMedicalHistoryRequest {
    clinicUniqueId: string;
    appointmentUniqueId: string;
}
export interface RequestForMedicalHistoryResponse {
    status: { error: boolean, msg: string };
}
export interface AppointmentEditRequest {
    doctorid: string;
    appointmenttypeid: string;
    clinicUniqueId: string;
    appointmentUniqueId: string;
}
export interface AppointmentEditResponse {
    status: { error: boolean, msg: string };
}
export interface BookSlotRequest {
    clinicUniqueId?: string;
    data: {
        type: string;
        doctor?: {
            id: any;
        }
        patient: {
            fname: string;
            lname: string;
        },
        dateTime: {
            date: string;
            time?: string;
            starttime?: any;
            endtime?: any;
        }
        duration?: any;
    }
}
export interface BookSlotResponse {
    status: { error: boolean, msg: string };
}
export interface AppointmentUnverifiedRequest {
    clinicUniqueId: string;
    appointmentUniqueId: string;
}
export interface AppointmentUnverifiedResponse {
    status: { error: boolean, msg: string };
}
export interface AppointmentState {
    pending: boolean;
    appointmentRequest?: AppointmentRequest;
    appointmentResponse?: AppointmentResponse;
    appointmentDetailRequest?: AppointmentDetailRequest;
    appointmentDetailResponse?: AppointmentDetailResponse;
    modalEmailInfoRequest?: ModalEmailInfoRequest;
    modalEmailInfoResponse?: ModalEmailInfoResponse;
    manualAppointmentRequest?: ManualAppointmentRequest;
    manualAppointmentResponse?: ManualAppointmentResponse;
    requestForMedicalHistoryRequest?: RequestForMedicalHistoryRequest;
    requestForMedicalHistoryResponse?: RequestForMedicalHistoryResponse;
    appointmentEditRequest?: AppointmentEditRequest;
    appointmentEditResponse?: AppointmentEditResponse;
    bookSlotRequest?: BookSlotRequest;
    bookSlotResponse?: BookSlotResponse;
    appointmentUnverifiedRequest?: AppointmentUnverifiedRequest;
    appointmentUnverifiedResponse?: AppointmentUnverifiedResponse;
    error: any;
    callApiCount: number;
    callEmergencyCount: number;
}

export interface AppointmentPendingAction {
    type: APPOINTMENT.APPOINTMENT_LOAD_PENDING;
    payload: AppointmentRequest;
}
export interface AppointmentSuccessAction {
    type: APPOINTMENT.APPOINTMENT_LOAD_SUCCESS;
    appointment: AppointmentResponse;
}
export interface AppointmentErrorAction {
    type: APPOINTMENT.APPOINTMENT_LOAD_ERROR;
    error: any;
}

export interface AppointmentDetail {
    type: APPOINTMENT.APPOINTMENT_DETAIL
    payload: AppointmentDetailRequest;
}
export interface AppointmentDetailError {
    type: APPOINTMENT.APPOINTMENT_DETAIL_FAILURE
    error: any;
}
export interface AppointmentDetailSuccess {
    type: APPOINTMENT.APPOINTMENT_DETAIL_SUCCESS
    payload: AppointmentDetailResponse;
}
export interface ModalEmailInfoPending {
    type: APPOINTMENT.MODAL_EMAIL_INFO;
    payload: ModalEmailInfoRequest;
}
export interface ModalEmailInfoError {
    type: APPOINTMENT.MODAL_EMAIL_INFO_FAILURE
    payload: any;
}
export interface ModalEmailInfoSuccess {
    type: APPOINTMENT.MODAL_EMAIL_INFO_SUCCESS;
    payload: ModalEmailInfoResponse;
}
export interface ManualAppointmentPendingAction {
    type: APPOINTMENT.MANUAL_APPOINTMENT;
    payload: ManualAppointmentRequest;
}
export interface ManualAppointmentFailureAction {
    type: APPOINTMENT.MANUAL_APPOINTMENT_FAILURE;
    payload: any;
}
export interface ManualAppointmentSuccessAction {
    type: APPOINTMENT.MANUAL_APPOINTMENT_SUCCESS;
    payload: ManualAppointmentResponse;
}
export interface RequestForMedicalHistoryPendingAction {
    type: APPOINTMENT.REQUEST_FOR_MEDICAL_HISTORY;
    payload: RequestForMedicalHistoryRequest;
}
export interface RequestForMedicalHistoryErrorAction {
    type: APPOINTMENT.REQUEST_FOR_MEDICAL_HISTORY_ERROR;
    payload: any;
}
export interface RequestForMedicalHistorySuccessAction {
    type: APPOINTMENT.REQUEST_FOR_MEDICAL_HISTORY_SUCCESS;
    payload: RequestForMedicalHistoryResponse;
}
export interface AppointmentEditPendingAction {
    type: APPOINTMENT.EDIT_APPOINTMENT;
    payload: AppointmentEditRequest;
}
export interface AppointmentEditFailureAction {
    type: APPOINTMENT.EDIT_APPOINTMENT_FAILURE;
    payload: any;
}
export interface AppointmentEditSuccessAction {
    type: APPOINTMENT.EDIT_APPOINTMENT_SUCCESS;
    payload: AppointmentEditResponse;
}
export interface BookSlotPendingAction {
    type: APPOINTMENT.BOOK_SLOT;
    payload: BookSlotRequest;
}
export interface BookSlotFailureAction {
    type: APPOINTMENT.BOOK_SLOT_FAILURE;
    payload: any;
}
export interface BookSlotSuccessAction {
    type: APPOINTMENT.BOOK_SLOT_SUCCESS;
    payload: BookSlotResponse;
}
export interface AppointmentUnverifiedPendingAction {
    type: APPOINTMENT.APPOINTMENT_UNVERIFIED;
    payload: AppointmentUnverifiedRequest;
}
export interface AppointmentUnverifiedSuccesAction {
    type: APPOINTMENT.APPOINTMENT_UNVERIFIED_SUCCESS;
    payload: AppointmentUnverifiedResponse;
}
export interface AppointmentUnverifiedErrorAction {
    type: APPOINTMENT.APPOINTMENT_UNVERIFIED_FAILED;
    payload: any;
}
export interface CallWaitlistApiAction {
    type: APPOINTMENT.CALL_WAITLIST_API
}
export interface CallEmergencyApiAction {
    type: APPOINTMENT.CALL_EMERGENCY_API
}
export type AppointmentActionTypes = AppointmentErrorAction | AppointmentPendingAction | AppointmentSuccessAction |
    AppointmentDetail | AppointmentDetailError | AppointmentDetailSuccess | ModalEmailInfoPending | ModalEmailInfoError
    | ModalEmailInfoSuccess | ManualAppointmentPendingAction | ManualAppointmentFailureAction | ManualAppointmentSuccessAction |
    RequestForMedicalHistoryErrorAction | RequestForMedicalHistoryPendingAction | RequestForMedicalHistorySuccessAction |
    AppointmentEditPendingAction | AppointmentEditFailureAction | AppointmentEditSuccessAction | BookSlotPendingAction |
    BookSlotFailureAction | BookSlotSuccessAction | AppointmentUnverifiedPendingAction |
    AppointmentUnverifiedSuccesAction | AppointmentUnverifiedErrorAction | CallWaitlistApiAction | CallEmergencyApiAction;