import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getSettings = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + data.type + "/" + data.uniqueId + "/setting/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
}

export const saveTax = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'taxable/clinic/' + data.uniqueId + '/',
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data.requestData
        }
    };
    return commonApi(request);
}

export const saveAdminSafetyFee = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "clinic/" + data.uniqueId + "/adminsafetyfee/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data.requestData
        }
    };
    return commonApi(request);
}

export const uploadFile = (data: any) => {
  const request: CommonApiOptions = {
    url: configs.MESSAGE_CENTER_API + 'messagecenter/uploadpdf',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data,
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: false
    }
  };
  return commonApi(request);
};