import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../../../assets/js/canvasjs.react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { chartDataConfigure, chartOptions, CHART_LABEL, COLORS, getChart } from '../common/Charts';
import moment from 'moment';
import { NoDataPresent } from '../common/NoDataPresent';
import DateWeekModal from '../common/DateWeekModal';
import { CustomDateModal } from '../../../pages/dashboardCharts/CustomDateModal';
import { FilterIcons } from '../common/FilterIcons';

var CanvasJS:any = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const AdministrativeConfirmation = () => {
    const dispatch = useDispatch();
    const [data, setData]: any = useState([]);
    const [chartData, setChartData]: any = useState([]);
    const [isDateChange, setIsDateChange] = useState(false);
    const [isCustomDateChange, setIsCustomDateChange] = useState(false);
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'));
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    
    useEffect(() => {
        fetchChart();
    }, [startDate, endDate]);

    const fetchChart = async () => {
        dispatch(fetchLoadingPending());
        const request = {
            clinicid: userData.clinics[0].id,
            startdate: startDate,
            enddate: endDate
        }
        setData(await getChart(request, 'getadministrativestatisticdata'));
        dispatch(fetchLoadingSuccess(false));
    }
    useEffect(() => {
        if (data) {
            let find = data.find((item: any) => item.label === 'Confirmation');
            if (find) {
                let checkData = find.value.find((item: any) => item.value > 0);
                if (checkData) {
                    setChartData(find.value.reverse());
                } else {
                    setChartData([]);
                }
            } else {
                setChartData([]);
            }
        }
    },[data]);
    useEffect(() => {
        if (chartData && chartData.length > 0) {
            let dataP = chartDataConfigure(chartData);
            let chartOpt = chartOptions(dataP,'Administrative-Confirmation');
            var charts = new CanvasJS.Chart("administrativeconfirmation", { ...chartOpt });
            charts.render();
        }
    }, [chartData]);

    const handleDateChange = (sDate: any, eDate: any) => {
        setIsDateChange(false);
        setIsCustomDateChange(false);
        setStartDate(sDate);
        setEndDate(eDate);
    }
    
    return (
        <div className={chartData && chartData.length <= 0 ? "card alert-warning d-flex justify-content-center align-items-center position-relative" : "card"}>
            {/* {(chartData && chartData.length <= 0) && <NoDataPresent name={'Administrative-Confirmation'} />}
                <span className={'d-flex flex-row justify-content-end'}>
                    <i className="bi bi-calendar fs-4 mx-2" onClick={() => { setIsDateChange(true) }}></i>
                    <i className="bi bi-calendar-week fs-4" onClick={() => { setIsCustomDateChange(true) }}></i>
                </span>
            <div className={chartData && chartData.length <= 0 ? 'd-none' : ''} id="administrativeconfirmation"></div>
             */}
            <div className="container-box">
                <FilterIcons handleIsDateChange={()=>{setIsDateChange(true)}} handleIsCustomDateChange={()=>{setIsCustomDateChange(true)}} />
                <div className="chart-box">
                    {(chartData && chartData.length <= 0) && <NoDataPresent name={'Administrative-Confirmation'} />}
                    <div className={chartData && chartData.length <= 0 ? 'd-none w-100 h-100' : 'w-100 h-100'} id="administrativeconfirmation" ></div>
                </div>
            </div>
            {
                (isDateChange) &&
                <DateWeekModal
                    show={isDateChange}
                    handleClose={() => { setIsDateChange(false) }}
                    handleDateChange={(sDate:any,eDate:any)=>{handleDateChange(sDate,eDate)}}
                />
            }
            {
                (isCustomDateChange) &&
                <CustomDateModal
                    show={isCustomDateChange}
                    handleClose={() => { setIsCustomDateChange(false) }}
                    handleChangeStartEndDate={(sDate: any, eDate: any) => { handleDateChange(sDate, eDate) }}
                />
            }
        </div>
    )
};
