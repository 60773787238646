import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IAppState } from "../../../store";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../store/loadingIndicator/actions";
import { CreateCampaign } from "../../components/messageCenter/CreateCampaign";
import {
  campaignList,
  updateCampaignStatus,
  deleteCampaign,
  manualCampaignList,
  updateManualCampaignStatus,
  deleteManualCampaign,
  updateManualCampaignPatientStatus,
  updateManualCampaignImportedPatientStatus,
} from "../../services/messageCenter";
import { confirmAlert } from "react-confirm-alert";
import { CreateManualCampaign } from "../../components/messageCenter/CreateManualCampaign";
import NotificationIcon from "../../assets/images/notification-img.svg";
import ReportProblemIcon from "../../assets/images/report_problem-icon.svg";

export const AllCampaign = () => {
  const userData = useSelector(
    (state: IAppState) => state.userData.userDataResponse,
    shallowEqual
  );
  const isLoaded = useSelector(
    (state: IAppState) => state.loading.pending,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [campaigns, setCampaigns] = useState([]);
  const [manualCampaigns, setManualCampaigns]: any = useState([]);
  const createCampaignRef: any = useRef();
  const createManualCampaignRef: any = useRef();
  useEffect(() => {
    if (userData && userData.clinics[0] && userData.clinics[0].id) {
      getCampaignListFunc();
      getManualCampaignListFunc();
    }
  }, []);

  const getCampaignListFunc = () => {
    dispatch(fetchLoadingPending());
    campaignList({ clinicid: userData.clinics[0].id })
      .then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success.error === false) {
          setCampaigns(success.data);
          return;
        }
        toast.error(success.message);
      })
      .catch((err) => {
        dispatch(fetchLoadingSuccess(false));
        toast.error("Something went wrong.");
      });
  };

  const getManualCampaignListFunc = () => {
    dispatch(fetchLoadingPending());
    manualCampaignList({ clinicid: userData.clinics[0].id })
      .then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success.error === false) {
          setManualCampaigns(success.data);
          return;
        }
        toast.error(success.message);
      })
      .catch((err) => {
        dispatch(fetchLoadingSuccess(false));
        toast.error("Something went wrong.");
      });
  };

  const onRowClick = (item: any) => {
    createCampaignRef.current.showModal({
      item: item,
      getCampaignListFunc: getCampaignListFunc,
    });
  };

  const onManualRowClick = (item: any) => {
    item.isactive = item.isexecute;
    createManualCampaignRef.current.showModal({
      item: item,
      getManualCampaignListFunc: getManualCampaignListFunc,
    });
  };

  const updateStatus = (item: any) => {
    confirmAlert({
      title: "",
      message:
        "Please confirm, if you want to " +
        (item.isactive !== true ? "enable" : "disable") +
        " this configuration.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            updateCampaignStatus({
              clinicid: userData.clinics[0].id,
              messagecentercampaign_id: item.messagecentercampaign_id,
              isactive: !item.isactive,
            })
              .then((success) => {
                if (success && success.error === false) {
                  getCampaignListFunc();
                }
              })
              .catch((err) => {});
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const updateManualStatus = (item: any) => {
    confirmAlert({
      title: ``,
      message: `Please confirm, if you want to ${
        item.isactive !== true ? "enable" : "disable"
      } this configuration. Note: Re-call/Re-engage messages will be sent immediately if you activate this campaign now.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            if (item.categorytype === "signinpatientmanual") {
              updateManualCampaignPatientStatus({
                clinicid: userData.clinics[0].id,
                messagecentermanualcampaign_id:
                  item.messagecentermanualcampaign_id,
                isactive: !item.isactive,
              })
                .then((success) => {
                  if (success && success.error === false) {
                    setManualCampaigns(
                      manualCampaigns.map((mItem: any) => {
                        if (
                          mItem.messagecentermanualcampaign_id ===
                          item.messagecentermanualcampaign_id
                        ) {
                          return {
                            ...mItem,
                            isactive: !item.isactive ? true : false,
                            isexecute: !item.isactive ? true : false,
                          };
                        }
                        return mItem;
                      })
                    );
                  }
                })
                .catch((err) => {});
            } else if (item.categorytype === "importedpatientmanual") {
              updateManualCampaignImportedPatientStatus({
                clinicid: userData.clinics[0].id,
                messagecentermanualcampaign_id:
                  item.messagecentermanualcampaign_id,
                isactive: !item.isactive,
              })
                .then((success) => {
                  if (success && success.error === false) {
                    setManualCampaigns(
                      manualCampaigns.map((mItem: any) => {
                        if (
                          mItem.messagecentermanualcampaign_id ===
                          item.messagecentermanualcampaign_id
                        ) {
                          return {
                            ...mItem,
                            isactive: !item.isactive ? true : false,
                            isexecute: !item.isactive ? true : false,
                          };
                        }
                        return mItem;
                      })
                    );
                  }
                })
                .catch((err) => {});
            } else {
              updateManualCampaignStatus({
                clinicid: userData.clinics[0].id,
                messagecentermanualcampaign_id:
                  item.messagecentermanualcampaign_id,
                isactive: !item.isactive,
              })
                .then((success) => {
                  if (success && success.error === false) {
                    setManualCampaigns(
                      manualCampaigns.map((mItem: any) => {
                        if (
                          mItem.messagecentermanualcampaign_id ===
                          item.messagecentermanualcampaign_id
                        ) {
                          return {
                            ...mItem,
                            isactive: !item.isactive ? true : false,
                            isexecute: !item.isactive ? true : false,
                          };
                        }
                        return mItem;
                      })
                    );
                  }
                })
                .catch((err) => {});
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteCampaignFunc = (item: any) => {
    confirmAlert({
      title: "",
      message: item.isactive
        ? "Are you sure you want to archive ?"
        : "Are you sure you want to delete ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(fetchLoadingPending());
            deleteCampaign({
              clinicid: userData.clinics[0].id,
              messagecentercampaign_id: item.messagecentercampaign_id,
            })
              .then((success) => {
                dispatch(fetchLoadingSuccess(false));
                if (success && success.error === false) {
                  getCampaignListFunc();
                }
              })
              .catch((err) => {
                dispatch(fetchLoadingSuccess(false));
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const deleteManualCampaignFunc = (item: any) => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to archive?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(fetchLoadingPending());
            deleteManualCampaign({
              clinicid: userData.clinics[0].id,
              messagecentermanualcampaign_id:
                item.messagecentermanualcampaign_id,
            })
              .then((success) => {
                dispatch(fetchLoadingSuccess(false));
                if (success && success.error === false) {
                  getManualCampaignListFunc();
                }
              })
              .catch((err) => {
                dispatch(fetchLoadingSuccess(false));
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  return (
    <section className="all-campaign p-4">
      <div className="row mx-0">
        <div className="col-12">
          <div className="d-flex justify-content-between">
            <div>
              {/* <div className="campaning-title">All campaigns</div>
              <div className="campaning-desc">Setup automated pre-scheduled campaigns</div> */}
            </div>
            <div className="stp-action align-self-center">
              <button
                className="btn btn-outline-primary me-2"
                onClick={() => {
                  createManualCampaignRef.current.showModal();
                }}
              >
                Create manual campaign
              </button>
              <button
                className="btn btn-outline-primary"
                onClick={() => {
                  createCampaignRef.current.showModal();
                }}
              >
                Create auto campaign
              </button>
            </div>
          </div>
        </div>
        <div className="col-12">
          {isLoaded === false &&
            campaigns.length === 0 &&
            manualCampaigns.length === 0 && (
              <div className="my-4 border px-4 py-3 border-1 rounded d-flex warning-box">
                <img src={ReportProblemIcon} className="align-self-center" />
                <div className="ms-4">
                  <div>You don’t have any campaigns</div>
                  <small>
                    eg. if there is an event or promotion scheduled then you can
                    notify the patients.
                  </small>
                </div>
              </div>
            )}
          {campaigns.map((item: any, index: number) => {
            const computeTo = () => {
              if (item.categorytype === "event") {
                if (item.patient_list_id) {
                  return "Patient list";
                }
                if (item?.eventcampign_type === "all") {
                  return "All patients";
                }
                if (item?.eventcampign_type === "active") {
                  return "Active patients";
                }
                if (item?.eventcampign_type === "inactive") {
                  return "Inactive patients";
                }
                if (item?.eventcampign_type === "insurance") {
                  let str = "Insurance ";
                  if (item?.insurance === "yes") {
                    str += " (Yes)";
                  } else if (item?.insurance === "no") {
                    str += " (No)";
                  } else if (item?.insurance === "all") {
                    str += " (All patients)";
                  }
                  return str;
                }
              } else {
                return (
                  <>
                    {item.overduepatients !== null
                      ? item.overduepatients === "0,0"
                        ? "All patients"
                        : item.overduepatients.replace(",", "-") + " months"
                      : "-"}
                  </>
                );
              }
            };
            return (
              <div className="my-4 border px-3 py-3 border-1 rounded d-flex camp-all custom-camp-box justify-content-between w-100">
                <div className="camp-title d-flex w-20">
                  <img src={NotificationIcon} className="me-2" />
                  <span className="align-self-center fw-bold">
                    {item.title}
                  </span>
                </div>
                <div className="d-flex justify-content-between w-80">
                  <div className="d-flex justify-content-between w-80">
                    <div className="camp-category">
                      <small className="text-muted">Category</small>
                      <div className="fw-bold">{item.categorytype}</div>
                    </div>
                    <div className="camp-type">
                      <small className="text-muted">Type</small>
                      <div className="fw-bold">
                        {item?.templatetypes ?? item?.eventcampign_type}
                      </div>
                    </div>
                    <div className="camp-to">
                      <small className="text-muted">To</small>
                      <div className="fw-bold">{computeTo()}</div>
                    </div>
                    <div className="camp-create">
                      <small className="text-muted">Created On</small>
                      <div className="fw-bold">
                        {moment(item.created_at).format("MMM DD, YYYY - LT")}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end w-20">
                    <div className="form-check form-switch d-inline-block align-self-center mx-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        disabled={item.isactive}
                        checked={item.isactive}
                        onChange={(e) => {
                          updateStatus(item);
                        }}
                      />
                    </div>
                    {/* <button className="btn p-0"><img src={require("../../assets/images/more-op-btn.svg")} /></button> */}
                    {/*  <div className="noti-btns d-flex align-self-center">
                        <div onClick={() => {
                          deleteCampaignFunc(item);
                        }}>Delete</div>
                      </div> */}
                    <div className="noti-btns d-flex align-self-center btn-link">
                      <div
                        onClick={() => {
                          onRowClick(item);
                        }}
                      >
                        {item.isactive ? "View" : "Edit"}
                      </div>
                      <button
                        className="btn p-0 btn-link text-secondary"
                        onClick={() => {
                          deleteCampaignFunc(item);
                        }}
                      >
                        {item.isactive ? (
                          <i className="ms-2 bi bi-patch-check text-danger"></i>
                        ) : (
                          <i className="ms-2 bi bi-x-circle-fill text-danger"></i>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {manualCampaigns.map((item: any, index: number) => {
            return (
              <div className="my-4 border px-3 py-3 border-1 rounded d-flex camp-all custom-camp-box justify-content-between w-100">
                <div className="camp-title d-flex w-20">
                  <img src={NotificationIcon} className="me-2" />
                  <span className="align-self-center fw-bold">
                    {item.title}
                  </span>
                </div>
                <div className="d-flex justify-content-between w-80">
                  <div className="d-flex justify-content-between w-80">
                    <div className="camp-category">
                      <small className="text-muted">Category</small>
                      <div className="fw-bold">{item.categorytype}</div>
                    </div>
                    <div className="camp-type">
                      <small className="text-muted">Type</small>
                      <div className="fw-bold">{item.templatetypes}</div>
                    </div>
                    <div className="camp-to">
                      <small className="text-muted">To</small>
                      <div className="fw-bold">{"Selected patient"}</div>
                    </div>
                    <div className="camp-create">
                      <small className="text-muted">Created On</small>
                      <div className="fw-bold">
                        {moment(item.created_at).format("MMM DD, YYYY - LT")}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end w-20">
                    {
                      <>
                        {
                          <div className="form-check form-switch d-inline-block align-self-center mx-2">
                            <input
                              disabled={item.isexecute}
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              checked={item.isexecute}
                              onChange={(e) => {
                                updateManualStatus(item);
                              }}
                            />
                          </div>
                        }
                        {/* <button className="btn p-0"><img src={require("../../assets/images/more-op-btn.svg")} /></button> */}
                        {/*  <div className="noti-btns d-flex align-self-center">
                              <div onClick={() => {
                                deleteCampaignFunc(item);
                              }}>Delete</div>
                            </div> */}
                        <div className="noti-btns d-flex align-self-center btn-link">
                          {
                            <div
                              onClick={() => {
                                onManualRowClick(item);
                              }}
                            >
                              {item.isexecute ? "View" : "Edit"}
                            </div>
                          }
                          {
                            <button
                              className="btn p-0 btn-link text-secondary"
                              onClick={() => {
                                deleteManualCampaignFunc(item);
                              }}
                            >
                              {item.isexecute ? (
                                <i className="ms-2 bi bi-patch-check text-danger"></i>
                              ) : (
                                <i className="ms-2 bi bi-x-circle-fill text-danger"></i>
                              )}
                            </button>
                          }
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <CreateCampaign
        fetchList={getCampaignListFunc}
        ref={createCampaignRef}
      ></CreateCampaign>
      {}
      <CreateManualCampaign
        fetchList={getManualCampaignListFunc}
        ref={createManualCampaignRef}
      ></CreateManualCampaign>
    </section>
  );
};
