import configs from "../configs/apiConfigs";
import { BODY_TYPE, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getDocuments = (clinicid: string, type: string, id: string) => {
    let url = configs.API_URL + `clinic/${clinicid}/documentlist/?${type}=${id}`;
    const request = {
        url: url,
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const uploadDocuments = (clinicid: string, data: any) => {
    let url = configs.API_URL + `clinic/${clinicid}/upload/document/`;
    const request = {
        url: url,
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.FORM_DATA,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const deleteDocuments = (clinicid: string, id: string) => {
    let url = configs.API_URL + `clinic/${clinicid}/documentlist/?removeids=${id}`;
    const request = {
        url: url,
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}