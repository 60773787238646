import {
    ProviderAddActionTypes,
    ProviderAddState,
    ADD_PROVIDER
} from './types';

export const initialState: ProviderAddState = {
    pending: false,
    providerAdd: {
        uniqueid: '',
        fullname: '',
        Languages: [],
        speciality: [], email: '', gender: '', logo: '', clinics: []
    },
    providerAddResponse: { status: { error: false, msg: '' }, permission: '' },
    specialityResponse: { specialities: [], status: { error: false, msg: '' } },
    error: null
};

export const providerAddReducer = (state = initialState, action: ProviderAddActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case ADD_PROVIDER.ADD_PROVIDER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case ADD_PROVIDER.ADD_PROVIDER_PENDING:
            return {
                ...state,
                pending: true,
                providerAdd: action.payload
            }
        case ADD_PROVIDER.ADD_PROVIDER_SUCCESS:
            return {
                ...state,
                pending: false,
                providerAddResponse: action.providerAdd
            }
        case ADD_PROVIDER.FETCH_SPECIALITY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case ADD_PROVIDER.FETCH_SPECIALITY_PENDING:
            return {
                ...state,
                pending: true
            }
        case ADD_PROVIDER.FETCH_SPECIALITY_SUCCESS:
            return {
                ...state,
                pending: false,
                specialityResponse: action.speciality
            }
        default:
            return NewState;

    }
};