import { takeEvery, put } from "redux-saga/effects";
import axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import { USER_INFO, FetchUserInfoPendingAction, UserInfoRequest } from "../store/userinfo/types";
import { fetchUserInfoError, fetchUserInfoSuccess } from "../store/userinfo/actions";
import { attachHeader } from "../utils/apiHandler";
import { fetchLoadingPending, fetchLoadingSuccess } from "../store/loadingIndicator/actions";
import { nameChangeAction } from "../store/userData/actions";

function execute(userInfoRequest: UserInfoRequest) {
    const body = {
        firstName: userInfoRequest.firstName,
        lastName: userInfoRequest.lastName
    };
    return axios.post(CONFIG.API_URL + "user/" + userInfoRequest.uniqueId + "/", body, {
        headers: attachHeader(true), withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* userInfoPendingRun(action: FetchUserInfoPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200) {
            yield put(fetchUserInfoSuccess(response.data));
            yield put(nameChangeAction(action.payload.firstName, action.payload.lastName));
        } else {
            yield put(fetchUserInfoError(response))
        }
    }
    if (error) {
        yield put(fetchUserInfoError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export default function* userInfoPendingWatcher() {
    yield takeEvery(USER_INFO.LOAD_PENDING, userInfoPendingRun);
}