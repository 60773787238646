import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { AppointmentRowFollowup } from '../../../components/messageCenter/AppointmentRowFollowup';
import { getEmailTemplateList, getNotificationTypeList } from '../../../services/messageCenter';
export const Reminder = () => {
  const [notificationTypeList, setNotificationTypeList]: any = useState([]);
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  const userDataResponse = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchEmailTemplateList();
    dispatch(fetchLoadingPending());
    getNotificationTypeList(userDataResponse.clinics[0].id).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      if (success.error == false) {
        setNotificationTypeList(success.data.map((item: any) => ({ id: item.notificationtemplatetype_id, title: item.templatename, isRepeated: item.isrepeated })));
      }
    }).catch((err) => {
      dispatch(fetchLoadingSuccess(false));
    })
  }, []);
  const fetchEmailTemplateList = () => {
    getEmailTemplateList({clinicid: userDataResponse.clinics[0].id}).then((success) => {
        if (success && success.error === false) {
          setEmailTemplateList(success.data.filter((item: any) => item.isactive));
        }
    }).catch((err) => {
        setEmailTemplateList([]);
    });
}
  return (
    <>
      <Helmet>
        <title>Message center</title>
      </Helmet>
      {
        notificationTypeList.map((item: any, index: number) => {
          if (index ===0)  {
            return (
              <div key={item.id + "template"}>
                <AppointmentRowFollowup {...item} index={index} clinicId={userDataResponse.clinics[0].id} emailTemplateList={emailTemplateList}></AppointmentRowFollowup>
              </div>
            )
          }
          return null;
        })
      }
    </>
  )
}