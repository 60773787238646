import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { deletePaymentNotification, getEmailTemplateList, paymentNotificationList } from '../../services/messageCenter';
import { confirmAlert } from 'react-confirm-alert';
import { SinglePaymentNotificationModal } from '../../components/messageCenter/SinglePaymentNotificationModal';
import AddBtnIcon from '../../assets/images/add-btn-icon.svg';
import NotificationImg from '../../assets/images/notification-img.svg';
export const SinglePaymentNotification = () => {
    const [showAddNotification, setShowAddNotification] = useState(false);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [list, setList] = useState([]);
    const [emailTemplateList, setEmailTemplateList] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        fetchList();
        fetchEmailTemplateList();
    }, []);
    const fetchList = () => {
        dispatch(fetchLoadingPending());
        paymentNotificationList({ clinicid: userData.clinics[0].id, isrecurring: false }).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.error === false) {
                setList(success.data);
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        })
    }
    const fetchEmailTemplateList = () => {
        getEmailTemplateList({clinicid: userData.clinics[0].id}).then((success) => {
            if (success && success.error === false) {
                setEmailTemplateList(success.data.filter((item: any) => item.isactive));
            }
        }).catch((err) => {
            setEmailTemplateList([]);
        });
    }
    const onRowClick = (item: any) => {
        setSelectedTemplate(item);
        setShowAddNotification(true);
    }
    const onDeleteClick = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to delete ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(fetchLoadingPending());
                        deletePaymentNotification({
                            clinicid: userData.clinics[0].id,
                            paymentnotificationtemplateid: item.paymentnotificationtemplateid,
                        }).then((success) => {
                            dispatch(fetchLoadingSuccess(false));
                            if (success && success.error === false) {
                                fetchList();
                            }
                        }).catch((err) => {
                            dispatch(fetchLoadingSuccess(false));
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });
    }
    return (
        <>
            <section className="automated-reminders" id="automatedReminders">
                <div className="row mx-0">
                    <div className="col-12">
                        <div className="reminder-box">
                            <div className="d-flex justify-content-between">
                                <div className="me-3">
                                    <div className="reminder-title">Single Payment notifications</div>
                                    <div className="reminder-desc">Setup all single payment notifications</div>
                                </div>
                                <div className="align-self-center">
                                    <button className="btn btn-primary" onClick={() => {
                                        setShowAddNotification(true);
                                    }}><img src={AddBtnIcon} className="me-2" /> Create notification</button>
                                </div>
                            </div>
                            {
                                useMemo(() => {
                                    return (
                                        list.map((item: any) => {
                                            return (
                                                <div className="my-4 border px-4 py-3 border-1 rounded d-flex custom-noti-box justify-content-between"
                                                    key={item.paymentnotificationtemplateid} >
                                                    <div className="d-flex">
                                                        <div className="noti-title w-100 d-flex" >
                                                            <img src={NotificationImg} className="me-2" />
                                                            <span className="fw-bold align-self-center ">{item.paymentnotifcationtemplatetype}</span>
                                                        </div>
                                                    </div>
                                                    <div className="noti-edit d-flex align-self-center cursor-pointer btn-link">
                                                        <div onClick={() => { onRowClick(item); }}>Edit</div>
                                                        <button className="btn p-0 btn-link me-2 text-secondary" onClick={() => {
                                                            onDeleteClick(item);
                                                        }}><i className="ms-2 bi bi-x-circle-fill text-danger"></i></button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                }, [list])
                            }
                        </div>
                    </div>
                </div>
            </section>
            {
                showAddNotification &&
                <SinglePaymentNotificationModal
                    hideModal={() => {
                        setShowAddNotification(false);
                        setSelectedTemplate(null);
                    }}
                    selectedTemplate={selectedTemplate}
                    clinicId={(userData && userData.clinics[0] && userData.clinics[0].id)}
                    fetchList={fetchList}
                    emailTemplateList={emailTemplateList}
                    showEmailTemplate={userData.clinics[0].isenableemailtemplate}
                    clinic={userData.clinics[0]}
                ></SinglePaymentNotificationModal>
            }
        </>
    );
}