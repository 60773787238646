import { fetchLoadingPending, fetchLoadingSuccess } from './../store/loadingIndicator/actions';
import { takeEvery, put } from "redux-saga/effects";
import axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import { USER_PASSWORD, UserPasswordRequest, FetchUserPasswordPendingAction } from "../store/userPassword/types";
import { fetchUserPasswordError, fetchUserPasswordSuccess } from "../store/userPassword/actions";
import { attachHeader } from "../utils/apiHandler";

function execute(userPasswordRequest: UserPasswordRequest) {
    const body: UserPasswordRequest = {
        cpassword: userPasswordRequest.cpassword,
        npassword: userPasswordRequest.npassword,
        rpassword: userPasswordRequest.rpassword
    };
    return axios.post(CONFIG.API_URL + "user/" + userPasswordRequest.uniqueId + "/", body, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* userPasswordPendingRun(action: FetchUserPasswordPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200) {
            yield put(fetchUserPasswordSuccess(response.data));
        } else {
            yield put(fetchUserPasswordError(response))
        }
    }
    if (error) {
        yield put(fetchUserPasswordError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export default function* userPasswordPendingWatcher() {
    yield takeEvery(USER_PASSWORD.LOAD_PENDING, userPasswordPendingRun);
}