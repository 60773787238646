import {
    LoadingState,
    LoadingActionTypes,
    LOADING
} from './types';

const initialState: LoadingState = {
    pending: false
};

export const loadingReducer = (state = initialState, action: LoadingActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case LOADING.LOAD_ERROR:
            return {
                ...state,
                pending: false
            }
        case LOADING.LOAD_PENDING:
            return {
                ...state,
                pending: true,
            }
        case LOADING.LOAD_SUCCESS:
            return {
                ...state,
                pending: action.pending,
            }
        default:
            return NewState;

    }
};