import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { acceptNewPatientChange } from '../../services/provider';
export const AcceptNewPatient = ({ providerId, fetchProfile, acceptPatient }: any) => {
    const [active, setActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onChange = () => {
        setIsLoading(true);
        acceptNewPatientChange(providerId, {
            type: "acceptNewPatient",
            acceptNewPatient: active ? 0 : 1
        }).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                fetchProfile();
            }
        }).catch((err) => {
            setIsLoading(false);
            toast.error(err.msg);
        });
    }
    useEffect(() => {
        setActive(acceptPatient ? acceptPatient === "1" ? true : false : false);
    }, [acceptPatient])
    return (
        <div className="accept-patients-sec mb-4">
            <div className="card">
                <div className="card-body p-4">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="fw-medium">Accept new patients</div>
                            <small className="text-muted"><i className="bi bi-info-circle"></i> Online listing will not be shown to new patients if disabled</small>
                        </div>
                        <div className="form-check form-switch">
                            {
                                isLoading &&
                                <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
                            }
                            {
                                !isLoading &&
                                <input className="form-check-input" type="checkbox" id="enableDisable" checked={active} onChange={() => {
                                    onChange()
                                }} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}