import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { Profile } from './Profile';
import './clinic-details.scss';
import { Discoverablity } from './Discoveribility';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import configs from '../../configs/apiConfigs';
import { Configuration } from './Configuration';
export const Practice = () => {
  const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  const previewClick = () => {
    let address = 'xcare';
    if (userData.clinics[0] && userData.clinics[0].address) {
      if (userData.clinics[0].address.locality && userData.clinics[0].address.locality !== "" && userData.clinics[0].address.address_level_1_short &&
        userData.clinics[0].address.address_level_1_short !== "") {
        address = userData.clinics[0].address.locality.toLowerCase() + '-' + userData.clinics[0].address.address_level_1_short.toLowerCase();
      }
    }
    window.open(configs.SOURCE_URL + userData.clinics[0].name.replaceAll(" ", "-").toLowerCase() + '/' + userData.clinics[0].uniqueId + '/c');
  }
  return (
    <>

      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between">
            <div>
              <div className="nav-title-txt">Practice</div>
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <NavLink className="nav-link" exact to="/practice/profile">Profile</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" exact to="/practice/discoverablity">Discoverablity score</NavLink>
                </li>
                {
                  userData.userAccess === "1" &&
                  <li className="nav-item">
                    <NavLink className="nav-link" exact to="/practice/configuration">Configurations</NavLink>
                  </li>
                }
              </ul>
            </div>
            {
              userData && userData.clinics[0] &&
              <div className="stp-action">
                <button type="button" className="btn btn-outline-primary" onClick={previewClick}>Preview</button>
              </div>
            }

          </div>
        </div>
      </section>

      <Switch>
        <Route path="/practice/profile" component={Profile}></Route>
        <Route path="/practice/discoverablity" component={Discoverablity}></Route>
        <Route path="/practice/configuration" component={Configuration}></Route>
      </Switch>
    </>
  )
}