import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  createPatientList,
  updatePatientList,
} from "../../services/messageCenter";
import { toast } from "react-toastify";

interface CreatePatientListModalProps {
  patientIdsList: any;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  clinicId: any;
  selectedListData: {
    name: string;
    id: string;
  };
}

export const CreatePatientListModal = (props: CreatePatientListModalProps) => {
  const { patientIdsList, show, setShow, clinicId, selectedListData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState({ value: "", error: false });

  const onSubmit = () => {
    if (name.value === "") {
      setName({
        value: "",
        error: true,
      });
      return;
    } else {
      setName({
        ...name,
        error: false,
      });
    }
    setIsLoading(true);
    if (selectedListData.id) {
      updatePatientList({
        id: selectedListData.id,
        clinicid: clinicId,
        name: name.value,
        patient_ids: [...patientIdsList].join(","),
      })
        .then((success) => {
          setIsLoading(false);
          if (success && success.error === false) {
            toast.success("Updated list successfully.");
            setShow(false);
            return;
          }
          toast.warn("Something went wrong.");
        })
        .catch((err) => {
          setIsLoading(false);
          setShow(false);
        });
    } else {
      createPatientList({
        clinicid: clinicId,
        name: name.value,
        patient_ids: [...patientIdsList].join(","),
      })
        .then((success) => {
          setIsLoading(false);
          if (success && success.error === false) {
            toast.success("create list successfully.");
            setShow(false);
            return;
          }
          toast.warn("Something went wrong.");
        })
        .catch((err) => {
          setIsLoading(false);
          setShow(false);
        });
    }
  };

  useEffect(() => {
    if (selectedListData && selectedListData.name) {
      setName({
        value: selectedListData.name,
        error: false,
      });
    }
  }, [selectedListData]);
  return (
    <Modal
      className="camp-popups-sec"
      show={show}
      onHide={() => setShow(false)}
    >
      <div className="modal d-block  position-relative">
        <div className="modal-header">
          <div>
            <h5 className="modal-title mb-0">
              {!selectedListData.id ? "Create" : "Update"} Patient List
            </h5>
          </div>
        </div>
        <div className="modal-body">
          <div className="input-box">
            <div className="row mb-4">
              <div className="col">
                <label htmlFor="templatename" className="form-label fw-bold">
                  List Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="patientlistname"
                  placeholder="Patient List Name"
                  name="patientlistname"
                  value={name.value}
                  onChange={(e) => {
                    setName({
                      ...name,
                      value: e.target.value,
                    });
                  }}
                />
                {name.error && (
                  <div className="is-invalid">List name is required</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            disabled={isLoading}
            className="btn btn-border"
            data-bs-dismiss="modal"
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            type="submit"
            className="btn btn-primary"
            onClick={() => onSubmit()}
          >
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {selectedListData.id ? "update" : "create"}
          </button>
        </div>
      </div>
    </Modal>
  );
};
