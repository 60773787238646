import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { AppointmentTypesHours } from '../../components/practice/profile/AppointmentTypesHours';
import { Description } from '../../components/practice/profile/Description';
import { Features } from '../../components/practice/profile/Features';
import { GeneralInfo } from '../../components/practice/profile/GeneralInfo';
import { Hours } from '../../components/practice/profile/Hours';
import { Hygiene } from '../../components/practice/profile/Hygiene';
import { Insurance } from '../../components/practice/profile/Insurace';
import { Photos } from '../../components/practice/profile/Photos';
import { PMSProviders } from '../../components/practice/profile/PMSProvider';
import { PMSStatusMap } from '../../components/practice/profile/PMSStatusMap';
import { Questions } from '../../components/practice/profile/Questions';
import { Speciality } from '../../components/practice/profile/Speciality';
import { TitleBox } from '../../components/practice/profile/TitleBox';
import { UtilityApiKey } from '../../components/practice/profile/UtilityApiKey';
import { getAppointmentTypes, getFeatures, getHygiene, getInsuranceList, getQuestionsList, getSocialAssistanceList, getSpeciality } from '../../services/clinicOnboard';
import { getClinicProfile } from '../../services/clinicProfile';
import { getPMSProviderList } from '../../services/providerOnboard';

export const Profile = () => {
  const userDataResponse: any = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  const [titleBox, setTitleBox]: any = useState();
  const [photos, setPhotos]: any = useState();
  const [practiceDescription, setPracticeDescription]: any = useState();
  const [questionList, setQuestionList] = useState([]);
  const [practiceQuestions, setPracticeQuestions]: any = useState();
  const [specialityList, setSpecialityList]: any = useState([]);
  const [selectedSpeciality, setSelectedSpeciality]: any = useState([]);
  const [hygieneList, setHygieneList]: any = useState([]);
  const [selectedHygiene, setSelectedHygiene]: any = useState([]);
  const [featureList, setFeatureList]: any = useState([]);
  const [selectedFeature, setSelectedFeature]: any = useState([]);
  const [generalInfo, setGeneralInfo]: any = useState();
  const [hours, setHours]: any = useState([]);
  const [insurance, setInsurance]: any = useState();
  const [insuranceList, setInsuranceList]: any = useState([]);
  const [socialAssistanceList, setSocialAssistanceList]: any = useState([]);
  const [masterAppointmentTypesList, setMasterAppointmentTypesList]: any = useState([]);
  const [potentialAppointmentTypes, setPotentialAppointmentTypes]: any = useState([]);
  const [pmsStatusMap, setPmsStatuMap]: any = useState([]);
  const [pMSProviderList, setpMSProviderList]: any = useState([]);
  const [apiKey, setApiKey] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (userDataResponse && userDataResponse.status && userDataResponse.status.error === false &&
      userDataResponse.clinics && userDataResponse.clinics.length > 0) {
      const clinicId = userDataResponse.clinics[0].uniqueId;
      dispatch(fetchLoadingPending());
      getClinicProfile(clinicId).then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.status && success.status.error === false) {
          setTitleBox({ 
            name: success.name, 
            primarySpeciality: success.primarySpeciality, 
            logo: success.logo, uniqueId: success.uniqueId, 
            provider: success.provider,
            type: success?.domain?.title,
            plan: success?.plan?.planDisplayName,
            logo1: success.logo2,
            banner: success.banner,
          });
          setPhotos({ photos: success.photos });
          setPracticeDescription({ practiceDescription: success.description });
          setPracticeQuestions({ practiceQuestions: success.questionResponse });
          setSelectedSpeciality(success.primarySpeciality);
          setSelectedHygiene(success.HygieneFeatures);
          setSelectedFeature(success.features);
          setGeneralInfo({
            name: success.name,
            address: success.address,
            email: success.email,
            phone: success.phone,
            website: success.website,
          });
          setHours(success.hours);
          setInsurance({
            insuranceAccept: success.insuranceAccept,
            insurance: success.insurances,
            socialAssistance: success.socaiAssistance,
            socialAssistType: success.socialAssistType,
            socialAssistTypeAccept: success.socialAssistTypeAccept,
            acceptAssignment: success.acceptAssignment,
            paymentMethod: success.paymentMethod
          });
          setPotentialAppointmentTypes(success.potentialAppointmentTypes);
          setPmsStatuMap(success.pmsMapStatuses);
          setApiKey(success.apiKey);
          getQuestionsList().then((questionResponse) => {
            if (questionResponse && questionResponse.status && questionResponse.status.error === false) {
              setQuestionList(questionResponse.questions);
              return;
            }
          })
          getSpeciality(success.domain.id).then((specialityResponse) => {
            if (specialityResponse && specialityResponse.status && specialityResponse.status.error === false) {
              setSpecialityList(specialityResponse.specialities);
              return;
            }
          });
          getHygiene().then((success) => {
            if (success && success.status && success.status.error === false) {
              setHygieneList(success.hygieneFeatures);
              return;
            }
          });
          getFeatures(success.primarySpeciality.map((item: any) => item.id).toString()).then((success) => {
            if (success && success.status && success.status.error === false) {
              setFeatureList(success.features);
              return;
            }
          });
          getInsuranceList().then((success) => {
            if (success && success.status && success.status.error === false) {
              setInsuranceList(success.insurance);
              return;
            }
          });
          getSocialAssistanceList().then((success) => {
            if (success && success.status && success.status.error === false) {
              setSocialAssistanceList(success.types);
              return;
            }
          });
          getPMSProviderList(userDataResponse.clinics[0].id).then((success) => {
            if (success && success.status && success.status.error === false) {
              setpMSProviderList(success.pmsProviders.map((item: any) => {
                return {
                  value: item.sikkaId,
                  label: item.fname + ' ' + item.lname + ' - ' + item.sikkaId
                }
              }))
              return;
            }
          })
          if (success.primarySpeciality && Array.isArray(success.primarySpeciality)) {
            getAppointmentTypes({ speciality: success.primarySpeciality.map((item: any) => item.id).toString() }).then((success) => {
              if (success && success.status && success.status.error === false) {
                setMasterAppointmentTypesList(success.appointmentTypes);
              }
            }).catch((err) => {
              setMasterAppointmentTypesList([]);
            });
          }
          return;
        }
      }).catch((err) => {
        dispatch(fetchLoadingSuccess(false));
      });
    }
  }, [userDataResponse]);
  const fetchProfile = async (type: string) => {
    if (userDataResponse && userDataResponse.status && userDataResponse.status.error === false &&
      userDataResponse.clinics && userDataResponse.clinics.length > 0) {
      const clinicId = userDataResponse.clinics[0].uniqueId;
      dispatch(fetchLoadingPending());
      getClinicProfile(clinicId).then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.status && success.status.error === false) {
          if (type === 'logo') {
            setTitleBox({
              name: success.name, primarySpeciality: success.primarySpeciality,
              logo: success.logo,
              uniqueId: success.uniqueId, provider: success.provider,
              logo1: success.logo2,
              banner: success.banner,
            });
          } else if (type === 'photos') {
            setPhotos({ photos: success.photos });
          } else if (type === 'description') {
            setPracticeDescription({ practiceDescription: success.description });
          } else if (type === 'speciality') {
            setSelectedSpeciality(success.primarySpeciality);
          } else if (type === 'hygiene') {
            setSelectedHygiene(success.HygieneFeatures);
          } else if (type === 'general') {
            setGeneralInfo({
              name: success.name,
              address: success.address,
              email: success.email,
              phone: success.phone,
              website: success.website,
            });
          } else if (type === 'hour') {
            setHours(success.hours);
          } else if (type === 'socialAssignment') {
            setInsurance({
              insuranceAccept: success.insuranceAccept,
              insurance: success.insurances,
              socialAssistance: success.socaiAssistance,
              socialAssistType: success.socialAssistType,
              socialAssistTypeAccept: success.socialAssistTypeAccept,
              acceptAssignment: success.acceptAssignment,
              paymentMethod: success.paymentMethod
            });
          } else if (type === 'appointmenttype') {
            setPotentialAppointmentTypes(success.potentialAppointmentTypes);
            if (success.primarySpeciality && Array.isArray(success.primarySpeciality)) {
              getAppointmentTypes({ speciality: success.primarySpeciality.map((item: any) => item.id).toString() }).then((success) => {
                if (success && success.status && success.status.error === false) {
                  setMasterAppointmentTypesList(success.appointmentTypes);
                }
              }).catch((err) => {
                setMasterAppointmentTypesList([]);
              });
            }
          } else if (type === '') {
            setPmsStatuMap(success.pmsMapStatuses);
          }
          return new Promise((res, rej) => {
            res(true);
          });
        }
        return new Promise((res, rej) => {
          rej(true);
        });
      }).catch((err) => {
        dispatch(fetchLoadingSuccess(false));
        return new Promise((res, rej) => {
          rej(true);
        });
      });
    }
  }
  const fetchPMSProviderList =  () => {
    getPMSProviderList(userDataResponse.clinics[0].id).then((success) => {
      if (success && success.status && success.status.error === false) {
        setpMSProviderList(success.pmsProviders.map((item: any) => {
          return {
            value: item.sikkaId,
            label: item.fname + ' ' + item.lname + ' - ' + item.sikkaId
          }
        }))
        return;
      }
    })
  }
  return (
    <>
      <section className="practice-profile-sec px-3 pt-4">
        <div className="container">
          <div>
            {
              useMemo(() => {
                if (titleBox)
                  return (
                    <TitleBox {...titleBox} fetchProfile={fetchProfile}></TitleBox>
                  )
                return null;
              }, [titleBox])
            }
          </div>
          <div className="row">
            <div className="col-md-7 col-xl-8">
              {
                useMemo(() => {
                  if (photos)
                    return (<Photos {...photos} clinicId={userDataResponse.clinics[0] && userDataResponse.clinics[0].uniqueId} fetchProfile={fetchProfile}></Photos>);
                  return null;
                }, [photos])
              }
              {
                useMemo(() => {
                  if (practiceDescription)
                    return (<Description {...practiceDescription} clinicId={userDataResponse.clinics[0] && userDataResponse.clinics[0].uniqueId} fetchProfile={fetchProfile}></Description>)
                  return null;
                }, [practiceDescription])
              }
              {
                useMemo(() => {
                  if (practiceQuestions)
                    return (<Questions {...practiceQuestions} clinicId={userDataResponse.clinics[0] && userDataResponse.clinics[0].uniqueId}
                      questionList={questionList}
                      fetchProfile={fetchProfile}></Questions>)
                  return null;
                }, [practiceQuestions, questionList])
              }
              {
                useMemo(() => {
                  return (<Speciality
                    specialityList={specialityList}
                    speciality={selectedSpeciality}
                    clinicId={userDataResponse.clinics[0] && userDataResponse.clinics[0].uniqueId}
                    fetchProfile={fetchProfile}
                  ></Speciality>)
                }, [specialityList])
              }

              {
                useMemo(() => {
                  return (
                    <AppointmentTypesHours
                      masterAppointmentTypesList={masterAppointmentTypesList}
                      potentialAppointmentTypes={potentialAppointmentTypes}
                      clinicId={userDataResponse.clinics[0] && userDataResponse.clinics[0].uniqueId}
                      fetchProfile={fetchProfile}
                      specialities={selectedSpeciality}
                    ></AppointmentTypesHours>
                  )
                }, [masterAppointmentTypesList, potentialAppointmentTypes, selectedSpeciality])
              }

              {
                useMemo(() => {
                  return (<Hygiene
                    hygieneList={hygieneList}
                    selectedHygiene={selectedHygiene}
                    clinicId={userDataResponse.clinics[0] && userDataResponse.clinics[0].uniqueId}
                    fetchProfile={fetchProfile}
                  ></Hygiene>)
                }, [hygieneList, selectedHygiene])
              }
              {
                useMemo(() => {
                  return (<Features
                    featureList={featureList}
                    selectedFeature={selectedFeature}
                    clinicId={userDataResponse.clinics[0] && userDataResponse.clinics[0].uniqueId}
                    fetchProfile={fetchProfile}
                  ></Features>)
                }, [featureList, selectedFeature])
              }
            </div>
            <div className="col-md-5 col-xl-4">
              {
                useMemo(() => {
                  if (generalInfo)
                    return (<GeneralInfo {...generalInfo}
                      clinicId={userDataResponse.clinics[0] && userDataResponse.clinics[0].uniqueId}
                      fetchProfile={fetchProfile}
                    ></GeneralInfo>);
                  return null;
                }, [generalInfo])
              }
              {
                useMemo(() => {
                  return (<Hours hours_list={hours} clinicId={userDataResponse.clinics[0] && userDataResponse.clinics[0].uniqueId}
                    fetchProfile={fetchProfile}></Hours>)
                }, [hours])
              }
              {
                useMemo(() => {
                  if (insurance)
                    return (<Insurance
                      insuranceList={insuranceList}
                      socialAssistanceList={socialAssistanceList}
                      insurance={insurance}
                      clinicId={userDataResponse.clinics[0] && userDataResponse.clinics[0].uniqueId}
                      fetchProfile={fetchProfile}
                    ></Insurance>);
                  return null
                }, [insurance, insuranceList, socialAssistanceList])
              }
              {
                useMemo(() => {
                  return (
                    (userDataResponse && userDataResponse.accessEnabled && userDataResponse.accessEnabled.pms_integration) &&
                    <PMSStatusMap
                      pmsStatusMap={pmsStatusMap}
                      clinicId={userDataResponse.clinics[0] && userDataResponse.clinics[0].id}
                      clinicUniqueId={userDataResponse.clinics[0] && userDataResponse.clinics[0].uniqueId}
                      fetchProfile={fetchProfile}
                    ></PMSStatusMap>
                  )
                }, [pmsStatusMap])
              }
              {
                useMemo(() => {
                  return (
                    (userDataResponse && userDataResponse.accessEnabled && userDataResponse.accessEnabled.pms_integration) &&
                    <PMSProviders
                      pmsProviderList={pMSProviderList}
                      clinicUniqueId={userDataResponse.clinics[0] && userDataResponse.clinics[0].uniqueId}
                      fetchPMSProviderList={fetchPMSProviderList}
                    >
                    </PMSProviders>
                  )
                }, [pMSProviderList])
              }
              {
                useMemo(() => {
                  return (
                    <UtilityApiKey apiKey={apiKey} clinicId={userDataResponse.clinics[0].id}></UtilityApiKey>
                  )
                }, [apiKey])
              }
            </div>
          </div>
        </div >
      </section >
    </>
  )
}