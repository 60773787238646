import React from 'react';
import { AvatarComp } from '../Avatar';
export const ProvidersList = (props: any) => {
    const { providerList, onClickProvider, selectedProvider } = props;
    return (
        <>
            {
                providerList.map((item: any) => {
                    return (
                        <React.Fragment key={item.uniqueId}>
                            <ProviderListItem data={item} onClickProvider={onClickProvider} selectedProvider={selectedProvider}></ProviderListItem>
                        </React.Fragment>
                    )
                })
            }
        </>
    )
}

const ProviderListItem = (props: any) => {
    const { data, onClickProvider, selectedProvider } = props;
    return (
        <div id="userListBox">
            <ul className="list-group">
                <li className="border-bottom">
                    <a className={'list-group-item list-group-item-action border-0' + (data.id === selectedProvider?.group?.id ? ' active' : '')}>
                        {
                            <>
                                {/* <div className="badge bg-success float-right">{0}</div> */}
                                <div className="d-flex align-items-center"
                                    onClick={(e) => {
                                        onClickProvider(data);
                                    }}
                                >
                                    <AvatarComp name={data.fullName}></AvatarComp>
                                    <div className="flex-grow-1">
                                        <div>{data.fullName}</div>
                                        <div className="small chat-msg-inline">{data?.groupData?.recentMessage?.message}</div>
                                    </div>
                                </div>
                            </>
                        }
                    </a>
                </li>
            </ul>
        </div>
    )
}