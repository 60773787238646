export enum SETTINGS {
    LOAD_PENDING = 'LOAD_PENDING_SETTINGS',
    LOAD_ERROR = 'LOAD_FAILED_SETTINGS',
    LOAD_SUCCESS = 'LOAD_SUCCESS_SETTINGS',
    ADD_PENDING = 'SETTINGS_ADD_PENDING',
    ADD_SUCCESS = 'SETTINGS_ADD_SUCCESS',
    ADD_ERROR = 'SETTINGS_ADD_ERROR',
    REMOVE_PENDING = 'SETTINGS_REMOVE_PENDING',
    REMOVE_ERROR = 'SETTINGS_REMOVE_ERROR',
    REMOVE_SUCCESS = 'SETTINGS_REMOVE_SUCCESS',
    CHANGE_PENDING = 'SETTINGS_CHANGE_PENDING',
    CHANGE_SUCCESS = 'SETTINGS_CHANGE_SUCCESS',
    CHANGE_ERROR = 'SETTINGS_CHANGE_ERROR',
    SPECIALITY_ACTION_PENDING = 'SPECIALITY_ACTION_PENDING',
    SPECIALITY_ACTION_ERROR = 'SPECIALITY_ACTION_ERROR',
    SPECIALITY_ACTION_SUCCESS = 'SPECIALITY_ACTION_SUCCESS',
    EMAIL_VERIFICATION_ERROR = 'SETTINGS_EMAIL_VERIFICATION_ERROR',
    EMAIL_VERIFICATION_PENDING = 'SETTINGS_EMAIL_VERIFICATION_PENDING',
    EMAIL_VERIFICATION_SUCCESS = 'SETTINGS_EMAIL_VERIFICATION_SUCCESS',
    ACCEPT_TC_PENDING = 'ACCEPT_TC_PENDING',
    ACCEPT_TC_ERROR = 'ACCEPT_TC_ERROR',
    ACCEPT_TC_SUCCESS = 'ACCEPT_TC_SUCCESS',
    REVIEW_SAVE_PENDING = 'REVIEW_SAVE_PENDING',
    REVIEW_SAVE_SUCCESS = 'REVIEW_SAVE_SUCCESS',
    REVIEW_SAVE_ERROR = 'REVIEW_SAVE_ERROR',
    ADMIN_SAFETY_FEE_PENDING = 'ADMIN_SAFETY_FEE_PENDING',
    ADMIN_SAFETY_FEE_SUCCESS = 'ADMIN_SAFETY_FEE_SUCCESS',
    ADMIN_SAFETY_FEE_ERROR = 'ADMIN_SAFETY_FEE_ERROR',
};

export interface SettingsRequest {
    uniqueId: string;
    type: string;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface Permission {
    id: string;
    userid: string;
    uniqueId: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: string;
    userAccess: string;
    emailNotification: string;
}
export interface SettingsResponse {
    metadata: any;
    permission: Permission[];
    status: Status
}

export interface SettingsAddRequest {
    uniqueId: string;
    type: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface SettingsAddResponse {
    data: Permission,
    status: Status
}

export interface SettingsRemoveRequest {
    uniqueId: string;
    type: string;
    permission: Permission
}

export interface SettingsRemoveResponse {
    status: Status;
    uniqueId: string;
}
export interface SpecialityRequest {
    type: string;
    userUniqueId: string;
    uniqueId: string;
    specialityId: string;
}
export interface SpecialityResponse {
    payload?: SpecialityRequest;
    status: Status;
}
export interface EmailVerificationRequest {
    modelType: string;
    modelUniqueid: string;
    email: string;
}
export interface EmailVerificationResponse {
    status: Status;
}
export interface AcceptTCRequest {
    clinicUniqueId: string;
}
export interface AcceptTCResponse {
    status: Status;
}
export interface ReviewSaveRequest {
    clinicUniqueId: string;
    reviewOn: string[];
    trustApiKey: string;
}
export interface ReviewSaveResponse {
    status: Status;
    metadata: { clinicUniqueId: string, reviewOn: [], trustApiKey: string }
}
export interface SettingsState {
    pending: boolean;
    settings: SettingsRequest;
    settingsResponse: SettingsResponse;
    settingsAdd: SettingsAddRequest;
    settingsAddResponse: SettingsAddResponse;
    settingsRemove: SettingsRemoveRequest;
    settingsRemoveResponse: SettingsRemoveResponse;
    settingsNotificationChange: SettingsRemoveRequest;
    settingsNotificationChangeResponse: SettingsRemoveResponse;
    specialityActionRequest: SpecialityRequest;
    specialityActionResponse: SpecialityResponse;
    emailVerificationRequest?: EmailVerificationRequest;
    emailVerificationResponse?: EmailVerificationResponse;
    acceptTCRequest?: AcceptTCRequest;
    acceptTCResponse?: AcceptTCResponse;
    reviewSaveRequest?: ReviewSaveRequest;
    reviewSaveResponse?: ReviewSaveResponse;
    AdminSafetyFeeRequest?: AdminSafetyFeeRequest;
    AdminSafetyFeeResponse?: AdminSafetyFeeResponse;
    error: any;
}



export interface FetchSettingsPendingAction {
    type: SETTINGS.LOAD_PENDING;
    payload: SettingsRequest
}

export interface FetchSettingsErrorAction {
    type: SETTINGS.LOAD_ERROR;
    error: any;
}
export interface FetchSettingsSuccessAction {
    type: SETTINGS.LOAD_SUCCESS;
    settings: SettingsResponse
}

export interface SettingsAddRequestPendingAction {
    type: SETTINGS.ADD_PENDING,
    payload: SettingsAddRequest
}

export interface SettingsAddRequestSuccessAction {
    type: SETTINGS.ADD_SUCCESS,
    settingsAdd: SettingsAddResponse
}

export interface SettingsAddRequestErrorAction {
    type: SETTINGS.ADD_ERROR,
    error: any
}

export interface SettingsRemoveRequestPendingAction {
    type: SETTINGS.REMOVE_PENDING,
    payload: SettingsRemoveRequest
}

export interface SettingsRemoveRequestSuccessAction {
    type: SETTINGS.REMOVE_SUCCESS,
    settingsRemove: SettingsRemoveResponse,
}

export interface SettingsRemoveRequestErrorAction {
    type: SETTINGS.REMOVE_ERROR,
    error: any
}

export interface SettingsNotificationChangeRequestPendingAction {
    type: SETTINGS.CHANGE_PENDING,
    payload: SettingsRemoveRequest
}

export interface SettingsNotificationChangeRequestSuccessAction {
    type: SETTINGS.CHANGE_SUCCESS,
    settingsNotification: SettingsRemoveResponse,
}

export interface SettingsNotificationChangeRequestErrorAction {
    type: SETTINGS.CHANGE_ERROR,
    error: any
}

export interface SpecialityActionPedingAction {
    type: SETTINGS.SPECIALITY_ACTION_PENDING;
    payload: SpecialityRequest;
}

export interface SpecialityActionErrorAction {
    type: SETTINGS.SPECIALITY_ACTION_ERROR;
    error: any;
}

export interface SpecialityActionSuccessAction {
    type: SETTINGS.SPECIALITY_ACTION_SUCCESS;
    specialityAction: SpecialityResponse;
}
export interface EmailVerificationPendingAction {
    type: SETTINGS.EMAIL_VERIFICATION_PENDING;
    payload: EmailVerificationRequest;
}
export interface EmailVerificationSuccessAction {
    type: SETTINGS.EMAIL_VERIFICATION_SUCCESS;
    emailVerification: EmailVerificationResponse;
}
export interface EmailVerificationErrorAction {
    type: SETTINGS.EMAIL_VERIFICATION_ERROR;
    error: any;
}
export interface AcceptTCPendingAction {
    type: SETTINGS.ACCEPT_TC_PENDING;
    payload: AcceptTCRequest;
}
export interface AcceptTCErrorAction {
    type: SETTINGS.ACCEPT_TC_ERROR;
    error: any;
}
export interface AcceptTCSuccessAction {
    type: SETTINGS.ACCEPT_TC_SUCCESS;
    acceptTC: AcceptTCResponse;
}
export interface ReviewSavePendingAction {
    type: SETTINGS.REVIEW_SAVE_PENDING;
    payload: ReviewSaveRequest;
}
export interface ReviewSaveErrorAction {
    type: SETTINGS.REVIEW_SAVE_ERROR;
    error: any;
}
export interface ReviewSaveSuccessAction {
    type: SETTINGS.REVIEW_SAVE_SUCCESS;
    reviewSave: ReviewSaveResponse;
}

// Admin Safety

export interface AdminSafetyFeeRequest {
    clinicUniqueId: string;
    adminsafetyfee:boolean;
    adminFee:any;
}
export interface AdminSafetyFeeResponse {
    status: Status;
}

export interface AdminSafetyFeePendingAction {
    type: SETTINGS.ADMIN_SAFETY_FEE_PENDING;
    payload: AdminSafetyFeeRequest;
}
export interface AdminSafetyFeeErrorAction {
    type: SETTINGS.ADMIN_SAFETY_FEE_ERROR;
    error: any;
}
export interface AdminSafetyFeeSuccessAction {
    type: SETTINGS.ADMIN_SAFETY_FEE_SUCCESS;
    AdminSafetyFeeResponse: AdminSafetyFeeResponse;
}

export type SettingsActionTypes = FetchSettingsErrorAction | FetchSettingsPendingAction | FetchSettingsSuccessAction
    | SettingsAddRequestErrorAction | SettingsAddRequestPendingAction | SettingsAddRequestSuccessAction |
    SettingsRemoveRequestPendingAction | SettingsRemoveRequestSuccessAction | SettingsRemoveRequestErrorAction |
    SettingsNotificationChangeRequestPendingAction | SettingsNotificationChangeRequestSuccessAction | SettingsNotificationChangeRequestErrorAction |
    SpecialityActionErrorAction | SpecialityActionPedingAction | SpecialityActionSuccessAction |
    EmailVerificationErrorAction | EmailVerificationPendingAction | EmailVerificationSuccessAction |
    AcceptTCPendingAction | AcceptTCErrorAction | AcceptTCSuccessAction | ReviewSaveErrorAction |
    ReviewSavePendingAction | ReviewSaveSuccessAction | AdminSafetyFeePendingAction | AdminSafetyFeeSuccessAction | AdminSafetyFeeErrorAction;

