import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CHAT } from '../../../constants';
import { getCookie } from '../../utils/cookies';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import firebaseInstance from '../../helpers/firebase';
import configs from '../../configs/apiConfigs';
import { firestoreChangeNotificationToServer } from '../../services/appointment';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
export const CancelAppointmentModal = ({ appointment, hideModal, fetchAppointment }: any) => {
    const [selectedReasonForCancel, setSelectedReasonForCancel] = useState('');
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse);
    const [allowRescheduling, setAllowRescheduling] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const onCancel = async () => {
        setIsLoading(true);
        try {
            const selectedReason = CHAT.CANCEL_REASON.find((value: any) => {
                return Number(value.id) === Number(selectedReasonForCancel);
            });
            var objIndex: string = appointment.currentStatus.toUpperCase();
            const fromStatus = CHAT.FROM_STATUS[objIndex];
            const obj = {
                "appointmentId": Number(appointment.uniqueid),
                "senderId": Number(getCookie().xpr_user_id),
                "sender": CHAT.SENDER.USER,
                "action": `Changed status from ${fromStatus} to ${CHAT.TO_STATUS.CANCELLED}.`,
                "actionType": 'STATUS',
                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "fromStatus": fromStatus,
                "toStatus": CHAT.TO_STATUS.CANCELLED,
                "text": selectedReason.reason,
                "allowRescheduling": allowRescheduling === 1,
                "sendToCId": appointment.clinic.uniqueid,
                "dependantId": Number(appointment.dependant.uniqueid),
                "guest": appointment.type === "guest" ? true : false,
                "dateTime": {
                    "date": appointment.date,
                    "time": appointment.time
                },
                "firstName": userData.firstName,
                "lastName": userData.lastName,
            };
            const response = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
            if (response) {
                response.get().then((docRef: any) => {
                    let data = {
                        ...docRef.data(),
                        mode: "create",
                        id: docRef.id
                    };
                    firestoreChangeNotificationToServer(data).then((success) => {
                        hideModal();
                        fetchAppointment(true);
                    }).catch((err) => {
                        setIsLoading(false);
                        console.log(err);
                    })
                });
            }
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }
    const enableButton = selectedReasonForCancel !== '';
    return (
        <Modal className="newcommon cancelappt-popup" show={true} onHide={hideModal} centered>
            <Modal.Header>
                <div className="modal-title">Cancel appointment reason</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={hideModal} >
                </button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {
                        CHAT.CANCEL_REASON.map((reason: any, index: number) => {
                            return (
                                <div className={"form-check mb-2" + (Number(selectedReasonForCancel) === Number(reason.id) ? ' cactive' : '')} key={index + Math.random()}  >

                                    <input type="radio" name="reasonRadio" className="form-check-input" value={reason.id} id={"customRadio" + reason.id}
                                        checked={Number(selectedReasonForCancel) === Number(reason.id)}
                                        onChange={(e) => {
                                            setSelectedReasonForCancel(e.target.value);
                                        }} />
                                    <label className="form-check-label" htmlFor={"customRadio" + reason.id}>{reason.reason}</label>
                                </div>
                            )
                        })
                    }
                    <div className="form-check border-top pt-3 mt-3">
                        <input className="form-check-input" type="checkbox" id="reopen" value={allowRescheduling} checked={allowRescheduling === 1} onChange={(e: any) => {
                            if (allowRescheduling === 1) {
                                setAllowRescheduling(0);
                            } else {
                                setAllowRescheduling(1);
                            }
                        }} />
                        <label className="form-check-label" htmlFor="reopen">
                            Give this patient the ability to reopen their appointment request
                        </label>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-primary" type="button" onClick={hideModal}>Close</button>
                <button className="btn btn-primary" disabled={!enableButton} onClick={onCancel}>
                    {
                        isLoading &&
                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    }
                    Cancel appointment
                </button>
            </Modal.Footer>
        </Modal>
    )
}