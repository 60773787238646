import { encodeQueryData } from "../../utils/global";
import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getAppointments = (data: any) => {
    let url = configs.API_URL + data.modelType + '/' + data.Id + '/appointments/' + data.appointmentType + '/?';
    let queryData = {};
    if (data.viewCalendar) {
        Object.assign(queryData, { page: data.page, view: 'calendar' });
    } else {
        Object.assign(queryData, { page: data.page, results: data.results });
    }
    if (data.fname || data.lname) {
        Object.assign(queryData, { fname: data.fname, lname: data.lname });
    }
    if (data.from) {
        Object.assign(queryData, { from: data.from });
    }
    if (data.to) {
        Object.assign(queryData, { to: data.to });
    }
    if (data.selectedProvider) {
        Object.assign(queryData, { provider: data.selectedProvider });
    }
    if (data.selectedStatus !== 'all') {
        Object.assign(queryData, { status: data.selectedStatus });
    }
    if (data.fullname !== '') {
        Object.assign(queryData, { fullname: data.fullname });
    }
    if (data.searchdate !== '') {
        Object.assign(queryData, { searchdate: data.searchdate });
    }
    url = url + encodeQueryData(queryData);
    const request: CommonApiOptions = {
        url: url,
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const requestForMedicalHistory = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "medical/" + data.clinicUniqueId + "/" + data.appointmentUniqueId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};

export const firestoreChangeNotificationToServer = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "chatNotification/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: { ...data, directMethod: true }
        }
    };
    return commonApi(request);
};

export const editAppointment = (data: any, clinicId: any, appointmentId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "clinic/" + clinicId + "/editappointment/" + appointmentId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const appointmentUnverified = (clinicId: any, appointmentId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "clinic/" + clinicId + "/unverified/" + appointmentId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
};

export const fetchServiceTypes = () => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "serviceTypes/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
}

export const getPatientList = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "info/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
}

export const bookManualAppointment = (data: any, clinicId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic/' + clinicId + '/manualappointment/',
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
};

export const blockTime = (data: any, clinicId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "clinic/" + clinicId + "/bookslot/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
}

export const removeBlockSlot = (clinicId: any, blockSlotId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "clinic/" + clinicId + "/bookslot/" + blockSlotId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
}

export const checkAppointmentBook = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "checkslot/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
}

export const getMasterPMSStatus = (clinicId: any, modeList = false) => {
    let url = configs.API_URL + "master/appointmentstatus/" + clinicId + "/";
    if (modeList) {
        url = url + '?mode=list';
    }
    const request: CommonApiOptions = {
        url: url,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
}

export const changeAppointmentPMSStatus = (clinicUniqueId: any, appointmentUniqueId: any, data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "pms/" + clinicUniqueId + "/writestatus/" + appointmentUniqueId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
}

export const getInsurancePlan = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "insurancesplan/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
}

export const getAppointmentDetail = (clinicId: any, appointmentId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic' + '/' + clinicId + '/appointments/' + appointmentId + '/',
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
}

export const getAppointmentsEmergency = (data: any) => {
    let url = configs.API_URL + data.modelType + '/' + data.Id + '/appointments/' + data.appointmentType + '/?';
    let queryData = {};
    if (data.viewCalendar) {
        Object.assign(queryData, { page: data.page, view: 'calendar' });
    } else {
        Object.assign(queryData, { page: data.page, results: data.results });
    }
    if (data.fname || data.lname) {
        Object.assign(queryData, { fname: data.fname, lname: data.lname });
    }
    if (data.from) {
        Object.assign(queryData, { from: data.from });
    }
    if (data.to) {
        Object.assign(queryData, { to: data.to });
    }
    if (data.selectedProvider) {
        Object.assign(queryData, { provider: data.selectedProvider });
    }
    if (data.selectedStatus !== 'all') {
        Object.assign(queryData, { status: data.selectedStatus });
    }
    if (data.fullname !== '') {
        Object.assign(queryData, { fullname: data.fullname });
    }
    if (data.searchdate !== '') {
        Object.assign(queryData, { searchdate: data.searchdate });
    }
    url = url + encodeQueryData(queryData);
    const request: CommonApiOptions = {
        url: url,
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const archiveEmergency = (data: any, clinicId: any) => {
    let url = configs.API_URL + 'clinic/' + clinicId + '/appointments/emergency/';
    const request: CommonApiOptions = {
        url: url,
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data,
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const sendManualReview = (appointmentId: any, clinicId: any) => {
    let url = configs.API_URL + 'clinic/' + clinicId + '/reviewrequest/'+appointmentId+'/';
    const request: CommonApiOptions = {
        url: url,
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}