import axios from "axios"

export const mangoVoiceCall = async (idToken:any, toNumber:any, mangoCallUser: string) => {
    const ext_uuid = await listExtensions(idToken, mangoCallUser);
    return axios.post("https://apiuser.mangovoice.com/api/v2/iclicktocall/"+idToken, {
        ext_uuid: ext_uuid,
        tonumber: toNumber,
        autoanswer: 1
    });
};

export const listExtensions = async (idToken:any, mangoCallUser: string) => {
    const response = await axios.get("https://apiuser.mangovoice.com/api/v2/listextensions/"+idToken);
    const ext = response.data.find((item:any) => item.name === mangoCallUser);
    if (ext) {
        return ext.uuid;
    } else {
        throw 'No extension'
    }
}

/* Goto */

export const createSession = async (token: string) => {
    const response = await axios.post('https://realtime.jive.com/v2/session', {}, {
        headers: {
            "Authorization":  "Bearer "+ token
        }
    });
    return response;
}

export const initiateCall = async (token: string, dialString: string, gotoLine: string, gotoOrg: string) => {
    const line = await getLines(token, gotoLine, gotoOrg);
    const response = await axios.post('https://api.jive.com/calls/v2/calls', {
        dialString: dialString,
        from: {
            lineId: line.id
        }
    }, {
        headers: {
            "Authorization":  "Bearer "+ token
        }
    });
    return response;  
};

export const getLines =async (token:string, gotoLine: string, gotoOrg: string) => {
    const response = await axios.get('https://api.jive.com/users/v1/lines', {
        headers: {
            "Authorization":  "Bearer "+ token
        }
    });
    console.log(response.data.items, gotoLine, gotoOrg);
    return response.data.items.find((item:any) => item.name === gotoLine && item.organization.name === gotoOrg);
}

export const createSubscription = async (token: string, data:any, subscription: string) => {
    const response = await axios.post(subscription, data, {
        headers: {
            "Authorization":  "Bearer "+ token
        }
    })
}