import React, { useRef, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import configs from '../../../configs/apiConfigs';
import { deletePhoto } from '../../../services/clinicOnboard';
import { uploadPhoto } from '../../../services/clinicProfile';
export const Photos = ({ photos, clinicId, fetchProfile }: any) => {
  const [fullPhotoIndex, setFullPhotoIndex] = useState(0);
  const [showLightBox, setShowLightBox] = useState(false);
  const [imageError, setImageError] = useState(false);
  const dispatch = useDispatch();
  const uploadRef: any = useRef();
  const deletePhotoFunc = (photo: any) => {
    dispatch(fetchLoadingPending());
    deletePhoto(photo.id, clinicId).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      fetchProfile('photos');
    }).catch((err) => { dispatch(fetchLoadingSuccess(false)); });
  }
  const onChangeFile = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "image/png" ||
        file.type === 'image/jpg' ||
        file.type == 'image/jpeg') {
        var reader: any = new FileReader();
        var url: any = reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = function (e: any) {
          //Initiate the JavaScript Image object.
          var image = new Image();
          //Set the Base64 string return from FileReader as source.
          image.src = e.target.result;
          //Validate the File Height and Width.
          image.onload = function () {
            var height = image.height;
            var width = image.width;
            if (height < 256 || width < 256) {
              setImageError(true);
              return false;
            }
            setImageError(false);
            uploadPhoto(clinicId, { type: 'upload', uphoto: file }).then((success) => {
              if (success && success.status && success.status.error === false) {
                fetchProfile('photos');
                return;
              }
            })
            return true;
          };
        };
        return;
      } else {
        setImageError(true);
      }
    }
  }
  return (
    <div className="practice-photos-sec mb-4">
      <div className="card">
        <div className="card-header bg-light py-3 px-4">
          <div className="d-flex justify-content-between">
            <div className="fw-medium">Practice photos</div>
            <button className="btn p-0 btn-link" onClick={() => { uploadRef.current.click() }}>Add</button>
            <input id="myInputProfilePhotos" accept="image/png,image/gif,image/jpeg" type="file" ref={uploadRef} style={{ display: 'none' }} onChange={onChangeFile} />
          </div>
        </div>

        <div className="card-body p-4 pb-0">
          <div className="alert alert-warning" role="alert">
            <p className="m-0">Uploading practice photos helps boost your profile on XCarePRO by showing patients what your facilities look like. We recommend uploading at least 3 to 5 photos.</p>
          </div>
          <div className="clinic-img-sec">
            <div className="row">
              {
                photos.map((item: any, index: number) => {
                  return (
                    <div className="col-3" key={"image" + item.id}>
                      <div className="img-box">
                        <img src={configs.IMAGE_URL + 'clinic/' + item.image.name + '-300x300.' + item.image.type} />
                        <div className="hover-btns d-flex justify-content-center">
                          <button className="btn mx-1 p-0 text-white fs-4" onClick={() => {
                            setFullPhotoIndex(index);
                            setShowLightBox(true);
                          }}><i className="bi bi-zoom-in"></i></button>
                          <button className="btn mx-1 p-0 text-white fs-4" onClick={() => { deletePhotoFunc(item); }}><i className="bi bi-trash"></i></button>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        {
          showLightBox &&
          <div style={{ zIndex: 2000 }}>
            <Lightbox
              reactModalStyle={{ zIndex: 2000 }}
              mainSrc={configs.IMAGE_URL + 'clinic/' + photos[fullPhotoIndex].image.name + '-original.' + photos[fullPhotoIndex].image.type}
              nextSrc={configs.IMAGE_URL + 'clinic/' + photos[(fullPhotoIndex + 1) % photos.length].image.name + '-original.' + photos[(fullPhotoIndex + 1) % photos.length].image.type}
              prevSrc={configs.IMAGE_URL + 'clinic/' + photos[(fullPhotoIndex + photos.length - 1) % photos.length].image.name + '-original.' + photos[(fullPhotoIndex + photos.length - 1) % photos.length].image.type}
              onCloseRequest={() => setShowLightBox(false)}
              onMovePrevRequest={() =>
                setFullPhotoIndex((fullPhotoIndex + photos.length - 1) % photos.length)
              }
              onMoveNextRequest={() =>
                setFullPhotoIndex((fullPhotoIndex + 1) % photos.length)
              }
            />
          </div>
        }
      </div>
    </div>
  )
}