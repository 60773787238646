import axios from "axios";
import React, { useEffect, useState } from "react";
import { Device } from "@twilio/voice-sdk";
import { Modal } from "react-bootstrap";
import configs from "../../configs/apiConfigs";
import moment from "moment";
import firebase from "firebase";
import { getCookie } from "../../utils/cookies";
import { CHAT } from "../../../constants";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
let startTime = "";
let endTime = "";
const db = firebase.firestore();
export const VoiceCall = (props: any) => {
  const { callType, callTo, clientId, closeModal, callerId, appointment } =
    props;
  const [identity, setIdentity] = useState("");
  const [status, setStatus] = useState("");
  const [device, setDevice]: any = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [callObj, setCallObj]: any = useState(null);
  const userData = useSelector((state: IAppState) => state.userData.userDataResponse);
  console.log("---appointment---", appointment);
  useEffect(() => {
    setStatus("Setting up device...");
    axios
      .post(configs.COMMUNICATION_SERVER + "token/generate", {
        clientId: clientId,
      })
      .then((success) => {
        if (success && success.data) {
          const device: Device = new Device(success.data.token);
          setDevice(device);
          setTimeout(() => {
            beginCall(device);
          }, 1000);
          device.on("error", (error) => {
            console.log(error);
            setStatus("Connection failed");
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const beginCall = async (device: Device) => {
    let params: any = {};
    console.log('---CallType---', callType);
    if (callType === "web-app-patient") {
      params = {
        callToClientId: callTo,
        callerId: callerId,
        clinicName: props.appointment.clinic.name,
        clinicUniqueId: props.appointment.clinic.uniqueid,
      };
    } else if (callType === "mobile-patient") {
      params = {
        phoneNumber: "+1" + callTo,
        callerId: callerId,
        clinicName: props.appointment.clinic.name,
        clinicUniqueId: props.appointment.clinic.uniqueid,
      };
    } else {
      return;
    }
    console.log('---Params---', params);
    try {
      const response = await device.connect({
        params: params,
      });
      setCallObj(response);
      response.on("ringing", () => {
        setStatus("Ringing...");
      });
      response.on("accept", () => {
        console.log("--Accepted---");
        setStatus("Connected");
        startTime = moment().format("MMM DD, YYYY - LT");
      });
      response.on("cancel", () => {
        console.log("--Cancelled---");
        setStatus("Call cancelled");
        callHangup();
        endTime = moment().format("MMM DD, YYYY - LT");
        addCallIntoFirebase("Voice call cancelled");
      });
      response.on("disconnect", () => {
        console.log("--Disconnected---");
        setStatus("Call disconnected");
        callHangup();
        endTime = moment().format("MMM DD, YYYY - LT");
        addCallIntoFirebase("Voice call : " + startTime + " to " + endTime);
      });
      response.on("reject", () => {
        console.log("--Rejected---");
        setStatus("Call rejected");
        callHangup();
        endTime = moment().format("MMM DD, YYYY - LT");
        addCallIntoFirebase("Voice call Rejected");
      });
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  const callHangup = () => {
    let deviceObj: Device = device;
    callObj?.disconnect();
    deviceObj?.disconnectAll();
    setTimeout(() => {
      closeModal();
      document.getElementById("position-rel")?.classList.remove("call-start");
    }, 1000);
  };

  const addCallIntoFirebase = (text: string) => {
    const obj = {
      appointmentId: Number(props.appointment.appointmentId),
      senderId: Number(getCookie().xpr_user_id),
      sender: CHAT.SENDER.USER,
      action: CHAT.ACTION.CTP,
      actionType: CHAT.ACTION_TYPE.VOICE_CALL,
      text: text,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      sendToCId: props.appointment.sendToCId,
      dependantId: Number(props.appointment.dependant.uniqueid),
      dateTime: {
        date: props.appointment.date,
        time: props.appointment.time
      },
      "firstName": userData.firstName,
      "lastName": userData.lastName,
    };
    db.collection(configs.CHAT_COLLECTION)
      .doc()
      .set(obj)
      .then(() => {
        startTime = "";
        endTime = "";
      })
      .catch((error: any) => { });
  };

  return (
    <>
      <div className="call-strip">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <div className="avatar rounded-circle bg-white text-dark text-uppercase flex-shrink-0">
              {props.appointment.patient.fname
                ? props.appointment.patient.fname.charAt(0)
                : props.appointment.patient.firstName
                  ? props.appointment.patient.firstName.charAt(0)
                  : ""}
              {props.appointment.patient.lname
                ? props.appointment.patient.lname.charAt(0)
                : props.appointment.patient.lastName
                  ? props.appointment.patient.lastName.charAt(0)
                  : ""}
            </div>
            <div className="flex-grow-1 ms-3">
              <div className="d-block fw-medium p-name lh-base">
                {props.appointment.patient.fname
                  ? props.appointment.patient.fname
                  : props.appointment.patient.firstName
                    ? props.appointment.patient.firstName
                    : ""} {" "}
                {props.appointment.patient.lname
                  ? props.appointment.patient.lname
                  : props.appointment.patient.lastName
                    ? props.appointment.patient.lastName
                    : ""}
              </div>
                <>
                {
                  status == 'Calling' ?
                    <small className="d-block text-white lh-base">{status}</small>
                  :
                    <small className="d-block text-white lh-base">
                      <i className="bi bi-telephone"></i>
                      {status}
                    </small>
                }
                </>
            </div>
          </div>

          <button className="btn btn-danger" onClick={callHangup}>
            <i className="bi bi-telephone"></i> Hangup
          </button>
        </div>
      </div>
    </>
  );
};
