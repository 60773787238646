import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { IAppState } from '../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { DigitalDocHeader } from '../../components/messageCenter/DigitalDocHeader';
import { getDigitalDocNotificationList } from '../../services/messageCenter';
import { DigitalDocDocument } from './DigitalDocDocument';
import { DigitalDocNotification } from './DigitalDocNotifications';

export const DigitalDoc = () => {
    const [isAuthorize, setAuthorize] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const dispatch = useDispatch();
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    useEffect(() => {
        dispatch(fetchLoadingPending());
        getDigitalDocNotificationList({ clinicid: userData.clinics[0].id }).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success.error == false) {
                setAuthorize(true);
                setIsLoaded(true);
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            setAuthorize(false);
            setIsLoaded(true);
        })
    }, []);
    return (
        <>
            <DigitalDocHeader></DigitalDocHeader>
            <Switch>
                <Route exact path="/message-center/digitaldoc" component={DigitalDocNotification}></Route>
                <Route exact path="/message-center/digitaldoc/documents" component={DigitalDocDocument}></Route>
            </Switch>
            {
                !isAuthorize && isLoaded &&
                <div className="overlay-center">
                    <div className="vertical-center">

                        <div className="card">
                            <div className="card-body text-center p-5">
                                <div className="mb-3">Please contact admin to access digital form. You haven't subscribed to digital form section on message center.</div>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </>
    )
}