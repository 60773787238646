import { PaymentState, PaymentActionTypes, PAYMENT } from "./types";

const initialState: PaymentState = {
    pending: false,
    error: null,
}
export const paymentReducer = (state = initialState, action: PaymentActionTypes): PaymentState => {
    switch (action.type) {
        case PAYMENT.FETCH_INIT_PAYMENT:
            return {
                ...state,
                pending: true,
                error: null,
                fetchInitPaymentRequest: undefined,
                fetchInitPaymentResponse: undefined
            }
        case PAYMENT.FETCH_INIT_PAYMENT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                fetchInitPaymentRequest: undefined,
                fetchInitPaymentResponse: undefined
            }
        case PAYMENT.FETCH_INIT_PAYMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                fetchInitPaymentRequest: undefined,
                fetchInitPaymentResponse: action.payload
            }
        case PAYMENT.PAYMENT_BILLABLE_ENTRY:
            return {
                ...state,
                pending: true,
                error: null,
                paymentBillableEntryRequest: undefined,
                paymentBillableEntryResponse: undefined
            }
        case PAYMENT.PAYMENT_BILLABLE_ENTRY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                paymentBillableEntryRequest: undefined,
                paymentBillableEntryResponse: undefined
            }
        case PAYMENT.PAYMENT_BILLABLE_ENTRY_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                paymentBillableEntryRequest: undefined,
                paymentBillableEntryResponse: action.payload
            }
        case PAYMENT.PAYMENT_BILLABLE_APPOINTMENT:
            return {
                ...state,
                pending: true,
                error: null,
                paymentBillableAppointmentRequest: undefined,
                paymentBillableAppointmentResponse: undefined
            }
        case PAYMENT.PAYMENT_BILLABLE_APPOINTMENT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                paymentBillableAppointmentRequest: undefined,
                paymentBillableAppointmentResponse: undefined
            }
        case PAYMENT.PAYMENT_BILLABLE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                paymentBillableAppointmentRequest: undefined,
                paymentBillableAppointmentResponse: action.payload
            }
        case PAYMENT.SERVICES_TYPE:
            return {
                ...state,
                pending: true,
                error: null,
                servicesTypeRequest: action.payload,
                servicesTypeResponse: undefined
            }
        case PAYMENT.SERVICES_TYPE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                servicesTypeRequest: undefined,
                servicesTypeResponse: undefined
            }
        case PAYMENT.SERVICES_TYPE_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                servicesTypeRequest: undefined,
                servicesTypeResponse: action.payload
            }
        case PAYMENT.CREATE_INVOICE:
            return {
                ...state,
                pending: true,
                error: null,
                createInvoiceRequest: action.payload,
                createInvoiceResponse: undefined
            }
        case PAYMENT.CREATE_INVOICE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                createInvoiceRequest: undefined,
                createInvoiceResponse: undefined
            }
        case PAYMENT.CREATE_INVOICE_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                createInvoiceRequest: undefined,
                createInvoiceResponse: action.payload
            }
        case PAYMENT.STRIPE_SAVE_ACCOUNT_ID:
            return {
                ...state,
                pending: true,
                error: null,
                stripeSaveAccountIdRequest: action.payload,
                stripeSaveAccountIdResponse: undefined
            }
        case PAYMENT.STRIPE_SAVE_ACCOUNT_ID_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                stripeSaveAccountIdRequest: undefined,
                stripeSaveAccountIdResponse: undefined
            }
        case PAYMENT.STRIPE_SAVE_ACCOUNT_ID_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                stripeSaveAccountIdRequest: undefined,
                stripeSaveAccountIdResponse: action.payload
            }
        case PAYMENT.SAVE_TAX:
            return {
                ...state,
                pending: true,
                error: null,
                saveTaxRequest: action.payload,
                saveTaxResponse: undefined
            }
        case PAYMENT.SAVE_TAX_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                saveTaxRequest: undefined,
                saveTaxResponse: undefined
            }
        case PAYMENT.SAVE_TAX_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                saveTaxRequest: undefined,
                saveTaxResponse: action.payload
            }
        case PAYMENT.PAYMENT_AMOUNT_UPDATE:
            return {
                ...state,
                pending: true,
                error: null,
                paymentAmountRequest: action.payload,
                paymentAmountResponse: undefined
            }
        case PAYMENT.PAYMENT_AMOUNT_UPDATE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                paymentAmountRequest: undefined,
                paymentAmountResponse: undefined
            }
        case PAYMENT.PAYMENT_AMOUNT_UPDATE_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                paymentAmountRequest: undefined,
                paymentAmountResponse: action.payload
            }
        default:
            return state;
    }
}
