import React, { Component } from 'react';
import Axios from 'axios';
import { attachHeader } from '../../../utils/apiHandler';
import configs from '../../../configs/apiConfigs';
import { UserDataResponse } from '../../../store/userData/types';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import moment from 'moment';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { FetchSubscriptionPlanRequest, SubscriptionState, ClinicMyplanRequest } from '../../../store/subscriptionplan/types';
import { fetchSubscriptionPlan } from '../../../store/subscriptionplan/actions';
import { currentPlan, getInvoiceList } from '../../services/subscription';
import { toast } from 'react-toastify';
import { NavLink, Redirect } from 'react-router-dom';
import { ReadMore } from '../../components/subscription/ReadMore';
import { myPlan } from '../../../store/subscriptionplan/actions';
import { RenewPlanMessage } from '../../components/subscription/RenewPlanMessage';
import { roundNumberWithRoundingFactorDecimalFix } from '../../../utils/global';

interface Props {
    userDataResponse: UserDataResponse;
    history: any;
    loadingStart: typeof fetchLoadingPending;
    loadingEnd: typeof fetchLoadingSuccess;
    fetchSubscriptionPlan: typeof fetchSubscriptionPlan;
    subscriptionState: SubscriptionState;
    myPlan: typeof myPlan;
}
interface State {
    currentPlan: any;
    planList: any;
    invoiceList: any;
    isAdvertisement: boolean;
    showReadMore: boolean;
    isStandardPlan: boolean;
    isStandardPlanCancel: boolean;
    myPlanList: any;
    isReNewPlan: boolean;
    renewRequest: any;
    isReNewBtnShow: boolean;
    couponMessage: any;
}
class Overview extends Component<Props, State>{
    clinicId: any;
    constructor(props: any) {
        super(props);
        this.state = {
            currentPlan: [],
            planList: [], invoiceList: [], isAdvertisement: false, showReadMore: false,
            isStandardPlan: false, isStandardPlanCancel: false, myPlanList: [], isReNewPlan: false,
            renewRequest: '', isReNewBtnShow: true, couponMessage: ''
        }
    }
    componentDidMount() {
        if (this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics.length > 0) {
            this.props.loadingStart();
            let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
            this.currentPlanApi(clinicUniqueId);
            this.invoiceListApi(clinicUniqueId);
            this.props.myPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId });
        }
    }
    invoiceListApi = (clinicUniqueId: any) => {
        getInvoiceList(clinicUniqueId).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.invoicedetail && success.response.data.status.error === false) {
                    this.setState({ invoiceList: success.response.data.invoicedetail });
                    this.props.loadingEnd(false);
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        // toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            } else {
                toast.error('Something went wrong.')
                this.props.loadingEnd(false);
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });

    }
    currentPlanApi = (clinicUniqueId: any) => {
        currentPlan(clinicUniqueId).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    this.setState({ currentPlan: success.response.data, couponMessage: success.response.data.coupon_msg });
                    if (success.response.data && success.response.data.active_plans && success.response.data.active_plans.length > 0) {
                        for (let i = 0; i < success.response.data.active_plans.length; i++) {
                            if (success.response.data.active_plans[i].name === "Standard" && success.response.data.active_plans[i].product_mode !== "allinone") {
                                this.setState({ isStandardPlan: true });
                            }
                        }
                    }
                    success.response.data.active_plans.
                        this.props.loadingEnd(false);
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.userDataResponse !== this.props.userDataResponse) {
            if (this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics.length > 0) {
                this.props.loadingStart();
                let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
                this.clinicId = this.props.userDataResponse.clinics[0].uniqueId;
                this.currentPlanApi(clinicUniqueId);
                this.invoiceListApi(clinicUniqueId);
                this.props.myPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId });
            }
        }
        if (this.props.subscriptionState.fetchSubscriptionPlanResponse !== prevProps.subscriptionState.fetchSubscriptionPlanResponse &&
            this.props.subscriptionState.fetchSubscriptionPlanResponse && this.props.subscriptionState.fetchSubscriptionPlanResponse.status.error === false) {
            this.setState({ planList: this.props.subscriptionState.fetchSubscriptionPlanResponse },
                () => {
                    let type: any;
                    let interval: any;
                    this.state.currentPlan && this.state.currentPlan.active_plans && this.state.currentPlan.active_plans.length > 0 &&
                        this.state.currentPlan.active_plans.map((item: any) => {
                            if (item.plan_type !== 'custom') {
                                type = item.plan_type;
                                interval = item.interval;
                            }
                        });
                    if (this.state.planList) {
                        if (!this.state.isAdvertisement) {
                            this.props.history.push({ pathname: '/subscription/plans', state: { screenname: 'overview', type: type, interval: interval, data: this.state.planList, planName: "" } });
                        } else {
                            this.props.history.push({ pathname: '/subscription/plans', state: { screenname: 'overview', planName: interval === "month" ? 'Recall and Recare Monthly' : 'Recall and Recare Yearly', interval: interval, type: type, data: this.state.planList } });
                        }
                    }
                });
        }
        // if(prevState.invoiceList !== this.state.invoiceList){
        //     let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
        //     this.invoiceListApi(clinicUniqueId);
        // }
        if (this.props.subscriptionState !== prevProps.subscriptionState) {
            if (this.props.subscriptionState.error) {
                toast.error(this.props.subscriptionState.error);
            } else if (this.props.subscriptionState.clinicMyplanResponce !== prevProps.subscriptionState.clinicMyplanResponce &&
                this.props.subscriptionState.clinicMyplanResponce && this.props.subscriptionState.clinicMyplanResponce.status.error === false) {
                this.setState({
                    myPlanList: this.props.subscriptionState.clinicMyplanResponce.plans ? this.props.subscriptionState.clinicMyplanResponce.plans : [],
                }, () => {
                    for (let i = 0; i < this.state.myPlanList.length; i++) {
                        if (this.state.myPlanList[i].planName === "Standard Plan" && this.state.myPlanList[i].isCancelledPlan === true) {
                            console.log('call');
                            this.setState({
                                isStandardPlanCancel: true,
                                renewRequest: {
                                    "subscriptions": [{ "subscribed_subscription_id": this.state.myPlanList[i].subscribedId, "id": this.state.myPlanList[i].id }]
                                }

                            });
                            if (this.state.myPlanList[i].trial_enddate && moment().format('YYYY-MM-DD') < moment(this.state.myPlanList[i].trial_enddate).format('YYYY-MM-DD')) {
                                let date = moment(this.state.myPlanList[i].trial_enddate).format('YYYY-MM-DD');
                                let diff = moment(date).diff(moment().format('YYYY-MM-DD'), 'days');
                                if (diff === 1) {
                                    this.setState({ isReNewBtnShow: false });
                                }
                            } else {
                                let date = moment(this.state.myPlanList[i].subscribedEndDate).format('YYYY-MM-DD');
                                let diff = moment(date).diff(moment().format('YYYY-MM-DD'), 'days');
                                if (diff === 1) {
                                    this.setState({ isReNewBtnShow: false });
                                }
                            }
                        }
                    }

                })
            }
        }
    }
    advertiseUpgradePlan = () => {
        // let type: any;
        // let interval: any;
        // if(this.state.currentPlan && this.state.currentPlan.active_plans && this.state.currentPlan.active_plans.length > 0){
        //     this.state.currentPlan.active_plans.map((item: any) => {
        //         if (item.plan_type !== 'custom') {
        //             type = item.plan_type;
        //             interval = item.interval;
        //         }
        //     });
        // }
        this.setState({ isAdvertisement: true }, () => {
            if (this.props.userDataResponse && this.props.userDataResponse.clinics.length > 0 && this.props.userDataResponse.clinics[0].uniqueId) {
                this.props.fetchSubscriptionPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId ? this.props.userDataResponse.clinics[0].uniqueId : "" });
            }
        })
    }
    upgradePlan = () => {
        if (this.props.userDataResponse && this.props.userDataResponse.clinics.length > 0 && this.props.userDataResponse.clinics[0].uniqueId) {
            this.props.fetchSubscriptionPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId ? this.props.userDataResponse.clinics[0].uniqueId : "" });
        }

    }


    render() {
        return (
            this.state.currentPlan && this.state.currentPlan.active_plans && this.state.currentPlan.active_plans.length > 0 ?
                <section className="subscription-sec px-3 pt-4">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-8">
                                <div className="border p-4 border-1 rounded bg-light custom-card default-card">
                                    {
                                        (this.state.currentPlan && this.state.currentPlan.active_plans &&
                                            this.state.currentPlan.active_plans.length > 0) &&
                                        <>
                                            <div className="d-flex justify-content-between">
                                                {
                                                    this.state.currentPlan.active_plans.map((item: any) => {
                                                        let diff: any = 0
                                                        if (item.plan_type !== "custom") {
                                                            if (item.trial_enddate !== null && item.plan_type !== 'basic') {
                                                                let date = moment(item.trial_enddate).format('YYYY-MM-DD');
                                                                diff = moment(date).diff(moment().format('YYYY-MM-DD'), 'days');
                                                            }
                                                            if (item.trial_enddate === null && item.end_date !== null && item.plan_type !== 'basic' && item.interval === "month") {
                                                                let date = moment(item.end_date).format('YYYY-MM-DD');
                                                                diff = moment(date).diff(moment().format('YYYY-MM-DD'), 'days');
                                                            }
                                                            return (
                                                                <>
                                                                    <div>
                                                                        <div className="badge rounded-pill bg-warning mb-2">Current plan</div>
                                                                        <div className="info-box fs-5 fw-medium">{item.name}</div>
                                                                        {item.trial_enddate !== null && item.plan_type !== 'basic' &&
                                                                            <div className="text-muted small align-self-center"><i className="bi bi-info-circle"></i> Your free trial expires in {diff} days</div>
                                                                        }
                                                                        {(item.trial_enddate === null && item.end_date !== null && item.plan_type !== 'basic' && item.interval === "month") &&
                                                                            <div className="text-muted small align-self-center"><i className="bi bi-info-circle"></i> Your subscription will expires in {diff} days</div>
                                                                        }
                                                                    </div>
                                                                    <div className="align-self-center">
                                                                        <div className="d-flex">
                                                                            <div className="pr-2 price-tag">
                                                                                <span className="fs-6 pr-1 position-relative">$</span>
                                                                                <span className="fw-medium fs-1">{item.price}</span>
                                                                                / {item.interval}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-2">
                                                                            (Price before taxes and discounts)
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )

                                                        }
                                                    })
                                                }
                                            </div>
                                        </>
                                    }
                                    {
                                        (this.state.currentPlan && this.state.currentPlan.active_plans &&
                                            this.state.currentPlan.active_plans.length > 0) &&
                                        <div className="addon-list my-4">
                                            {this.state.currentPlan.active_plans.length <= 1 && <small className="fw-medium">You don't have any activated addon.</small>}
                                            {
                                                this.state.currentPlan.active_plans.map((item: any) => {
                                                    if (item.plan_type === "custom") {
                                                        return (
                                                            <div className="d-flex my-2 border p-4 py-3 border-1 rounded default-card bg-white justify-content-between">
                                                                <div className="d-flex">
                                                                    <div className="avatar avatar-lg rounded-circle me-3">
                                                                        <img src={item.img_url} />
                                                                    </div>
                                                                    <div>
                                                                        <small className="fw-medium text-muted">{item.title}</small>
                                                                        <div className="info-box fs-5 fw-medium">{item.name}</div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (item.product_mode !== 'allinone') ?
                                                                        <div className="pr-2 price-tag align-self-center">
                                                                            <span className="fs-6 pr-1 position-relative">$</span>
                                                                            <span className="fw-medium fs-1">{item.price}</span>
                                                                        </div>
                                                                        :
                                                                        (item.display_amount) &&
                                                                        <div className="pr-2 price-tag align-self-center">
                                                                            <span className="fs-6 pr-1 position-relative">$</span>
                                                                            <span className="fw-medium fs-1">{item.display_amount}</span>
                                                                        </div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }

                                        </div>
                                    }

                                    <div className="d-flex justify-content-end mt-4 info-box-addons">
                                        {/* {this.state.isStandardPlan && <div className="text-muted small align-self-center"><i className="bi bi-info-circle"></i> You can subscribe to add-ons from Subscription plans tab</div>} */}
                                        <div onClick={() => { !this.state.isStandardPlanCancel ? this.upgradePlan() : this.setState({ isReNewPlan: true }) }} className="btn btn-outline-primary">{!this.state.isStandardPlan ? 'Manage plan' : 'Add Add-ons'}</div>
                                    </div>
                                    <button className="btn btn-link p-0" onClick={() => {
                                        // this.setState({ showReadMore: true });
                                        window.open('https://www.xcare.app/pricing/')
                                    }}>Know more about plan</button>
                                    {this.state.couponMessage && <div className="small align-self-center"><span className="fw-medium">Note :</span> {this.state.couponMessage}</div>}
                                </div>
                            </div>
                            {
                                (this.state.currentPlan && this.state.currentPlan.amount &&
                                    this.state.currentPlan.plans_exp) &&
                                <div className="col-4">
                                    <div className="sidebar p-4 border border-1 rounded bg-light default-card addon-sidebar">
                                        <div className="d-flex align-items-start flex-column bd-highlight mb-3 h-100">
                                            {/* {this.state.currentPlan.plans_exp.map((item: any) => {
                                            return ( */}
                                            <>
                                                <div className="pr-2 price-tag bd-highlight w-100">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="fw-medium fs-5">
                                                            Plan Price:
                                                        </div>
                                                        <div>
                                                            {/* <span className="fs-6 pr-1 position-relative">$</span> */}
                                                            <span className="fw-medium fs-3">${this.state.currentPlan.amount}.00</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.currentPlan.discount > 0 &&

                                                        <div className="d-flex justify-content-between mt-3">
                                                            <div className="fw-medium fs-5">
                                                                Discount:
                                                            </div>
                                                            <div>

                                                                {/* <span className="fs-6 pr-1 position-relative">$</span> */}
                                                                <span className="fw-medium fs-3">- (${this.state.currentPlan.discount})</span>

                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.currentPlan.tax_percentage !== '' && this.state.currentPlan.tax_amount > 0 &&

                                                        <div className="d-flex justify-content-between mt-3">
                                                            <div className="fw-medium fs-5">
                                                                Tax ({this.state.currentPlan.tax_percentage}%):
                                                            </div>
                                                            <div>

                                                                {/* <span className="fs-6 pr-1 position-relative">$</span> */}
                                                                <span className="fw-medium fs-3">${roundNumberWithRoundingFactorDecimalFix(this.state.currentPlan.tax_amount, 2)}</span>

                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="fw-medium fs-5">
                                                            Total Plan Price:
                                                        </div>
                                                        <div>
                                                            {/* <span className="fs-6 pr-1 position-relative">$</span> */}
                                                            <span className="fw-medium fs-1">${roundNumberWithRoundingFactorDecimalFix((Number(this.state.currentPlan.amount) - Number(this.state.currentPlan.discount) + Number(this.state.currentPlan.tax_amount)), 2)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="next-date mt-2 bd-highlight">
                                                    {/* <div className="fw-medium fs-5 mt-2">Plan Price: <span className="fs-6 pr-1 position-relative">$</span> <span className="fw-medium fs-3">{this.state.currentPlan.amount}</span></div> */}
                                                    {/* {this.state.currentPlan.discount && <div className="fw-medium fs-5 mt-1">Discount: -<span className="fs-6 pr-1 position-relative">$</span> <span className="fw-medium fs-1">{this.state.currentPlan.discount}</span></div>} */}
                                                    {/* {this.state.currentPlan.amount && <div className="fw-medium fs-5 mt-1">Total Plan Price: $<span className="fw-medium fs-1">{Number(this.state.currentPlan.amount) - Number(this.state.currentPlan.discount)}.00</span></div>} */}
                                                    <small className="d-block fw-medium text-muted mt-1">Next payment</small>
                                                    <div className="fw-medium fs-5">On {moment(this.state.currentPlan.plans_exp).format('MMMM DD, YYYY')}</div>
                                                    <div className="fw-medium fs-5 mt-2">Credit Balance : {this.state.currentPlan.creditbalance ? `$${this.state.currentPlan.creditbalance}` : '$0'}</div>
                                                </div>
                                            </>
                                            {/* //     )
                                        // })
                                        // } */}
                                            <button
                                                onClick={() => { this.props.history.push({ pathname: '/subscription/manage-plans' }) }}
                                                className="btn btn-outline-primary mt-5">Manage plan</button>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                        {this.state.invoiceList && this.state.invoiceList.length > 0 &&
                            <>
                                <hr className="my-5" />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between mb-3">
                                            <div className="fw-medium">Recent invoices</div>
                                            <button onClick={() => { this.props.history.push({ pathname: '/subscription/invoices' }) }} className="btn btn-link p-0">View all invoices</button>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <ul className="recent-invoice-list p-0">
                                            <li className="d-flex justify-content-between px-4">
                                                <div className="fw-medium small">Files name</div>
                                                <div className="fw-medium small">Created Date</div>
                                                <div className="fw-medium small">Total</div>
                                                <div className="fw-medium small">Status</div>
                                                <div className="li-box"></div>
                                            </li>
                                            {
                                                this.state.invoiceList.map((item: any, index: number) => {
                                                    if (index < 5) {
                                                        return (
                                                            <li className="content-box d-flex justify-content-between border border-1 rounded default-card px-4 py-3 my-2">
                                                                <div className="d-flex">
                                                                    <span className="avatar avatar-48 img-circle me-2"><i className="bi bi-file-earmark-ppt-fill"></i></span>
                                                                    {/* <img src={require("../../assets/images/rounded-pdf.svg")} /> */}
                                                                    <span className="align-self-center">{item && item.invoice_name && item.invoice_name}</span>
                                                                </div>
                                                                <div className="align-self-center"><small>{item && item.created_date && item.created_date}</small></div>
                                                                <div className="align-self-center"><small>{item && item.total && item.total}</small></div>
                                                                <div className="align-self-center"><span className="badge rounded-pill bg-success">{item && item.status && item.status}</span></div>
                                                                <div className="align-self-center"><a href={item && item.invoice_url && item.invoice_url} className="btn mx-1"><i className="bi bi-download"></i></a></div>
                                                            </li>
                                                        )
                                                    }
                                                })
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <ReadMore show={this.state.showReadMore} onHide={() => { this.setState({ showReadMore: false }) }}></ReadMore>
                    <RenewPlanMessage
                        clinicUniqueId={this.props.userDataResponse.clinics[0].uniqueId}
                        history={this.props.history}
                        successReNewPlan={() => {
                            this.setState({ isStandardPlanCancel: false });
                            this.props.myPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId });
                        }}
                        isReNewBtnShow={this.state.isReNewBtnShow}
                        renewRequest={this.state.renewRequest}
                        isReNewPlan={this.state.isReNewPlan} onHide={() => { this.setState({ isReNewPlan: false }) }}></RenewPlanMessage>
                </section>
                :
                null
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    userDataResponse: state.userData.userDataResponse,
    subscriptionState: state.subscriptionPlan,

});
const mapDispatchToProps = (dispatch: any) => ({
    loadingStart: () => {
        dispatch(fetchLoadingPending());
    },
    loadingEnd: (status: any) => {
        dispatch(fetchLoadingSuccess(status));
    },
    fetchSubscriptionPlan: (request: FetchSubscriptionPlanRequest) => {
        dispatch(fetchSubscriptionPlan(request));
    },
    myPlan: (request: ClinicMyplanRequest) => {
        dispatch(myPlan(request));
    },

});
export default connect(mapStateToProps, mapDispatchToProps)(Overview);