import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { updateProviderProfile } from '../../services/provider';
export const PMSMapping = ({ providerId, fetchProfile, sikkaMapping, masterPMSProviderList, specialities, providerName }: any) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pmsMapping, setPMSMapping]: any = useState([]);
    const { register, errors, setValue, getValues, setError, handleSubmit } = useForm({
        defaultValues: {
            speciality: '',
            pmsprovider: ''
        }
    });
    useEffect(() => {
        if (sikkaMapping && Array.isArray(sikkaMapping)) {
            setPMSMapping(sikkaMapping);
        }
    }, [sikkaMapping]);
    const onCancel = () => {
        setIsEdit(false);
        setPMSMapping(sikkaMapping);
    };
    const onSave = () => {
        setIsLoading(true);
        updateProviderProfile('pmsProviders', providerId, pmsMapping).then((success) => {
            if (success && success.status && success.status.error === false) {
                fetchProfile().then((success: any) => {
                    setIsEdit(false);
                    setTimeout(() => {
                        setIsLoading(false);
                        setIsEdit(false);
                    }, 2000);
                });
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        });
    };
    const onSubmit = (data: any) => {
        if (!(pmsMapping.find((item: any) => { return item.specialityId === data.speciality && item.sikkaId === data.pmsprovider }))) {
            setPMSMapping([...pmsMapping, {
                specialityId: data.speciality,
                sikkaId: data.pmsprovider,
            }]);
        }
    }
    useEffect(() => {
        if (masterPMSProviderList && Array.isArray(masterPMSProviderList) && masterPMSProviderList[0]) {
            setValue('pmsprovider', masterPMSProviderList[0].sikkaId);
        }
    }, [masterPMSProviderList]);
    useEffect(() => {
        if (specialities && Array.isArray(specialities) && specialities[0]) {
            setValue('speciality', specialities[0].id);
        }
    }, [specialities]);
    const handleRemoveClick = (item: any) => {
        setPMSMapping(pmsMapping.filter((pItem: any) => { return !(pItem.sikkaId === item.sikkaId && pItem.specialityId === item.specialityId) }));
    }
    console.log(specialities, pmsMapping);
    return (
        <div className="pms-maping-sec mb-4">
            <div className="card">
                <div className="card-header bg-light py-3 px-4">
                    <div className="d-flex justify-content-between">
                        <div className="fw-medium">Patient Management System Mapping</div>
                        <div>
                            {
                                isEdit && isLoading &&
                                <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
                            }
                            {
                                isLoading && !isEdit &&
                                <i className="bi bi-check-circle-fill text-success"></i>
                            }
                            {
                                isEdit && !isLoading &&
                                <>
                                    <button className="btn p-0 btn-link me-2 text-secondary" onClick={() => {
                                        onCancel();
                                    }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                    <button className="btn p-0 btn-link" onClick={() => {
                                        onSave();
                                    }}><i className="bi bi-check-circle-fill text-success"></i></button>
                                </>
                            }
                            {
                                !isEdit && !isLoading &&
                                <button className="btn p-0 btn-link" onClick={() => {
                                    setIsEdit(true);
                                }}>Edit</button>
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    <div className="fw-medium fs-5">Map provider with PMS</div>
                    <p className="mt-1">Please set the office hours to let patients know when you're open</p>
                    <hr className="my-4" />

                    <div className="row">
                        <div className="col-12">
                            <div className="insurance-list d-flex flex-wrap mb-4">
                                {
                                    pmsMapping.map((item: any) => {
                                        const speciality = specialities.find((sitem: any) => sitem.id === item.specialityId);
                                        if (speciality) {
                                            return (
                                                <div className="badge text-dark border rounded-pill me-2 ps-3" key={item.sikkaId + "" + item.specialityId}>
                                                    {providerName} - {speciality.name} {item.sikkaId}
                                                    {
                                                        isEdit &&
                                                        <button className="btn py-0 pe-0" onClick={(e) => {
                                                            handleRemoveClick(item);
                                                        }}><i className="bi bi-x"></i></button>
                                                    }
                                                </div>
                                            )
                                        }
                                        return null;
                                    })
                                }
                            </div>
                        </div>
                        <div className={"col-12 " + (!isEdit ? 'd-none' : '')}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="d-flex add-pms">
                                    <div className="input-box">
                                        <div className="">
                                            <label className="form-label">Provider (map Speciality)</label>
                                            <select className="form-select" name="speciality" ref={register({ required: true })}>
                                                {
                                                    (specialities && Array.isArray(specialities)) &&
                                                    specialities.filter((item: any) => {
                                                        return pmsMapping.find((pItem: any) => {
                                                            return pItem.specialityId === item.id
                                                        }) === undefined
                                                    }).map((item) => {
                                                        return (<option key={item.id} value={item.id}>{providerName} - {item.name}</option>);
                                                    })
                                                }
                                            </select>
                                            {
                                                (errors && errors.speciality && errors.speciality.type === 'required') &&
                                                <div className="is-invalid">Required</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="pb-2 mx-3 align-self-end"><i className="bi bi-arrow-left-right"></i></div>
                                    <div className="input-box">
                                        <div className="">
                                            <label className="form-label">PMS provider list </label>
                                            <select className="form-select" name="pmsprovider" ref={register({ required: true })}>
                                                {
                                                    (masterPMSProviderList && Array.isArray(masterPMSProviderList)) &&
                                                    masterPMSProviderList.map((item) => {
                                                        return (<option key={item.sikkaId} value={item.sikkaId}>{item.fname + ' ' + item.lname} - {item.sikkaId}</option>);
                                                    })
                                                }
                                            </select>
                                            {
                                                (errors && errors.pmsprovider && errors.pmsprovider.type === 'required') &&
                                                <div className="is-invalid">Required</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="align-self-end">
                                        <button className="btn btn-primary fw-normal ms-3 rounded align-self-center" type="submit"> Add</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}