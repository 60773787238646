import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../../constants/api"
import { commonApi } from "../../helpers/api";
import { attachHeader } from "../../utils/apiHandler";
import { getCookie } from "../../utils/cookies";
import config from '../configs/apiConfigs';

export const getSpecialityList = (clinicuniqueId: any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'master/speciality/clinic-' + clinicuniqueId + '/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const getLanguagesList = () => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'languages/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const createStep = (data: any) => {
    const login: any = getCookie();

    const request: CommonApiOptions = {
        url: config.API_URL + 'doctor/' + login.xpr_user_id + '/create/step-' + data.stepNumber + '/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.FORM_DATA,
            data: data.formData
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const getServiceList = (speciality: any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'services/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: {
                speciality: speciality
            }
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }

    };
    return commonApi(request);
};

export const getPMSProviderList = (clinicid: any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'master/pmsProvider/' + clinicid + '/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const getQuestionsList = () => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'questions/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: {
                modelType: "doctor"
            }
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};
