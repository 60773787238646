import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import React, { useEffect, useState } from 'react';
import { fetchLoadingPending, fetchLoadingSuccess } from "../../../../store/loadingIndicator/actions";
import { getNotificationPatientListFollowup, updateNotificationPatientListFollowup } from '../../../services/messageCenter';
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { confirmAlert } from 'react-confirm-alert';
import Pagination from 'react-js-pagination';
import CONSTANT_LABELS from "../../../../constants/labels";
import { removeCountryCode } from "../../../../utils/global";
import AddBtnIcon from "../../../assets/images/add-btn-icon.svg";
export const NotificationPatients = () => {
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const isLoaded = useSelector((state: IAppState) => state.loading.pending, shallowEqual);
    const dispatch = useDispatch();
    const location = useLocation();

    const [notificationpatientListState, setnotificationpatientListState] = useState([]);

    useEffect(() => {
    }, []);
    var searchtext = new URLSearchParams(location.search).get("q");
    const getPatientList = () => {
        if (userData && userData.clinics[0] && userData.clinics[0].id) {
            dispatch(fetchLoadingPending());
            getNotificationPatientListFollowup({
                clinicid: userData.clinics[0].id,
                searchtext: searchtext === null ? '' : searchtext,
                pageno: page,
                offset: results
            }).then((success: any) => {
                dispatch(fetchLoadingSuccess(false));
                if (success.error === false) {
                    setnotificationpatientListState(success.data);
                    if (success.data[0]) {
                        setTotalCount(success.data[0].total);
                    }
                    return;
                } toast.error(success.message);
            }).catch((err) => {
                dispatch(fetchLoadingSuccess(false));
                toast.error('Something went wrong.');
            });
        }
    }

    useEffect(() => {
        getPatientList();
    }, [page, searchtext]);

    const updatepatientstatus = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Are you sure to ' + (item.messagecenterfollowupstatus ? 'disable' : 'enable') + ' this configuration.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(fetchLoadingPending());
                        updateNotificationPatientListFollowup({
                            clinicid: userData.clinics[0].id,
                            dependantid: item.dependantid,
                            messagecenterstatus: !item.messagecenterfollowupstatus
                        }).then((success: any) => {
                            if (success.error === false) {
                                getPatientList();
                            }
                        }).catch((err) => {
                            dispatch(fetchLoadingSuccess(false));
                            toast.error('Something went wrong.');
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const handlePageChange = (page: any) => {
        setPage(page);
    }
    return (
        <section className="recall-recare-sec p-4 camp-popups-sec">
            <div className="row mx-0">
                <div className="col-12 d-none">
                    <div className="filter-strip d-flex my-4 justify-content-between">
                        <div className="align-self-center me-2 recall-checkbox-sec">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" checked />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Active</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" checked />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Inactive</label>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="mx-1">
                                <select className="form-select" aria-label="Default select example">
                                    <option >Over due list</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="mx-1">
                                <select className="form-select" aria-label="Default select example">
                                    <option>Financial ratings</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="mx-1">
                                <select className="form-select" aria-label="Default select example">
                                    <option>Health ratings</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="mx-1">
                                <select className="form-select" aria-label="Default select example">
                                    <option>Appointment type</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="ms-1">
                                <button className="btn btn-primary"><img className="me-1" src={AddBtnIcon} />Create campaign</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    {
                        (notificationpatientListState.length > 0) &&
                        <div className="advances-table">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            {/* <div className="form-check d-none">
                                            <input className="form-check-input" type="checkbox" value="" />
                                        </div> */}
                                        </th>
                                        <th>Name</th>
                                        <th>preference</th>
                                        <th>Mobile Number/email</th>
                                        {/* <th>Last Visit date</th> */}
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        notificationpatientListState.map((item: any, index: number) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <div className="form-check d-none">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="d-block fw-medium">{item.dependantfname + ' ' + item.dependantlname}</span>
                                                        <small className={item.messagecenterfollowupstatus ? "text-success" : "text-danger"}>{item.messagecenterfollowupstatus ? 'Active' : 'Inactive'}</small>
                                                    </td>
                                                    <td className="text-capitalize">
                                                        {
                                                            item.compref !== '' ?
                                                                <>{item.commpref.replaceAll('&', ',').replaceAll(' ', '').toLowerCase()}</> : '-'
                                                        }
                                                    </td>
                                                    <td>
                                                        <div>{item.phonenumber ? (CONSTANT_LABELS.PLUS_ONE + removeCountryCode(item.phonenumber)) : ''}</div>
                                                        <div>{item.emailaddress}</div>
                                                    </td>
                                                    {/* <td>
                                                        {item.lastvisitdate ? item.lastvisitdate : '-'}
                                                    </td> */}
                                                    <td >
                                                        {item.messagecenterfollowupstatus ? 'Active' : 'Inactive'}
                                                    </td>
                                                    <td >
                                                        {
                                                            item.dependantreminder === "yes" &&
                                                            <button className={item.messagecenterfollowupstatus ? "btn btn-link" : "btn btn-link text-muted"} onClick={() => { updatepatientstatus(item) }}>{item.messagecenterfollowupstatus ? 'Disable' : 'Enable'}</button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    hideDisabled
                                    activePage={page}
                                    itemsCountPerPage={results}
                                    totalItemsCount={Number(totalCount)}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    }
                    {
                        (isLoaded === false && notificationpatientListState.length === 0) &&
                        <label>No patient list</label>
                    }
                </div>
            </div>
        </section>
    )
}