import React,{useEffect} from 'react';
import { Modal } from 'react-bootstrap';
import {renewPlan} from '../../services/subscription';
import { toast } from 'react-toastify';

export const RenewPlanMessage=(props:any)=> {

    const  handleRenewPlan = () => {
        if(props.clinicUniqueId && props.renewRequest){
            renewPlan(props.clinicUniqueId, props.renewRequest).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    toast.success(success.response.data.status.msg);
                    props.successReNewPlan();
                    props.onHide();
                    // this.props.loadingEnd(false);
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    // this.props.loadingEnd(false);
                }
            } else {
                // this.props.loadingEnd(false);
            }
            }).catch((err) => {
                // this.props.loadingEnd(false);
                return '';
            });
        }
        
    }
    return (
        <Modal show={props.isReNewPlan} onHide={props.onHide}>
             <Modal.Header className="border-0">
                <button type="button" className="btn-close" aria-label="Close" onClick={props.onHide}></button>
            </Modal.Header>
            <Modal.Body>
                <p>You do not have any active plan, please renew your plan</p>
                {/* <div> */}
                    <button onClick={()=>{props.onHide()}}  className="btn btn-secondary me-2">Cancel</button>
                    <button disabled={!props.isReNewBtnShow} onClick={()=>{props.history.push({pathname:'/subscription/manage-plans'})}} className="btn btn-primary">Renew Plan</button>
                {/* </div> */}
            </Modal.Body>
        </Modal>
    )
}

