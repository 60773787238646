import { AddEmployeeRequest, AddEmployeePendingAction, EMPLOYEE, AddEmployeeFailureAction, AddEmployeeResponse, AddEmployeeSuccessAction, EmployeeListRequest, EmployeeListPendingAction, EmployeeListFailureAction, EmployeeListResponse, EmployeeListSuccessAction, EmployeeDeleteRequest, EmployeeDeletePendingAction, EmployeeDeleteFailureAction, EmployeeDeleteResponse, EmployeeDeleteSuccessAction, EmployeePrescreeningRequest, EmployeePrescreeningPendingAction, EmployeePrescreeningResponse, EmployeePrescreeningSuccessAction, EmployeePrescreeningFailureAction } from "./types";

export const addEmployeePending = (request: AddEmployeeRequest): AddEmployeePendingAction => ({
    type: EMPLOYEE.ADD_EMPLOYEE,
    payload: request
});
export const addEmployeeFailure = (error: any): AddEmployeeFailureAction => ({
    type: EMPLOYEE.ADD_EMPLOYEE_FAILURE,
    payload: error
});
export const addEmployeeSuccess = (response: AddEmployeeResponse): AddEmployeeSuccessAction => ({
    type: EMPLOYEE.ADD_EMPLOYEE_SUCCESS,
    payload: response
});
export const employeeListPending = (request: EmployeeListRequest): EmployeeListPendingAction => ({
    type: EMPLOYEE.EMPLOYEE_LIST,
    payload: request
});
export const employeeListFailure = (error: any): EmployeeListFailureAction => ({
    type: EMPLOYEE.EMPLOYEE_LIST_FAILURE,
    payload: error
});
export const employeeListSuccess = (response: EmployeeListResponse): EmployeeListSuccessAction => ({
    type: EMPLOYEE.EMPLOYEE_LIST_SUCCESS,
    payload: response
});
export const employeeDeletePending = (request: EmployeeDeleteRequest): EmployeeDeletePendingAction => ({
    type: EMPLOYEE.DELETE_EMPLOYEE,
    payload: request
});
export const employeeDeleteFailure = (error: any): EmployeeDeleteFailureAction => ({
    type: EMPLOYEE.DELETE_EMPLOYEE_FAILURE,
    payload: error
});
export const employeeDeleteSuccess = (response: EmployeeDeleteResponse): EmployeeDeleteSuccessAction => ({
    type: EMPLOYEE.DELETE_EMPLOYEE_SUCCESS,
    payload: response
});
export const employeePrescreeningPending = (request: EmployeePrescreeningRequest): EmployeePrescreeningPendingAction => ({
    type: EMPLOYEE.EMPLOYEE_PRESCREENING,
    payload: request
});
export const employeePrescreeningSuccess = (response: EmployeePrescreeningResponse): EmployeePrescreeningSuccessAction => ({
    type: EMPLOYEE.EMPLOYEE_PRESCREENIN_SUCCESS,
    payload: response
});
export const employeePrescreeningError = (error: any): EmployeePrescreeningFailureAction => ({
    type: EMPLOYEE.EMPLOYEE_PRESCREENIN_FAILURE,
    payload: error
});
export const employeeInit = () => ({
    type: EMPLOYEE.EMPLOYEE_INIT
});