export const validateAddCard = (card: any) => {
    let error: any = {};
    if (!card.title || (card.title && card.title.length === 0)) {
        error.title = { error: true, message: 'Card title is required' };
    }
    if (!card.description || (card.description && card.description.length === 0)) {
        error.description = { error: true, message: 'Card description is required' };
    }
    if (!card.assignedTo || (card.assignedTo && card.assignedTo.length === 0)) {
        error.assignedTo = { error: true, message: 'Assigned to is required' };
    }
    if (!card.targetDate || (card.targetDate && card.targetDate.length === 0)) {
        error.targetDate = { error: true, message: 'Target date is required' };
    }
    return error;
}