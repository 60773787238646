import {
    ClinicAddActionTypes,
    ClinicAddState,
    ADD_CLINIC
} from './types';

export const initialState: ClinicAddState = {
    pending: false,
    clinicAdd: { uniqueid: '', address: '', email: '', hours: '', logo: '', name: '', phone: '', questionResponse: [], speciality: '', website: '' },
    clinicAddResponse: { status: { error: false, msg: '' }, permission: '' },
    checkClinicEmail: { email: '' },
    checkClinicEmailResponse: { status: { error: false, msg: '' } },
    checkClinicName: { name: '' },
    checkClinicNameResponse: { status: { error: false, msg: '' } },
    checkClinicPhone: { phone: '' },
    checkClinicPhoneResponse: { status: { error: false, msg: '' } },
    error: null
};

export const clinicAddReducer = (state = initialState, action: ClinicAddActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case ADD_CLINIC.CHECK_CLINIC_EMAIL_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case ADD_CLINIC.CHECK_CLINIC_EMAIL_PENDING:
            return {
                ...state,
                pending: true,
                checkClinicEmail: action.payload
            }
        case ADD_CLINIC.CHECK_CLINIC_EMAIL_SUCCESS:
            return {
                ...state,
                pending: false,
                checkClinicEmailResponse: action.checkClinicEmail
            }
        case ADD_CLINIC.CHECK_CLINIC_NAME_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case ADD_CLINIC.CHECK_CLINIC_NAME_PENDING:
            return {
                ...state,
                pending: true,
                checkClinicName: action.payload
            }
        case ADD_CLINIC.CHECK_CLINIC_NAME_SUCCESS:
            return {
                ...state,
                pending: false,
                checkClinicNameResponse: action.checkClinicName
            }
        case ADD_CLINIC.ADD_CLINIC_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case ADD_CLINIC.ADD_CLINIC_PENDING:
            return {
                ...state,
                pending: true,
                clinicAdd: action.payload
            }
        case ADD_CLINIC.ADD_CLINIC_SUCCESS:
            return {
                ...state,
                pending: false,
                clinicAddResponse: action.clinicAdd
            }
        case ADD_CLINIC.CHECK_CLINIC_PHONE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case ADD_CLINIC.CHECK_CLINIC_PHONE_PENDING:
            return {
                ...state,
                pending: true,
                checkClinicPhone: action.payload
            }
        case ADD_CLINIC.CHECK_CLINIC_PHONE_SUCCESS:
            return {
                ...state,
                pending: false,
                checkClinicPhoneResponse: action.checkClinicPhone
            }
        default:
            return NewState;

    }
};