import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { removeCountryCode, takeOnlyDigitAndPlus } from '../../../../utils/global';
import { checkPracticeEmail, checkPracticeName, checkPracticePhone } from '../../../services/clinicOnboard';
import { updateClinicProfile } from '../../../services/clinicProfile';
import countryCodeList from '../../../constants/country-calling-codes.json';
import Select from 'react-select';
import CONSTANT_LABELS from '../../../../constants/labels';
export const GeneralInfo = ({ name, address, email, phone, website, clinicId, fetchProfile }: any) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  return (
    <div className="practice-contact-sec mb-4">
      <div className="card">

        <div className="card-header bg-light py-xl-3 py-2 px-4">
          <div className="d-flex justify-content-between">
            <div className="fw-medium">Contact information</div>
          </div>
        </div>


        <ul className="list-group list-group-flush">
          <li className="list-group-item py-xl-3 py-2 px-2 px-xl-3">
            {
              useMemo(() => {
                return (<Location address={address} clinicId={clinicId} fetchProfile={fetchProfile}></Location>)
              }, [address])
            }
          </li>
          <li className="list-group-item py-xl-3 py-2 px-2 px-xl-3">
            {
              useMemo(() => {
                return (<PracticeName name={name} clinicId={clinicId} fetchProfile={fetchProfile}></PracticeName>)
              }, [name])
            }
          </li>
          <li className="list-group-item py-xl-3 py-2  px-2 px-xl-3">
            {
              useMemo(() => {
                return (<PracticePhone phone={CONSTANT_LABELS.PLUS_ONE+removeCountryCode(phone)} clinicId={clinicId} fetchProfile={fetchProfile}></PracticePhone>)
              }, [phone])
            }
          </li>
          <li className="list-group-item py-xl-3 py-2 px-2 px-xl-3">
            {
              useMemo(() => {
                return (
                  <PracticeEmail email={email} clinicId={clinicId} fetchProfile={fetchProfile}></PracticeEmail>
                )
              }, [email])
            }
          </li>
          <li className="list-group-item py-xl-3 py-2  px-2 px-xl-3">
            {
              useMemo(() => {
                return (
                  <PracticeWebsite website={website} clinicId={clinicId} fetchProfile={fetchProfile}></PracticeWebsite>
                )
              }, [website])
            }
          </li>
          <li className="list-group-item py-xl-3 py-2 px-2 px-xl-3">
            {
              useMemo(() => {
                return (
                  <PracticeCountryCode countryCode={"1"}></PracticeCountryCode>
                )
              }, [selectedCountryCode])
            }
          </li>
        </ul>
      </div>
    </div>
  );
};
let googleMap: any;
let maps: any;
let marker: any;
let autocomplete: any;
let googleMapsPromise: any;
const Location = ({ address, fetchProfile, clinicId }: any) => {
  let autcompleteRef: any = useRef();
  const [autoSuggest, setAutoSuggest] = useState('');
  const [componentAddress, setComponentAddress] = useState(null as any);
  const [addressValidation, setAddressValidation] = useState({ invalid: false, msg: '' });
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getGoogleMaps = () => {
    // If we haven't already defined the promise, define it
    if (!googleMapsPromise) {
      googleMapsPromise = new Promise((resolve) => {
        // Add a global handler for when the API finishes loading
        //@ts-ignore
        window.resolveGoogleMapsPromise = () => {
          // Resolve the promise
          //@ts-ignore
          resolve(google);

          // Tidy up
          //@ts-ignore
          delete window.resolveGoogleMapsPromise;
        };

        // Load the Google Maps API
        const script = document.createElement("script");
        const API = 'AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&libraries=places&callback=resolveGoogleMapsPromise`;
        script.async = true;
        document.body.appendChild(script);
      });
    }

    // Return a promise for the Google Maps API
    return googleMapsPromise;
  }
  useEffect(() => {
    getGoogleMaps().then((google: any) => {
      const center = { lat: 43.6205, lng: -79.5132 };
      const map = new google.maps.Map(document.getElementById('map'), {
        zoom: 10,
        center: center
      });
      googleMap = map;
      maps = google;
      attachAutoComplete();
      if (address) {
        //@ts-ignore
        if (window.google) {
          //@ts-ignore
          const latLng = new window.google.maps.LatLng(address.latitude, address.longitude);
          if (marker && marker.setMap) {
            marker.setMap(null);
          }
          //@ts-ignore
          marker = new window.google.maps.Marker({
            position: latLng,
            map: googleMap
          });
          googleMap.setCenter(marker.getPosition());
        }
      }
    });
  }, []);
  const attachAutoComplete = () => {
    try {
      //@ts-ignore
      autocomplete = new window.google.maps.places.Autocomplete(autcompleteRef.current);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place && place.geometry) {
          //@ts-ignore
          const latLng = new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
          if (marker && marker.setMap) {
            marker.setMap(null);
          }
          //@ts-ignore
          marker = new window.google.maps.Marker({
            position: latLng,
            map: googleMap
          });
          googleMap.setCenter(marker.getPosition());
          let componentForm = {
            subpremise: '',
            street_number: '',
            route: '',
            sublocality_level_1: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            latitude: '',
            longitude: '',
            address_level_1_short: '',
            neighborhood: '',
            formatted_address: ''
          };
          for (let start = 0; start < place.address_components.length; start++) {
            const addressType = place.address_components[start].types[0];
            if (addressType === "street_number") {
              componentForm.street_number = place.address_components[start].long_name;
            } else if (addressType === "subpremise") {
              componentForm.subpremise = place.address_components[start].short_name;
            } else if (addressType === "route") {
              componentForm.route = place.address_components[start].long_name;
            } else if (addressType === "sublocality_level_1") {
              componentForm.sublocality_level_1 = place.address_components[start].long_name;
            } else if (addressType === "locality") {
              componentForm.locality = place.address_components[start].long_name;
            } else if (addressType === "administrative_area_level_1") {
              componentForm.administrative_area_level_1 = place.address_components[start].long_name;
              componentForm.address_level_1_short = place.address_components[start].short_name;
            } else if (addressType === "country") {
              componentForm.country = place.address_components[start].long_name;
            } else if (addressType === "postal_code") {
              componentForm.postal_code = place.address_components[start].short_name;
            } else if (addressType === 'neighborhood') {
              componentForm.neighborhood = place.address_components[start].long_name;
            }
            componentForm.latitude = place.geometry.location.lat();
            componentForm.longitude = place.geometry.location.lng();
            componentForm.formatted_address = place.formatted_address.trim();
          }
          setComponentAddress(componentForm);
          setAutoSuggest(place.formatted_address.trim());
          // { autoCompletePlace: componentForm, edtAddress: place.formatted_address.trim() }
        }
      });
    } catch (err) {
    }
  };
  const onCancel = () => {
    setIsEdit(false);
    setAutoSuggest(address.formattedAddress);
    //@ts-ignore
    const latLng = new window.google.maps.LatLng(address.latitude, address.longitude);
    if (marker && marker.setMap) {
      marker.setMap(null);
    }
    //@ts-ignore
    marker = new window.google.maps.Marker({
      position: latLng,
      map: googleMap
    });
    googleMap.setCenter(marker.getPosition());
  };
  const onSave = () => {
    if (autoSuggest === '') {
      setAddressValidation({ invalid: true, msg: 'Address Required' });
      return;
    }
    setAddressValidation({ invalid: false, msg: '' });
    setIsLoading(true);
    updateClinicProfile('location', clinicId, componentAddress).then((success) => {
      if (success && success.status && success.status.error === false) {
        fetchProfile('general').then((success: any) => {
          setIsLoading(false);
          setIsEdit(false);
        }).catch((err: any) => {
          setIsLoading(false); setIsEdit(false);
        });
        return;
      }
    }).catch((err) => {
      setIsLoading(false);
    });
  }
  return (
    <>
      <div className="map-box">
        <div id="map" className="map-sec" style={{ width: "100%", height: "260px" }}></div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="icon-box me-1">
          <i className="bi bi-geo-alt-fill"></i>
        </div>
        <div className="flex-grow-1">{address.formattedAddress}</div>
        <div className="d-flex justify-content-end">
          {
            (isEdit && !isLoading) &&
            <>
              <button className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
              <button className="ms-2 btn btn-link p-0" onClick={(e) => { onSave(); }}><i className="bi bi-check-circle-fill text-success"></i></button>
            </>
          }
          {
            (isEdit && isLoading) &&
            <>
              {/* <button disabled className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
            <button disabled className="ms-2 btn btn-link p-0" onClick={(e) => { onSave(); }}>Saving</button> */}
              <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
            </>
          }
          {
            (!isEdit && !isLoading) &&
            <button className="ms-2 btn btn-link p-0" onClick={(e) => { setIsEdit(true); }}>Edit</button>
          }
        </div>

      </div>

      <div>
        <input type="text" className={"form-control my-2 " + (!isEdit ? 'd-none' : '')} id="autocomplete" placeholder="1234 Main St"
          value={autoSuggest} onChange={(e) => { setAutoSuggest(e.target.value) }} ref={autcompleteRef} />
        {
          addressValidation.invalid &&
          <div className="is-invalid">{addressValidation.msg}</div>
        }
      </div>


    </>
  );
}

const PracticeName = ({ name, fetchProfile, clinicId }: any) => {
  const { register, handleSubmit, errors, setError, setValue, control, getValues, trigger, clearErrors } = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setValue('practiceName', name);
  }, []);
  const onSubmit = async () => {
    const practiceName = getValues('practiceName');
    try {
      const isValid = await trigger('practiceName');
      if (isValid) {
        setIsLoading(true);
        await checkPracticeName(practiceName);
        updateClinicProfile('name', clinicId, practiceName).then((success) => {
          if (success && success.status && success.status.error === false) {
            fetchProfile('general').then((resp: any) => {
              setIsLoading(false);
              setIsEdit(false);
            }).catch((err: any) => {
              setIsLoading(false);
            });
          }
        }).catch((err) => {
          setIsLoading(false);
        });
        clearErrors('practiceName');
      }
    } catch (err: any) {
      if (err && err.error) {
        const practiceName = getValues('practiceName');
        setError('practiceName', { type: "manual", message: err.msg });
        setValue('practiceName', practiceName);
        setIsLoading(false);
        return;
      }
    }
  }
  const onCancel = () => {
    setIsEdit(false);
    setValue('practiceName', name);
  }
  return (
    <form onSubmit={(e) => { e.preventDefault() }}>

      <div className="d-flex justify-content-between align-items-center">
        <div className="icon-box me-1">
          <i className="bi bi-shop"></i>
        </div>
        <div className="flex-grow-1">{name}</div>
        <div className="d-flex justify-content-end">
          {
            (isEdit && !isLoading) &&
            <>
              <button className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
              <button className="ms-2 btn btn-link p-0" onClick={handleSubmit(onSubmit)}><i className="bi bi-check-circle-fill text-success"></i></button>
            </>
          }
          {
            (isEdit && isLoading) &&
            <>
              {/* <button disabled className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}>Cancel</button>
            <button disabled className="ms-2 btn btn-link p-0">Saving</button> */}
              <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
            </>
          }
          {
            (!isEdit && !isLoading) &&
            <button className="ms-2 btn btn-link p-0" onClick={(e) => { setIsEdit(true); }}>Edit</button>
          }
        </div>

      </div>

      <>
        <input type="text" className={"form-control my-2 " + (!isEdit ? 'd-none' : '')} id="practicetName" name="practiceName" placeholder="xyz clinic"
          ref={register({
            required: true,
            pattern: /^[ A-Za-z0-9_@./#&+-]*$/
          })} />
        {
          errors.practiceName && errors.practiceName.type === "required" &&
          <div className="is-invalid">Required</div>
        }
        {
          errors.practiceName && errors.practiceName.type === "pattern" &&
          <div className="is-invalid">Allow only A-Za-z0-9_@./#&+-</div>
        }
        {
          errors.practiceName && errors.practiceName.type === "manual" &&
          <div className="is-invalid">{errors.practiceName.message}</div>
        }
      </>


    </form>
  )
};

const PracticePhone = ({ phone, fetchProfile, clinicId }: any) => {
  const { handleSubmit, errors, setError, setValue, control, getValues, trigger, clearErrors } = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setValue('practicePhone', phone);
  }, []);
  const onSubmit = async () => {
    const practicePhone = getValues('practicePhone');
    try {
      const isValid = !(practicePhone.length < 10);
      if (isValid) {
        setIsLoading(true);
        await checkPracticePhone(practicePhone);
        updateClinicProfile('phone', clinicId, practicePhone).then((success) => {
          if (success && success.status && success.status.error === false) {
            fetchProfile('general').then((resp: any) => {
              setIsLoading(false);
              setIsEdit(false);
            }).catch((err: any) => {
              setIsLoading(false);
            });
          }
        }).catch((err) => {
          setIsLoading(false);
        });
        clearErrors('practicePhone');
      }
    } catch (err: any) {
      if (err && err.error) {
        const practicePhone = getValues('practicePhone');
        setError('practicePhone', { type: "manual", message: err.msg });
        setValue('practicePhone',  practicePhone);
        setIsLoading(false);
        return;
      }
    }
  }
  const onCancel = () => {
    setIsEdit(false);
    setValue('practicePhone', phone);
  }
  return (

    <form onSubmit={(e) => { e.preventDefault() }}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="icon-box me-1">
          <i className="bi bi-telephone-fill"></i>
        </div>
        <div className="flex-grow-1">{phone}</div>
        <div className="d-flex justify-content-end">
          {
            (isEdit && !isLoading) &&
            <>
              <button className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
              <button className="ms-2 btn btn-link p-0" onClick={handleSubmit(onSubmit)}><i className="bi bi-check-circle-fill text-success"></i></button>
            </>
          }
          {
            (isEdit && isLoading) &&
            <>
              {/* <button disabled className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}>Cancel</button>
            <button disabled className="ms-2 btn btn-link p-0">Saving</button> */}
              <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
            </>
          }
          {
            (!isEdit && !isLoading) &&
            <button className="ms-2 btn btn-link p-0" onClick={(e) => { setIsEdit(true); }}>Edit</button>
          }
        </div>

      </div>
      <>
        <Controller
          as={ReactInputMask}
          control={control}
          mask="9999999999"
          name="practicePhone"
          className={"form-control my-2 " + (!isEdit ? 'd-none' : '')}
          rules={{ required: true, minLength: { value: 10, message: 'Mobile Number is invalid' } }}
        />
        {
          errors.practicePhone && errors.practicePhone.type === "required" &&
          <div className="is-invalid">Required</div>
        }
        {
          errors.practicePhone && errors.practicePhone.type === "minLength" &&
          <div className="is-invalid">Mobile Number is invalid</div>
        }
        {
          errors.practicePhone && errors.practicePhone.type === "manual" &&
          <div className="is-invalid">{errors.practicePhone.message}</div>
        }
      </>

    </form>
  )
}

const PracticeEmail = ({ email, fetchProfile, clinicId }: any) => {
  const { register, handleSubmit, errors, setError, setValue, control, getValues, trigger, clearErrors } = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setValue('email', email);
  }, []);
  const onSubmit = async () => {
    const email = getValues('email');
    try {
      const isValid = await trigger('email');
      if (isValid) {
        setIsLoading(true);
        await checkPracticeEmail(email);
        updateClinicProfile('email', clinicId, email).then((success) => {
          if (success && success.status && success.status.error === false) {
            fetchProfile('general').then((resp: any) => {
              setIsLoading(false);
              setIsEdit(false);
            }).catch((err: any) => {
              setIsLoading(false);
            });
          }
        }).catch((err) => {
          setIsLoading(false);
        });
        clearErrors('email');
      }
    } catch (err: any) {
      if (err && err.error) {
        const email = getValues('email');
        setError('email', { type: "manual", message: err.msg });
        setValue('email', email);
        setIsLoading(false);
        return;
      }
    }
  }
  const onCancel = () => {
    setIsEdit(false);
    setValue('email', email);
  }
  return (

    <form onSubmit={(e) => { e.preventDefault() }}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="icon-box me-1">
          <i className="bi bi-envelope-fill"></i>
        </div>
        <div className="flex-grow-1 charlmt">{email}
          {/* <div><span className="badge rounded-pill bg-danger">UNVERIFIED</span> <button className="btn text-danger p-0" type="button">Resend</button></div> */}
        </div>
        <div className="d-flex justify-content-end">
          {
            (isEdit && !isLoading) &&
            <>
              <button className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
              <button className="ms-2 btn btn-link p-0" onClick={handleSubmit(onSubmit)}><i className="bi bi-check-circle-fill text-success"></i></button>
            </>
          }
          {
            (isEdit && isLoading) &&
            <>
              {/* <button disabled className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}>Cancel</button>
            <button disabled className="ms-2 btn btn-link p-0">Saving</button> */}
              <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
            </>
          }
          {
            (!isEdit && !isLoading) &&
            <button className="ms-2 btn btn-link p-0" onClick={(e) => { setIsEdit(true); }}>Edit</button>
          }
        </div>

      </div>

      <>
        <input type="email" className={"form-control my-2 " + (!isEdit ? 'd-none' : '')} id="emailAddress" name="email" placeholder="xyz@clinic.com" ref={register({
          required: true,
          pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        })} />
        {
          errors.email && errors.email.type === "required" &&
          <div className="is-invalid">Required</div>
        }
        {
          errors.email && errors.email.type === "pattern" &&
          <div className="is-invalid">Email is invalid</div>
        }
        {
          errors.email && errors.email.type === "manual" &&
          <div className="is-invalid">{errors.email.message}</div>
        }
      </>

    </form>
  )
}

const PracticeWebsite = ({ website, fetchProfile, clinicId }: any) => {
  const { register, handleSubmit, errors, setError, setValue, control, getValues, trigger, clearErrors } = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setValue('website', website);
  }, []);
  const onSubmit = async () => {
    const website = getValues('website');
    try {
      const isValid = await trigger('website');
      if (isValid) {
        setIsLoading(true);
        updateClinicProfile('website', clinicId, website).then((success) => {
          if (success && success.status && success.status.error === false) {
            fetchProfile('general').then((resp: any) => {
              setIsLoading(false);
              setIsEdit(false);
            }).catch((err: any) => {
              setIsLoading(false);
            });
          }
        }).catch((err) => {
          setIsLoading(false);
        });
        clearErrors('website');
      }
    } catch (err: any) {
      if (err && err.error) {
        const website = getValues('website');
        setError('website', { type: "manual", message: err.msg });
        setValue('website', website);
        setIsLoading(false);
        return;
      }
    }
  }
  const onCancel = () => {
    setIsEdit(false);
    setValue('website', website);
  }
  return (
    <div>
      <form onSubmit={(e) => { e.preventDefault() }}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="icon-box me-1">
            <i className="bi bi-globe2"></i>
          </div>
          <div className="flex-grow-1">{website}</div>
          <div className="d-flex justify-content-end">
            {
              (isEdit && !isLoading) &&
              <>
                <button className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                <button className="ms-2 btn btn-link p-0" onClick={handleSubmit(onSubmit)}><i className="bi bi-check-circle-fill text-success"></i></button>
              </>
            }
            {
              (isEdit && isLoading) &&
              <>
                {/* <button disabled className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}>Cancel</button>
              <button disabled className="ms-2 btn btn-link p-0">Saving</button> */}
                <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
              </>
            }
            {
              (!isEdit && !isLoading) &&
              <button className="ms-2 btn btn-link p-0" onClick={(e) => { setIsEdit(true); }}>Edit</button>
            }
          </div>

        </div>

        <>
          <input type="text" className={"form-control my-2 " + (!isEdit ? 'd-none' : '')} id="website" name="website" placeholder="www.xyz.com"
            ref={register({
              required: true,
              pattern: /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
            })} />
          {
            errors.website && errors.website.type === "required" &&
            <div className="is-invalid">Required</div>
          }
          {
            errors.website && errors.website.type === "pattern" &&
            <div className="is-invalid">Website is invalid</div>
          }
        </>


      </form>
    </div>
  )
}

const PracticeCountryCode = ({ countryCode }: any) => {
  const { register, handleSubmit, errors, setError, setValue, control, getValues, trigger, clearErrors } = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCode, setSelectedCode] = useState('' as any);
  const [countryCodes, setCountryCodes] = useState([]);
  useEffect(() => {
    setSelectedCode(countryCode);
  }, [countryCode]);
  useEffect(() => {
    if (countryCodeList && countryCodeList.length > 0) {
      let codes: any = countryCodeList.map((item: any) => {
        return {
          value: item.callingCodes[0],
          label: item.name
        }
      });
      setCountryCodes(codes);
    }
  }, [countryCodeList]);
  const onCancel = () => {
    setIsEdit(false);
    setValue('practiceCountryCode', selectedCode.value);
  }

  const onSubmit = () => {
    setIsEdit(false);
  }

  return (
    <form onSubmit={(e) => { e.preventDefault() }}>

      <div className="d-flex justify-content-between align-items-center">
        <div className="icon-box me-1">
          <i className="bi bi-shop"></i>
        </div>
        <div className="flex-grow-1">{(selectedCode.value && selectedCode.value !== "") ? selectedCode.value : '+1 United States/Canada'}</div>
        <div className="d-flex justify-content-end d-none">
          {
            (isEdit && !isLoading) &&
            <>
              <button className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
              <button className="ms-2 btn btn-link p-0" onClick={handleSubmit(onSubmit)}><i className="bi bi-check-circle-fill text-success"></i></button>
            </>
          }
          {
            (isEdit && isLoading) &&
            <>
              <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
            </>
          }
          {
            (!isEdit && !isLoading) &&
            <button className="ms-2 btn btn-link p-0" onClick={(e) => { setIsEdit(true); }}>Edit</button>
          }
        </div>

      </div>
      { (isEdit) &&
        <>
          <Select className="select2 myslt" options={countryCodes}
            value={selectedCode}
            onChange={(e: any) => { setSelectedCode(e) }}
            ref={register({
              required: true
            })}
          ></Select>
          {
            errors.practiceName && errors.practiceName.type === "required" &&
            <div className="is-invalid">Required</div>
          }
          {
            errors.practiceName && errors.practiceName.type === "pattern" &&
            <div className="is-invalid">Allow only A-Za-z0-9_@./#&+-</div>
          }
          {
            errors.practiceName && errors.practiceName.type === "manual" &&
            <div className="is-invalid">{errors.practiceName.message}</div>
          }
        </>
      }



    </form>
  )
}