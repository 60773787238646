import React from 'react';
import { Route, Switch } from 'react-router';
import { PaymentHeader } from '../../components/messageCenter/PaymentHeader';
import { RecurringPaymentNotification } from './RecurringPaymentNotification';
import { SinglePaymentNotification } from './SinglePaymentNotification';
import { AdministrativePatients } from './AdministrativePatients';
import { PaymentPatients } from './PaymentPatients';
export const PaymentNotification = () => {
    return (
        <>
           <RecurringPaymentNotification/>
           <SinglePaymentNotification/>
        </>
    );
}