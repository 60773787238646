import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import { editAppointment } from '../../services/appointment';
export const AppointmentEditModal = ({ hideModal, appointment, fetchAppointment }: any) => {
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [doctorList, setDoctorList] = useState([]);
    const [selectedAppointmentType, setSelectedAppointmentType] = useState('');
    const [appointmentTypesList, setAppointmentTypesList] = useState([]);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (userData && userData.clinics && userData.clinics[0] && appointment) {
            const provider = userData.clinics[0].provider.filter((item: any) => item.onBoarding.status === 1 && item.enableAppointment === "1");
            setDoctorList(provider);
            const selectedProvider = provider.find((item: any) => {
                return appointment.doctor.uniqueid === item.uniqueid;
            });
            /* When provider is available as active. */
            if (selectedProvider) {
                setSelectedDoctor(appointment.doctor.id);
                setAppointmentTypesList(selectedProvider.potentialAppointmentTypes);
                setSelectedAppointmentType(appointment.appointmentTypeId);
            } else {
                /* When provider is not available, default select first. */
                setSelectedDoctor(provider[0].id);
                setAppointmentTypesList(provider[0].potentialAppointmentTypes);
                setSelectedAppointmentType(provider[0].potentialAppointmentTypes[0].id);
            }
        }
    }, [appointment, userData]);
    const setAppointmentType = (id: string, appointmentTypeId: string = '') => {
        const provider: any = doctorList.find((value: any) => {
            return value.id === id;
        });
        if (provider && provider.potentialAppointmentTypes && provider.potentialAppointmentTypes[0]) {
            setAppointmentTypesList(provider.potentialAppointmentTypes);
            setSelectedAppointmentType(appointmentTypeId !== '' ? appointmentTypeId : provider.potentialAppointmentTypes[0].id);
        }
    };
    const editAppointmentClick = () => {
        setIsLoading(true);
        let data = {
            appointmenttypeid: selectedAppointmentType,
            doctorid: selectedDoctor
        };
        editAppointment(data, appointment.clinic.uniqueid, appointment.uniqueid).then((success) => {
            if (success && success.status && success.status.error === false) {
                setIsLoading(false);
                hideModal();
                fetchAppointment(true);
                return;
            }
            setIsLoading(false);
        }).catch((err) => {
            toast.error(err.msg);
            setIsLoading(false);
        });
    }
    return (
        <Modal show={true} onHide={() => { hideModal() }} className="newcommon editappt-popup" centered>

            <Modal.Header>
                <div className="modal-title">Edit appointment with {appointment.clinic.name}</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => { hideModal() }} >
                </button>
            </Modal.Header>

            <Modal.Body>

                <p className="mb-3">Change provider and appointment type</p>

                <div className="row g-3">
                    <div className="col-12">
                        <label className="form-label">Select provider</label>
                        <select className="form-select" value={selectedDoctor} onChange={(e: any) => {
                            setSelectedDoctor(e.target.value);
                            setAppointmentType(e.target.value);
                        }}>
                            {
                                doctorList.map((value: any, index: number) => {
                                    return (
                                        <option value={value.id} key={value.id + "provider"}>{value.fullname}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="com-12">
                        <label className="form-label">Change appointment type</label>
                        <select className="form-select" value={selectedAppointmentType} onChange={(e) => {
                            setSelectedAppointmentType(e.target.value);
                        }}>
                            {
                                appointmentTypesList.map((value: any, index: number) => {
                                    return (
                                        <option value={value.id} key={value.id + "appointmentType"}>{value.name} - {value.speciality}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

            </Modal.Body>

            <Modal.Footer>

                <button className="btn btn-outline-primary" type="button" onClick={() => { hideModal() }} disabled={isLoading}>Cancel</button>

                <button className="btn btn-primary" onClick={(e: any) => {
                    editAppointmentClick();
                }} disabled={selectedDoctor === '' || selectedAppointmentType === '' || isLoading}>
                    {
                        isLoading &&
                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    }
                        Save
                    </button>

            </Modal.Footer>
        </Modal>
    )
}