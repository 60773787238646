export enum ADD_CLINIC {
    ADD_CLINIC_PENDING = 'ADD_PENDING_CLINIC',
    ADD_CLINIC_ERROR = 'ADD_FAILED_CLINIC',
    ADD_CLINIC_SUCCESS = 'ADD_SUCCESS_CLINIC',
    CHECK_CLINIC_EMAIL_PENDING = 'CHECK_CLINIC_EMAIL_PENDING',
    CHECK_CLINIC_EMAIL_ERROR = 'CHECK_CLINIC_EMAIL_ERROR',
    CHECK_CLINIC_EMAIL_SUCCESS = 'CHECK_CLINIC_EMAIL_SUCCESS',
    CHECK_CLINIC_NAME_PENDING = 'CHECK_CLINIC_NAME_PENDING',
    CHECK_CLINIC_NAME_ERROR = 'CHECK_CLINIC_NAME_ERROR',
    CHECK_CLINIC_NAME_SUCCESS = 'CHECK_CLINIC_NAME_SUCCESS',
    CHECK_CLINIC_PHONE_ERROR = 'CHECK_CLINIC_PHONE_ERROR',
    CHECK_CLINIC_PHONE_SUCCESS = 'CHECK_CLINIC_PHONE_SUCCESS',
    CHECK_CLINIC_PHONE_PENDING = 'CHECK_CLINIC_PHONE_PENDING',
};

export interface ClinicAddRequest {
    uniqueid: string;
    name: string;
    logo: any;
    speciality: string;
    phone: string;
    email: string;
    website: string;
    hours: any;
    questionResponse: any[];
    address: any;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface CheckClinicEmailRequest {
    email: string;
}
export interface CheckClinicEmailResponse {
    status: Status;
}
export interface ClinicAddResponse {
    status: Status;
    permission: any;
}
export interface CheckClinicNameRequest {
    name: string;
}
export interface CheckClinicNameResponse {
    status: Status;
}
export interface CheckClinicPhoneRequest {
    phone: string;
}
export interface CheckClinicPhoneResponse {
    status: Status;
}
export interface ClinicAddState {
    pending: boolean;
    clinicAdd: ClinicAddRequest,
    clinicAddResponse: ClinicAddResponse,
    checkClinicEmail: CheckClinicEmailRequest,
    checkClinicEmailResponse: CheckClinicEmailResponse,
    checkClinicName: CheckClinicNameRequest,
    checkClinicNameResponse: CheckClinicNameResponse,
    checkClinicPhone: CheckClinicPhoneRequest,
    checkClinicPhoneResponse: CheckClinicPhoneResponse,
    error: any;
}

export interface ClinicAddPendingAction {
    type: ADD_CLINIC.ADD_CLINIC_PENDING;
    payload: ClinicAddRequest
}

export interface ClinicAddErrorAction {
    type: ADD_CLINIC.ADD_CLINIC_ERROR;
    error: any;
}
export interface ClinicAddSuccessAction {
    type: ADD_CLINIC.ADD_CLINIC_SUCCESS;
    clinicAdd: ClinicAddResponse
}
export interface CheckClinicEmailPendingAction {
    type: ADD_CLINIC.CHECK_CLINIC_EMAIL_PENDING,
    payload: CheckClinicEmailRequest
}
export interface CheckClinicEmailErrorAction {
    type: ADD_CLINIC.CHECK_CLINIC_EMAIL_ERROR,
    error: any
}
export interface CheckClinicEmailSuccessAction {
    type: ADD_CLINIC.CHECK_CLINIC_EMAIL_SUCCESS,
    checkClinicEmail: CheckClinicEmailResponse
}
export interface CheckClinicNamePendingAction {
    type: ADD_CLINIC.CHECK_CLINIC_NAME_PENDING,
    payload: CheckClinicNameRequest
}
export interface CheckClinicNameErrorAction {
    type: ADD_CLINIC.CHECK_CLINIC_NAME_ERROR,
    error: any
}
export interface CheckClinicNameSuccessAction {
    type: ADD_CLINIC.CHECK_CLINIC_NAME_SUCCESS,
    checkClinicName: CheckClinicNameResponse
}
export interface CheckClinicPhonePendingAction {
    type: ADD_CLINIC.CHECK_CLINIC_PHONE_PENDING,
    payload: CheckClinicPhoneRequest
}
export interface CheckClinicPhoneErrorAction {
    type: ADD_CLINIC.CHECK_CLINIC_PHONE_ERROR,
    error: any
}
export interface CheckClinicPhoneSuccessAction {
    type: ADD_CLINIC.CHECK_CLINIC_PHONE_SUCCESS,
    checkClinicPhone: CheckClinicPhoneResponse
}
export type ClinicAddActionTypes = ClinicAddErrorAction | ClinicAddPendingAction | ClinicAddSuccessAction |
    CheckClinicEmailErrorAction | CheckClinicEmailPendingAction | CheckClinicEmailSuccessAction |
    CheckClinicNameErrorAction | CheckClinicNamePendingAction | CheckClinicNameSuccessAction |
    CheckClinicPhonePendingAction | CheckClinicPhoneErrorAction | CheckClinicPhoneSuccessAction;