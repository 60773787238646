import React, { useEffect, useRef, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { getPatientList, updateSendSMSStatus } from '../../services/patientsearch';
import { IAppState } from '../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import configs from '../../configs/apiConfigs';
import { Device } from '@twilio/voice-sdk';
import { createSession, createSubscription, getLines, initiateCall, mangoVoiceCall } from '../../services/voicecall';
import firebaseInstance from "../../helpers/firebase";
import FaxIcon from "../../assets/images/fax.svg";
import { sendFax, uploadPDF } from '../../services/messageCenter';
import { toast } from 'react-toastify';
import { w3cwebsocket as W3CWebSocket } from "websocket";

const db = firebaseInstance.db;
let unsubscribe: any;
let client: any;
let lastStatus = "";
let lastType = "";
export const DialModal = ({ hideModal }: any) => {
    const [mobile, setMobile]: any = useState('');
    const [searchName, setSearchName]: any = useState('');
    const [patientList, setPatientList] = useState([]);
    const [isNoRecord, setIsNoRecord] = useState(false);
    const [device, setDevice]: any = useState(null);
    const [status, setStatus] = useState("");
    const [callObj, setCallObj]: any = useState(null);
    const [faxStatus, setFaxStatus] = useState(false);
    const [documentPath, setDocumentPath] = useState("");
    const [clickedPatient, setClickedPatient]: any = useState(null);
    const mobileRef = useRef("");
    const faxStatusRef = useRef(false);

    const setMyMobile = (data: any) => {
        setMobile(data);
        mobileRef.current = data;
    }

    const setMyFaxStatus = (data: any) => {
        setFaxStatus(data);
        faxStatusRef.current = data;
    }
    /* Mango */
    const [direction, setDirection] = useState("");
    const [sourceNumber, setSourceNumber] = useState("");

    const dispatch = useDispatch();
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const clinic = userData.clinics[0];
    useEffect(() => {
        document.addEventListener("keydown", registerListerKeyboard);
        getAllPatientList();
        return () => {
            if (unsubscribe) unsubscribe();
            if (client && client.close) {
                client.close()
            }
        }
    }, []);
    const registerListerKeyboard = (event: any) => {
        console.log(event.keyCode, event.key);
        if (event.shiftKey && ((event.keyCode === 187) || (event.keyCode === 107))) {
            handleNumber("+")
        } else if (event.shiftKey && ((event.keyCode === 56) || (event.keyCode === 106))) {
            handleNumber("*")
        } else if (event.shiftKey && ((event.keyCode === 51) || (event.keyCode === 99))) {
            handleNumber("#")
        } else if ((event.keyCode === 48) || (event.keyCode === 96)) {
            handleNumber("0")
        } else if ((event.keyCode === 49) || (event.keyCode === 97)) {
            handleNumber("1")
        } else if ((event.keyCode === 50) || (event.keyCode === 98)) {
            handleNumber("2")
        } else if ((event.keyCode === 51) || (event.keyCode === 99)) {
            handleNumber("3")
        } else if ((event.keyCode === 52) || (event.keyCode === 100)) {
            handleNumber("4")
        } else if ((event.keyCode === 53) || (event.keyCode === 101)) {
            handleNumber("5")
        } else if ((event.keyCode === 54) || (event.keyCode === 102)) {
            handleNumber("6")
        } else if ((event.keyCode === 55) || (event.keyCode === 103)) {
            handleNumber("7")
        } else if ((event.keyCode === 56) || (event.keyCode === 104)) {
            handleNumber("8")
        } else if ((event.keyCode === 57) || (event.keyCode === 105)) {
            handleNumber("9")
        } else if (event.keyCode === 8) {
            handleBackSpace();
        } else if (event.keyCode === 13) {
            if (faxStatusRef.current) {
                handleFaxDial()
            } else {
                handleDial(mobileRef.current);
            }
        }
    }
    const getAllPatientList = () => {
        const request: any = { fullname: searchName };
        dispatch(fetchLoadingPending());
        getPatientList(request).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setPatientList(success.patients);
                if (success.patients.length === 0) {
                    setIsNoRecord(true);
                }
                return;
            }
            return;
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            setPatientList([]);
        })
    }

    const handleNumber = (digit: any) => {
        console.log(mobileRef.current, digit)
        setMyMobile(mobileRef.current.concat(digit));
    }

    const handleBackSpace = () => {
        console.log(mobileRef.current.slice(0, -1));
        setMyMobile(mobileRef.current.slice(0, -1));
    }

    const handleDial = (mobile: any) => {
        if (!(mobileRef.current && mobileRef.current.length > 0)) return;
        if (clinic.clinicSetting.communicationprovider === "mango") {
            mangoVoiceCall(clinic.clinicSetting.mangoToken, mobile, clinic.clinicSetting.mangoCallUser);
            listenerForMango()
            setStatus("Calling");
        } else if (clinic.clinicSetting.communicationprovider === "twilio") {
            if (unsubscribe) unsubscribe();
            axios
                .post(configs.COMMUNICATION_SERVER + "token/generate", {
                    clientId: userData.clinics[0].uniqueId,
                })
                .then((success) => {
                    if (success && success.data) {
                        const device: Device = new Device(success.data.token);
                        console.log(device);
                        setDevice(device);
                        setTimeout(() => {
                            beginCall(device, mobileRef.current);
                        }, 1000);
                        device.on("error", (error) => {
                            console.log(error);
                            setStatus("Connection failed");
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (clinic.clinicSetting.communicationprovider === "goto") {
            initiateCall(clinic.clinicSetting.gotoaccess_token, mobileRef.current, clinic.clinicSetting.gotoLine, clinic.clinicSetting.gotoOrg);
            listenToGotoEvents();
            setStatus("Calling");
        }
    }
    const beginCall = async (device: any, mobile: any) => {
        let cell = mobile;
        if (!cell.startsWith('+1')) {
            cell = "+1" + cell;
        }
        let params: any = {};
        console.log('called to', cell);
        params = {
            phoneNumber: cell,
            callerId: userData?.clinics[0].twilioCell,
        };
        console.log('---Params---', params);
        try {
            const response = await device.connect({
                params: params,
            });
            setCallObj(response);
            response.on("ringing", () => {
                setStatus("Ringing...");
            });
            response.on("accept", () => {
                console.log("--Accepted---");
                setStatus("Connected");
            });
            response.on("cancel", () => {
                console.log("--Cancelled---");
                setStatus("Call cancelled");
                callHangup();
            });
            response.on("disconnect", () => {
                console.log("--Disconnected---");
                setStatus("Call disconnected");
                callHangup();
            });
            response.on("reject", () => {
                console.log("--Rejected---");
                setStatus("Call rejected");
                callHangup();
            });
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    };
    const callHangup = () => {
        let deviceObj: Device = device;
        callObj?.disconnect();
        deviceObj?.disconnectAll();
        setTimeout(() => {
            hideModal();
            document.getElementById("position-rel")?.classList.remove("call-start");
        }, 1000);
    };
    const listenerForMango = () => {
        unsubscribe = db.collection(configs.MANGO_WEBHOOK_COLLECTION).where("x-mango-remote-id", "==", clinic?.clinicSetting["remote-id"])
            .orderBy("createdAt", "desc").onSnapshot((querySnapshot: any) => {
                querySnapshot.docChanges().forEach((change: any) => {
                    if (change.type === "added") {
                        const data = change.doc.data();
                        if (data.payload.source_number_e164 === clinic.clinicSetting.mangoCell ||
                            data.payload.destination_number_e164 === clinic.clinicSetting.mangoCell) {
                            console.log(data, clinic);
                            if (!data.processed && (data.type === "line_extension.ringing" ||
                                data.type === "line_extension.answered" ||
                                data.type === "line_extension.hangup")) {
                                const docRef = db.collection(configs.MANGO_WEBHOOK_COLLECTION).doc(change.doc.id);
                                docRef.update({
                                    processed: true
                                });
                                if (data.payload.direction === "inbound") {
                                    setSourceNumber(data.payload.source_number_e164);
                                } else {
                                    setSourceNumber(data.payload.destination_number_e164);
                                }
                                setDirection(data.payload.direction);
                                setStatus(data.payload.state);
                                if (data.payload.state === "hangup") {
                                    setDirection(""); setStatus(""); setSourceNumber("");
                                }
                            }
                        }
                    }
                })
            });
    }
    const handleSendFax = () => {
        setMyFaxStatus(!faxStatus);
    }
    const handleFileUpload = (e: any) => {
        console.log(e.target.files, e.target.files[0]);
        if (e.target.files[0] && e.target.files[0].type === "application/pdf") {
            console.log(e.target.files, e.target.files[0]);
            uploadPDF({
                upload: e.target.files[0]
            }).then((success) => {
                if (success && success.error === false) {
                    setDocumentPath(success.data);
                }
            })
        }
    }
    const handleFaxDial = async () => {
        if (!(mobileRef.current && mobileRef.current.length > 0)) return;
        if (clinic && clinic.clinicSetting.communicationprovider === "mango") {
            try {
                const response = await sendFax({
                    tomail: mobileRef.current + "@faxmango.com",
                    clinicid: clinic.id,
                    subject: "",
                    user_id: userData.id,
                    attachments: [documentPath]
                });
                if (response && response.error === false) {
                    toast.success("Fax initiated successfully.");
                } else {
                    toast.error("Fax initiation failed.");
                }
            } catch (err) {
                toast.error("Fax initiation failed.");
            }
        } else if (clinic && clinic.clinicSetting.communicationprovider === "goto") {
            try {
                const response = await sendFax({
                    tomail: mobileRef.current + `@${clinic.clinicSetting.gotoOrg}.fax.onjive.com`,
                    clinicid: clinic.id,
                    subject: clinic.clinicSetting.gotoFaxAccessCode,
                    user_id: userData.id,
                    attachments: [documentPath]
                });
                if (response && response.error === false) {
                    toast.success("Fax initiated successfully.");
                } else {
                    toast.error("Fax initiation failed.");
                }
            } catch (err) {
                toast.error("Fax initiation failed.");
            }
        }
    }
    const listenToGotoEvents = async () => {

        const response = await createSession(clinic.clinicSetting.gotoaccess_token);
        console.log("response", response);
        const line = await getLines(clinic.clinicSetting.gotoaccess_token, clinic.clinicSetting.gotoLine, clinic.clinicSetting.gotoOrg);
        const subscription = await createSubscription(clinic.clinicSetting.gotoaccess_token, [{
            id: "xcarepro" + clinic.id,
            type: "dialog",
            entity: {
                "id": line.id,
                "type": "line.v2",
                "account": line.organization.id
            }
        }], response.data.subscriptions);
        console.log("subscription", subscription);
        if (response.data.ws) {
            client = new W3CWebSocket(response.data.ws);
            client.onopen = () => {
                console.log('WebSocket Client Connected');
            };
            client.onmessage = (message: any) => {
                console.log(message);
                const data = JSON.parse(message.data);
                /* Outgoing */
                if (data.type === "announce" && data.data.state === "CREATED" && data.subId === "xcarepro" + clinic.id && data.data.direction === "initiator") {
                    lastStatus = data.data.state;
                    lastType = data.type;
                }
                if (data.type === "withdraw" && data.data.state === "HUNGUP" && data.subId === "xcarepro" + clinic.id && data.data.direction === "initiator") {
                    if (lastType === "replace" && lastStatus === "UNBRIDGED") {
                        lastStatus = "";
                        lastType = "";
                        setDirection(""); setStatus(""); setSourceNumber("");
                    }
                    if (lastType === "announce" && lastStatus === "CREATED") {
                        setDirection(""); setStatus(""); setSourceNumber("");
                        lastStatus = "";
                        lastType = "";
                    }
                    if (lastType === "withdraw" && lastStatus === "HUNGUP") {
                        setDirection(""); setStatus(""); setSourceNumber("");
                        lastStatus = "";
                        lastType = "";
                    }
                }
                if (data.type === "replace" && data.data.state === "ANSWERED" && data.subId === "xcarepro" + clinic.id && data.data.direction === "initiator") {
                    lastStatus = data.data.state;
                    lastType = data.type;
                    setStatus("Ringing");
                    // const obj:any = state.voiceCall.patient;
                    // console.log(obj);
                    // setPatientList([{...obj, dependant: obj}]);
                }
                if (data.type === "replace" && data.data.state === "BRIDGED" && data.subId === "xcarepro" + clinic.id && data.data.direction === "initiator") {
                    setStatus("Answered");
                    // const obj:any = state.voiceCall.patient;
                    // setPatientList([{...obj, dependant: obj}]);
                }
                if (data.type === "announce" && data.data.state === "ANSWERED" && data.subId === "xcarepro" + clinic.id && data.data.direction === "initiator") {
                    setStatus("Answered");
                    // const obj:any = state.voiceCall.patient;
                    // setPatientList([{...obj, dependant: obj}]);
                }
                if (data.type === "announce" && data.data.state === "RINGING" && data.subId === "xcarepro" + clinic.id && data.data.direction === "initiator") {
                    setStatus("Calling");
                    // const obj:any = state.voiceCall.patient;
                    // setPatientList([{...obj, dependant: obj}]);
                }
                if (data.type === "replace" && data.data.state === "UNBRIDGED" && data.subId === "xcarepro" + clinic.id && data.data.direction === "initiator") {
                    lastStatus = data.data.state;
                    lastType = data.type;
                    setStatus("Disconnecting");
                    // const obj:any = state.voiceCall.patient;
                    // setPatientList([{...obj, dependant: obj}]);
                }
                if (data.type === "withdraw" && data.data.state === "HUNGUP" && data.subId === "xcarepro" + clinic.id && data.data.direction === "initiator") {
                    lastStatus = data.data.state;
                    lastType = data.type;
                    // const obj:any = state.voiceCall.patient;
                    // setPatientList([{...obj, dependant: obj}]);
                }

                /* Incoming */
                if (data.type === "announce" && data.data.state === "CREATED" && data.subId === "xcarepro" + clinic.id && data.data.direction === "recipient") {
                    lastStatus = data.data.state;
                    lastType = data.type;
                }
                if (data.type === "withdraw" && data.data.state === "HUNGUP" && data.subId === "xcarepro" + clinic.id && data.data.direction === "recipient") {
                    if (lastType === "replace" && lastStatus === "UNBRIDGED") {
                        setDirection(""); setStatus(""); setSourceNumber("");
                        lastStatus = "";
                        lastType = "";
                    }
                    if (lastType === "announce" && lastStatus === "CREATED") {
                        setDirection(""); setStatus(""); setSourceNumber("");
                        lastStatus = "";
                        lastType = "";
                    }
                    if (lastType === "withdraw" && lastStatus === "HUNGUP") {
                        setDirection(""); setStatus(""); setSourceNumber("");
                        lastStatus = "";
                        lastType = "";
                    }
                }
                if (data.type === "replace" && data.data.state === "ANSWERED" && data.subId === "xcarepro" + clinic.id && data.data.direction === "recipient") {
                    lastStatus = data.data.state;
                    lastType = data.type;
                    setStatus("Answered");
                }
                if (data.type === "replace" && data.data.state === "BRIDGED" && data.subId === "xcarepro" + clinic.id && data.data.direction === "recipient") {
                    setStatus("Answered");
                }
                if (data.type === "announce" && data.data.state === "ANSWERED" && data.subId === "xcarepro" + clinic.id && data.data.direction === "recipient") {
                    setStatus("Answered");
                }
                if (data.type === "replace" && data.data.state === "RINGING" && data.subId === "xcarepro" + clinic.id && data.data.direction === "recipient") {
                    // getPatients(data.data.caller.number);
                    setStatus("Incoming");
                    // incoming call
                }
                if (data.type === "replace" && data.data.state === "UNBRIDGED" && data.subId === "xcarepro" + clinic.id && data.data.direction === "recipient") {
                    lastStatus = data.data.state;
                    lastType = data.type;
                    setStatus("Disconnecting");
                }
                if (data.type === "withdraw" && data.data.state === "HUNGUP" && data.subId === "xcarepro" + clinic.id && data.data.direction === "recipient") {
                    lastStatus = data.data.state;
                    lastType = data.type;
                }
                /* End */
            };
        }
    }
    const getPatients = async (number: string) => {
        try {
            const results = await getPatientList({ fullname: number, page: 1, results: 30 });
            setPatientList(results.patients);
            // setShowPatientListModal(true);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        return () => document.removeEventListener("keydown", registerListerKeyboard);
    }, []);

    return (
        <Modal show={true} onHide={hideModal} centered className="newcommon patient_directory">
            <Modal.Header>
                <div className="modal-title">Patient Directory</div>
                <div>
                    {
                        clinic && (clinic.clinicSetting.communicationprovider === "mango" ||
                            clinic.clinicSetting.communicationprovider === "goto") &&
                        <button className="btn btn-link" onClick={handleSendFax}>Fax</button>
                    }
                    <button className="btn btn-link" onClick={hideModal}>Done</button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="position-relative inpopup_dial">
                    {

                        <>
                            {
                                status !== "" && clinic.clinicSetting.communicationprovider === "mango" &&
                                <div className="call-strip">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar rounded-circle bg-white text-dark text-uppercase flex-shrink-0">
                                                {clickedPatient && clickedPatient.fname
                                                    ? clickedPatient.fname.charAt(0)
                                                    : clickedPatient && clickedPatient.firstName
                                                        ? clickedPatient.firstName.charAt(0)
                                                        : ""}
                                                {clickedPatient && clickedPatient.lname
                                                    ? clickedPatient.lname.charAt(0)
                                                    : clickedPatient && clickedPatient.lastName
                                                        ? clickedPatient.lastName.charAt(0)
                                                        : ""}
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="d-block fw-medium text-white lh-base">
                                                    <div>{clickedPatient && clickedPatient.fname + " " + clickedPatient.lname}</div>
                                                    <div>{mobileRef.current}</div>
                                                </div>
                                                <small className="d-block text-white lh-base">{direction}</small>
                                                <small className="d-block text-white lh-base">{status}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                status !== "" && clinic.clinicSetting.communicationprovider === "goto" &&
                                <div className="call-strip">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar rounded-circle bg-white text-dark text-uppercase flex-shrink-0">
                                                {clickedPatient && clickedPatient.fname
                                                    ? clickedPatient.fname.charAt(0)
                                                    : clickedPatient && clickedPatient.firstName
                                                        ? clickedPatient.firstName.charAt(0)
                                                        : ""}
                                                {clickedPatient && clickedPatient.lname
                                                    ? clickedPatient.lname.charAt(0)
                                                    : clickedPatient && clickedPatient.lastName
                                                        ? clickedPatient.lastName.charAt(0)
                                                        : ""}
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="d-block fw-medium text-white lh-base">
                                                    <div>{clickedPatient && clickedPatient.fname + " " + clickedPatient.lname}</div>
                                                    <div>{mobileRef.current}</div>
                                                </div>
                                                <small className="d-block text-white lh-base">{status}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                device && clinic.clinicSetting.communicationprovider === "twilio" &&
                                <div className="call-strip">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar rounded-circle bg-white text-dark text-uppercase flex-shrink-0">
                                                {clickedPatient && clickedPatient.fname
                                                    ? clickedPatient.fname.charAt(0)
                                                    : clickedPatient && clickedPatient.firstName
                                                        ? clickedPatient.firstName.charAt(0)
                                                        : ""}
                                                {clickedPatient && clickedPatient.lname
                                                    ? clickedPatient.lname.charAt(0)
                                                    : clickedPatient && clickedPatient.lastName
                                                        ? clickedPatient.lastName.charAt(0)
                                                        : ""}
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <div className="d-block fw-medium text-white lh-base">
                                                    <div>{clickedPatient && clickedPatient.fname + " " + clickedPatient.lname}</div>
                                                    <div>{mobileRef.current}</div>
                                                </div>
                                                <small className="d-block text-white lh-base">{status}</small>
                                            </div>
                                        </div>

                                        <button className="btn btn-danger" onClick={callHangup}>
                                            <i className="bi bi-telephone"></i> Hangup
                                        </button>
                                    </div>
                                </div>
                            }
                            {
                                faxStatus &&
                                <div className="row">
                                    <div className="col">
                                        <input type="file" name="doc" multiple={false} accept="application/pdf" onChange={handleFileUpload} />
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-4 dial-pad">
                                    <label>Mobile/Home phone</label>
                                    <input className="form-control" placeholder="Enter mobile/home phone #" value={mobile} disabled />
                                    <div className="d-flex csdial flex-row mt-2 justify-content-between">
                                        <button className="btn btn-light rounded-circle " id="btn1" onClick={() => { handleNumber(1) }}>1</button>
                                        <button className="btn btn-light rounded-circle " id="btn2" onClick={() => { handleNumber(2) }}>2</button>
                                        <button className="btn btn-light rounded-circle " id="btn3" onClick={() => { handleNumber(3) }}>3</button>
                                    </div>
                                    <div className="d-flex csdial flex-row mt-2 justify-content-between">
                                        <button className="btn btn-light rounded-circle " id="btn4" onClick={() => { handleNumber(4) }}>4</button>
                                        <button className="btn btn-light rounded-circle " id="btn5" onClick={() => { handleNumber(5) }}>5</button>
                                        <button className="btn btn-light rounded-circle " id="btn6" onClick={() => { handleNumber(6) }}>6</button>
                                    </div>
                                    <div className="d-flex csdial flex-row mt-2 justify-content-between">
                                        <button className="btn btn-light rounded-circle " id="btn7" onClick={() => { handleNumber(7) }}>7</button>
                                        <button className="btn btn-light rounded-circle " id="btn8" onClick={() => { handleNumber(8) }}>8</button>
                                        <button className="btn btn-light rounded-circle " id="btn9" onClick={() => { handleNumber(9) }}>9</button>
                                    </div>
                                    <div className="d-flex csdial flex-row mt-2 justify-content-between">
                                        <button className="btn btn-light rounded-circle " id="btnStar" onClick={() => { handleNumber('*') }}>*</button>
                                        <button className="btn btn-light rounded-circle " id="btn0" onClick={() => { handleNumber(0) }}>0</button>
                                        <button className="btn btn-light rounded-circle " id="btnPound" onClick={() => { handleNumber('#') }}>#</button>
                                    </div>
                                    <div className="d-flex csdial flex-row mt-2 justify-content-between">
                                        <button className="btn btn-light rounded-circle " id="btnPlus" onClick={() => { handleNumber('+') }}>+</button>
                                        {
                                            faxStatus &&
                                            <button className="btn btn-dark rounded-circle" onClick={handleFaxDial}><img src={FaxIcon} alt="" srcSet="" /></button>
                                        }
                                        {
                                            !faxStatus &&
                                            <button className="btn btn-dark rounded-circle " id="btnDial" onClick={() => { handleDial(mobile.current) }}><i className="bi bi-telephone"></i></button>
                                        }
                                        <button className="btn btn-light rounded-circle " id="btnRemove" onClick={() => { handleBackSpace() }}><i className="bi bi-backspace"></i></button>
                                    </div>
                                </div>
                                <div className="col-8 ps-4">

                                    {
                                        <>
                                            <div className="globe-search">
                                                <label>Patient name</label>
                                                <div className="input-group mb-3">
                                                    <input className="form-control" placeholder="Enter patient name" name="patient" onChange={(e: any) => { setSearchName(e.target.value) }} />
                                                    <button className="btn btn-outline-secondary" type="submit" onClick={() => { getAllPatientList() }}>
                                                        <i className="bi bi-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                (patientList && patientList.length > 0) ?
                                                    <ul className="patient_call_list w-100 p-0">
                                                        {
                                                            patientList.map((patient: any) => {
                                                                return (
                                                                    <li className="border-bottom p-1 pointer">
                                                                        <div>{patient.dependant && patient.dependant.fname + " " + patient.dependant.lname}</div>
                                                                        {
                                                                            patient.dependant && patient.dependant.homephone &&
                                                                            <span className="border-end me-3">Home# :
                                                                                <button className="btn" onClick={() => {
                                                                                    setMyMobile(patient.dependant.homephone);
                                                                                    if (faxStatus) return;
                                                                                    handleDial(patient.dependant.homephone);
                                                                                    setClickedPatient(patient.dependant);
                                                                                }}><i className="bi bi-telephone me-1"></i> +1{patient.dependant && patient.dependant.homephone}</button> </span>
                                                                        }
                                                                        {
                                                                            patient.dependant && patient.dependant.phone &&
                                                                            <span>Mobile# :
                                                                                <button className="btn" onClick={() => {
                                                                                    setMyMobile(patient.dependant.phone);
                                                                                    if (faxStatus) return;
                                                                                    handleDial(patient.dependant.phone);
                                                                                    setClickedPatient(patient.dependant);
                                                                                }}><i className="bi bi-telephone me-1"></i> +1{patient.dependant && patient.dependant.phone}</button></span>
                                                                        }
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    : (isNoRecord) &&
                                                    <strong>No record found!</strong>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Modal.Body>
        </Modal >
    )
}
