import React, { useEffect, useState } from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { employeeListPending, employeeDeletePending, addEmployeePending, employeeInit } from '../../store/employee/actions';
import { IAppState } from '../../store';
import { isArray } from 'lodash';
import { Employee as EmployeeItem } from '../../store/employee/types';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { allowOnlyAlpha, emailValidator, passwordValidator } from '../../utils/validators';
import { EmployeeHeader } from './EmployeeHeader';
import { EmployeePrescreening } from './EmployeePrescreening';
interface Props {
    match: any;
    location: any;
    history: any;
}
export const Employee: React.FC<Props> = (props) => {
    const [state, setState] = useState({ addEmpModal: false, _isMounted: false, selectedClinic: '' });
    const dispatch = useDispatch();
    const employeeListResponse = useSelector((state: IAppState) => state.employee.employeeListResponse, shallowEqual);
    const error = useSelector((state: IAppState) => state.employee.error, shallowEqual);
    const employeeAddResponse = useSelector((state: IAppState) => state.employee.addEmployeeResponse, shallowEqual);
    const employeeDeleteResponse = useSelector((state: IAppState) => state.employee.deleteEmployeeResponse, shallowEqual);
    const clinicListFromUserData = useSelector((state: IAppState) => state.userData.userDataResponse.clinics, shallowEqual);
    useEffect(() => {
        dispatch(employeeInit());
    }, []);
    useEffect(() => {
        if (clinicListFromUserData[0]) {
            setTimeout(() => {
                setState({
                    ...state, selectedClinic: clinicListFromUserData[0].uniqueId,
                    addEmpModal: false, _isMounted: false,
                });
                dispatch(employeeListPending({ clinicUniqueId: clinicListFromUserData[0].uniqueId }));
            }, 100);
        }
    }, [clinicListFromUserData]);
    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);
    useEffect(() => {
        if (employeeListResponse && employeeListResponse.status && employeeListResponse.status.error === false &&
            employeeListResponse.status.msg !== "" && state._isMounted === false) {
            setState({ ...state, _isMounted: true });
        }
    }, [employeeListResponse]);
    useEffect(() => {
        if (employeeAddResponse && employeeAddResponse.status && employeeAddResponse.status.error === false &&
            employeeAddResponse.status.msg !== "" && state._isMounted) {
            toast.success(employeeAddResponse.status.msg);
            toggleEmpAddModal();
        }
    }, [employeeAddResponse]);
    useEffect(() => {
        if (employeeDeleteResponse && employeeDeleteResponse.status && employeeDeleteResponse.status.error === false &&
            employeeDeleteResponse.status.msg !== "" && state._isMounted) {
            toast.success(employeeDeleteResponse.status.msg);
        }
    }, [employeeDeleteResponse]);
    const toggleEmpAddModal = () => {
        setState({ ...state, addEmpModal: !state.addEmpModal });
    }
    const employeeDelete = (workId: string) => {
        dispatch(employeeDeletePending({ clinicUniqueId: state.selectedClinic, workId: workId }));
    }
    const selectedClinicChange = (uniqueId: string) => {
        setState({
            ...state, selectedClinic: uniqueId,
            _isMounted: false
        });
        dispatch(employeeListPending({ clinicUniqueId: uniqueId }));
    }
    return (
        <>
            <EmployeeHeader></EmployeeHeader>
            <div className="container">
                <Switch>
                    <Route exact path="/user/employee/prescreening" render={(props) => (<EmployeePrescreening {...props}></EmployeePrescreening>)}></Route>
                    <Route exact path="/user/employee" render={(props) => (
                        <>
                            <div className="row">
                                <div className="col-sm-12">

                                    <ClinicDropDown clinicList={clinicListFromUserData} onChange={selectedClinicChange}></ClinicDropDown>

                                    <button className="btn btn-primary" onClick={toggleEmpAddModal}>Add Employee</button>
                                    <EmployeeAddModal clinicUniqueId={state.selectedClinic} modalShow={state.addEmpModal} hideModal={toggleEmpAddModal}></EmployeeAddModal>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 m-t-20">
                                    {
                                        (state._isMounted === true) ?
                                            <>

                                                {
                                                    (employeeListResponse && isArray(employeeListResponse.employees) && employeeListResponse.employees.length > 0) ?
                                                        <table className="table m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>First Name</th>
                                                                    <th>Last Name</th>
                                                                    <th>Email</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    employeeListResponse.employees.map((value: any) => {
                                                                        return <EmployeeListItem employee={value} employeeDelete={employeeDelete}></EmployeeListItem>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table> : 'No data available'
                                                }
                                            </>
                                            :
                                            <><i className="anticon anticon-loading"></i></>
                                    }
                                </div>
                            </div>
                        </>
                    )}></Route>
                </Switch>

            </div>



        </>
    );
}
interface EmployeeItemProps {
    employee: EmployeeItem;
    employeeDelete: (workId: string) => void;
}
const EmployeeListItem: React.FC<EmployeeItemProps> = (props: EmployeeItemProps) => {
    return (
        <tr>
            <td>{props.employee.fname}</td>
            <td>{props.employee.lname}</td>
            <td>{props.employee.email}</td>
            <td><button className="btn btn-icon btn-primary" onClick={(e) => props.employeeDelete(props.employee.workid)}><i className="anticon anticon-delete"></i></button></td>
        </tr>
    )
}
interface EmployeeAddModalProps {
    clinicUniqueId: string;
    modalShow: boolean;
    hideModal: () => void;
}
export const EmployeeAddModal: React.FC<EmployeeAddModalProps> = (props: EmployeeAddModalProps) => {
    const [state, setState] = useState({
        fname: '',
        lname: '',
        email: '',
        password: '',
        processing: false,
        passwordValidation: false,
        firstNameRequired: false,
        lastNameRequired: false,
        emailValidation: false,
        emailRequired: false,
        passwordRequired: false
    });
    const error = useSelector((state: IAppState) => state.employee.error);
    useEffect(() => {
        if (props.modalShow === true) {
            setState({
                fname: '',
                lname: '',
                email: '',
                password: '',
                processing: false,
                passwordValidation: false,
                firstNameRequired: false,
                lastNameRequired: false,
                emailValidation: false,
                emailRequired: false,
                passwordRequired: false
            });
        }
    }, [props.modalShow]);
    useEffect(() => {
        if (error) {
            setState({
                ...state, processing: false
            });
        }
    }, [error]);
    const dispatch = useDispatch();
    const onchangeListener = (e: any, type: "fname" | "lname" | "email" | "password") => {
        switch (type) {
            case "fname":
                setState({
                    ...state,
                    firstNameRequired: false,
                    fname: (allowOnlyAlpha(e.target.value) || e.target.value === "") ? e.target.value.trim() : state.fname
                });
                break;
            case "lname":
                setState({
                    ...state,
                    lastNameRequired: false,
                    lname: (allowOnlyAlpha(e.target.value) || e.target.value === "") ? e.target.value.trim() : state.lname
                });
                break;
            case "email":
                setState({
                    ...state,
                    emailRequired: false,
                    emailValidation: false,
                    email: e.target.value.trim()
                });
                break;
            case "password":
                setState({
                    ...state,
                    passwordRequired: false, passwordValidation: false,
                    password: e.target.value.trim()
                });
                break;
            default:
                return null;
        }
    }
    const addEmployee = (e: any) => {
        setState({
            ...state,
            firstNameRequired: false,
            lastNameRequired: false,
            emailValidation: false,
            passwordValidation: false,
            emailRequired: false,
            passwordRequired: false
        });
        if (state.fname.trim() === "") {
            setState({
                ...state,
                firstNameRequired: true,
            });
            return;
        }
        if (state.lname.trim() === "") {
            setState({
                ...state,
                lastNameRequired: true,
            });
            return;
        }
        if (state.email.trim() === "") {
            setState({
                ...state,
                emailRequired: true,
            });
            return;
        }
        if (!emailValidator(state.email)) {
            setState({
                ...state,
                emailValidation: true,
            });
            return;
        }
        if (state.password.trim() === "") {
            setState({
                ...state,
                passwordRequired: true,
            });
            return;
        }
        if (!passwordValidator(state.password)) {
            setState({
                ...state,
                passwordValidation: true
            });
            return;
        }
        setState({
            ...state, processing: true,
            firstNameRequired: false,
            lastNameRequired: false,
            emailValidation: false,
            passwordValidation: false,
            emailRequired: false,
            passwordRequired: false
        });
        dispatch(addEmployeePending({
            clinicUniqueId: props.clinicUniqueId,
            email: state.email,
            fname: state.fname,
            lname: state.lname,
            password: state.password
        }));
    }
    return (
        <Modal show={props.modalShow} onHide={props.hideModal} centered>
            <Modal.Header>
                <Modal.Title>Add Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>First Name</label>
                        <input className="form-control" type="text" name="firstName" value={state.fname} onChange={e => onchangeListener(e, "fname")}
                            onInput={e => onchangeListener(e, "fname")}></input>
                        {
                            state.firstNameRequired &&
                            <label className="text-danger">First name required</label>
                        }
                    </div>
                    <div className="form-group col-md-6">
                        <label>Last Name</label>
                        <input className="form-control" type="text" name="lastName" value={state.lname} onChange={e => onchangeListener(e, "lname")}
                            onInput={e => onchangeListener(e, "lname")}></input>
                        {
                            state.lastNameRequired &&
                            <label className="text-danger">Last name required</label>
                        }
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>Email</label>
                        <input className="form-control" type="email" name="email" value={state.email} onChange={e => onchangeListener(e, "email")}
                            onInput={e => onchangeListener(e, "email")}></input>
                        {
                            state.emailRequired &&
                            <label className="text-danger">Email required</label>
                        }
                        {
                            state.emailValidation &&
                            <label className="text-danger">Please enter valid email</label>
                        }
                    </div>
                    <div className="form-group col-md-6">
                        <label>Password</label>
                        <input className="form-control" type="password" name="password" value={state.password} onChange={e => onchangeListener(e, "password")}
                            onInput={e => onchangeListener(e, "password")}></input>
                        {
                            state.passwordRequired &&
                            <label className="text-danger">Password required</label>
                        }
                        {
                            state.passwordValidation &&
                            <label className="text-danger">Password must have at least one uppercase, one lowercase, one special character and one number with a minimum of 8 characters.</label>
                        }
                    </div>
                </div>

                <div>
                    <button disabled={state.processing} className="btn btn-primary" onClick={addEmployee}>Add</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
interface ClinicDropDownProps {
    clinicList: any[];
    onChange: (uniqueId: string) => void;
}
export const ClinicDropDown: React.FC<ClinicDropDownProps> = (props: ClinicDropDownProps) => {
    const [selectedClinic, selectClinic] = useState(props.clinicList[0] ? props.clinicList[0].uniqueid : '');
    useEffect(() => {
        if (selectedClinic) {
            props.onChange(selectedClinic);
        }
    }, [selectedClinic]);
    return (
        <select className="custom-select m-r-15" style={{ width: "240px" }} value={selectedClinic} onChange={(e) => {
            selectClinic(e.target.value);
        }}>
            <option value=''>Select</option>
            {
                props.clinicList.map((value, index) => {
                    return (
                        <option value={value.uniqueId} key={index + Math.random()}>{value.name}</option>
                    )
                })
            }
        </select>
    );
}