import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { addMember, getPatientList, updateDependantDeceased, updateDependantMedicinRemind, updateSendSMSStatus } from '../../services/patientsearch';
import { confirmAlert } from 'react-confirm-alert';
import CONSTANT_LABELS from '../../../constants/labels';
import { removeCountryCode } from '../../../utils/global';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { AddMemberModal } from '../../components/patientsearch/AddMemberModal';
import { toast } from 'react-toastify';

export const PatientList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [patientList, setPatientList] = useState([]);
    const location = useLocation();
    const history = useHistory();
    const clinic = useSelector((state: IAppState)=> state.userData.userDataResponse.clinics[0]);
    const userData = useSelector((state: IAppState)=> state.userData.userDataResponse);
    const [newPatientModal, setNewPatientModal] = useState(false);
    useEffect(() => {
        getAllPatientList();
    }, [location]);

    const getAllPatientList = () => {
        if (location && location.search) {
            const request: any = { fullname: new URLSearchParams(location.search).get("q") };
            setIsLoading(true);
            getPatientList(request).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    setPatientList(success.patients);
                    return;
                }
                setPatientList([]);
            }).catch((err) => {
                setIsLoading(false);
                setPatientList([]);
            })
        }
    }

    const changeSendSMSStats = (dependantuniqueid: any, chatpinstatus: boolean, chatstatus: boolean) => {
        confirmAlert({
            title: '',
            message: 'Please confirm, if you want to change status',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        updateSendSMSStatus({
                            depid: dependantuniqueid,
                            chat_pin_sendsms: chatpinstatus,
                            chat_sendsms: chatstatus
                        }).then((success) => {
                            if (success && success.status.error === false) {
                                getAllPatientList();
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        getAllPatientList();
                    }
                }
            ]
        });
    }

    const changeDependedDeceased = (dependantuniqueid: any, status: boolean) => {
        confirmAlert({
            title: '',
            message: 'Please confirm, if you want to change status',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        updateDependantDeceased({
                            dependant_archive: status ? "1" : "0",
                        }, clinic.uniqueId, dependantuniqueid).then((success) => {
                            if (success && success.status.error === false) {
                                getAllPatientList();
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        getAllPatientList();
                    }
                }
            ]
        });
    };

    const changeDependantMedicine = (dependantuniqueid: any, status: boolean) => {
        confirmAlert({
            title: '',
            message: 'Please confirm, if you want to change status',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        updateDependantMedicinRemind({
                            remindForMedicine: status ? "1" : "0",
                        }, clinic.uniqueId, dependantuniqueid).then((success) => {
                            if (success && success.status.error === false) {
                                getAllPatientList();
                            }
                        }).catch((err) => {
                            console.log(err);
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        getAllPatientList();
                    }
                }
            ]
        });
    };

    const createMemberFunc = (data: any) => {
        const clinicId = userData.clinics[0].uniqueId;
        return addMember(data, clinicId, true)
          .then((success) => {
            if (success && success.status && success.status.error === false) {
                getAllPatientList();
                toast.success(success.status.msg);
              setNewPatientModal(false);
              return;
            }
            toast.error("Add patient failed");
          })
          .catch((err) => {
            if (err && err.error === true) {
              toast.error(err.msg);
              throw err;
            }
            toast.error("Add patient failed");
            throw err;
          });
    };

    return (
        <>
            <section className="middle-navbar px-30">
                <div className="p-0 container-fluid">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="nav-title-txt">Patient search</div>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/patientsearch">All patients</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div>
                            {
                                !userData?.accessEnabled?.pms_integration &&
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => {
                                        setNewPatientModal(true);
                                    }}
                                >
                                    Add new patient
                                </button>
                            }
                        </div>

                    </div>
                </div>
            </section>
            <section className="patientsearch-sec px-3 pt-4">
                {
                    isLoading &&
                    <span className="spinner-border" role="status"></span>
                }
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="advances-table">
                                {
                                    (patientList.length === 0 && !isLoading) &&
                                    <label>No record found.</label>
                                }
                                {
                                    !isLoading && patientList.length > 0 &&
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Patient name</th>
                                                <th>Guarantor</th>
                                                <th>Mobile Number</th>
                                                <th colSpan={3} className="text-center" style={{ width: "180px" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                patientList.map((value: any) => {
                                                    let letterOne: any = '';
                                                    let letterTwo: any = '';
                                                    if (value.dependant && value.dependant.fname || value.dependant.lname) {
                                                        letterOne = value.dependant.fname ? value.dependant.fname.substring(0, 1) : '';
                                                        letterTwo = value.dependant.lname ? value.dependant.lname.substring(0, 1) : '';
                                                    }
                                                    return (
                                                        <tr key={value.dependant.id}>
                                                            <td>{value.dependant && value.dependant.uniqueid && value.dependant.uniqueid}</td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="avatar flex-shrink-0 avatar-lg border rounded-circle me-3">
                                                                        {letterOne}{letterTwo}
                                                                    </div>
                                                                    <div>
                                                                        <div className="fw-medium">{value.dependant && value.dependant?.fname + " " + value.dependant?.lname}</div>
                                                                        <div className="text-muted email-txt">{value.dependant && value.dependant.email && value.dependant.email}</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{value.guarantor && value.guarantor?.fname + " " + value.guarantor?.lname}</td>
                                                            <td>{value.dependant && value.dependant.phone && (CONSTANT_LABELS.PLUS_ONE + removeCountryCode(value.dependant.phone))}</td>
                                                            <td>
                                                                <div className="form-check form-switch align-self-center sms-switch">
                                                                    <label className="small text-muted">Mark as Deceased </label>
                                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                                                        checked={value?.dependant?.deceased}
                                                                        onChange={() => { changeDependedDeceased(value.dependant.uniqueid, !value?.dependant?.deceased) }}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-check form-switch align-self-center sms-switch">
                                                                    <label className="small text-muted">Medicine Reminder </label>
                                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                                                        checked={value?.remindForMedicine}
                                                                        onChange={() => { changeDependantMedicine(value.dependant.uniqueid, !value?.remindForMedicine) }}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-check form-switch align-self-center sms-switch">
                                                                    <label className="small text-muted">Chat Pin SMS</label>
                                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                                                        checked={value.chatpinsms}
                                                                        onChange={() => { changeSendSMSStats(value.dependant.id, !value.chatpinsms, value.chatsendsms) }}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-check form-switch align-self-center sms-switch">
                                                                    <label className="small text-muted">Chat SMS</label>
                                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                                                        checked={value.chatsendsms}
                                                                        onChange={() => { changeSendSMSStats(value.dependant.id, value.chatpinsms, !value.chatsendsms) }}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <button className="btn mx-1 p-1 btn-link" onClick={(e) => {
                                                                    history.push('/patient/' + value.dependant.uniqueid);
                                                                }}>Edit</button>


                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>

                </div>
                {
                    newPatientModal && (
                    <AddMemberModal
                        hideModal={() => {
                            setNewPatientModal(false);
                        }}
                        createMember={createMemberFunc}
                    ></AddMemberModal>
                )}
            </section>
        </>
    );
}