import React, { useEffect, useState } from 'react';
import Board from "react-trello";
import moment from 'moment';
import { addIntoTaskManagerDiscussion, addIntoTaskManagerHistory, archiveCard, createCard, filterBoard, getArchiveBoard, getBoard, moveTask, removeCard } from '../../services/taskmanager';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { Modal } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { validateAddCard } from '../../utils/taskmanager';
import { getCookie } from '../../utils/cookies';
import firebase from 'firebase';
import configs from '../../configs/apiConfigs';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';

const db = firebase.firestore();
export const TaskManager = () => {
    const location = useLocation();
    const mode = new URLSearchParams(location.search).get("mode");
    // console.log("mode", mode);
    const [board, setBoard]: any = useState({
        lanes: []
    });
    const [users, setUsers] = useState([]);
    const [showCardPopup, setShowCardPopup] = useState(false);
    const [selectedCard, setSelectedCard]: any = useState(null);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const [showArchiveCards, setShowArchiveCards] = useState(false);
    const [archiveBoard, setArchiveBoard] = useState({
        lanes: []
    });
    const [filters, setFilters]: any = useState({
        assigned: [],
        created: '',
        target: ''
    });
    const [statuses, setStatuses] = useState([]);
    const [showFilterModal, setShowFilterModal] = useState(false);
    useEffect(() => {
        getBoardList();
    }, [userData]);
    const getBoardList = () => {
        getBoard(userData.clinics[0].uniqueId, {assigned: userData.id}).then((success) => {
            if (success && success && success.status.error === false) {
                const lanes = success.boards[0].columns;
                const informationTabIndex = lanes.findIndex((item: any) => item.title === "Information Tab");
                if (informationTabIndex !== -1) {
                    const informationTab = lanes[informationTabIndex];
                    lanes.splice(informationTabIndex, 1);
                    lanes.splice(0, 0, informationTab);
                }
                setBoard({
                    ...success.boards[0],
                    lanes: lanes
                });
                setUsers(success.users);
                const currentUser = success.users.find((item:any) => item.id == userData.id);
                setShowArchiveCards(false);
                if (currentUser){
                    // console.log(lanes.map((item: any) => ({ value: item.id, label: item.title })));
                    setFilters({
                        assigned: [{value: currentUser.id, label: currentUser.fname +" " + currentUser.lname}],
                        created: '',
                        target: '',
                        status: lanes?.filter((item: any) => item.title !== "Created by me" && item.title !== 'Information Tab')?.map((item: any) => ({ value: item.id, label: item.title }))
                    });
                } else {
                    setFilters({
                        assigned: [],
                        created: '',
                        target: '',
                        status: lanes?.filter((item: any) => item.title !== "Created by me" && item.title !== 'Information Tab')?.map((item: any) => ({ value: item.id, label: item.title }))
                    });
                }
                let status = [];
                setStatuses(status = lanes.map((item: any) => ({ value: item.id, label: item.title })));
            }
        })
    }
    const getArchiveBoardList = () => {
        let filtersReq: any = {};
        filtersReq.assigned = filters?.assigned?.map((item: any) => item.value)?.toString() || undefined;
        filtersReq.created = filters?.created?.value || undefined;
        filtersReq.target = filters?.target || undefined;
        filtersReq.status = filters?.status?.map((item: any) => item.value)?.toString() || undefined;
        getArchiveBoard(userData.clinics[0].uniqueId, filtersReq).then((success) => {
            if (success && success && success.status.error === false) {
                const lanes = success.boards[0].columns;
                setArchiveBoard({
                    ...success.boards[0],
                    lanes: lanes
                })
            }
        })
    }
    const handleCardClick = (e: any) => {
        let card: any = board.lanes.find((item: any) => {
            return item.cards.find((card: any) => card.id === e) !== undefined;
        });
        if (card) {
            card = card.cards.find((item: any) => item.id === e);
            setSelectedCard(card);
            setShowCardPopup(true);
        }
    }
    const handleDragEnd = (cardId: any, sourceLaneId: any, targetLaneId: any) => {
        // console.log('drag ended')
        // console.log(`cardId: ${cardId}`)
        // console.log(`sourceLaneId: ${sourceLaneId}`)
        // console.log(`targetLaneId: ${targetLaneId}`)
        const request = { cardId: cardId, fromId: sourceLaneId, toId: targetLaneId };
        /* moveTask(userData.clinics[0].uniqueId, cardId, request).then((success) => {
            if (success && success && success.status.error === false) {
                getBoardList();
            }
        }).catch((err) => {
            console.log(err);
        }) */
    }
    const handleCardAdd = (card: any, laneId: any) => {
        card = {
            ...card,
            desc: card.description
        }
        const assignedTo: any = users.find((item: any) => item.id == card.assignedto);
        createCard(userData.clinics[0].uniqueId, card).then((success) => {
            // console.log(success);
            if (success && success && success.status.error === false) {
                getBoardList();
                addIntoTaskManagerHistory({
                    boardId: board.id,
                    cardId: success.data.cardid,
                    laneId: card.columnid,
                    createdById: getCookie().xpr_user_id,
                    createdByName: userData.firstName + ' ' + userData.lastName,
                    text: 'New card with title ' + card.title + ' is assigned to ' + (assignedTo?.fname || '') + ' ' + (assignedTo?.lname || '') + '.'
                });
            }
        });
    }
    const handleEditCard = (card: any) => {
        createCard(userData.clinics[0].uniqueId, card).then((success) => {
            // console.log(success);
            if (success && success && success.status.error === false) {
                getBoardList();
                setShowCardPopup(false);
                addIntoTaskManagerHistory({
                    boardId: board.id,
                    cardId: card.cardid,
                    laneId: card.columnid,
                    createdById: getCookie().xpr_user_id,
                    createdByName: userData.firstName + ' ' + userData.lastName,
                    text: 'Card with title ' + card.title + ' is updated.'
                });
            }
        });
    }
    const handleCardDelete = (cardId: any, laneId: any) => {
        removeCard(userData.clinics[0].uniqueId, cardId).then((success) => {
            if (success && success && success.status.error === false) {
                if (showArchiveCards) {
                    getArchiveBoardList()
                    return;
                }
                getBoardList();
            }
        })
    }
    const handleOnCardMoveAcrossLanes = (fromLaneId: any, toLaneId: any, cardId: any, index: any) => {
        // console.log('handleOnCardMoveAcrossLanes: ', fromLaneId, toLaneId, cardId, index);
        const request = { cardId: cardId, fromId: fromLaneId, toId: toLaneId, index: index + 1 };
        const fromColumn = board.lanes.find((item: any) => {
            return item.id == fromLaneId;
        });
        const toColumn = board.lanes.find((item: any) => {
            return item.id == toLaneId;
        });
        const card = fromColumn.cards.find((card: any) => {
            return card.id == cardId
        });
        moveTask(userData.clinics[0].uniqueId, cardId, request).then((success) => {
            if (success && success && success.status.error === false) {
                if (showArchiveCards) {
                    getArchiveBoardList()
                    return;
                }
                getBoardList();
                addIntoTaskManagerHistory({
                    boardId: board.id,
                    cardId: cardId,
                    laneId: fromLaneId,
                    createdById: getCookie().xpr_user_id,
                    createdByName: userData.firstName + ' ' + userData.lastName,
                    text: 'Card with title ' + card.title + ' is moved from ' + fromColumn.title + ' to ' + toColumn.title + '.'
                });
            }
        }).catch((err) => {
            console.log(err);
        })
    };
    const handleArchive = (cardId: any, laneId: any, card: any) => {
        archiveCard(userData.clinics[0].uniqueId, cardId).then((success) => {
            if (success && success && success.status.error === false) {
                getBoardList();
                addIntoTaskManagerHistory({
                    boardId: board.id,
                    cardId: cardId,
                    laneId: laneId,
                    createdById: getCookie().xpr_user_id,
                    createdByName: userData.firstName + ' ' + userData.lastName,
                    text: 'Card with title ' + card.title + ' is archived.'
                });
            }
        })
    }
    const handleShowArchivedCard = () => {
        let filtersReq: any = {};
        filtersReq.assigned = filters?.assigned?.map((item: any) => item.value)?.toString() || undefined;
        filtersReq.created = filters?.created?.value || undefined;
        filtersReq.target = filters?.target || undefined;
        filtersReq.status = filters?.status?.map((item: any) => item.value)?.toString() || undefined;
        getArchiveBoard(userData.clinics[0].uniqueId, filtersReq).then((success) => {
            if (success && success && success.status.error === false) {
                const lanes = success.boards[0].columns;
                setArchiveBoard({
                    ...success.boards[0],
                    lanes: lanes
                });
                setShowArchiveCards(true);
            }
        })
    }
    const handleSearch = () => {
        // console.log(filters);
        setShowFilterModal(false);
        if (showArchiveCards) {
            handleShowArchivedCard()
            return;
        }
        let filtersReq: any = {};
        filtersReq.assigned = filters?.assigned?.map((item: any) => item.value)?.toString() || undefined;
        filtersReq.created = filters?.created?.value || undefined;
        filtersReq.target = filters?.target || undefined;
        filtersReq.status = filters?.status?.map((item: any) => item.value)?.toString() || undefined;
        filterBoard(userData.clinics[0].uniqueId, filtersReq).then((success) => {
            if (success && success && success.status.error === false) {
                const lanes = success.boards[0].columns;
                setBoard({
                    ...success.boards[0],
                    lanes: lanes
                })
                setShowArchiveCards(false);
            }
        }).catch((err) => {

        })
    }
    const handleReset = () => {
        setFilters({
            assigned: [],
            created: '',
            target: '',
            status: []
        });
        getBoardList();
    }

    return (

        <div className="task_board_section p-3">
            <div className="task-manager-header d-flex justify-content-between align-items-center pb-3 mb-2" >
                <div>
                    {
                        !showArchiveCards &&
                        <h6>Main Board</h6>
                    }
                    {
                        showArchiveCards &&
                        <h6>Archived Cards</h6>
                    }
                </div>
                <div>
                    {
                        <button className="btn btn-outline-dark me-2" onClick={() => {
                            handleReset()
                        }}>Reset Search</button>
                    }
                    {
                        <button className="btn btn-outline-dark me-2" onClick={() => {
                            setShowFilterModal(true);
                        }}>Search</button>
                    }
                    {
                        !showArchiveCards &&
                        <button className="btn btn-outline-dark" onClick={handleShowArchivedCard}>Archived Cards</button>
                    }
                    {
                        showArchiveCards &&
                        <button className="btn btn-outline-dark" onClick={getBoardList}>Main Board</button>
                    }
                </div>
            </div>
            <div>

                {/* <div className="d-flex justify-content-end">
                    {
                        !showArchiveCards &&
                        <button className="btn btn-outline-dark" onClick={handleShowArchivedCard}>Show Archived Cards</button>
                    }
                    {
                        showArchiveCards &&
                        <button className="btn btn-outline-dark" onClick={getBoardList}>Main Board</button>
                    }
                </div> */}



                {
                    <Modal show={showFilterModal} onHide={() => {
                        setShowFilterModal(false);
                    }}>
                        <Modal.Header>
                            <button className="btn btn-link" onClick={() => {
                                setShowFilterModal(false);
                            }}>Done</button>
                        </Modal.Header>
                    <div className="row p-4">

                        <div className="col-12 mb-3">
                            <label className="form-label">Assigned to: </label>
                            <Select
                                value={filters.assigned}
                                isMulti
                                name="assigned"
                                options={users.map((item: any) => ({ value: item.id, label: item.fname + " " + item.lname }))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e: any) => {
                                    setFilters({
                                        ...filters,
                                        assigned: e
                                    });
                                }}
                            />
                        </div>
                   
                        <div className="col-12 mb-3">
                            <label className="form-label">Created by: </label>
                            <Select
                                value={filters.created}
                                name="created"
                                options={users.map((item: any) => ({ value: item.id, label: item.fname + " " + item.lname }))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e: any) => {
                                    setFilters({
                                        ...filters,
                                        created: e
                                    });
                                }}
                            />
                        </div>
                        <div className={"col-12 mb-3" + (showArchiveCards && " d-none")}>
                            <label className="form-label">Status</label>
                            <Select
                                value={filters.status}
                                name="status"
                                isMulti={true}
                                options={statuses.filter((item: any) => item.label !== "Created by me" && item.label !== 'Information Tab')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e: any) => {
                                    setFilters({
                                        ...filters,
                                        status: e
                                    });
                                }}
                            />
                        </div>

                        <div className="col-12 mb-3">
                            <label className="form-label">Target date:</label>
                            <input className="form-control" style={{ padding: "8px 10px" }} type="date" value={filters.target} onChange={(e) => {
                                setFilters({
                                    ...filters,
                                    target: e.target.value
                                });
                            }} />
                        </div>

                        <div className="col-12 mb-3">
                            <button className="btn btn-secondary me-2" onClick={handleSearch}>Search</button>
                            <button className="btn btn-secondary me-2" onClick={handleReset}>Reset</button>
                        </div>
                    </div>
                    </Modal>

                }
               
                {
                    showArchiveCards &&
                    <ArchiveCardList board={archiveBoard} showArchiveCards={showArchiveCards}></ArchiveCardList>
                }
                {
                    !showArchiveCards &&
                    <Board
                        data={board}    
                        editable={true}
                        style={{
                            backgroundColor: '#ffffff'
                        }}
                        onCardClick={handleCardClick}
                        draggable
                        components={{
                            NewCardForm: (props: any) => <NewCardForm {...props} users={users}></NewCardForm>,
                            Card: (props: any) => <CustomCard {...props} showArchiveCards={showArchiveCards} onArchive={handleArchive} board={board}></CustomCard>,
                            AddCardLink: AddCardLink
                        }}
                        handleDragEnd={handleDragEnd}
                        onCardAdd={handleCardAdd}
                        onCardDelete={handleCardDelete}
                        onCardMoveAcrossLanes={handleOnCardMoveAcrossLanes}
                    ></Board>
                }
                {
                    showCardPopup &&
                    <CardPopUp
                        users={users}
                        cardDetail={selectedCard}
                        hideModal={() => {
                            setShowCardPopup(false);
                            setSelectedCard(null);
                        }}
                        handleEditCard={handleEditCard}
                        userData={userData}
                        board={board}
                        statuses={statuses}
                    >
                    </CardPopUp>
                }
            </div>
        </div>
    )
}

const AddCardLink = (props: any) => {
    if (props.laneId !== 0) {
        return (
            <div className="d-flex justify-content-end align-items-end add-new-task-link"><a href="#" onClick={props.onClick}>Add Task</a></div>
        )
    }
    return null;
}

const NewCardForm = (props: any) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [assignedTo, setAssignedTo] = useState('');
    const [targetDate, setTargetDate] = useState('');
    const [errors, setErrors]: any = useState({});
    const handleChange = (e: any) => {
        setAssignedTo(e.target.value);
    }
    return (
        <div className="add_new_task">
            <div className="mb-2">
                <label className="form-label">Title</label>
                <input className="form-control form-control-sm"
                    placeholder="Title" type="text" name="title"
                    value={title} onChange={(e) => { setTitle(e.target.value); }} />
                {
                    errors && errors.title && errors.title.error === true &&
                    <label className="text-danger">{errors.title.message}</label>
                }
            </div>

            <div className="mb-2">
                <label className="form-label">Description</label>
                <textarea cols={5} rows={5} className="form-control form-control-sm" placeholder="Description" name="description" value={description} onChange={(e) => {
                    setDescription(e.target.value);
                }}></textarea>
                {
                    errors && errors.description && errors.description.error === true &&
                    <label className="text-danger">{errors.description.message}</label>
                }
            </div>

            <div className="mb-2">
                <label className="form-label">Assigned To</label>
                <select className="form-select form-select-sm" value={assignedTo} name="assignedTo" onChange={handleChange}>
                    <option value={""} disabled>Select</option>
                    {
                        props.users.map((item: any) => {
                            return (
                                <option value={item.id} key={item.id + "assign_user"}>{item.fname + " " + item.lname}</option>
                            )
                        })
                    }
                </select>
                {
                    errors && errors.assignedTo && errors.assignedTo.error === true &&
                    <label className="text-danger">{errors.assignedTo.message}</label>
                }
            </div>

            <div className="mb-2">
                <label className="form-label">Targeted Date</label>
                <input className="form-control form-control-sm" type="date" value={targetDate} onChange={(e) => {
                    setTargetDate(e.target.value);
                }} />
                {
                    errors && errors.targetDate && errors.targetDate.error === true &&
                    <label className="text-danger">{errors.targetDate.message}</label>
                }
            </div>

            <div className="d-flex justify-content-end">
                <button className="btn btn-sm btn-outline-primary me-2" onClick={() => {
                    props.onCancel()
                }}>Cancel</button>

                <button className="btn btn-sm btn-primary" onClick={() => {
                    const errors = validateAddCard({
                        title, description, assignedTo, targetDate
                    });
                    if (Object.keys(errors).length > 0) {
                        setErrors(errors);
                    } else {
                        props.onAdd({
                            "columnid": props.laneId,
                            "title": title,
                            "description": description,
                            "targetdate": targetDate,
                            "tags": "",
                            "assignedto": assignedTo
                        })
                    }
                }}>Add</button>
            </div>

        </div>
    )
}

const CustomCard = (props: any) => {
    // console.log(props);
    return (
        <article data-id={props.id} className="react-trello-card task_card">
            <header className="task_header">
                <span draggable="true" className="task_title" onClick={props.onClick}>{props.title}</span>
                <span className="" onClick={props.onClick}></span>
                {
                    !props.showArchiveCards && props.board.lanes.find((item: any) => item.id === props.laneId)?.title === "Completed" &&
                    <div className="task_close">
                        <button className="btn p-0 btn-link" onClick={() => { props.onArchive(props.id, props.laneId, props) }}>Archive</button>
                    </div>
                }
                {
                    !props.showArchiveCards && (
                        (props.board.lanes.find((item: any) => item.id === props.laneId)?.title === "New Task"
                            || props.board.lanes.find((item: any) => item.id === props.laneId)?.title === "In-progress Task" ||
                            props.board.lanes.find((item: any) => item.id === props.laneId)?.title === "Inprogress Task" ||
                            props.board.lanes.find((item: any) => item.id === props.laneId)?.title === "Information Tab")) &&
                    <div className="task_close">
                        <button className="btn p-0 btn-link" onClick={props.onDelete}>Delete</button>
                    </div>
                }
            </header>
            <div className="" onClick={props.onClick}>
                <div className="mb-2">
                    {
                        (props.description.length > 100) ? props.description.substring(0, 100) + "..." : props.description
                    }
                </div>
                <div className="tags">
                    {
                        props.tags.split(',').map((item: any) => {
                            return (
                                <span className="badge bg-success me-1">{item}</span>
                            )
                        })
                    }
                </div>
                <div className="d-flex justify-content-between align-items-center task_asto_creadat mt-2">
                    <div className="task_asto">
                        {
                            props?.assignto?.map((item: any) => {
                                return (
                                    <div className="avatar flex-shrink-0 avatar-sm img-circle me-2">
                                        {item?.fname?.charAt(0)}{item?.lname.charAt(0)}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="task_creadat">
                        {moment(props.targetdate, 'YYYY-MM-DD').format('MMM DD, YYYY')}
                    </div>
                </div>

            </div>
        </article>
    )
}

const CardPopUp = (props: any) => {
    const { hideModal, cardDetail, handleEditCard, userData, board } = props;
    // console.log('cardDetail', props.cardDetail);
    const [title, setTitle] = useState(cardDetail.title);
    const [description, setDescription] = useState(cardDetail.description);
    const [assignedTo, setAssignedTo] = useState(cardDetail.assignto.length > 0 ? cardDetail.assignto[0].id : '');
    const [targetDate, setTargetDate] = useState(cardDetail.targetdate);
    const [errors, setErrors]: any = useState({});
    const [cardHistories, setCardHistories]: any = useState([]);
    const [cardDiscussions, setCardDiscussions]: any = useState([]);
    const [discussionText, setDiscussionText] = useState('');
    const [tab, setTab] = useState(0);
    let currentStatus = props.statuses.find((lane:any) => props.cardDetail.laneId === lane.value);
    if (currentStatus.label === "Created by me") {
        currentStatus = props.statuses.find((lane:any) => props.cardDetail.title === lane.label);
    }
    if (currentStatus.label === "Information Tab") {
        currentStatus = props.statuses.find((lane:any) => props.cardDetail.title === lane.label);
    }
    console.log("currentState", currentStatus, props.cardDetail, props.statuses);
    const [selectedStatus, setSelectedStatus]:any = useState(currentStatus);

    useEffect(() => {
        getCardHistory();
        getCardDiscussions();
    }, []);

    const getCardHistory = () => {
        db.collection(configs.TASK_MANAGER_HISTORY_COLLECTION).where('cardId', '==', cardDetail.id).orderBy("createdAt", 'desc').onSnapshot((snapshot) => {
            const data = snapshot.docs.map((item) => ({ ...item.data(), id: item.id }));
            setCardHistories(data);
        })
    }

    const getCardDiscussions = () => {
        db.collection(configs.TASK_MANAGER_DISCUSSION_COLLECTION).where('cardId', '==', cardDetail.id).orderBy("createdAt", 'desc').onSnapshot((snapshot) => {
            const data = snapshot.docs.map((item) => ({ ...item.data(), id: item.id }));
            setCardDiscussions(data);
        })
    }

    const handleChangeAssignedTo = (e: any) => {
        setAssignedTo(e.target.value);
    }
    const components = {
        DropdownIndicator: null,
    };
    const createOption = (label: string) => ({
        label,
        value: label,
    });
    const [inputValue, setInputValue]: any = useState('');
    const [value, setValue]: any = useState(cardDetail.tags !== "" ? cardDetail.tags.split(',').map((item: any) => ({ label: item, value: item })) : "");
    const handleChange = (value: any, actionMeta: any) => {
        setValue(value);
    };
    const handleInputChange = (inputValue: string) => {
        setInputValue(inputValue);
    };
    const handleKeyDown = (event: any) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setInputValue('');
                if (value) {
                    setValue([...value, createOption(inputValue)]);
                } else {
                    setValue([createOption(inputValue)]);
                }
                event.preventDefault();
            default:
                return;
        }
    };
    const handleSubmit = () => {
        const errors = validateAddCard({
            title, description, assignedTo, targetDate
        });
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            handleEditCard({
                "cardid": cardDetail.id,
                "columnid": selectedStatus && selectedStatus?.value ? selectedStatus?.value : cardDetail.laneId,
                "title": title,
                "desc": description,
                "targetdate": targetDate,
                "tags": value.length > 0 ? value.map((item: any) => item.value).toString() : '',
                "assignedto": assignedTo
            })
        }
    }

    const sendDiscussion = () => {
        if (discussionText === '') {
            return;
        }
        addIntoTaskManagerDiscussion({
            boardId: board.id,
            cardId: cardDetail.id,
            laneId: cardDetail.laneId,
            createdById: getCookie().xpr_user_id,
            createdByName: userData.firstName + ' ' + userData.lastName,
            text: discussionText
        })
        setDiscussionText('');
    }
    return (
        <Modal className="newcommon task-edit-popup" show={true} onHide={hideModal} centered>
            <Modal.Header>
                <div className="modal-title">{cardDetail.title}</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={hideModal}></button>
            </Modal.Header>
            <Modal.Body>

                <div className="row">
                    <div className="col-md-7 task_lft-part">
                        <div className="mb-2">
                            <label className="form-label">Title</label>
                            <input className="form-control form-control-sm" placeholder="Title" type="text" name="title" value={title} onChange={(e) => {
                                setTitle(e.target.value);
                            }} />
                            {
                                errors && errors.title && errors.title.error === true &&
                                <label className="text-danger">{errors.title.message}</label>
                            }
                        </div>

                        <div className="mb-2">
                            <label className="form-label">Description</label>
                            <textarea cols={5} rows={5} className="form-control form-control-sm" placeholder="Description"
                                name="description" value={description} onChange={(e) => {
                                    setDescription(e.target.value)
                                }}></textarea>
                            {
                                errors && errors.description && errors.description.error === true &&
                                <label className="text-danger">{errors.description.message}</label>
                            }
                        </div>

                        <div className="row mb-2">
                            <div className="col-6">
                                <div className="mb-2">
                                    <label className="form-label">Assigned To</label>
                                    <select className="form-select form-select-sm" value={assignedTo} name="assignedTo" onChange={handleChangeAssignedTo}>
                                        <option value={""} disabled>Select user from the list</option>
                                        {
                                            props.users.map((item: any) => {
                                                return (
                                                    <option value={item.id} key={item.id + "assign_user"}>{item.fname + " " + item.lname}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {
                                        errors && errors.assignedTo && errors.assignedTo.error === true &&
                                        <label className="text-danger">{errors.assignedTo.message}</label>
                                    }
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-2">
                                    <label className="form-label">Targeted Date</label>
                                    <input className="form-control form-control-sm" type="date" value={targetDate} onChange={(e) => {
                                        setTargetDate(e.target.value);
                                    }} />
                                    {
                                        errors && errors.targetDate && errors.targetDate.error === true &&
                                        <label className="text-danger">{errors.targetDate.message}</label>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="mb-2">
                            <label className="form-label">Status</label>
                            <Select
                                value={selectedStatus}
                                name="status"
                                isMulti={false}
                                options={props.statuses.filter((item: any) => item.label !== "Created by me" && item.label !== 'Information Tab')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e: any) => {
                                    setSelectedStatus(e);
                                }}
                            />
                        </div>

                        <div className="mb-2">
                            <label className="form-label">Tags</label>
                            <CreatableSelect
                                components={components}
                                inputValue={inputValue}
                                isClearable
                                isMulti
                                menuIsOpen={false}
                                onChange={handleChange}
                                onInputChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                placeholder="tags"
                                value={value}
                            />
                        </div>

                        <div className="w-100 d-flex align-items-center justify-content-between mt-4">
                            <div className="task_created_date">
                                <label><b>Created On:</b> {moment(cardDetail?.createdAt, 'YYYY-MM-DD LT').format('MMM DD, YYYY - LT')}</label>
                                <br />
                                <label><b>Created By:</b> {cardDetail.createdByFname + " " + cardDetail.createdByLname}</label>
                            </div>
                            <button className="btn btn-sm btn-primary" onClick={handleSubmit}>Submit</button>
                        </div>

                    </div>

                    <div className="col-md-5 task_rt-part">


                        <ul className="nav justify-content-start">
                            <li className="nav-item">
                                <a className={"nav-link " + (tab === 0 ? 'active' : '')} href="#" onClick={(e) => {
                                    setTab(0);
                                }}>Discussion</a>
                            </li>
                            <li className="nav-item">
                                <a className={"nav-link " + (tab === 1 ? 'active' : '')} href="#" onClick={(e) => {
                                    setTab(1);
                                }}>History</a>
                            </li>
                        </ul>
                        {
                            tab === 0 &&
                            <div className="mb-2">
                                <textarea className="form-control" placeholder="Add your comments" rows={2} value={discussionText} onChange={(e) => {
                                    setDiscussionText(e.target.value);
                                }}></textarea>
                                <div className="d-flex align-items-center justify-content-end mt-2">
                                    <button className="btn btn-sm btn-primary" onClick={sendDiscussion}>Add</button>
                                </div>
                            </div>
                        }
                        <div className="task_discu_list">
                            {
                                (tab === 0) && cardDiscussions.map((item: any) => {
                                    return (
                                        <div className="d-flex mb-3" key={item.id}>
                                            <div className="avatar flex-shrink-0 avatar-sm img-circle me-2">
                                                {item.createdByName.split(' ')[0].charAt(0)}{item.createdByName.split(' ')[1].charAt(0)}
                                            </div>
                                            <div className="task_discu">
                                                {item.text}
                                                <br />
                                                <div className="text-muted text-start small text-nowrap">
                                                    {
                                                        item &&
                                                        item.createdAt &&
                                                        <> {moment(moment.unix(item.createdAt.seconds)).format("MMM DD, YYYY - LT")}</>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                (tab === 1) && cardHistories.map((item: any) => {
                                    return (
                                        <div className="d-flex mb-3" key={item.id}>
                                            <div className="avatar flex-shrink-0 avatar-sm img-circle me-2">
                                                {item.createdByName.split(' ')[0].charAt(0)}{item.createdByName.split(' ')[1].charAt(0)}
                                            </div>
                                            <div className="task_discu">
                                                {item.text}
                                                <br />
                                                <div className="text-muted text-start small text-nowrap">
                                                    {
                                                        item &&
                                                        item.createdAt &&
                                                        <> {moment(moment.unix(item.createdAt.seconds)).format("MMM DD, YYYY - LT")}</>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export const ArchiveCardItem = (props: any) => {
    return (
        <article data-id={props.id} className="react-trello-card task_card border border-dark">
            <header className="task_header">
                <span draggable="true" className="task_title">{props.title}</span>
                <span className=""></span>
            </header>
            <div className="">
                <div className="mb-2">
                    {
                        (props.description.length > 100) ? props.description.substring(0, 100) + "..." : props.description
                    }
                </div>
                <div className="tags">
                    {
                        props.tags.split(',').map((item: any) => {
                            return (
                                <span className="badge bg-success me-1">{item}</span>
                            )
                        })
                    }
                </div>
                <div className="d-flex justify-content-between align-items-center task_asto_creadat mt-2">
                    <div className="task_asto">
                        {
                            props?.assignto?.map((item: any) => {
                                return (
                                    <div className="avatar flex-shrink-0 avatar-sm img-circle me-2">
                                        {item?.fname?.charAt(0)}{item?.lname.charAt(0)}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="task_creadat">
                        {moment(props.targetdate, 'YYYY-MM-DD').format('MMM DD, YYYY')}
                    </div>
                </div>

            </div>
        </article>
    )
}

export const ArchiveCardList = (props: any) => {
    const completedLane = props.board.lanes.find((item: any) => item.title === "Completed")
    return (
        <>

            <div className="w-full h-full">
                {/* <p><b>Archived Tasks</b></p> */}
                <div className="d-flex flex-row archived-container">
                    {
                        completedLane.cards.map((item: any) => {
                            return (
                                <div key={item.id + "card"} className="archived-item">
                                    <ArchiveCardItem {...item}></ArchiveCardItem>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}