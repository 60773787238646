import React,{useEffect} from 'react';
import { Route, Switch } from 'react-router-dom';
import Overview from './Overview';
import { SubscriptionHeader } from './SubscriptionHeader';
import SubscriptionPlansNew from './SubscriptionPlansNew';
import MyCards from './MyCards';
import GetPlan from './GetPlan';
import ManagePlans from './ManagePlans';
import Invoices from './Invoices';
import BillingAddress from './BillingAddress';
// import './_subscription.scss';
export const SubscriptionRoute = (props:any) => {
  useEffect(() => {
    if(props.location && props.location.state === undefined && props.location.pathname === "/subscription/upgrade/plan"){
      props.history.replace({pathname:'/subscription/overview'});
    }
  }, [props.location.pathname])
  return (
    <>
      <SubscriptionHeader></SubscriptionHeader>
      <Switch>
        <Route path="/subscription/overview" component={Overview}></Route>
        <Route path="/subscription/plans" component={SubscriptionPlansNew}></Route>
        <Route path="/subscription/manage-cards" component={MyCards}></Route>
        <Route path="/subscription/manage-plans" component={ManagePlans}></Route>
        <Route path="/subscription/invoices" component={Invoices}></Route>
        <Route path="/subscription/billing-address" component={BillingAddress}></Route>
      </Switch>
    </>
  )
}