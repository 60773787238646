import Axios from 'axios';
import { attachHeader } from '../../utils/apiHandler';
import configs from '../../configs/apiConfigs';
import { DataUsageSharp } from '@material-ui/icons';
import { CommonApiOptions, METHOD_TYPE } from '../constants/api';
import { commonApi } from '../helpers/api';

export const currentPlan = (clinicUniqueId: any) => {
    const url = configs.API_URL + 'prime/clinic' + '/' + clinicUniqueId + '/clinic-current-plans/';
    return Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
};

export const billInfoCardList = (clinicUniqueId: any) => {
    const url = configs.API_URL + 'prime/clinic' + '/' + clinicUniqueId + '/billing-subsciption/';
    return Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}

export const addCard = (clinicUniqueId: any, formData: any) => {
    const url = configs.API_URL + '/user/card/';
    return Axios.post(url, formData, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}
export const removeCard = (clinicUniqueId: any, Id: any) => {
    const url = configs.API_URL + '/user/card/remove/' + Id + '/';
    return Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}

export const createBillInfo = (clinicUniqueId: any, data: any) => {
    const url = configs.API_URL + 'prime/clinic' + '/' + clinicUniqueId + '/create-billing-subsciption/';
    return Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}

export const updateBillInfo = (clinicUniqueId: any, data: any) => {
    const url = configs.API_URL + 'prime/clinic' + '/' + clinicUniqueId + '/update-billing-subsciption/';
    return Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}
export const deleteBillInfo = (clinicUniqueId: any, data: any) => {
    const url = configs.API_URL + 'prime/clinic' + '/' + clinicUniqueId + '/delete-billing-subsciption/';
    return Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}
export const billInfoStatusChange = (clinicUniqueId: any, data: any) => {
    const url = configs.API_URL + 'prime/clinic' + '/' + clinicUniqueId + '/change-status-subsciption/';
    return Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}
export const checkCouponCode = (clinicUniqueId: any, data: any) => {
    const url = configs.API_URL + "prime/clinic/" + clinicUniqueId + "/check-coupon1/";
    return Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}

export const removeCouponCode = (clinicUniqueId: any, data: any) => {
    const url = configs.API_URL + "prime/clinic/" + clinicUniqueId + "/remove-coupon/";
    return Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}

export const getCountry = async (clinicUniqueId: any) => {
    const url = configs.API_URL + "prime/clinic/" + clinicUniqueId + "/billing-country/";
    return await Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}

export const getState = async (clinicUniqueId: any, data: any) => {
    const url = configs.API_URL + "prime/clinic/" + clinicUniqueId + "/billing-state/";
    return await Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}
export const getcity = async (clinicUniqueId: any, data: any) => {
    const url = configs.API_URL + "prime/clinic/" + clinicUniqueId + "/billing-city/";
    return await Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}

export const getInvoiceList = async (clinicUniqueId: any) => {
    const url = configs.API_URL + "prime/clinic/" + clinicUniqueId + "/invoice-detail/";
    return await Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}

export const createBasicPlan = (clinicId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'prime/clinic/' + clinicId + '/basic-plan-create/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const renewPlan = async (clinicUniqueId: any, data: any) => {
    const url = configs.API_URL + "prime/clinic/" + clinicUniqueId + "/renew-subsciption/";
    return await Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}