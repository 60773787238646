import config from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getProviderList = (clinicId: string) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'clinic/' + clinicId + '/providers/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}
export const changeStatus = (body: any, providerId: any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'doctor/' + providerId + '/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: body
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const getProviderProfile = (providerId: any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'doctor/' + providerId + '/profile/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const uploadLogoDoctor = (providerId: string, data: any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'doctor/' + providerId + '/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.FORM_DATA,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const updateProviderProfile = (updateAttribute: string, providerId: string, data: any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'doctor/' + providerId + '/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: {
                type: updateAttribute,
                [`${updateAttribute}`]: data
            }
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
};

export const acceptNewPatientChange = (providerId: any, data: any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'doctor/' + providerId + '/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const shareCalendarChange = (providerId: any, data: any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'doctor/' + providerId + '/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const blockTimeSlot = (providerId: any, data: any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'doctor/' + providerId + '/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const signInInfoUpdate = (providerId: any, data: any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'doctor/' + providerId + '/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}