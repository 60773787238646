// import './_practiceSteps.scss';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { createStep, getInsuranceList, getSocialAssistanceList } from '../../services/clinicOnboard';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import RemoveBlue from "../../assets/images/remove-blue.svg";
export function PracticeApp7(props: any) {
	const [insuranceAcceptType, setInsuranceAcceptType] = useState("all");
	const [selectedInsurance, setSelectedInsurace]: any = useState([]);
	const [availableInsurance, setAvailableInsurace]: any = useState([]);
	const [selectedSearchInsurance, setSelectedSearchInsurance] = useState(null);

	const [acceptSocialAssistance, setSocialAssistance] = useState("no");
	const [assistanceTypes, setAssistanceTypes]: any = useState([]);
	const [selectedAssistance, setSelectedAssistance] = useState(null);
	const dispatch = useDispatch();
	const [assistanceSelected, setAssistanceSelected]: any = useState([]);
	const [assistanceAcceptType, setAssistanceAcceptType]: any = useState('');
	const insuranceTypeChange = (type: string) => {
		if (type === 'all' || type === 'no') {
			setSelectedInsurace([]);
		}
		setInsuranceAcceptType(type);
	}
	const addInsurance = () => {
		if (selectedSearchInsurance !== null) {
			//@ts-ignore
			if (!(selectedInsurance.find((item: any) => item.value === selectedSearchInsurance.value))) {
				setSelectedInsurace([...selectedInsurance, selectedSearchInsurance]);
			}
		}
	};
	const onNext = () => {
		const request = {
			insurance: {
				insuranceAccept: insuranceAcceptType,
				selectedInsurances: selectedInsurance.map((item: any) => ({ id: item.value, name: item.label }))
			},
			socialAssistance: {
				accept: acceptSocialAssistance,
				selectedAssistance: assistanceSelected.map((item: any) => ({ id: item.value, title: item.label })),
				assistanceAcceptType: assistanceAcceptType
			}
		};
		if (selectedAssistance !== null) {
			const requestData = {
				uniqueid: props.clinicUniqueId,
				insuranceAccept: insuranceAcceptType,
				insurance: selectedInsurance.map((item: any) => item.value).toString(),
				socialAssist: acceptSocialAssistance,
				//@ts-ignore
				socialAssistType: assistanceSelected.map((item: any) => item.value).toString(),
				socialAssistTypeAccept: assistanceAcceptType

			};
			dispatch(fetchLoadingPending());
			createStep({
				stepNumber: 9,
				formData: requestData
			}).then((success) => {
				dispatch(fetchLoadingSuccess(false));
				if (success.status && success.status.error === false) {
					props.onNext(9, { insurance: request });
				}
			}).catch((err) => {
				dispatch(fetchLoadingSuccess(false));
			});
		}
	}
	useEffect(() => {
		dispatch(fetchLoadingPending());
		getInsuranceList().then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				const mapped = success.insurance.map((item: any) => ({ value: item.id, label: item.name }));
				setAvailableInsurace(mapped);
				if (success.insurance.length > 0) {
					if (props.data && props.data.insurance && props.data.insurance.selectedInsurances) {
						// setSelectedInsurace(props.data.insurance.selectedInsurances.map((item: any) => ({ value: item.id, label: item.name })));
					}
					setSelectedSearchInsurance(mapped[0]);
				}
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});

		getSocialAssistanceList().then((success) => {
			dispatch(fetchLoadingPending());
			if (success.status && success.status.error === false) {
				dispatch(fetchLoadingSuccess(false));
				const mapped = success.types.map((item: any) => ({ value: item.id, label: item.title }));
				setAssistanceTypes(mapped);
				if (success.types.length > 0) {
					if (props.data && props.data.socialAssistance && props.data.socialAssistance.selectedAssistance) {
						// const found = mapped.find((item: any) => item.value === props.data.socialAssistance.selectedAssistance);
						// setSelectedAssistance(found);
					}
					setSelectedAssistance(mapped[0]);
				}
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});
	}, []);
	useEffect(() => {
		if (props.data) {
			setInsuranceAcceptType(props.data.insurance.insuranceAccept);
			if (Array.isArray(props.data.insurance.selectedInsurances)) {
				setSelectedInsurace(props.data.insurance.selectedInsurances.map((item: any) => ({ value: item.id, label: item.name })));
			}
			setSocialAssistance(props.data.socialAssistance.accept);
			if (Array.isArray(props.data.socialAssistance.selectedAssistance)) {
				setAssistanceSelected(props.data.socialAssistance.selectedAssistance.map((item: any) => ({ value: item.id, label: item.title })));
			}
			setAssistanceAcceptType(props.data.socialAssistance.assistanceAcceptType);
			// setSelectedAssistance(props.data.socialAssistance.selectedAssistance);
		}
	}, [props.data]);
	const socialAssitanceTypeChange = (type: string) => {
		if (type == 'all') {
			setAssistanceSelected([]);
		}
		setAssistanceAcceptType(type);
	};
	const addSocialAssistance = () => {
		if (selectedAssistance !== null) {
			//@ts-ignore
			if (!(assistanceSelected.find((item: any) => item.value === selectedAssistance.value))) {
				setAssistanceSelected([...assistanceSelected, selectedAssistance]);
			}
		}
	}
	return (

		<>
			<div className="row justify-content-center">
				<div className="col-12">
					<h2 className="mb-3">Insurance</h2>
					<div className="fw-medium fs-5">Which insurance companies do you accept?</div>
					<p className="mt-1">Please select one of the following:</p>
					<hr className="my-4" />
				</div>


				<div className="col-12">
					<div className="d-flex insurance-type mb-4">
						<div className="form-check">
							<input className="form-check-input" type="radio" name="insuranceType" id="AllInsurance" value="all" checked={insuranceAcceptType === "all"}
								onChange={(e) => { insuranceTypeChange(e.target.value) }}
							/>
							<label className="form-check-label" htmlFor="AllInsurance">
								All Insurance
							</label>
						</div>
						<div className="form-check ms-md-4 ms-2">
							<input className="form-check-input" type="radio" name="insuranceType" id="SpecificCompanies" value="specific" checked={insuranceAcceptType === "specific"}
								onChange={(e) => { insuranceTypeChange(e.target.value) }}
							/>
							<label className="form-check-label" htmlFor="SpecificCompanies">
								Specific companies
							</label>
						</div>
						<div className="form-check ms-md-4 ms-2">
							<input className="form-check-input" type="radio" name="insuranceType" id="noInsurance" value="no" checked={insuranceAcceptType === "no"}
								onChange={(e) => { insuranceTypeChange(e.target.value) }}
							/>
							<label className="form-check-label" htmlFor="noInsurance">
								No insurance
							</label>
						</div>
					</div>
					{
						selectedInsurance.length > 0 &&
						<div className="insurance-list d-flex flex-wrap mb-4">
							{
								selectedInsurance.map((item: any, index: number) => {
									return (
										<div className="badge border rounded-pill" key={item.value}>
											<span>{item.label}</span>
											<button className="btn py-0 pe-0" onClick={() => {
												setSelectedInsurace(selectedInsurance.filter((iItem: any, iIndex: number) => {
													return iIndex !== index;
												}));
											}}>
												<img src={RemoveBlue} />
											</button>
										</div>
									)
								})
							}
						</div>
					}
				</div>
				{
					insuranceAcceptType === "specific" &&
					<div className="col-12 mb-4">
						<label htmlFor="searchInsurance" className="form-label">Search insurance</label>
						<div className="input-group">
							{/* <input type="text" className="form-control rounded" id="searchInsurance" placeholder="Manulife" /> */}
							<Select
								className="w-50 searchNewIns"
								classNamePrefix="select"
								isSearchable={true}
								name="insuranceSearch"
								value={selectedSearchInsurance}
								options={availableInsurance}
								onChange={(value: any) => { setSelectedSearchInsurance(value) }}
							/>
							<button className="btn btn-primary fw-normal ms-3 rounded" type="button" onClick={() => { addInsurance(); }}>
								<i className="bi bi-plus"></i> Add</button>
						</div>
						<small className="text-muted">This data is pulled from our insurance database.</small>
					</div>
				}
				<div className="col-12 mt-5">
					<div className="fw-medium fs-5">Social Assistance Programs </div>
					<p>e.g. Social assistance, WSIB, MVA, OHIP.</p>
					<hr />
					<div>Do you accept all social assistance programs? </div>
					<div className="d-flex mt-3">
						<div className="form-check">
							<input className="form-check-input" type="radio" name="socialAssistance" id="socialAssistanceYes" value={"yes"}
								checked={acceptSocialAssistance === "yes"} onChange={() => {
									setSocialAssistance("yes");
									setAssistanceSelected([]);
									setAssistanceAcceptType('all');
								}} />
							<label className="form-check-label text-muted" htmlFor="socialAssistanceYes">
								Yes
							</label>
						</div>
						<div className="form-check ms-4">
							<input className="form-check-input" type="radio" name="socialAssistance" id="socialAssistanceNo" value={"no"}
								checked={acceptSocialAssistance === "no"}
								onChange={() => {
									setSocialAssistance("no");
								}}
							/>
							<label className="form-check-label text-muted" htmlFor="socialAssistanceNo">
								No
							</label>
						</div>
					</div>
					{
						acceptSocialAssistance === 'yes' &&
						<div className="d-flex mt-3">
							<div className="d-flex insurance-type mb-4">
								<div className="form-check">
									<input className="form-check-input" type="radio" name="assistanceType" id="allProgram" value="all" checked={assistanceAcceptType === "all"}
										onChange={(e) => { socialAssitanceTypeChange(e.target.value) }}
									/>
									<label className="form-check-label" htmlFor="allProgram">
										All programs
									</label>
								</div>
								<div className="form-check ms-4">
									<input className="form-check-input" type="radio" name="assistanceType" id="SpecificProgram" value="specific" checked={assistanceAcceptType === "specific"}
										onChange={(e) => { socialAssitanceTypeChange(e.target.value) }}
									/>
									<label className="form-check-label" htmlFor="SpecificProgram">
										Specific program
									</label>
								</div>
							</div>
						</div>
					}
					{
						acceptSocialAssistance === "yes" && assistanceSelected.length > 0 &&
						<div className="insurance-list d-flex flex-wrap mb-4">
							{
								assistanceSelected.map((item: any, index: number) => {
									return (
										<div className="badge border rounded-pill" key={item.value}>{item.label}
											<button className="btn py-0 pe-0" onClick={() => {
												setAssistanceSelected(assistanceSelected.filter((iItem: any, iIndex: number) => {
													return iIndex !== index;
												}));
											}}>
												<img src={RemoveBlue} />
											</button>
										</div>
									)
								})
							}
						</div>
					}
					{
						acceptSocialAssistance === "yes" && assistanceAcceptType === "specific" &&
						<div className="col-8 mb-4">
							<label className="form-label d-block">Select type</label>
							<div className="input-group">
								<Select
									className="w-md-50"
									name="assistance-select"
									options={assistanceTypes}
									value={selectedAssistance}
									onChange={(value: any) => { setSelectedAssistance(value) }}
								></Select>
								<button className="btn btn-primary fw-normal ms-3 rounded" type="button" onClick={() => { addSocialAssistance(); }}>
									<i className="bi bi-plus"></i> Add</button>
							</div>
						</div>
					}
				</div>

				<div className="col-12 my-4">
					<div className="bottom-btn-sec d-flex justify-content-between">
						<button className="btn border me-1" onClick={() => { props.onPrev(7) }}>Back</button>
						<button className="btn btn-primary ms-1" disabled={((insuranceAcceptType === "specific" && selectedInsurance.length === 0)
							|| (assistanceAcceptType === "specific" && assistanceSelected.length === 0))} onClick={() => { onNext() }}>Next</button>
					</div>
				</div>

			</div>
		</>

	);
}
