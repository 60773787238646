import { takeEvery } from 'redux-saga/effects';
import { fetchProfileSuccess, fetchProfileError, fetchQuestionSuccess, fetchQuestionError, fetchFeatureError, fetchFeatureSuccess, fetchLanguageSuccess, fetchLanguageError, descriptionUpdateSuccess, descriptionUpdateError, imageRemoveError, imageRemoveSuccess, fetchImageUploadError, fetchImageUploadSuccess, emailVerificationSuccessAction, emailVerificationErrorAction, fetchHygieneFeatureSuccess, fetchHygieneFeatureError } from './../store/profile/actions';
import { put } from 'redux-saga/effects';
import { ProfileRequest, FetchProfilePendingAction, PROFILE, QuestionRequest, FetchQuestionPendingAction, FeatureRequest, FetchFeaturePendingAction, LanguageRequest, FetchLanguagePendingAction, ProfileUpdatePendingAction, ProfileUpdateRequest, ImageRemoveRequest, ImageRemovePendingAction, ImageUploadRequest, ImageUploadPendingAction, EmailVerificationPendingAction, EmailVerificationRequest, HygieneFeatureRequest, HygieneFeaturePendingAction } from './../store/profile/types';
import Axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import { fetchLoadingPending, fetchLoadingSuccess } from '../store/loadingIndicator/actions';
import { attachHeader } from '../utils/apiHandler';
import { takeOnlyDigitAndPlus } from '../utils/global';
import { locationChange } from '../store/userData/actions';
function execute(request: ProfileRequest) {
    return Axios.get(CONFIG.API_URL + request.type + "/" + request.uniqueId + "/profile/",
        { headers: attachHeader(true), withCredentials: true }
    ).then(response => ({ response }), error => ({ error }));
}
function* profilePendingRun(action: FetchProfilePendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(fetchProfileError(response));
            } else {
                yield put(fetchProfileSuccess(response.data));
            }
        } else {
            yield put(fetchProfileError(response));
        }
    }
    if (error) {
        yield put(fetchProfileError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export default function* profilePendingWatcher() {
    yield takeEvery(PROFILE.LOAD_PENDING, profilePendingRun);
}

function executeQuestion(request: QuestionRequest) {
    const body = {
        modelType: request.modelType
    };
    return Axios.post(CONFIG.API_URL + "questions/", body).then(response => ({ response }), error => ({ error }));
}
function* questionPendingRun(action: FetchQuestionPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeQuestion(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(fetchQuestionError(response));
            } else {
                yield put(fetchQuestionSuccess(response.data));
            }
        } else {
            yield put(fetchQuestionError(response));
        }
    }
    if (error) {
        yield put(fetchQuestionError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* questionPendingWatcher() {
    yield takeEvery(PROFILE.QUESTION_PENDING, questionPendingRun);
}

// Feature
function executeFeature(request: FeatureRequest) {
    const body = {
        speciality: request.speciality
    };
    return Axios.post(CONFIG.API_URL + "features/", body).then(response => ({ response }), error => ({ error }));
}
function* featurePendingRun(action: FetchFeaturePendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeFeature(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(fetchFeatureError(response));
            } else {
                yield put(fetchFeatureSuccess(response.data));
            }
        } else {
            yield put(fetchFeatureError(response));
        }
    }
    if (error) {
        yield put(fetchFeatureError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* featurePendingWatcher() {
    yield takeEvery(PROFILE.FEATURE_PENDING, featurePendingRun);
}

function executeLanguage(request: LanguageRequest) {
    return Axios.get(CONFIG.API_URL + "languages/").then(response => ({ response }), error => ({ error }));
}
function* languagePendingRun(action: FetchLanguagePendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeLanguage(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(fetchLanguageError(response));
            } else {
                yield put(fetchLanguageSuccess(response.data));
            }
        } else {
            yield put(fetchLanguageError(response));
        }
    }
    if (error) {
        yield put(fetchLanguageError(error));
    }
    yield put(fetchLoadingSuccess(false));
}

export function* laguagePendingWatcher() {
    yield takeEvery(PROFILE.LANGUAGE_PENDING, languagePendingRun);
}

// Update Description
function executeUpdateDescription(request: ProfileUpdateRequest) {
    const body = {
        type: request.type
    };
    if (request.type === 'description') { Object.assign(body, { description: request.description }); }
    else if (request.type === 'assignment') { Object.assign(body, { assignment: request.assignment }); }
    else if (request.type === 'email') { Object.assign(body, { email: request.email }); }
    else if (request.type === 'website') { Object.assign(body, { website: request.website }); }
    else if (request.type === 'question') {
        //@ts-ignore
        delete request.question?.questionIndex;
        Object.assign(body, { question: request.question });
    }
    else if (request.type === 'features') {
        const featureArrayId = request.features?.map((value, index) => {
            return value.id;
        });
        Object.assign(body, { features: featureArrayId });
    } else if (request.type === 'hygieneFeatures') {
        const featureArrayId = request.HygieneFeatures?.map((value, index) => {
            return value.id;
        });
        Object.assign(body, { hygieneFeatures: featureArrayId });
    }
    else if (request.type === 'hours') {
        Object.assign(body, { hours: request.hours });
    }
    else if (request.type === 'phone') {
        Object.assign(body, { phone: takeOnlyDigitAndPlus("" + request.phone) });
    }
    else if (request.type === 'gender') {
        Object.assign(body, { gender: request.gender });
    } else if (request.type === 'certificate') {
        Object.assign(body, {
            certificate: {
                name: request.boardCertificate?.edtAssociateName,
                date: request.boardCertificate?.edtDate,
                registrationNumber: request.boardCertificate?.edtRegistrationNumber
            }
        });
    } else if (request.type === 'education') {
        Object.assign(body, {
            education: {
                degree: request.education?.edtDegree,
                college: request.education?.edtUniversity,
                year: request.education?.edtYear
            }
        });
    } else if (request.type === 'removeCertificate') {
        Object.assign(body, {
            removeCertificate: request.removeCertificate
        });
    } else if (request.type === 'removeEducation') {
        Object.assign(body, {
            removeEducation: request.removeEducation
        });
    } else if (request.type === 'languages') {
        const requestedArray = request.languages?.map((value: any, index) => {
            return value.value;
        });
        Object.assign(body, { languages: requestedArray });
    } else if (request.type === 'location') {
        body.type = 'address';
        Object.assign(body, { address: request.location });
    }
    return Axios.post(CONFIG.API_URL + request.userType + "/" + request.uniqueId + "/", body, { headers: attachHeader(true) }).then(response => ({ response }), error => ({ error }));
}
function* updateDescriptionPendingRun(action: ProfileUpdatePendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeUpdateDescription(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(descriptionUpdateError(response.data));
            } else {
                const updateData = {};
                if (action.payload.type === 'description') Object.assign(updateData, { description: action.payload.description });
                else if (action.payload.type === 'assignment') Object.assign(updateData, { assignment: action.payload.assignment });
                else if (action.payload.type === 'email') Object.assign(updateData, { email: action.payload.email });
                else if (action.payload.type === 'website') Object.assign(updateData, { website: action.payload.website });
                else if (action.payload.type === 'question') {
                    Object.assign(updateData, {
                        questionResponse: action.payload.question
                    });
                }
                else if (action.payload.type === 'features') {
                    Object.assign(updateData, {
                        features: action.payload.features
                    });
                } else if (action.payload.type === 'hygieneFeatures') {
                    Object.assign(updateData, {
                        HygieneFeatures: action.payload.HygieneFeatures
                    });
                }
                else if (action.payload.type === 'hours') {
                    Object.assign(updateData, {
                        hours: action.payload.hours
                    });
                }
                else if (action.payload.type === 'phone') {
                    Object.assign(updateData, {
                        phone: takeOnlyDigitAndPlus("" + action.payload.phone)
                    });
                } else if (action.payload.type === 'gender') {
                    Object.assign(updateData, {
                        gender: action.payload.gender
                    });
                } else if (action.payload.type === 'certificate') {
                    Object.assign(updateData, {
                        certificate: response.data.certificate
                    });
                } else if (action.payload.type === 'education') {
                    Object.assign(updateData, {
                        education: response.data.education
                    });
                } else if (action.payload.type === 'removeCertificate') {
                    Object.assign(updateData, {
                        removeCertificate: action.payload.removeCertificate
                    });
                } else if (action.payload.type === 'removeEducation') {
                    Object.assign(updateData, {
                        removeEducation: action.payload.removeEducation
                    });
                } else if (action.payload.type === 'languages') {
                    action.payload.languages = action.payload.languages?.map((value, index) => {
                        return { id: value.value, name: value.label };
                    })
                    Object.assign(updateData, {
                        languages: action.payload.languages
                    });
                } else if (action.payload.type === 'location') {
                    Object.assign(updateData, {
                        address: response.data.address
                    });
                }
                yield put(descriptionUpdateSuccess({ ...response.data, udpateData: updateData, userType: action.payload.userType, type: action.payload.type }));
                if (action.payload.type === 'location') {
                    yield put(locationChange({ location: response.data.address, uniqueId: action.payload.uniqueId, userType: action.payload.userType }));
                }
            }
        } else {
            yield put(descriptionUpdateError(response));
        }
    }
    if (error) {
        yield put(descriptionUpdateError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* updateDescriptionPendingWatcher() {
    yield takeEvery(PROFILE.PROFILE_UPDATE_PENDING, updateDescriptionPendingRun);
}

//Image remove profile
function executeImageRemove(request: ImageRemoveRequest) {
    return Axios.post(CONFIG.API_URL + request.userType + "/"
        + request.uniqueId + "/remove/photo/", request.image,
        { headers: attachHeader(true) })
        .then(response => ({ response }), error => ({ error }));
}
function* imageRemovePendingRun(action: ImageRemovePendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeImageRemove(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(imageRemoveError(response));
            } else {
                yield put(imageRemoveSuccess({ ...response.data, id: action.payload.image.id }));
            }
        } else {
            yield put(imageRemoveError(response));
        }
    }
    if (error) {
        yield put(imageRemoveError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* imageRemoveWatcher() {
    yield takeEvery(PROFILE.IMAGE_REMOVE_PENDING, imageRemovePendingRun);
}

function executeUploadPhoto(request: ImageUploadRequest) {
    const formData = new FormData();
    formData.append('type', request.type);
    formData.append('uphoto', request.uphoto);
    return Axios.post(CONFIG.API_URL + request.userType + "/" + request.uniqueId + "/upload/photo/", formData, { headers: attachHeader(true) })
        .then(response => ({ response }), error => ({ error }));
}
function* userUploadPhotoPendingRun(action: ImageUploadPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeUploadPhoto(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(fetchImageUploadError(response.data));
            } else {
                yield put(fetchImageUploadSuccess(response.data));
            }
        } else {
            yield put(fetchImageUploadError(response));
        }
    }
    if (error) {
        yield put(fetchImageUploadError(error));
    }
    yield put(fetchLoadingSuccess(false));
}

export function* imageUploadPhotoPendingWatcher() {
    yield takeEvery(PROFILE.IMAGE_UPLOAD_PENDING, userUploadPhotoPendingRun);
}
function executeEmailVerification(request: EmailVerificationRequest) {
    const body = {
        email: request.email
    };
    return Axios.post(CONFIG.API_URL + 'verify/' + request.modelType + "/" + request.modelUniqueid + "/", body, {
        headers: attachHeader(true, true),
        withCredentials: true,
    }).then(response => ({ response }), error => ({ error }));
}
export function* emailVerificationRun(action: EmailVerificationPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeEmailVerification(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(emailVerificationErrorAction(response.data));
            } else {
                yield put(emailVerificationSuccessAction(response.data));
            }
        } else {
            yield put(emailVerificationErrorAction(response));
        }
    }
    if (error) {
        yield put(emailVerificationErrorAction(error));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* emailVerificationPendingWatcher() {
    yield takeEvery(PROFILE.EMAIL_VERIFICATION_PENDING, emailVerificationRun);
}


function executeHygieneFeature(request: HygieneFeatureRequest) {
    return Axios.get(CONFIG.API_URL + "hygienes/").then(response => ({ response }), error => ({ error }));
}
function* hygieneFeaturePendingRun(action: HygieneFeaturePendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeHygieneFeature(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(fetchHygieneFeatureError(response));
            } else {
                yield put(fetchHygieneFeatureSuccess(response.data));
            }
        } else {
            yield put(fetchHygieneFeatureError(response));
        }
    }
    if (error) {
        yield put(fetchHygieneFeatureError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* hygieneFeaturePendingWatcher() {
    yield takeEvery(PROFILE.HYGIENE_FEATURE_PENDING, hygieneFeaturePendingRun);
}