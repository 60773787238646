import {
    ADD_PROVIDER, ProviderAddRequest, ProviderAddPendingAction, ProviderAddResponse,
    ProviderAddSuccessAction, ProviderAddErrorAction, FetchSpecialityResponse, FetchSpecialityPendingAction, FetchSpecialitySuccessAction, FetchSpecialityErrorAction,
} from './types';
/* Action Creators */
export const providerAddPending = (request: ProviderAddRequest): ProviderAddPendingAction => {
    return {
        type: ADD_PROVIDER.ADD_PROVIDER_PENDING,
        payload: request
    };
};

export const providerAddSuccess = (response: ProviderAddResponse): ProviderAddSuccessAction => {
    return {
        type: ADD_PROVIDER.ADD_PROVIDER_SUCCESS,
        providerAdd: response
    }
}

export const providerAddError = (err: any): ProviderAddErrorAction => {
    return {
        type: ADD_PROVIDER.ADD_PROVIDER_ERROR,
        error: err
    }
}

export const fetchSpecialityPending = (): FetchSpecialityPendingAction => {
    return {
        type: ADD_PROVIDER.FETCH_SPECIALITY_PENDING,
    };
};

export const fetchSpecialitySuccess = (response: FetchSpecialityResponse): FetchSpecialitySuccessAction => {
    return {
        type: ADD_PROVIDER.FETCH_SPECIALITY_SUCCESS,
        speciality: response
    }
}

export const fetchSpecialityError = (err: any): FetchSpecialityErrorAction => {
    return {
        type: ADD_PROVIDER.FETCH_SPECIALITY_ERROR,
        error: err
    }
}