import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { updateProviderProfile } from '../../services/provider';
export const Speciality = ({ specialityList, speciality, providerId, fetchProfile }: any) => {
    const [specialities, setSpecialities]: any = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setSpecialities(specialityList.map((item: any) => {
            if (speciality.find((sItem: any) => sItem.id === item.id)) {
                return {
                    ...item,
                    checked: true
                }
            }
            return {
                ...item,
                checked: false
            };
        }));
    }, [specialityList, speciality]);
    const onSave = () => {
        const specialitiesSelected = specialities.filter((item: any) => item.checked).map((item: any) => { return item.id; }).toString();
        if (specialitiesSelected === "") {
            toast.warn('Atleast one service required.');
            return;
        }
        setIsLoading(true);
        updateProviderProfile('speciality', providerId, specialitiesSelected).then((success) => {
            if (success && success.status && success.status.error === false) {
                setIsLoading(false);
                setIsEdit(false);
                fetchProfile('speciality');
            }
        }).catch((err) => {
        })
    }
    const onCancel = () => {
        setIsEdit(false);
        setSpecialities(specialityList.map((item: any) => {
            if (speciality.find((sItem: any) => sItem.id === item.id)) {
                return {
                    ...item,
                    checked: true
                }
            }
            return {
                ...item,
                checked: false
            };
        }))
    }
    return (
        <div className="practice-speciality-sec mb-4">
            <div className="card">
                <div className="card-header bg-light py-3 px-4">
                    <div className="d-flex justify-content-between">
                        <div className="fw-medium">Provider speciality</div>
                        <div>
                            {
                                !isEdit && !isLoading &&
                                <button className="btn p-0 btn-link" onClick={(e) => { setIsEdit(true) }}>Edit</button>
                            }
                            {
                                isEdit && !isLoading &&
                                <>
                                    <button className="btn p-0 btn-link me-2 text-secondary" onClick={(e) => { onCancel() }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                    <button className="btn p-0 btn-link" onClick={(e) => { onSave() }}><i className="bi bi-check-circle-fill text-success"></i></button>
                                </>
                            }
                            {
                                isLoading &&
                                <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    <div className={"features-check-list custom-check-list d-flex flex-wrap " + (!isEdit ? 'hidden-input' : '')}>
                        {
                            specialities.map((item: any, index: number) => {
                                return (
                                    <div className="form-check" key={item.id}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={item.checked}
                                            value={item.id}
                                            disabled={!isEdit}
                                            id={"speciality" + item.id}
                                            onChange={(e) => {
                                                setSpecialities(specialities.map((sItem: any) => {
                                                    if (sItem.id === item.id) {
                                                        return {
                                                            ...sItem,
                                                            checked: sItem.checked ? false : true
                                                        }
                                                    }
                                                    return sItem;
                                                }));
                                            }}
                                        />
                                        <label className="form-check-label rounded-2 border w-100" htmlFor={"speciality" + item.id}>
                                            {item.name}
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};