import { EmployeeState, EmployeeActionTypes, EMPLOYEE } from "./types";

const initialState: EmployeeState = {
    error: null,
    pending: false
}
const employeeReducer = (state = initialState, action: EmployeeActionTypes): EmployeeState => {
    switch (action.type) {
        case EMPLOYEE.ADD_EMPLOYEE:
            return {
                ...state,
                pending: true,
                error: null,
                addEmployeeRequest: action.payload,
                addEmployeeResponse: undefined
            };
        case EMPLOYEE.ADD_EMPLOYEE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.payload,
                addEmployeeRequest: undefined,
                addEmployeeResponse: undefined
            }
        case EMPLOYEE.ADD_EMPLOYEE_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                addEmployeeRequest: undefined,
                addEmployeeResponse: action.payload,
                employeeListResponse: state.employeeListResponse ? {
                    ...state.employeeListResponse,
                    employees: [...state.employeeListResponse.employees, action.payload.employees]
                } : undefined
            }
        case EMPLOYEE.EMPLOYEE_LIST:
            return {
                ...state,
                pending: true,
                error: null,
                employeeListRequest: action.payload
            }
        case EMPLOYEE.EMPLOYEE_LIST_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.payload,
                employeeListRequest: undefined,
                employeeListResponse: undefined
            }
        case EMPLOYEE.EMPLOYEE_LIST_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                employeeListResponse: action.payload
            }
        case EMPLOYEE.DELETE_EMPLOYEE:
            return {
                ...state,
                pending: true,
                error: null,
                deleteEmployeeRequest: action.payload
            }
        case EMPLOYEE.DELETE_EMPLOYEE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case EMPLOYEE.DELETE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                deleteEmployeeResponse: action.payload,
                employeeListResponse: state.employeeListResponse ? {
                    ...state.employeeListResponse,
                    employees: state.employeeListResponse.employees.filter((value) => { return value.workid !== action.payload.workId })
                } : undefined
            }
        case EMPLOYEE.EMPLOYEE_PRESCREENING:
            return {
                ...state,
                pending: true,
                error: null,
                employeePrescreeningRequest: action.payload,
                employeePrescreeningResponse: undefined
            }
        case EMPLOYEE.EMPLOYEE_PRESCREENIN_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.payload,
                employeePrescreeningRequest: undefined,
                employeePrescreeningResponse: undefined
            }
        case EMPLOYEE.EMPLOYEE_PRESCREENIN_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                employeePrescreeningRequest: undefined,
                employeePrescreeningResponse: action.payload
            }
        case EMPLOYEE.EMPLOYEE_INIT:
            return {
                ...state,
                pending: false,
                error: null,
                employeeListRequest: undefined,
                employeeListResponse: undefined,
                addEmployeeRequest: undefined,
                addEmployeeResponse: undefined,
                deleteEmployeeRequest: undefined,
                deleteEmployeeResponse: undefined,
                employeePrescreeningRequest: undefined,
                employeePrescreeningResponse: undefined,
            };
        default:
            return state;
    }
}
export default employeeReducer;