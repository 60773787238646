import * as _ from 'lodash';
const initialState: any = {
    data: []
}

const storeChatReducer = (state = initialState, action: any): any => {
    if (action.type === 'CHAT_DATA') {
        const dataUniq = _.uniqBy([...state.data, action.payload], function (item) { return item.appointmentId; });
        return {
            ...state,
            data: dataUniq.map((item) => {
                if (item.appointmentId === action.payload.appointmentId) {
                    return {
                        ...item,
                        openPin: action.payload.openPin ? true : false
                    };
                }
                return {
                    ...item,
                    openPin: false
                }
            })
        }
    } else if (action.type === 'REMOVE_PIN') {
        const processedData = _.unionBy([...state.data].filter((item) => item.appointmentId !== action.payload));
        return {
            ...state,
            data: processedData
        }
    } else if (action.type === 'CLOSE_PIN') {
        return {
            ...state,
            data: state.data.map((item: any) => {
                return {
                    ...item,
                    openPin: false
                }
            })
        }
    }
    return state;

}

export default storeChatReducer;