import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { CreateTemplate } from '../../components/messageCenter/CreateTemplate';
import { templateList, deleteTemplate, getEmailTemplateList } from '../../services/messageCenter';
import { confirmAlert } from 'react-confirm-alert';
import TemplateIcon from '../../assets/images/template-icon.svg';

export const Templates = () => {
  const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  const isLoaded = useSelector((state: IAppState) => state.loading.pending, shallowEqual);
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState([]);
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  const createTemplateRef: any = useRef();
  useEffect(() => {
    if (userData && userData.clinics[0] && userData.clinics[0].id) {
      getTemplates();
      fetchEmailTemplateList();
    }
  }, []);
  const getTemplates = () => {
    dispatch(fetchLoadingPending());
    templateList({ clinicid: userData.clinics[0].id }).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      if (success.error === false) {
        setTemplates(success.data);
        return;
      }
      toast.error(success.message);
    }).catch((err) => {
      dispatch(fetchLoadingSuccess(false));
      toast.error('Something went wrong.');
    })
  }
  const fetchEmailTemplateList = () => {
    getEmailTemplateList({clinicid: userData.clinics[0].id}).then((success) => {
        if (success && success.error === false) {
          setEmailTemplateList(success.data.filter((item: any) => item.isactive));
        }
    }).catch((err) => {
        setEmailTemplateList([]);
    });
  };
  const onRowClick = (item: any) => {
    createTemplateRef.current.showModal({
      item: item,
      getTemplateList: getTemplates
    });
  }
  const onDeleteClick = (item: any) => {
    confirmAlert({
      title: '',
      message: 'Are you sure you want to delete ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(fetchLoadingPending());
            deleteTemplate({
              clinicid: userData.clinics[0].id,
              messagecentercampaigntemplate_id: item.messagecentercampaigntemplate_id,
            }).then((success) => {
              dispatch(fetchLoadingSuccess(false));
              if (success && success.error === false) {
                getTemplates();
              }
            }).catch((err) => {
              dispatch(fetchLoadingSuccess(false));
            })
          }
        }
        ,
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });
  }
  return (
    <section className="all-campaign p-4">
      <div className="row mx-0">
        <div className="col-12">
          <div className="d-flex justify-content-between">
            <div>
              {/* <div className="campaning-title">Templates</div>
              <div className="campaning-desc">Template configuration</div> */}
            </div>
            <div className="stp-action align-self-center">
              <button className="btn btn-outline-primary" onClick={() => { createTemplateRef.current.showModal() }}>Create Template</button>
            </div>
          </div>
        </div>
        <div className="col-12">
          {
            templates.map((item: any, index: number) => {
              return (
                <div className="my-4 border px-3 py-3 border-1 rounded d-flex custom-camp-box camp-template temp-config justify-content-between w-100" >
                  <div className="align-self-center camp-title">
                    <img src={TemplateIcon} className="me-2" />
                    <span className="camp-title fw-bold">{item.name}</span>
                  </div>
                  <div className="d-flex">
                    <div className="camp-category">
                      <small className="text-muted">Category</small>
                      <div className="fw-bold">{item.category}</div>
                    </div>
                    <div className="camp-type">
                      <small className="text-muted">Template</small>
                      <div className="fw-bold">
                        {item.subject !== '' && 'email'}
                        {(item.subject !== '' && item.smstext !== '') && ','}
                        {item.smstext !== '' && 'text'}
                      </div>
                    </div>
                    <div>
                      <small className="text-muted">Created On</small>
                      <div className="fw-bold">{moment(item.created_at).format("MMM DD, YYYY - LT")}</div>
                    </div>
                    {/* <button className="btn p-0"><img src={require("../../assets/images/more-op-btn.svg")} /></button> */}
                  </div>
                  <div className="noti-btns d-flex align-self-center btn-link" >
                    <div onClick={() => {
                      onRowClick(item);
                    }}>Edit</div>
                    <button className="btn p-0 btn-link text-secondary" onClick={() => {
                      onDeleteClick(item);
                    }}><i className="ms-2 bi bi-x-circle-fill text-danger"></i></button>
                  </div>
                </div>
              )
            })
          }
          {
            (isLoaded === false && templates.length === 0) &&
            <label>No templates available</label>
          }
        </div>
      </div>
      <CreateTemplate fetchTemplate={getTemplates} emailTemplateList={emailTemplateList} ref={createTemplateRef}></CreateTemplate>
    </section>
  )
}