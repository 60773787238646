import { getCookie, prepareCookie, removeCookie } from "./cookies"
import { logoutUser } from "./firebaseUtils";
import moment from 'moment';
/**
 * 
 * @param isAttachAuthParam true when request made after login
 * @param isMultiPart true if request is multipart/form-data : default to false
 */
export const attachHeader = (isAttachAuthParam: boolean, isMultiPart: boolean = false) => {
    try {
        const login = getCookie();
        if (isAttachAuthParam) {
            let headers = null;
            if (isMultiPart) {
                headers = {
                    'Content-Type': 'multipart/form-data',
                    // 'x-access-param': login.authParam,
                    // 'xpr_user_id': login.uniqueId,
                    'x-access-param': login.xprAuthUser,
                    'xpr_user_id': login.xpr_user_id,
                    'xcookie': prepareCookie(),
                    'xprApp': 'clinic'
                }
            } else {
                headers = {
                    'Content-Type': 'application/json',
                    /* 'x-access-param': login.authParam,
                    'xpr_user_id': login.uniqueId, */
                    'x-access-param': login.xprAuthUser,
                    'xpr_user_id': login.xpr_user_id,
                    'xcookie': prepareCookie(),
                    'xprApp': 'clinic'
                }
            }
            return headers;
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        return headers;
    } catch (error) {
        removeCookie()
        logoutUser();
        window.location.href = '/';
    }
}

export const checkProviderAvailable = (clinic?: any, doctor?: any, startDate?: any, startTime?: any, endTime?: any) => {
    if (clinic.hours && clinic.hours.length > 0 && doctor.hours && doctor.hours.length > 0) {
        let day = moment(startDate).format('ddd').toUpperCase();
        let isAvailableClinic: boolean = true;
        let isAvailableDoctor: boolean = true;
        isAvailableClinic = clinic.hours.find((item: any) => {
            if (day === item.id && item.startHour !== 'Closed') {
                return (
                    moment(startTime, 'LT').isSameOrAfter(moment(item.startHour, 'LT')) &&
                    moment(endTime, 'LT').isSameOrBefore(moment(item.endHour, 'LT'))
                )
            }
        })
        console.log(isAvailableClinic);
        if (isAvailableClinic !== undefined) {
            isAvailableDoctor = doctor.hours.find((item: any) => {
                if (day === item.id && item.active !== false) {
                    return (
                        moment(startTime, 'LT').isSameOrAfter(moment(item.startHour, 'LT')) &&
                        moment(endTime, 'LT').isSameOrBefore(moment(item.endHour, 'LT'))
                    )
                }
            })
        } else {
            return false;
        }
        if (isAvailableDoctor !== undefined) {
            return true;
        } else return false;
    } else {
        return false;
    }
}