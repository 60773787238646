import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { shareCalendarChange, signInInfoUpdate } from '../../services/provider';
import ReactInputMask from 'react-input-mask';
import CONSTANT_LABELS from '../../../constants/labels';
import { removeCountryCode } from '../../../utils/global';
import { allowOnlyDigit } from '../../../utils/validators';
const SignInInformaion = ({ email, phone, providerId, fetchProfile }: any) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(phone ? phone : '');
    const { register, setValue, getValues, handleSubmit, watch, control, errors } = useForm({
        defaultValues: {
            providerPhone: phone ? phone : '',
            email: email ? email : ''
        }
    });

    const onCancel = () => {
        setIsEdit(false);
        setValue('providerPhone', phone ? phone : '');
        setValue('email', email ? email : '');
        setPhoneNumber(phone ? phone : '');
    }
    const onSave = (data: any) => {
        console.log('data', data, providerId, phoneNumber);
        if ((data.phone !== phone || data.email !== email) && providerId) {
            const request = {
                "type": "signup",
                "email": data.email,
                "phone": data.providerPhone,
                "mode": (phone && email) ? "update" : "add"
            }
            setIsLoading(true);
            signInInfoUpdate(providerId, request).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    fetchProfile().then((success: any) => {
                        setIsEdit(false);
                        setTimeout(() => {
                            setIsLoading(false);
                            setIsEdit(false);
                        }, 2000);
                    });
                }
            }).catch((err) => {
                setIsLoading(false);
            });
        } else {
            setIsEdit(false);
        }
    }
    return (
        <div className="share-cal-sec mb-4">
            <div className="card">
                <div className="card-header bg-light py-3 px-4">
                    <div className="d-flex justify-content-between">
                        <div className="fw-medium">Mobile App Sign-in</div>
                        <div>
                            {
                                !isEdit && !isLoading &&
                                <button className="btn p-0 btn-link" onClick={() => {
                                    setIsEdit(true);
                                }}>Edit</button>
                            }
                            {
                                isEdit && !isLoading &&
                                <>
                                    <button className="btn p-0 btn-link me-2 text-secondary" onClick={() => {
                                        onCancel();
                                    }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                    <button className="btn p-0 btn-link"
                                        onClick={handleSubmit(onSave)}
                                    ><i className="bi bi-check-circle-fill text-success"></i></button>
                                </>
                            }
                            {
                                isEdit && isLoading &&
                                <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
                            }
                            {
                                isLoading && !isEdit &&
                                <i className="bi bi-check-circle-fill text-success"></i>
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    <div>
                        <div className="fw-medium">Mobile#</div>
                        <span>{phone && !isEdit ? CONSTANT_LABELS.PLUS_ONE + removeCountryCode(phone) : ''}</span>
                    </div>

                    <div className="">
                        {/* <Controller
                            mask=""
                            name="providerPhone"
                            className={"form-control my-2 " + (!isEdit ? 'd-none' : '')}
                            control={control}
                            maxLength={10}
                            pattern={'\d{10}'}
                            onChange={
                                (e:any) => {
                                    if (!allowOnlyDigit(e.target.value)) {
                                        return;
                                    }
                                }
                            }
                            value={}
                            rules={{
                                required: true, minLength: { value: 10, message: 'Mobile number should be of 10 digit' },
                                maxLength: { value: 10, message: 'Mobile number should be of 10 digit' },
                                pattern: /[1-9]{1}[0-9]{9}/
                            }}
                            as={ReactInputMask}
                        /> */}
                        <input type="text" className={isEdit ? "form-control" : 'd-none'} value={phoneNumber} name="providerPhone" maxLength={10} placeholder="Enter mobile #"
                            onChange={(e: any) => {
                                if (e.target.value === '') {
                                    setPhoneNumber('');
                                } else if (!allowOnlyDigit(e.target.value)) {
                                    return;
                                } else {
                                    setPhoneNumber(e.target.value);
                                }
                            }}
                            ref={register({
                                required: true,
                                minLength: 10, maxLength: 10,
                                pattern: /^[0-9]*$/
                            })}
                        />
                        {
                            errors.providerPhone && errors.providerPhone.type === "required" &&
                            <div className="is-invalid">Required</div>
                        }
                        {
                            errors.providerPhone && errors.providerPhone.type === "minLength" &&
                            <div className="is-invalid">Mobile Number is invalid</div>
                        }
                        {
                            errors.providerPhone && errors.providerPhone.type === "maxLength" &&
                            <div className="is-invalid">Mobile Number is invalid</div>
                        }
                        {
                            errors.providerPhone && errors.providerPhone.type === "manual" &&
                            <div className="is-invalid">{errors.providerPhone.message}</div>
                        }
                        {
                            errors.providerPhone && errors.providerPhone.type === "pattern" &&
                            <div className="is-invalid">{"Mobile Number is invalid"}</div>
                        }
                    </div>

                    <div className="pt-2">
                        <div className="fw-medium">Email</div>
                        {email && !isEdit ? email : ''}
                    </div>
                    <div>
                        <input type="email" className={"form-control my-2 " + (!isEdit ? 'd-none' : '')} id="emailAddress" name="email" placeholder="xyz@clinic.com" ref={register({
                            required: true,
                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })} />
                        {
                            errors.email && errors.email.type === "required" &&
                            <div className="is-invalid">Required</div>
                        }
                        {
                            errors.email && errors.email.type === "pattern" &&
                            <div className="is-invalid">Email is invalid</div>
                        }
                        {
                            errors.email && errors.email.type === "manual" &&
                            <div className="is-invalid">{errors.email.message}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignInInformaion
