export const storeChatData = (data: any): any => ({
    type: 'CHAT_DATA',
    payload: data
});
export const removeChatPin = (appointmentId: any) => ({
    type: 'REMOVE_PIN',
    payload: appointmentId
});
export const closeChatPin = (appointmentId: any) => ({
    type: 'CLOSE_PIN',
    payload: appointmentId
});