import { specialityActionSuccess, specialityActionError, emailVerificationErrorAction, emailVerificationSuccessAction, acceptTCErrorAction, acceptTCSuccessAction, reviewSaveErrorAction, reviewSaveSuccessAction } from './../store/settings/actions';
import { SettingsAddRequest, SettingsRemoveRequestPendingAction, SettingsRemoveRequest, SpecialityRequest, SpecialityActionPedingAction, EmailVerificationPendingAction, EmailVerificationRequest, AcceptTCPendingAction, AcceptTCRequest, ReviewSaveRequest, ReviewSavePendingAction } from './../store/settings/types';
import { fetchLoadingPending, fetchLoadingSuccess, } from './../store/loadingIndicator/actions';
import { takeEvery, put } from "redux-saga/effects";
import axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import { SETTINGS, FetchSettingsPendingAction, SettingsRequest, SettingsAddRequestPendingAction,AdminSafetyFeeRequest,AdminSafetyFeePendingAction } from "../store/settings/types";
import { fetchSettingsError, fetchSettingsSuccess, settingsAddSucess, settignsAddError, settingsRemoveSucess, settignsRemoveError, settingsNotificationChangeSucess, settignsNotificationChangeError ,adminSafetyFeeErrorAction,adminSafetyFeeSuccessAction} from "../store/settings/actions";
import { attachHeader } from '../utils/apiHandler';
import { getCookie, prepareCookie } from '../utils/cookies';
import { specialityChange } from '../store/userData/actions';

function execute(login: SettingsRequest) {
    return axios.get(CONFIG.API_URL + login.type + "/" + login.uniqueId + "/setting/", {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* settingsPendingRun(action: FetchSettingsPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200) {
            yield put(fetchSettingsSuccess(response.data));
        } else {
            yield put(fetchSettingsError(response))
        }
    }
    if (error) {
        yield put(fetchSettingsError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export default function* settingsPendingWatcher() {
    yield takeEvery(SETTINGS.LOAD_PENDING, settingsPendingRun);
}


function executeAdd(login: SettingsAddRequest) {
    const body = {
        firstName: login.firstName,
        lastName: login.lastName,
        email: login.email
    }
    return axios.post(CONFIG.API_URL + login.type + "/" + login.uniqueId + "/setting/add/", body, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}

function* settingsAddPendingRun(action: SettingsAddRequestPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeAdd(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === false) {
                yield put(settingsAddSucess(response.data));
            } else {
                yield put(settignsAddError(response.data));
            }
        } else {
            yield put(settignsAddError(error));
        }
        if (error) {
            yield put(settignsAddError(error));
        }
        yield put(fetchLoadingSuccess(false));
    }
}

export function* settingsAddPendingWatcher() {
    yield takeEvery(SETTINGS.ADD_PENDING, settingsAddPendingRun);
}

function executeRemove(login: SettingsRemoveRequest) {
    return axios.post(CONFIG.API_URL + login.type + "/" + login.uniqueId + "/setting/remove/", login.permission, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}

function* settingsRemovePendingRun(action: SettingsRemoveRequestPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeRemove(action.payload);
    const itemUniqueId = action.payload.permission.uniqueId;
    if (response) {
        if (response.status === 200) {
            yield put(settingsRemoveSucess({ ...response.data, uniqueId: itemUniqueId }));
        } else {
            yield put(settignsRemoveError(error));
        }
        if (error) {
            yield put(settignsRemoveError(error));
        }
        yield put(fetchLoadingSuccess(false));
    }
}

export function* settingsRemovePendingWatcher() {
    yield takeEvery(SETTINGS.REMOVE_PENDING, settingsRemovePendingRun);
}

function executeNotificationChange(login: SettingsRemoveRequest) {
    return axios.post(CONFIG.API_URL + login.type + "/" + login.uniqueId + "/setting/change/", login.permission, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}

function* settingsNotificationChangePendingRun(action: SettingsRemoveRequestPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeNotificationChange(action.payload);
    const itemUniqueId = action.payload.permission.uniqueId;
    if (response) {
        if (response.status === 200) {
            yield put(settingsNotificationChangeSucess({ ...response.data, uniqueId: itemUniqueId }));
        } else {
            yield put(settignsNotificationChangeError(error));
        }
        if (error) {
            yield put(settignsNotificationChangeError(error));
        }
        yield put(fetchLoadingSuccess(false));
    }
}

export function* settingsNotificationChangePendingWatcher() {
    yield takeEvery(SETTINGS.CHANGE_PENDING, settingsNotificationChangePendingRun);
}

function executeSpecialityActionChange(request: SpecialityRequest) {
    const headers = {
        'Content-Type': 'application/json',
        // 'x-access-param': getCookie().authParam,
        'x-access-param': getCookie().xprAuthUser,
        'xpr_user_id': request.userUniqueId,
        'xcookie': prepareCookie(),
        'xprApp': 'clinic'
    };
    const body = {
        speciality: request.specialityId
    }
    if (request.type === "remove") {
        Object.assign(body, { type: "remove" })
    }
    return axios.post(CONFIG.API_URL + "doctor/" + request.uniqueId + "/setting/speciality/", body, {
        headers: headers,
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}

function* specialityPendingRun(action: SpecialityActionPedingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeSpecialityActionChange(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                response.data.type = action.payload.type;
                yield put(specialityActionError(response.data));
            } else {
                yield put(specialityActionSuccess({
                    ...response.data,
                    payload: action.payload
                }));
                yield put(specialityChange({ ...response.data, requestData: action.payload }));
            }
        } else {
            yield put(specialityActionError(error));
        }
        if (error) {
            yield put(specialityActionError(error));
        }
        yield put(fetchLoadingSuccess(false));
    }
}

export function* specialityActionPendingWatcher() {
    yield takeEvery(SETTINGS.SPECIALITY_ACTION_PENDING, specialityPendingRun);
}

function executeEmailVerification(request: EmailVerificationRequest) {
    const body = {
        email: request.email
    };
    return axios.post(CONFIG.API_URL + 'verify/' + request.modelType + "/" + request.modelUniqueid + "/", body, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
export function* emailVerificationRun(action: EmailVerificationPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeEmailVerification(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(emailVerificationErrorAction(response.data));
            } else {
                yield put(emailVerificationSuccessAction(response.data));
            }
        } else {
            yield put(emailVerificationErrorAction(response));
        }
    }
    if (error) {
        yield put(emailVerificationErrorAction(error));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* emailVerificationPendingWatcherSettings() {
    yield takeEvery(SETTINGS.EMAIL_VERIFICATION_PENDING, emailVerificationRun);
}

function executeAcceptTC(request: AcceptTCRequest) {
    return axios.get(CONFIG.API_URL + 'clinic/' + request.clinicUniqueId + "/tnc/", {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
export function* acceptTCRun(action: AcceptTCPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeAcceptTC(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(acceptTCErrorAction(response.data));
            } else {
                yield put(acceptTCSuccessAction(response.data));
            }
        } else {
            yield put(acceptTCErrorAction(response));
        }
    }
    if (error) {
        yield put(acceptTCErrorAction(error));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* acceptTCPendingWatcher() {
    yield takeEvery(SETTINGS.ACCEPT_TC_PENDING, acceptTCRun);
}


function executeReviewSave(request: ReviewSaveRequest) {
    return axios.post(CONFIG.API_URL + 'clinic/' + request.clinicUniqueId + "/review/",
        {
            reviewOn: request.reviewOn,
            trustApiKey: request.trustApiKey
        }
        , {
            headers: attachHeader(true),
            withCredentials: true
        }).then(response => ({ response }), error => ({ error }));
}
export function* reviewSaveRun(action: ReviewSavePendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeReviewSave(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(reviewSaveErrorAction(response.data));
            } else {
                yield put(reviewSaveSuccessAction({ ...response.data, metadata: action.payload }));
            }
        } else {
            yield put(reviewSaveErrorAction(response));
        }
    }
    if (error) {
        yield put(reviewSaveErrorAction(error));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* reviewSavePendingWatcher() {
    yield takeEvery(SETTINGS.REVIEW_SAVE_PENDING, reviewSaveRun);
}

function executeAdminSafetyFee(request: AdminSafetyFeeRequest) {
    const body = {
        adminsafetyfee: request.adminsafetyfee,
        adminFee:request.adminFee
    };
    return axios.post(CONFIG.API_URL + "clinic/" + request.clinicUniqueId + "/adminsafetyfee/",body, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* adminSafetyFeePendingRun(action: AdminSafetyFeePendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeAdminSafetyFee(action.payload);
    if (response) {
        if (response.status === 200) {
            yield put(adminSafetyFeeSuccessAction(response.data));
        } else {
            yield put(adminSafetyFeeErrorAction(response))
        }
    }
    if (error) {
        yield put(adminSafetyFeeErrorAction(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export  function* adminSafetyFeePendingWatcher() {
    yield takeEvery(SETTINGS.ADMIN_SAFETY_FEE_PENDING, adminSafetyFeePendingRun);
}