import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store';
const LoadingIndicator = () => {
    const isLoading = useSelector((state: IAppState) => state.loading.pending);
    if (isLoading) {
        return (
            <>
                <div className="global_loder">
                    <div className="loder_in shadow-lg bg-white rounded text-center">
                        <div className="spinner-border" role="status">
                        </div>
                        <div className="mt-2">Loading...</div>
                    </div>

                </div>
            </>
        );
    }
    return null;
}
export default LoadingIndicator;