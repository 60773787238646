export const APPOINTMENT_STATUS = {
    REQUEST: ['requested', 'requested'],
    CANCEL: ['cancelled', 'cancelled'],
    COMPLETE: ['completed', 'completed'],
    SCHEDULE: ['scheduled', 'scheduled'],
}


export const BILLING_STATUS: any = {
    TO_BILL: 'Pending Charge',
    NOT_BILLED: 'Not Billed',
    BILLED: 'Billed',
    NOT_TO_BILLED: 'Not to be charged'
}

export const NOT_BILLING_STATUS = ['NOT_TO_BILLED'];