import React from 'react';
class BillingInvoice extends React.Component {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <>
            <div>Coming soon</div>
               {/* <div className="container">
                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Invoices</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 mb-3">
                                            <div className="d-md-flex">
                                                <div className="m-b-10 m-r-15">
                                                    <label>Card</label>
                                                    <select className="custom-select">  
                                                        <option value="">●●●● ●●●● ●●●● 5247 (INACTIVE)</option>
                                                        <option value="">●●●● ●●●● ●●●● 4646</option>
                                                    </select>
                                                </div>
                                                <div className="m-b-10 m-r-15">
                                                    <label>Year</label>
                                                    <select className="custom-select"> 
                                                        <option value="">2017</option>
                                                        <option value="">2018</option>
                                                    </select>
                                                </div>
                                                <div className="m-b-10">
                                                    <label>Month</label>
                                                    <select className="custom-select"> 
                                                        <option value="">May</option>
                                                        <option value="">April</option>
                                                        <option value="">March</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> 

                                    
                                        <div className="col-sm-6">
                                            <h4 className="m-0">
                                                <strong>Sandeep Walia</strong>
                                            </h4>
                                            <span className="text-muted">Card ending 4646 (Visa)</span>
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <h4 className="m-0">
                                                <strong>November 2018</strong>
                                            </h4>
                                            <span className="text-muted">Billing Period</span>
                                        </div>

                                        <div className="col-sm-12">
                                            <hr/>
                                        </div>
                                        
                                        <div className="col-sm-9">
                                            <h3 className="m-t-10">Invoice To:</h3>
                                            <div className="m-t-10">
                                                <h4 className="m-0">Sandeep Walia</h4>
                                                <span>8626 Maiden Dr. Niagara Falls, New York 14304</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="m-t-80">
                                                <div className="text-dark d-inline-block">
                                                    <span className="font-weight-semibold text-dark">Invoice No :</span></div>
                                                <div className="float-right">#1668</div>
                                            </div>
                                            <div className="text-dark d-inline-block">
                                                <span className="font-weight-semibold text-dark">Billing Period :</span>
                                            </div>
                                            <div className="float-right">November 2018</div>
                                        </div>
                                         
                                        <div className="col-sm-12">
                                            <hr/>
                                        </div>

                                        

                                        <div className="col-sm-12 m-t-20">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Provider</th>
                                                            <th>Price</th>
                                                            <th>Discount</th>
                                                            <th>Cost</th>
                                                            <th>Leads</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th> Dr. Sandeep Walia Westmetro Dental </th>
                                                            <td>$399.00</td>
                                                            <td>$249.00</td>
                                                            <td>$150.00</td>
                                                            <td>2</td>
                                                            <td>$300.00</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row m-t-30 lh-1-8">
                                                <div className="col-sm-12">
                                                    <div className="float-right text-right">
                                                        <p>Sub - Total amount: $300.00</p>
                                                        <p>Tax (13%) : $39.00 </p>
                                                        <hr/>
                                                        <h3><span className="font-weight-semibold text-dark">Total :</span> $339.00</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </>
        )
    }
}
export default BillingInvoice;