import {
    LOGINFORGOTSENT,
    LoginForgotSentActionTypes,
    LoginForgotSentState
} from './types';

const initialState: LoginForgotSentState = {
    pending: false,
    loginforgotsent: { loginforgotsent: 'login' },
    error: null
};

export const loginForgotSentReducer = (state = initialState, action: LoginForgotSentActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case LOGINFORGOTSENT.LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case LOGINFORGOTSENT.LOAD_PENDING:
            return {
                ...state,
                pending: true,
                loginforgotsent: action.payload
            }

        case LOGINFORGOTSENT.LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                loginforgotsent: action.loginforgotsent
            }
        default:
            return NewState;

    }
};

export const getCountries = (state: LoginForgotSentState) => state.loginforgotsent;
export const getCountriesPending = (state: LoginForgotSentState) => state.pending;
export const getCountriesError = (state: LoginForgotSentState) => state.error;