import firebase from 'firebase';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IAppState } from '../../../../store';
import configs from '../../../configs/apiConfigs';
import { USER_TYPE } from '../../../constants/constants';
import { getProviderByName } from '../../../services/providerChat';
import { Messages } from './Messages';
import { GroupList } from './GroupList';

const db = firebase.firestore();

export const GroupChat = () => {
    const history = useHistory();
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const [selectedGroup, setSelectedGroup]: any = useState(null);

    const onClickGroup = async (data: any) => {
        setSelectedGroup(data);
    }

    return (
        <main className="chat_params">
            <div className="container-fluid p-0">
                <div className="card border-0">
                    <div className="row g-0">
                        <div className="col-3 border-end chatlist-panel" id="scroll">
                            <div className="chat-tab-links">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <button className="nav-link" type="button" onClick={() => {
                                            history.push('/chats/patient');
                                        }}>Patient</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link" type="button" onClick={() => {
                                            history.push('/chats/provider');
                                        }}>Provider</button>
                                    </li>
                                    <li className={"nav-item " + (configs.SOURCE_URL !== "https://www.xcarepro.com/" ? "" : "d-none")}>
                                        <button className="nav-link active" type="button" >Group</button>
                                    </li>
                                </ul>
                            </div>
                            {
                                (false) &&
                                <span className="spinner-border spinner-border-sm" role="status"></span>
                            }
                            <GroupList
                                onClickGroup={onClickGroup}
                                selectedGroup={selectedGroup}
                            ></GroupList>
                        </div>
                        {
                            selectedGroup &&
                            <Messages selectedGroup={selectedGroup}></Messages>
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}