// import './_practiceSteps.scss';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { createStep, deletePhoto } from '../../services/clinicOnboard';
import configs from '../../configs/apiConfigs';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import DeleteBtn from "../../assets/images/delete-btn.svg";
export function PracticeApp9(props: any) {
	const [uploadedPhotos, setUploadedPhotos]: any = useState([]);
	const dispatch = useDispatch();
	let { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: 'image/jpeg, image/png, image/jpg',
		multiple: false,
		onDrop: (acceptedFiles) => {
			if (acceptedFiles[0]) {
				const requestData = {
					uphoto: acceptedFiles[0],
					uniqueid: props.clinicUniqueId
				};
				dispatch(fetchLoadingPending());
				createStep({ stepNumber: 10, formData: requestData }).then((success) => {
					dispatch(fetchLoadingSuccess(false));
					if (success.status && success.status.error === false) {
						setUploadedPhotos(success.photos);
					}
				}).catch((err) => {
					dispatch(fetchLoadingSuccess(false));
				})
			}
		}
	});
	const onNext = () => {
		const request = {
			practicePhotos: uploadedPhotos
		}
		props.onNext(10, { practicePhotos: request });
	}
	useEffect(() => {
		if (props.data) {
			setUploadedPhotos(props.data.practicePhotos);
		}
	}, [props.data]);
	const onDeleteImage = (imageId: any) => {
		dispatch(fetchLoadingPending());
		deletePhoto(imageId, props.clinicUniqueId).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				setUploadedPhotos(uploadedPhotos.filter((iItem: any, iIndex: number) => iItem.id !== imageId));
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});
	}
	return (

		<>
			<div className="row justify-content-center">
				<div className="col-12">
					<h2 className="mb-3">Photos</h2>
					<div className="fw-medium fs-5">Practice Photos.</div>
					<p className="mt-1">Uploading practice photos helps boost your profile on XCarePro by showing patients what your facilities look like. We recommend uploading at least 3 to 5 photos.</p>
					<hr className="my-4" />
				</div>

				<div className="col-12">
					<div className="img-gallery-practice d-inline-block">
						{
							uploadedPhotos.map((item: any, index: number) => {
								return (
									<div className="img-box d-inline-block" key={index + ""}>
										<button className="btn p-0 position-absolute delete-img-btn" onClick={(e) => {
											onDeleteImage(item.id);
										}}>
											<img src={DeleteBtn} /></button>
										<img src={configs.IMAGE_URL + "clinic" + "/" + item.image.name + "-300x300." + item.image.type} height={115} width={115} />
									</div>
								)
							})
						}
					</div>
				</div>
				<div className="col-12">
					<div className="border rounded upload-sec p-4">
						<label className="form-label">Upload</label>
						<section className="container upload-input-box py-5 text-center mb-4">
							<div {...getRootProps({ className: 'dropzone' })}>
								<input {...getInputProps()} />
								<em>(Only *.jpeg, *.jpg and *.png images will be accepted)</em>
								<div><button className="btn btn-link ps-0 py-0">Browse</button></div>
							</div>
						</section>
					</div>
				</div>

				<div className="col-12 my-4">
					<div className="bottom-btn-sec d-flex justify-content-between">
						<button className="btn border me-1" onClick={() => { props.onPrev(8) }}>Back</button>
						<button className="btn btn-primary ms-1" disabled={uploadedPhotos.length === 0} onClick={(e) => { onNext(); }}>Next</button>
					</div>
				</div>

			</div>
		</>
	);
}
