import {
    FetchInitPaymentRequest, FetchInitPaymentPendingAction, PAYMENT, FetchInitePaymentResponse,
    FetchInitePaymentSuccessAction, FetchInitPaymentErrorAction, PaymentBillableEntryRequest, PaymentBillableEntryPendingAction,
    PaymentBillableEntryErrorAction,
    PaymentBillableEntryResponse,
    PaymentBillableEntrySuccessAction,
    PaymentBillableAppointmentRequest,
    PaymentBillableAppointmentPendingAction,
    PaymentBillableAppointmentErrorAction,
    PaymentBillableAppointmentResponse,
    PaymentBillableAppointmentSuccessAction,
    ServicesTypeRequest,
    ServicesTypePendingAction,
    ServicesTypeErrorAction,
    ServicesTypeResponse,
    ServicesTypeSuccessAction,
    CreateInvoiceRequest,
    CreateInvoicePendingAction,
    CreateInvoiceResponse,
    CreateInvoiceSuccessAction,
    CreateInvoiceErrorAction,
    StripeSaveAccountIdRequest,
    StripeSaveAccountIdPendingAction,
    StripeSaveAccountIdResponse,
    StripeSaveAccountIdSuccessAction,
    StripeSaveAccountIdErrorAction,
    SaveTaxRequest,
    SaveTaxResponse,
    SaveTaxPendingAction,
    SaveTaxSuccessAction,
    SaveTaxErrorAction,
    paymentAmountRequest,
    PayamentAmountPendingAction,
    paymentAmountResponse,
    PayamentAmountSuccessAction,
    PayamentAmountxErrorAction
} from "./types";

export const fetchInitPaymentPending = (request: FetchInitPaymentRequest): FetchInitPaymentPendingAction => ({
    type: PAYMENT.FETCH_INIT_PAYMENT,
    payload: request
});
export const fetchInitPaymentSuccess = (response: FetchInitePaymentResponse): FetchInitePaymentSuccessAction => ({
    type: PAYMENT.FETCH_INIT_PAYMENT_SUCCESS,
    payload: response
});
export const fetchInitPaymentError = (error: any): FetchInitPaymentErrorAction => ({
    type: PAYMENT.FETCH_INIT_PAYMENT_ERROR,
    payload: error
});
export const paymentBillableEntryPending = (request: PaymentBillableEntryRequest): PaymentBillableEntryPendingAction => ({
    type: PAYMENT.PAYMENT_BILLABLE_ENTRY,
    payload: request
});
export const paymentBillableEntryError = (error: any): PaymentBillableEntryErrorAction => ({
    type: PAYMENT.PAYMENT_BILLABLE_ENTRY_ERROR,
    payload: error
});
export const paymentBillableEntrySuccess = (response: PaymentBillableEntryResponse): PaymentBillableEntrySuccessAction => ({
    type: PAYMENT.PAYMENT_BILLABLE_ENTRY_SUCCESS,
    payload: response
});
export const paymentBillableAppointmentPending = (request: PaymentBillableAppointmentRequest): PaymentBillableAppointmentPendingAction => ({
    type: PAYMENT.PAYMENT_BILLABLE_APPOINTMENT,
    payload: request
});
export const paymentBillableAppointmentError = (error: any): PaymentBillableAppointmentErrorAction => ({
    type: PAYMENT.PAYMENT_BILLABLE_APPOINTMENT_ERROR,
    payload: error
});
export const paymentBillableAppointmentSuccess = (response: PaymentBillableAppointmentResponse[]): PaymentBillableAppointmentSuccessAction => ({
    type: PAYMENT.PAYMENT_BILLABLE_APPOINTMENT_SUCCESS,
    payload: response
});
export const servicesTypePending = (request: ServicesTypeRequest): ServicesTypePendingAction => ({
    type: PAYMENT.SERVICES_TYPE,
    payload: request
});
export const servicesTypeError = (error: any): ServicesTypeErrorAction => ({
    type: PAYMENT.SERVICES_TYPE_ERROR,
    payload: error
});
export const servicesTypeSuccess = (response: ServicesTypeResponse): ServicesTypeSuccessAction => ({
    type: PAYMENT.SERVICES_TYPE_SUCCESS,
    payload: response
});
export const createInvoicePending = (request: CreateInvoiceRequest): CreateInvoicePendingAction => ({
    type: PAYMENT.CREATE_INVOICE,
    payload: request
});
export const createInvoiveSuccess = (response: CreateInvoiceResponse): CreateInvoiceSuccessAction => ({
    type: PAYMENT.CREATE_INVOICE_SUCCESS,
    payload: response
});
export const createInvoiceError = (error: any): CreateInvoiceErrorAction => ({
    type: PAYMENT.CREATE_INVOICE_ERROR,
    payload: error
});
export const stripeSaveAccountIdPending = (request: StripeSaveAccountIdRequest): StripeSaveAccountIdPendingAction => ({
    type: PAYMENT.STRIPE_SAVE_ACCOUNT_ID,
    payload: request
});
export const stripeSaveAccountIdSuccess = (response: StripeSaveAccountIdResponse): StripeSaveAccountIdSuccessAction => ({
    type: PAYMENT.STRIPE_SAVE_ACCOUNT_ID_SUCCESS,
    payload: response
});
export const stripeSaveAccountIdError = (error: any): StripeSaveAccountIdErrorAction => ({
    type: PAYMENT.STRIPE_SAVE_ACCOUNT_ID_ERROR,
    payload: error
});
export const saveTaxPending = (request: SaveTaxRequest): SaveTaxPendingAction => ({
    type: PAYMENT.SAVE_TAX,
    payload: request
});
export const saveTaxSuccess = (response: SaveTaxResponse): SaveTaxSuccessAction => ({
    type: PAYMENT.SAVE_TAX_SUCCESS,
    payload: response
});
export const saveTaxError = (error: any): SaveTaxErrorAction => ({
    type: PAYMENT.SAVE_TAX_ERROR,
    payload: error
});
export const paymentAmountPending = (request: paymentAmountRequest): PayamentAmountPendingAction => ({
    type: PAYMENT.PAYMENT_AMOUNT_UPDATE,
    payload: request
});
export const paymenAmountSuccess = (response: paymentAmountResponse): PayamentAmountSuccessAction => ({
    type: PAYMENT.PAYMENT_AMOUNT_UPDATE_SUCCESS,
    payload: response
});
export const paymentAmountError = (error: any): PayamentAmountxErrorAction => ({
    type: PAYMENT.PAYMENT_AMOUNT_UPDATE_ERROR,
    payload: error
});