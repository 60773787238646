import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { fetchLoadingPending, fetchLoadingSuccess } from "../store/loadingIndicator/actions";
import { messageCenterRecallFailure, messageCenterRecallSuccess, messageCenterRecareFailure, messageCenterRecareSuccess } from "../store/messagecenter/actions";
import { MessageCenterRecarePendingAction, MESSAGE_CENTER } from "../store/messagecenter/types";
import { attachHeader } from "../utils/apiHandler";

function* MessageCenterRecare(action: MessageCenterRecarePendingAction) {
  try {
    yield put(fetchLoadingPending());
    let url = configs.API_URL + "clinic/" + action.payload.clinicUniqueId + "/recare/";
    const options: CommonApiOptions = {
      url: url,
      body: {
        data: action.payload.data,
        type: BODY_TYPE.RAW
      },
      apiOptions: {
        headers: attachHeader(true),
        withCredentials: true
      },
      method: METHOD_TYPE.POST,
    };
    //@ts-ignore
    const response = yield call(commonApi, options);
    yield put(messageCenterRecareSuccess({ ...response }));
  } catch (err) {
    yield put(messageCenterRecareFailure(err));
  }
  yield put(fetchLoadingSuccess(false));
}
function* MessageCenterRecareWatcher() {
  yield takeEvery(MESSAGE_CENTER.MESSAGE_CENTER_RECARE, MessageCenterRecare)
}
function* MessageCenterRecall(action: MessageCenterRecarePendingAction) {
  try {
    yield put(fetchLoadingPending());
    let url = configs.API_URL + "clinic/" + action.payload.clinicUniqueId + "/recall/";
    const options: CommonApiOptions = {
      url: url,
      body: {
        data: action.payload.data,
        type: BODY_TYPE.RAW
      },
      apiOptions: {
        headers: attachHeader(true),
        withCredentials: true
      },
      method: METHOD_TYPE.POST,
    };
    //@ts-ignore
    const response = yield call(commonApi, options);
    yield put(messageCenterRecallSuccess({ ...response }));
  } catch (err) {
    yield put(messageCenterRecallFailure(err));
  }
  yield put(fetchLoadingSuccess(false));
}
function* MessageCenterRecallWatcher() {
  yield takeEvery(MESSAGE_CENTER.MESSAGE_CENTER_RECALL, MessageCenterRecall)
}
function* MessageCenter() {
  yield all([fork(MessageCenterRecareWatcher), fork(MessageCenterRecallWatcher)]);
}
export default MessageCenter;