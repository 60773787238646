import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { createSubscriptionPlan, fetchSubscriptionPlan } from '../../store/subscriptionplan/actions';
import { CreateSubscriptionPlanRequest, FetchSubscriptionPlanRequest, PlanItem, SubscriptionState } from '../../store/subscriptionplan/types';
import { UserDataResponse } from '../../store/userData/types';
import { toast } from 'react-toastify';
import { Alert, Col, Form, Button, Modal, ModalBody } from 'react-bootstrap';
import { fetchBillingPendingAction } from '../../store/billing/actions';
import { BillingRequest, BillingState } from '../../store/billing/types';
import { id } from 'date-fns/locale';
import _ from "lodash";
import CardInfo from './CardInfo';
import GetPlan from './GetPlan';
import Plans from './Plans';
import Axios from 'axios';
import { attachHeader } from '../../utils/apiHandler';
import configs from '../../configs/apiConfigs';

interface Props {
  userDataResponse: UserDataResponse;
  fetchSubscriptionPlan: typeof fetchSubscriptionPlan;
  subscriptionState: SubscriptionState;
  createSubscriptionPlan: typeof createSubscriptionPlan;
  fetchBilling: typeof fetchBillingPendingAction;
  billingState: BillingState;
  history: any;
}
interface State {
  selectedClinic: string;
  planListMonth: PlanItem[];
  planListYear: PlanItem[];
  monthPlans: any;
  yearPlans: any;
  showCardModal: boolean;
  planObj?: any;
  stripeCards: any[];
  showPlanCancellationModal: boolean;
  isCheckOut: boolean;
  planType: any; // Monthly or Annualy
  isPlanActive: boolean;
  planList: any;
  currentPlan: any;
  type: any;
  interval: any;
  selectedAddon: any;
  advertisePlanName: any;


}

class SubscriptionPlanNew extends Component<Props, State> {
  calledFirst: boolean;
  constructor(props: any) {
    super(props);
    this.state = {
      selectedClinic: '',
      planListMonth: [],
      planListYear: [],
      monthPlans: {} as any,
      yearPlans: {} as any,
      showCardModal: false,
      stripeCards: [],
      showPlanCancellationModal: false,
      isCheckOut: false,
      planType: 'Monthly',
      isPlanActive: true, planList: '', currentPlan: [], type: '', interval: '',
      selectedAddon: '',
      advertisePlanName: ''
    }
    this.calledFirst = false;
  }
  componentDidMount() {
    if (this.props.history && this.props.history.location && this.props.history.location.state &&
      this.props.history.location.state.screenname === 'overview' && this.props.history.location.state.type) {
      this.setState({ isPlanActive: false, type: this.props.history.location.state.type, interval: this.props.history.location.state.interval });
      if (this.props.userDataResponse && this.props.userDataResponse.clinics.length > 0 && this.props.userDataResponse.clinics[0].uniqueId) {
        this.props.fetchSubscriptionPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId ? this.props.userDataResponse.clinics[0].uniqueId : "1512145582" });
      }

    } else if (this.props.history && this.props.history.location && this.props.history.location.state &&
      this.props.history.location.state.screenname === 'overview' && this.props.history.location.state.planName) {
      this.setState({ advertisePlanName: this.props.history.location.state.planName });
      if (this.props.userDataResponse && this.props.userDataResponse.clinics.length > 0 && this.props.userDataResponse.clinics[0].uniqueId) {
        this.props.fetchSubscriptionPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId ? this.props.userDataResponse.clinics[0].uniqueId : "1512145582" });
      }

    }
    else {
      if (this.props.userDataResponse && this.props.userDataResponse.clinics.length > 0 && this.props.userDataResponse.clinics[0].uniqueId) {
        const url = configs.API_URL + 'prime/clinic' + '/' + this.props.userDataResponse.clinics[0].uniqueId + '/clinic-current-plans/';
        Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then((success) => {
          if (success.data && success.data.active_plans.length > 0) {
            this.setState({ currentPlan: success.data, isPlanActive: true });
          }

        }).catch((error) => {
          return ''
        });
        this.props.fetchSubscriptionPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId ? this.props.userDataResponse.clinics[0].uniqueId : "1512145582" });
      }
    }


  }
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.subscriptionState.fetchSubscriptionPlanResponse !== prevProps.subscriptionState.fetchSubscriptionPlanResponse &&
      this.props.subscriptionState.fetchSubscriptionPlanResponse && this.props.subscriptionState.fetchSubscriptionPlanResponse.status.error === false) {
      this.setState({ planList: this.props.subscriptionState.fetchSubscriptionPlanResponse },
        () => {
          if (this.state.advertisePlanName) {
            this.state.planList.month.map((item: any) => {
              if (this.state.advertisePlanName === item.name) {
                this.setState({ selectedAddon: item, isPlanActive: false });
              }
            })
          }
        })
    }
    if (prevProps.userDataResponse.clinics !== this.props.userDataResponse.clinics) {
      const url = configs.API_URL + 'prime/clinic' + '/' + this.props.userDataResponse.clinics[0].uniqueId + '/clinic-current-plans/';
      Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then((success) => {
        if (success.data && success.data.active_plans.length > 0) {
          this.setState({ currentPlan: success.data, isPlanActive: true });
        }
      }).catch((error) => {
        return ''
      });
      this.props.fetchSubscriptionPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId ? this.props.userDataResponse.clinics[0].uniqueId : "1512145582" });
    }
  }

  handlePlanActive = () => {
    this.setState({ isPlanActive: !this.state.isPlanActive });
  }
  addAddon = (value: any) => {
    this.setState({ isPlanActive: !this.state.isPlanActive, selectedAddon: value });
  }

  render() {
    return (
      (this.state.isPlanActive === true) ?
        <Plans
          goToPlans={this.handlePlanActive}
          selectedPlan={this.state.currentPlan}
          addons={this.state.planList}
          selectedAddon={this.addAddon}
        ></Plans> :
        <GetPlan
          data={this.state.planList}
          selectedPlan={this.state.currentPlan}
          history={this.props.history}
          type={this.state.type}
          interval={this.state.interval}
          selectedAddon={this.state.selectedAddon}
          clinicId={this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics[0].uniqueId}
        ></GetPlan>
    )
  }
}
const mapStateToProps = (state: IAppState) => ({
  userDataResponse: state.userData.userDataResponse,
  subscriptionState: state.subscriptionPlan,
  billingState: state.billing,
});
const mapDispatchToProps = (dispatch: any) => ({
  fetchSubscriptionPlan: (request: FetchSubscriptionPlanRequest) => {
    dispatch(fetchSubscriptionPlan(request));
  },
  createSubscriptionPlan: (request: CreateSubscriptionPlanRequest) => {
    dispatch(createSubscriptionPlan(request));
  },
  fetchBilling: (request: BillingRequest) => {
    dispatch(fetchBillingPendingAction(request));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlanNew);