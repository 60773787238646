import { DatePicker } from "@material-ui/pickers";
import _, { cloneDeep, over } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ReportHeader } from ".";
import { IAppState } from "../../../store";
import { fetchLoadingPending, fetchLoadingSuccess } from "../../../store/loadingIndicator/actions";
import configs from "../../configs/apiConfigs";
import { convertToSystemDate } from "../../helpers/datetime";
import { getAppointmentCategoryList, getAppointmentListAnalytics, getProcedureCodeList, setDueImmediatelyAppointmentArchive } from "../../services/messageCenter";
import { getProviderList } from "../../services/provider";
import { titleCase } from "../../utils/global";

export const AnalyticsAndReportDetail = ({
    type,
    onBackClick
}: any) => {
    const [date, setDate]:any = useState("");
    const [week, setWeek] = useState("");
    const [month, setMonth] = useState("");

    const [showCustomFilterDialog, setShowCustomFilterDialog] = useState(false);
    const [overdue, setOverDue]= useState('0,6');
    const getStartDate = () => {
        let startDate = convertToSystemDate(moment());
        switch(type) {
            case "confirmed_patient_list":
                startDate = convertToSystemDate(moment());
                break;
            case "unconfirmed_patient_list":
                startDate = convertToSystemDate(moment());
                break;
            case  "waitlisted_patient_list":
                startDate = convertToSystemDate(moment());
                break;
            case "cancelled_patient_list":
                startDate = convertToSystemDate(moment().subtract(1, "months"));
                break;
            case "rescheduled_patient_list":
                startDate = convertToSystemDate(moment().subtract(1, "months"));
                break;
            case "requested_patient_list":
                startDate = convertToSystemDate(moment());
                break;
            case "pre-screening_verified_patient_list":
                startDate = convertToSystemDate(moment());
                break;
            case "pre-screening_pending_patient_list":
                startDate = convertToSystemDate(moment());
                break;
            case "pre-screening_failed_patient_list":
                startDate = convertToSystemDate(moment());
                break;
            case "unscheduled_patients_list":
                startDate = convertToSystemDate(moment().subtract(1, "months"));
                break;
            case "overdue_patients_list":
                startDate = convertToSystemDate(moment().subtract(6, "months"));
                break;
            case "double_booked_patients_list":
                startDate = convertToSystemDate(moment());
                break;
            case "pre-appointed_patients_list":
                startDate = convertToSystemDate(moment().subtract(13, "day"));
                break;
            case "due_immediately_patients_list":
                startDate = convertToSystemDate(moment().subtract(1, "months"));
                break;
        }
        return startDate;
    }
    const getEndDate = () => {
        let endDate = convertToSystemDate(moment().add(13, "day"));
        switch(type) {
            case "confirmed_patient_list":
                endDate = convertToSystemDate(moment().add(13, "day"));
                break;
            case "unconfirmed_patient_list":
                endDate = convertToSystemDate(moment().add(13, "day"));
                break;
            case  "waitlisted_patient_list":
                endDate = convertToSystemDate(moment().add(1, "year"));
                break;
            case "cancelled_patient_list":
                endDate = convertToSystemDate(moment().add(3, 'months'));
                break;
            case "rescheduled_patient_list":
                endDate = convertToSystemDate(moment().add(3, 'months'));
                break;
            case "requested_patient_list":
                endDate = convertToSystemDate(moment().add(1, "year"));
                break;
            case "pre-screening_verified_patient_list":
                endDate = convertToSystemDate(moment().add(1, "day"));
                break;
            case "pre-screening_pending_patient_list":
                endDate = convertToSystemDate(moment().add(1, "day"));
                break;
            case "pre-screening_failed_patient_list":
                endDate = convertToSystemDate(moment().add(1, "day"));
                break;
            case "unscheduled_patients_list":
                endDate = convertToSystemDate(moment());
                break;
            case "overdue_patients_list":
                endDate = convertToSystemDate(moment());
                break;
            case "double_booked_patients_list":
                endDate = convertToSystemDate(moment().add(6, "day"));
                break;
            case "pre-appointed_patients_list":
                endDate = convertToSystemDate(moment());
                break;
            case "due_immediately_patients_list":
                endDate = convertToSystemDate(moment().add(3, 'months'));
                break;
        }
        return endDate;
    }
    const [startDate, setStartDate]:any = useState(getStartDate());
    const [endDate, setEndDate]:any = useState(getEndDate());
    const [showFilterOnMore, setShowFilterOnMore] = useState(false);

    const [appointmentList, setAppointmentList]:any = useState({});

    /* Filter's Master */
    const [categoryListMaster, setCategoryListMaster] = useState([]);
    const [proceedureCodeListMaster, setProceedureCodeListMaster] = useState([]);
    const [providersListMaster, setProvidersListMaster] = useState([]);

    const [selectedCategory, setSelectedCategory]:any = useState(null);
    const [selectedProceedureCode,setSelectedProceedureCode]:any = useState(null);
    const [selectedProvider, setSelectedProvider]:any = useState(null);
    const [selectedInitiationDays, setSelectedInitiationDays] = useState(120);
    const [_isMounted, setIsMounted] = useState(false);

    const clinic: any = useSelector((state: IAppState) => state.userData.userDataResponse.clinics[0]);
    const dispatch = useDispatch();

    let pageTitle = type || "";
    pageTitle = pageTitle.replaceAll("_", " ");
    pageTitle = titleCase(pageTitle);

    const handleDateChange =(date:any) => {
        setDate(moment(date));
        setWeek("");
        setMonth("");
        // setStartDate("");
        setEndDate("");
    }
    const handleWeekChange = (date:any, dateString:string) => {
        setWeek(date);
        setDate("");
        setMonth("");
        // setStartDate("");
        setEndDate("");
    }
    const handleMonthChange = (date:any, dateString:string) => {
        setMonth(date);
        setDate("");
        setWeek("");
        // setStartDate("");
        setEndDate("");
    }
    const handleCategoryChange = (e:any) => {
        const value = e.target.value;
        setSelectedCategory(value);
        getProceedureCode(value);
        setSelectedProceedureCode(null);
    }
    const handleProcedureCodeChange = (e:any) => {
        const value = e.target.value;
        setSelectedProceedureCode(value);
    }
    const handleProviderChange = (e:any) => {
        const value = e.target.value;
        setSelectedProvider(value);
    }
    const handleInitiationDays = (e:any) => {
        const value = e.target.value;
        setSelectedInitiationDays(value);
    }

    const generateQuery = () =>{
        let query:any = {
            clinicid:clinic.id,
        }
        if (date) {
            Object.assign(query, {
                startdate:convertToSystemDate(date),
                enddate:convertToSystemDate(date)
            });
        };
        if (week) {
            Object.assign(query, {
                startdate:convertToSystemDate(moment(week)),
                enddate:convertToSystemDate(moment(week).add(6, "day"))
            });
        }
        if (month) {
            Object.assign(query, {
                startdate:convertToSystemDate(moment(month).startOf('month')),
                enddate:convertToSystemDate(moment(month).endOf('month'))
            });
        }
        if (startDate && endDate) {
            Object.assign(query, {
                startdate:convertToSystemDate(startDate),
                enddate:convertToSystemDate(endDate)
            });
        }
        if (selectedCategory) {
            Object.assign(query, {
                category: selectedCategory
            });
        }
        if (selectedProceedureCode) {
            Object.assign(query, {
                procedurecode: selectedProceedureCode
            });
        }
        if (selectedProvider) {
            Object.assign(query, {
                doctorid: selectedProvider
            });
        }
        if (type === "overdue_patients_list" && selectedInitiationDays) {
            Object.assign(query, {
                initialdays: selectedInitiationDays,
                overdue: overdue
            });
        }
        return query;
    }

    useEffect(() => {
        getCategoryList();
        getProviders();
        let obj = {
            clinicid:clinic.id,
            startdate:getStartDate(),
            enddate:getEndDate()
        };
        if (type === "overdue_patients_list") {
            Object.assign(obj, {
                initialdays: 120,
                overdue: overdue
            });
        }
        onFilter(obj);
        return () => {
        }
    }, []);

    const resetFilter = () => {
        setDate("");
        setWeek("");
        setMonth("");
        setOverDue('0,6');
        setStartDate(getStartDate());
        setEndDate(getEndDate());
        setShowCustomFilterDialog(false);
        setShowFilterOnMore(false);
        setAppointmentList({});
        setCategoryListMaster([]);
        setProceedureCodeListMaster([]);
        setProvidersListMaster([]);
        setSelectedCategory(null);
        setSelectedProceedureCode(null);
        setSelectedProvider(null);
        setSelectedInitiationDays(120);
        setTimeout(() => {
            getCategoryList();
            getProviders();
            let obj = {
                clinicid:clinic.id,
                startdate:getStartDate(),
                enddate:getEndDate()
            };
            if (type === "overdue_patients_list") {
                Object.assign(obj, {
                    initialdays: 120,
                    overdue: overdue
                });
            }
            onFilter(obj);
        }, 100);
    }

    useEffect(() => {
        if (_isMounted) onFilter();
    }, [date, week, month, selectedCategory, selectedProceedureCode, selectedProvider]);

    useEffect(() => {
        if (_isMounted) if (startDate && endDate && !showCustomFilterDialog) onFilter();
    }, [startDate, endDate]);

    const onFilter = (queryDefault:any = null) => {
        let query = generateQuery();
        if (queryDefault) query = queryDefault;
        if (!query.startdate || !query.enddate) {
            return;
        }
        if (!query.category || (query.category && query.category.length === 0)) {
            query.category = "all";
        }
        if (!query.procedurecode) {
            query.procedurecode = "all";
        }
        if (!query.doctorid) {
            query.doctorid = "all";
        }
        let _type = "";
        switch(type) {
            case "confirmed_patient_list":
                _type = "getconfirmedappointmentdatalist";
                break;
            case "unconfirmed_patient_list":
                _type = "getunconfirmedappointmentdatalist";
                break;
            case  "waitlisted_patient_list":
                _type = "getwaitlistedappointmentdatalist";
                break;
            case "cancelled_patient_list":
                _type = "getcancelledappointmentdatalist";
                break;
            case "rescheduled_patient_list":
                _type = "getrescheduledappointmentdatalist";
                break;
            case "requested_patient_list":
                _type = "getrequestedappointmentdatalist";
                break;
            case "pre-screening_verified_patient_list":
                _type = "getprescreeningverifeddatalist";
                break;
            case "pre-screening_pending_patient_list":
                _type = "getprescreeingpendingdatalist";
                break;
            case "pre-screening_failed_patient_list":
                _type = "getprescreeningfaileddatalist";
                break;
            case "unscheduled_patients_list":
                _type = "getunscheduledappointmentdatalist";
                break;
            case "overdue_patients_list":
                _type = "getoverduedatalist";
                break;
            case "double_booked_patients_list":
                _type = "getdoublebookappointmentdatalist";
                break;
            case "pre-appointed_patients_list":
                _type = "getpreappointedpatientlist";
                break;
            case "due_immediately_patients_list":
                _type = "getdueimmediatelypatientlist";
                break;
        }
        dispatch(fetchLoadingPending());
        console.log(query, type);
        getAppointmentListAnalytics(query, _type).then((success:any) => {
            if (success.error === false) {
                setAppointmentList(_.groupBy(success.data, 'appointmentdate'));
            }
            setIsMounted(true);
            dispatch(fetchLoadingSuccess(false));
        }).catch((err:any) => {
            dispatch(fetchLoadingSuccess(false));
        });
    }

    const handleCustomerFilterApply = () => {
        onFilter();
    }

    const generateDownloadLink = () => {
        try {
            let query = generateQuery();
            let _type = "";
            switch(type) {
                case "confirmed_patient_list":
                    _type = "generateconfirmedxlsx";
                    break;
                case "unconfirmed_patient_list":
                    _type = "generateunconfirmedxlsx";
                    break;
                case  "waitlisted_patient_list":
                    _type = "generatewaitlistedxlsx";
                    break;
                case "cancelled_patient_list":
                    _type = "generatecancelledxlsx";
                    break;
                case "rescheduled_patient_list":
                    _type = "generaterescheduledxlsx";
                    break;
                case "requested_patient_list":
                    _type = "generaterequestedxlsx";
                    break;
                case "pre-screening_verified_patient_list":
                    _type = "generateprescreeingverifedxlsx";
                    break;
                case "pre-screening_pending_patient_list":
                    _type = "generateprescreeingpendingxlsx";
                    break;
                case "pre-screening_failed_patient_list":
                    _type = "generateprescreeingfailedxlsx";
                    break;
                case "unscheduled_patients_list":
                    _type = "generateunscheduledxlsx";
                    break;
                case "overdue_patients_list":
                    _type = "generateoverduexlsx";
                    break;
                case "double_booked_patients_list":
                    _type = "generatedoublebookxlsx";
                    break;
                case "pre-appointed_patients_list":
                    _type = "generatepreappointedpatientxlsx";
                    break;
                case "due_immediately_patients_list":
                    _type = "generatedueimmediatelypatientxlsx";
                    break;
            }
            if (_type === "") return;
            let url = configs.MESSAGE_CENTER_API + 'analytics/'+_type+'/'+query.clinicid;
            if (query.startdate) {
                url += '/'+query.startdate;
            }
            if (query.enddate) {
                url += '/'+query.enddate;
            }
            if (type === "overdue_patients_list") {
                url += '/'+ query.initialdays
            }
            if (type !== "unscheduled_patients_list" && type !== "pre-appointed_patients_list" &&
                        type !== "due_immediately_patients_list" &&
                type !== "double_booked_patients_list") {
                if (query.procedurecode) {
                    url += '/'+query.procedurecode;
                } else {
                    url += '/all';
                }
                if (query?.category) {
                    url += '/'+query.category;
                } else {
                    url += '/all';
                }
                if (query.doctorid) {
                    url += '/'+query.doctorid;
                } else {
                    url += '/all';
                }
            }
            return (<a href={url} target={"_self"} download onClick={() => {
                toast.success("It might take couple of minutes to download this report. Thank you for your patience. We will show a pop up to save the file once it is ready for download.");
            }}>Download</a>);
        }catch (err) {
            return null
        }
    }
    const getCategoryList = async () => {
        try {
            const response = await getAppointmentCategoryList({clinicid: clinic.id})
            const categories = response.data;
            setCategoryListMaster(categories.map((item:any) => ({label: item.procedure_code_category, value: item.procedure_code_category})));
            getProceedureCode("");
        } catch (err) {
        }
    }
    const getProceedureCode = async (category_code: any) => {
        try {
            let obj = {
                clinicid: clinic.id,
            };
            if (!category_code || (category_code && category_code.length === 0)) {
                Object.assign(obj, {
                    type: "all",
                    category: "",
                });
            } else {
                Object.assign(obj, {
                    type: "",
                    category: [category_code],
                });
            }
            const response = await getProcedureCodeList(obj);
            const categories:any = _.flattenDeep(response.data.map((item:any) => {
                return item.map((sub_item:any) => {
                    return sub_item.procedure_code_sikkaId
                });
            }));
            setProceedureCodeListMaster(categories.map((item:any) => ({label: item, value: item})));
        } catch (err) {
        }
    }

    const getProviders = async () => {
        try {
            const response = await getProviderList(clinic.uniqueId)
            if (response.providers) {
                setProvidersListMaster(response.providers.map((item:any) => ({value: item.id, label: item.fullname})));
            }
        } catch (err) {
        }
    }
    const generateOptionsForInitiationDays = () => {
        let options = [];
        for (let index=1; index <=180; index++) {
            options.push(<option value={index} key={index}>{index}</option>);
        }
        return options;
    }
    // function disabledDate() {
    //     if (clinic?.importDataDate) {
    //         return moment().valueOf() < moment(clinic?.importDataDate, "YYYY-MM-DD").add(1, 'day').valueOf();
    //     } else {
    //         return true;
    //     }
    //   }

    const handleDueAppointmentArchiveClick = (uniqueId: number, status: number) => {
        dispatch(fetchLoadingPending());
        setDueImmediatelyAppointmentArchive({
            appointment_unique_id: uniqueId,
            status: status
        }).then((success:any) => {
            if (success.error === false) {
                onFilter();
            }
            dispatch(fetchLoadingSuccess(false));
        }).catch((err:any) => {
            dispatch(fetchLoadingSuccess(false));
        });
    }
    return (
        <div className="app analytics-report">
            <ReportHeader showBack pageTitle={pageTitle} onBackClick={onBackClick}></ReportHeader>

            <div className="app-body" style={{ paddingTop: 0, marginTop: 20 }}>
            <div style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 99,
                    backgroundColor: "#f7f5f0"
                }}>
                <div className="w-100 d-flex justify-content-end">
                    <DatePicker
                        format="MM/DD/YYYY"
                        placeholder="MM/DD/YYYY"
                        value={date !== '' ? moment(date).toDate() : null}
                        onChange={handleDateChange}
                    />
                  {/* {
                    type !== "overdue_patients_list" &&
                    <>
                        <DatePicker style={{width: 170}} className="ms-1" value={week ? moment(week) : null} onChange={handleWeekChange} picker={"week"}></DatePicker>
                        <DatePicker style={{width: 170}} className="ms-1" value={month ? moment(month) : null} onChange={handleMonthChange} picker={"month"}></DatePicker>
                    </>
                  } */}
                </div>
                <div className="w-100 d-flex justify-content-end align-items-center">
                    {
                        type === "overdue_patients_list" &&
                        <>
                            <a className="mx-2" onClick={() => {
                                setStartDate(convertToSystemDate(moment().subtract(12, "months")));
                                setEndDate(convertToSystemDate(moment().subtract(6, "months")));
                                setSelectedInitiationDays(120);
                                setOverDue('6,12');
                            }}>7-12 Months</a>
                            <a className="mx-2" onClick={() => {
                                setStartDate(convertToSystemDate(moment().subtract(6, "months")));
                                setEndDate(convertToSystemDate(moment()));
                                setSelectedInitiationDays(120);
                                setOverDue('0,6');
                            }}>0-6 Months</a>
                        </>
                    }
                    {
                        type !== "unscheduled_patients_list" && type !== "pre-appointed_patients_list" &&
                        type !== "due_immediately_patients_list" && type !== "double_booked_patients_list" &&
                        type !== "pre-screening_verified_patient_list" &&
                        type !== "pre-screening_pending_patient_list" &&
                        type !== "pre-screening_failed_patient_list" &&
                        <button type="button" className="btn btn-link" onClick={() => {
                            setShowFilterOnMore(!showFilterOnMore);
                        }}>More</button>
                    }
                    {
                        type !== "overdue_patients_list" &&
                        <button type="button" className="btn btn-link" onClick={() => {
                            setShowCustomFilterDialog(true);
                        }}>Custom</button>
                    }
                    <button type="button" className="btn btn-link" onClick={() => {
                        resetFilter();
                    }}>Reset</button>
                </div>
                
                    {
                        showFilterOnMore &&
                        <>
                            <div className="w-100 d-flex justify-content-center mb-2">
                                <select style={{width: 170}} value={selectedCategory} onChange={handleCategoryChange}
                                    placeholder="Select Category">
                                    <option value={"all"}>All</option>
                                    {
                                        categoryListMaster.map((category:any) => {
                                            return <option value={category.value} key={category.value}>{category.label}</option>
                                        })
                                    }
                                </select>
                                <select style={{width: 170}} className="ms-1" value={selectedProceedureCode} onChange={handleProcedureCodeChange} placeholder="Select Procedure Code">
                                    <option value={"all"}>All</option>
                                    {
                                        proceedureCodeListMaster.map((category:any) => {
                                            return <option value={category.value} key={category.value}>{category.label}</option>
                                        })
                                    }
                                </select>
                                <select style={{width: 170}} className="ms-1" value={selectedProvider} onChange={handleProviderChange} placeholder="Select Provider">
                                    <option value={"all"}>All</option>
                                    {
                                        providersListMaster.map((provider:any) => {
                                            return (
                                                <option value={provider.value} key={provider.value}>{provider.label}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            {/* {
                                type === "overdue_patients_list" &&
                                <Select style={{width: 170}} className="ms-1" value={selectedInitiationDays} allowClear onChange={handleInitiationDays} placeholder="Select Initiation Days">
                                    {generateOptionsForInitiationDays()}
                                </Select>
                            } */}
                        </>
                    }
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            Object.keys(appointmentList).length !== 0 &&
                            <div className="d-flex"><div className="me-2">{generateDownloadLink()}</div>
                            <div><small>{moment(startDate).isValid() && <>({<>{moment(startDate).format("MM/DD/YYYY")} to {moment(endDate).format("MM/DD/YYYY")}</>})</>}</small></div>
                            </div>
                        }
                        <div className="writebtn"></div>
                    </div>
                    <hr />
                </div>
                {
                    Object.keys(appointmentList).length === 0 &&
                    <>Please select date, week or month to view desirable data</>
                }
                {
                    Object.keys(appointmentList).map((key) => {
                        return (
                            <div key={key} className="border-bottom pb-1">
                                {
                                    type !== "overdue_patients_list" &&
                                    <label className="h6">{key}</label> 
                                }
                                <div className="mt-4">
                                    {
                                        appointmentList[key].map((appointment:any, idx: number) => {
                                            if (appointment) {
                                                let category = appointment?.category || "";
                                                category = category.split(",");
                                                return (
                                                <>
                                                    <div className="appo_card card mb-4">
                                                        {
                                                            type === "due_immediately_patients_list" &&
                                                            <>
                                                                {
                                                                    !appointment?.isdueimmediatearchive ?
                                                                    <div className="edt-archive-btn">
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip">Archive</Tooltip>
                                                                            }
                                                                        >
                                                                            <div className="card-btn-chkboc" onClick={() => {
                                                                                handleDueAppointmentArchiveClick(appointment?.appointmentuniqueid, 1);
                                                                            }}>
                                                                                <i className="bi bi-archive"></i>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </div> :
                                                                    <div className="edt-archive-btn">
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip">Un-archive</Tooltip>
                                                                            }
                                                                        >
                                                                            <div className="card-btn-chkboc" onClick={() => {
                                                                                handleDueAppointmentArchiveClick(appointment?.appointmentuniqueid, 0);
                                                                            }}>
                                                                                <i className="bi bi-archive-fill"></i>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    <div className="appo_status">
                                                        <span className="badge me-2 rounded-pill bg-secondary">{appointment.doctorname}</span>
                                                        {
                                                           type === "overdue_patients_list" && appointment.appointment_date && appointment.appointment_time && 
                                                           <span className="badge me-2 rounded-pill bg-secondary">{appointment.appointment_date} - {appointment.appointment_time}</span>
                                                        }
                                                        {
                                                           type !== "overdue_patients_list" && appointment.appointmentstime && appointment.appointmentetime && 
                                                           <span className="badge me-2 rounded-pill bg-secondary">{appointment.appointmentstime} - {appointment.appointmentetime}</span>
                                                        }
                                                        {
                                                            category?.map((item: any) => {
                                                                return (
                                                                    <span className="badge ms-2 rounded-pill bg-secondary">{item}</span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className="appo_body">
                                                        <h6>{appointment.dependant_name}</h6>
                                                        <div className="d-flex">
                                                        <div className="box-left">
                                                            {
                                                                appointment.dependantphone ?
                                                                <p className="m-0">
                                                                    <a href="javascript:void(0)">{appointment.dependantphone}</a>
                                                                </p> : 
                                                                <>
                                                                    {
                                                                        appointment.guarantor_phone &&
                                                                        <p className="m-0">
                                                                            <a href="javascript:void(0)">{appointment.guarantor_phone} ({appointment?.guarantor_name})</a>
                                                                        </p>
                                                                    }
                                                                </>
                                                            }
                                                            {
                                                                appointment.dependantemailaddress &&
                                                                <p className="m-0">
                                                                    <a href={"mailto:"+appointment.dependantemailaddress}>{appointment.dependantemailaddress}</a>
                                                                </p>
                                                            }
                                                        </div>
                                                        <div className="box-right">
                                                            {/* {
                                                                category.map((item: any) => {
                                                                    return <p className="m-0" key={item + "category"}>{item}</p>
                                                                })
                                                            } */}
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    {/* <div className="d-flex flex-row" key={appointment.appointmentid}>
                                                        <label className="me-2">{appointment.dependant_name}</label>
                                                        <label className="me-2">{appointment.category}</label>
                                                        <label className="me-2">{appointment.procedurecode}</label>
                                                        <label className="me-2">{appointment.appointmentstime} - {appointment.appointmentetime}</label>
                                                    </div> */}
                                                </>
                                                )
                                            }
                                            return null;
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Modal show={showCustomFilterDialog} onHide={() => {
                    setShowCustomFilterDialog(false);
                }}
            >
                <Modal.Header>
                    <div className="modal-title">Custom Date</div>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => { setShowCustomFilterDialog(false) }}
                    ></button>
                </Modal.Header>
                <div className="date_picker">
                    <div className="input-group">
                        <label className="me-1">Start Date: </label>
                        <DatePicker
                            format="MM/DD/YYYY"
                            placeholder="MM/DD/YYYY"
                            value={startDate && startDate !== '' ? moment(startDate).toDate() : null}
                            onChange={(date) => {
                                setStartDate(moment(date));
                                if (moment(date).isAfter(endDate)) {
                                    setEndDate(date);
                                }
                            }}
                        />
                    </div>
                    <div className="input-group ms-1">
                        <label className="me-1">End Date: </label>
                        <DatePicker
                            format="MM/DD/YYYY"
                            placeholder="MM/DD/YYYY"
                            value={endDate && endDate!== '' ? moment(endDate).toDate() : null}
                            onChange={(date) => {
                                setEndDate(moment(date));
                            }}
                        />
                    </div>
                    {/* {
                      type === "overdue_patients_list" &&
                      <div className="input-group ms-1">
                        <label className="me-1">Initiation Days: </label>
                        <Select className="w-100" value={selectedInitiationDays} allowClear onChange={handleInitiationDays} placeholder="Select Initiation Days">
                          {generateOptionsForInitiationDays()}
                        </Select>
                      </div>
                    } */}
                </div>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            setWeek("");
                            setDate("");
                            setMonth("");
                            setShowCustomFilterDialog(false);
                            setTimeout(() => {
                                handleCustomerFilterApply();
                            }, 1000);
                        }}
                    >
                        Ok
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
