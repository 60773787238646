import React, { useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { fetchUserDataPending } from '../../../store/userData/actions';
import configs from '../../configs/apiConfigs';
import { changeStatus, uploadLogoDoctor } from '../../services/provider';
export const TitleBox = ({ profile, fetchProfile }: any) => {
    const uploadRef: any = useRef();
    const [imageError, setImageError] = useState(false);
    const [logo, setLogo]: any = useState({});
    const dispatch = useDispatch();
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const onChangeFile = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === "image/png" ||
                file.type === 'image/jpg' ||
                file.type == 'image/jpeg') {
                var reader: any = new FileReader();
                var url: any = reader.readAsDataURL(event.target.files[0]);
                reader.onloadend = function (e: any) {
                    //Initiate the JavaScript Image object.
                    var image = new Image();
                    //Set the Base64 string return from FileReader as source.
                    image.src = e.target.result;
                    //Validate the File Height and Width.
                    image.onload = function () {
                        var height = image.height;
                        var width = image.width;
                        if (height < 256 || width < 256) {
                            setImageError(true);
                            toast.warn('Image size is not valid.');
                            return false;
                        }
                        setImageError(false);
                        uploadLogoDoctor(profile.providerId, { type: 'upload', uphoto: file }).then((success) => {
                            if (success && success.status && success.status.error === false) {
                                fetchProfile();
                                toast.success('Image upload success.');
                                return;
                            }
                            toast.warn('Image upload failed.')
                        }).catch((err) => {
                            toast.error('Image upload failed.');
                        })
                        return true;
                    };
                };
                return;
            } else {
                toast.warn('Image type is not valid.');
                setImageError(true);
            }
        }
    }
    useEffect(() => {
        if (profile) {
            setLogo(profile.photo);
        }
    }, [profile]);
    const handleChangeStatus = () => {
        confirmAlert({
            title: '',
            message: 'Please confirm, if you want to ' + (profile.enableAppointment === "1" ? 'disable' : 'enable') + ' this configuration.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let request = {
                            "type": "enableAppointment",
                            "enableAppointment": profile.enableAppointment === "1" ? '0' : '1'
                        }
                        dispatch(fetchLoadingPending());
                        changeStatus(request, profile.providerId).then((success: any) => {
                            if (success.status.error === false) {
                                toast.success(success.status.msg);
                                dispatch(fetchUserDataPending({}));
                                fetchProfile();
                            } else if (success.status.error === true) {
                                toast.warn(success.status.msg);
                            }
                            dispatch(fetchLoadingSuccess(false));
                        }).catch((err) => {
                            dispatch(fetchLoadingSuccess(false))
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleShowOnFrontChangeStatus = () => {
        confirmAlert({
            title: '',
            message: 'Please confirm, if you want to ' + (profile.showOnFront === "1" ? 'disable' : 'enable') + ' this configuration.',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                        let request = {
                            "type": "showOnFront",
                            "showOnFront": profile.showOnFront === "1" ? '0' : '1'
                        }
                        dispatch(fetchLoadingPending());
                        changeStatus(request, profile.providerId).then((success: any) => {
                            if (success.status.error === false) {
                                toast.success(success.status.msg);
                                dispatch(fetchUserDataPending({}));
                                fetchProfile();
                            } else if (success.status.error === true) {
                                toast.warn(success.status.msg);
                            }
                            dispatch(fetchLoadingSuccess(false));
                        }).catch((err) => {
                            dispatch(fetchLoadingSuccess(false))
                        });
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-primary',
                    onClick: () => {

                    }
                }
            ]
        });
    }

    return (
        <div className="provider-detail-sec mb-4">
            <div className="card bg-light p-4 py-3">
                <div className="d-flex justify-content-between">
                    <div className="d-flex">
                        <div className="position-relative">
                            {
                                (logo && logo.name && logo.type) ?
                                    <>
                                        <img src={configs.IMAGE_URL + 'doctor/' + logo.name + '-200x200.' + logo.type} className="img-fluid border rounded-3" style={{ width: '80px' }} />
                                        <span className="cursor-pointer position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger p-1" onClick={() => { uploadRef.current.click() }}>
                                            <i className="bi bi-x font-size-12"></i>
                                        </span>
                                    </> :
                                    <div className="avatar avatar-lg border rounded-circle me-3 text-uppercase">
                                        {
                                            (profile && profile.name) &&
                                            <>
                                                {(profile.name.split(" ")[0] && profile.name.split(" ")[0].charAt(0))}
                                                {(profile.name.split(" ")[1] && profile.name.split(" ")[1].charAt(0))}
                                            </>
                                        }
                                    </div>
                            }
                        </div>
                        <input id="myInput" accept="image/png,image/gif,image/jpeg" multiple={false} type="file" ref={uploadRef}
                            style={{ display: 'none' }} onChange={onChangeFile} />
                        <div className="mx-4 align-self-center">{profile.name}<small className="text-muted d-block">Pro. ID - {profile ? profile.providerId : '-'}</small></div>
                        <div className="mx-4 align-self-center"><small className="text-muted d-block">Speciality</small>
                            {(profile && profile.specialities && profile.specialities.length > 0) ? profile.specialities.map((item: any) => item.name).toString() : '-'}
                        </div>
                        <div className="mx-4 align-self-center"><small className="text-muted d-block">PMS ID</small>
                            {(profile && profile.pmsProviderids) ? profile.pmsProviderids : '-'}
                        </div>
                    </div>
                    <div className="align-self-center">
                        {
                            (profile.onBoarding && profile.onBoarding.status !== 1) &&
                            <span className="badge rounded-pill bg-danger">Profile incomplete</span>
                        }
                    </div>
                    <div className="align-self-center">
                        <div className="form-check form-switch">
                            <label className="form-check-label text-muted small me-3" htmlFor="scheduleED">Enable/Disable</label>
                            <input disabled={(profile.onBoarding && profile.onBoarding.status !== 1)} className="form-check-input" type="checkbox" id="scheduleED" checked={profile ? profile.enableAppointment === "0" ? false : true : false} onChange={() => {
                                handleChangeStatus();
                            }} />
                        </div>
                    </div>
                    <div className="align-self-center">
                        <div className="form-check form-switch">
                            <label className="form-check-label text-muted small me-3" htmlFor="showFrontCheckbox">XCarePro Search</label>
                            <input disabled={(profile.onBoarding && profile.onBoarding.status !== 1)} className="form-check-input" type="checkbox" id="showFrontCheckbox" checked={profile ? profile.showOnFront === "0" ? false : true : false} onChange={() => {
                                handleShowOnFrontChangeStatus();
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}