const ERROR_LABELS = {
    LABELS: {
        INVALID_EMAIL_PASSWORD: "Invalid email address or password.",
        EMAIL: "Invalid email address.",
        PASSWORD_DONOT_MATCH: "Current password and repeat password do not match.",
        ENTER_NEW_PASSWORD: "Please enter new password.",
        CURRENT_PASSWORD: "Please enter current password.",
        ENTER_RE_TYPE_NEW_PASSWORD: "Please enter re type password",
        ONLY_ALPHABET_ALLOWED: "Only alphabets allowed",
        PHONE_INVALID: "Invalid Mobile Number",
        WEBSITE_INVALID: "Invalid Website",
        ADDRESS_INVALID: "Invalid Address",
        SUGGESSTED_ADDRESS: "Select Address from auto suggestion"
    }
}

export default ERROR_LABELS;