import { LOGIN, FetchLoginErrorAction, FetchLoginPendingAction, FetchLoginSuccessAction, LoginRequest, LoginResponse } from './types';
/* Action Creators */
export const fetchLoginPending = (login: LoginRequest): FetchLoginPendingAction => {
    return {
        type: LOGIN.LOAD_PENDING,
        payload: login
    };
};

export const fetchLoginSuccess = (loginResponse: LoginResponse): FetchLoginSuccessAction => {
    return {
        type: LOGIN.LOAD_SUCCESS,
        login: loginResponse
    }
}

export const fetchLoginError = (err: any): FetchLoginErrorAction => {
    return {
        type: LOGIN.LOAD_ERROR,
        error: err
    }
}

export const userLogout = () => {
    return {
        type: LOGIN.LOGGED_OUT
    }
}