import React, { useEffect, useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { DigitalDocDocumentModel } from '../../components/messageCenter/DigitalDocDocumentModel';
import { deleteDocument, getDocumentList, updateDocumentStatus } from '../../services/messageCenter';
import NotificationImg from "../../assets/images/notification-img.svg";
import AddBtnImg from "../../assets/images/add-btn-icon.svg";

export const DigitalDocDocument = () => {
    const [showAddNotification, setShowAddNotification] = useState(false);
    const [list, setList] = useState([]);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const dispatch = useDispatch();
    useEffect(() => {
        fetchList();
    }, []);
    const fetchList = () => {
        dispatch(fetchLoadingPending());
        getDocumentList({ clinicid: userData.clinics[0].id }).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.error === false) {
                setList(success.data);
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        })
    }

    const updatedocumentstatus = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Please confirm, if you want to ' + (item.isactive !== true ? 'enable' : 'disable') + ' this configuration.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        updateDocumentStatus({
                            clinicid: userData.clinics[0].id,
                            messagecenterdocument_id: item.messagecenterdocument_id,
                            isactive: !item.isactive
                        }).then((success) => {
                            if (success && success.error === false) {
                                fetchList();
                            }
                        }).catch((err) => {

                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        })
    }

    const deletedocument = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Please confirm, if you want to delete' + (item.documentname),
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteDocument({
                            clinicid: userData.clinics[0].id,
                            messagecenterdocument_id: item.messagecenterdocument_id
                        }).then((success) => {
                            if (success && success.error === false) {
                                fetchList();
                            }
                        }).catch((err) => {

                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ]
        });
    }

    return (
        <>
            <section className="automated-reminders" id="automatedReminders">
                <div className="row mx-0">
                    <div className="col-12" >
                        <div className="reminder-box">
                            <div className="d-flex justify-content-between">
                                <div className="me-3">
                                    {/* <div className="reminder-title">Digital Documents</div> */}
                                    {/* <div className="reminder-desc">Setup all Digital Doc. Documents</div> */}
                                </div>
                                <div className="align-self-center">
                                    <button className="btn btn-primary" onClick={() => {
                                        setShowAddNotification(true);
                                    }}><img src={AddBtnImg} className="me-2" /> Create Document</button>
                                </div>
                            </div>
                            {
                                useMemo(() => {
                                    return (
                                        list.map((item: any) => {
                                            return (
                                                <div className="my-4 border px-4 py-3 border-1 rounded d-flex custom-noti-box justify-content-between">
                                                    <div className="d-flex flex-grow-1">
                                                        <div className="noti-title d-flex align-items-center w-100">
                                                            <img src={NotificationImg} className="me-2" />
                                                            <a href={item.documentpath} target="_blank"><span className="fw-bold align-self-center ">{item.documentname}</span></a>
                                                        </div>
                                                    </div>
                                                    <div className='form-check form-switch d-inline-block align-self-center mx-2'>
                                                        {/* <small className="text-muted">Status</small> */}
                                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={item.isactive} onChange={(e) => { updatedocumentstatus(item) }} />
                                                    </div>
                                                    <div className="noti-edit d-flex align-self-center cursor-pointer">
                                                        <button className="btn p-0 btn-link me-2 text-secondary" onClick={() => {
                                                            deletedocument(item)
                                                        }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                                        {/* <div onClick={() => { deletedocument(item) }}>Delete</div> */}
                                                    </div>
                                                </div>
                                            )
                                        })

                                    )
                                }, [list])
                            }
                        </div>
                    </div>
                </div>
            </section>
            {
                showAddNotification &&
                <DigitalDocDocumentModel
                    hideModal={() => {
                        setShowAddNotification(false);
                    }}
                    clinicId={(userData && userData.clinics[0] && userData.clinics[0].id)}
                    fetchList={fetchList}
                ></DigitalDocDocumentModel>
            }
        </>
    )
}