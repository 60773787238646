export enum USER_EMAIL {
    LOAD_PENDING = 'LOAD_PENDING_USER_EMAIL',
    LOAD_ERROR = 'LOAD_FAILED_USER_EMAIL',
    LOAD_SUCCESS = 'LOAD_SUCCESS_USER_EMAIL'
};

export interface UserEmailRequest {
    uniqueId?: number;
    email: string;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface UserEmailResponse {
    email: string;
    emailVerified: string;
    status: Status
}
export interface UserEmailState {
    pending: boolean;
    userEmail: UserEmailRequest;
    userEmailResponse: UserEmailResponse;
    error: any;
}

export interface FetchUserEmailPendingAction {
    type: USER_EMAIL.LOAD_PENDING;
    payload: UserEmailRequest
}

export interface FetchUserEmailErrorAction {
    type: USER_EMAIL.LOAD_ERROR;
    error: any;
}
export interface FetchUserEmailSuccessAction {
    type: USER_EMAIL.LOAD_SUCCESS;
    userInfo: UserEmailResponse
}

export type UserEmailActionTypes = FetchUserEmailErrorAction | FetchUserEmailPendingAction | FetchUserEmailSuccessAction;