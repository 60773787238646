import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getPatientList = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'info/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const updateSendSMSStatus = (data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'infoedit/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}


export const getDependantDetail = (clinicId: any, dependantId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic/' + clinicId + '/dependant/' + dependantId + '/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const createLogin = (clinicId: any, dependantId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic/' + clinicId + '/makeaccount/' + dependantId + '/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const addMember = (data: any, clinicId: any, individual = false) => {
    let url = configs.API_URL + 'clinic/' + clinicId + '/dependant/add/';
    if (individual) url += '?mode=individual';
    const request: CommonApiOptions = {
        url: url,
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const editMember = (data: any, clinicId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic/' + clinicId + '/dependant/edit/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const createGuarantor = (data: any, clinicId: any, dependantId: any, individual = false) => {
    let url = configs.API_URL + 'clinic/' + clinicId + '/changeguarantor/' + dependantId + '/';
    if (individual) url += '?mode=individual';
    const request: CommonApiOptions = {
        url: url,
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const guarantorEdit = (data: any, clinicId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic/' + clinicId + '/guarantor/edit/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const updateDependantDeceased = (data: any, clinicId: any, dependantId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic/'+clinicId+'/dependant_archive/'+dependantId+'/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const updateDependantMedicinRemind = (data: any, clinicId: any, dependantId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic/'+clinicId+'/dependant_medicine_remind/'+dependantId+'/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}