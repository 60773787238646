import { KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { updateProviderProfile } from '../../../services/provider';
import moment from 'moment';
export const EducationCertifications = ({ providerId, fetchProfile, educations, certifications }: any) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [eduList, setEduList]: any = useState([]);
    const [certList, setCertList]: any = useState([]);
    useEffect(() => {
        if (educations) {
            setEduList(educations);
        }
    }, [educations]);
    useEffect(() => {
        if (certifications) {
            setCertList(certifications);
        }
    }, [certifications]);
    const onCancel = () => {
        setEduList(educations);
        setCertList(certifications);
        setIsEdit(false);
    };
    const onSave = () => {
        const data = {
            education: eduList,
            certificate: certList
        }
        setIsLoading(true);
        updateProviderProfile('educerts', providerId, data).then((success) => {
            if (success && success.status && success.status.error === false) {
                fetchProfile().then((success: any) => {
                    setIsEdit(false);
                    setTimeout(() => {
                        setIsLoading(false);
                        setIsEdit(false);
                    }, 2000);
                });
            }
        }).catch((err) => {
            setIsLoading(false);
        })
    }
    return (
        <div className="edu-cer-sec mb-4">
            <div className="card">
                <div className="card-header bg-light py-3 px-4">
                    <div className="d-flex justify-content-between">
                        <div className="fw-medium">Education & Certifications</div>
                        <div>
                            {
                                !isEdit && !isLoading &&
                                <button className="btn p-0 btn-link" onClick={() => {
                                    setIsEdit(true);
                                }}>Edit</button>
                            }
                            {
                                isEdit && !isLoading &&
                                <>
                                    <button className="btn p-0 btn-link me-2 text-secondary" onClick={() => {
                                        onCancel();
                                    }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                    <button className="btn p-0 btn-link" onClick={() => {
                                        onSave();
                                    }}><i className="bi bi-check-circle-fill text-success"></i></button>
                                </>
                            }
                            {
                                isEdit && isLoading &&
                                <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
                            }
                            {
                                isLoading && !isEdit &&
                                <i className="bi bi-check-circle-fill text-success"></i>
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    <div>
                        <div className="fw-medium">Board certifications</div>
                        <hr />
                        <div className="advances-table">
                            <table className="table table-hover">
                                <tbody>
                                    {
                                        eduList.map((item: any, index: number) => {
                                            return (
                                                <tr key={item.degree}>
                                                    <td>
                                                        <small className="text-muted">Association name</small>
                                                        <div>{item.associationName}</div>
                                                    </td>
                                                    <td>
                                                        <small className="text-muted">Received date</small>
                                                        <div>{moment(item.receviedDate, 'DD-MM-YYYY').format('MMM DD, YYYY')}</div>
                                                    </td>
                                                    <td>
                                                        <small className="text-muted">Registration number</small>
                                                        <div>{item.registrationNumber}</div>
                                                    </td>
                                                    <td className={"text-end"}>
                                                        {
                                                            isEdit &&
                                                            <button type="button" className="btn p-1" onClick={(e) => {
                                                                setEduList(eduList.filter((item: any, ind: number) => ind !== index));
                                                            }}>
                                                                <i className="bi bi-x text-danger"></i>
                                                            </button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <EducationInputs isEdit={isEdit} setEduList={setEduList} eduList={eduList}></EducationInputs>
                    </div>
                    <div>
                        <div className="fw-medium">Education</div>
                        <hr />
                        <div className="advances-table">
                            <table className="table table-hover">
                                <tbody>
                                    {
                                        certList.map((item: any, index: number) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <small className="text-muted">Degree</small>
                                                        <div>{item.degree}</div>
                                                    </td>
                                                    <td>
                                                        <small className="text-muted">University/College</small>
                                                        <div>{item.college}</div>
                                                    </td>
                                                    <td>
                                                        <small className="text-muted">Year</small>
                                                        <div>{item.year}</div>
                                                    </td>
                                                    <td className="text-end">
                                                        {
                                                            isEdit &&
                                                            <button type="button" className="btn p-1" onClick={() => {
                                                                setCertList(certList.filter((item: any, ind: number) => ind !== index));
                                                            }}>
                                                                <i className="bi bi-x text-danger"></i>
                                                            </button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <AssociationInputs isEdit={isEdit} certList={certList} setCertList={setCertList}></AssociationInputs>
                    </div>
                </div>
            </div>
        </div>

    )
};

const EducationInputs = ({ isEdit, setEduList, eduList }: any) => {
    const { register, errors, setValue, getValues, setError, handleSubmit, control } = useForm();
    const onSubmitEducation = (data: any) => {
        if (data.associationname.trim() === "" || data.receiveddate === "" || data.regnumber.trim() === "") {
            return;
        }
        setEduList([...eduList, {
            associationName: data.associationname,
            receviedDate: moment(data.receiveddate).format('DD-MM-YYYY'),
            registrationNumber: data.regnumber,
        }]);
        setValue('associationname', '');
        setValue('receiveddate', moment().format('MM-DD-YYYY'));
        setValue('regnumber', '');
    };
    console.log(errors);
    return (
        <div className={"addaptduetab " + (isEdit ? '' : 'd-none')}>
            <table className="table">
                <tr>
                    <td>
                        <div className="ps-0">
                            <label htmlFor="associationName" className="form-label d-none">Association name</label>
                            <input type="text" className="form-control" id="associationName" placeholder="Association name" name="associationname"
                                ref={register({ required: true })}
                            />
                            {
                                (errors && errors.associationname && errors.associationname.type === "required") &&
                                <div className="is-invalid">Required name</div>
                            }
                        </div>
                    </td>
                    <td>
                        <div className="miuif_margin">
                            <label htmlFor="receiveddate" className="form-label d-none">Received date</label>
                            <Controller
                                name="receiveddate"
                                control={control}
                                rules={{ required: true }}
                                defaultValue={moment().toDate()}
                                render={(props) => {
                                    return (
                                        <KeyboardDatePicker
                                            margin="normal"
                                            value={props.value}
                                            format='MM-DD-YYYY'
                                            maxDate={moment().toDate()}
                                            onChange={(date) => {
                                                props.onChange(date);
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    )
                                }}
                            ></Controller>
                            {
                                (errors && errors.receiveddate && errors.receiveddate.type === "required") &&
                                <div className="is-invalid">Required</div>
                            }
                        </div>
                    </td>
                    <td>
                        <div>
                            <label htmlFor="registrationNumber" className="form-label d-none">Registration number</label>
                            <input type="text" className="form-control" id="registrationNumber" placeholder="Registration number" name="regnumber"
                                ref={register({ required: true, maxLength: { value: 10, message: 'Allowed max character is 10' } })}
                            />
                            {
                                (errors && errors.regnumber && errors.regnumber.type === "required") &&
                                <div className="is-invalid">Required</div>
                            }
                            {
                                (errors && errors.regnumber && errors.regnumber.type === "maxLength") &&
                                <div className="is-invalid">{errors.regnumber.message}</div>
                            }
                        </div>
                    </td>
                    <td className="text-end">
                        <div className="pe-0"><button type="button" className="btn btn-outline-primary px-4" onClick={handleSubmit(onSubmitEducation)}> + </button></div>
                    </td>
                </tr>
            </table>
        </div>
    )
}

const AssociationInputs = ({ isEdit, certList, setCertList }: any) => {
    const { register, errors, setValue, getValues, setError, handleSubmit, control } = useForm();
    const onSubmitAssociation = (data: any) => {
        if (data.college.trim() === "" || data.degree.trim() === "" || data.year === "") {
            return;
        }
        setCertList([...certList, {
            college: data.college,
            degree: data.degree,
            year: moment(data.year).format('YYYY')
        }]);
        setValue('college', '');
        setValue('degree', '');
        setValue('year', moment().format('YYYY'));
    };
    console.log(errors);
    return (
        <div className={"addaptduetab " + (isEdit ? '' : 'd-none')}>
            <table className="table">
                <tr>
                    <td>
                        <div className="ps-0">
                            <label htmlFor="degree" className="form-label d-none">Degree</label>
                            <input type="text" className="form-control" id="degree" placeholder="Degree" name="degree"
                                ref={register({ required: true })} />
                            {
                                (errors && errors.degree && errors.degree.type === "required") &&
                                <div className="is-invalid">Required</div>
                            }
                        </div>
                    </td>
                    <td>
                        <div>
                            <label htmlFor="UniversityCollege" className="form-label d-none">University/College</label>
                            <input type="text" className="form-control" id="UniversityCollege" placeholder="University/College" name="college" ref={register({ required: true })} />
                            {
                                (errors && errors.college && errors.college.type === "required") &&
                                <div className="is-invalid">Required</div>
                            }
                        </div>
                    </td>
                    <td>
                        <div>
                            <label htmlFor="PYear" className="form-label d-none">Year</label>
                            <Controller
                                name="year"
                                control={control}
                                rules={{ required: true }}
                                render={(props) => {
                                    return (
                                        <DatePicker
                                            views={["year"]}
                                            id="practicetName"
                                            placeholder="1994"
                                            defaultValue={moment().year()}
                                            maxDate={moment()}
                                            value={props.value}
                                            onChange={(year: any) => { props.onChange(year) }}
                                        />
                                    )
                                }}
                            ></Controller>
                            {
                                (errors && errors.year && errors.year.type === "required") &&
                                <div className="is-invalid">Required</div>
                            }
                        </div>
                    </td>
                    <td className="text-end">
                        <div className="pe-0"><button type="button" className="btn btn-outline-primary px-4" onClick={handleSubmit(onSubmitAssociation)}> + </button></div>
                    </td>
                </tr>
            </table>
        </div>
    )
}