import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { BODY_TYPE } from '../../constants/api';
import { createStep, getAppointmentTypes } from '../../services/clinicOnboard';
export const AppointmentTypeHourMap = ({ onNext, onPrev, clinicUniqueId, specialities, potentialAppointmentTypes }: any) => {
    const [appointentTypesList, setAppointmentTypesList] = useState([]);
    const [specialityList, setSpecialityList] = useState([]);
    const [appTypeList, setAppTypeList] = useState([]);
    const [duration, setDuration] = useState('');
    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedAppointmentType, setSelectedAppointmentType] = useState('');
    const [list, setList]: any = useState([]);
    const [callOtherAction, setCallOtherAction] = useState(false);
    const dispatch = useDispatch();
    const onNextFunc = () => {
        onNext(7, {
            potentialAppointmentTypes: list.map((item: any) => {
                delete item.isEdit;
                delete item.cduration;
                return item;
            })
        });
    };
    useEffect(() => {
        getPotentialTypeList();
    }, []);
    useEffect(() => {
        if (specialities && specialities.specialities && callOtherAction) {
            setSpecialityList(specialities.specialities);
            setSelectedSpeciality(specialities.specialities[0].id);
            getAppointmentTypeFunc();
        }
    }, [specialities, callOtherAction]);
    useEffect(() => {
        if (potentialAppointmentTypes) {
            setList(potentialAppointmentTypes.map((item: any) => ({ ...item, cduration: item.duration })));
        }
    }, [potentialAppointmentTypes]);
    const getAppointmentTypeFunc = () => {
        dispatch(fetchLoadingPending());
        const specialitiesMapped = specialities.specialities.map((item: any) => item.id).toString();
        getAppointmentTypes({ speciality: specialitiesMapped }).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setAppointmentTypesList(success.appointmentTypes);
                const appointmentTypes = success.appointmentTypes.filter((item: any) => item.speciality === specialities.specialities[0].id);
                if (appointmentTypes) {
                    setAppTypeList(appointmentTypes);
                    if (appointmentTypes[0] && appointmentTypes[0].id) {
                        setSelectedAppointmentType(appointmentTypes[0].id);
                    } else {
                        setSelectedAppointmentType('');
                    }
                }
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            setAppointmentTypesList([]);
        });
    };
    const getPotentialTypeList = () => {
        dispatch(fetchLoadingPending());
        createStep({
            stepNumber: 7,
            formData: { type: "list", uniqueid: clinicUniqueId },
        }).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setList(success.potentialAppointmentTypes.map((item: any) => ({ ...item, cduration: item.duration })));
                setCallOtherAction(true);
            }
        }).catch((err) => {
            if (err && err.msg !== "") {
                toast.error(err.msg);
            }
            dispatch(fetchLoadingSuccess(false));
            setCallOtherAction(false)
        });
    }
    const removeAppointmentType = (id: any) => {
        dispatch(fetchLoadingPending());
        createStep({
            stepNumber: 7,
            formData: { type: "remove", id: id, uniqueid: clinicUniqueId },
        }).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setList(success.potentialAppointmentTypes.map((item: any) => ({ ...item, cduration: item.duration })));
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
        });
    };
    const addAppointmentTypes = () => {
        if (selectedSpeciality === '' || selectedAppointmentType === '' || duration === '' || duration.trim() === '') {
            toast.warn('All fields are required.');
            return;
        } else if (isNaN(Number(duration))) {
            toast.warn('Duration should be number.');
            return;
        } else if (Number(duration) <= 0) {
            toast.warn('Duration should be greater than 0.');
            return;
        }
        dispatch(fetchLoadingPending());
        createStep({
            stepNumber: 7,
            formData: { type: "add", id: selectedAppointmentType, duration: duration, uniqueid: clinicUniqueId },
        }).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setList(success.potentialAppointmentTypes.map((item: any) => ({ ...item, cduration: item.duration })));
            }
        }).catch((err) => {
            if (err && err.msg !== "") {
                toast.error(err.msg);
            }
            dispatch(fetchLoadingSuccess(false));
        });
    };
    const editAppointmentType = (item: any) => {
        dispatch(fetchLoadingPending());
        createStep({
            stepNumber: 7,
            formData: { type: "edit", id: item.id, duration: item.duration, uniqueid: clinicUniqueId },
        }).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setList(success.potentialAppointmentTypes.map((item: any) => {
                    return { ...item, cduration: item.duration };
                }));
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
        });
    }
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-12">
                    <h2 className="mb-3">Appointment types and duration</h2>
                    {/* <div className="fw-medium fs-5">Practice feature and facility</div> */}
                    <p className="mt-1">Please define appointment type duration to help your scheduling process.</p>
                    <hr className="my-4" />
                </div>
            </div>
            <div className="col-12">
                <div className="advances-table">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Speciality</th>
                                <th>Appointment type</th>
                                <th className="w-25">Duration</th>
                                <th className="text-end">Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                list.map((item: any, index: number) => {
                                    return (
                                        <tr key={item.id + item.name}>
                                            <td>{item.speciality}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                {
                                                    item.isEdit &&
                                                    <div className="form-floating">
                                                        <input type="text" className="form-control" id="durationmin" placeholder="ex - 30, 45, 60, 90" value={item.duration} onChange={(e) => {
                                                            if (!isNaN(Number(e.target.value))) {
                                                                setList(list.map((lItem: any) => {
                                                                    if (lItem.id === item.id) {
                                                                        return {
                                                                            ...lItem,
                                                                            duration: e.target.value,
                                                                        }
                                                                    }
                                                                    return lItem;
                                                                }));
                                                            }
                                                        }} />
                                                        <label htmlFor="durationmin">Duration (min)</label>
                                                    </div>
                                                }
                                                {
                                                    !item.isEdit &&
                                                    item.duration + " Min"
                                                }
                                            </td>
                                            <td className="text-end">
                                                {/* THis section opens when appointment is in edit state, put close and save icon here. */}
                                                {
                                                    item.isEdit &&
                                                    <>
                                                        <button type="button" className="btn" onClick={(e) => {
                                                            setList(list.map((lItem: any) => {
                                                                if (lItem.id === item.id) {
                                                                    delete lItem.isEdit;
                                                                    return { ...lItem, duration: lItem.cduration };
                                                                }
                                                                return lItem;
                                                            }));
                                                        }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                                        <button type="button" className="btn" onClick={(e) => {
                                                            editAppointmentType(item);
                                                        }}><i className="bi bi-check-circle-fill text-success"></i></button>
                                                    </>
                                                }
                                                {
                                                    !item.isEdit &&
                                                    <>
                                                        <button type="button" className="btn" onClick={(e) => {
                                                            setList(list.map((lItem: any) => {
                                                                if (lItem.id === item.id) {
                                                                    return {
                                                                        ...lItem,
                                                                        isEdit: true,
                                                                    }
                                                                }
                                                                return { ...lItem, isEdit: false };
                                                            }));
                                                        }}><i className="bi bi-pencil"></i></button>
                                                        <button type="button" className="btn" onClick={(e) => {
                                                            removeAppointmentType(item.id);
                                                        }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td>
                                    <div className="form-floating">
                                        <select className="form-select" id="sptype" aria-label="sptype" value={selectedSpeciality} onChange={(e) => {
                                            setSelectedSpeciality(e.target.value);
                                            const appTypes: any = appointentTypesList.filter((item: any) => item.speciality === e.target.value);
                                            if (appTypes) {
                                                setAppTypeList(appTypes);
                                                if (appTypes[0] && appTypes[0].id) {
                                                    setSelectedAppointmentType(appTypes[0].id);
                                                } else {
                                                    setSelectedAppointmentType('');
                                                }
                                            }
                                        }}>
                                            {
                                                specialityList.map((item: any) => {
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <label htmlFor="aptype">Speciality</label>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-floating">
                                        <select className="form-select" id="aptype" aria-label="aptype" value={selectedAppointmentType} onChange={(e) => {
                                            setSelectedAppointmentType(e.target.value);
                                        }}>
                                            {
                                                appTypeList.map((item: any) => {
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                        <label htmlFor="aptype">Appointment type</label>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="durationmin" placeholder="ex - 30, 45, 60, 90" value={duration} onChange={(e) => {
                                            setDuration(e.target.value);
                                        }} />
                                        <label htmlFor="durationmin">Duration (min)</label>
                                    </div>
                                </td>
                                <td className="text-end">
                                    <button type="button" className="btn btn-outline-primary h-100 px-4" onClick={(e) => {
                                        addAppointmentTypes();
                                    }}>+ Add</button>
                                </td>
                            </tr>


                        </tbody>
                    </table>

                </div>


            </div>
            <div className="col-12 my-4">
                <div className="bottom-btn-sec d-flex justify-content-between">
                    <button className="btn border me-1" onClick={() => { onPrev(5) }}>Back</button>
                    <button className="btn btn-primary ms-1" disabled={appointentTypesList.length === 0 || list.length === 0} onClick={() => {
                        onNextFunc();
                    }}>Next</button>
                </div>
            </div>
        </>
    )
}