import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
export const NotificationHeader = () => {
  return (
    <>
      <section className="middle-navbar px-30">
        <div className="p-0 container-fluid">
          <div className="d-flex justify-content-between">
            <div>
              <div className="nav-title-txt">Appointment reminders</div>
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <NavLink className="nav-link" exact={true} to={"/message-center/notification/reminder"}>Reminders</NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink className="nav-link" exact={true} to={"/message-center/notification/configuration"}>Configurations</NavLink>
                </li> */}
                <li className="nav-item">
                  <NavLink className="nav-link" exact={true} to={"/message-center/notification/patients"}>Patients</NavLink>
                </li>
              </ul>

            </div>

            {/* <div className="stp-action">
               
            </div> */}

          </div>
        </div>
      </section>
    </>
  )
}