import React from 'react';
import { Modal } from 'react-bootstrap';
export const ReadMore = ({ show, onHide }: any) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="modal-fullscreen"
            className="newcommon planpopup"
        >

            <Modal.Header className="border-0">
                <button type="button" className="btn-close" aria-label="Close" onClick={onHide}></button>
            </Modal.Header>

            <Modal.Body className="pt-0">

                <div>
                    <table className="table table-bordered plantbl">

                        <thead>
                            <tr>
                                <th rowSpan={3}>Account Type</th>
                                <th className="text-start">Plan + Upgrades</th>
                                <th colSpan={2}>Basic</th>
                                <th>Standard</th>
                                <th>PMS</th>
                                <th>Contactless Payments</th>
                                <th>Trust Horizon</th>
                                <th>Recall</th>
                                <th>Full Plan</th>
                            </tr>
                            <tr>

                                <th className="text-start">Price</th>
                                <th colSpan={2}>Pay as you go</th>
                                <th>$249</th>
                                <th>$100</th>
                                <th>$50</th>
                                <th>$100</th>
                                <th>$100</th>
                                <th>$549</th>
                            </tr>
                            <tr>
                                <th className="text-start">Card status</th>
                                <td className="text-danger">No card</td>
                                <td className="text-danger">With card</td>
                                <td className="text-danger" colSpan={6}>Credit Card Mandotary</td>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <th>Module</th>
                                <th className="text-start">Sub category</th>
                                <th colSpan={8}>Access enabled</th>
                            </tr>
                            <tr>
                                <td rowSpan={3}>Account</td>
                                <td className="text-start">My account</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td className="text-start">Subscription</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td className="text-start">Billing</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td rowSpan={2}>Profile</td>
                                <td className="text-start">Clinic</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td className="text-start">Provider</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td rowSpan={6}>Schedule</td>
                                <td className="text-start">Calendar</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td className="text-start">Upcomming</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td className="text-start">Waitlist</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td className="text-start">Cancelled</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td className="text-start">Pre-screening</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td className="text-start">Check-in</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td rowSpan={2}>Chat</td>
                                <td className="text-start">Xcare notifications</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td className="text-start">Text notifications</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td rowSpan={2}>Patient search</td>
                                <td className="text-start">Patient data import</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td className="text-start">Patient search</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td>Employee-prescreening</td>
                                <td></td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td rowSpan={2}>Message Center</td>
                                <td className="text-start">Appointment notifications</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td className="text-start">Marketing notifications</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td>PMS integration</td>
                                <td></td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td>Contactless payments</td>
                                <td></td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                            <tr>
                                <td>Trust Horizon</td>
                                <td></td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                                <td className="text-danger">No</td>
                                <td className="bg-success">Yes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal.Body>

        </Modal>
    )
}