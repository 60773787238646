import React, { useEffect, useRef, useState } from 'react';
import { AvatarComp } from '../Avatar';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import configs from '../../../configs/apiConfigs';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { IAppState } from '../../../../store';
import moment from 'moment';
import { getCookie } from '../../../utils/cookies';
import { CHAT } from '../../../../constants';
import $ from 'jquery';
import { sendSMSChat } from '../../../services/messageCenter';
import { toast } from 'react-toastify';
import { getAppointmentDetails } from '../../../services/chat';
import * as _ from 'lodash';
import { Avatar } from '@material-ui/core';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import CONSTANT_LABELS from '../../../../constants/labels';
import { removeCountryCode } from '../../../../utils/global';
import nl2br from 'react-nl2br';
import { uploadGotoImage } from '../../../services/clinicProfile';

let listener: any;
let apptListener: any;
export const DependentMessages = (props: any) => {

  const [dependent, setDependent]: any = useState('');
  const [messageList, setMessageList] = useState([]);
  const [chatMessageInput, setChatMessageInput] = useState('');
  const [appoitmentList, setAppointmentList] = useState([]);
  const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  const dispatch = useDispatch();
  const myMessageListRef = React.useRef(messageList);
  const uploadRef: any = useRef();
  const [imageUrl, setImageUrl]: any = useState([]);
  const setMyMessageList = (data: any) => {
    myMessageListRef.current = data;
    setMessageList(data);
  }
  useEffect(() => {
    return () => {
      if (apptListener) {
        apptListener();
      }
      if (listener) {
        listener();
      }
    }
  }, []);

  useEffect(() => {
    if (props.dependent) {
      if (listener) {
        listener();
      }
      if (apptListener) {
        apptListener();
      }
      setTimeout(() => {
        setDependent(props.dependent);
        getApptDetails().then((success) => {
          getMessages(props.dependent);
        });
      }, 1000);
    }
  }, [props.dependent]);

  const getApptDetails = async () => {
    const clinicUniqueId = userData.clinics[0].uniqueId;
    try {
      const response: any = await getAppointmentDetails(clinicUniqueId, props.dependent.uniqueid);
      if (response && response.status && response.status.error === false) {
        setAppointmentList(response.appointments);
      }
      return new Promise((res, rej) => { res(''); });
    } catch (err) {
      console.log(err);
      return new Promise((res, rej) => { rej(''); });
    }
  }

  const getAppointmentCollectionDoc = () => {
    if (apptListener) {
      apptListener();
    }
    const clinicUniqueId = userData.clinics[0].uniqueId;
    console.log("ClinicId", clinicUniqueId, props?.dependent?.uniqueid);
    apptListener = firebase.firestore().collection(configs.CHAT_COLLECTION)
      .where('sendToCId', '==', clinicUniqueId)
      .where('dependantId', '==', Number(props.dependent.uniqueid))
      .orderBy('createdAt', 'asc')
      .limit(20)
      .onSnapshot((snapshot) => {
        let docs = snapshot.docs.map((item: any) => ({
          ...item.data(), id: item.id, sortTime:
            moment(moment.unix(item.data()?.createdAt?.seconds)).toDate()
        }));
        // docs = docs.filter((item: any) => item.actionType !== CHAT.ACTION_TYPE.MESSAGE);
        console.log('docs', docs);
        setMyMessageList([...myMessageListRef.current, ...docs]);
        scrollToBottom();
      });
  }

  const getMessages = (dependent: any) => {
    dispatch(fetchLoadingPending());
    listener = firebase.firestore().collection(configs.CONVERSATION_DEV)
      .where('dependentUniqueId', '==', Number(dependent.uniqueid))
      .where('clinicId', '==', Number(userData.clinics[0].uniqueId))
      .orderBy("createdAt", 'asc')
      .limit(20)
      .onSnapshot((querySnapshot: any) => {
        const messages = querySnapshot.docs.map((item: any) => ({
          ...item.data(), id: item.id,
          sortTime: moment(moment.unix(item.data()?.createdAt?.seconds)).toDate()
        }));
        setMyMessageList(messages);
        if (apptListener) { apptListener(); }
        getAppointmentCollectionDoc();
        updateViewingAt(props.dependent.uniqueid);
        scrollToBottom();
        dispatch(fetchLoadingSuccess(false));
      });
  }

  const updateViewingAt = (uniqueId: any) => {
    firebase.firestore().collection(configs.CONVERSATION_DEV)
      .where("clinicId", "==", Number(userData.clinics[0].uniqueId))
      .where("dependentUniqueId", "==", Number(uniqueId))
      .where("metadata.viewByClinicAt", "==", null)
      .get().then((querySnapshot: any) => {
        querySnapshot.forEach((doc: any) => {
          const docRef = firebase.firestore().collection(configs.CONVERSATION_DEV).doc(doc.id);
          docRef.update({
            "metadata.viewByClinicAt": firebase.firestore.FieldValue.serverTimestamp(),
          });
        });
      }).catch((error: any) => {
        console.log('error while update view', error);
      });;
  }

  const onMessageSendClick = (e: any) => {
    e.preventDefault();
    if (userData.clinics[0].text_smschat === "on" && props.dependent.phone && props.dependent.chatsendsms === true) {
      sendSMSChat({
        "clinicuniqueid": userData.clinics[0].uniqueId,
        "phonenumber": props.dependent.phone,
        "dependantuniqueid": props.dependent.uniqueid,
        "message": chatMessageInput,
        imageUrl: imageUrl
      }).then((success) => {
        if (success && success.error === false) {
          const obj = {
            "clinicId": Number(userData.clinics[0].uniqueId),
            "senderId": Number(getCookie().xpr_user_id),
            "dependentUniqueId": Number(props.dependent.uniqueid),
            "sender": CHAT.SENDER.CLINIC,
            "message": chatMessageInput,
            "metadata": {
              viewByClinicAt: firebase.firestore.FieldValue.serverTimestamp(),
              viewByPatientAt: null
            },
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "medium": CHAT.SEND_MEDIUM.SMS,
            "firstName": userData.firstName,
            "lastName": userData.lastName,
            imageUrl: imageUrl
          };
          try {
            firebase.firestore().collection(configs.CONVERSATION_DEV).doc().set(obj)
              .then(() => {
                setChatMessageInput('');
                setImageUrl([]);
                scrollToBottom();
              })
              .catch((error: any) => {
                console.log('error while send message', error);
              });
          } catch (error) {
            console.log('error while send message', error);
          }
        } else {
          toast.warn('Send sms failed, seems like given mobile number is not valid.');
        }
      }).catch((err) => {
        toast.warn('Send sms failed, seems like given mobile number is not valid.');
      })
    } else {
      const obj = {
        "clinicId": Number(userData.clinics[0].uniqueId),
        "senderId": Number(getCookie().xpr_user_id),
        "dependentUniqueId": Number(props.dependent.uniqueid),
        "sender": CHAT.SENDER.CLINIC,
        "message": chatMessageInput,
        "metadata": {
          viewByClinicAt: firebase.firestore.FieldValue.serverTimestamp(),
          viewByPatientAt: null
        },
        "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
        "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
        "medium": CHAT.SEND_MEDIUM.CHAT,
        "firstName": userData.firstName,
        "lastName": userData.lastName
      };
      try {
        firebase.firestore().collection(configs.CONVERSATION_DEV).doc().set(obj)
          .then(() => {
            setChatMessageInput('');
            setImageUrl([]);
            scrollToBottom();
          })
          .catch((error: any) => {
            console.log('error while send message', error);
          });
      } catch (error) {
        console.log('error while send message', error);
      }
    }
  }

  const scrollToBottom = () => {
    $("#chat-box").animate({ scrollTop: $('#chat-box').prop("scrollHeight") }, 50);
  }

  console.log(messageList);
  let uniqueMessageById = _.uniqBy(myMessageListRef.current, 'id');
  console.log('uniqueMessageById', uniqueMessageById)
  let sortedArray = _.sortBy(uniqueMessageById, function (value: any) { return value.sortTime });
  console.log(sortedArray);

  const onChangeFile = (event: any) => {
    console.log("event", event);
      if (event.target.files[0]) {
        uploadGotoImage(userData.clinics[0].uniqueId, { type: 'upload', 
        uniqueId: userData.clinics[0].uniqueId, 
        uphoto: event.target.files[0], userType: 'clinic' }).then((res) => {
          setImageUrl([...imageUrl, res.url]);
          if (uploadRef && uploadRef.current) {
            //@ts-ignore
            uploadRef.current.value = '';
          }
        });
      }
  }

  return (
    <>
      <div className={"col-9 border-end position-relative " + (!dependent && 'd-none')}>
        <>
          <div className="position-absolute top-0 start-0 border-bottom w-100">
            <div className="d-flex align-items-center chat-cnt-header px-3">
              <AvatarComp name={dependent && dependent.fname + " " + dependent.lname}></AvatarComp>
              <div className="flex-grow-1 chat-user-details">
                <div className="fw-medium">{dependent && dependent.fname + " " + dependent.lname} <span className="text-muted">(ID - {dependent && dependent.uniqueid})</span></div>
                {/* <small className="text-black-50">ID- {dependent && dependent.uniqueid}</small> */}
                <small className="text-black-50">Email - <span className="fw-bold">{dependent && dependent.email}</span></small>
                <small className="text-black-50 ms-3">Phone - <span className="fw-bold">{dependent && dependent.phone && (CONSTANT_LABELS.PLUS_ONE + removeCountryCode(dependent.phone))}</span></small>
              </div>
            </div>
          </div>
          <div className="position-relative">
            <div className="chatbox-sc" id="chat-box">
              <div className="chat-messages p-4">
                {(sortedArray && sortedArray.length > 0) &&
                  sortedArray.map((value: any, index: number) => {
                    let letterOne = '', letterTwo = '';
                    if (value.firstName) {
                      letterOne = value.firstName.charAt(0);
                    }
                    if (value.lastName) {
                      letterTwo = value.lastName.charAt(0);
                    }
                    if (value.appointmentId) {
                      if (value.actionType === CHAT.ACTION_TYPE.MESSAGE) {
                        let letterOne = "",
                          letterTwo = "";
                        if (value.firstName) {
                          letterOne = value.firstName.charAt(0);
                        }
                        if (value.lastName) {
                          letterTwo = value.lastName.charAt(0);
                        }
                        if (value.sender === CHAT.SENDER.PATIENT) {
                          return (
                            <div className="chat-message-left cuser pb-4" key={index + Math.random()}>
                              {
                                (letterOne !== '' || letterTwo !== '') &&
                                <Avatar>{letterOne}{letterTwo}</Avatar>
                              }
                              
                              <div className="chatbu rounded py-2 px-3 bg-light">
                               <img src={value.imageUrl}></img>
                                <div>{nl2br(value.text)}</div>
                              </div>
                              <div className="text-muted text-end small text-nowrap">
                                {
                                  value &&
                                  value.createdAt &&
                                  <> {moment(moment.unix(value.createdAt.seconds)).format("MMM DD, YYYY - LT")}
                                  </>
                                }

                                <>
                                  {value.medium &&
                                    value.medium === CHAT.SEND_MEDIUM.APP && (
                                      <> - sent via app</>
                                    )}
                                  {value.medium &&
                                    value.medium === CHAT.SEND_MEDIUM.SMS && (
                                      <> - sent via sms</>
                                    )}
                                  {value.medium &&
                                    value.medium === CHAT.SEND_MEDIUM.CHAT && (
                                      <> - not sent via sms/app</>
                                    )}
                                  {/* {(value.firstName || value.lastName) && (
                                    <>
                                      {" "}
                                      <br />
                                      Sent by{" "}
                                      {value.firstName + " " + value.lastName}
                                    </>
                                  )} */}
                                </>
                              </div>
                            </div>
                          )
                        } else {
                          return (
                            <div className="chat-message-right cadmin pb-4" key={index + Math.random()}>
                              {
                                (letterOne !== '' || letterTwo !== '') &&
                                <Avatar>{letterOne}{letterTwo}</Avatar>
                              }
                              
                              <div className="chatbu rounded py-2 px-3 bg-light">
                              <img src={value.imageUrl}></img>
                              <div>{nl2br(value.text)}</div>
                              </div>
                              <div className="text-muted text-end small text-nowrap">
                                {
                                  value &&
                                  value.createdAt &&
                                  <> {moment(moment.unix(value.createdAt.seconds)).format("MMM DD, YYYY - LT")}</>
                                }

                                <>
                                  {value.medium &&
                                    value.medium === CHAT.SEND_MEDIUM.APP && (
                                      <> - sent via app</>
                                    )}
                                  {value.medium &&
                                    value.medium === CHAT.SEND_MEDIUM.SMS && (
                                      <> - sent via sms</>
                                    )}
                                  {value.medium &&
                                    value.medium === CHAT.SEND_MEDIUM.CHAT && (
                                      <> - not sent via sms/app</>
                                    )}
                                  {/*  {(value.firstName || value.lastName) && (
                                    <>
                                      {" "}
                                      <br />
                                      Sent by{" "}
                                      {value.firstName + " " + value.lastName}
                                    </>
                                  )} */}
                                </>
                              </div>
                            </div>
                          )
                        }
                      } else if (
                        value.actionType === CHAT.ACTION_TYPE.MESSAGE_TEXT
                      ) {
                        let letterOne = "",
                          letterTwo = "";
                        if (value.firstName) {
                          letterOne = value.firstName.charAt(0);
                        }
                        if (value.lastName) {
                          letterTwo = value.lastName.charAt(0);
                        }
                        if (value.sender === CHAT.SENDER.PATIENT) {
                          return (
                            <div className="chat-message-left cuser pb-4" key={index + Math.random()}>
                              {
                                (letterOne !== '' || letterTwo !== '') &&
                                <Avatar>{letterOne}{letterTwo}</Avatar>
                              }
                              <div className="chatbu rounded py-2 px-3 bg-light">
                                {nl2br(value.text)}
                              </div>
                              <div className="text-muted text-end small text-nowrap">
                                {
                                  value &&
                                  value.createdAt &&
                                  <> {moment(moment.unix(value.createdAt.seconds)).format("MMM DD, YYYY - LT")}
                                  </>
                                }

                                <>
                                  {value.medium &&
                                    value.medium === CHAT.SEND_MEDIUM.APP && (
                                      <> - sent via app</>
                                    )}
                                  {value.medium &&
                                    value.medium === CHAT.SEND_MEDIUM.SMS && (
                                      <> - sent via sms</>
                                    )}
                                  {value.medium &&
                                    value.medium === CHAT.SEND_MEDIUM.CHAT && (
                                      <> - not sent via sms/app</>
                                    )}
                                  {/*  {(value.firstName || value.lastName) && (
                                    <>
                                      {" "}
                                      <br />
                                      Sent by{" "}
                                      {value.firstName + " " + value.lastName}
                                    </>
                                  )} */}
                                </>
                              </div>
                            </div>
                          )
                        } else {
                          return (
                            <div className="chat-message-right cadmin pb-4" key={index + Math.random()}>
                              {
                                (letterOne !== '' || letterTwo !== '') &&
                                <Avatar>{letterOne}{letterTwo}</Avatar>
                              }
                              <div className="chatbu rounded py-2 px-3 bg-light">
                                {nl2br(value.text)}
                              </div>
                              <div className="text-muted text-end small text-nowrap">
                                {
                                  value &&
                                  value.createdAt &&
                                  <> {moment(moment.unix(value.createdAt.seconds)).format("MMM DD, YYYY - LT")}
                                  </>
                                }

                                <>
                                  {value.medium &&
                                    value.medium === CHAT.SEND_MEDIUM.APP && (
                                      <> - sent via app</>
                                    )}
                                  {value.medium &&
                                    value.medium === CHAT.SEND_MEDIUM.SMS && (
                                      <> - sent via sms</>
                                    )}
                                  {value.medium &&
                                    value.medium === CHAT.SEND_MEDIUM.CHAT && (
                                      <> - not sent via sms/app</>
                                    )}
                                  {/*  {(value.firstName || value.lastName) && (
                                    <>
                                      {" "}
                                      <br />
                                      Sent by{" "}
                                      {value.firstName + " " + value.lastName}
                                    </>
                                  )} */}
                                </>
                              </div>
                            </div>
                          )
                        }
                      } else if (
                        value.actionType ===
                        CHAT.ACTION_TYPE
                          .MESSAGE_CENTER_TEXT
                      ) {
                        let text = value.text;
                        const idx =
                          text.indexOf("Sent On");
                        const newTxt = text.substr(
                          idx,
                          value.text.length
                        );
                        text = text.replace(newTxt, "");
                        const index1 = text.indexOf("Sent");
                        const newTxt1 = text.substr(index1, text.length);
                        text = text.replace(newTxt1, "");
                        const appointment: any =
                          appoitmentList.find(
                            (appointment: any) =>
                              Number(
                                appointment.uniqueid
                              ) ===
                              Number(value.appointmentId)
                          );

                        return (
                          <div
                            className="alert alert-light border py-2 text-center"
                            key={index + Math.random()}
                          >
                            {appointment && (
                              <div>
                                {text} on{" "}
                                {value &&
                                  value.createdAt && (
                                    <>
                                      {" "}
                                      {moment(
                                        moment.unix(
                                          value.createdAt
                                            .seconds
                                        )
                                      ).format(
                                        "MMM DD, YYYY - LT"
                                      )}
                                      {value.senderName &&
                                        value.senderName !==
                                        "" && <></>}
                                    </>
                                  )}{" "}
                                {
                                  appointment.appointmentType
                                }{" "}
                                with{" "}
                                {
                                  appointment.doctor
                                    .fullname
                                }{" "}
                                for{" "}
                                {appointment.patient
                                  .firstName +
                                  " " +
                                  appointment.patient
                                    .lastName}{". "}
                                Appointment:{" "}{
                                  value?.dateTime?.date && value?.dateTime?.time ?
                                    <>
                                      {moment(
                                        value?.dateTime?.date +
                                        " " +
                                        value?.dateTime?.time,
                                        "YYYY-MM-DD LT"
                                      ).format(
                                        "MMM DD, YYYY - LT"
                                      )}
                                    </> :
                                    <>
                                      {moment(
                                        appointment.date +
                                        " " +
                                        appointment.time,
                                        "YYYY-MM-DD LT"
                                      ).format(
                                        "MMM DD, YYYY - LT"
                                      )}
                                    </>
                                }. XcarePro Appointment ID:{" "}{appointment.uniqueid}
                              </div>
                            )}
                          </div>
                        );
                      } else if (
                        value.actionType ===
                        CHAT.ACTION_TYPE.VOICE_CALL
                      ) {
                        return (
                          <div
                            className="alert alert-light border py-2 text-center"
                            key={index + Math.random()}
                          >
                            <span>{value.text}</span>
                          </div>
                        );
                      } else if (
                        value.actionType ===
                        CHAT.ACTION_TYPE.STATUS
                      ) {
                        const appointment: any =
                          appoitmentList.find(
                            (appointment: any) =>
                              Number(
                                appointment.uniqueid
                              ) ===
                              Number(value.appointmentId)
                          );
                        if (
                          value.toStatus ===
                          CHAT.TO_STATUS.COMPLETED
                        ) {
                          return (
                            <div
                              className="alert alert-success py-2 text-center"
                              key={index + Math.random()}
                            >
                              {appointment && (
                                <div>
                                  Appointment Completed:{" "}{appointment.appointmentType}{" "}
                                  with{" "}{appointment.doctor.fullname}{" "}for{" "}
                                  {appointment.patient.firstName + " " + appointment.patient.lastName}{". "}
                                  Appointment: {" "}
                                  {value?.dateTime?.date && value?.dateTime?.time ?
                                    <>
                                      {moment(
                                        value?.dateTime?.date +
                                        " " +
                                        value?.dateTime?.time,
                                        "YYYY-MM-DD LT"
                                      ).format(
                                        "MMM DD, YYYY - LT"
                                      )}
                                    </> :
                                    <>
                                      {moment(
                                        appointment.date +
                                        " " +
                                        appointment.time,
                                        "YYYY-MM-DD LT"
                                      ).format(
                                        "MMM DD, YYYY - LT"
                                      )}
                                    </>
                                  }. XcarePro Appointment ID:{" "}{appointment.uniqueid}.
                                  <div className="text-end alert-date-txt">
                                    {" "}
                                    Updated on:
                                    {value &&
                                      value.createdAt &&
                                      value.createdAt
                                        .seconds && (
                                        <>
                                          &nbsp;
                                          {moment(
                                            moment.unix(
                                              value
                                                .createdAt
                                                .seconds
                                            )
                                          ).format(
                                            "MMM DD, YYYY"
                                          ) +
                                            " - " +
                                            moment(
                                              moment.unix(
                                                value
                                                  .createdAt
                                                  .seconds
                                              )
                                            ).format(
                                              "LT"
                                            )}
                                        </>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        } else if (
                          value.toStatus ===
                          CHAT.TO_STATUS.CANCELLED
                        ) {
                          return (
                            <div
                              className="alert alert-danger py-2 text-center"
                              key={index + Math.random()}
                            >
                              {appointment && (
                                <div>
                                  Appointment Cancelled:{" "}{appointment.appointmentType}{" "}
                                  with{" "}{appointment.doctor.fullname}{" "}for{" "}
                                  {appointment.patient.firstName + " " + appointment.patient.lastName}{". "}
                                  Appointment: {" "}{
                                    value?.dateTime?.date && value?.dateTime?.time ?
                                      <>
                                        {moment(
                                          value?.dateTime?.date +
                                          " " +
                                          value?.dateTime?.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </> :
                                      <>
                                        {moment(
                                          appointment.date +
                                          " " +
                                          appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </>
                                  }. XcarePro Appointment ID:{" "}{appointment.uniqueid}.
                                  <div className="text-end alert-date-txt">
                                    Updated on:
                                    {value &&
                                      value.createdAt &&
                                      value.createdAt
                                        .seconds && (
                                        <>
                                          &nbsp;
                                          {moment(
                                            moment.unix(
                                              value
                                                .createdAt
                                                .seconds
                                            )
                                          ).format(
                                            "MMM DD, YYYY"
                                          ) +
                                            " - " +
                                            moment(
                                              moment.unix(
                                                value
                                                  .createdAt
                                                  .seconds
                                              )
                                            ).format(
                                              "LT"
                                            )}
                                        </>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        } else if (
                          value.fromStatus ===
                          CHAT.TO_STATUS.SCHEDULED &&
                          value.toStatus ===
                          CHAT.TO_STATUS.SCHEDULED
                        ) {
                          return (
                            <div
                              className="alert alert-info py-2 text-center"
                              key={index + Math.random()}
                            >
                              {appointment && (
                                <div>
                                  Appointment Re-scheduled:{" "}{appointment.appointmentType}{" "}with{" "}
                                  {appointment.doctor.fullname}{" "}for{" "}
                                  {appointment.patient.firstName + " " + appointment.patient.lastName}{". "}
                                  Re-schedule Appointment:{" "}
                                  {
                                    value?.dateTime?.date && value?.dateTime?.time ?
                                      <>
                                        {moment(
                                          value?.dateTime?.date +
                                          " " +
                                          value?.dateTime?.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </> :
                                      <>
                                        {moment(
                                          appointment.date +
                                          " " +
                                          appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </>
                                  }. XcarePro Appointment ID:{" "}{appointment.uniqueid}.
                                  <div className="text-end alert-date-txt">
                                    Updated on:
                                    {value &&
                                      value.createdAt &&
                                      value.createdAt
                                        .seconds && (
                                        <>
                                          &nbsp;
                                          {moment(
                                            moment.unix(
                                              value
                                                .createdAt
                                                .seconds
                                            )
                                          ).format(
                                            "MMM DD, YYYY"
                                          ) +
                                            " - " +
                                            moment(
                                              moment.unix(
                                                value
                                                  .createdAt
                                                  .seconds
                                              )
                                            ).format(
                                              "LT"
                                            )}
                                        </>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        } else if (
                          value.toStatus ===
                          CHAT.TO_STATUS.SCHEDULED
                        ) {
                          return (
                            <div
                              className="alert alert-info py-2 text-center"
                              key={index + Math.random()}
                            >
                              {appointment && (
                                <div>
                                  Appointment Scheduled:{" "}{appointment.appointmentType}{" "}with{" "}
                                  {appointment.doctor.fullname}{" "}for{" "}
                                  {appointment.patient.firstName + " " + appointment.patient.lastName}{". "}
                                  Appointment:{" "}
                                  {
                                    value?.dateTime?.date && value?.dateTime?.time ?
                                      <>
                                        {moment(
                                          value?.dateTime?.date +
                                          " " +
                                          value?.dateTime?.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </> :
                                      <>
                                        {moment(
                                          appointment.date +
                                          " " +
                                          appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </>
                                  }. XcarePro Appointment ID:{" "}{appointment.uniqueid}
                                  <div className="text-end alert-date-txt">
                                    Updated on:{value &&
                                      value.createdAt &&
                                      value.createdAt
                                        .seconds && (
                                        <>
                                          &nbsp;
                                          {moment(
                                            moment.unix(
                                              value
                                                .createdAt
                                                .seconds
                                            )
                                          ).format(
                                            "MMM DD, YYYY"
                                          ) +
                                            " - " +
                                            moment(
                                              moment.unix(
                                                value
                                                  .createdAt
                                                  .seconds
                                              )
                                            ).format(
                                              "LT"
                                            )}
                                        </>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        }
                      } else if (
                        value.actionType ===
                        CHAT.ACTION_TYPE.PRESCREENING
                      ) {
                        const appointment: any =
                          appoitmentList.find(
                            (appointment: any) =>
                              Number(
                                appointment.uniqueid
                              ) ===
                              Number(value.appointmentId)
                          );
                        return (
                          <>
                            <div
                              className="alert alert-info py-2 text-center"
                              key={index + Math.random()}
                            >
                              {appointment && (
                                <div>
                                  Appointment Pre-screening Complete:{" "}{appointment.appointmentType}{" "}with{" "}
                                  {appointment.doctor.fullname}{" "}for{" "}
                                  {appointment.patient.firstName + " " + appointment.patient.lastName}{". "}
                                  Appointment: {" "}{
                                    value?.dateTime?.date && value?.dateTime?.time ?
                                      <>
                                        {moment(
                                          value?.dateTime?.date +
                                          " " +
                                          value?.dateTime?.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </> :
                                      <>
                                        {moment(
                                          appointment.date +
                                          " " +
                                          appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </>
                                  }. XcarePro Appointment ID:{" "}{appointment.uniqueid}.
                                  <div className="text-end alert-date-txt">
                                    Updated on:
                                    {value &&
                                      value.createdAt &&
                                      value.createdAt
                                        .seconds && (
                                        <>
                                          &nbsp;
                                          {moment(
                                            moment.unix(
                                              value
                                                .createdAt
                                                .seconds
                                            )
                                          ).format(
                                            "MMM DD, YYYY"
                                          ) +
                                            " - " +
                                            moment(
                                              moment.unix(
                                                value
                                                  .createdAt
                                                  .seconds
                                              )
                                            ).format(
                                              "LT"
                                            )}
                                        </>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      } else if (
                        value.actionType ===
                        CHAT.ACTION_TYPE
                          .PRESCREENING_STATUS
                      ) {
                        const appointment: any =
                          appoitmentList.find(
                            (appointment: any) =>
                              Number(
                                appointment.uniqueid
                              ) ===
                              Number(value.appointmentId)
                          );
                        return (
                          <div
                            className="alert alert-success py-2 text-center"
                            key={index + Math.random()}
                          >
                            {appointment && (
                              <div>
                                Appointment Prescreening - Verified:{" "}
                                {appointment.appointmentType}{" "}with{" "}
                                {appointment.doctor.fullname}{" "}
                                for{" "}{appointment.patient.firstName + " " + appointment.patient.lastName}{". "}
                                Appointment:{" "}
                                {
                                  value?.dateTime?.date && value?.dateTime?.time ?
                                    <>
                                      {moment(
                                        value?.dateTime?.date +
                                        " " +
                                        value?.dateTime?.time,
                                        "YYYY-MM-DD LT"
                                      ).format(
                                        "MMM DD, YYYY - LT"
                                      )}
                                    </> :
                                    <>
                                      {moment(
                                        appointment.date +
                                        " " +
                                        appointment.time,
                                        "YYYY-MM-DD LT"
                                      ).format(
                                        "MMM DD, YYYY - LT"
                                      )}
                                    </>
                                }. XcarePro Appointment ID:{" "}{appointment.uniqueid}.
                                <div className="text-end alert-date-txt">
                                  Updated on:
                                  {value &&
                                    value.createdAt &&
                                    value.createdAt
                                      .seconds && (
                                      <>
                                        &nbsp;
                                        {moment(
                                          moment.unix(
                                            value
                                              .createdAt
                                              .seconds
                                          )
                                        ).format(
                                          "MMM DD, YYYY"
                                        ) +
                                          " - " +
                                          moment(
                                            moment.unix(
                                              value
                                                .createdAt
                                                .seconds
                                            )
                                          ).format("LT")}
                                      </>
                                    )}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      } else if (
                        value.actionType ===
                        CHAT.ACTION_TYPE
                          .PRESCREENING_REQUEST
                      ) {
                        const appointment: any =
                          appoitmentList.find(
                            (appointment: any) =>
                              Number(
                                appointment.uniqueid
                              ) ===
                              Number(value.appointmentId)
                          );
                        return (
                          <div
                            className="alert alert-warning py-2 text-center"
                            key={index + Math.random()}
                          >
                            {appointment && (
                              <div>
                                Appointment Prescreening - Request:{" "}{appointment.appointmentType}{" "}with{" "}
                                {appointment.doctor.fullname}{" "}for{" "}
                                {appointment.patient.firstName + " " + appointment.patient.lastName}{". "}
                                Appointment:{" "}
                                {
                                  value?.dateTime?.date && value?.dateTime?.time ?
                                    <>
                                      {moment(
                                        value?.dateTime?.date +
                                        " " +
                                        value?.dateTime?.time,
                                        "YYYY-MM-DD LT"
                                      ).format(
                                        "MMM DD, YYYY - LT"
                                      )}
                                    </> :
                                    <>
                                      {moment(
                                        appointment.date +
                                        " " +
                                        appointment.time,
                                        "YYYY-MM-DD LT"
                                      ).format(
                                        "MMM DD, YYYY - LT"
                                      )}
                                    </>
                                }. XcarePro Appointment ID:{" "}{appointment.uniqueid}.
                                <div className="text-end alert-date-txt">
                                  Updated on:
                                  {value &&
                                    value.createdAt &&
                                    value.createdAt
                                      .seconds && (
                                      <>
                                        &nbsp;
                                        {moment(
                                          moment.unix(
                                            value
                                              .createdAt
                                              .seconds
                                          )
                                        ).format(
                                          "MMM DD, YYYY"
                                        ) +
                                          " - " +
                                          moment(
                                            moment.unix(
                                              value
                                                .createdAt
                                                .seconds
                                            )
                                          ).format("LT")}
                                      </>
                                    )}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      } else if (
                        value.actionType ===
                        CHAT.ACTION_TYPE
                          .APPONINTMENT_ASSIGN
                      ) {
                        const appointment: any =
                          appoitmentList.find(
                            (appointment: any) =>
                              Number(
                                appointment.uniqueid
                              ) ===
                              Number(value.appointmentId)
                          );
                        return (
                          <div
                            className="alertparent"
                            key={index + Math.random()}
                          >
                            {appointment && (
                              <div>
                                Appointment Assign:{" "}
                                {
                                  appointment.appointmentType
                                }{" "}
                                with{" "}
                                {
                                  appointment.doctor
                                    .fullname
                                }{" "}
                                for{" "}
                                {appointment.patient
                                  .firstName +
                                  " " +
                                  appointment.patient
                                    .lastName}{". "}
                                Appointment:{" "}
                                {
                                  value?.dateTime?.date && value?.dateTime?.time ?
                                    <>
                                      {moment(
                                        value?.dateTime?.date +
                                        " " +
                                        value?.dateTime?.time,
                                        "YYYY-MM-DD LT"
                                      ).format(
                                        "MMM DD, YYYY - LT"
                                      )}
                                    </> :
                                    <>
                                      {moment(
                                        appointment.date +
                                        " " +
                                        appointment.time,
                                        "YYYY-MM-DD LT"
                                      ).format(
                                        "MMM DD, YYYY - LT"
                                      )}
                                    </>
                                }{" "}
                                assigned to email{" "}
                                {value.assignToEmail}.
                                XcarePro Appointment ID:{" "}
                                {appointment.uniqueid}.
                                Updated on:
                                {value &&
                                  value.createdAt &&
                                  value.createdAt
                                    .seconds && (
                                    <>
                                      &nbsp;
                                      {moment(
                                        moment.unix(
                                          value.createdAt
                                            .seconds
                                        )
                                      ).format(
                                        "MMM DD, YYYY"
                                      ) +
                                        " - " +
                                        moment(
                                          moment.unix(
                                            value
                                              .createdAt
                                              .seconds
                                          )
                                        ).format("LT")}
                                    </>
                                  )}
                              </div>
                            )}
                          </div>
                        );
                      } else if (
                        value.actionType ===
                        CHAT.ACTION_TYPE.CHECKIN
                      ) {
                        const appointment: any =
                          appoitmentList.find(
                            (appointment: any) =>
                              Number(
                                appointment.uniqueid
                              ) ===
                              Number(value.appointmentId)
                          );
                        return (
                          <>
                            <div
                              className="alert alert-warning py-2 text-center"
                              key={index + Math.random()}
                            >
                              {appointment && (
                                <div>
                                  Appointment Check-in - ({value.text}) :{" "}{appointment.appointmentType}{" "}
                                  with{" "}
                                  {appointment.doctor.fullname}{" "}
                                  for{" "}
                                  {appointment.patient.firstName + " " + appointment.patient.lastName}{". "}
                                  Appointment:{" "}
                                  {
                                    value?.dateTime?.date && value?.dateTime?.time ?
                                      <>
                                        {moment(
                                          value?.dateTime?.date +
                                          " " +
                                          value?.dateTime?.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </> :
                                      <>
                                        {moment(
                                          appointment.date +
                                          " " +
                                          appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </>
                                  }{" "}
                                  assigned to email{" "}
                                  {value.assignToEmail}.
                                  XcarePro Appointment ID:{" "}
                                  {appointment.uniqueid}.
                                  <div className="text-end alert-date-txt">
                                    Updated on:
                                    {value &&
                                      value.createdAt &&
                                      value.createdAt
                                        .seconds && (
                                        <>
                                          &nbsp;
                                          {moment(
                                            moment.unix(
                                              value
                                                .createdAt
                                                .seconds
                                            )
                                          ).format(
                                            "MMM DD, YYYY"
                                          ) +
                                            " - " +
                                            moment(
                                              moment.unix(
                                                value
                                                  .createdAt
                                                  .seconds
                                              )
                                            ).format(
                                              "LT"
                                            )}
                                        </>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      } else if (
                        value.actionType ===
                        CHAT.ACTION_TYPE
                          .CANCELLED_BY_PATIENT
                      ) {
                        const appointment: any =
                          appoitmentList.find(
                            (appointment: any) =>
                              Number(
                                appointment.uniqueid
                              ) ===
                              Number(value.appointmentId)
                          );
                        return (
                          <>
                            <div
                              className="alert alert-warning py-2 text-center"
                              key={index + Math.random()}
                            >
                              {appointment && (
                                <div>
                                  Appointment Re-scheduled Request by Patient:{" "}{appointment.appointmentType} with
                                  {appointment.doctor
                                    .fullname
                                  } for {appointment.patient
                                    .firstName +
                                    " " +
                                    appointment.patient
                                      .lastName}.
                                  Re-scheduled Request:{" "}{
                                    value?.dateTime?.date && value?.dateTime?.time ?
                                      <>
                                        {moment(
                                          value?.dateTime?.date +
                                          " " +
                                          value?.dateTime?.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                        {
                                          (value.dateTime.endtime) &&
                                          <>{" "}and {" "}
                                            {moment(
                                              value?.dateTime?.date +
                                              " " +
                                              value?.dateTime?.endtime,
                                              "YYYY-MM-DD LT"
                                            ).format(
                                              "LT"
                                            )}
                                          </>
                                        }
                                      </> :
                                      <>
                                        {moment(
                                          appointment.date +
                                          " " +
                                          appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </>
                                  }
                                  {" "}XcarePro Appointment ID:{" "}{appointment.uniqueid}.
                                  <div className="text-end alert-date-txt">
                                    {" "}
                                    Updated on:
                                    {value &&
                                      value.createdAt &&
                                      value.createdAt
                                        .seconds && (
                                        <>
                                          &nbsp;
                                          {moment(
                                            moment.unix(
                                              value
                                                .createdAt
                                                .seconds
                                            )
                                          ).format(
                                            "MMM DD, YYYY"
                                          ) +
                                            " - " +
                                            moment(
                                              moment.unix(
                                                value
                                                  .createdAt
                                                  .seconds
                                              )
                                            ).format(
                                              "LT"
                                            )}
                                        </>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      } else if (
                        value.actionType ===
                        CHAT.ACTION_TYPE
                          .CONFIRM_BY_PATIENT
                      ) {
                        const appointment: any =
                          appoitmentList.find(
                            (appointment: any) =>
                              Number(
                                appointment.uniqueid
                              ) ===
                              Number(value.appointmentId)
                          );
                        return (
                          <>
                            <div
                              className="alert alert-warning py-2 text-center"
                              key={index + Math.random()}
                            >
                              {appointment && (
                                <div>
                                  Appointment Confirmed:{" "}
                                  {
                                    appointment.appointmentType
                                  }{" "}
                                  with{" "}
                                  {
                                    appointment.doctor
                                      .fullname
                                  }{" "}
                                  for{" "}
                                  {appointment.patient
                                    .firstName +
                                    " " +
                                    appointment.patient
                                      .lastName}{". "}
                                  Appointment: {" "}
                                  {
                                    value?.dateTime?.date && value?.dateTime?.time ?
                                      <>
                                        {moment(
                                          value?.dateTime?.date +
                                          " " +
                                          value?.dateTime?.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </> :
                                      <>
                                        {moment(
                                          appointment.date +
                                          " " +
                                          appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </>
                                  }. XcarePro Appointment ID:{" "}{appointment.uniqueid}.
                                  <div className="text-end alert-date-txt">
                                    Updated on:
                                    {value &&
                                      value.createdAt &&
                                      value.createdAt
                                        .seconds && (
                                        <>
                                          &nbsp;
                                          {moment(
                                            moment.unix(
                                              value
                                                .createdAt
                                                .seconds
                                            )
                                          ).format(
                                            "MMM DD, YYYY"
                                          ) +
                                            " - " +
                                            moment(
                                              moment.unix(
                                                value
                                                  .createdAt
                                                  .seconds
                                              )
                                            ).format(
                                              "LT"
                                            )}
                                        </>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      } else if (value.actionType === CHAT.ACTION_TYPE.TRIAGE_EMERGENCY) {
                        const appointment: any =
                          appoitmentList.find(
                            (appointment: any) =>
                              Number(
                                appointment.uniqueid
                              ) ===
                              Number(value.appointmentId)
                          );
                        return (
                          <>
                            <div
                              className="alert alert-warning py-2 text-center"
                              key={index + Math.random()}
                            >
                              {appointment && (
                                <div>
                                  Emergency Triage Requested:{" "}
                                  {
                                    appointment.appointmentType
                                  }{" "}
                                  with{" "}
                                  {
                                    appointment.doctor
                                      .fullname
                                  }{" "}
                                  for{" "}
                                  {appointment.patient
                                    .firstName +
                                    " " +
                                    appointment.patient
                                      .lastName}{". "}
                                  Appointment: {" "}
                                  {
                                    value?.dateTime?.date && value?.dateTime?.time ?
                                      <>
                                        {moment(
                                          value?.dateTime?.date +
                                          " " +
                                          value?.dateTime?.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </> :
                                      <>
                                        {moment(
                                          appointment.date +
                                          " " +
                                          appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format(
                                          "MMM DD, YYYY - LT"
                                        )}
                                      </>
                                  }. XcarePro Appointment ID:{" "}{appointment.uniqueid}.
                                  <div className="text-end alert-date-txt">
                                    Updated on:
                                    {value &&
                                      value.createdAt &&
                                      value.createdAt
                                        .seconds && (
                                        <>
                                          &nbsp;
                                          {moment(
                                            moment.unix(
                                              value
                                                .createdAt
                                                .seconds
                                            )
                                          ).format(
                                            "MMM DD, YYYY"
                                          ) +
                                            " - " +
                                            moment(
                                              moment.unix(
                                                value
                                                  .createdAt
                                                  .seconds
                                              )
                                            ).format(
                                              "LT"
                                            )}
                                        </>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      }
                    } else if (value.sender === 'clinic') {
                      return (
                        <div className="chat-message-right cadmin pb-4" key={index + Math.random()}>
                          {
                            (letterOne !== '' || letterTwo !== '') &&
                            // <div className="avatar avatar-icon avatar-md newAvtr">
                            <Avatar>{letterOne}{letterTwo}</Avatar>
                            // </div>
                          }
                          
                          <div className="chatbu rounded py-2 px-3 bg-light">
                            <img src={value.imageUrl}></img>
                            <div>{value.message}</div>
                          </div>
                          <div className="text-muted text-end small text-nowrap">
                            {
                              value &&
                              value.createdAt &&
                              <> {moment(moment.unix(value.createdAt.seconds)).format("MMM DD, YYYY - LT")}
                                {
                                  (value.fullName && value.fullName !== "")
                                }
                              </>
                            }
                            {
                              (value.medium && value.medium === CHAT.SEND_MEDIUM.SMS) &&
                              <> - sent via sms</>
                            }
                            {
                              (value.medium && value.medium === CHAT.SEND_MEDIUM.CHAT) &&
                              <> - not sent via sms/app</>
                            }
                          </div>
                        </div>
                      )
                    } else if (value.sender === 'patient') {
                      return (
                        <div className="chat-message-left cuser pb-4" key={index + Math.random()}>
                          {
                            (letterOne !== '' || letterTwo !== '') &&
                            // <div className="avatar avatar-icon avatar-md newAvtr">
                            <Avatar>{letterOne}{letterTwo}</Avatar>
                            // </div>
                          }
                          
                          <div className="chatbu rounded py-2 px-3 bg-primary text-white">
                            <img src={value.imageUrl}></img>
                            <div>{value.message}</div>
                          </div>
                          <div className="text-muted text-start small text-nowrap">
                            {
                              value &&
                              value.createdAt &&
                              <> {moment(moment.unix(value.createdAt.seconds)).format("MMM DD, YYYY - LT")}
                                {
                                  (value.fullName && value.fullName !== "")
                                }
                              </>
                            }
                            {
                              (value.medium && value.medium === CHAT.SEND_MEDIUM.SMS) &&
                              <>- received via sms</>
                            }
                          </div>
                        </div>
                      )
                    }
                  })
                }
              </div>
            </div>
          </div>
        </>
        
        <div className="position-absolute bottom-0 start-0 border-top w-100">

          <div className='preview-image'>
              {
              imageUrl?.map((item: any, idx: number) => {
                return (
                  <React.Fragment>
                    <i onClick={() => {
                      setImageUrl((imageUrl: any) => imageUrl?.filter((item: any, index: number) => index !== idx))
                    }} className="bi bi-x-circle-fill"></i>
                    <img src={item} width={200}></img>
                  </React.Fragment>
                )
              })
            }
          </div>
          <div className="d-flex align-items-center ch-write-sec px-3">
            <div className="input-group">
              <input type="text" className="form-control" placeholder="Type your message" value={chatMessageInput} onChange={(e) => {
                if (e.target.value.length > 160) {
                  return;
                }
                setChatMessageInput(e.target.value)
              }} />
              <button className="btn btn-primary" disabled={!(chatMessageInput.length > 0 || imageUrl.length > 0)}
                onClick={(e: any) => { onMessageSendClick(e) }}
              >Send</button>
              <input id="myInput" className='d-none' accept="image/png,image/gif,image/jpeg" type="file" ref={uploadRef} style={{ display: 'none' }} onChange={onChangeFile} />
              {
                userData?.clinics[0]?.clinicSetting.communicationprovider === "goto" &&
                  <button
                    className={"btn btn-primary"}
                    onClick={(e) => {
                      //@ts-ignore
                      uploadRef.current.click();
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </button>
              }
            </div>
          </div>
        </div>
      </div>
      {/* {
                (props.dependent) &&
                <DependentDetail dependent={props.dependent} />
            } */}
    </>
  )
}
