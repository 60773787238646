
export enum BILLING {
    BILLING_LOAD_PENDING = 'BILLING_LOAD_PENDING',
    BILLING_LOAD_SUCCESS = 'BILLING_LOAD_SUCCESS',
    BILLING_LOAD_ERROR = 'BILLING_LOAD_ERROR',
    REMOVE_CARD_PENDING = 'REMOVE_CARD_PENDING',
    REMOVE_CARD_SUCCESS = 'REMOVE_CARD_SUCCESS',
    REMOVE_CARD_ERROR = 'REMOVE_CARD_ERROR',
    ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS',
    ADD_CARD_ERROR = 'ADD_CARD_ERROR',
    ADD_CARD_PENDING = 'ADD_CARD_PENDING',
    ENROLL_CARD_PENDING = 'ENROLL_CARD_PENDING',
    ENROLL_CARD_ERROR = 'ENROLL_CARD_ERROR',
    ENROLL_CARD_SUCCESS = 'ENROLL_CARD_SUCCESS',
    /* Billiable Entry years/month */
    BILLABLE_YEAR_FETCH_PENDING = 'BILLABLE_YEAR_FETCH_PENDING',
    BILLABLE_YEAR_FETCH_ERROR = 'BILLABLE_YEAR_FETCH_ERROR',
    BILLABLE_YEAR_FETCH_SUCCESS = 'BILLABLE_YEAR_FETCH_SUCCESS',
    /* Billable entry appointmeny */
    BILLABLE_APPOINTMENT_FETCH_PENDING = 'BILLABLE_APPOINTMENT_FETCH_PENDING',
    BILLABLE_APPOINTMENT_FETCH_ERROR = 'BILLABLE_APPOINTMENT_FETCH_ERROR',
    BILLABLE_APPOINTMENT_FETCH_SUCCESS = 'BILLABLE_APPOINTMENT_FETCH_SUCCESS',

    /*Defer Appointment request */
    DEFER_REQUEST_PENDING = 'DEFER_REQUEST_PENDING',
    DEFER_REQUEST_SUCCESS = 'DEFER_REQUEST_SUCCESS',
    DEFER_REQUEST_ERROR = 'DEFER_REQUEST_ERROR',
}

export interface BillingRequest { }

export interface DeferRequestResponse {
    status: { error: boolean, msg: string };
}

interface Doctor {
    id: string;
    uniqueid: string;
    fullname: string;
    stripeCard: string;
    provider: string;
}
interface Clinic {
    id: string;
    uniqueId: string;
    name: string;
    doctors: Doctor[];
}
export interface StripeCard {
    id: string;
    name: string;
    brand: string;
    last4: string;
    fingerprint: string;
    expMonth: string;
    expYear: string;
    status: string;
    stauts: string;
    createdAt: string;
    deletedAt: string;
}
export interface BillingResponse {
    status: { error: boolean, msg: string };
    id: string;
    uniqueId: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: string;
    userAccess: string;
    clinics: Clinic[];
    stripeCards: StripeCard[];
}
export interface RemoveCardRequest {
    cardId: string;
}
export interface RemoveCardResponse {
    cardId: string;
    status: { error: boolean, msg: string };
}
export interface AddCardRequest {
    stripeToken: string;
}
export interface AddCardResponse {
    card: any;
    status: { error: boolean, msg: string };
}
export interface EnrolCardRequest {
    request: any;
}
export interface EnrolCardResponse {
    clinicId: string;
    doctors: Doctor[];
    status: { error: boolean, msg: string };
}
/* Billable year/month */
export interface BillableEntryFetchRequest {
    type: string;
    uniqueId: string;
}
export interface BillableEntryFetchResponse {
    billableYears: any[];
    billableMonthsByYear: any;
}

export interface DeferRequestType {
    clinicUniqueId: string
    appointmentId: string;
}

/* Billable entry appointment list */
export interface BillableEntryAppointmentRequest {
    type: string;
    uniqueId: string;
    month: string;
    year: string;
}
export interface BillableEntryAppointmentResponse {
    id: string;
    uniqueid: string;
    scheduledDate: string;
    scheduledTime: string;
    status: string;
    metadata: {
        patient: {
            reminder: number;
            isNewPatient: boolean;
        };
        claimPoint: number;
        appointment: {
            lastCommunication: string;
            lastCommunicationOn: string;
            scheduledOn: string;
            scheduledNote: string;
            visited: boolean;
        }
    };
    billingdata: {
        isNewLead: boolean;
        billingStatus: string;
        billingPrice: string;
        billingTax: number;
    };
    reconciledStatus: string;
    billingStatus: string;
    createdAt: string;
    updatedAt: string;
    billableAction: string;
    clinic: {
        id: string;
        email: string;
        phone: string;
        uniqueid: string;
        name: string;
    };
    doctor: {
        fullname: string;
        id: string;
        email: string;
        uniqueid: string;
        gender: string;
    };
    patient: {
        phone: string;
        email: string;
        lname: string;
        fname: string;
        id: string;
        uniqueid: string;
    };
    dependant: {
        phone: string;
        email: string;
        lname: string;
        fname: string;
        id: string;
        uniqueid: string;
    };
}
export interface BillingState {
    pending: boolean;
    billingRequest?: BillingRequest;
    billingResponse?: BillingResponse;
    removeCardRequest?: RemoveCardRequest;
    removeCardResponse?: RemoveCardResponse;
    addCardRequest?: AddCardRequest;
    addCardResponse?: AddCardResponse;
    enrolCardRequest?: EnrolCardRequest;
    enrolCardResponse?: EnrolCardResponse;
    billableEntryFetchRequest?: BillableEntryFetchRequest;
    billableEntryFetchResponse?: BillableEntryFetchResponse;
    billableEntryAppointmentRequest?: BillableEntryAppointmentRequest;
    billableEntryAppointmentResponse?: BillableEntryAppointmentResponse[];
    deferRequestResponse?: DeferRequestResponse
    error: any;
}



export interface BillingPendingAction {
    type: BILLING.BILLING_LOAD_PENDING;
    payload: BillingRequest;
}
export interface BillingSuccessAction {
    type: BILLING.BILLING_LOAD_SUCCESS;
    billing: BillingResponse;
}
export interface BillingErrorAction {
    type: BILLING.BILLING_LOAD_ERROR;
    error: any;
}

export interface RemoveCardPendingAction {
    type: BILLING.REMOVE_CARD_PENDING;
    payload: RemoveCardRequest;
}
export interface RemoveCardSuccessAction {
    type: BILLING.REMOVE_CARD_SUCCESS;
    removeCard: RemoveCardResponse;
}
export interface RemoveCardErrorAction {
    type: BILLING.REMOVE_CARD_ERROR;
    error: any;
}

/* Add Card Action types */
export interface AddCardPendingAction {
    type: BILLING.ADD_CARD_PENDING;
    payload: AddCardRequest;
}
export interface AddCardSuccessAction {
    type: BILLING.ADD_CARD_SUCCESS;
    addCard: AddCardResponse;
}
export interface AddCardErrorAction {
    type: BILLING.ADD_CARD_ERROR;
    error: any;
}

/* Enrol Card Action Types */
export interface EnrolCardPendingAction {
    type: BILLING.ENROLL_CARD_PENDING;
    payload: EnrolCardRequest;
}
export interface EnrolCardSuccessAction {
    type: BILLING.ENROLL_CARD_SUCCESS;
    enrolCard: EnrolCardResponse;
}
export interface EnrolCardErrorAction {
    type: BILLING.ENROLL_CARD_ERROR;
    error: any;
}
/* Billable Entry Fetch year/month */
export interface BillableEntryFetchPendingAction {
    type: BILLING.BILLABLE_YEAR_FETCH_PENDING;
    payload: BillableEntryFetchRequest;
}
export interface BillableEntryFetchErrorAction {
    type: BILLING.BILLABLE_YEAR_FETCH_ERROR;
    error: any;
}
export interface BillableEntryFetchSuccessAction {
    type: BILLING.BILLABLE_YEAR_FETCH_SUCCESS;
    billableEntryFetch: BillableEntryFetchResponse;
}
/* Billable Entry appointment */
export interface BillableEntryAppointmentPendingAction {
    type: BILLING.BILLABLE_APPOINTMENT_FETCH_PENDING;
    payload: BillableEntryAppointmentRequest;
}
export interface BillableEntryAppointmentErrorAction {
    type: BILLING.BILLABLE_APPOINTMENT_FETCH_ERROR;
    error: any;
}
export interface BillableEntryAppointmentSuccessAction {
    type: BILLING.BILLABLE_APPOINTMENT_FETCH_SUCCESS;
    billableEntryAppointment: BillableEntryAppointmentResponse[];
}

export interface DeferRequestResponsePending {
    type: BILLING.DEFER_REQUEST_PENDING;
    payload: DeferRequestType;
}

export interface DeferRequestSuccessAction {
    type: BILLING.DEFER_REQUEST_SUCCESS;
    payload: DeferRequestResponse;
}

export interface DeferRequestErrorAction {
    type: BILLING.DEFER_REQUEST_ERROR;
    payload: any
}



export type BillingActionTypes = BillingErrorAction | BillingPendingAction | BillingSuccessAction |
    RemoveCardErrorAction | RemoveCardPendingAction | RemoveCardSuccessAction |
    AddCardErrorAction | AddCardPendingAction | AddCardSuccessAction |
    EnrolCardErrorAction | EnrolCardPendingAction | EnrolCardSuccessAction |
    BillableEntryFetchErrorAction | BillableEntryFetchPendingAction | BillableEntryFetchSuccessAction |
    BillableEntryAppointmentErrorAction | BillableEntryAppointmentPendingAction | BillableEntryAppointmentSuccessAction |
    DeferRequestResponsePending | DeferRequestSuccessAction | DeferRequestErrorAction;