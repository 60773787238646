import React from 'react';
import { NavLink } from 'react-router-dom';
export const PaymentHeader = () => {
    return (
        <section className="middle-navbar px-30">
            <div className="p-0 container-fluid">
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="nav-title-txt">Payment Notifications</div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <NavLink className="nav-link" exact={true} to={"/message-center/payment"}>Payment Notification</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" exact={true} to={"/message-center/payment/reminder"}>Payment Reminder</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" exact={true} to={"/message-center/payment/patients"}>Patients</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}