// import './_practiceSteps.scss';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import RichTextEditor from 'react-rte';
import { createStep, getQuestionsList } from '../../services/clinicOnboard';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
export function PracticeApp5(props: any) {

	const [practiceDescription, setPracticeDescription] = useState(RichTextEditor.createEmptyValue());
	const [practiceQuestions, setPracticeQuestions]: any = useState([]);
	const [seoTitle, setSEQTitle] = useState('');
	const [metaDescription, setMetaDescription] = useState('');
	const [practiceDescriptionValidation, setPracticeDescriptionValidation] = useState({ invalid: false, msg: '' });
	const dispatch = useDispatch();
	const components = {
		DropdownIndicator: null,
	};
	const createOption = (label: string) => ({
		label,
		value: label,
	});
	const [inputValue, setInputValue]: any = useState('');
	const [value, setValue]: any = useState('');

	useEffect(() => {
		dispatch(fetchLoadingPending());
		getQuestionsList().then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				if (props.data && props.data.questions.length > 0) {
					setPracticeQuestions(success.questions.map((item: any) => {
						const found = props.data.questions.find((qItem: any) => qItem.id === item.id);
						if (found) {
							return {
								...item,
								response: found.response
							};
						}
						return { ...item, response: "", error: false };
					}));
				} else {
					setPracticeQuestions(success.questions.map((item: any) => ({ ...item, response: "", error: false })));
				}
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		})
	}, []);

	const handleChange = (value: any, actionMeta: any) => {
		setValue(value);
	};
	const handleInputChange = (inputValue: string) => {
		setInputValue(inputValue);
	};
	const handleKeyDown = (event: any) => {
		if (!inputValue) return;
		switch (event.key) {
			case 'Enter':
			case 'Tab':
				setInputValue('');
				if (value) {
					setValue([...value, createOption(inputValue)]);
				} else {
					setValue([createOption(inputValue)]);
				}
				event.preventDefault();
			default:
				return;
		}
	};
	const onNext = () => {
		if (!practiceDescription.getEditorState().getCurrentContent().hasText()) {
			setPracticeDescriptionValidation({ invalid: true, msg: 'Required' });
			return;
		}
		setPracticeDescriptionValidation({ invalid: false, msg: '' });
		if (practiceQuestions.find((item: any) => item.response === '')) {
			setPracticeQuestions(practiceQuestions.map((item: any) => {
				if (item.response === '') {
					return {
						...item,
						error: true
					}
				} else {
					return { ...item, error: false };
				}
			}));
			return;
		}
		const request = {
			practiceDescription: practiceDescription.toString('html'),
			questions: practiceQuestions.map((item: any) => {
				delete item.error;
				return item;
			}),
			seoTitle: seoTitle,
			metaDescription: metaDescription,
			targetKeywords: value
		};
		const requestBody = {
			description: practiceDescription.toString('html'),
			questionResponse: JSON.stringify(practiceQuestions.map((item: any) => {
				delete item.error;
				return item;
			})),
			metaTitle: seoTitle,
			metaDesc: metaDescription,
			metaKeywords: value ? value.map((item: any) => item.label).toString() : '',
			uniqueid: props.clinicUniqueId
		};
		dispatch(fetchLoadingPending());
		createStep({ stepNumber: 5, formData: requestBody }).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				props.onNext(5, { questions: request });
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});
	}
	useEffect(() => {
		if (props.data) {
			setPracticeDescription(RichTextEditor.createValueFromString(props.data.practiceDescription, 'html'));
			setPracticeQuestions(props.data.questions.map((item: any) => ({ ...item, error: false })));
			setSEQTitle(props.data.seoTitle);
			setMetaDescription(props.data.metaDescription);
			setValue(props.data.targetKeywords);
		}
	}, [props.data]);
	return (

		<>
			<div className="row justify-content-center">
				<div className="col-12">
					<h2 className="mb-3">Discoverablity score</h2>
					<div className="fw-medium fs-5">Profile description.</div>
					<p className="mt-1">Please set your profile description to let patients know about your practice.</p>
					<hr className="my-4" />
				</div>
				<div className="col-12 mb-4">
					<label htmlFor="PracticeDescription" className="form-label">Practice description <span className="text-danger">*</span></label>
					<div className="editor-box form-control">
						<RichTextEditor
							value={practiceDescription}
							onChange={setPracticeDescription}
						/>
					</div>
				</div>
				{
					practiceDescriptionValidation.invalid &&
					<div className="is-invalid">{practiceDescriptionValidation.msg}</div>
				}
			</div>
			{
				practiceQuestions.map((item: any, index: number) => {
					return (
						<div className="col-12 mb-4" key={item.id + index}>
							<label htmlFor={"PracticeQ" + index} className="form-label">{item.title} <span className="text-danger">*</span></label>
							<textarea className="form-control" id={"PracticeQ" + index} rows={3} value={item.response} onChange={(e) => {
								setPracticeQuestions(practiceQuestions.map((qItem: any) => {
									if (item.id === qItem.id) {
										return {
											...qItem,
											response: e.target.value
										}
									}
									return qItem
								}))
							}}></textarea>
							{
								item.error &&
								<div className="is-invalid">
									Required
								</div>
							}
						</div>
					)
				})
			}
			<div className="col-12">
				<div className="meta-data-sec mt-4">
					<div className="fw-medium fs-5">Meta Data</div>
					{/* <p>Please set the office hours to let patients know when you're open</p> */}
					<hr />
					<div className="tdk-box mb-4">
						<label htmlFor="seoTitle" className="form-label">SEO title</label>
						<input className="form-control" id="seoTitle" type="text"
							value={seoTitle} onChange={(e) => { setSEQTitle(e.target.value) }} />
						<small className="text-muted">Please set your profile description to let patients know about your practice.</small>
					</div>
					<div className="tdk-box mb-4">
						<label htmlFor="metaDescription" className="form-label">Meta description</label>
						<textarea className="form-control" id="metaDescription"
							value={metaDescription} onChange={(e) => { setMetaDescription(e.target.value) }}
							rows={3}></textarea>
						<small className="text-muted">Please set your profile description to let patients know about your practice.</small>
					</div>
					<div className="tdk-box mb-4">
						<label className="form-label">Target keyword</label>
						<CreatableSelect
							components={components}
							inputValue={inputValue}
							isClearable
							isMulti
							menuIsOpen={false}
							onChange={handleChange}
							onInputChange={handleInputChange}
							onKeyDown={handleKeyDown}
							placeholder="Keywords"
							value={value}
						/>
					</div>
				</div>

				<div className="col-12 my-4">
					<div className="bottom-btn-sec d-flex justify-content-between">
						<button className="btn border me-1" onClick={() => { props.onPrev(3) }}>Back</button>
						<button className="btn btn-primary ms-1" onClick={() => { onNext(); }}>Next</button>
					</div>
				</div>

			</div>


		</>
	);
}
