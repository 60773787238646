import firebase from 'firebase';

export const signInAnonymously = () => {
    if (!firebase.auth().currentUser) {
        firebase.auth().signInAnonymously().then((success) => {
        }).catch((error) => {
        });
    }
}
export const getCurrentUser = () => {
    return firebase.auth().currentUser;
}

/* Signin with token */
export const signInWithCustomToken = (token: any) => {
    return firebase.auth().signInWithCustomToken(token).then((success) => {
        return success;
    }).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        throw errorMessage;
        // ...
    });
}

export const logoutUser = () => {
    if (firebase.auth().currentUser) {
        firebase.auth().signOut();
    }
}