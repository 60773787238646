import React, { useState } from "react"
import { Modal } from "react-bootstrap";
import { AnalyticsAndReportDetail } from "./AnalyticsAndReportDetail";
import { AnalyticsPatientListDetail } from "./AnalyticsPatientList";
import { NotificationAndReportDetail } from "./NotificationAndReportDetail";
import { ReportList } from "./reportlist";

export const PatientReports = ({
    onHide
}: any) => {
    const [reportType, setReportType] = useState({
        report: '',
        type: ''
    });
    const [activeIndex, setActiveIndex] = useState(0);
    console.log(reportType);
    return (
        <Modal centered show={true} onHide={onHide}>
            <Modal.Body>
                {
                    activeIndex === 0 &&
                    <ReportList onClose={() => {
                        setReportType({
                            report: '',
                            type: ''
                        });
                        onHide();
                    }}
                    handleReportClick={(value: any) =>{
                        setActiveIndex(1);
                        setReportType(value);
                    }}
                    ></ReportList>
                }
                {
                    activeIndex === 1 && (
                        <>
                            {
                                reportType?.report === "analytics-reports" && <>
                                    <AnalyticsAndReportDetail type={reportType?.type} onBackClick={() => {
                                        setActiveIndex(0);
                                        setReportType({
                                            report: '',
                                            type: ''
                                        });
                                    }}/>
                                </>
                            }
                            {
                                reportType?.report === "patient-reports" && <>
                                    <AnalyticsPatientListDetail type={reportType?.type} onBackClick={() => {
                                        setActiveIndex(0);
                                        setReportType({
                                            report: '',
                                            type: ''
                                        });
                                    }}/>
                                </>
                            }
                            {
                                reportType?.report === "notification-reports" && <>
                                    <NotificationAndReportDetail type={reportType?.type} onBackClick={() => {
                                        setActiveIndex(0);
                                        setReportType({
                                            report: '',
                                            type: ''
                                        });
                                    }}/>
                                </>
                            }
                        </>
                    )
                }
            </Modal.Body>
        </Modal>
    )
}
export const ReportHeader = ({
    showDone,
    showBack,
    onDoneClick,
    onBackClick,
    pageTitle
}: any) => {
    return (
        <div className="app-header">
            <div className="action_bar">

                <div className="box-1">
                    {
                        showDone &&
                        <button className="btn btn-link p-0" onClick={() => {
                            if (onDoneClick) {
                                onDoneClick();
                            }
                        }}>
                          Done
                        </button>
                    }
                </div>

                <div className="fw-medium fs-5 box-2">
                    {
                      pageTitle &&
                      <>{pageTitle}</>
                    }
                </div>

                  <div className={"box-3 "}>
                    {
                        showBack &&
                        <button className="btn btn-link p-0" onClick={() => {
                          if (onBackClick) {
                            onBackClick();
                          }
                        } }>Back</button>
                    }
                  </div>



            </div>
        </div>
    )
}