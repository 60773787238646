import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { getClinicProfile, updateClinicProfile } from '../../services/clinicProfile';
import { TitleBox } from '../../components/practice/profile/TitleBox';
import CreatableSelect from 'react-select/creatable';
import { useForm } from 'react-hook-form';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
export const Discoverablity = () => {
  const userDataResponse = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  const [profile, setProfile]: any = useState();
  const [titleBox, setTitleBox]: any = useState();
  const [isEdit, setIsEdit]: any = useState(false);
  const [isLoading, setIsLoading]: any = useState(false);
  const dispatch = useDispatch();
  const components = {
    DropdownIndicator: null,
  };
  const createOption = (label: string) => ({
    label,
    value: label,
  });
  const [inputValue, setInputValue]: any = useState('');
  const [targetKeywords, setTargetKeywords]: any = useState([]);

  const { register, errors, setError, getValues, setValue, handleSubmit, watch } = useForm();
  useEffect(() => {
    if (userDataResponse && userDataResponse.status && userDataResponse.status.error === false &&
      userDataResponse.clinics && userDataResponse.clinics.length > 0) {
      const clinicId = userDataResponse.clinics[0].uniqueId;
      dispatch(fetchLoadingPending());
      getClinicProfile(clinicId).then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.status && success.status.error === false) {
          setProfile(success);
          setTitleBox({ name: success.name, primarySpeciality: success.primarySpeciality, logo: success.logo, uniqueId: success.uniqueId, provider: success.provider });
          if (success.seodata) {
            setValue('seoTitle', success.seodata ? success.seodata.metaTitle : '');
            setValue('metaDescription', success.seodata ? success.seodata.metaDesc : '');
            if (success.seodata && success.seodata.metaKeywords) {
              setTargetKeywords(success.seodata.metaKeywords.split(",").map((item: any) => ({ label: item, value: item })))
            }
          }
          return;
        }
      }).catch((err) => {
        dispatch(fetchLoadingSuccess(false));
      });
    }
  }, [userDataResponse]);
  const fetchProfile = async (type: string) => {
    if (userDataResponse && userDataResponse.status && userDataResponse.status.error === false &&
      userDataResponse.clinics && userDataResponse.clinics.length > 0) {
      const clinicId = userDataResponse.clinics[0].uniqueId;
      dispatch(fetchLoadingPending());
      getClinicProfile(clinicId).then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.status && success.status.error === false) {
          setProfile(success);
          if (type === 'logo') {
            setTitleBox({
              name: success.name, primarySpeciality: success.primarySpeciality,
              logo: success.logo,
              uniqueId: success.uniqueId, provider: success.provider
            });
          } else if (type === 'seo') {
            if (success.seodata) {
              setValue('seoTitle', success.seodata ? success.seodata.metaTitle : '');
              setValue('metaDescription', success.seodata ? success.seodata.metaDesc : '');
              if (success.seodata && success.seodata.metaKeywords) {
                setTargetKeywords(success.seodata.metaKeywords.split(",").map((item: any) => ({ label: item, value: item })))
              }
            }
          }
          return new Promise((res, rej) => {
            res(true);
          });
        }
        return new Promise((res, rej) => {
          rej(true);
        });
      }).catch((err) => {
        dispatch(fetchLoadingSuccess(false));
        return new Promise((res, rej) => {
          rej(true);
        });
      });
    }
  };
  const handleChange = (value: any, actionMeta: any) => {
    setTargetKeywords(value);
  };
  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue);
  };
  const handleKeyDown = (event: any) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
        return;
      case 'Tab':
        setInputValue('');
        if (targetKeywords) {
          setTargetKeywords([...targetKeywords, createOption(inputValue)]);
        } else {
          setTargetKeywords([createOption(inputValue)]);
        }
        event.preventDefault();
      default:
        return;
    }
  };
  const onCancel = () => {
    setIsEdit(false);
    setIsLoading(false);
    if (profile) {
      if (profile.seodata) {
        setValue('seoTitle', profile.seodata ? profile.seodata.metaTitle : '');
        setValue('metaDescription', profile.seodata ? profile.seodata.metaDesc : '');
        if (profile.seodata && profile.seodata.metaKeywords) {
          setTargetKeywords(profile.seodata.metaKeywords.split(",").map((item: any) => ({ label: item, value: item })))
        }
      }
    }
  }
  const onUpdate = () => {
    const seoTitle = getValues('seoTitle');
    const metaDesc = getValues('metaDescription');
    const keywordTarget = targetKeywords.filter((item: any) => item.value !== "").map((item: any) => item.value).toString();
    const clinicId = userDataResponse.clinics[0].uniqueId;
    setIsLoading(true);
    updateClinicProfile('seo', clinicId, {
      metaTitle: seoTitle,
      metaDesc: metaDesc,
      metaKeywords: keywordTarget
    }).then((success) => {
      if (success && success.status && success.status.error === false) {
        fetchProfile('seo').then((success: any) => {
          setIsEdit(false);
          setIsLoading(false);
        });
      }
    }).catch((err) => {
    });
  }
  return (
    <section className="practice-seod-sec px-3 pt-4">
      <div className="container">

        {/* {
          useMemo(() => {
            if (titleBox)
              return (
                <TitleBox {...titleBox} fetchProfile={fetchProfile}></TitleBox>
              )
            return null;
          }, [titleBox])
        } */}



        <div className="card mb-4">
          <div className="card-header bg-light py-3 px-4">
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Practice mata data</div>
              <div>
                {
                  !isEdit &&
                  <button className="btn p-0 btn-link" onClick={(e) => { setIsEdit(true) }}>Edit</button>
                }
                {
                  isEdit &&
                  <>
                    <button className="btn p-0 btn-link" onClick={(e) => { setIsEdit(false) }}>Cancel</button>
                  </>
                }
              </div>
            </div>
          </div>

          <div className="card-body p-4">
            <div>
              {
                useMemo(() => {
                  return (
                    <div>
                      <label className="form-label">SEO title</label>
                      <input type="text" className="form-control px-3 py-2" placeholder="Enter SEO title"
                        id="seoTitle"
                        name="seoTitle"
                        ref={register} />
                    </div>
                  )
                }, [watch('seoTitle')])
              }
              {
                useMemo(() => {
                  return (
                    <div className="mt-4">
                      <label className="form-label">Meta description</label>
                      <textarea className="form-control px-3 py-2" rows={3}
                        placeholder="Enter meta description etc. etc."
                        id="metaDescription"
                        name="metaDescription"
                        ref={register}
                      ></textarea>
                      <small className="text-muted">Your business must be listed on google map.</small>
                    </div>
                  )
                }, [watch('metaDescription')])
              }
              {
                useMemo(() => {
                  return (
                    <div className="mt-4">
                      <label className="form-label">Target keyword</label>
                      <CreatableSelect
                        name="targetKeywords"
                        components={components}
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Keywords"
                        value={targetKeywords}
                        ref={register}
                      />
                    </div>
                  )
                }, [inputValue, targetKeywords])
              }
            </div>

            <div className="d-flex justify-content-between mt-3">
              <small className="d-block text-muted align-self-center">This bio will help new patients find your clinic on google.</small>
              {
                isEdit && !isLoading &&
                <div className="bottom-btn-sec">
                  <button className="btn btn-outline-primary me-2" onClick={(e) => {
                    onCancel();
                  }}>Cancel</button>
                  <button className="btn btn-primary" onClick={() => {
                    onUpdate();
                  }}>Update</button>
                </div>
              }
              {
                isEdit && isLoading &&
                <div className="bottom-btn-sec">
                  <p>Updating</p>
                </div>
              }
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}
