import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import {
    getPaymentReminderNotificationTemplateList, updateActiveStatusPaymentReminderNotificationTemplate,
    deletePaymentReminderNotificationTemplate,
    getEmailTemplateList
} from '../../services/messageCenter';
import { IAppState } from '../../../store';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { PaymentReminderNotificationModal } from '../../components/messageCenter/PaymentReminderNotificationModal';
import AddBtnIcon from '../../assets/images/add-btn-icon.svg';
import NotificationIcon from '../../assets/images/notification-img.svg';

export const PaymentReminder = () => {
    const [templateList, setTemplateList] = useState([]);
    const [showAddNotification, setShowAddNotification] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [emailTemplateList, setEmailTemplateList] = useState([]);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const dispatch = useDispatch();
    useEffect(() => {
        getPaymentReminderNotificationTemplateListFunc();
        fetchEmailTemplateList();
    }, []);
    const getPaymentReminderNotificationTemplateListFunc = () => {
        dispatch(fetchLoadingPending())
        getPaymentReminderNotificationTemplateList({ clinicid: userData.clinics[0].id }).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.error === false && success.code === 200) {
                setTemplateList(success.data);
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        })
    };

    const fetchEmailTemplateList = () => {
        getEmailTemplateList({clinicid: userData.clinics[0].id}).then((success) => {
            if (success && success.error === false) {
                setEmailTemplateList(success.data.filter((item: any) => item.isactive));
            }
        }).catch((err) => {
            setEmailTemplateList([]);
        });
    }

    const changeStatus = (status: boolean, id: any) => {
        confirmAlert({
            title: '',
            message: 'Please confirm, if you want to ' + (status === true ? 'enable' : 'disable') + ' this configuration.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        updateActiveStatusPaymentReminderNotificationTemplate({
                            payment_reminder_notificationtemplate_id: id,
                            clinicid: userData.clinics[0].id,
                            isactive: status
                        }).then((success) => {
                            if (success && success.error === false && success.code === 200) {
                                getPaymentReminderNotificationTemplateListFunc();
                            }
                        }).catch((err) => {
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });
    };

    const onRowClick = (item: any) => {
        setSelectedTemplate(item);
        setShowAddNotification(true);
    }

    const handleOnDeleteClick = (id: any) => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to delete ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(fetchLoadingPending())
                        deletePaymentReminderNotificationTemplate({
                            clinicid: userData.clinics[0].id,
                            payment_reminder_notificationtemplate_id: id
                        }).then((success) => {
                            dispatch(fetchLoadingSuccess(false))
                            if (success && success.error === false && success.code === 200) {
                                getPaymentReminderNotificationTemplateListFunc();
                            }
                        }).catch((err) => {
                            dispatch(fetchLoadingSuccess(false))
                            toast.error('Delete notification template error.')
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });
    }
    return (
        <>
            <section className="automated-reminders" id="automatedReminders">
                <div className="row mx-0">
                    <div className="col-12">
                        <div className="reminder-box">
                            <div className="d-flex justify-content-between">
                                <div className="me-3">
                                    {/* <div className="reminder-title">Notification</div> */}
                                    {/* <div className="reminder-desc">Setup automated payment reminders</div> */}
                                </div>
                                <div className="align-self-center">
                                    <button className="btn btn-primary" onClick={() => {
                                        setShowAddNotification(true);
                                    }}><img src={AddBtnIcon} className="me-2" /> Create notification</button>
                                </div>
                            </div>
                            {
                                useMemo(() => {
                                    return (
                                        templateList.map((item: any, index: number) => {
                                            return (
                                                <div className="my-4 border px-3 py-3 border-1 rounded d-flex custom-noti-box justify-content-between"
                                                    key={item.messagecenternotificationtemplate_id} >
                                                    <div className="d-flex">
                                                        <div className="noti-title d-flex" >
                                                            <img src={NotificationIcon} className="me-2 align-self-center" />
                                                            <span className="fw-bold align-self-center ">{item.notificationname}</span>
                                                        </div>
                                                        <div className="noti-time">
                                                            <small className="text-muted">Time</small>
                                                            {
                                                                <div className="fw-bold">{"08:00 AM"}</div>
                                                            }
                                                        </div>
                                                        <div className="noti-reminder">
                                                            <small className="text-muted">Reminder</small>
                                                            {
                                                                <div className="fw-bold">
                                                                    {
                                                                        item.scheduledays.split(",").find((item: any) => item === "0") &&
                                                                        <>same day</>
                                                                    }
                                                                    {(item.scheduledays.split(",").filter((item: any) => item !== "0").length > 0 && 
                                                                    item.scheduledays.split(",").find((item: any) => item === "0")) && ', '}
                                                                    {
                                                                        item.scheduledays.split(",").filter((item: any) => item !== "0").map((sitem: any, index: number) => (
                                                                        <>
                                                                            {/* {(item.scheduledays.split(",").find((item: any) => item === "0")) && ', '} */}
                                                                            {/* {index !== 0 && ','} */}
                                                                            {sitem !== '' && sitem + (index + 1 !== item.scheduledays.split(",").filter((item: any) => item !== "0")?.length ? ',' : '')}
                                                                        </>
                                                                        ))
                                                                    }
                                                                    {/* {
                                                                        item.scheduledays.split(",").filter((item: any) => item !== "0").length > 0 && ' Day'
                                                                    } */}
                                                                </div>
                                                            }
                                                            {/* {
                                                                <div className="fw-bold">
                                                                    immediate
                                                                </div>
                                                            } */}
                                                        </div>
                                                        <div className="noti-type">
                                                            <small className="text-muted">Type</small>
                                                            <div className="fw-bold">email, text</div>
                                                        </div>
                                                        <div className="noti-createdon">
                                                            <small className="text-muted">CreatedOn</small>
                                                            <div className="fw-bold">{moment(item.created_at).format("MMM DD, YYYY - LT")}</div>
                                                        </div>
                                                    </div>
                                                    <div className="noti-btns d-flex ">
                                                        <div className="form-check form-switch d-inline-block align-self-center">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={item.isactive} onChange={() => { changeStatus(!item.isactive, item.payment_reminder_notificationtemplate_id) }} />
                                                        </div>
                                                    </div>
                                                    <div className="noti-edit d-flex align-self-center btn-link">
                                                        <div onClick={() => {
                                                            onRowClick(item);
                                                        }}>Edit</div>
                                                        <button className="btn p-0 btn-link text-secondary" onClick={() => {
                                                            handleOnDeleteClick(item.payment_reminder_notificationtemplate_id)
                                                        }}><i className="ms-2 bi bi-x-circle-fill text-danger"></i></button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                }, [templateList])
                            }
                        </div>
                    </div>
                </div>
                {
                    showAddNotification &&
                    <PaymentReminderNotificationModal
                        hideModal={() => {
                            setShowAddNotification(false);
                            setSelectedTemplate(null);
                        }}
                        selectedTemplate={selectedTemplate}
                        clinicId={(userData && userData.clinics[0] && userData.clinics[0].id)}
                        fetchList={getPaymentReminderNotificationTemplateListFunc}
                        emailTemplateList={emailTemplateList}
                        showEmailTemplate={userData.clinics[0].isenableemailtemplate}
                        clinic={userData.clinics[0]}
                    ></PaymentReminderNotificationModal>
                }
            </section>
        </>
    )
}
