export enum SUBSCRIPTION_PLAN {
  SUBSCRIPTION_PLAN_FETCH = 'SUBSCRIPTION_PLAN_FETCH',
  SUBSCRIPTION_PLAN_FETCH_FAILED = 'SUBSCRIPTION_PLAN_FETCH_FAILED',
  SUBSCRIPTION_PLAN_FETCH_SUCCESS = 'SUBSCRIPTION_PLAN_FETCH_SUCCESS',
  SUBSCRIPTION_PLAN_CREATE = 'SUBSCRIPTION_PLAN_CREATE',
  SUBSCRIPTION_PLAN_CREATE_FAILED = 'SUBSCRIPTION_PLAN_CREATE_FAILED',
  SUBSCRIPTION_PLAN_CREATE_SUCCESS = 'SUBSCRIPTION_PLAN_CREATE_SUCCESS',
  CLINIC_MYPLAN = 'CLINIC_MYPLAN',
  CLINIC_MYPLAN_FAILED = 'CLINIC_MYPLAN_FAILED',
  CLINIC_MYPLAN_SUCCESS = 'CLINIC_MYPLAN_SUCCESS',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION_FAILD = 'CANCEL_SUBSCRIPTION_FAILD',
  CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS',
}
interface Status {
  error: boolean;
  msg: string;
}
export interface FetchSubscriptionPlanRequest {
  clinicUniqueId: string;
}
export interface PlanItem {
  id: string;
  plan_price: string;
  name: string;
  type: string;
  plan_interval: string;
  plan_price_id: string;
  isPlanActive: boolean;
  subscribedStatus: string;
}
export interface FetchSubscriptionPlanResponse {
  plans: PlanItem[];
  status: Status;
}
export interface CreateSubscriptionPlanRequest {
  clinicUniqueId: string;
  data: any;

}
export interface CreateSubscriptionPlanResponse {
  status: Status;
}
export interface ClinicMyplanResponce {
  plans: PlanItem[];
  status: Status;
}
export interface ClinicMyplanRequest {
  clinicUniqueId: string;
}
export interface CancelSubscriptionResponse {
  status: Status;
}
export interface CancelSubscriptionRequest {
  data: { subscriptions: { subscribed_subscription_id: string, id: any }[] };
  clinicUniqueId: string;
}
export interface SubscriptionState {
  pending: boolean;
  error: any;
  fetchSubscriptionPlanRequest?: FetchSubscriptionPlanRequest;
  fetchSubscriptionPlanResponse?: FetchSubscriptionPlanResponse;
  createSubscriptionPlanRequest?: CreateSubscriptionPlanRequest;
  createSubscriptionPlanResponse?: CreateSubscriptionPlanResponse;
  clinicMyplanResponce?: ClinicMyplanResponce;
  clinicMyplanRequest?: ClinicMyplanRequest;
  cancelSubscriptionResponse?: CancelSubscriptionResponse,
  cancelSubscriptionRequest?: CancelSubscriptionRequest,
}
export interface FetchSubscriptionPlanPendingAction {
  type: SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_FETCH;
  payload: FetchSubscriptionPlanRequest;
}
export interface FetchSubscriptionPlanErrorAction {
  type: SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_FETCH_FAILED;
  payload: any;
}
export interface FetchSubscriptionPlanSuccessAction {
  type: SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_FETCH_SUCCESS;
  payload: FetchSubscriptionPlanResponse;
}
export interface CreateSubscriptionPlanPendingAction {
  type: SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_CREATE;
  payload: CreateSubscriptionPlanRequest;
}
export interface CreateSubscriptionPlanErrorAction {
  type: SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_CREATE_FAILED;
  payload: any;
}
export interface CreateSubscriptionPlanSuccessAction {
  type: SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_CREATE_SUCCESS;
  payload: CreateSubscriptionPlanResponse;
}
export interface MyPlanPendingAction {
  type: SUBSCRIPTION_PLAN.CLINIC_MYPLAN;
  payload: ClinicMyplanRequest;
}
export interface MyPlanErrorAction {
  type: SUBSCRIPTION_PLAN.CLINIC_MYPLAN_FAILED;
  payload: any;
}
export interface MyPlanSuccessAction {
  type: SUBSCRIPTION_PLAN.CLINIC_MYPLAN_SUCCESS;
  payload: ClinicMyplanResponce;
}
export interface CancelSubscriptionPendingAction {
  type: SUBSCRIPTION_PLAN.CANCEL_SUBSCRIPTION;
  payload: CancelSubscriptionRequest;
}
export interface CancelSubscriptionErrorAction {
  type: SUBSCRIPTION_PLAN.CANCEL_SUBSCRIPTION_FAILD;
  payload: any;
}
export interface CancelSubscriptionSuccessAction {
  type: SUBSCRIPTION_PLAN.CANCEL_SUBSCRIPTION_SUCCESS;
  payload: CancelSubscriptionResponse;
}
export type SubscriptionPlanActionTypes = FetchSubscriptionPlanPendingAction | FetchSubscriptionPlanErrorAction
  | FetchSubscriptionPlanSuccessAction | CreateSubscriptionPlanPendingAction | CreateSubscriptionPlanErrorAction |
  CreateSubscriptionPlanSuccessAction | MyPlanPendingAction | MyPlanErrorAction | MyPlanSuccessAction | CancelSubscriptionPendingAction |
  CancelSubscriptionErrorAction | CancelSubscriptionSuccessAction;