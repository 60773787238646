import React, { useEffect, useState, useMemo } from 'react';
import { IAppState } from '../../../store';
import { fetchUserDataPending } from '../../../store/userData/actions';
import { useDispatch, useSelector } from 'react-redux';
import firebaseInstance from '../../helpers/firebase';
import configs from '../../configs/apiConfigs';
import { toast } from 'react-toastify';
import { CHAT } from '../../../constants';
import { MyScheduleHeader } from '../../components/mySchedule/MyScheduleHeader';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { storeChatData } from '../../../store/chat/action';
import { DigitalDoc } from '../../components/digitaldoc/DigitalDoc';
import { CancelAppointmentModal } from '../../components/mySchedule/CancelAppointmentModal';
import { confirmAlert } from 'react-confirm-alert';
import { getCookie } from '../../utils/cookies';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { appointmentUnverified, getAppointments, firestoreChangeNotificationToServer, getMasterPMSStatus, changeAppointmentPMSStatus, sendManualReview } from '../../services/appointment';
import { PatientDetailModel } from '../../components/mySchedule/PatientDetailModel';
import CONSTANT_LABELS from '../../../constants/labels';
import { removeCountryCode } from '../../../utils/global';
import { NoteModal } from '../../../components/note/NoteModal';
let subscriptionToAppointmentChange: any = null;
export const All = () => {
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse);
    const dispatch = useDispatch();
    const [providerList, setProviderList] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(30);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [appointmentItemList, setAppointmentItemList] = useState([]);
    const [currentActiveModalAppointment, setCurrentActiveModalAppointment]: any = useState(null);
    const [digitalDocShow, setShowDigitalDocShow] = useState(false);
    const [masterPMSStatusList, setMasterPMSStatusList] = useState([]);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [showPatientDetailModal, setShowPatientDetailModal] = useState(false);
    const [name, setName] = useState('');
    const [showNoteModal, setShowNoteModal] = useState(false);
    useEffect(() => {
        return () => {
            if (subscriptionToAppointmentChange) subscriptionToAppointmentChange();
        }
    }, []);
    useEffect(() => {
        if (userData && userData.clinics && userData.clinics[0] && userData.status && userData.status.error === false && userData.status.msg !== "") {
            let providers = userData.clinics[0].provider;
            providers = providers.filter((item: any) => item.onBoarding.status === 1 && item.enableAppointment === "1");
            setProviderList(providers);
            listenRealtimeAppointmentChange();
            getMasterPMSStatusListFunc(userData.clinics[0].id);
        }
    }, [userData]);
    const getMasterPMSStatusListFunc = async (id: string) => {
        try {
            const response = await getMasterPMSStatus(id, true);
            if (response && response.status && response.status.error === false) {
                setMasterPMSStatusList(response.pmsStatuses);
            }
        } catch (err) {
            console.log(err);
        }
    }
    const handlePatientDetailAppointmentClick = (item: any) => {
        setShowPatientDetailModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const listenRealtimeAppointmentChange = () => {
        subscriptionToAppointmentChange && subscriptionToAppointmentChange();
        subscriptionToAppointmentChange = firebaseInstance.db.collection(configs.CHAT_COLLECTION).where('actionType', 'in', [CHAT.ACTION_TYPE.STATUS,
        CHAT.ACTION_TYPE.PRESCREENING, CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT, CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT, CHAT.ACTION_TYPE.PRESCREENING_STATUS,
        CHAT.ACTION_TYPE.CHECKIN])
            .where('sendToCId', '==', userData.clinics[0].uniqueId)
            .onSnapshot((snapshot: any) => {
                fecthAppointmentListAsync(true);
            });
    }
    useEffect(() => {
        fecthAppointmentListAsync();
    }, [selectedProvider, page, results, selectedStatus]);
    useEffect(() => {
        if (name.length > 3) {
            fecthAppointmentListAsync();
        } else if (name === '') {
            fecthAppointmentListAsync();
        }
    }, [name]);
    const fecthAppointmentListAsync = async (realTime = false) => {
        try {
            let modelType = 'clinic';
            let Id = userData.clinics[0].uniqueId;
            let selectedProviderId: any = '';
            if (selectedProvider !== '') {
                selectedProviderId = selectedProvider;
            }
            const request = {
                appointmentType: 'all',
                modelType: modelType,
                page: page,
                results: results,
                Id: Id,
                selectedProvider: selectedProviderId,
                selectedStatus: selectedStatus
            };
            if (name !== '' && name.length > 3) {
                Object.assign(request, { fullname: name });
            }
            if (realTime === true) {
                setIsRefreshing(true);
            } else {
                setIsLoading(true);
            }
            const response = await getAppointments(request);
            if (response && response.status && response.status.error === false) {
                setAppointmentItemList(response.appointments);
                if (page === 1) {
                    setTotalCount(response.total);
                }
            }
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
        } catch (err) {
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
            console.log(err);
        }
    }
    const handlePageChange = (page: any) => {
        setPage(page);
    }
    const handleDigitalDocClick = (appointment: any) => {
        setCurrentActiveModalAppointment(appointment);
        setShowDigitalDocShow(true);
    }
    const addChatPin = (item: any) => {
        dispatch(storeChatData({
            ...item,
            fetched: true,
            pinType: "chat",
            openPin: true,
            appointmentId: item.uniqueid,
            sendToCId: item.clinic.uniqueid
        }));
    }
    const handlePMSStatusChange = async (item: any, oldvalue: any, newvalue: any) => {
        try {
            const response = await changeAppointmentPMSStatus(userData.clinics[0].uniqueId, item.uniqueid, {
                oldValue: oldvalue, newValue: newvalue
            });
            if (response && response.status && response.status.error === false) {
                fecthAppointmentListAsync(true);
                toast.success('Appointment pms status change success.');
            }
        } catch (err: any) {
            if (err.msg) {
                toast.error(err.msg);
            } else {
                toast.error('Appointment pms status change failed.');
            }
        }
    }
    const handleAppointmentUnverified = async (item: any) => {
        try {
            const response = await appointmentUnverified(item.clinic.uniqueid, item.uniqueid);
            if (response && response.status && response.status.error === false) {
                toast.success(response.status.msg);
                fecthAppointmentListAsync(true);
            } else {
                toast.error('Appointment unverified failed.');
            }
        } catch (err) {
            console.log(err);
            toast.error('Appointment unverified failed.');
        }
    }
    const cancelAppointment = (item: any) => {
        setShowCancelModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const handleAppointmentVerify = (item: any) => {
        let message = 'Are you sure you want to complete this appointment.';
        if ((!(item.metadata && item.metadata.appointment &&
            item.metadata.appointment.prescreening &&
            item.metadata.appointment.prescreening.attempt)) &&
            !(item.metadata && item.metadata.appointment &&
                item.metadata.appointment.prescreening &&
                item.metadata.appointment.prescreening.verifiedOn)) {
            message = ' Are you sure you want to mark this appointment as complete, we see that prescreening for this appointment is in pending state.';
        }
        confirmAlert({
            title: '',
            message: message,
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                        verifyAppointmentDirect(item);
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-primary',
                    onClick: () => {

                    }
                }
            ]
        });
    }
    const verifyAppointmentDirect = async (appointment: any) => {
        dispatch(fetchLoadingPending());
        var objIndex: string = appointment.currentStatus.toUpperCase();
        const fromStatus = CHAT.FROM_STATUS[objIndex];
        const obj = {
            "appointmentId": Number(appointment.uniqueid),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": `Changed status from ${fromStatus} to ${CHAT.TO_STATUS.COMPLETED}.`,
            "actionType": 'STATUS',
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "fromStatus": fromStatus,
            "toStatus": CHAT.TO_STATUS.COMPLETED,
            "sendToCId": appointment.clinic.uniqueid,
            "dependantId": Number(appointment.dependant.uniqueid),
            "dateTime": {
                "date": appointment.date,
                "time": appointment.time
            },
            "firstName": userData.firstName,
            "lastName": userData.lastName,
        };
        try {
            const response = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
            if (response) {
                response.get().then((docRef: any) => {
                    let data = {
                        ...docRef.data(),
                        mode: "create",
                        id: docRef.id
                    };
                    firestoreChangeNotificationToServer(data).then((success) => {
                        dispatch(fetchLoadingSuccess(false));
                        fecthAppointmentListAsync(true);
                        toast.success('Appointment verified, select if you want to send invoice.');
                    }).catch((err) => {
                        dispatch(fetchLoadingSuccess(false));
                        toast.error('Appointment verify error.');
                        console.log(err);
                    })
                });
            }
        } catch (err) {
            dispatch(fetchLoadingSuccess(false));
            toast.error('Appointment verify error.');
        }
    }
    const handleConfirmByPatient = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to confirm patient visit?',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-outline-primary',
                    onClick: async () => {
                        dispatch(fetchLoadingPending());
                        const obj = {
                            "appointmentId": Number(item.uniqueid),
                            "senderId": Number(getCookie().xpr_user_id),
                            "sender": CHAT.SENDER.USER,
                            "action": CHAT.ACTION.CBP,
                            "actionType": CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT,
                            "text": 'Confirm by patient',
                            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                            "sendToCId": item.clinic.uniqueid,
                            "sendToDId": item.doctor.uniqueid,
                            "dependantId": Number(item.dependant.uniqueid),
                            "dateTime": {
                                "date": item.date,
                                "time": item.time
                            },
                            "firstName": userData.firstName,
                            "lastName": userData.lastName,
                        };
                        try {
                            const response = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
                            if (response) {
                                response.get().then((docRef: any) => {
                                    let data = {
                                        ...docRef.data(),
                                        mode: "create",
                                        id: docRef.id
                                    };
                                    firestoreChangeNotificationToServer(data).then((success) => {
                                        dispatch(fetchLoadingSuccess(false));
                                        fecthAppointmentListAsync(true);
                                        toast.success('Appt. confirm by patient successful.');
                                    }).catch((err) => {
                                        toast.error('Appt. confirm by patient failed.');
                                    });
                                });
                            }
                        } catch (err) {
                            toast.error('Appt. confirm by patient failed.');
                            dispatch(fetchLoadingSuccess(false));
                        }
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-primary',
                    onClick: () => {

                    }
                }
            ]
        });
    }
    const manualReview = async (appointment:any) => {
        try {
            confirmAlert({
                title: '',
                message: "This action will send review request to patient, please confirm.",
                buttons: [
                    {
                        label: 'Yes',
                        className: 'btn btn-outline-primary',
                        onClick: async () => {
                            try {
                                dispatch(fetchLoadingSuccess(true));
                                const response = await sendManualReview(appointment.uniqueid,userData.clinics[0].uniqueId,);
                                dispatch(fetchLoadingSuccess(false));
                                if (response && response.status && response.status.error === false) {
                                    fecthAppointmentListAsync(true);
                                } else {
                                    toast.error(response.status.msg);
                                    dispatch(fetchLoadingSuccess(false));
                                }
                            } catch (err) {
                                toast.error("Review sent failed");
                                dispatch(fetchLoadingSuccess(false));
                            }
                        }
                    },
                    {
                        label: 'No',
                        className: 'btn btn-outline-primary',
                        onClick: () => {

                        }
                    }
                ]
            });
        } catch (err: any) {
            dispatch(fetchLoadingSuccess(false));
            if (err.msg) {
                toast.error(err.msg);
            } else {
                toast.error('Mark appt done failed.');
            }
        }
    }
    const handleShowNote = (appointment: any) => {
        setCurrentActiveModalAppointment(appointment);
        setShowNoteModal(true);
    };
    const handlePaymentRequest = async (appointment: any) => {
        const obj = {
            "appointmentId": Number(appointment.uniqueid),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": CHAT.ACTION.PRFC,
            "actionType": CHAT.ACTION_TYPE.PAYMENT_REQUEST,
            "text": appointment.clinic.name + ' requested ' + appointment?.billingdata?.clinicToPatientBilling?.total + ' amount of payment from you.',
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": appointment.clinic.uniqueid,
            "sendToDId": appointment.doctor.uniqueid,
            "dependantId": Number(appointment.dependant.uniqueid),
            "dateTime": {
                "date": appointment.date,
                "time": appointment.time
            },
            "firstName": userData.firstName,
            "lastName": userData.lastName,
        };
        try {
            dispatch(fetchLoadingPending());
            await firebaseInstance.setDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
            toast.success('Payment request sent successfully.');
            dispatch(fetchLoadingSuccess(false));
        } catch (err) {
            console.log("err", err);
            dispatch(fetchLoadingSuccess(false));
            toast.error('Payment request sent failed.');
        }
    }
    return (
        <React.Fragment>
            <MyScheduleHeader onFetchAppointment={fecthAppointmentListAsync}></MyScheduleHeader>
            <section className="schedule-sec px-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div>NAME</div>
                                            <div className="d-flex">
                                                <input className="form-control form-control-sm" placeholder="Search by name" value={name} onChange={(e) => {
                                                    setPage(1);
                                                    setName(e.target.value);
                                                }} />
                                                <button className="btn btn-sm btn-secondary ms-2" onClick={(e) => {
                                                    setPage(1);
                                                    setName('');
                                                }}><i className="bi bi-x"></i></button>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div>Provider</div>
                                            {
                                                useMemo(() => {
                                                    return (
                                                        <select className="form-select form-select-sm" style={{ width: "200px" }} value={selectedProvider} onChange={(e) => {
                                                            setPage(1);
                                                            setSelectedProvider(e.target.value);
                                                        }}>
                                                            <option value=''>All provider</option>
                                                            {
                                                                providerList.map((value: any, index) => {
                                                                    return (
                                                                        <option value={value.uniqueid} key={value.uniqueid + "provider"}>{value.fullname}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    )
                                                }, [providerList, selectedProvider])
                                            }
                                        </th>
                                        <th scope="col">
                                            <div>Status</div>
                                            {
                                                useMemo(() => {
                                                    return (
                                                        <select className="form-select form-select-sm" style={{ width: "200px" }} value={selectedStatus} onChange={(e) => {
                                                            setPage(1);
                                                            setSelectedStatus(e.target.value);
                                                        }}>
                                                            <option value='all'>All</option>
                                                            <option value='requested'>Requested</option>
                                                            <option value='scheduled'>Scheduled</option>
                                                            <option value='completed'>Completed</option>
                                                            <option value='cancelled'>Cancelled</option>
                                                        </select>
                                                    )
                                                }, [selectedStatus])
                                            }
                                        </th>
                                        {
                                            (userData && userData.accessEnabled && userData.accessEnabled.pms_integration &&
                                                userData.clinics[0] &&
                                                (userData.clinics[0].writeBack === "on" ||
                                                    userData.clinics[0].tempwriteBack === "on") && userData.clinics[0].statuswriteback === "on") &&
                                            <th scope="col">PMS</th>
                                        }
                                        <th className="text-end" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isLoading && !isRefreshing &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        isRefreshing && !isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        isRefreshing && isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        useMemo(() => {
                                            if (!isLoading && appointmentItemList.length === 0) {
                                                return <tr>No records found</tr>
                                            }
                                            if (userData && userData.clinics[0] && !isLoading) {
                                                return (
                                                    <>
                                                        {
                                                            appointmentItemList.map((item: any) => {
                                                                return (
                                                                    <ItemRow item={item}
                                                                        key={item.uniqueid}
                                                                        handleDigitalDocClick={handleDigitalDocClick}
                                                                        addChatPin={addChatPin}
                                                                        handlePMSStatusChange={handlePMSStatusChange}
                                                                        masterPMSStatusList={masterPMSStatusList}
                                                                        handleAppointmentUnverified={handleAppointmentUnverified}
                                                                        cancelAppointment={cancelAppointment}
                                                                        handleAppointmentVerify={handleAppointmentVerify}
                                                                        handleConfirmByPatient={handleConfirmByPatient}
                                                                        userData={userData}
                                                                        handlePatientDetailAppointmentClick={handlePatientDetailAppointmentClick}
                                                                        manualReview={manualReview}
                                                                        handleShowNote={handleShowNote}
                                                                        handlePaymentRequest={handlePaymentRequest}
                                                                    ></ItemRow>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            }
                                        }, [appointmentItemList, isLoading])
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    hideDisabled
                                    activePage={page}
                                    itemsCountPerPage={results}
                                    totalItemsCount={Number(totalCount)}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                useMemo(() => {
                    if (digitalDocShow && currentActiveModalAppointment) {
                        return (
                            <DigitalDoc
                                appointment={currentActiveModalAppointment}
                                hideModal={() => {
                                    setShowDigitalDocShow(false);
                                }}
                                fetchAppointment={fecthAppointmentListAsync}
                            ></DigitalDoc>
                        )
                    }
                }, [digitalDocShow, currentActiveModalAppointment])
            }
            {
                useMemo(() => {
                    if (currentActiveModalAppointment && showCancelModal) {
                        return (
                            <CancelAppointmentModal appointment={currentActiveModalAppointment}
                                hideModal={() => {
                                    setShowCancelModal(false);
                                }}
                                fetchAppointment={fecthAppointmentListAsync}
                            ></CancelAppointmentModal>
                        )
                    }
                }, [currentActiveModalAppointment, showCancelModal])
            }
            {
                useMemo(() => {
                    if (showPatientDetailModal && currentActiveModalAppointment) {
                        return (
                            <PatientDetailModel
                                hideModal={() => {
                                    setShowPatientDetailModal(false);
                                }}
                                appointment={currentActiveModalAppointment}
                            >
                            </PatientDetailModel>
                        )
                    }
                }, [showPatientDetailModal, currentActiveModalAppointment])
            }
            {
                showNoteModal &&
                <NoteModal
                    data={currentActiveModalAppointment}
                    handleCancel={() => {
                        setShowNoteModal(false);
                    }} handleOk={() => {
                        setShowNoteModal(false);
                        toast.success("Note added successfully");
                    }}
                ></NoteModal>
            }
        </React.Fragment>
    )
}

const ItemRow = ({ item, handleDigitalDocClick, addChatPin, handlePMSStatusChange, masterPMSStatusList,
    handleAppointmentUnverified, cancelAppointment, handleAppointmentVerify, handleConfirmByPatient, userData, handlePatientDetailAppointmentClick, manualReview, handleShowNote, handlePaymentRequest }: any) => {
    let takeWritebackBool = false;
    let apptScheduled = true;
    let apptReSchedule = true;
    let apptConfirmPatient = true;
    let apptComplete = true;
    let apptEdit = true;
    let apptCancel = true;
    let apptPMSStatus = true;
    let tempWriteBack = false;
    if (userData && userData.clinics && userData.clinics[0] &&
        userData.clinics[0].tempwriteBack === "on") {
        tempWriteBack = true;
    }
    if (userData?.accessEnabled?.pms_integration === true) {
        if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].appointmentwriteback === "on") {
            apptReSchedule = false;
        }
        if (userData.clinics[0].writeBack === "off") {
            apptScheduled = false;
            // apptReSchedule = true; // enabled for special case of SIKKA status sync issue
            apptConfirmPatient = false;
            apptComplete = true; // enabled for special case of SIKKA status sync issue
            apptEdit = true; // enabled for special case of SIKKA status sync issue
            apptCancel = true; //enabled for special case of SIKKA status sync issue
            apptPMSStatus = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "off" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "off") {
            apptScheduled = false;
            // apptReSchedule = false;
            apptConfirmPatient = false;
            apptComplete = false;
            apptEdit = false;
            apptCancel = false;
            apptPMSStatus = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "on" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "off") {
            apptScheduled = false;
            // apptReSchedule = false;
            apptConfirmPatient = false;
            apptComplete = false;
            apptEdit = false;
            apptCancel = false;
            apptPMSStatus = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "on" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "on") {
            apptScheduled = false;
            // apptReSchedule = false;
            apptEdit = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "off" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "on") {
            apptScheduled = false;
            // apptReSchedule = false;
            apptEdit = false;
        } else if (userData.clinics[0].writeBack === "on" &&
            userData.clinics[0].patientwriteback === "off" &&
            userData.clinics[0].appointmentwriteback === "off" &&
            userData.clinics[0].statuswriteback === "off") {
            apptScheduled = false;
            // apptReSchedule = false;
            apptConfirmPatient = false;
            apptComplete = false;
            apptEdit = false;
            apptCancel = false;
            apptPMSStatus = false;
        }
    }
    if (userData.clinics[0] &&
        userData?.accessEnabled?.pms_integration === true &&
        (userData.clinics[0].writeBack === "on") &&
        item.metadata && item.metadata.pms && item.metadata.pms.writeBackStatus && item.metadata.pms.writeBackStatus === "Success") {
        takeWritebackBool = true;
    } else if (userData.clinics[0] &&
        userData?.accessEnabled?.pms_integration === true &&
        (userData.clinics[0].writeBack === "off")) {
        takeWritebackBool = true; //enabled for special case sikka sync issue.
    } else if (userData.clinics[0] &&
        userData?.accessEnabled?.pms_integration === false &&
        userData.clinics[0].writeBack === "off" &&
        (!(item.metadata && item.metadata.pms && item.metadata.pms.writeBackStatus) ||
            (item.metadata && item.metadata.pms && item.metadata.pms.writeBackStatus === "Success"))) {
        takeWritebackBool = true;
    };
    const getBadge = () => {
        const data = item;
        let badges = [];
        if (data?.metadata?.appointment?.floating) {
            badges.push(<span className="badge ms-2 rounded-pill bg-warning">Floating</span>)
        } else {
            if (!data?.metadata?.appointment?.prescreening?.attempt &&
                !data?.metadata?.appointment?.confirmation?.attempt && data.currentStatus === "scheduled") {
                badges.push(<span className="badge ms-2 rounded-pill bg-secondary">Scheduled</span>);
            }
            if (data?.metadata?.appointment?.checkin && data?.metadata?.appointment?.checkin.attempt === true) {
                badges.push(<span className="badge ms-2 rounded-pill bg-success">Checked-in</span>)
            } else {
                if (data?.metadata?.appointment?.confirmation?.attempt &&
                    data?.metadata?.appointment?.confirmation?.status === "confirmed") {
                    badges.push(<span className="badge ms-2 rounded-pill bg-darkgreen">Confirmed</span>)
                }
            }
        }
        return badges;
    }
    return (
        <tr className={"table-row have-msg"} key={item.uniqueid}>
            <td>
                <span className="d-block fw-medium p-0 cursor-pointer btn-link" onClick={() => {
                    handlePatientDetailAppointmentClick(item)
                }}>
                    {
                        (item.dependant && item.dependant.fname && item.dependant.lname) ?
                            (item.dependant.fname + ' ' + item.dependant.lname).substring(0, 15) : ''
                    }
                </span>
                {/*  <button className="btn p-0 btn-link"
                    type="button"
                    onClick={(e) => { handlePatientDetailAppointmentClick(item) }}>
                    {
                        (item.dependant && item.dependant.fname && item.dependant.lname) ?
                            (item.dependant.fname + ' ' + item.dependant.lname).substring(0, 15) : ''
                    }
                </button> */}
                <span className="d-block text-muted">
                    {
                        item.dependant.phone && item.dependant.phone !== "" ? (CONSTANT_LABELS.PLUS_ONE + removeCountryCode(item.dependant.phone)) : item.dependant.email
                    }
                </span>
            </td>
            <td>
                <div className="d-flex p-0">
                    <div className="avatar flex-shrink-0 avatar-lg border rounded-circle me-3">
                        {
                            (item.doctor && item.doctor.photo && item.doctor.photo.name &&
                                item.doctor.photo.type) ?
                                <img src={configs.IMAGE_URL + 'doctor/' +
                                    item.doctor.photo.name + '-200x200.' + item.doctor.photo.type} alt="profile image" className="img-fluid" />
                                :
                                <>{item.doctor.fullname.split(" ")[0] ? item.doctor.fullname.split(" ")[0].charAt(0) : ''}
                                    {item.doctor.fullname.split(" ")[1] ? item.doctor.fullname.split(" ")[1].charAt(0) : ''}</>
                        }
                    </div>
                    <div>
                        <span className="d-block fw-medium"> {(item.doctor && item.doctor.fullname) ?
                            item.doctor.fullname : ''}</span>
                        <small className="d-block text-muted">{item.appointmentType ? item.appointmentType : ''}</small>
                    </div>
                </div>
            </td>
            <td>
                {
                    item.currentStatus === 'waitlist' && <>
                        {
                            (item && item.date && item.time) &&
                            <>
                                <span className="d-block fw-medium p-0">Waitlist</span>
                                <small className="d-block p-0">{moment(item.date + " " + item.time, 'YYYY-MM-DD LT').format("MMM DD, YYYY - LT")}</small>
                            </>
                        }
                    </>
                }
                {
                    item.currentStatus === 'requested' && <>
                        {
                            (item && item.date && item.time) &&
                            <>
                                <span className="d-block fw-medium p-0">Waitlist</span>
                                <small className="d-block p-0">{moment(item.date + " " + item.time, 'YYYY-MM-DD LT').format("MMM DD, YYYY - LT")}</small>
                            </>
                        }
                    </>
                }
                {
                    item.currentStatus === 'scheduled' && <>
                        {
                            (item && item.date && item.time) &&
                            <>
                                <span className="d-block fw-medium p-0">Upcoming</span>
                                <small className="d-block p-0">{moment(item.date + " " + item.time, 'YYYY-MM-DD LT').format("MMM DD, YYYY - LT")}</small>
                            </>
                        }
                    </>
                }
                {
                    item.currentStatus === 'cancelled' && <>
                        {
                            (item.metadata && item.metadata.appointment
                                && item.metadata.appointment.cancellation && item.metadata.appointment.cancellation.cancelledOn) &&
                            <>
                                <span className="d-block fw-medium p-0">Cancelled</span>
                                <small className="d-block p-0">
                                    {moment(item.metadata.appointment.cancellation.cancelledOn, 'YYYY-MM-DD HH:mm:ss ZZ').format("MMM DD, YYYY")} -
                                    {moment(item.metadata.appointment.cancellation.cancelledOn, 'YYYY-MM-DD HH:mm:ss ZZ').format("LT")}
                                </small>
                            </>
                        }
                    </>
                }
                {
                    item.currentStatus === 'completed' && <>
                        {
                            (item.metadata && item.metadata.appointment
                                && item.metadata.appointment.verifiedOn) &&
                            <>
                                <span className="d-block fw-medium p-0">Completed</span>
                                <small className="d-block p-0">{moment(item.metadata.appointment.verifiedOn, 'YYYY-MM-DD HH:mm:ss ZZ').format("MMM DD, YYYY")} - {moment(item.metadata.appointment.verifiedOn, 'YYYY-MM-DD HH:mm:ss ZZ').format("LT")}</small>
                            </>
                        }
                    </>
                }
            </td>
            {
                ((userData && userData.accessEnabled && userData.accessEnabled.pms_integration && userData.clinics[0] &&
                    (userData.clinics[0].writeBack === "on" ||
                        userData.clinics[0].tempwriteBack === "on")) && (apptPMSStatus || userData.clinics[0].tempwriteBack === "on")) &&
                <td className="text-center">
                    {
                        (item && item.metadata && item.metadata.pms && item.metadata.pms.writeBackStatus &&
                            item.metadata.pms.writeBackStatus === "Success") ?
                            <>
                                <select className="form-select" defaultValue={item.metadata.pms.appointmentStatus ? item.metadata.pms.appointmentStatus : ""} onChange={(e: any) => {
                                    if (e.target.value === "") return;
                                    handlePMSStatusChange(item, item.metadata.pms.appointmentStatus, e.target.value);
                                }}>
                                    <option value="" disabled>Not available</option>
                                    {
                                        masterPMSStatusList.map((item: any) => {
                                            return <option value={item.status} key={item.id + "" + item.status}>{item.status}</option>
                                        })
                                    }
                                </select>
                            </>
                            :
                            <>
                                <label>Pending</label>
                            </>
                    }
                </td>
            }
            <td className="text-end">
                {
                    item.dependant && !item.dependant.phone && !item.patient.phone && (item.currentStatus === 'scheduled' || item.currentStatus === 'requested' || item.currentStatus === 'waitlist') &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">Manual followup</Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { }}>
                            <i className="bi bi-telephone"></i></button>
                    </OverlayTrigger>
                }
                {
                    (item.dependant && (item.dependant.isSignInUser === true || item.dependant.phone) &&
                        (!(item.billingdata && item.billingdata.clinicToPatientBilling &&
                            item.billingdata.clinicToPatientBilling.status === "paid") && item.currentStatus === 'completed') ||
                        (item.dependant && (item.dependant.isSignInUser === true || item.dependant.phone) && (item.currentStatus === 'scheduled' || item.currentStatus === 'requested' || item.currentStatus === 'waitlist'))) &&

                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Quick chat
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { addChatPin(item); }}>
                            <i className="bi bi-chat-right-text"><span className="top-badges-yellow"></span></i></button>
                    </OverlayTrigger>
                }
                {
                    (item.dependant && item.dependant.isSignInUser === true && (item.currentStatus !== 'cancelled') && userData.accessEnabled?.digitaldoc === true) &&
                    <OverlayTrigger placement="auto" overlay={(props: any) => {
                        return (
                            <Tooltip id="button-tooltip" {...props}>Digital forms</Tooltip>
                        )
                    }}>
                        <button onClick={() => {
                            handleDigitalDocClick(item);
                        }} className="btn mx-1 p-1">
                            <i className="bi bi-file-medical"></i>
                        </button>
                    </OverlayTrigger>
                }
                {((item.currentStatus === 'scheduled' || item.currentStatus === 'requested' || item.currentStatus === 'waitlist') && (takeWritebackBool || tempWriteBack) && apptCancel) &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Cancel appointment
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { cancelAppointment(item); }}>
                            <i className="bi bi-calendar-x"></i>
                        </button>
                    </OverlayTrigger>
                }
                {
                    ((takeWritebackBool || tempWriteBack) && apptComplete && (moment(item.date + " " + item.time, 'YYYY-MM-DD LT').isBefore(moment()) &&
                        !(item.metadata && item.metadata.appointment &&
                            item.metadata.appointment.confirmation &&
                            item.metadata.appointment.confirmation.status === 'cancelled') &&
                        !(item.metadata && item.metadata.appointment &&
                            item.metadata.appointment.prescreening &&
                            item.metadata.appointment.prescreening.attempt &&
                            item.metadata.appointment.prescreening.result === 'Fail'))) &&
                    item.currentStatus === 'scheduled' &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Mark Appt. Complete
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={() => {
                                handleAppointmentVerify(item);
                            }}>
                            <i className="bi bi-patch-check"></i></button>
                    </OverlayTrigger>
                }
                {
                    ((takeWritebackBool || tempWriteBack) && apptConfirmPatient && (moment(moment(item.date + " " + item.time, 'YYYY-MM-DD LT').add(item.duration, 'minutes').format('YYYY-MM-DD LT')).isAfter(moment().format('YYYY-MM-DD LT')) &&
                        (!(item.metadata && item.metadata.appointment &&
                            item.metadata.appointment.prescreening &&
                            item.metadata.appointment.prescreening.attempt &&
                            item.metadata.appointment.prescreening.result === 'Fail') &&
                            !(item.metadata && item.metadata.appointment &&
                                item.metadata.appointment.prescreening &&
                                item.metadata.appointment.prescreening.attempt)) &&
                        !(item.metadata && item.metadata.appointment &&
                            item.metadata.appointment.confirmation &&
                            (item.metadata.appointment.confirmation.status === 'confirmed' ||
                                item.metadata.appointment.confirmation.status !== 'cancelled')))) &&
                    item.currentStatus === 'scheduled' &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Appt. confirmed by patient
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={() => {
                                handleConfirmByPatient(item);
                            }}>
                            <i className="bi bi-patch-check"></i></button>
                    </OverlayTrigger>
                }
                {
                    moment(moment(item.date + " " + item.time, 'YYYY-MM-DD LT').add(item.duration, 'minutes').format('YYYY-MM-DD LT')).isBefore(moment().format('YYYY-MM-DD LT')) &&
                        <OverlayTrigger
                            placement="auto"
                            overlay={
                                <Tooltip id="tooltip">
                                    Review
                                </Tooltip>
                            }
                        >
                            <button type="button" className="btn mx-1 p-1"
                                onClick={(e) => { manualReview(item) }}>
                                <i className="bi bi-hand-index-thumb"></i>
                            </button>
                        </OverlayTrigger>
                }
                {
                    
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Note
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={() => {
                                handleShowNote(item);
                            }}>
                            <i className="bi bi-pencil-square"></i></button>
                    </OverlayTrigger>
                }
                {
                    (userData.accessEnabled &&
                        userData.accessEnabled
                        .contactless_payments) && item.currentStatus === 'completed' &&
                        <>
                            {
                                !item?.metadata?.appointment?.payment ?
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Payment request
                                        </Tooltip>
                                    }
                                >
                                    <button className={"btn btn-sm me-2"} onClick={(e) => {
                                        handlePaymentRequest(item);
                                    }}><i className="bi bi-cursor"></i></button>
                                </OverlayTrigger>
                                :
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Resend request
                                        </Tooltip>
                                    }
                                >
                                    <button className={"btn btn-sm me-2 "} onClick={(e) => {
                                        handlePaymentRequest(item);
                                    }}><i className="bi bi-arrow-clockwise"></i></button>
                                </OverlayTrigger>
                            }
                        </>
                }
                <div className="inline-badges">
                    {getBadge()}
                </div>
            </td>
        </tr>
    )
}
