export enum LOGINFORGOTSENT {
    LOAD_PENDING = 'LOAD_PENDING_LOGIN_FORGOT_SENT',
    LOAD_ERROR = 'LOAD_FAILED_LOGIN_FORGOT_SENT',
    LOAD_SUCCESS = 'LOAD_SUCCESS_LOGIN_FORGOT_SENT'
};

export interface LoginForgotSent {
    loginforgotsent: string;
}

export interface LoginForgotSentState {
    pending: boolean;
    loginforgotsent: LoginForgotSent;
    error: any;
}

export interface FetchLoginForgotSentPendingAction {
    type: LOGINFORGOTSENT.LOAD_PENDING;
    payload: LoginForgotSent;
}


export interface FetchLoginForgotSentErrorAction {
    type: LOGINFORGOTSENT.LOAD_ERROR;
    error: any;
}
export interface FetchLoginForgotSentSuccessAction {
    type: LOGINFORGOTSENT.LOAD_SUCCESS;
    loginforgotsent: LoginForgotSent
}

export type LoginForgotSentActionTypes = FetchLoginForgotSentErrorAction | FetchLoginForgotSentPendingAction | FetchLoginForgotSentSuccessAction;