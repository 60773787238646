// import './_practiceSteps.scss';
import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { emailValidator, phoneValidator } from '../../../utils/validators';
import { takeOnlyDigitAndPlus } from '../../../utils/global';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { createStep, getLanguagesList, getSpecialityList } from '../../services/providerOnboard';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IAppState } from '../../../store';
import RemoveBtn from "../../assets/images/remove-btn.svg";
export function ProviderApp1(props: any) {

	const dispatch = useDispatch();
	const [previewFile, setPreviewFile] = useState('');
	const [selectedFile, setSelectedFile] = useState('');
	const [imageError, setImageError] = useState(false);
	const [providerFullName, setproviderFullName] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [gender, setGender] = useState('Male');
	const [uniqueLicNo, setUniqueLicNo] = useState('');
	const [specialties, setSpecialties]: any = useState([]);
	const [language, setLanguage]: any = useState([]);
	let uploadRef: any = useRef();
	const [selectedSpecilaties, setSelectedSpecilaties] = useState([]);
	const [selectedLaunguage, setSelectedLaunguage] = useState([]);
	const [profileImageValidation, setProfileImageValidation] = useState({ profileImageValidation: false, msg: '' });
	const [specialtiesValidation, setSpecialtiesValidation] = useState({ specialitiesValidation: false, msg: '' });
	const [providerNameValidation, setProviderNameValidation] = useState({ providerNameValidation: false, msg: '' });
	const [emailValidation, setEmailValidation] = useState({ emailValidation: false, msg: '' });
	const [phoneNumberValidation, setPhoneValidation] = useState({ phoneNumberValidation: false, msg: '' })
	const [languageValidation, setLanguageValidation] = useState({ languageValidation: false, msg: '' });
	const [unqiueValidation, setUnqiueValidation] = useState({ unqiueValidation: false, msg: '' });
	const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);


	useEffect(() => {
		if (props.clinicId) {
			dispatch(fetchLoadingPending());
			getSpecialityList(props.clinicId).then((success) => {
				dispatch(fetchLoadingSuccess(false))

				if (success.status.error === false) {
					setSpecialties(success.speciality.map((item: any) => {
						return {
							value: item.id,
							label: item.name
						}
					}))
				}
			}).catch((err) => {
				dispatch(fetchLoadingSuccess(false));
			});

			dispatch(fetchLoadingPending());
			getLanguagesList().then((success) => {
				dispatch(fetchLoadingSuccess(false))
				if (success.status.error === false) {
					setLanguage(success.languages.map((item: any) => {
						return {
							value: item.id,
							label: item.name
						}
					}))
				}
			}).catch((err) => {
				dispatch(fetchLoadingSuccess(false));
			});
		}
	}, [props.clinicId]);

	const onFileChange = (event: any) => {
		const file = event.target.files[0];
		if (file && (file.type === "image/png" ||
			file.type === 'image/jpg' ||
			file.type === 'image/jpeg')) {
			setSelectedFile(event.target.files[0]);
			var reader: any = new FileReader();
			var url: any = reader.readAsDataURL(event.target.files[0]);
			reader.onloadend = function (e: any) {
				//Initiate the JavaScript Image object.
				var image = new Image();
				//Set the Base64 string return from FileReader as source.
				image.src = e.target.result;
				//Validate the File Height and Width.
				image.onload = function () {
					var height = image.height;
					var width = image.width;
					if (height < 256 || width < 256) {
						setImageError(true);
						return false;
					}
					setImageError(false);
					setPreviewFile(reader.result);
					return true;
				};
			};
			return;
		} else {
			setImageError(true);
		}
	}

	const onGenderChange = (event: any) => {
		setGender(event.target.value);
	}

	const personalInfoValidation = () => {
		// if (selectedFile === '') {
		// 	setProfileImageValidation({ profileImageValidation: true, msg: 'Profile Image Requried', });
		// 	return;
		// }
		if (selectedSpecilaties.length === 0) {
			setSpecialtiesValidation({ specialitiesValidation: true, msg: 'Specilaties Requried' });
			return;
		}
		if (providerFullName === '') {
			setProviderNameValidation({ providerNameValidation: true, msg: 'Provider Full Name Requried' });
			return;
		}
		if (!emailValidator(email)) {
			setEmailValidation({ emailValidation: true, msg: 'Invalid Email' });
			return;
		}
		if (phoneNumber.length < 10) {
			setPhoneValidation({ phoneNumberValidation: true, msg: 'Invalid PhoneNumber' });
			return;
		}
		if (selectedLaunguage.length === 0) {
			setLanguageValidation({ languageValidation: true, msg: 'Language Requried' });
			return;
		}
		if (uniqueLicNo === '') {
			setUnqiueValidation({ unqiueValidation: true, msg: 'UniqueLicNo Requried' });
			return;
		}
		const request = {
			uniqueid: props.clinicUniqueId,
			logo: selectedFile,
			speciality: selectedSpecilaties.map((item: any) => {
				return item.value
			}).toString(),
			fullname: providerFullName,
			email: email,
			phone: phoneNumber,
			gender: gender,
			languages: selectedLaunguage.map((item: any) => {
				return item.value
			}).toString(),
			licence: uniqueLicNo
		};


		dispatch(fetchLoadingPending());
		createStep({
			stepNumber: 1,
			formData: request
		}).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status.error === false) {
				props.nextStep(1, { personalInfo: request, providerId: success.providerId });
				let isPmsTaken = userData.accessEnabled.pms_integration;
				if (isPmsTaken) {
					props.setPercentage(16);
				} else {
					props.setPercentage(20);
				}
			}

		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});


	}
	useEffect(() => {
		if (props.clinicId) {
			dispatch(fetchLoadingPending());
			getSpecialityList(props.clinicId).then((success) => {
				dispatch(fetchLoadingSuccess(false))

				if (success.status.error === false) {
					setSpecialties(success.speciality.map((item: any) => {
						return {
							value: item.id,
							label: item.name
						}
					}))
					if (props.data) {
						setSelectedSpecilaties(success.speciality.filter((item: any) => {
							if (props.data.speciality.split(",").find((sItem: any) => sItem === item.id)) {
								return true;
							}
							return false;
						}).map((item: any) => {
							return {
								value: item.id,
								label: item.name
							}
						}));

					}
				}
			}).catch((err) => {
				dispatch(fetchLoadingSuccess(false));
			});

			dispatch(fetchLoadingPending());
			getLanguagesList().then((success) => {
				dispatch(fetchLoadingSuccess(false))
				if (success.status.error === false) {
					setLanguage(success.languages.map((item: any) => {
						return {
							value: item.id,
							label: item.name
						}
					}));
					if (props.data) {
						setSelectedLaunguage(success.languages.filter((item: any) => {
							if (props.data.languages.split(",").find((lItem: any) => lItem === item.id)) {
								return true;
							}
							return false;
						}).map((item: any) => {
							return {
								value: item.id,
								label: item.name
							}
						}));
					}
				}
			}).catch((err) => {
				dispatch(fetchLoadingSuccess(false));
			});
		}


		if (props.data) {
			if (props.data.logo) {
				setPreviewFile(URL.createObjectURL(props.data.logo));
			} else {
				setPreviewFile('');
			}
			setSelectedFile(props.data.logo);
			setproviderFullName(props.data.fullname);
			setEmail(props.data.email);
			setPhoneNumber(props.data.phone);
			setGender(props.data.gender);
			setUniqueLicNo(props.data.licence);
		}
	}, [props.data]);

	return (
		<>
			<div className="row">
				<div className="col-12">
					<h2 className="mb-3">Personal info</h2>
					<div className="fw-medium fs-5">Personal information of provider</div>
					<p className="mt-1">- Please ensure that all of the following information is correct. We will use this profile for your listing and for marketing purposes.</p>
					<hr className="my-4" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="profile-logo-sec d-flex">
						<div className="img-box rounded-circle border position-relative">
							{
								previewFile !== '' &&
								<button className="btn position-absolute top-0 end-0 p-0 rounded-circle" onClick={(e) => {
									setSelectedFile('');
									setPreviewFile('');
									uploadRef.current.value = '';
								}}>
									<img src={RemoveBtn} />
								</button>
							}
							<img className="rounded-circle" style={{ height: 120, width: 120 }} src={previewFile}></img>
						</div>
						<div className="txt-box align-self-center ms-4">
							<input type="file" ref={uploadRef} style={{ display: 'none' }}
								onChange={onFileChange}
								required
							></input>
							<button className="btn border d-block fw-normal"
								onClick={(e) => {
									uploadRef.current.click();
								}}
							>Upload profile logo</button>
							<span className="d-block mt-2 text-muted">At least 256 x 256px PNG or JPG file.</span>
							{
								imageError &&
								<div className="is-invalid">At least 256 x 256px PNG or JPG file.</div>
							}
						</div>
					</div>
					{
						profileImageValidation.profileImageValidation === true &&
						<div className="is-invalid">{profileImageValidation.msg}</div>
					}
				</div>
				<div className="col-12">
					<form className="general-info-form provider-info mt-5" noValidate={true}>
						<div className="row">
							<div className="col-sm-6 mb-4">
								<label htmlFor="practiceName" className="form-label">Specialties</label>
								<div className="">
									<Select className="select2 myslt" options={specialties} isMulti
										value={selectedSpecilaties}
										onChange={(e: any) => { setSelectedSpecilaties(e) }}
									></Select>
									{
										specialtiesValidation.specialitiesValidation === true &&
										<div className="is-invalid">{specialtiesValidation.msg}</div>
									}
								</div>
								<small className="text-muted">Ex. Dentist, Orthodontist, Hygienist, etc...</small>
							</div>
							<div className="col-sm-6 mb-4">
								<label htmlFor="practiceName" className="form-label">Provider full name</label>
								<input type="text" className="form-control" id="providerFullName" placeholder="Provider full name" value={providerFullName} onChange={(e) => { setproviderFullName(e.target.value); }} />
								{
									providerNameValidation.providerNameValidation === true &&
									<div className="is-invalid">{providerNameValidation.msg}</div>
								}
								{/* <small className="text-muted">Business name, Location name, etc...</small> */}
							</div>
							<div className="col-sm-6 mb-4">
								<label htmlFor="emailAddress" className="form-label">Email address</label>
								<input type="email" className="form-control" id="emailAddress" placeholder="name@email.com" value={email} onChange={(e) => { setEmail(e.target.value); }} />
								{
									emailValidation.emailValidation === true &&
									<div className="is-invalid">{emailValidation.msg}</div>
								}
								<small className="text-muted">We’ll never share your email with anyone else.</small>
							</div>
							<div className="col-sm-6 mb-4">
								<label htmlFor="phoneNumber" className="form-label">Mobile Number</label>
								<InputMask mask="9999999999" className="form-control" placeholder="xxxxxxxxxx"
									value={phoneNumber}
									onChange={(e) => { setPhoneNumber(e.target.value); }}
									onBlur={(e) => { setPhoneNumber(e.target.value) }}
								>
									{(inputProps: any) => <input {...inputProps} className="form-control"></input>}
								</InputMask>
								{
									phoneNumberValidation.phoneNumberValidation === true &&
									<div className="is-invalid">{phoneNumberValidation.msg}</div>
								}
							</div>
							<div className="col-sm-6 mb-4">
								<label htmlFor="website" className="form-label">Gender</label>
								<div className="d-flex insurance-type">
									<div className="form-check">
										<input className="form-check-input" type="radio" name="gender" id="male"
											value='Male'
											checked={gender === 'Male'}
											onChange={onGenderChange}
										/>
										<label className="form-check-label" htmlFor="male">
											Male
									</label>
									</div>
									<div className="form-check ms-4">
										<input className="form-check-input" type="radio" name="gender" id="female"
											value='Female'
											checked={gender === 'Female'}
											onChange={onGenderChange}
										/>
										<label className="form-check-label" htmlFor="female">
											Female
									</label>
									</div>
								</div>
							</div>
							<div className="col-sm-6 mb-4">
								<label htmlFor="practiceName" className="form-label">Language Spoken</label>
								<div className="">
									<Select className="select2 myslt" options={language} isMulti
										value={selectedLaunguage}
										onChange={(e: any) => { setSelectedLaunguage(e) }}
									></Select>
									{
										languageValidation.languageValidation === true &&
										<div className="is-invalid">{languageValidation.msg}</div>
									}
								</div>
							</div>
							<div className="col-sm-6 mb-4">
								<label htmlFor="practiceName" className="form-label">License number
								<OverlayTrigger
										placement="auto"
										overlay={
											<Tooltip id="tooltip">
												Provide content for this
                                          </Tooltip>
										}
									>
										<i className="bi bi-info-circle ms-1"></i>
									</OverlayTrigger>


								</label>
								<input type="text" className="form-control" id="practicetName" placeholder="" value={uniqueLicNo}
									onChange={(e) => { setUniqueLicNo(e.target.value) }}
								/>
								{
									unqiueValidation.unqiueValidation === true &&
									<div className="is-invalid">{unqiueValidation.msg}</div>
								}

							</div>
						</div>
					</form>
				</div>
			</div>
			<div className="row">
				<div className="col-12 my-4">
					<div className="bottom-btn-sec text-end">
						<button className="btn btn-primary ms-1" onClick={() => {
							personalInfoValidation()
						}}>
							Next
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
