import { PROVIDER, FetchProviderErrorAction, FetchProviderPendingAction, FetchProviderSuccessAction, ProviderRequest, ProviderResponse, ServiceResponse, ServiceRequest, FetchServicePendingAction, FetchServiceSuccessAction, FetchServiceErrorAction, ProviderUpdateErrorAction, ProviderUpdateRequest, ProviderUpdatePendingAction, ProviderUpdateResponse, ProviderUpdateSuccessAction, ProviderMappingErrorAction, ProviderMappingResponse, ProviderMappingRequest, ProviderMappingPendingAction, ProviderMappingSuccessAction, PMSProviderScheduleUpdateRequest, PMSProviderScheduleUpdatePendingAction, PMSProviderScheduleUpdateErrorAction, PMSProviderScheduleUpdateResponse, PMSProviderScheduleUpdateSuccessAction } from './types';
/* Action Creators */
export const fetchProviderPending = (request: ProviderRequest): FetchProviderPendingAction => {
    return {
        type: PROVIDER.LOAD_PENDING,
        payload: request
    };
};
export const fetchProviderSuccess = (response: ProviderResponse): FetchProviderSuccessAction => {
    return {
        type: PROVIDER.LOAD_SUCCESS,
        provider: response
    }
}
export const fetchProviderError = (err: any): FetchProviderErrorAction => {
    return {
        type: PROVIDER.LOAD_ERROR,
        error: err
    }
}
export const fetchServicePending = (request: ServiceRequest): FetchServicePendingAction => {
    return {
        type: PROVIDER.LOAD_SERVICE_PENDING,
        payload: request
    };
};
export const fetchServiceSuccess = (response: ServiceResponse): FetchServiceSuccessAction => {
    return {
        type: PROVIDER.LOAD_SERVICE_SUCCESS,
        service: response
    }
}
export const fetchServiceError = (err: any): FetchServiceErrorAction => {
    return {
        type: PROVIDER.LOAD_SERVICE_ERROR,
        error: err
    }
}
export const providerUpdateErrorAction = (err: any): ProviderUpdateErrorAction => {
    return {
        type: PROVIDER.PROVIDER_UPDATE_ERROR,
        error: err
    }
}
export const providerUpdatePendingAction = (request: ProviderUpdateRequest): ProviderUpdatePendingAction => {
    return {
        type: PROVIDER.PROVIDER_UPDATE_PENDING,
        payload: request
    }
}
export const providerUpdateSuccessAction = (response: ProviderUpdateResponse): ProviderUpdateSuccessAction => {
    return {
        type: PROVIDER.PROVIDER_UPDATE_SUCCESS,
        providerUpdate: response
    }
}
export const providerMappingError = (err: any): ProviderMappingErrorAction => {
    return {
        type: PROVIDER.PROVIDER_MAPPING_ERROR,
        error: err
    }
}
export const providerMappingSuccess = (response: ProviderMappingResponse): ProviderMappingSuccessAction => {
    return {
        type: PROVIDER.PROVIDER_MAPPING_SUCCESS,
        providerMapping: response
    }
}
export const providerMappingPending = (request: ProviderMappingRequest): ProviderMappingPendingAction => {
    return {
        type: PROVIDER.PROVIDER_MAPPING_PENDING,
        payload: request
    }
}
export const pmsProviderScheduleUpdatePending = (request: PMSProviderScheduleUpdateRequest): PMSProviderScheduleUpdatePendingAction => ({
    type: PROVIDER.PMS_PROVIDER_SCHEDULE_UPDATE,
    payload: request
});
export const pmsProviderScheduleUpdateError = (error: any): PMSProviderScheduleUpdateErrorAction => ({
    type: PROVIDER.PMS_PROVIDER_SCHEDULE_UPDATE_ERROR,
    payload: error
});
export const pmsProviderScheduleUpdateSuccess = (response: PMSProviderScheduleUpdateResponse): PMSProviderScheduleUpdateSuccessAction => ({
    type: PROVIDER.PMS_PROVIDER_SCHEDULE_UPDATE_SUCCESS,
    payload: response
});