import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { administrativePatientsList, administrativePatientStatusChange } from '../../services/messageCenter';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from 'react-js-pagination';
import CONSTANT_LABELS from '../../../constants/labels';
import { removeCountryCode } from '../../../utils/global';
import AddBtnIcon from '../../assets/images/add-btn-icon.svg';
export const AdministrativePatients = () => {
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const isLoaded = useSelector((state: IAppState) => state.loading.pending, shallowEqual);
    const dispatch = useDispatch();
    const location = useLocation();
    const [patientListState, setPatientListState] = useState([]);
    useEffect(() => {
    }, []);
    var searchtext = new URLSearchParams(location.search).get("q");
    const getPatientList = () => {
        if (userData && userData.clinics[0] && userData.clinics[0].id) {
            dispatch(fetchLoadingPending());
            administrativePatientsList({
                clinicid: userData.clinics[0].id,
                searchtext: searchtext === null ? '' : searchtext,
                pageno: page,
                offset: results
            }).then((success: any) => {
                dispatch(fetchLoadingSuccess(false));
                if (success.error === false) {
                    setPatientListState(success.data);
                    if (success.data[0]) {
                        setTotalCount(success.data[0].total);
                    }
                    return;
                } toast.error(success.message);
            }).catch((err) => {
                dispatch(fetchLoadingSuccess(false));
                toast.error('Something went wrong.');
            });
        }
    }
    useEffect(() => {
        getPatientList();
    }, [page, searchtext]);

    const updatepatientstatus = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Are you sure to ' + (item.administrativenotificationstatus ? 'disable' : 'enable') + ' this configuration.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(fetchLoadingPending());
                        administrativePatientStatusChange({
                            clinicid: userData.clinics[0].id,
                            dependantid: item.dependantid,
                            messagecenterstatus: !item.administrativenotificationstatus
                        }).then((success: any) => {
                            if (success.error === false) {
                                getPatientList();
                            }
                        }).catch((err) => {
                            dispatch(fetchLoadingSuccess(false));
                            toast.error('Something went wrong.');
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const handlePageChange = (page: any) => {
        setPage(page);
    }
    return (
        <section className="recall-recare-sec p-4 camp-popups-sec">
            <div className="row mx-0">
                <div className="col-12 d-none">
                    <div className="filter-strip d-flex my-4 justify-content-between">
                        <div className="align-self-center me-2 recall-checkbox-sec">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" checked />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Active</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" checked />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Inactive</label>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="mx-1">
                                <select className="form-select" aria-label="Default select example">
                                    <option >Over due list</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="mx-1">
                                <select className="form-select" aria-label="Default select example">
                                    <option>Financial ratings</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="mx-1">
                                <select className="form-select" aria-label="Default select example">
                                    <option>Health ratings</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="mx-1">
                                <select className="form-select" aria-label="Default select example">
                                    <option>Appointment type</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="ms-1">
                                <button className="btn btn-primary"><img className="me-1" src={AddBtnIcon} />Create campaign</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    {
                        (patientListState.length > 0) &&
                        <div className="advances-table">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div className="form-check d-none">
                                                <input className="form-check-input" type="checkbox" value="" />
                                            </div>
                                        </th>
                                        <th scope="col">Name</th>
                                        <th scope="col">preference</th>
                                        <th scope="col">Mobile Number/email</th>
                                        {/* <th scope="col">Last Visit date</th> */}
                                        <th scope="col">Status</th>
                                        {/* <th className="table-papptype" scope="col text-center">Appo. type</th>
                  <th className="table-phealthratting" scope="col text-center">Health Ratings</th>
                  <th className="table-pfinanceratting" scope="col text-center">Financial ratings</th> */}
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        patientListState.map((item: any, index: number) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <div className="form-check d-none">
                                                            <input className="form-check-input" type="checkbox" value="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="d-block fw-medium">{item.dependantfname + ' ' + item.dependantlname}</span>
                                                        <small className={item.administrativenotificationstatus ? "text-success" : "text-danger"}>{item.administrativenotificationstatus ? 'Active' : 'Inactive'}</small>
                                                    </td>
                                                    <td className="text-capitalize">
                                                        {
                                                            item.compref !== '' ?
                                                                <>{item.commpref.replaceAll('&', ',').replaceAll(' ', '').toLowerCase()}</> : '-'
                                                        }
                                                    </td>
                                                    <td>
                                                        <div>{item.phonenumber?(CONSTANT_LABELS.PLUS_ONE+removeCountryCode(item.phonenumber)):''}</div>
                                                        <div>{item.emailaddress}</div>
                                                    </td>
                                                    {/*  <td>
                                                        {item.lastvisitdate ? item.lastvisitdate : '-'}
                                                    </td> */}
                                                    <td >
                                                        {item.administrativenotificationstatus ? 'Active' : 'Inactive'}
                                                    </td>
                                                    {/* <td className="text-center">
                        {item.appointmenttype ? 'Hygiene' : 'Hygiene'}
                      </td>
                      <td className="text-center">
                        {item.healthratings}
                      </td>
                      <td className="text-center">
                        {item.financialratings}
                      </td> */}
                                                    <td>
                                                        <button className={item.administrativenotificationstatus ? 'btn btn-link' : 'btn btn-link text-muted'} onClick={() => { updatepatientstatus(item) }}>{item.administrativenotificationstatus ? 'Disable' : 'Enable'}</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    hideDisabled
                                    activePage={page}
                                    itemsCountPerPage={results}
                                    totalItemsCount={Number(totalCount)}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    }
                    {
                        (isLoaded === false && patientListState.length === 0) &&
                        <label>No patient list</label>
                    }
                </div>
            </div>
        </section>
    )
}