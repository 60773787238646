import firebase from 'firebase';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IAppState } from '../../../../store';
import configs from '../../../configs/apiConfigs';
import { USER_TYPE } from '../../../constants/constants';
import firebaseInstance from '../../../helpers/firebase';
import { getProviderByName } from '../../../services/providerChat';
import { Messages } from './Messages';
import { ProvidersList } from './ProvidersList';
import _ from "lodash";

const db = firebase.firestore();

export const ProvidersChat = () => {
    const history = useHistory();
    const [providerName, setProviderName] = useState('');
    const [providerList, setProviderList]: any = useState([]);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const [selectedProvider, setSelectedProvider]: any = useState(null);
    const [dependantCount, setDependantCount] = useState(0);
    const [providerCount, setProviderCount] = useState(0);
    const [groupCount, setGroupCount] = useState(0);

    useEffect(() => {
        getProviderFromFirestore();
        getPendingCount();
    }, []);

    const getPendingCount = async () => {
        // const count = await firebaseInstance.getPendingMessageCount(userDataResponse.clinics[0].uniqueId);
        firebaseInstance.db.collection(configs.CONVERSATION_DEV)
            .where('clinicId', '==', Number(userData.clinics[0].uniqueId))
            .where('metadata.viewByClinicAt', '==', null)
            .onSnapshot(async (querySnapshot: any) => {
                let docs = querySnapshot.docs.map((doc:any) => ({...doc.data(), id: doc.id}));
                docs = _.uniqBy(docs, 'dependentUniqueId');
                setDependantCount(docs.length);
            });
        let clinicDoc = await firebaseInstance.db.collection(configs.USER_COLLECTION).where('uniqueId', '==', userData.clinics[0].uniqueId).get();
        let clinicFirebaseId = clinicDoc.docs.map((doc: any) => ({ ...doc.data, id: doc.id }))[0]?.id;
        if (clinicFirebaseId) {
            firebaseInstance.db.collection(configs.GROUP_COLLECTION)
                .where('isClosed', '==', false)
                .where('type', '==', 1)
                .where('members', 'array-contains', clinicFirebaseId)
                .orderBy('modifiedAt').onSnapshot(async (snapshot: any) => {
                    const providers = snapshot.docs.map((item: any) => ({ ...item.data(), id: item.id }));
                    let totalCounter = 0;
                    for (const provider of providers) {
                        let clinicDoc = firebaseInstance.db.collection(configs.MESSAGE_COLLECTION);
                        const chatRefdoc = clinicDoc.doc(provider.id);
                        const snapshot = await chatRefdoc.get();
                        const data = snapshot.data();
                        if (data && data.messages) {
                            const messages = data.messages;
                            const counter = messages.filter((item:any) => item.readBy.find((item:any) => {return item.seenBy === clinicFirebaseId}) ===  undefined);
                            if (counter.length > 0) {
                                totalCounter += 1;
                            }
                        }
                    }
                    setProviderCount(totalCounter);
                });
        }

        if (clinicFirebaseId) {
            firebaseInstance.db.collection(configs.GROUP_COLLECTION)
                .where('isClosed', '==', false)
                .where('type', '==', 0)
                .where('members', 'array-contains', clinicFirebaseId)
                .orderBy('modifiedAt').onSnapshot(async (snapshot: any) => {
                    const providers = snapshot.docs.map((item: any) => ({ ...item.data(), id: item.id }));
                    let totalCounter = 0;
                    for (const provider of providers) {
                        let clinicDoc = firebaseInstance.db.collection(configs.MESSAGE_COLLECTION);
                        const chatRefdoc = clinicDoc.doc(provider.id);
                        const snapshot = await chatRefdoc.get();
                        const data = snapshot.data();
                        if (data && data.messages) {
                            const messages = data.messages;
                            const counter = messages.filter((item:any) => item.readBy.find((item:any) => {return item.seenBy === clinicFirebaseId}) ===  undefined);
                            if (counter.length > 0) {
                                totalCounter += 1;
                            }
                        }
                    }
                    setGroupCount(totalCounter);
                });
        }
    }

    const getProviderFromFirestore = async () => {
        const clinicUniqueId = userData?.clinics[0]?.uniqueId;
        const clinicDoc = await db.collection(configs.USER_COLLECTION).where('uniqueId', '==', clinicUniqueId).where('userType', '==', USER_TYPE.CLINIC).get();
        let id = null;
        if (clinicDoc.docs.map((item: any) => { return { id: item.id, ...item.data() } })[0]) {
            id = clinicDoc.docs.map((item: any) => { return { id: item.id, ...item.data() } })[0].id
        }
        if (id) {
            let clinicDocRef = db.collection(configs.USER_COLLECTION).doc(id);
            const clinicData = await clinicDocRef.get();
            db.collection(configs.GROUP_COLLECTION).where('isClosed', '==', false)
                .where('type', '==', 1)
                .where('members', 'array-contains', clinicData.id)
                .orderBy("modifiedAt")
                .onSnapshot((snapshot) => {
                    const array = snapshot.docs.map(async (doc) => {
                        const data = doc.data();
                        const id = doc.id;
                        let providerId = data.members.filter((item: any) => item !== clinicData.id)[0];
                        let providerDoc = await db.collection(configs.USER_COLLECTION).doc(providerId).get();
                        const providerData: any = providerDoc.data();
                        return {
                            groupData: { ...data, id: doc.id },
                            fullName: providerData.fullName,
                            uniqueId: providerData.uniqueId,
                            email: providerData.email,
                            id: id
                        };
                    });
                    Promise.all(array).then((success) => {
                        setProviderList(success.reverse());
                    });
                });
        }
    }

    const getProviderList = (searchText: any) => {
        const clinicUniqueId = userData?.clinics[0]?.uniqueId;
        getProviderByName(searchText, clinicUniqueId).then((success) => {
            if (success.data) {
                setProviderList(success.data.map((item: any) => {
                    return {
                        uniqueId: item.uniqueid,
                        fullName: item.fullname,
                        email: item.email
                    }
                }));
            }
            console.log(success);
        }).catch((err) => {
            console.log(err);
        });
    }

    const onClickProvider = async (data: any) => {
        // find user and create group or create user and create group;
        let providerDocRef = null;
        let clinicDocRef = null;
        const clinicUniqueId = userData?.clinics[0]?.uniqueId;
        const clinic = userData?.clinics[0];
        const providerDoc = await db.collection(configs.USER_COLLECTION).where('uniqueId', '==', data.uniqueId).where('userType', '==', USER_TYPE.PROVIDER).get();
        const clinicDoc = await db.collection(configs.USER_COLLECTION).where('uniqueId', '==', clinicUniqueId).where('userType', '==', USER_TYPE.CLINIC).get();

        /* Provider */
        if (providerDoc.size === 0) {
            providerDocRef = await db.collection(configs.USER_COLLECTION).add({
                fullName: data.fullName,
                email: data.email,
                phone: data.phone,
                groups: [],
                uniqueId: data.uniqueId,
                userType: USER_TYPE.PROVIDER,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        } else {
            const id = providerDoc.docs.map((item: any) => { return { id: item.id, ...item.data() } })[0].id;
            providerDocRef = db.collection(configs.USER_COLLECTION).doc(id);
        }

        /* Clinic */
        if (clinicDoc.size === 0) {
            clinicDocRef = await db.collection(configs.USER_COLLECTION).add({
                fullName: clinic.name,
                email: clinic.email,
                groups: [],
                uniqueId: clinic.uniqueId,
                userType: USER_TYPE.CLINIC,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        } else {
            const id = clinicDoc.docs.map((item: any) => { return { id: item.id, ...item.data() } })[0].id;
            clinicDocRef = db.collection(configs.USER_COLLECTION).doc(id);
        }

        const clinicData = await clinicDocRef.get();
        const providerData = await providerDocRef.get();

        console.log("providerData", providerData.id)
        let groupDoc = await db.collection(configs.GROUP_COLLECTION)
            .where('members', 'in', [[providerData.id, clinicData.id], [clinicData.id, providerData.id]])
            .where('type', '==', 1)
            .where('isClosed', '==', false).get();

        if (groupDoc.size === 0) {
            const clinicData = await clinicDocRef.get();
            const providerData = await providerDocRef.get();

            const groupDocRef = await db.collection(configs.GROUP_COLLECTION).add({
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                createdBy: clinicData.id,
                members: [clinicData.id, providerData.id],
                modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
                modifiedBy: clinicData.id,
                name: data.fullName,
                recentMessage: {
                    message: "",
                    sentAt: null
                },
                isClosed: false,
                type: 1
            });
            const groupData = await groupDocRef.get();
            console.log(groupData);
            await db.collection(configs.MESSAGE_COLLECTION).doc(groupData.id).set({
                messages: []
            });
            clinicDocRef.update({
                "groups": firebase.firestore.FieldValue.arrayUnion(groupData.id)
            });
            providerDocRef.update({
                "groups": firebase.firestore.FieldValue.arrayUnion(groupData.id)
            });
            setSelectedProvider({
                group: { ...groupData.data(), id: groupData.id },
                provider: { ...providerData.data(), id: providerData.id },
                clinic: { ...clinicData.data(), id: clinicData.id }
            });
        } else {
            const clinicData = await clinicDocRef.get();
            const providerData = await providerDocRef.get();
            const groupdata = groupDoc.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })).filter((item: any) => {
                if (item.members.includes(clinicData.id)) {
                    return true;
                }
                return false;
            })[0];

            if (groupdata) {
                setSelectedProvider({
                    group: groupdata,
                    provider: { ...providerData.data(), id: providerData.id },
                    clinic: { ...clinicData.data(), id: clinicData.id }
                });
            }
        }
    }

    return (
        <main className="chat_params">
            <div className="container-fluid p-0">
                <div className="card border-0">
                    <div className="row g-0">
                        <div className="col-3 border-end chatlist-panel" id="scroll">
                            <div className="chat-tab-links">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <button className="nav-link" type="button" onClick={() => {
                                            history.push('/chats/patient');
                                        }}>
                                            Patient
                                            {
                                                dependantCount !== 0 &&
                                                <span className="badge rounded-pill bg-primary align-self-center">{dependantCount}</span>
                                            }
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link active" type="button">
                                            Provider
                                            {
                                                providerCount !== 0 &&
                                                <span className="badge rounded-pill bg-primary align-self-center">{providerCount}</span>
                                            }
                                        </button>
                                    </li>
                                    <li className={"nav-item " + (configs.SOURCE_URL !== "https://www.xcarepro.com/" ? "" : "d-none")}>
                                        <button className="nav-link" type="button" onClick={() => {
                                            history.push('/chats/group');
                                        }}>
                                            Group
                                            {
                                                groupCount !== 0 &&
                                                <span className="badge rounded-pill bg-primary align-self-center">{groupCount}</span>
                                            }
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="p-3">
                                <div className="d-flex align-items-center">
                                    {/* Search provider UI */}
                                    <div className="flex-grow-1">
                                        <input type="text" className="form-control" placeholder="Search provider..." value={providerName}
                                            onChange={(e: any) => {
                                                if (e.target.value) {
                                                    setProviderName(e.target.value);
                                                    if (e.target.value.length > 1) {
                                                        getProviderList(e.target.value);
                                                    }
                                                } else if (e.target.value === '') {
                                                    setProviderName(e.target.value);
                                                    setProviderList([]);
                                                }
                                            }} />
                                    </div>
                                </div>
                            </div>
                            {
                                (false) &&
                                <span className="spinner-border spinner-border-sm" role="status"></span>
                            }
                            <ProvidersList
                                providerList={providerList}
                                onClickProvider={onClickProvider}
                                selectedProvider={selectedProvider}
                            ></ProvidersList>
                        </div>
                        <Messages selectedProvider={selectedProvider}></Messages>
                    </div>
                </div>
            </div>
        </main>
    )
}