import { fetchUserLogoUploadSuccess, fetchUserLogoUploadError, fetchUserNameChangeSuccess, fetchUserNameChangeError, emailVerificationErrorAction, emailVerificationSuccessAction } from './../store/userData/actions';
import { UserLogoUploadRequest, UploadUserLogoPendingAction, UserNameChangeRequest, UserNameChangingPendingAction, EmailVerificationRequest, EmailVerificationPendingAction } from './../store/userData/types';
import { takeEvery, put } from "redux-saga/effects";
import axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import { USER_DATA, FetchUserDataPendingAction, UserDataRequest } from "../store/userData/types";
import { fetchUserDataError, fetchUserDataSuccess } from "../store/userData/actions";
import { attachHeader } from "../utils/apiHandler";
import { fetchLoadingPending, fetchLoadingSuccess } from "../store/loadingIndicator/actions";

function execute(userDataRequest: UserDataRequest) {
    // return axios.post(CONFIG.API_URL + "userdata/", userDataRequest, { headers: attachHeader(true) }).then(response => ({ response }), error => ({ error }));
    return axios.get(CONFIG.API_URL + "userdata/", { headers: attachHeader(true), withCredentials: true }).then(response => ({ response }), error => ({ error }));
}
function* userDataPendingRun(action: FetchUserDataPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200) {
            yield put(fetchUserDataSuccess({ ...response.data, donotAffect: action.payload.donotAffect ? true : false }));
        } else {
            yield put(fetchUserDataError(response))
        }
    }
    if (error) {
        yield put(fetchUserDataError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export default function* userDataPendingWatcher() {
    yield takeEvery(USER_DATA.LOAD_PENDING, userDataPendingRun);
}

function executeUploadPhoto(request: UserLogoUploadRequest) {
    const formData = new FormData();
    formData.append('type', request.type);
    formData.append('uphoto', request.uphoto);
    return axios.post(CONFIG.API_URL + request.userType + "/" + request.uniqueId + "/upload/logo/", formData, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* userUploadPhotoPendingRun(action: UploadUserLogoPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeUploadPhoto(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(fetchUserLogoUploadError(response.data));
            } else {
                yield put(fetchUserLogoUploadSuccess({ ...response.data, userType: action.payload.userType, uniqueId: action.payload.uniqueId }));
            }
        } else {
            yield put(fetchUserLogoUploadError(response));
        }
    }
    if (error) {
        yield put(fetchUserLogoUploadError(error));
    }
    yield put(fetchLoadingSuccess(false));
}

export function* userUploadPhotoPendingWatcher() {
    yield takeEvery(USER_DATA.CHANGE_IMAGE_PENDING, userUploadPhotoPendingRun);
}

function executeNameChange(request: UserNameChangeRequest) {
    const body = {
        type: 'name',
        name: request.name
    };
    return axios.post(CONFIG.API_URL + request.userType + "/" + request.uniqueId + "/", body, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* userNameChangePendingRun(action: UserNameChangingPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeNameChange(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.status.error === true) {
                yield put(fetchUserNameChangeError(response.data));
            } else {
                yield put(fetchUserNameChangeSuccess({ ...response.data, name: action.payload.name, userType: action.payload.userType, uniqueId: action.payload.uniqueId }));
            }
        } else {
            yield put(fetchUserNameChangeError(response))
        }
    }
    if (error) {
        yield put(fetchUserNameChangeError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* userNameChangePendingWatcher() {
    yield takeEvery(USER_DATA.CHANGE_NAME_PENDING, userNameChangePendingRun);
}
function executeEmailVerification(request: EmailVerificationRequest) {
    const body = {
        email: request.email
    };
    return axios.post(CONFIG.API_URL + 'verify/' + request.modelType + "/" + request.modelUniqueid + "/", body, {
        headers: attachHeader(true, true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
export function* emailVerificationRun(action: EmailVerificationPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeEmailVerification(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(emailVerificationErrorAction(response.data));
            } else {
                yield put(emailVerificationSuccessAction(response.data));
            }
        } else {
            yield put(emailVerificationErrorAction(response));
        }
    }
    if (error) {
        yield put(emailVerificationErrorAction(error));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* emailVerificationPendingWatcherUserData() {
    yield takeEvery(USER_DATA.EMAIL_VERIFICATION_PENDING, emailVerificationRun);
}
