import React, { useState, useEffect,useMemo } from 'react';
import { Redirect, NavLink, useHistory, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { deleteEmailTemplate, getEmailTemplateList, updateEmailTemplateStatus } from '../../services/messageCenter';
import { confirmAlert } from 'react-confirm-alert';
import NotificationIcon from '../../assets/images/notification-img.svg';
export const EmailTemplates = () => {
  const history = useHistory();
  const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchList();
  }, []);
  const fetchList = () => {
    dispatch(fetchLoadingPending());
    getEmailTemplateList({ clinicid: userData.clinics[0].id }).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      if (success && success.error === false) {
        setList(success.data);
      }
    }).catch((err) => {
      dispatch(fetchLoadingSuccess(false));
      console.log(err);
    })
  }
  
  const updateStatus = (item: any) => {
    confirmAlert({
      title: '',
      message: 'Please confirm, if you want to ' + (item.isactive !== true ? 'enable' : 'disable') + ' this configuration.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            updateEmailTemplateStatus({
              clinicid: Number(userData.clinics[0].id),
              emailtemplate_id: item.messagecenteremailtemplate_id,
              isactive: !item.isactive
            }).then((success) => {
              if (success && success.error === false) {
                fetchList();
              }
            }).catch((err) => {
            })
          }
        }
        ,
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });
  }
  const deleteTemplateFunc = (item: any) => {
    confirmAlert({
      title: '',
      message: 'Are you sure you want to delete ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(fetchLoadingPending());
            deleteEmailTemplate({
              clinicid: Number(userData.clinics[0].id)
            },item.messagecenteremailtemplate_id).then((success) => {
              dispatch(fetchLoadingSuccess(false));
              if (success && success.error === false) {
                fetchList();
              }
            }).catch((err) => {
              dispatch(fetchLoadingSuccess(false));
            })
          }
        }
        ,
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });
  }
  const onRowClick = (item: any) => {
    setSelectedTemplate(item);
    history.push({ pathname: '/message-center/emaileditor', state: item });
  }
  return (
    <section className="all-campaign p-4">
      <div className="row mx-0">
        <div className="col-12">
          <div className="d-flex justify-content-between">
            <div>
              {/* <div className="campaning-title">Templates</div>
              <div className="campaning-desc">Template configuration</div> */}
            </div>
            <div className="stp-action align-self-center">
              <button className="btn btn-outline-primary" onClick={() => { history.push({ pathname: '/message-center/emaileditor' }) }}>Create Template</button>
            </div>
          </div>
        </div>
        <div className="col-12">
          {
            useMemo(() => {
              return (
                list.map((item: any) => {
                  return (
                    <div className="my-4 border px-4 py-3 border-1 rounded d-flex custom-noti-box justify-content-between"
                      key={item.messagecenteremailtemplate_id} >
                      <div className="d-flex">
                        <div className="noti-title w-100 d-flex" >
                          <img src={NotificationIcon} className="me-2" />
                          <span className="fw-bold align-self-center ">{item.templatename}</span>
                        </div>
                        <div className="form-check form-switch d-inline-block align-self-center mx-2">
                      <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={item.isactive} onChange={(e) => { updateStatus(item); }} />
                    </div>
                      </div>
                      <div className="noti-edit d-flex align-self-center cursor-pointer btn-link">
                        <div onClick={() => { onRowClick(item) }}>Edit</div>
                        <button className="btn p-0 btn-link me-2 text-secondary" onClick={() => { deleteTemplateFunc(item) }}><i className="ms-2 bi bi-x-circle-fill text-danger"></i></button>
                      </div>
                    </div>
                  )
                })
              )
            }, [list])
          }
        </div>
      </div>
    </section>
  )
}
