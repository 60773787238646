import { MessageCenterState, MessageCenterActionTypes, MESSAGE_CENTER } from "./types";

export const initialState: MessageCenterState = {
  error: null,
  pending: false
};

export const messageCenterReducer = (state = initialState, action: MessageCenterActionTypes): MessageCenterState => {
  switch (action.type) {
    case MESSAGE_CENTER.MESSAGE_CENTER_RECARE:
      return {
        ...state,
        pending: true,
        error: null,
        messageCenterRecareRequest: action.payload,
        messageCenterRecareResponse: undefined
      };
    case MESSAGE_CENTER.MESSAGE_CENTER_RECARE_FAILED:
      return {
        ...state,
        pending: false,
        error: action.payload,
        messageCenterRecareRequest: undefined,
        messageCenterRecareResponse: undefined
      };
    case MESSAGE_CENTER.MESSAGE_CENTER_RECARE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        messageCenterRecareRequest: undefined,
        messageCenterRecareResponse: action.payload
      };
    case MESSAGE_CENTER.MESSAGE_CENTER_RECALL:
      return {
        ...state,
        pending: true,
        error: null,
        messageCenterRecallRequest: action.payload,
        messageCenterRecallResponse: undefined
      };
    case MESSAGE_CENTER.MESSAGE_CENTER_RECALL_FAILED:
      return {
        ...state,
        pending: false,
        error: action.payload,
        messageCenterRecallRequest: undefined,
        messageCenterRecallResponse: undefined
      };
    case MESSAGE_CENTER.MESSAGE_CENTER_RECALL_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        messageCenterRecallRequest: undefined,
        messageCenterRecallResponse: action.payload
      };
    default:
      return state;
  }
};