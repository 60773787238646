import {
    FORGOT_PASSWORD,
    ForgotPasswordState,
    ForgotPasswordActionTypes
} from './types';

const initialState: ForgotPasswordState = {
    pending: false,
    forgotPassword: { email: '', modelType: '' },
    forgotPasswordResponse: { status: { error: false, msg: '' } },
    error: null
};

export const forgotPasswordReducer = (state = initialState, action: ForgotPasswordActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case FORGOT_PASSWORD.LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case FORGOT_PASSWORD.LOAD_PENDING:
            return {
                ...state,
                pending: true,
                forgotPassword: action.payload
            }
        case FORGOT_PASSWORD.LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                forgotPasswordResponse: action.forgotPassword
            }
        default:
            return NewState;

    }
};