import configs from "../configs/apiConfigs"
import { CommonApiOptions, METHOD_TYPE } from "../constants/api"
import { commonApi } from "../helpers/api"
import { attachHeader } from "../utils/apiHandler"

export const fetchUserData = () => {
  const request: CommonApiOptions = {
    url: configs.API_URL + "userdata/",
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  }
  return commonApi(request);
}