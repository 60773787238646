import React from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store";
import Chat from '../../../components/clinic/appointment/Chat';
import firebase from "firebase";
import configs from "../../configs/apiConfigs";
import { CHAT } from "../../../constants";
import { getAppointmentDetail } from "../../services/appointment";
import { removeChatPin, storeChatData } from "../../../store/chat/action";
import moment from "moment";
import { useState } from "react";
import { GroupPin } from "../grouppin/GroupPin";
let db = firebase.firestore();
let listener: any;
let listenMessage: any;
let groupListener: any;
export const ChatPinList = () => {
    const pinList = useSelector((state: IAppState) => state.chat.data, shallowEqual);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const dispatch = useDispatch();
    const [chatPinList, setChatPinList] = useState([]);
    const [startListening, setStartListening] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState('');
    useEffect(() => {
        setChatPinList(pinList);
    }, [pinList]);
    useEffect(() => {
        if (startListening) {
            startWatchingRealtimeChecking();
            startWatchingMessages();
        }
    }, [startListening]);
    useEffect(() => {
        if (userData && userData.clinics[0] && userData.clinics[0].uniqueId) {
            getSetGroups();
            db.collection(configs.CHAT_COLLECTION)
                .where("actionType", "==", CHAT.ACTION_TYPE.CHECKIN)
                .where("sendToCId", "==", userData.clinics[0].uniqueId).get().then(async (snapshot) => {
                    let mapped: any = snapshot.docs.map((doc) => {
                        return { ...doc.data(), id: doc.id }
                    });
                    mapped = mapped.filter((item: any) => {
                        if (item.metadata && item.metadata.viewByClinicAt !== null) {
                            return false;
                        } else {
                            return true;
                        }
                    });
                    for (let doc of mapped) {
                        try {
                            const response = await getAppointmentDetail(doc.sendToCId, doc.appointmentId);
                            if (response) {
                                if (response.currentStatus !== "completed" && response.currentStatus !== "cancelled" &&
                                    moment().isSameOrAfter(moment(response.date + " " + response.time))) {
                                    dispatch(storeChatData({
                                        ...response,
                                        appointmentId: response.uniqueid,
                                        sendToCId: response.clinic.uniqueid,
                                        pinType: "checkin",
                                        openPin: false,
                                        fetched: true,
                                        _id: doc.id
                                    }));
                                }
                            }
                        } catch (err) {
                            console.log('Error in pin listing')
                        }
                    }
                    if (listener) listener();
                    if (listenMessage) listenMessage();
                    setStartListening(true);
                    // startWatchingRealtimeChecking();
                })
        }
        return () => {
            if (listener) listener();
        }
    }, []);

    const getSetGroups = () => {
        const clinicUniqueId = userData.clinics[0].uniqueId;
        groupListener = db.collection(configs.USER_COLLECTION).where('uniqueId', '==', clinicUniqueId).get().then((snapshot) => {
            const user = snapshot.docs.map((item) => { return { id: item.id, ...item.data() } });
            const userDocId = user[0]?.id;
            if (userDocId) {
                db.collection(configs.GROUP_COLLECTION).where('isClosed', '==', false)
                    .where('members', 'array-contains', userDocId).onSnapshot((snapshot) => {
                        snapshot.docChanges().forEach((change) => {
                            if (change.type === "added") {
                                const data = change.doc.data();
                                if (!data.type) {
                                    Object.assign(data, { groupId: change.doc.id });
                                    const allowToAdd = chatPinList.find((pin: any) => { return (Number(pin.appointmentId) === Number(data.appointmentId) && pin.pinType === 'group') });
                                    if (!allowToAdd) {
                                        getAppointmentDetail(clinicUniqueId, data.appointmentUniqueId).then((response) => {
                                            if (response) {
                                                dispatch(storeChatData({
                                                    ...response,
                                                    appointmentId: "g" + response.uniqueid,
                                                    sendToCId: response.clinic.uniqueid,
                                                    pinType: "group",
                                                    openPin: false,
                                                    fetched: true,
                                                    ...data
                                                }));
                                            }
                                        }).catch((err) => { });
                                    }
                                }
                            }
                        })
                    });
                /* Listen for group chat reject by provider */
                db.collection(configs.GROUP_COLLECTION).where('isClosed', '==', true).where('requestAcceptedByProvider.accepted', '==', false).onSnapshot((snapshot) => {
                    console.log('---Provider group reject---', snapshot.docs)
                    const data = snapshot.docs.map((doc) => {
                        console.log("---Reject Data---", { ...doc.data(), id: doc.id });
                        dispatch(removeChatPin("g" + doc.data().appointmentUniqueId));
                    });
                });
            }
        });
    }

    const handleCloseChat = (Id: any) => {
        pinList.map((item: any) => {
            if (item.appointmentId === Id) {
                $('.prime' + Id).toggleClass('zmdi-comment-outline');
                $('.prime' + Id).toggleClass('zmdi-close');
                $('.prime' + Id).toggleClass('is-active');
                $('.prime' + Id).toggleClass('is-visible');
                $('#prime' + Id).toggleClass('is-float');
                $('.chat' + Id).toggleClass('is-visible');
                $('.fab' + Id).toggleClass('is-visible');
                $('#chatSend' + Id).focus();
            } else {
                $('.prime' + item.appointmentId).addClass('zmdi-comment-outline');
                $('.prime' + item.appointmentId).removeClass('zmdi-close');
                $('.prime' + item.appointmentId).removeClass('is-active');
                $('.prime' + item.appointmentId).removeClass('is-visible');
                $('#prime' + item.appointmentId).removeClass('is-float');
                $('.chat' + item.appointmentId).removeClass('is-visible');
                $('.fab' + item.appointmentId).removeClass('is-visible');
            }
        })
    }
    const startWatchingRealtimeChecking = () => {
        // console.log(chatPinList);
        listener = db.collection(configs.CHAT_COLLECTION)
            .where("actionType", "==", CHAT.ACTION_TYPE.CHECKIN)
            .where("sendToCId", "==", userData.clinics[0].uniqueId)
            .onSnapshot((querySnapshot) => {
                // console.log(querySnapshot.docs);
                querySnapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        // console.log(change.type, change.doc.data());
                        const data = change.doc.data();
                        if (data.metadata && data.metadata.viewByClinicAt !== null) {
                            return;
                        }
                        const allowToAdd = chatPinList.find((pin: any) => { return (Number(pin.appointmentId) === Number(data.appointmentId) && pin.pinType !== 'group') });
                        // console.log(allowToAdd);
                        if (!allowToAdd) {
                            getAppointmentDetail(data.sendToCId, data.appointmentId).then((response) => {
                                if (response) {
                                    if (response.currentStatus !== "completed" && response.currentStatus !== "cancelled") {
                                        dispatch(storeChatData({
                                            ...response,
                                            appointmentId: response.uniqueid,
                                            sendToCId: response.clinic.uniqueid,
                                            pinType: "checkin",
                                            openPin: false,
                                            fetched: true,
                                            _id: change.doc.id
                                        }));
                                    }
                                }
                            }).catch((err) => { });
                        }
                    } else if (change.type === "modified") {
                    }
                });
            })
    }
    const startWatchingMessages = () => {
        listenMessage = db.collection(configs.CHAT_COLLECTION)
            .where('actionType', '==', CHAT.ACTION_TYPE.MESSAGE)
            .where('sendToCId', '==', userData.clinics[0].uniqueId)
            // .where("metadata.viewByClinicAt", "==", null)
            .onSnapshot((snapshot: any) => {
                // console.log(snapshot.docs)
                snapshot.docChanges().forEach((change: any) => {
                    // console.log(change.type, change.doc.data());
                    if (change.type === "added") {
                        if (change.doc.data() && change.doc.data().actionType === CHAT.ACTION_TYPE.MESSAGE && !change.doc.data()?.metadata?.viewByClinicAt) {
                            const data = change.doc.data()
                            const docRef = db.collection(configs.CHAT_COLLECTION).doc(change.doc.id);
                            docRef.update({
                                "metadata.viewByClinicAt": firebase.firestore.FieldValue.serverTimestamp(),
                            });
                            if (!chatPinList.find((item: any) => item.appointmentId === data.appointmentId) && data?.sender !== "user" 
                                && userData?.clinics[0]?.clinicSetting?.appointmentChatPin) {
                                getAppointmentDetail(data.sendToCId, data.appointmentId).then((response) => {
                                    if (response) {
                                        dispatch(storeChatData({
                                            ...response,
                                            appointmentId: response.uniqueid,
                                            sendToCId: response.clinic.uniqueid,
                                            pinType: "chat",
                                            openPin: true,
                                            fetched: true
                                        }));
                                    }
                                }).catch((err) => { });
                            }
                        }
                    } else if (change.type === "modified") {
                    }
                });
            })
    }
    return (
        <>
            {
                pinList.map((item: any, index: number) => {
                    if (item.pinType === "group") {
                        return (
                            <React.Fragment key={"chat" + item.appointmentId}>
                                <GroupPin selectedAppointment={selectedAppointment} data={item} handleCloseChat={handleCloseChat}></GroupPin>
                            </React.Fragment>
                        )
                    }
                    return (
                        <React.Fragment key={"chat" + item.appointmentId} >
                            <Chat handleAppointment={(Id: any) => { setSelectedAppointment(Id) }} chatProps={item} handleCloseChat={(id: any) => { handleCloseChat(id) }} />
                        </React.Fragment>)
                })
            }
        </>
    )
}