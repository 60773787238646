import React, { useEffect, useState } from 'react';
import { PracticeApp1 } from './Practice-App-1';
import { PracticeApp2 } from './Practice-App-2';
import { PracticeApp3 } from './Practice-App-3';
import { PracticeApp4 } from './Practice-App-4';
import { PracticeApp5 } from './Practice-App-5';
import { PracticeApp6 } from './Practice-App-6';
import { PracticeApp7 } from './Practice-App-7';
import { PracticeApp8 } from './Practice-App-8';
import { PracticeApp9 } from './Practice-App-9';
import { PracticeApp10 } from './Practice-App-10';
import { ProgressBar } from './ProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IAppState } from '../../../store';
import { fetchUserDataPending, fetchUserDataSuccess } from '../../../store/userData/actions';
import configs from '../../configs/apiConfigs';
import './_practiceSteps.scss';
import { fetchUserData } from '../../services/main';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { AppointmentTypeHourMap } from './AppointmentTypeHourMap';
import { createBasicPlan } from '../../services/subscription';
import { toast } from 'react-toastify';

export const OnboardClinic = (props: any) => {
  const [step, setStep] = useState(0);
  const [request, setRequest]: any = useState({});
  const [percentage, setPercentage] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  let userDataSuccess = useSelector((state: IAppState) => state.userData.userDataResponse);

  useEffect(() => {
    if (location.state && location.state.step === "1") {
      setStep(1);
      setRequest({
        practiceType: userDataSuccess.clinics[0].domain,
        clinicUniqueId: userDataSuccess.clinics[0].uniqueId
      });
      setPercentage(10);
    } else if (location.state && location.state.step === "2") {
      setStep(2);
      setRequest({
        practiceType: userDataSuccess.clinics[0].domain,
        clinicUniqueId: userDataSuccess.clinics[0].uniqueId,
        generalInfo: {
          practicePhoto: userDataSuccess.clinics[0].logo ? configs.IMAGE_URL + "clinic/" + userDataSuccess.clinics[0].logo.name + "-200x200." + userDataSuccess.clinics[0].logo.type : configs.DEFAULT_IMAGE_URL_CLINIC,
          //practicePhoto: userDataSuccess.clinics[0].logo ? configs.IMAGE_URL + "clinic/" + userDataSuccess.clinics[0].logo.name + "-200x200." + userDataSuccess.clinics[0].logo.type : '',
          practiceName: userDataSuccess.clinics[0].name,
          phoneNumber: userDataSuccess.clinics[0].phone,
          email: userDataSuccess.clinics[0].email,
          website: userDataSuccess.clinics[0].website
        }
      });
      setPercentage(20);
    } else if (location.state && location.state.step === "3") {
      setStep(3);
      setRequest({
        practiceType: null,
        clinicUniqueId: userDataSuccess.clinics[0].uniqueId,
        generalInfo: {
          practicePhoto: userDataSuccess.clinics[0].logo ? configs.IMAGE_URL + "clinic/" + userDataSuccess.clinics[0].logo.name + "-200x200." + userDataSuccess.clinics[0].logo.type : configs.DEFAULT_IMAGE_URL_CLINIC,
          practiceName: userDataSuccess.clinics[0].name,
          phoneNumber: userDataSuccess.clinics[0].phone,
          email: userDataSuccess.clinics[0].email,
          website: userDataSuccess.clinics[0].website
        },
        location: {
          address: userDataSuccess.clinics[0].address,
          textAddress: userDataSuccess.clinics[0].address.formattedAddress
        }
      });
      setPercentage(30);
    } else if (location.state && location.state.step === "4") {
      setStep(4);
      setRequest({
        practiceType: null,
        clinicUniqueId: userDataSuccess.clinics[0].uniqueId,
        generalInfo: {
          practicePhoto: userDataSuccess.clinics[0].logo ? configs.IMAGE_URL + "clinic/" + userDataSuccess.clinics[0].logo.name + "-200x200." + userDataSuccess.clinics[0].logo.type : configs.DEFAULT_IMAGE_URL_CLINIC,
          practiceName: userDataSuccess.clinics[0].name,
          phoneNumber: userDataSuccess.clinics[0].phone,
          email: userDataSuccess.clinics[0].email,
          website: userDataSuccess.clinics[0].website
        },
        location: {
          address: userDataSuccess.clinics[0].address,
          textAddress: userDataSuccess.clinics[0].address.formattedAddress
        },
        hours: userDataSuccess.clinics[0].hours
      });
      setPercentage(40);
    } else if (location.state && location.state.step === "5") {
      setStep(5);
      setRequest({
        practiceType: null,
        clinicUniqueId: userDataSuccess.clinics[0].uniqueId,
        generalInfo: {
          practicePhoto: userDataSuccess.clinics[0].logo ? configs.IMAGE_URL + "clinic/" + userDataSuccess.clinics[0].logo.name + "-200x200." + userDataSuccess.clinics[0].logo.type : configs.DEFAULT_IMAGE_URL_CLINIC,
          practiceName: userDataSuccess.clinics[0].name,
          phoneNumber: userDataSuccess.clinics[0].phone,
          email: userDataSuccess.clinics[0].email,
          website: userDataSuccess.clinics[0].website
        },
        location: {
          address: userDataSuccess.clinics[0].address,
          textAddress: userDataSuccess.clinics[0].address.formattedAddress
        },
        hours: userDataSuccess.clinics[0].hours,
        questions: {
          practiceDescription: userDataSuccess.clinics[0].description,
          questions: userDataSuccess.clinics[0].questionResponse ? userDataSuccess.clinics[0].questionResponse : [],
          seoTitle: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaTitle : '',
          metaDescription: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaDesc : '',
          targetKeywords: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaKeywords.split(",").map((item: any) => ({ value: item, label: item })) : []
        }
      });
      setPercentage(50);
    } else if (location.state && location.state.step === "6") {
      setStep(6);
      setRequest({
        practiceType: null,
        clinicUniqueId: userDataSuccess.clinics[0].uniqueId,
        generalInfo: {
          practicePhoto: userDataSuccess.clinics[0].logo ? configs.IMAGE_URL + "clinic/" + userDataSuccess.clinics[0].logo.name + "-200x200." + userDataSuccess.clinics[0].logo.type : configs.DEFAULT_IMAGE_URL_CLINIC,
          practiceName: userDataSuccess.clinics[0].name,
          phoneNumber: userDataSuccess.clinics[0].phone,
          email: userDataSuccess.clinics[0].email,
          website: userDataSuccess.clinics[0].website
        },
        location: {
          address: userDataSuccess.clinics[0].address,
          textAddress: userDataSuccess.clinics[0].address.formattedAddress
        },
        hours: userDataSuccess.clinics[0].hours,
        questions: {
          practiceDescription: userDataSuccess.clinics[0].description,
          questions: userDataSuccess.clinics[0].questionResponse ? userDataSuccess.clinics[0].questionResponse : [],
          seoTitle: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaTitle : '',
          metaDescription: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaDesc : '',
          targetKeywords: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaKeywords.split(",").map((item: any) => ({ value: item, label: item })) : []
        },
        specialities: {
          specialities: userDataSuccess.clinics[0].primarySpeciality
        }
      });
      setPercentage(60);
    } else if (location.state && location.state.step === "7") {
      setStep(7);
      setRequest({
        practiceType: null,
        clinicUniqueId: userDataSuccess.clinics[0].uniqueId,
        generalInfo: {
          practicePhoto: userDataSuccess.clinics[0].logo ? configs.IMAGE_URL + "clinic/" + userDataSuccess.clinics[0].logo.name + "-200x200." + userDataSuccess.clinics[0].logo.type : configs.DEFAULT_IMAGE_URL_CLINIC,
          practiceName: userDataSuccess.clinics[0].name,
          phoneNumber: userDataSuccess.clinics[0].phone,
          email: userDataSuccess.clinics[0].email,
          website: userDataSuccess.clinics[0].website
        },
        location: {
          address: userDataSuccess.clinics[0].address,
          textAddress: userDataSuccess.clinics[0].address.formattedAddress
        },
        hours: userDataSuccess.clinics[0].hours,
        questions: {
          practiceDescription: userDataSuccess.clinics[0].description,
          questions: userDataSuccess.clinics[0].questionResponse ? userDataSuccess.clinics[0].questionResponse : [],
          seoTitle: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaTitle : '',
          metaDescription: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaDesc : '',
          targetKeywords: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaKeywords.split(",").map((item: any) => ({ value: item, label: item })) : []
        },
        specialities: {
          specialities: userDataSuccess.clinics[0].primarySpeciality
        },
        potentialAppointmentTypes: userDataSuccess.clinics[0].potentialAppointmentTypes
      });
      setPercentage(60);
    } else if (location.state && location.state.step === "8") {
      setStep(8);
      setRequest({
        practiceType: null,
        clinicUniqueId: userDataSuccess.clinics[0].uniqueId,
        generalInfo: {
          practicePhoto: userDataSuccess.clinics[0].logo ? configs.IMAGE_URL + "clinic/" + userDataSuccess.clinics[0].logo.name + "-200x200." + userDataSuccess.clinics[0].logo.type : configs.DEFAULT_IMAGE_URL_CLINIC,
          practiceName: userDataSuccess.clinics[0].name,
          phoneNumber: userDataSuccess.clinics[0].phone,
          email: userDataSuccess.clinics[0].email,
          website: userDataSuccess.clinics[0].website
        },
        location: {
          address: userDataSuccess.clinics[0].address,
          textAddress: userDataSuccess.clinics[0].address.formattedAddress
        },
        hours: userDataSuccess.clinics[0].hours,
        questions: {
          practiceDescription: userDataSuccess.clinics[0].description,
          questions: userDataSuccess.clinics[0].questionResponse ? userDataSuccess.clinics[0].questionResponse : [],
          seoTitle: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaTitle : '',
          metaDescription: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaDesc : '',
          targetKeywords: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaKeywords.split(",").map((item: any) => ({ value: item, label: item })) : []
        },
        specialities: {
          specialities: userDataSuccess.clinics[0].primarySpeciality
        },
        potentialAppointmentTypes: userDataSuccess.clinics[0].potentialAppointmentTypes,
        featureAndFacility: {
          hygieneFeatures: userDataSuccess.clinics[0].HygieneFeatures,
          generalFeatures: userDataSuccess.clinics[0].features
        }
      });
      setPercentage(70);
    } else if (location.state && location.state.step === "9") {
      setStep(9);
      setRequest({
        practiceType: null,
        clinicUniqueId: userDataSuccess.clinics[0].uniqueId,
        generalInfo: {
          practicePhoto: userDataSuccess.clinics[0].logo ? configs.IMAGE_URL + "clinic/" + userDataSuccess.clinics[0].logo.name + "-200x200." + userDataSuccess.clinics[0].logo.type : configs.DEFAULT_IMAGE_URL_CLINIC,
          practiceName: userDataSuccess.clinics[0].name,
          phoneNumber: userDataSuccess.clinics[0].phone,
          email: userDataSuccess.clinics[0].email,
          website: userDataSuccess.clinics[0].website
        },
        location: {
          address: userDataSuccess.clinics[0].address,
          textAddress: userDataSuccess.clinics[0].address.formattedAddress
        },
        hours: userDataSuccess.clinics[0].hours,
        questions: {
          practiceDescription: userDataSuccess.clinics[0].description,
          questions: userDataSuccess.clinics[0].questionResponse ? userDataSuccess.clinics[0].questionResponse : [],
          seoTitle: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaTitle : '',
          metaDescription: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaDesc : '',
          targetKeywords: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaKeywords.split(",").map((item: any) => ({ value: item, label: item })) : []
        },
        specialities: {
          specialities: userDataSuccess.clinics[0].primarySpeciality
        },
        potentialAppointmentTypes: userDataSuccess.clinics[0].potentialAppointmentTypes,
        featureAndFacility: {
          hygieneFeatures: userDataSuccess.clinics[0].HygieneFeatures,
          generalFeatures: userDataSuccess.clinics[0].features
        },
        insurance: {
          insurance: {
            insuranceAccept: userDataSuccess.clinics[0].insurances.length > 0 ? 'specific' : 'all',
            selectedInsurances: userDataSuccess.clinics[0].insurances
          },
          socialAssistance: {
            accept: userDataSuccess.clinics[0].socaiAssistance,
            selectedAssistance: userDataSuccess.clinics[0].socialAssistType,
            assistanceAcceptType: userDataSuccess.clinics[0].socialAssistTypeAccept,
          }
        }
      });
      setPercentage(80);
    } else if (location.state && location.state.step === "10") {
      setStep(10);
      setRequest({
        practiceType: null,
        clinicUniqueId: userDataSuccess.clinics[0].uniqueId,
        generalInfo: {
          practicePhoto: userDataSuccess.clinics[0].logo ? configs.IMAGE_URL + "clinic/" + userDataSuccess.clinics[0].logo.name + "-200x200." + userDataSuccess.clinics[0].logo.type : configs.DEFAULT_IMAGE_URL_CLINIC,
          practiceName: userDataSuccess.clinics[0].name,
          phoneNumber: userDataSuccess.clinics[0].phone,
          email: userDataSuccess.clinics[0].email,
          website: userDataSuccess.clinics[0].website
        },
        location: {
          address: userDataSuccess.clinics[0].address,
          textAddress: userDataSuccess.clinics[0].address.formattedAddress
        },
        hours: userDataSuccess.clinics[0].hours,
        questions: {
          practiceDescription: userDataSuccess.clinics[0].description,
          questions: userDataSuccess.clinics[0].questionResponse ? userDataSuccess.clinics[0].questionResponse : [],
          seoTitle: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaTitle : '',
          metaDescription: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaDesc : '',
          targetKeywords: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaKeywords.split(",").map((item: any) => ({ value: item, label: item })) : []
        },
        specialities: {
          specialities: userDataSuccess.clinics[0].primarySpeciality
        },
        potentialAppointmentTypes: userDataSuccess.clinics[0].potentialAppointmentTypes,
        featureAndFacility: {
          hygieneFeatures: userDataSuccess.clinics[0].HygieneFeatures,
          generalFeatures: userDataSuccess.clinics[0].features
        },
        insurance: {
          insurance: {
            insuranceAccept: userDataSuccess.clinics[0].insurances.length > 0 ? 'specific' : 'all',
            selectedInsurances: userDataSuccess.clinics[0].insurances
          },
          socialAssistance: {
            accept: userDataSuccess.clinics[0].socaiAssistance,
            selectedAssistance: userDataSuccess.clinics[0].socialAssistType,
            assistanceAcceptType: userDataSuccess.clinics[0].socialAssistTypeAccept,
          }
        },
        practicePhotos: {
          practicePhotos: userDataSuccess.clinics[0].photos
        }
      });
      setPercentage(90);
    } else if (location.state && location.state.step === "11") {
      setStep(10);
      setRequest({
        practiceType: null,
        clinicUniqueId: userDataSuccess.clinics[0].uniqueId,
        generalInfo: {
          practicePhoto: userDataSuccess.clinics[0].logo ? configs.IMAGE_URL + "clinic/" + userDataSuccess.clinics[0].logo.name + "-200x200." + userDataSuccess.clinics[0].logo.type : configs.DEFAULT_IMAGE_URL_CLINIC,
          practiceName: userDataSuccess.clinics[0].name,
          phoneNumber: userDataSuccess.clinics[0].phone,
          email: userDataSuccess.clinics[0].email,
          website: userDataSuccess.clinics[0].website
        },
        location: {
          address: userDataSuccess.clinics[0].address,
          textAddress: userDataSuccess.clinics[0].address.formattedAddress
        },
        hours: userDataSuccess.clinics[0].hours,
        questions: {
          practiceDescription: userDataSuccess.clinics[0].description,
          questions: userDataSuccess.clinics[0].questionResponse ? userDataSuccess.clinics[0].questionResponse : [],
          seoTitle: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaTitle : '',
          metaDescription: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaDesc : '',
          targetKeywords: userDataSuccess.clinics[0].seodata ? userDataSuccess.clinics[0].seodata.metaKeywords.split(",").map((item: any) => ({ value: item, label: item })) : []
        },
        specialities: {
          specialities: userDataSuccess.clinics[0].primarySpeciality
        },
        potentialAppointmentTypes: userDataSuccess.clinics[0].potentialAppointmentTypes,
        featureAndFacility: {
          hygieneFeatures: userDataSuccess.clinics[0].HygieneFeatures,
          generalFeatures: userDataSuccess.clinics[0].features
        },
        insurance: {
          insurance: {
            insuranceAccept: userDataSuccess.clinics[0].insurances.length > 0 ? 'specific' : 'all',
            selectedInsurances: userDataSuccess.clinics[0].insurances
          },
          socialAssistance: {
            accept: userDataSuccess.clinics[0].socaiAssistance,
            selectedAssistance: userDataSuccess.clinics[0].socialAssistType,
            assistanceAcceptType: userDataSuccess.clinics[0].socialAssistTypeAccept,
          }
        },
        practicePhotos: {
          practicePhotos: userDataSuccess.clinics[0].photos
        }
      });
      setPercentage(100);
    }
  }, []);
  const onNext = (nextStep: any, requestToAdd: any) => {
    setRequest({ ...request, ...requestToAdd });
    setStep(nextStep);
  }
  const onPrev = (prevStep: any) => {
    setStep(prevStep);
  }
  const onFinish = (cardInfo: any) => {
    dispatch(fetchLoadingPending());
    createBasicPlan(request.clinicUniqueId).then(() => {
      fetchUserData().then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.status && success.status.error === false) {
          // history.replace('/practice/profile');
          window.location.href = window.location.origin + '/practice/profile';
        }
      }).catch((err) => {
        dispatch(fetchLoadingSuccess(false));
      });
    }).catch((err) => {
      toast.error('Failed to create your basic plan, please contact admin if you do not see plan under subscription.');
      dispatch(fetchLoadingSuccess(false));
    });
  };
  useEffect(() => {
    if (step !== undefined) {
      setPercentage(step * 10);
    }
  }, [step]);
  return (
    <>
      <section className="practice-step-sec">

        <div className="progress m-0 custom-progress overflow-visible" style={{ height: '3px', width: '100%' }}>
          <div className="progress-bar overflow-visible" role="progressbar" style={{ width: percentage + '%' }}> <span className="fw-medium text-primary progper">{percentage}%</span></div>
          {/* <button type="button" className="btn-close" aria-label="Close"></button> */}
        </div>

        <div className="container-fluid border-bottom py-5 mb-5">
          <ProgressBar step={step}></ProgressBar>
        </div>

        <div className="container steps-container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10 col-12">

              {
                step === 0 &&
                <PracticeApp1 onNext={onNext} data={request.practiceType}></PracticeApp1>
              }
              {
                step === 1 &&
                <PracticeApp2 onNext={onNext} onPrev={onPrev} data={request.generalInfo}
                  clinicUniqueId={request.clinicUniqueId}
                ></PracticeApp2>
              }
              {
                step === 2 &&
                <PracticeApp3 onNext={onNext} onPrev={onPrev} data={request.location}
                  clinicUniqueId={request.clinicUniqueId}
                ></PracticeApp3>
              }
              {
                step === 3 &&
                <PracticeApp4 onNext={onNext} onPrev={onPrev} data={request.hours}
                  clinicUniqueId={request.clinicUniqueId}
                ></PracticeApp4>
              }
              {
                step === 4 &&
                <PracticeApp5 onNext={onNext} onPrev={onPrev} data={request.questions}
                  clinicUniqueId={request.clinicUniqueId}
                ></PracticeApp5>
              }
              {
                step === 5 &&
                <PracticeApp8 onNext={onNext} onPrev={onPrev} data={request.specialities}
                  clinicUniqueId={request.clinicUniqueId}
                  practiceType={request.practiceType}
                ></PracticeApp8>
              }
              {
                step === 6 &&
                <AppointmentTypeHourMap onNext={onNext} onPrev={onPrev}
                  clinicUniqueId={request.clinicUniqueId}
                  specialities={request.specialities}
                  potentialAppointmentTypes={request.potentialAppointmentTypes}
                ></AppointmentTypeHourMap>
              }
              {
                step === 7 &&
                <PracticeApp6 onNext={onNext} onPrev={onPrev} data={request.featureAndFacility}
                  clinicUniqueId={request.clinicUniqueId}
                  specialities={request.specialities}
                ></PracticeApp6>
              }
              {
                step === 8 &&
                <PracticeApp7 onNext={onNext} onPrev={onPrev} data={request.insurance}
                  clinicUniqueId={request.clinicUniqueId}
                ></PracticeApp7>
              }
              {
                step === 9 &&
                <PracticeApp9 onNext={onNext} onPrev={onPrev} data={request.practicePhotos}
                  clinicUniqueId={request.clinicUniqueId}
                ></PracticeApp9>
              }
              {
                step === 10 &&
                <PracticeApp10 onFinish={onFinish} onPrev={onPrev} data={request.cardInfo}
                  clinicUniqueId={request.clinicUniqueId}
                ></PracticeApp10>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}