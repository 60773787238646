import React from 'react';
import configs from '../../../configs/apiConfigs';
export const UtilityApiKey = ({
    apiKey,
    clinicId
}: any) => {
    return (
        <div className="pms-maping-sec mb-4">
            <div className="card">
                <div className="card-header bg-light py-3 px-4">
                    <div className="d-flex justify-content-between">
                        <div className="fw-medium">Utility</div>
                    </div>
                </div>
                <div className="card-body p-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex">
                                <div style={{ wordBreak: "break-all" }}>
                                    {configs.UTILITY_URL + apiKey + "/" + clinicId}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}