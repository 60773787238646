export enum EMPLOYEE {
    ADD_EMPLOYEE = 'ADD_EMPLOYEE',
    ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE',
    ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS',
    EMPLOYEE_LIST = 'EMPLOYEE_LIST',
    EMPLOYEE_LIST_FAILURE = 'EMPLOYEE_LIST_FAILURE',
    EMPLOYEE_LIST_SUCCESS = 'EMPLOYEE_LIST_SUCCESS',
    DELETE_EMPLOYEE = 'DELETE_EMPLOYEE',
    DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE',
    DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS',
    EMPLOYEE_PRESCREENING = 'EMPLOYEE_PRESCREENING',
    EMPLOYEE_PRESCREENIN_FAILURE = 'EMPLOYEE_PRESCREENING_FAILURE',
    EMPLOYEE_PRESCREENIN_SUCCESS = 'EMPLOYEE_PRESCREENIN_SUCCESS',
    EMPLOYEE_INIT = 'EMPLOYEE_INIT'
}
/* State */
export interface EmployeeState {
    error: any;
    pending: boolean;
    addEmployeeRequest?: AddEmployeeRequest;
    addEmployeeResponse?: AddEmployeeResponse;
    employeeListRequest?: EmployeeListRequest;
    employeeListResponse?: EmployeeListResponse;
    deleteEmployeeRequest?: EmployeeDeleteRequest;
    deleteEmployeeResponse?: EmployeeDeleteResponse;
    employeePrescreeningRequest?: EmployeePrescreeningRequest;
    employeePrescreeningResponse?: EmployeePrescreeningResponse;
}
interface Status {
    error: boolean;
    msg: string;
}
/* Request & Response */
export interface AddEmployeeRequest {
    fname: string;
    lname: string;
    email: string;
    password: string;
    clinicUniqueId: string;
}
export interface AddEmployeeResponse {
    employees: Employee;
    status: Status;
}
export interface EmployeeListRequest {
    clinicUniqueId: string;
}
export interface Employee {
    id: string;
    uniqueid: string;
    workid: string;
    fname: string;
    lname: string;
    email: string;
}
export interface EmployeeListResponse {
    employees: Employee[];
    status: Status;
}
export interface EmployeeDeleteRequest {
    clinicUniqueId: string;
    workId: string;
}
export interface EmployeeDeleteResponse {
    workId: string;
    status: Status;
}
export interface EmployeePrescreeningRequest {
    clinicUniqueId: string;
    start: string;
    end: string;
    workid: string;
}
export interface PrescreeningItem {
    data: { question: string; answer: string; }[];
    date: string;
    email: string;
    fname: string;
    id: string;
    lname: string;
    uniqueid: string;
    workid: string;
}
export interface EmployeePrescreeningResponse {
    status: Status;
    list: PrescreeningItem[];
}
/* Action creator types */
export interface AddEmployeePendingAction {
    type: EMPLOYEE.ADD_EMPLOYEE;
    payload: AddEmployeeRequest;
}
export interface AddEmployeeFailureAction {
    type: EMPLOYEE.ADD_EMPLOYEE_FAILURE;
    payload: any;
}
export interface AddEmployeeSuccessAction {
    type: EMPLOYEE.ADD_EMPLOYEE_SUCCESS;
    payload: AddEmployeeResponse;
}
export interface EmployeeListPendingAction {
    type: EMPLOYEE.EMPLOYEE_LIST;
    payload: EmployeeListRequest;
}
export interface EmployeeListFailureAction {
    type: EMPLOYEE.EMPLOYEE_LIST_FAILURE;
    payload: any;
}
export interface EmployeeListSuccessAction {
    type: EMPLOYEE.EMPLOYEE_LIST_SUCCESS;
    payload: EmployeeListResponse;
}
export interface EmployeeDeletePendingAction {
    type: EMPLOYEE.DELETE_EMPLOYEE;
    payload: EmployeeDeleteRequest;
}
export interface EmployeeDeleteFailureAction {
    type: EMPLOYEE.DELETE_EMPLOYEE_FAILURE;
    payload: any;
}
export interface EmployeeDeleteSuccessAction {
    type: EMPLOYEE.DELETE_EMPLOYEE_SUCCESS;
    payload: EmployeeDeleteResponse;
}
export interface EmployeePrescreeningPendingAction {
    type: EMPLOYEE.EMPLOYEE_PRESCREENING;
    payload: EmployeePrescreeningRequest;
}
export interface EmployeePrescreeningFailureAction {
    type: EMPLOYEE.EMPLOYEE_PRESCREENIN_FAILURE;
    payload: any;
}
export interface EmployeePrescreeningSuccessAction {
    type: EMPLOYEE.EMPLOYEE_PRESCREENIN_SUCCESS;
    payload: EmployeePrescreeningResponse;
}
export interface EmployeeInitAction {
    type: EMPLOYEE.EMPLOYEE_INIT;
}
export type EmployeeActionTypes = AddEmployeePendingAction | AddEmployeeFailureAction | AddEmployeeSuccessAction |
    EmployeeListPendingAction | EmployeeListFailureAction | EmployeeListSuccessAction | EmployeeDeletePendingAction |
    EmployeeDeleteFailureAction | EmployeeDeleteSuccessAction | EmployeePrescreeningFailureAction | EmployeePrescreeningPendingAction |
    EmployeePrescreeningSuccessAction | EmployeeInitAction;