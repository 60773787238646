import { USER_DATA, FetchUserDataErrorAction, FetchUserDataPendingAction, FetchUserDataSuccessAction, UserDataRequest, UserDataResponse, UserLogoUploadRequest, UploadUserLogoPendingAction, UserLogoUploadResponse, UploadUserLogoSuccessAction, UploadUserLogoErrorAction, UserNameChangeRequest, UserNameChangeResponse, UserNameChangingPendingAction, UserNameChangingSuccessAction, UserNameChangingErrorAction, LocationChangeAction, LocationChange, SpecialityChangeAction, EmailVerificationRequest, EmailVerificationPendingAction, EmailVerificationResponse, EmailVerificationSuccessAction, EmailVerificationErrorAction, NameChangeAction, EmailChangeAction } from './types';
/* Action Creators */
export const fetchUserDataPending = (userData: UserDataRequest): FetchUserDataPendingAction => {
    return {
        type: USER_DATA.LOAD_PENDING,
        payload: userData
    };
};

export const fetchUserDataSuccess = (userDataResponse: UserDataResponse): FetchUserDataSuccessAction => {
    return {
        type: USER_DATA.LOAD_SUCCESS,
        userData: userDataResponse
    }
}

export const fetchUserDataError = (err: any): FetchUserDataErrorAction => {
    return {
        type: USER_DATA.LOAD_ERROR,
        error: err
    }
}

export const fetchUserLogoUploadPending = (userData: UserLogoUploadRequest): UploadUserLogoPendingAction => {
    return {
        type: USER_DATA.CHANGE_IMAGE_PENDING,
        payload: userData
    };
};

export const fetchUserLogoUploadSuccess = (userDataResponse: UserLogoUploadResponse): UploadUserLogoSuccessAction => {
    return {
        type: USER_DATA.CHANGE_IMAGE_SUCCESS,
        userLogoUpload: userDataResponse,
    }
}

export const fetchUserLogoUploadError = (err: any): UploadUserLogoErrorAction => {
    return {
        type: USER_DATA.CHANGE_IMAGE_ERROR,
        error: err
    }
}

export const fetchUserNameChangePending = (request: UserNameChangeRequest): UserNameChangingPendingAction => {
    return {
        type: USER_DATA.CHANGE_NAME_PENDING,
        payload: request
    };
};

export const fetchUserNameChangeSuccess = (response: UserNameChangeResponse): UserNameChangingSuccessAction => {
    return {
        type: USER_DATA.CHANGE_NAME_SUCCESS,
        userNameChange: response
    }
}

export const fetchUserNameChangeError = (err: any): UserNameChangingErrorAction => {
    return {
        type: USER_DATA.CHANGE_NAME_ERROR,
        error: err
    }
}

export const locationChange = (location: LocationChange): LocationChangeAction => {
    return {
        type: USER_DATA.LOCATION_CHANGE,
        location: location
    }
}

export const specialityChange = (payload: any): SpecialityChangeAction => {
    return {
        type: USER_DATA.SPECIALITY_CHANGE,
        payload: payload
    }
}
export const emailVerificationPendingAction = (request: EmailVerificationRequest): EmailVerificationPendingAction => {
    return {
        type: USER_DATA.EMAIL_VERIFICATION_PENDING,
        payload: request
    }
}
export const emailVerificationSuccessAction = (response: EmailVerificationResponse): EmailVerificationSuccessAction => {
    return {
        type: USER_DATA.EMAIL_VERIFICATION_SUCCESS,
        emailVerification: response
    }
}
export const emailVerificationErrorAction = (error: any): EmailVerificationErrorAction => {
    return {
        type: USER_DATA.EMAIL_VERIFICATION_ERROR,
        error: error
    }
}
export const nameChangeAction = (firstName: string, lastName: string): NameChangeAction => {
    return {
        type: "NAME_CHANGE_USER",
        firstName: firstName,
        lastName: lastName
    }
}
export const emailChangeAction = (email: string): EmailChangeAction => {
    return {
        type: "EMAIL_CHANGE_USER",
        email: email
    }
}