import { billingReducer } from './billing/reducers';
import { appointmentReducer } from './appointment/reducers';
import { providerReducer } from './provider/reducers';
import { profileReducer } from './profile/reducers';
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { createLogger } from 'redux-logger';
import CreateSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/root";
import { loginForgotSentReducer } from './loginforgotsent/reducers';
import { loginReducer } from './login/reducers';
import { forgotPasswordReducer } from './forgotpassword/reducers';
import { userInfoReducer } from './userinfo/reducers';
import { userEmailReducer } from './userEmail/reducers';
import { userPasswordReducer } from './userPassword/reducers';
import { userDataReducer } from './userData/reducers';
import { loadingReducer } from './loadingIndicator/reducers';
import { settingsReducer } from './settings/reducers';
import { clinicAddReducer } from './addclinic/reducers';
import { providerAddReducer } from './addProvider/reducers';
import { paymentReducer } from './payment/reducers';
import employeeReducer from './employee/reducers';
import { pmsAppointmentReducer } from './pmsappointments/reducers';
import { subscriptionPlanReducer } from './subscriptionplan/reducers';
import { messageCenterReducer } from './messagecenter/reducers';
import storeChatReducer from './chat/reducer';
import storeQuickChatReducer from './quickChat/reducer';
import appointmentPrefrenceReducer from './appointmentPrefrence/reducer';
//Combines all the reducer under appReducer
const appReducer = combineReducers({
    loginForgotSent: loginForgotSentReducer,
    login: loginReducer,
    forgotPassword: forgotPasswordReducer,
    userInfo: userInfoReducer,
    userEmail: userEmailReducer,
    userPassword: userPasswordReducer,
    userData: userDataReducer,
    loading: loadingReducer,
    settings: settingsReducer,
    profile: profileReducer,
    provider: providerReducer,
    clinicAdd: clinicAddReducer,
    providerAdd: providerAddReducer,
    appointment: appointmentReducer,
    billing: billingReducer,
    payment: paymentReducer,
    employee: employeeReducer,
    pmsAppointment: pmsAppointmentReducer,
    subscriptionPlan: subscriptionPlanReducer,
    messageCenter: messageCenterReducer,
    chat: storeChatReducer,
    quickChat: storeQuickChatReducer,
    appointmentPrefrence: appointmentPrefrenceReducer
});
// root reducer with when performes log out it make app state undefined and return appReducer
const rootReducer = (state: any, action: any) => {
    if (action.type === 'USER_LOGGED_OUT') {
        state = undefined;
    }
    return appReducer(state, action);
}
// logger middleware to print all redux log in console
const loggerMiddleware = createLogger();
//IAppState for application with type of rootreducer
export type IAppState = ReturnType<typeof rootReducer>;
/**
 * Configures the store and returns the store object
 */
export default function configureStore(): Store<any, any> {
    const sagaMiddleware = CreateSagaMiddleware();
    const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, /* loggerMiddleware */));
    sagaMiddleware.run(rootSaga);
    //pass this store object in route provider so can access as props in the component.
    return store;
}