export enum PAYMENT {
    FETCH_INIT_PAYMENT = 'FETCH_INIT_PAYMENT',
    FETCH_INIT_PAYMENT_SUCCESS = 'FETCH_INIT_PAYMENT_SUCCESS',
    FETCH_INIT_PAYMENT_ERROR = 'FETCH_INIT_PAYMENT_ERROR',
    PAYMENT_BILLABLE_ENTRY = 'PAYMENT_BILLABLE_ENTRY',
    PAYMENT_BILLABLE_ENTRY_SUCCESS = 'PAYMENT_BILLABLE_ENTRY_SUCCESS',
    PAYMENT_BILLABLE_ENTRY_ERROR = 'PAYMENT_BILLABLE_ENTRY_ERROR',
    PAYMENT_BILLABLE_APPOINTMENT = 'PAYMENT_BILLABLE_APPOINTMENT',
    PAYMENT_BILLABLE_APPOINTMENT_ERROR = 'PAYMENT_BILLABLE_APPOINTMENT_ERROR',
    PAYMENT_BILLABLE_APPOINTMENT_SUCCESS = 'PAYMENT_BILLABLE_APPOINTMENT_SUCCESS',
    SERVICES_TYPE = 'SERVICES_TYPE',
    SERVICES_TYPE_ERROR = 'SERVICES_TYPE_ERROR',
    SERVICES_TYPE_SUCCESS = 'SERVICES_TYPE_SUCCESS',
    CREATE_INVOICE = 'CREATE_INVOICE',
    CREATE_INVOICE_ERROR = 'CREATE_INVOICE_ERROR',
    CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS',
    STRIPE_SAVE_ACCOUNT_ID = 'STRIPE_SAVE_ACCOUNT_ID',
    STRIPE_SAVE_ACCOUNT_ID_ERROR = 'STRIPE_SAVE_ACCOUNT_ID_ERROR',
    STRIPE_SAVE_ACCOUNT_ID_SUCCESS = 'STRIPE_SAVE_ACCOUNT_ID_SUCCESS',
    SAVE_TAX = 'SAVE_TAX',
    SAVE_TAX_ERROR = 'SAVE_TAX_ERROR',
    SAVE_TAX_SUCCESS = 'SAVE_TAX_SUCCESS',
    PAYMENT_AMOUNT_UPDATE = 'PAYMENT_AMOUNT_UPDATE',
    PAYMENT_AMOUNT_UPDATE_SUCCESS = 'PAYMENT_AMOUNT_UPDATE_SUCCESS',
    PAYMENT_AMOUNT_UPDATE_ERROR = 'PAYMENT_AMOUNT_UPDATE_ERROR'
}
export interface PaymentState {
    pending: boolean;
    error: any;
    fetchInitPaymentRequest?: FetchInitPaymentRequest;
    fetchInitPaymentResponse?: FetchInitePaymentResponse;
    paymentBillableEntryRequest?: PaymentBillableEntryRequest;
    paymentBillableEntryResponse?: PaymentBillableEntryResponse;
    paymentBillableAppointmentRequest?: PaymentBillableAppointmentRequest;
    paymentBillableAppointmentResponse?: PaymentBillableAppointmentResponse[];
    servicesTypeRequest?: ServicesTypeRequest;
    servicesTypeResponse?: ServicesTypeResponse;
    createInvoiceRequest?: CreateInvoiceRequest;
    createInvoiceResponse?: CreateInvoiceResponse;
    stripeSaveAccountIdRequest?: StripeSaveAccountIdRequest;
    stripeSaveAccountIdResponse?: StripeSaveAccountIdResponse;
    saveTaxRequest?: SaveTaxRequest;
    saveTaxResponse?: SaveTaxResponse;
    paymentAmountResponse?: paymentAmountResponse;
    paymentAmountRequest?: paymentAmountRequest;
}
export interface FetchInitPaymentRequest {
}
export interface FetchInitePaymentResponse {
    id: string;
    uniqueId: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: string;
    userAccess: string;
    stripeAccount: {
        customerId: string;
        accountID: string;
    },
    clinics: {
        id: string;
        uniqueId: string;
        name: string;
        metadata: {
            clinicTnC: boolean,
            reviewOn: any[],
            trustApiKey: string,
            acceptAssignment: boolean,
            isTaxable: boolean
        },
        doctors: {
            id: string;
            uniqueId: string;
            fullname: string;
            stripeCard: string;
            provider: string;
        }[];
    }[];
    status: { error: boolean, msg: string };
}
export interface paymentAmountRequest {
    amount: number;
    clinicUniqueId: any;
    appoinmentUniqueId: any;
}
export interface paymentAmountResponse {
    status: { error: boolean, msg: string };
}

export interface FetchInitPaymentPendingAction {
    type: PAYMENT.FETCH_INIT_PAYMENT,
    payload: FetchInitPaymentRequest;
}
export interface FetchInitPaymentErrorAction {
    type: PAYMENT.FETCH_INIT_PAYMENT_ERROR,
    payload: any;
}
export interface FetchInitePaymentSuccessAction {
    type: PAYMENT.FETCH_INIT_PAYMENT_SUCCESS,
    payload: FetchInitePaymentResponse
}

export interface PaymentBillableEntryRequest {
    type: string;
    uniqueId: string;
}
export interface PaymentBillableEntryResponse {
    billableYears: []; billableMonthsByYear: {};
}
export interface PaymentBillableEntryPendingAction {
    type: PAYMENT.PAYMENT_BILLABLE_ENTRY,
    payload: PaymentBillableEntryRequest
}
export interface PaymentBillableEntrySuccessAction {
    type: PAYMENT.PAYMENT_BILLABLE_ENTRY_SUCCESS,
    payload: PaymentBillableEntryResponse
}
export interface PaymentBillableEntryErrorAction {
    type: PAYMENT.PAYMENT_BILLABLE_ENTRY_ERROR,
    payload: PaymentBillableEntryErrorAction
}

export interface PaymentBillableAppointmentRequest {
    type: string;
    uniqueId: string;
    month: string;
    year: string;
    invoiceType: any;
    fullname?: any;
}
export interface PaymentBillableAppointmentResponse {
    id: string,
    uniqueid: string,
    scheduledDate: string,
    scheduledTime: string,
    status: string,
    date: any;
    time: any;
    metadata: {
        patient: {
            reminder: number,
            isNewPatient: boolean
        },
        claimPoint: string,
        appointment: {
            scheduledOn: string,
            scheduledNote: string,
            visited: boolean,
            verifiedOn: string,
            payment: {
                request: number;
                lastRequest: string;
            };
            prescreening: {
                attempt: boolean,
                data: []
            }
        },
        isTaxable: boolean;
    },
    billingdata: {
        isNewLead: false,
        billingStatus: string,
        billingPrice: string,
        billingTax: number,
        clinicToPatientBilling: {
            memo: string;
            total: string,
            appointmentId: string,
            isTaxable: boolean,
            subtotal: string,
            billingTax: string,
            status: string,
            paidOn: string,
            invoiceCreatedDate: string,
            invoiceNumber: string,
            invoiceDetails: {
                totalAmount: string,
                amount: string,
                serviceType: string,
                service_id: string,
                insuranceAmount: string,
                procedureCategory: string,
                procedureCode:string
            }[],
            isAdminFee: boolean,
            adminFee: string;
            invoicedoc: string;
        },
        recurring: any
    },
    billingStatus: string,
    createdAt: string,
    updatedAt: string,
    reconciledStatus: string,
    billableAction: string,
    appointmenttype_name: string,
    invoiceNumber: string;
    clinic: {
        id: string,
        taxrate: number,
        email: string,
        phone: string,
        uniqueid: string,
        name: string;
        isTaxable: boolean,
        metadata: any
    },
    doctor: {
        fullname: string,
        id: string,
        email: string,
        uniqueid: string,
        gender: string
    },
    patient: {
        phone: string,
        email: string,
        lname: string,
        fname: string,
        id: string,
        uniqueid: string
    },
    dependant: {
        phone: string,
        email: string,
        lname: string,
        fname: string,
        id: string,
        uniqueid: string
    },
    requests: {
        appointmentFor: string;
        dependantName: string;
        dependantRelation: string;
    },
    clinicmetadata: {
        adminsafetyfee: boolean;
        adminFee?: string;
    }
}
export interface PaymentBillableAppointmentPendingAction {
    type: PAYMENT.PAYMENT_BILLABLE_APPOINTMENT,
    payload: PaymentBillableAppointmentRequest
}
export interface PaymentBillableAppointmentErrorAction {
    type: PAYMENT.PAYMENT_BILLABLE_APPOINTMENT_ERROR,
    payload: any
}
export interface PaymentBillableAppointmentSuccessAction {
    type: PAYMENT.PAYMENT_BILLABLE_APPOINTMENT_SUCCESS,
    payload: PaymentBillableAppointmentResponse[]
}

export interface ServicesTypeRequest { }
export interface ServicesTypeResponse {
    serviceType: {
        id: string;
        service_type: string;
    }[];
    status: { error: boolean; msg: string };
}
export interface ServicesTypePendingAction {
    type: PAYMENT.SERVICES_TYPE;
    payload: ServicesTypeRequest;
}
export interface ServicesTypeSuccessAction {
    type: PAYMENT.SERVICES_TYPE_SUCCESS;
    payload: ServicesTypeResponse;
}
export interface ServicesTypeErrorAction {
    type: PAYMENT.SERVICES_TYPE_ERROR;
    payload: any;
}

export interface CreateInvoiceRequest {
    type: string;
    appointmentId: string;
    provider: string;
    uniqueId: string;
    requestInvoiceData: any;
}
export interface CreateInvoiceResponse {
    status: { error: false, msg: string };
}
export interface CreateInvoicePendingAction {
    type: PAYMENT.CREATE_INVOICE;
    payload: CreateInvoiceRequest;
}
export interface CreateInvoiceSuccessAction {
    type: PAYMENT.CREATE_INVOICE_SUCCESS;
    payload: CreateInvoiceResponse;
}
export interface CreateInvoiceErrorAction {
    type: PAYMENT.CREATE_INVOICE_ERROR;
    payload: any
}

export interface StripeSaveAccountIdRequest {
    uniqueId: string;
    requestData: any;
}
export interface StripeSaveAccountIdResponse {
    status: { error: false, msg: string };
}
export interface StripeSaveAccountIdPendingAction {
    type: PAYMENT.STRIPE_SAVE_ACCOUNT_ID;
    payload: StripeSaveAccountIdRequest;
}
export interface StripeSaveAccountIdSuccessAction {
    type: PAYMENT.STRIPE_SAVE_ACCOUNT_ID_SUCCESS;
    payload: StripeSaveAccountIdResponse;
}
export interface StripeSaveAccountIdErrorAction {
    type: PAYMENT.STRIPE_SAVE_ACCOUNT_ID_ERROR;
    payload: any
}

export interface SaveTaxRequest {
    uniqueId: string;
    requestData: any;
}
export interface SaveTaxResponse {
    status: { error: false, msg: string };
}
export interface SaveTaxPendingAction {
    type: PAYMENT.SAVE_TAX;
    payload: SaveTaxRequest;
}
export interface SaveTaxSuccessAction {
    type: PAYMENT.SAVE_TAX_SUCCESS;
    payload: SaveTaxResponse;
}
export interface SaveTaxErrorAction {
    type: PAYMENT.SAVE_TAX_ERROR;
    payload: any
}
export interface PayamentAmountPendingAction {
    type: PAYMENT.PAYMENT_AMOUNT_UPDATE;
    payload: paymentAmountRequest;
}
export interface PayamentAmountSuccessAction {
    type: PAYMENT.PAYMENT_AMOUNT_UPDATE_SUCCESS;
    payload: paymentAmountResponse;
}
export interface PayamentAmountxErrorAction {
    type: PAYMENT.PAYMENT_AMOUNT_UPDATE_ERROR;
    payload: any
}
export type PaymentActionTypes = FetchInitPaymentErrorAction | FetchInitPaymentPendingAction | FetchInitePaymentSuccessAction
    | PaymentBillableEntryErrorAction | PaymentBillableEntryPendingAction | PaymentBillableEntrySuccessAction |
    PaymentBillableAppointmentErrorAction | PaymentBillableAppointmentPendingAction | PaymentBillableAppointmentSuccessAction |
    ServicesTypeSuccessAction | ServicesTypeErrorAction | ServicesTypePendingAction | CreateInvoiceSuccessAction | CreateInvoicePendingAction |
    CreateInvoiceErrorAction | StripeSaveAccountIdErrorAction | StripeSaveAccountIdPendingAction | StripeSaveAccountIdSuccessAction |
    SaveTaxErrorAction | SaveTaxPendingAction | SaveTaxSuccessAction | PayamentAmountPendingAction | PayamentAmountSuccessAction | PayamentAmountxErrorAction;