import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import Select from 'react-select';
import { getDigitalFormList, sendDigitalDocEmail, sendDigitalSignatureEmail } from '../../services/messageCenter';
import { editAppointment } from '../../services/appointment';
export const DigitalDoc = ({ hideModal, appointment, fetchAppointment }: any) => {
    const { register, setError, getValues, setValue, errors, watch, handleSubmit } = useForm({
        defaultValues: {
            reqMedHistory: true,
            digitalDoc: false,
            digitalForm: [],
            digitalFormDoc: [],
            error: '',
            email: ''
        }
    });
    const [digitalFormList, setDigitalFormList] = useState([]);
    const [isLoadingDigitalForm, setIsLoadingDigitalForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDigitalFormDoc, setSelectedDigitalFormDoc] = useState([] as any);
    const [selectedDigitalForm, setSelectedDigitalForm] = useState([] as any);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const [showEmailAcceptModal, setShowEmailAcceptModalModal] = useState(false);
    const [showDigitalDocModal, setShowDigitalDocModal] = useState(false);
    useEffect(() => {
        if (appointment && appointment.dependant && appointment.dependant.email && appointment.dependant.email !== "") {
            setShowEmailAcceptModalModal(false);
            setShowDigitalDocModal(true);
            // setShowEmailAcceptModalModal(true);
            // setShowDigitalDocModal(false);
        } else {
            setShowEmailAcceptModalModal(true);
            setShowDigitalDocModal(false);
        }
        getDigitFormListFunc();
    }, []);
    const getDigitFormListFunc = async () => {
        if (userData && userData.clinics[0]) {
            try {
                setIsLoadingDigitalForm(true);
                const response = await getDigitalFormList({ clinicid: userData.clinics[0].id });
                setIsLoadingDigitalForm(false);
                if (response && response.error === false) {
                    let list = response.data.map((item: any) => {
                        return {
                            value: item.messagecenterdocument_id,
                            label: item.documentname,
                            path: item.documentpath
                        }
                    })
                    console.log(list)
                    // setDigitalFormList(response.data);
                    setDigitalFormList(list);
                    // setValue('digitalForm', list[0]);
                }
            } catch (err) {
                setIsLoadingDigitalForm(false);
                console.log(err);
            }
        }
    }
    const selectDigitalFormDoc = (e: any) => {
        setSelectedDigitalFormDoc(e);
    }
    const selectDigitalForm = (e: any) => {
        setSelectedDigitalForm(e);
    }
    const onSendClick = async (data: any) => {
        console.log(data);
        if (!getValues('reqMedHistory') && !getValues('digitalDoc')) {
            setError('error', { type: 'custom', message: 'Please select at least one digital forms.' });
            return;
        }
        // if (!getValues('reqMedHistory')) {
        //     setError('error', { type: 'custom', message: 'Select at least one form under medical history forms.' });
        //     return;
        // }
        // if (getValues('reqMedHistory')) {
        //     setError('error', { type: 'custom', message: 'Select atleast one form under medical history forms.' });
        //     return;
        // }
        if (getValues('reqMedHistory') && getValues('digitalFormDoc') && getValues('digitalFormDoc').length <= 0) {
            setError('error', { type: 'custom', message: 'Select at least one form under the medical history.' });
            return;
        }
        if (getValues('digitalDoc') && getValues('digitalForm') && getValues('digitalForm').length <= 0) {
            setError('error', { type: 'custom', message: 'Select at least one form under the digital forms.' });
            return;
        }
        // if (getValues('digitalDoc')) {
        //     setError('error', { type: 'custom', message: 'Select atleast one form under digital forms.' });
        //     return;
        // }
        setError('error', {});
        if (data.reqMedHistory === true && userData.clinics[0]) {
            console.log('selectedDigitalFormDoc', selectedDigitalFormDoc);
            let documentId_List: any = [];
            selectedDigitalFormDoc.map((item: any) => {
                documentId_List.push(item.value);
            });
            let documentFormId_List: any = [];
            selectedDigitalForm.map((item: any) => {
                documentFormId_List.push(item.value);
            });
            try {
                setIsLoading(true);
                // const response = await sendDigitalDocEmail({
                //     clinicid: userData.clinics[0].id,
                //     appointment_uniqueid: appointment.uniqueid,
                //     documentid_list: data.digitalFormDoc.toString()
                // });
                const response = await sendDigitalSignatureEmail({
                    clinicid: userData.clinics[0].id,
                    appointment_uniqueid: appointment.uniqueid,
                    documentid_list: data?.digitalFormDoc?.toString() ?? ""
                });
                setIsLoading(false);
                if (response && response.error === false) {

                    if (data.digitalDoc === true) {
                        setIsLoading(true);
                        // const digitalSignatureResponse = await sendDigitalSignatureEmail({
                        //     clinicid: userData.clinics[0].id,
                        //     appointment_uniqueid: appointment.uniqueid,
                        //     documentid_list: data.digitalForm.toString()
                        // });
                        const digitalSignatureResponse = await sendDigitalDocEmail({
                            clinicid: userData.clinics[0].id,
                            appointment_uniqueid: appointment.uniqueid,
                            documentid_list: data.digitalForm.toString()
                        });
                        setIsLoading(false);
                        if (digitalSignatureResponse && digitalSignatureResponse.error === false) {
                            toast.success('Digital form sent successfully');
                            hideModal();
                        } else if (digitalSignatureResponse && digitalSignatureResponse.error === true) {
                            setIsLoading(false);
                        }
                    } else {
                        toast.success('Digital form sent successfully');
                        setIsLoading(false);
                        hideModal();
                    }
                }
            } catch (err) {
                setIsLoading(false);
                toast.error('Digital forms sent failed');
                console.log(err);
            }
        } else {
            if (data.digitalDoc === true) {
                let documentFormId_List: any = [];
                selectedDigitalForm.map((item: any) => {
                    documentFormId_List.push(item.value);
                })
                try {
                    setIsLoading(true);
                    // const digitalSignatureResponse = await sendDigitalSignatureEmail({
                    //     clinicid: userData.clinics[0].id,
                    //     appointment_uniqueid: appointment.uniqueid,
                    //     documentid_list: data.digitalForm.toString()
                    // });
                    const digitalSignatureResponse = await sendDigitalDocEmail({
                        clinicid: userData.clinics[0].id,
                        appointment_uniqueid: appointment.uniqueid,
                        documentid_list: data?.digitalForm?.toString() ?? ""
                    });
                    setIsLoading(false);
                    if (digitalSignatureResponse && digitalSignatureResponse.error === false) {
                        toast.success('Digital form sent successfully');
                        hideModal();
                    } else if (digitalSignatureResponse && digitalSignatureResponse.error === true) {
                        setIsLoading(false);
                    }
                } catch (err) {
                    toast.error('Digital form send failed');
                    setIsLoading(false);
                }
            }
        }
    }
    const onEmailAcceptClick = (data: any) => {
        let reqData = {
            email: data.email,
        };
        setIsLoading(true);
        editAppointment(reqData, appointment.clinic.uniqueid, appointment.uniqueid).then((success: any) => {
            if (success && success.status && success.status.error === false) {
                setError('error', {});
                setIsLoading(false);
                fetchAppointment(false);
                setShowDigitalDocModal(true);
                setShowEmailAcceptModalModal(false);
                return;
            }
            setError('error', { type: 'custom', message: 'Email add to record failed.' });
            setIsLoading(false);
        }).catch((err) => {
            toast.error(err.msg);
            setError('error', { type: 'custom', message: 'Email add to record failed.' });
            setIsLoading(false);
        });
    }
    console.log(appointment)
    return (
        <>
            <Modal className="newcommon reschedule-popup" show={showEmailAcceptModal} onHide={hideModal} centered>
                <Modal.Header>
                    <div className="modal-title">Accept Email</div>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideModal} ></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="">

                        <div className="d-flex justify-content-between mb-2">
                            <label className="fw-medium" htmlFor="reqMedHistory">
                                Patient does not have email registered, would you like to enter email manually?
                            </label>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <input type="text" className="form-control" id="email" name="email" placeholder="Email" ref={register({
                                required: true,
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })} />
                            {
                                (errors && errors.email && errors.email.type === "required") &&
                                <div className="is-invalid">Email is required.</div>
                            }
                            {
                                (errors && errors.email && errors.email.type === "pattern") &&
                                <div className="is-invalid">Email is invalid.</div>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        (errors && errors.error && errors.error.type === 'custom') &&
                        <div className="is-invalid">{errors.error.message}</div>
                    }
                    <button className="btn btn-primary" type="button" onClick={handleSubmit(onEmailAcceptClick)}>
                        {
                            isLoading &&
                            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                        }
                        Add to record
                    </button>
                </Modal.Footer>
            </Modal >
            <Modal className="newcommon reschedule-popup" show={showDigitalDocModal} onHide={hideModal} centered>
                <Modal.Header>
                    <div className="modal-title">Digital Forms</div>
                    <button type="button" className="btn-close" aria-label="Close" onClick={hideModal} ></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="">

                        <div className="d-flex justify-content-between mb-2">
                            <label className="fw-medium" htmlFor="reqMedHistory">
                                Medical History/Consent Forms
                            </label>
                            <div className="form-check form-switch">
                                <input type="checkbox" className="form-check-input" id="reqMedHistory" name="reqMedHistory" ref={register} />
                            </div>
                        </div>

                        <div className={"mb-3 " + (watch('reqMedHistory') === true ? '' : 'd-none')}>
                            {
                                digitalFormList.map((form: any) => {
                                    return (
                                        <React.Fragment key={form.value + "reqMedForm"}>
                                            <div className="form-check me-3">
                                                <input type="checkbox" className="form-check-input" id={form.value + "digitalFormDoc"} name={"digitalFormDoc"} value={form.value} ref={register} />
                                                <label className="form-check-label" htmlFor={form.value + "digitalFormDoc"}>{form.label}</label>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                // <Select options={digitalFormList} isMulti
                                //     value={selectedDigitalFormDoc}
                                //     onChange={(e: any) => { selectDigitalFormDoc(e) }}
                                // ></Select>
                            }
                        </div>

                        <div className="d-flex justify-content-between mb-2">
                            <label className="fw-medium" htmlFor="digitalDoc">
                                Additional Forms/Documents
                            </label>
                            <div className="form-check form-switch">
                                <input type="checkbox" className="form-check-input" id="digitalDoc" name="digitalDoc" ref={register} />
                            </div>
                        </div>

                        <div className={"mb-3 " + (watch('digitalDoc') === true ? '' : 'd-none')}>
                            {
                                digitalFormList.map((form: any) => {
                                    return (
                                        <React.Fragment key={form.value}>
                                            <div className="form-check me-3">
                                                <input type="checkbox" className="form-check-input" id={form.value + "digitalForm"} name={"digitalForm"} value={form.value} ref={register} />
                                                <label className="form-check-label" htmlFor={form.value + "digitalForm"}>{form.label}</label>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                                // <Select options={digitalFormList} isMulti
                                //     value={selectedDigitalForm}
                                //     onChange={(e: any) => { selectDigitalForm(e) }}
                                // ></Select>
                            }
                        </div>
                    </div>
                    <div className="row g-3 mt-3">
                        <input type="hidden" name="error" ref={register}></input>
                        {
                            (errors && errors.error && errors.error.type === 'custom') &&
                            <div className="is-invalid">{errors.error.message}</div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" type="button" onClick={handleSubmit(onSendClick)}
                        disabled={((watch('reqMedHistory') === false && watch('digitalDoc') === false))}>
                        {
                            isLoading &&
                            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                        }
                        Send
                    </button>
                </Modal.Footer>
            </Modal >
        </>
    )
}