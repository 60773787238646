import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Campaign } from './Campaign';
import { Notification } from './Notification';
import { Notification as NotificationFollowup } from './followup/Notification';
import './notification.scss';
import './campaign.scss';
import { Administrative } from './Administrative';
import { DigitalDoc } from './DigitalDoc';
import { Payment } from '../messageCenter/Payment';
import { EmailTemplates } from '../../components/messageCenter/EmailTemplates';
import { CustomEmailEditor } from '../emailEditor/EmailEditor';
export const MessageCenter = (props: any) => {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={match.url + '/notification'} component={Notification}></Route>
        <Route path={match.url + '/followup'} component={NotificationFollowup}></Route>
        <Route path={match.url + '/campaigns'} component={Campaign}></Route>
        <Route path={match.url + '/administrative'} component={Administrative}></Route>
        <Route path={match.url + '/digitaldoc'} component={DigitalDoc}></Route>
        <Route path={match.url + '/payment'} component={Payment}></Route>
        <Route exact path={match.url + '/templates'} component={EmailTemplates}></Route>
        <Route exact path={match.url + '/emaileditor'} component={CustomEmailEditor}></Route>
      </Switch>
    </>
  );
}