import React from 'react';
import { IAppState } from '../../../store';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import HeaderBilling from './headerBilling/HeaderBilling';
import { BillingRequest, BillingState, BillingResponse } from '../../../store/billing/types';
import { fetchBillingPendingAction } from '../../../store/billing/actions';
import { toast } from 'react-toastify';
import BillingSettings from './settings/BillingSettings';
import BillingAppointment from './appointments/BillingAppointment';
import BillingInvoice from './invoices/BillingInvoice';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
interface Props {
    billingState: BillingState;
    fetchBilling: typeof fetchBillingPendingAction;
    match: any;
    location: any;
    history: any;
}
interface State {
    billingResponse?: BillingResponse;
    isLoading: boolean;
}
class BillingAccount extends React.Component<Props, State> {
    cardNumberElement: any;
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
        }
    }
    componentDidMount() {
        this.setState({ isLoading: true });
        this.props.fetchBilling({});
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (this.props !== nextProps) {
            if (this.props.billingState !== nextProps.billingState) {
                if (nextProps.billingState.error !== null) {
                    if (nextProps.billingState.billingResponse && nextProps.billingState.billingResponse.status
                        && nextProps.billingState.billingResponse.status.error === true
                        && nextProps.billingState.billingResponse.status.msg !== '') {
                        toast.error(nextProps.billingState.billingResponse.status.msg);
                    } else {
                        toast.error('Something went wrong');
                    }
                } else if (nextProps.billingState.billingResponse && nextProps.billingState.pending === false &&
                    nextProps.billingState.billingResponse.status.error === false) {
                    this.setState({ billingResponse: nextProps.billingState.billingResponse, isLoading: false });
                }
            }
        }
        return true;
    }
    componentDidUpdate() {
        this.props.billingState.error = null;
        if (this.props.billingState && this.props.billingState.billingResponse) {
            this.props.billingState.billingResponse.status.error = true;
            this.props.billingState.billingResponse.status.msg = '';
        }
    }
    render() {
        return (
            <>
                {
                    (this.props.billingState.pending === true || this.state.isLoading === true) &&
                    <LoadingIndicator></LoadingIndicator>
                }
                <Helmet><title>Billing Account</title></Helmet>
                <HeaderBilling></HeaderBilling>
                {
                    (this.state.billingResponse) &&
                    <div style={{ display: this.props.match.params.subtype === 'appointments' ? 'block' : 'none' }}>
                        <BillingAppointment {...this.props}></BillingAppointment>
                    </div>
                }
                {/*  {
                    (this.state.billingResponse) &&
                    <div style={{ display: this.props.match.params.subtype === 'settings' ? 'block' : 'none' }}>
                        <BillingSettings {...this.props}></BillingSettings>
                    </div>
                } */}
                {
                    (this.state.billingResponse) &&
                    <div style={{ display: this.props.match.params.subtype === 'invoices' ? 'block' : 'none' }}>
                        <BillingInvoice {...this.props}></BillingInvoice>
                    </div>
                }
                {/* {
                    <Switch>
                        <Route exact path="/user/billing/appointments" component={BillingAppointment} ></Route>
                        <Route exact path="/user/billing/settings" component={BillingSettings} ></Route>
                        <Route exact path="/user/billing/invoices" component={BillingInvoice} ></Route>
                        <Route render={(props) => (<Redirect to="/user/billing/appointments"></Redirect>)}></Route>
                    </Switch>
                } */}

            </>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    billingState: state.billing,
});
const mapDispatchToProps = (dispatch: any) => ({
    fetchBilling: (request: BillingRequest) => {
        dispatch(fetchBillingPendingAction(request));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(BillingAccount);