import * as firebase from 'firebase/app';
import 'firebase/firestore';
import apiConfigs from '../../src/configs/apiConfigs';
import * as _ from 'lodash';
import Axios from 'axios';
import { attachHeader } from '../utils/apiHandler';
import configs from '../../src/configs/apiConfigs';
import moment from 'moment';

export const ChatDetails = (clinics: any) => {
    localStorage.removeItem('Chat');
    localStorage.removeItem('ChatClinicId');
    firebase.firestore().collection(apiConfigs.CHAT_COLLECTION)
        .where('actionType', '==', 'MESSAGE')
        .where('sendToCId', '==', clinics[0].uniqueId)
        .orderBy('createdAt', 'desc')
        .get()
        .then(async (querySnapshot: any) => {
            let docsMapped = querySnapshot.docs.map((doc: any) => doc.data()).filter((item: any) => { return item.closeChat !== true });
            const uniqueAppointments = _.uniqBy(docsMapped, function (item: any) { return item.appointmentId });
            let appointmentIds = uniqueAppointments.map((item: any) => { return item.appointmentId });
            if (appointmentIds && appointmentIds.length > 0) {
                const url = configs.API_URL + 'clinic' + '/' + clinics[0].uniqueId + '/information/?ids=' + appointmentIds.toString();
                await Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then((success) => {
                    // this.apiData(success.data, appointmentIds)
                    let appointments = success.data.appointments.map((item: any) => {
                        const firestoreAppointment = uniqueAppointments.find((fItem: any) => fItem.appointmentId === Number(item.uniqueid));
                        if (firestoreAppointment) {
                            return {
                                ...item,
                                ...firestoreAppointment,
                                creationTime: moment(moment.unix(firestoreAppointment.createdAt.seconds)).toDate()
                            }
                        }
                        return item;
                    });
                    appointments = _.sortBy(appointments, (item) => item.creationTime).reverse();
                    localStorage.removeItem('Chat');
                    localStorage.removeItem('ChatClinicId');
                    let uniqAppointments = _.uniqBy(appointments,'id');
                    localStorage.setItem('Chat', JSON.stringify(uniqAppointments))
                    localStorage.setItem('ChatClinicId', clinics[0].uniqueId);
                }).catch((error) => {
                    return ''
                });
            }

        })

}