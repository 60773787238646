import { USER_INFO, FetchUserInfoErrorAction, FetchUserInfoPendingAction, FetchUserInfoSuccessAction, UserInfoRequest, UserInfoResponse } from './types';
/* Action Creators */
export const fetchUserInfoPending = (userInfo: UserInfoRequest): FetchUserInfoPendingAction => {
    return {
        type: USER_INFO.LOAD_PENDING,
        payload: userInfo
    };
};

export const fetchUserInfoSuccess = (userInfoResponse: UserInfoResponse): FetchUserInfoSuccessAction => {
    return {
        type: USER_INFO.LOAD_SUCCESS,
        userInfo: userInfoResponse
    }
}

export const fetchUserInfoError = (err: any): FetchUserInfoErrorAction => {
    return {
        type: USER_INFO.LOAD_ERROR,
        error: err
    }
}