import {
    LoginState,
    LoginActionTypes,
    LOGIN
} from './types';

export const initialState: LoginState = {
    pending: false,
    login: { email: '', password: '' },
    loginResponse: {
        id: '',
        uniqueId: '',
        firstName: '',
        lastName: '',
        email: '',
        emailVerified: '',
        userAccess: '0',
        authParam: '',
        permissions: [],
        status: { error: false, msg: '' },
        jwtToken: ''
    },
    error: null
};

export const loginReducer = (state = initialState, action: LoginActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case LOGIN.LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case LOGIN.LOAD_PENDING:
            return {
                ...state,
                pending: true,
                login: action.payload
            }
        case LOGIN.LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                loginResponse: action.login
            }
        default:
            return NewState;

    }
};