import { AppointmentState, AppointmentActionTypes, APPOINTMENT } from "./types";

export const initialState: AppointmentState = {
    pending: false,
    error: null,
    callApiCount: 0,
    callEmergencyCount: 0
}
export const appointmentReducer = (state = initialState, action: AppointmentActionTypes) => {
    switch (action.type) {
        case APPOINTMENT.APPOINTMENT_LOAD_PENDING:
            return {
                ...state,
                pending: true,
                appointmentRequest: action.payload
            }
        case APPOINTMENT.APPOINTMENT_LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                appointmentResponse: action.appointment
            }
        case APPOINTMENT.APPOINTMENT_LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case APPOINTMENT.APPOINTMENT_DETAIL:
            return {
                ...state,
                pending: true,
                error: null,
                appointmentDetailRequest: action.payload
            }
        case APPOINTMENT.APPOINTMENT_DETAIL_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                appointmentDetailResponse: action.payload
            }
        case APPOINTMENT.APPOINTMENT_DETAIL_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error,
                appointmentDetailRequest: undefined,
                appointmentDetailResponse: undefined
            }
        case APPOINTMENT.MODAL_EMAIL_INFO:
            return {
                ...state,
                pending: true,
                error: null,
                modalEmailInfoRequest: action.payload
            }
        case APPOINTMENT.MODAL_EMAIL_INFO_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.payload,
                modalEmailInfoResponse: undefined,
                modalEmailInfoRequest: undefined,
            }
        case APPOINTMENT.MODAL_EMAIL_INFO_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                modalEmailInfoResponse: action.payload,
                modalEmailInfoRequest: undefined,
            }
        case APPOINTMENT.MANUAL_APPOINTMENT:
            return {
                ...state,
                pending: true,
                error: null,
                manualAppointmentRequest: action.payload
            }
        case APPOINTMENT.MANUAL_APPOINTMENT_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.payload,
                manualAppointmentResponse: undefined,
                manualAppointmentRequest: undefined,
            }
        case APPOINTMENT.MANUAL_APPOINTMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                manualAppointmentResponse: action.payload,
                manualAppointmentRequest: undefined,
            }
        case APPOINTMENT.REQUEST_FOR_MEDICAL_HISTORY:
            return {
                ...state,
                pending: true,
                error: null,
                requestForMedicalHistoryRequest: action.payload
            }
        case APPOINTMENT.REQUEST_FOR_MEDICAL_HISTORY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
            }
        case APPOINTMENT.REQUEST_FOR_MEDICAL_HISTORY_SUCCESS:
            return {
                ...state,
                pendig: false,
                error: null,
                requestForMedicalHistoryResponse: action.payload
            }
        case APPOINTMENT.EDIT_APPOINTMENT:
            return {
                ...state, pending: true,
                error: null,
                appointmentEditRequest: undefined,
            }
        case APPOINTMENT.EDIT_APPOINTMENT_FAILURE:
            return {
                ...state, pending: false,
                error: action.payload,
            }
        case APPOINTMENT.EDIT_APPOINTMENT_SUCCESS:
            return {
                ...state, pending: false,
                error: null,
                appointmentEditResponse: action.payload
            }
        case APPOINTMENT.BOOK_SLOT:
            return {
                ...state, pending: true,
                error: null,
                bookSlotRequest: action.payload,
                bookSlotResponse: undefined
            }
        case APPOINTMENT.BOOK_SLOT_FAILURE:
            return {
                ...state, pending: false,
                error: action.payload,
                bookSlotRequest: undefined,
                bookSlotResponse: undefined
            }
        case APPOINTMENT.BOOK_SLOT_SUCCESS:
            return {
                ...state, pending: false,
                error: null,
                bookSlotRequest: undefined,
                bookSlotResponse: action.payload
            }
        case APPOINTMENT.APPOINTMENT_UNVERIFIED:
            return {
                ...state, pending: true,
                error: null,
                appointmentUnverifiedRequest: action.payload,
                appointmentUnverifiedResponse: undefined
            }
        case APPOINTMENT.APPOINTMENT_UNVERIFIED_SUCCESS:
            return {
                ...state, pending: false, error: null,
                appointmentUnverifiedRequest: undefined,
                appointmentUnverifiedResponse: action.payload
            }
        case APPOINTMENT.APPOINTMENT_UNVERIFIED_FAILED:
            return {
                ...state, pending: false, error: action.payload,
                appointmentUnverifiedRequest: undefined,
                appointmentUnverifiedResponse: undefined
            }
        case APPOINTMENT.CALL_WAITLIST_API:
            return {
                ...state,
                callApiCount: state.callApiCount + 1
            }
        case APPOINTMENT.CALL_EMERGENCY_API:
            return {
                ...state,
                callEmergencyCount: state.callApiCount + 1
            }
        default:
            return state;
    }
};