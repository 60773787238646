import React, { useEffect, useState } from "react";
import configs from "../../configs/apiConfigs";
import firebase from "firebase";
import { getPatientList } from "../../services/patientsearch";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { createSession, createSubscription, getLines, initiateCall, mangoVoiceCall } from "../../services/voicecall";
let client:any;
let lastStatus = "";
let lastType = "";
export const VoiceCallGoto = (props: any) => {
    const [status, setStatus] = useState("");
    const [direction, setDirection] = useState("");
    const [sourceNumber, setSourceNumber] = useState("");
    const [patientList, setPatientList]:any = useState([]);
    const [showPatientListModal, setShowPatientListModal] = useState(false);
    const state = useSelector((state: IAppState) => state);
    const clinic: any = state.userData.userDataResponse.clinics[0];
    useEffect(() => {
    listenToGotoEvents();
    return () => {
        if (client && client.close) {
            client.close()
        }
    }
  }, []);
  useEffect(() => {
    console.log(props);
    if (props.appointment) {
        const dependant:any = props.appointment.dependant;
        initiateCall(clinic.clinicSetting.gotoaccess_token, props.callTo, clinic.clinicSetting.gotoLine, clinic.clinicSetting.gotoOrg);
        setPatientList([{...dependant, dependant: dependant}]);
        setStatus("Calling");
    }
}, props);
  const listenToGotoEvents = async () => {

    const response = await createSession(clinic.clinicSetting.gotoaccess_token);
    console.log("response", response);
    const line = await getLines(clinic.clinicSetting.gotoaccess_token, clinic.clinicSetting.gotoLine, clinic.clinicSetting.gotoOrg);
    const subscription = await createSubscription(clinic.clinicSetting.gotoaccess_token, [{
        id: "xcarepro"+clinic.id,
        type: "dialog",
        entity: {
            "id": line.id,
            "type":"line.v2",
            "account": line.organization.id
        }
    }], response.data.subscriptions);
    console.log("subscription",subscription);
    if (response.data.ws) {
        client = new W3CWebSocket(response.data.ws);
        client.onopen = () => {
            console.log('WebSocket Client Connected');
        };
        client.onmessage = (message:any) => {
            console.log(message);
            const data = JSON.parse(message.data);
            /* Outgoing */
            if (data.type === "announce" && data.data.state === "CREATED" && data.subId === "xcarepro"+clinic.id && data.data.direction === "initiator") {
                lastStatus = data.data.state;
                lastType = data.type;
            }
            if (data.type === "withdraw" && data.data.state === "HUNGUP" && data.subId === "xcarepro"+clinic.id && data.data.direction === "initiator") {
                if (lastType === "replace" && lastStatus === "UNBRIDGED") {
                    lastStatus = "";
                    lastType = "";
                    setDirection(""); setStatus(""); setSourceNumber("");
                    setShowPatientListModal(false);
                    setPatientList([]);
                    props.closeModal();
                    document.getElementById("position-rel")?.classList.remove("call-start");
                }
                if (lastType === "announce" && lastStatus === "CREATED") {
                    setDirection(""); setStatus(""); setSourceNumber("");
                    setShowPatientListModal(false);
                    setPatientList([]);
                    props.closeModal();
                    lastStatus = "";
                    lastType = "";
                    document.getElementById("position-rel")?.classList.remove("call-start");
                }
                if (lastType === "withdraw" && lastStatus === "HUNGUP") {
                    setDirection(""); setStatus(""); setSourceNumber("");
                    setShowPatientListModal(false);
                    setPatientList([]);
                    props.closeModal();
                    lastStatus = "";
                    lastType = "";
                    document.getElementById("position-rel")?.classList.remove("call-start");
                }
                if (lastType === "replace" && lastStatus === "ANSWERED") {
                    setDirection(""); setStatus(""); setSourceNumber("");
                    setShowPatientListModal(false);
                    setPatientList([]);
                    lastStatus = "";
                    lastType = "";
                    document.getElementById("position-rel")?.classList.remove("call-start");
                }
            }
            if (data.type === "replace" && data.data.state === "ANSWERED" && data.subId === "xcarepro"+clinic.id && data.data.direction === "initiator")  {
                lastStatus = data.data.state;
                lastType = data.type;
                setStatus("Ringing");
                // const obj:any = state.voiceCall.patient;
                // console.log(obj);
                // setPatientList([{...obj, dependant: obj}]);
            }
            if (data.type === "replace" && data.data.state === "BRIDGED" && data.subId === "xcarepro"+clinic.id && data.data.direction === "initiator")  {
                setStatus("Answered");
                // const obj:any = state.voiceCall.patient;
                // setPatientList([{...obj, dependant: obj}]);
            }
            if (data.type === "announce" && data.data.state === "ANSWERED" && data.subId === "xcarepro"+clinic.id && data.data.direction === "initiator")  {
                setStatus("Answered");
                // const obj:any = state.voiceCall.patient;
                // setPatientList([{...obj, dependant: obj}]);
            }
            if (data.type === "announce" && data.data.state === "RINGING" && data.subId === "xcarepro"+clinic.id && data.data.direction === "initiator")  {
                setStatus("Calling");
                // const obj:any = state.voiceCall.patient;
                // setPatientList([{...obj, dependant: obj}]);
            }
            if (data.type === "replace" && data.data.state === "UNBRIDGED" && data.subId === "xcarepro"+clinic.id && data.data.direction === "initiator")  {
                lastStatus = data.data.state;
                lastType = data.type;
                setStatus("Disconnecting");
                // const obj:any = state.voiceCall.patient;
                // setPatientList([{...obj, dependant: obj}]);
            }
            if (data.type === "withdraw" && data.data.state === "HUNGUP" && data.subId === "xcarepro"+clinic.id && data.data.direction === "initiator") {
                lastStatus = data.data.state;
                lastType = data.type;
                // const obj:any = state.voiceCall.patient;
                // setPatientList([{...obj, dependant: obj}]);
            }

            /* Incoming */
            if (data.type === "announce" && data.data.state === "CREATED" && data.subId === "xcarepro"+clinic.id && data.data.direction === "recipient") {
                lastStatus = data.data.state;
                lastType = data.type;
            }
            if (data.type === "withdraw" && data.data.state === "HUNGUP" && data.subId === "xcarepro"+clinic.id && data.data.direction === "recipient") {
                if (lastType === "replace" && lastStatus === "UNBRIDGED") {
                    setDirection(""); setStatus(""); setSourceNumber("");
                    setShowPatientListModal(false);
                    setPatientList([]);
                    props.closeModal();
                    lastStatus = "";
                    lastType = "";
                    document.getElementById("position-rel")?.classList.remove("call-start");
                }
                if (lastType === "announce" && lastStatus === "CREATED") {
                    setDirection(""); setStatus(""); setSourceNumber("");
                    setShowPatientListModal(false);
                    setPatientList([]);
                    props.closeModal();
                    lastStatus = "";
                    lastType = "";
                    document.getElementById("position-rel")?.classList.remove("call-start");
                }
                if (lastType === "withdraw" && lastStatus === "HUNGUP") {
                    setDirection(""); setStatus(""); setSourceNumber("");
                    setShowPatientListModal(false);
                    setPatientList([]);
                    props.closeModal();
                    lastStatus = "";
                    lastType = "";
                    document.getElementById("position-rel")?.classList.remove("call-start");
                }
            }
            if (data.type === "replace" && data.data.state === "ANSWERED" && data.subId === "xcarepro"+clinic.id && data.data.direction === "recipient")  {
                lastStatus = data.data.state;
                lastType = data.type;
                setStatus("Answered");
            }
            if (data.type === "replace" && data.data.state === "BRIDGED" && data.subId === "xcarepro"+clinic.id && data.data.direction === "recipient")  {
                setStatus("Answered");
            }
            if (data.type === "announce" && data.data.state === "ANSWERED" && data.subId === "xcarepro"+clinic.id && data.data.direction === "recipient")  {
                setStatus("Answered");
            }
            if (data.type === "replace" && data.data.state === "RINGING" && data.subId === "xcarepro"+clinic.id && data.data.direction === "recipient")  {
                getPatients(data.data.caller.number);
                setStatus("Incoming");
                // incoming call
            }
            if (data.type === "replace" && data.data.state === "UNBRIDGED" && data.subId === "xcarepro"+clinic.id && data.data.direction === "recipient")  {
                lastStatus = data.data.state;
                lastType = data.type;
                setStatus("Disconnecting");
            }
            if (data.type === "withdraw" && data.data.state === "HUNGUP" && data.subId === "xcarepro"+clinic.id && data.data.direction === "recipient") {
                lastStatus = data.data.state;
                lastType = data.type;
            }
            /* End */
        };
    }
  }
  const getPatients = async (number:string) => {
    try {
      const results = await getPatientList({ fullname: number, page: 1, results: 30 });
      setPatientList(results.patients);
      // setShowPatientListModal(true);
    } catch (err) {
        console.log(err);
    }
  }
    if (status !== "") {
        return (
            <>
                <div className="call-strip">
                    <div className="d-flex justify-content-start">
                        {
                            patientList.slice(0, 2).map((patient: any, index:number) => {
                                const name = patient?.dependant.fname + " " + patient?.dependant.lname;
                                return (
                                    <>
                                    <div className="d-flex align-items-center" key={index}>
                                        <div className="avatar rounded-circle bg-white text-dark text-uppercase flex-shrink-0">
                                            {patient?.dependant.fname.charAt(0)}{patient?.dependant.lname.charAt(0)}
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <div className="d-block fw-medium p-name lh-base" onClick={() => {
                                                setShowPatientListModal(true);
                                            }}>
                                                {name}
                                            </div>
                                            <small className="d-block text-white lh-base">{direction}</small>
                                            <>
                                                {
                                                    status == 'Calling' ?
                                                    <small className="d-block text-white lh-base">{status}</small>
                                                    :
                                                    <small className="d-block text-white lh-base">
                                                    <i className="bi bi-telephone"></i>
                                                    {status}
                                                    </small>
                                                }
                                            </>
                                        </div>
                                    </div>
                                    {
                                        patientList.length == 2 && index == 0 &&
                                        <div className="v-line"></div>
                                    }
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
};
