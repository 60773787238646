import React, { useEffect, useState } from 'react'
import configs from '../../../configs/apiConfigs';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { IAppState } from '../../../../store';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import _ from 'lodash';
import { SearchDependent } from './SearchDependent';
import { ChatDependent } from './ChatDependent';
import { getPatientList } from '../../../services/chat';
import { DependentMessages } from './DependentMessages';
import { useHistory } from 'react-router-dom';
import firebaseInstance from '../../../helpers/firebase';

let listener: any;
let dependentUniqueIdTemp: any[] = [];
let displayDependentsTemp: any[] = [];
let isSearch: boolean = false;
export const PatientsChat = () => {
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const [totalLength, setTotalLength] = useState(0);
    const [selectedDependent, setSelectedDependent]: any = useState('');
    const [displayDependents, setDisplayDependents]: any = useState([]);
    const [dependentUniqueIds, setDependentUniqueIds]: any = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dependantCount, setDependantCount] = useState(0);
    const [providerCount, setProviderCount] = useState(0);
    const [groupCount, setGroupCount] = useState(0);
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        getConversation();
        getPendingCount();
        return () => {
            if (listener) {
                listener();
            }
        }
    }, []);

    const getPendingCount = async () => {
        // const count = await firebaseInstance.getPendingMessageCount(userDataResponse.clinics[0].uniqueId);
        firebaseInstance.db.collection(configs.CONVERSATION_DEV)
            .where('clinicId', '==', Number(userData.clinics[0].uniqueId))
            .where('metadata.viewByClinicAt', '==', null)
            .onSnapshot(async (querySnapshot: any) => {
                let docs = querySnapshot.docs.map((doc:any) => ({...doc.data(), id: doc.id}));
                docs = _.uniqBy(docs, 'dependentUniqueId');
                setDependantCount(docs.length);
            });
        let clinicDoc = await firebaseInstance.db.collection(configs.USER_COLLECTION).where('uniqueId', '==', userData.clinics[0].uniqueId).get();
        let clinicFirebaseId = clinicDoc.docs.map((doc: any) => ({ ...doc.data, id: doc.id }))[0]?.id;
        if (clinicFirebaseId) {
            firebaseInstance.db.collection(configs.GROUP_COLLECTION)
                .where('isClosed', '==', false)
                .where('type', '==', 1)
                .where('members', 'array-contains', clinicFirebaseId)
                .orderBy('modifiedAt').onSnapshot(async (snapshot: any) => {
                    const providers = snapshot.docs.map((item: any) => ({ ...item.data(), id: item.id }));
                    let totalCounter = 0;
                    for (const provider of providers) {
                        let clinicDoc = firebaseInstance.db.collection(configs.MESSAGE_COLLECTION);
                        const chatRefdoc = clinicDoc.doc(provider.id);
                        const snapshot = await chatRefdoc.get();
                        const data = snapshot.data();
                        if (data && data.messages) {
                            const messages = data.messages;
                            const counter = messages.filter((item:any) => item.readBy.find((item:any) => {return item.seenBy === clinicFirebaseId}) ===  undefined);
                            if (counter.length > 0) {
                                totalCounter += 1;
                            }
                        }
                    }
                    setProviderCount(totalCounter);
                });
        }

        if (clinicFirebaseId) {
            firebaseInstance.db.collection(configs.GROUP_COLLECTION)
                .where('isClosed', '==', false)
                .where('type', '==', 0)
                .where('members', 'array-contains', clinicFirebaseId)
                .orderBy('modifiedAt').onSnapshot(async (snapshot: any) => {
                    const providers = snapshot.docs.map((item: any) => ({ ...item.data(), id: item.id }));
                    let totalCounter = 0;
                    for (const provider of providers) {
                        let clinicDoc = firebaseInstance.db.collection(configs.MESSAGE_COLLECTION);
                        const chatRefdoc = clinicDoc.doc(provider.id);
                        const snapshot = await chatRefdoc.get();
                        const data = snapshot.data();
                        if (data && data.messages) {
                            const messages = data.messages;
                            const counter = messages.filter((item:any) => item.readBy.find((item:any) => {return item.seenBy === clinicFirebaseId}) ===  undefined);
                            if (counter.length > 0) {
                                totalCounter += 1;
                            }
                        }
                    }
                    setGroupCount(totalCounter);
                });
        }
    }

    const getConversation = () => {
        setIsLoading(true);
        //  dispatch(fetchLoadingPending());
        firebase.firestore().collection(configs.CONVERSATION_DEV)
            .where('clinicId', '==', Number(userData.clinics[0].uniqueId))
            .orderBy('createdAt', 'desc')
            .limit(10)
            .get()
            .then((querySnapshot: any) => {
                const clinicby = querySnapshot.docs.map((item: any) => ({ ...item.data(), id: item.id }));
                const uniqueDependentsIds = _.uniqBy(clinicby, 'dependentUniqueId').map((item: any) => { return item.dependentUniqueId });
                setTotalLength(uniqueDependentsIds.length);
                setDependentUniqueIds(uniqueDependentsIds);
                dependentUniqueIdTemp = uniqueDependentsIds;
                if (listener) {
                    listener();
                }
                fetchDependentList(userData.clinics[0].uniqueId, uniqueDependentsIds.toString());
            });
    }
    const fetchDependentList = (clinicUniqueId: any, dependentUniqueIdList: any) => {
        if (dependentUniqueIdList) {
            getPatientList(clinicUniqueId, dependentUniqueIdList).then((success) => {
                if (success && success.status && success.status.error === false) {
                    setDisplayDependents(success.patients);
                    displayDependentsTemp = success.patients;
                    getRealTimeDependentChat();
                    setIsLoading(false);
                    // dispatch(fetchLoadingSuccess(false));
                    if (success && success.patients && success.patients.length > 0 && !selectedDependent) {
                        setSelectedDependent(success.patients[0]);
                    }
                }
            }).catch((err) => {
                console.log(err);
                setIsLoading(false);
                // dispatch(fetchLoadingSuccess(false));
            })
        } else {
            setIsLoading(false);
        }
    }
    const handleSearchDependentList = (dependentList: any) => {
        if (dependentList && dependentList.length > 0) {
            setDisplayDependents(dependentList);
            displayDependentsTemp = dependentList;
            setSelectedDependent('');
            setTotalLength(dependentList.length)
            handleLoader(false);
            isSearch = true;
        } else {
            setDisplayDependents([]);
            displayDependentsTemp = [];
            setSelectedDependent('');
            dependentUniqueIdTemp = [];
            setDependentUniqueIds([]);
            handleLoader(false);
            if (listener) {
                listener();
            }
            getConversation();
            isSearch = false;
        }
    }

    useEffect(() => {
        dependentUniqueIdTemp = dependentUniqueIds;
    }, [dependentUniqueIds]);

    useEffect(() => {
        displayDependentsTemp = displayDependents;
    }, [displayDependents])

    const getRealTimeDependentChat = () => {
        if (listener && !isSearch) {
            listener();
        }
        listener = firebase.firestore().collection(configs.CONVERSATION_DEV)
            .where('clinicId', '==', Number(userData.clinics[0].uniqueId))
            .orderBy('createdAt', 'desc')
            .onSnapshot((querySnapshot: any) => {
                querySnapshot.docChanges().forEach((change: any, index: number) => {
                    if (change.type === "modified" && !isSearch) {
                        const newMessage = change.doc.data();
                        let findIndex = dependentUniqueIdTemp.findIndex((item: any) => { return item == newMessage.dependentUniqueId });
                        if (findIndex === -1) {
                            setDependentUniqueIds((dependentUniqueIds: any) => { return [newMessage.dependentUniqueId, ...dependentUniqueIds] });
                            getPatientList(userData.clinics[0].uniqueId, newMessage.dependentUniqueId).then((success) => {
                                if (success && success.status && success.status.error === false) {
                                    displayDependentsTemp = [...success.patients, ...displayDependentsTemp];
                                    setDisplayDependents((displayDependents: any) => { return [...success.patients, ...displayDependents] });
                                }
                            }).catch((err) => {
                                console.log(err);
                            });
                        } else {
                            let removeDependent = displayDependentsTemp.splice(findIndex, 1);
                            let removeUniqueId = dependentUniqueIdTemp.splice(findIndex, 1);
                            setDependentUniqueIds([removeUniqueId[0], ...dependentUniqueIdTemp]);
                            setDisplayDependents([...removeDependent, ...displayDependentsTemp]);
                        }
                    }
                });
            });
    }

    const handleLoader = (status: any) => {
        if (status === true) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }
    return (
        /*   (totalLength === 0) ?
          <div className="d-flex align-items-center justify-content-center centermsg">
              <div className="card w-25">
                  <div className="card-body text-center">
                      <label>No Messages</label>
                  </div>
              </div>
          </div>
              : */
        <main className="chat_params">
            <div className="container-fluid p-0">
                <div className="card border-0">
                    <div className="row g-0">
                        <div className="col-3 border-end chatlist-panel" id="scroll">
                            <div className="chat-tab-links">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <button className="nav-link active" type="button">
                                            Patient
                                            {
                                                dependantCount !== 0 &&
                                                <span className="badge rounded-pill bg-primary align-self-center">{dependantCount}</span>
                                            }
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link" type="button" onClick={() => {
                                            history.push('/chats/provider');
                                        }}>
                                            Provider
                                            {
                                                providerCount !== 0 &&
                                                <span className="badge rounded-pill bg-primary align-self-center">{providerCount}</span>
                                            }
                                        </button>
                                    </li>
                                    <li className={"nav-item " + (configs.SOURCE_URL !== "https://www.xcarepro.com/" ? "" : "d-none")}>
                                        <button className="nav-link" type="button" onClick={() => {
                                            history.push('/chats/group');
                                        }}>
                                            Group
                                            {
                                                groupCount !== 0 &&
                                                <span className="badge rounded-pill bg-primary align-self-center">{groupCount}</span>
                                            }
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="p-3">
                                <div className="fs-5 fw-medium mb-2">Messages</div>
                                <div className="d-flex align-items-center">
                                    <SearchDependent
                                        loader={handleLoader}
                                        searchDependentList={handleSearchDependentList}
                                    />
                                </div>
                            </div>
                            {
                                (isLoading) &&
                                <span className="spinner-border spinner-border-sm" role="status"></span>
                            }
                            {
                                (displayDependents && displayDependents.length > 0) &&
                                displayDependents.map((item: any, index: number) => {
                                    return (
                                        <React.Fragment key={item.uniqueid}>
                                            <ChatDependent
                                                selectedDependent={selectedDependent}
                                                setSelectedDependent={setSelectedDependent}
                                                dependent={item}
                                            />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            (selectedDependent) &&
                            <DependentMessages
                                dependent={selectedDependent}
                            />
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}