import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import RichTextEditor from 'react-rte';
import { createNotificationTemplateFollowup, getAppointmentCategoryList, getProcedureCodeList, updateNotificationDetailFollowup, uploadPhotos } from '../../services/messageCenter';
import moment from 'moment';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { values } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IAppState } from '../../../store';
import { useSelector } from 'react-redux';
import ClipboardIconPopup from '../../assets/images/clipboard-icon-popup.svg';
import WarningIcon from '../../assets/images/warning-icon.svg';

export const NotificationCreateRepeatedFollowup = forwardRef((props: any, ref: any) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalType, setModalType]: any = useState();
  const [procedureCodes, setProcedureCodes]: any = useState([]);
  const [appointmentCategories, setAppointmentCategories]: any = useState([]);
  const { register, handleSubmit, watch, errors, control, setValue, getValues, setError } = useForm();
  const clinic = useSelector((state: IAppState) => state.userData.userDataResponse.clinics[0]);
  const showEmailTemplate = clinic.isenableemailtemplate;
  const components = {
    DropdownIndicator: null,
  };

  const [inputValue, setInputValue]: any = useState('');
  const [value, setCustomDayValue]: any = useState([]);

  useImperativeHandle(ref, () => ({
    showModal: showModal,
    hideModal: hideModal
  }));
  const showModal = (item: any) => {
    setShow(true);
    setModalType(item);
    getAppointmentCategoryList({
      clinicid: clinic.id,
    }).then((success) => {
      if (success && success.error === false) {
        setAppointmentCategories(success.data);
      }
    }).catch((err) => {
    })
    getProcedureCodeList({
      clinicid: clinic.id,
      category: watch('appointmentcategory') ? watch('appointmentcategory') : '',
      type: watch('appointmentcategorytype') ? watch('appointmentcategorytype') : 'none'
    }).then((success) => {
      if (success && success.error === false) {
        if (success.data && success.data[0]) {
          setProcedureCodes(success.data[0]);
          if (watch('procedurecodestype') === 'all') {
            let values: any = [];
            for (let i = 0; i < success.data[0].length; i++) {
              values.push(success.data[0][i].procedure_code_sikkaId)
            }
            setValue('procedurecodes', values);
          }
        }
      }
    }).catch((err) => {
    })
  }
  const hideModal = () => {
    setShow(false);
    setCustomDayValue([]);
  }

  const handleChange = (value: any, actionMeta: any) => {
    if (value === null) {
      setCustomDayValue([]);
    } else {
      setCustomDayValue(value);
    }
  };
  const createOption = (label: string) => ({
    label,
    value: label,
  });
  const handleInputChange = (inputValue: any) => {
    if (isNaN(inputValue)) {
      return;
    }
    setInputValue(inputValue);
  };
  const handleKeyDown = (event: any) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setInputValue('');
        if (!value.find((item: any) => item.label == inputValue)) {
          setCustomDayValue([...value, createOption(inputValue)]);
        }
        event.preventDefault();
      default:
        return;
    }
  };
  const onSubmit = (data: any) => {
    console.log(data);
    let scheduledays = "";
    if (data.day2) {
      scheduledays += "2,";
    }
    if (data.day15) {
      scheduledays += "15,";
    }
    if (data.day7) {
      scheduledays += "7,";
    }
    const mappedValue = value.map((item: any) => item.value);
    if (value) {
      scheduledays += mappedValue.toString();
    }
    let templateTypes = '';
    if (data.email) {
      templateTypes = templateTypes + 'email';
    }
    if (data.text) {
      if (data.email) templateTypes += ',';
      templateTypes = templateTypes + 'text';
    }
    let appointmentCategoryTypeWatch = watch('appointmentcategorytype');
    let procedureCodesTypeWatch = watch('procedurecodestype');
    let procedure_categoryTemp: any = '', procedure_codeTemp: any = '';
    if (appointmentCategoryTypeWatch === 'all') {
      procedure_categoryTemp = 'all';
    } else if (appointmentCategoryTypeWatch === 'none') {
      procedure_categoryTemp = 'none';
    } else {
      procedure_categoryTemp = data.appointmentcategory.toString();
    }
    // Procedure code
    if (procedureCodesTypeWatch === 'all') {
      procedure_codeTemp = 'all';
    } else if (procedureCodesTypeWatch === 'none') {
      procedure_codeTemp = 'none';
    } else {
      procedure_codeTemp = data.procedurecodes.toString();
    }
    const requestData = {
      clinicid: modalType.clinicId,
      notificationtemplatetype_id: modalType.id,
      notificationname: data.notificationName,
      scheduledays: scheduledays,
      logourl: getValues('logoPreview'),
      bannerurl: getValues('bannerPreview'),
      subject: data.subject,
      body: data.body ? data.body.toString('html') : '',
      smstext: data.textMsgInput,
      appnotificationtext: data.appNotificationInput,
      startdate: data.startDate ? data.startDate : '',
      enddate: data.endDate ? data.endDate : '',
      notificationtime: data.time ? moment(data.time).format("hh:mm") : '',
      isactive: modalType.item ? modalType.item.isactive : false,
      appointment_type: data.appointmentType,
      notification_type: templateTypes,
      emailtemplate_id: showEmailTemplate ? data.emailTemplate : undefined,
      procedure_category: procedure_categoryTemp,
      procedure_code: procedure_codeTemp
    }
    setIsLoading(true);
    if (modalType.item) {
      Object.assign(requestData, { messagecenterfollowuptemplate_id: modalType.item.messagecenterfollowuptemplate_id });
      updateNotificationDetailFollowup(requestData).then((success) => {
        setIsLoading(false);
        if (success && success.error === false) {
          modalType.getNotificationTemplateList();
          toast.success('Updated successfully.');
          hideModal();
          return;
        }
        toast.warn('Something went wrong');
      }).catch((err) => {
        setIsLoading(false);
        toast.warn('Something went wrong');
      });
    } else {
      createNotificationTemplateFollowup(requestData).then((success) => {
        setIsLoading(false);
        if (success && success.error === false) {
          modalType.getNotificationTemplateList();
          toast.success('Notification added successfully.');
          hideModal();
          return;
        }
        toast.warn('Something went wrong');
      }).catch((err) => {
        setIsLoading(false);
        toast.warn('Something went wrong');
      });
    }
  }
  useEffect(() => {
    if (modalType && modalType.item) {
      const custom = modalType.item.scheduledays.split(",").filter((item: any) => (item !== "2" && item !== "7" && item !== "15"));
      console.log(custom);
      if (custom[0] !== '' && custom.length > 0) {
        setCustomDayValue(custom.map((item: any) => ({ value: item, label: item })));
      }
      if (showEmailTemplate) {
        setValue('subject', clinic.name);
        setValue('body', RichTextEditor.createValueFromString(clinic.name, 'html'));
      } else {
        setValue('subject', modalType.item.subject);
        setValue('body', RichTextEditor.createValueFromString(modalType.item.body, 'html'));
      }
      setValue('textMsgInput', modalType.item.smstext);
      setValue('appNotificationInput', modalType.item.appnotificationtext);
      setValue('startDate', modalType.item.startdate ? moment(modalType.item.startdate) : moment());
      setValue('endDate', modalType.item.enddate ? moment(modalType.item.enddate) : moment());
      setValue('time', modalType.item.notificationtime ? moment(modalType.item.notificationtime, 'HH:mm:ss') : moment());
      setValue('bannerPreview', modalType.item.bannerurl);
      setValue('logoPreview', modalType.item.logourl);
      setValue('notificationName', modalType.item.notificationname);
      setValue('appointmentType', modalType.item.appointment_type);
      setValue('email', modalType.item?.notification_type?.includes("email") || false);
      setValue('text', modalType.item?.notification_type?.includes("text") || false);
      fetchAppointmentCategoryListOnEdit();
    }
  }, [modalType]);
  useEffect(() => {
    if (modalType && modalType.emailTemplateList && modalType.item === null && showEmailTemplate) {
      setValue("emailTemplate", "");
      if (showEmailTemplate) {
        setValue('subject', clinic.name);
        setValue('body', RichTextEditor.createValueFromString(clinic.name, 'html'));
      }
    }
    if (modalType && modalType.emailTemplateList && modalType.item && showEmailTemplate) setValue('emailTemplate', modalType.item.emailtemplate_id);
  }, [modalType]);
  const onLogoChange = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      setError('logo', { type: 'filerequired', message: 'Logo is required.' });
      setValue('logo', null);
    } else {
      if (file.type === "image/png" ||
        file.type === 'image/jpg' ||
        file.type == 'image/jpeg') {
        if (file.size > 512000) {
          setError('logo', {
            type: 'logosize', message: 'Maximum file size should be 512kb'
          });
          setValue('logo', null);
        } else {
          const files = event.target.files;
          uploadPhotos({ upload: event.target.files[0] }).then((success) => {
            if (success && success.error === false) {
              setValue('logoPreview', success.data);
              setValue('logo', files);
              setError('logo', {});
            }
          }).catch((err) => {
            setError('logo', {
              type: 'logotype', message: 'Image upload failed.'
            });
            setValue('logoPreview', '');
            setValue('logo', null);
          });
        }
      } else {
        setError('logo', {
          type: 'logotype', message: 'Logo extension is not valid, allowed png,jpg,jpeg'
        });
        setValue('logo', null);
      }
    }
  };
  const onBannerChange = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      setError('banner', { type: 'filerequired', message: 'Logo is required.' });
      setValue('banner', null);
    } else {
      if (file.type === "image/png" ||
        file.type === 'image/jpg' ||
        file.type == 'image/jpeg') {
        if (file.size > 512000) {
          setError('banner', {
            type: 'bannersize', message: 'Maximum file size should be 512kb'
          });
          setValue('banner', null);
        } else {
          const files = event.target.files;
          uploadPhotos({ upload: event.target.files[0] }).then((success) => {
            if (success && success.error === false) {
              setValue('bannerPreview', success.data);
              setValue('banner', files);
              setError('banner', {});
            }
          }).catch((err) => {
            setError('banner', {
              type: 'bannertype', message: 'Image upload failed.'
            });
            setValue('bannerPreview', '');
            setValue('banner', null);
          });
        }
      } else {
        setError('banner', {
          type: 'bannertype', message: 'Banner extension is not valid, allowed png,jpg,jpeg'
        });
        setValue('banner', null);
      }
    }
  }

  const fetchAppointmentCategoryListOnEdit = () => {
    getAppointmentCategoryList({
      clinicid: clinic.id,
    }).then((success) => {
      if (success && success.error === false) {
        setAppointmentCategories(success.data);
        if (modalType && modalType.item) {
          let procedure_code_categoryTemp: any = '';
          // Appointment category
          if (modalType.item.procedure_category === 'all') {
            setValue('appointmentcategorytype', 'all');
            let values: any = [];
            for (let i = 0; i < success.data.length; i++) {
              values.push(success.data[i].procedure_code_category)
            }
            setValue('appointmentcategory', values);
            fetchProcedureCodeOnEdit('all', []);
          } else if (modalType.item.procedure_code === 'none') {
            setValue('appointmentcategorytype', 'none');
            setValue('appointmentcategory', []);
          } else {
            setValue('appointmentcategorytype', 'custom');
            if (modalType.item && modalType.item.procedure_category) {
              procedure_code_categoryTemp = modalType.item.procedure_category.split(",");
            } else {
              procedure_code_categoryTemp = [];
            }
            setValue('appointmentcategory', procedure_code_categoryTemp);
            fetchProcedureCodeOnEdit('custom', procedure_code_categoryTemp);
          }
        }
      }
    }).catch((err) => {
    })
  }

  const fetchProcedureCodeOnEdit = (type: any, categories?: any) => {
    getProcedureCodeList({
      clinicid: clinic.id,
      category: categories ? categories : watch('appointmentcategory'),
      type: type
    }).then((success) => {
      if (success && success.error === false) {
        if (success.data && success.data[0]) {
          setProcedureCodes(success.data[0]);
          if (modalType && modalType.item) {
            let procedure_codeTemp: any = '';
            // procedure code
            if (modalType.item.procedure_code === 'all') {
              setValue('procedurecodestype', 'all');
              let values: any = [];
              for (let i = 0; i < success.data[0].length; i++) {
                values.push(success.data[0][i].procedure_code_sikkaId)
              }
              setValue('procedurecodes', values);
            } else if (modalType.item.procedure_code === 'none') {
              setValue('procedurecodestype', 'none');
              setValue('procedurecodes', []);
            } else {
              setValue('procedurecodestype', 'custom');
              procedure_codeTemp = modalType.item.procedure_code.split(",");
              setValue('procedurecodes', procedure_codeTemp);
            }
          }
        }
      }
    }).catch((err) => {
    })
  }

  const fetchProcedureCode = () => {
    getProcedureCodeList({
      clinicid: clinic.id,
      category: watch('appointmentcategory'),
      type: watch('appointmentcategorytype')
    }).then((success) => {
      if (success && success.error === false) {
        if (success.data && success.data[0]) {
          setProcedureCodes(success.data[0]);
          if (watch('procedurecodestype') === 'all') {
            let values: any = [];
            for (let i = 0; i < success.data[0].length; i++) {
              values.push(success.data[0][i].procedure_code_sikkaId)
            }
            setValue('procedurecodes', values);
          }
        }
      }
    }).catch((err) => {
    })
  }

  const handleOnChnageAll = () => {
    if (watch('appointmentcategorytype') === 'all') {
      let values: any = [];
      for (let i = 0; i < appointmentCategories.length; i++) {
        values.push(appointmentCategories[i].procedure_code_category)
      }
      setValue('appointmentcategory', values);
      fetchProcedureCode();
    }
  }

  const handleOnChangeNone = () => {
    if (watch('appointmentcategorytype') === 'none') {
      setValue('appointmentcategory', []);
      setValue('procedurecodestype', 'none');
      setValue('procedurecodes', []);
      setProcedureCodes([]);
    }
  }

  const handleOnChangeCustom = () => {
    setValue('appointmentcategory', []);
  }

  useEffect(() => {
    let type = watch('procedurecodestype');
    if (type === 'all' && procedureCodes.length > 0) {
      let values: any = [];
      for (let i = 0; i < procedureCodes.length; i++) {
        values.push(procedureCodes[i].procedure_code_sikkaId)
      }
      setValue('procedurecodes', values);
    } else if (type === 'none') {
      setValue('procedurecodes', []);
    } else {
      setValue('procedurecodes', []);
    }
  }, [watch('procedurecodestype')]);

  const onChnageAppointmentCategory = () => {
    if (watch('appointmentcategory') && watch('appointmentcategory').length > 0) {
      fetchProcedureCode();
    }
  }

  watch('logo');
  watch('banner');
  watch('bannerPreview');
  watch('logoPreview');
  console.log(modalType);
  return (
    <Modal className="noti-popups-sec" show={show} onHide={hideModal}>
      {
        (modalType) &&
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="position-relative">
            <div className="modal-header">
              <h5 className="modal-title h4">{modalType.title}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hideModal}></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="mb-1">Notification name</label>
                <input className="form-control" aria-label="Default example" name="notificationName" placeholder="Notification name" ref={register({
                  required: true
                })} />
                {
                  errors.notificationName && errors.notificationName.type === "required" &&
                  <div className="is-invalid">Notification Name is Required</div>
                }
              </div>
              {
                modalType.isRepeated === true &&
                <div className="set-time-box mb-3">
                  <div className="mb-0"><span className="label-txt">Time interval config.</span><small className="text-muted">(Please select reminder interval)</small></div>
                  <div className="features-check-list align-items-start custom-check-list d-flex">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="day2" value="2" id="general-features-1"
                        defaultChecked={modalType.item ? modalType.item.scheduledays.includes("2") : false} ref={register} />
                      <label className="form-check-label rounded-2 border w-100" htmlFor="general-features-1">
                        2 days
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="day7" value="7" id="general-features-2" ref={register}
                        defaultChecked={modalType.item ? modalType.item.scheduledays.includes("7") : false}
                      />
                      <label className="form-check-label rounded-2 border w-100" htmlFor="general-features-2">
                        7 days
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="day15" value="15" id="general-features-3"
                        defaultChecked={modalType.item ? modalType.item.scheduledays.includes("15") : false}
                        ref={register} />
                      <label className="form-check-label rounded-2 border w-100" htmlFor="general-features-3">
                        15 days
                      </label>
                    </div>
                    <div className="flex-grow-1 mt-2">
                      {/* <label className="d-block mb-1">Custom Day</label> */}
                      <CreatableSelect
                        components={components}
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Custom Day"
                        value={value}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="form-select-label">Appointment state when to follow up</label>
                    <select className="form-select" name="appointmentType" ref={register}>
                      <option value="">Not applicable</option>
                      <option value="scheduled">Scheduled</option>
                      <option value="completed">Completed</option>
                    </select>
                  </div>

                </div>
              }
              <div className={"mb-3 " + (!showEmailTemplate ? "d-none" : "")}>
                <label className="mb-1">Email template</label>
                <select className="form-control" name="emailTemplate" placeholder="Email template" ref={register({ required: showEmailTemplate ? true : false })}>
                  <option value="">Select email template</option>
                  {
                    modalType?.emailTemplateList.map((item: any, index: number) => {
                      return (
                        <option key={item.messagecenteremailtemplate_id + "emailtemplate"}
                          value={item.messagecenteremailtemplate_id}>{item.templatename}</option>
                      )
                    })
                  }
                </select>
                {
                  errors && errors.emailTemplate && errors.emailTemplate.type === "required" &&
                  <div className="is-invalid">Email template is Required</div>
                }
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  <label htmlFor="category" className="form-label fw-bold">Appointment Category</label>
                  {/* <Select className="select2 myslt" name="appointmentcategory" options={appointmentCategories} isMulti
                        value={selectedAppointmentCategories}
                        onChange={(e: any) => { setSelectedAppointmentCategories(e) }}
                      ></Select> */}
                  <div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="appointmentcategorytype" id="allcategory" value="all" ref={register} onChange={() => { handleOnChnageAll() }} />
                      <label className="form-check-label" htmlFor="allcategory">All</label>
                    </div>
                    <div className="form-check form-check-inline d-none">
                      <input className="form-check-input" type="radio" name="appointmentcategorytype" id="nonecategory" value="none" ref={register} onChange={() => { handleOnChangeNone() }} />
                      <label className="form-check-label" htmlFor="nonecategory">None</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="appointmentcategorytype" id="customcategory" value="custom" ref={register} onChange={() => { handleOnChangeCustom() }} />
                      <label className="form-check-label" htmlFor="customcategory">Custom</label>
                    </div>
                  </div>
                  <select disabled={watch('appointmentcategorytype') === 'all' || watch('appointmentcategorytype') === 'none'} multiple={true} className="form-control" aria-label="Select Appointment Category" id="appointmentcategory" name="appointmentcategory" ref={register()}
                    onChange={() => { onChnageAppointmentCategory() }}>
                    {
                      (appointmentCategories && appointmentCategories.length > 0) &&
                      appointmentCategories.map((category: any) => {
                        return (
                          <option key={category.procedure_code_category} value={category.procedure_code_category}>{category.procedure_code_category}</option>
                        )
                      })
                    }
                  </select>
                </div>
                <div className="col-6">
                  <label htmlFor="category" className="form-label fw-bold">Procedure Codes</label>
                  {/* <Select className="select2 myslt" name="procedurecodes" options={procedureCodes} isMulti
                        value={selectedProcedurecodes}
                        onChange={(e: any) => { setSelectedProcedurecodes(e) }}
                      ></Select> */}
                  <div>
                    <div className="form-check form-check-inline">
                      <input disabled={watch('appointmentcategorytype') === 'none'} className="form-check-input" type="radio" name="procedurecodestype" id="allcodetype" value="all" ref={register} />
                      <label className="form-check-label" htmlFor="allcodetype">All</label>
                    </div>
                    <div className="form-check form-check-inline d-none">
                      <input className="form-check-input" type="radio" name="procedurecodestype" id="nonecodetype" value="none" ref={register} />
                      <label className="form-check-label" htmlFor="nonecodetype">None</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input disabled={watch('appointmentcategorytype') === 'none'} className="form-check-input" type="radio" name="procedurecodestype" id="customcodetype" value="custom" ref={register} />
                      <label className="form-check-label" htmlFor="customcodetype">Custom</label>
                    </div>
                  </div>
                  <select disabled={watch('appointmentcategorytype') === 'none' || watch('procedurecodestype') === 'all' || watch('procedurecodestype') === 'none'} multiple={true} className="form-control" aria-label="Select Procedure Codes" id="procedurecodes" name="procedurecodes" ref={register()}>
                    {
                      (procedureCodes && procedureCodes.length > 0) &&
                      procedureCodes.map((code: any) => {
                        return (
                          <option key={code.procedure_code_sikkaId} value={code.procedure_code_sikkaId}>{code.procedure_code_sikkaId}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
              <div className="row mb-4 d-none">
                <div className="col-6">
                  <label htmlFor="category" className="form-label fw-bold">Appointment Status</label>
                  <select className="form-select" aria-label="Select Appointment Status" id="appointmentstatus" name="appointmentstatus" ref={register()}>
                    <option value="scheduled">Scheduled</option>
                    <option value="completed">Completed</option>
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12">
                  <label htmlFor="exampleFormControlInput2" className="form-label fw-bold">Notification medium</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="checkbox" id="emailCheckbox" name="email" value="email" ref={register} />
                      <label className="form-check-label" htmlFor="emailCheckbox">Email</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="checkbox" id="textCheckbox" name="text" value="text" ref={register} />
                      <label className="form-check-label" htmlFor="textCheckbox">Text</label>
                    </div>
                  </div>
                  <div className="text-muted small mt-2 d-none"><img src={WarningIcon} className="me-2" />Disable is not configured, please configure email & text in selected template.</div>
                </div>
              </div>
              {
                <div className={"set-time-box mb-3 " + ((modalType.isRepeated === true ? 'd-none' : ''))}>
                  <div className="row miuif_margin">
                    <div className="col-6">
                      <label className="form-label w-100" htmlFor="general-features-1">
                        Appointments From :
                      </label>
                      <Controller
                        name="startDate"
                        control={control}
                        // rules={{ required: true }}
                        render={(props) => (
                          <KeyboardDatePicker
                            disableToolbar
                            // variant="inline"
                            margin="none"
                            format={"DD-MM-YYYY"}
                            id="date-picker-inline-startdate"
                            label=""
                            value={props.value}
                            onChange={(date: any) => {
                              props.onChange(date);
                              if (moment(date).isAfter(getValues('endDate'))) { setValue('endDate', date) }
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change start date',
                            }}
                          />
                        )}
                      ></Controller>
                      {
                        (errors && errors.startDate && errors.startDate.type === "required") &&
                        <div className="is-invalid">Date is required</div>
                      }
                      {/* <input className="form-control" type="date" name="startDate" id="general-features-1" ref={register} /> */}
                    </div>
                    <div className="col-6">
                      <label className="form-label w-100" htmlFor="general-features-2">
                        To :
                      </label>
                      <Controller
                        name="endDate"
                        control={control}
                        // rules={{ required: true }}
                        render={(props) => (
                          <KeyboardDatePicker
                            disableToolbar
                            //variant="inline"
                            margin="none"
                            format={"DD-MM-YYYY"}
                            id="date-picker-inline-enddate"
                            label=""
                            minDate={watch('startDate')}
                            value={props.value}
                            onChange={(date: any) => {
                              props.onChange(date);
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change end date',
                            }}
                          />
                        )}
                      ></Controller>
                      {
                        (errors && errors.endDate && errors.endDate.type === "required") &&
                        <div className="is-invalid">Date is required</div>
                      }
                      {/* <input className="form-control" type="date" name="endDate" id="general-features-2" ref={register} /> */}
                    </div>
                  </div>
                </div>
              }
              {
                // <div className={modalType.isRepeated !== true ? 'd-none' : ''}>
                <div className="d-none">
                  <Controller
                    name="time"
                    control={control}
                    render={(props) => (
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Time"
                        value={props.value}
                        onChange={(date: any) => {
                          props.onChange(date);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    )}
                  ></Controller>
                  {
                    (errors && errors.time && errors.time.type === "required") &&
                    <div className="is-invalid">Time is required</div>
                  }
                </div>
              }
              <div className="short-codes-box my-3">
                <div className="mb-3"><span className="fw-bold">Short codes </span>
                  <small className="text-muted">(click icon to copy code.)</small>
                </div>
                <div className="d-flex short-code-btns flex-wrap">
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip">
                        {"Use {patientname} in body to attach patient name."}
                      </Tooltip>
                    }
                  >
                    <CopyToClipboard text={'{patientname}'} onCopy={() => toast.success('patientname copied!')}>
                      <button className="btn p-0" type="button">Patient name<img src={ClipboardIconPopup} className="ms-2" /></button>
                    </CopyToClipboard>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip">
                        {"Use {appointment} in body to attach appointment detail."}
                      </Tooltip>
                    }
                  >
                    <CopyToClipboard text={'{appointment}'} onCopy={() => toast.success('appointment is copied!')}>
                      <button className="btn p-0" type="button">Apppointment<img src={ClipboardIconPopup} className="ms-2" /></button>
                    </CopyToClipboard>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip">
                        {"Use {providername} in body to attach provider name."}
                      </Tooltip>
                    }
                  >
                    <CopyToClipboard text={'{providername}'} onCopy={() => toast.success('providername copied!')}>
                      <button className="btn p-0" type="button">Provider name<img src={ClipboardIconPopup} className="ms-2" /></button>
                    </CopyToClipboard>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip">
                        {"Use {amount} in body to attach provider name."}
                      </Tooltip>
                    }
                  >
                    <CopyToClipboard text={'{amount}'} onCopy={() => toast.success('Amount copied!')}>
                      <button className="btn p-0" type="button">Amount<img src={ClipboardIconPopup} className="ms-2" /></button>
                    </CopyToClipboard>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip">
                        {"Use {clinicname} in body to attach provider name."}
                      </Tooltip>
                    }
                  >
                    <CopyToClipboard text={'{clinicname}'} onCopy={() => toast.success('Clinic name copied!')}>
                      <button className="btn p-0" type="button">Clinic name<img src={ClipboardIconPopup} className="ms-2" /></button>
                    </CopyToClipboard>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip">
                        {"Use {appointmentstarttime} in body to attach provider name."}
                      </Tooltip>
                    }
                  >
                    <CopyToClipboard text={'{appointmentstarttime}'} onCopy={() => toast.success('Appointment start time copied!')}>
                      <button className="btn p-0" type="button">Appointment start time<img src={ClipboardIconPopup} className="ms-2" /></button>
                    </CopyToClipboard>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip">
                        {"Use {appointmentdate} in body to attach provider name."}
                      </Tooltip>
                    }
                  >
                    <CopyToClipboard text={'{appointmentdate}'} onCopy={() => toast.success('Appointment date copied!')}>
                      <button className="btn p-0" type="button">Appointment date<img src={ClipboardIconPopup} className="ms-2" /></button>
                    </CopyToClipboard>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip">
                        {"Use {speciality} in body to attach speciality."}
                      </Tooltip>
                    }
                  >
                    <CopyToClipboard text={'{speciality}'} onCopy={() => toast.success('Speciality is copied!')}>
                      <button className="btn p-0" type="button">Speciality<img src={ClipboardIconPopup} className="ms-2" /></button>
                    </CopyToClipboard>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip">
                        {"Use {clinicsmsnumber} in body to attach speciality."}
                      </Tooltip>
                    }
                  >
                    <CopyToClipboard text={'{clinicsmsnumber}'} onCopy={() => toast.success('ClinicSMSNumber is copied!')}>
                      <button className="btn p-0" type="button">ClinicSMSNumber<img src={ClipboardIconPopup} className="ms-2" /></button>
                    </CopyToClipboard>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip">
                        {"Use {clinicadminemail} in body to attach speciality."}
                      </Tooltip>
                    }
                  >
                    <CopyToClipboard text={'{clinicadminemail}'} onCopy={() => toast.success('ClinicAdminEmail is copied!')}>
                      <button className="btn p-0" type="button">ClinicAdminEmail<img src={ClipboardIconPopup} className="ms-2" /></button>
                    </CopyToClipboard>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip">
                        {"Use {reviewlink} in body to attach review link."}
                      </Tooltip>
                    }
                  >
                    <CopyToClipboard text={'{reviewlink}'} onCopy={() => toast.success('ReviewLink is copied!')}>
                      <button className="btn p-0" type="button">Review Link<img src={ClipboardIconPopup} className="ms-2" /></button>
                    </CopyToClipboard>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="mt-4">
                {
                  showEmailTemplate &&
                  <div className={"my-3 "}>
                    <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">Subject line</label>
                    <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter email subject line" name="subject" ref={register({
                      required: true
                    })} />
                    {
                      errors.subject && errors.subject.type === "required" &&
                      <div className="is-invalid">Subject is required</div>
                    }
                  </div>
                }
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    {
                      !showEmailTemplate &&
                      <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Email</button>
                    }
                    <button className={"nav-link " + (showEmailTemplate ? "active" : "")} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Text SMS</button>
                    <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">App notification</button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div className={"tab-pane fade " + (!showEmailTemplate ? "show active" : "")} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div className="row">
                      <div className="col-6">
                        <div className="upload-box">
                          <label className="fw-bold form-label d-block" htmlFor="exampleFormControlUpload">Upload logo</label>
                          <div className="">
                            {/* <button className="btn btn-upload">Chose File</button> */}
                            {/* <span className="file-txt align-self-center">No File Chosen</span> */}
                            <input ref={register} className="btn btn-upload" type="file" name="logo" onChange={onLogoChange} />
                            <small className="text-muted mt-1 d-block">Max file size should be 500kb</small>
                            {
                              errors && errors.logo && errors.logo.type === "logosize" &&
                              <div className="is-invalid">{errors.logo.message}</div>
                            }
                            {
                              errors && errors.logo && errors.logo.type === "logotype" &&
                              <div className="is-invalid">{errors.logo.message}</div>
                            }
                            {
                              errors && errors.logo && errors.logo.type === "filerequired" &&
                              <div className="is-invalid">{errors.logo.message}</div>
                            }
                            {/* <div className="img-box"> */}
                            <input type="hidden" name="logoPreview" ref={register}></input>
                            {
                              (getValues('logo') && getValues('logo')[0] && getValues('logo')[0].name) ?
                                <>
                                  <div className="img-box">
                                    <img id="logo" src={URL.createObjectURL(getValues('logo')[0])} /></div>
                                </>
                                :
                                (getValues('logoPreview') && getValues('logoPreview') !== '') &&
                                <>
                                  {
                                    (getValues('logoPreview')) &&
                                    <>
                                      <div className="img-box">
                                        <img id="logo" src={getValues('logoPreview')} />
                                      </div>
                                    </>
                                  }
                                </>
                            }
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="upload-box">
                          <label className="fw-bold form-label d-block" htmlFor="exampleFormControlFile1">Banner image</label>
                          <div className="">
                            {/* <button className="btn btn-upload">Chose File</button>
                                <span className="file-txt align-self-center">No File Chosen</span> */}
                            <input ref={register} className="btn btn-upload" type="file" name="banner" onChange={onBannerChange} />

                            <small className="text-muted mt-1 d-block">Max file size should be 500kb</small>
                            {
                              errors && errors.banner && errors.banner.type === "bannersize" &&
                              <div className="is-invalid">{errors.banner.message}</div>
                            }
                            {
                              errors && errors.banner && errors.banner.type === "bannertype" &&
                              <div className="is-invalid">{errors.banner.message}</div>
                            }
                            {
                              errors && errors.banner && errors.banner.type === "filerequired" &&
                              <div className="is-invalid">{errors.logo.message}</div>
                            }
                            {/* <div className="img-box"> */}
                            <input type="hidden" name="bannerPreview" ref={register}></input>
                            {
                              (getValues('banner') && getValues('banner')[0] && getValues('banner')[0].name) ?
                                <> <div className="img-box"><img id="banner" src={URL.createObjectURL(getValues('banner')[0])} /></div></> :
                                (getValues('bannerPreview') && getValues('bannerPreview') !== '') &&
                                <>
                                  {
                                    (getValues('bannerPreview')) &&
                                    <> <div className="img-box"><img id="banner" src={getValues('bannerPreview')} /></div></>
                                  }
                                </>
                            }
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      !showEmailTemplate &&
                      <div className="my-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">Subject line</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter email subject line" name="subject" ref={register({
                          required: showEmailTemplate ? true : false
                        })} />
                        {
                          errors.subject && errors.subject.type === "required" &&
                          <div className="is-invalid">subject is required</div>
                        }
                      </div>
                    }
                    <div className="my-3">
                      <label className="form-label fw-bold">Content</label>
                      <div className="editor-box form-control">
                        <Controller
                          name="body"
                          control={control}
                          render={(props) => {
                            return (
                              <RichTextEditor value={props.value ? props.value : RichTextEditor.createValueFromString('', 'html')}
                                onChange={(value) => props.onChange(value)}
                              ></RichTextEditor>
                            )
                          }}
                        ></Controller>
                        {/* <textarea id="PracticeDescription" rows={3} name="body">When you need family dental care, you want a dentist that provides you with comfortable and convenient services in a relaxing atmosphere.</textarea> */}
                      </div>

                    </div>
                    {/*  <div className="my-3">
                          <div className="d-flex form-check form-switch justify-content-between border p-3 rounded-10">
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Social media links</label>
                            <input className="form-check-input m-0 align-self-center" type="checkbox" id="flexSwitchCheckDefault" />
                          </div>
                        </div> */}
                  </div>
                  <div className={"tab-pane fade " + (showEmailTemplate ? "active show" : "")} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <div>
                      <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bold">Text message content</label>
                      <textarea className="form-control mb-1" id="exampleFormControlTextarea1" rows={3} name="textMsgInput" ref={register({
                        required: true
                      })}></textarea>
                      <small className="text-muted">The character limit for a single SMS message is 160 characters</small>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                    <div>
                      <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bold">App notification content</label>
                      <textarea className="form-control mb-1" id="exampleFormControlTextarea1" rows={3} name="appNotificationInput" ref={register({
                        required: true
                      })}></textarea>
                      <small className="text-muted">The character limit for a single app notification is 250 characters</small>
                    </div>
                  </div>
                </div>
              </div>
              {
                errors.textMsgInput && errors.textMsgInput.type === "required" &&
                <div className="is-invalid">Text Message is Required</div>
              }
              {
                errors.appNotificationInput && errors.appNotificationInput.type === "required" &&
                <div className="is-invalid">App Notification isRequired</div>
              }
            </div>
            <div className="modal-footer">
              <button type="button" disabled={isLoading} className="btn btn-border" data-bs-dismiss="modal" onClick={hideModal}>Cancel</button>
              <button disabled={isLoading} type="submit" className="btn btn-primary">
                {
                  isLoading &&
                  <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                }
                {
                  modalType.item && 'Save'
                }
                {
                  !modalType.item && 'Submit'
                }
              </button>
            </div>
          </div>
        </form>
      }
    </Modal>
  )
});