const initialState:any = {
   data:[]
}

const storeQuickChatReducer=(state=initialState,action:any):any=>{
    if(action.type==='QUICK_CHAT_DATA'){
        return {
            ...state,
            data:action.payload
        }
    }
    return state;

}

export default storeQuickChatReducer;