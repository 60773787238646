import React, { Component } from 'react';
import { Elements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import apiConfigs from '../../configs/apiConfigs';
import { BillingState, AddCardRequest, BillingRequest, BillingResponse, StripeCard, RemoveCardRequest } from '../../store/billing/types';
import { addCardPendingAction, fetchBillingPendingAction, removeCardPendingAction } from '../../store/billing/actions';
import { allowOnlyAlpha, allowOnlyDigit } from '../../utils/validators';
import { IAppState } from '../../store';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../store/loadingIndicator/actions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { attachHeader } from '../../utils/apiHandler';
import configs from '../../configs/apiConfigs';
import { UserDataResponse } from '../../store/userData/types';
import VisaImg from './images/visa-img.svg';
import ReportProblemIcon from './images/report_problem-icon.svg';
import SpinnerIcon from './images/spinner-icon.svg';

interface Props {
    loadingStart: typeof fetchLoadingPending;
    loadingEnd: typeof fetchLoadingSuccess;
    billingState: BillingState;
    addCard: typeof addCardPendingAction;
    fetchBilling: typeof fetchBillingPendingAction;
    removeCard: typeof removeCardPendingAction;
    userDataResponse: UserDataResponse;
    selectedCard(Id: any): void;
    selectedBillInfo(Id: any): void;
    billingList(billingList: any): void;

}
interface State {
    isValidCard: boolean;
    cards: any;
    isAddCard: boolean;
    cardNumberValidation: { isInvalid: boolean, msg: string };
    stripeReady: boolean;
    cardHolderName: string;
    cardHolderNameValidation: { isInvalid: boolean, msg: string };
    cardExpiryValidation: { isInvalid: boolean, msg: string };
    cardCvvValidation: { isInvalid: boolean, msg: string };
    cardError: { isInvalid: boolean, msg: string };
    billingResponse?: BillingResponse;
    selectedCard: any;
    billingList: any;
    id: any;
    name: any;
    address1: any;
    address2: any;
    city: any;
    zipCode: any;
    state: any;
    country: any;
    selectedBillInfo: boolean;
    isEdit: boolean;
}
const stripePromise = loadStripe(apiConfigs.STRIPE_PUBLIC_KEY);
class CardInfo extends Component<Props, State>{
    cardNumberElement: any;
    constructor(props: any) {
        super(props);
        this.state = {
            isValidCard: true,
            cards: [],
            isAddCard: false,
            cardNumberValidation: { isInvalid: false, msg: '' },
            stripeReady: false,
            cardHolderName: '',
            cardHolderNameValidation: { isInvalid: false, msg: '' },
            cardExpiryValidation: { isInvalid: false, msg: '' },
            cardCvvValidation: { isInvalid: false, msg: '' },
            cardError: { isInvalid: false, msg: '' },
            selectedCard: '', billingList: [], id: '', isEdit: false,
            name: '', address1: '', address2: '', city: '', zipCode: '', state: '', country: '', selectedBillInfo: false,
        }
    }
    componentDidMount() {
        this.props.loadingStart();
        this.props.fetchBilling({});
        this.fetchBillingInfoList();
    }
    fetchBillingInfoList = () => {
        this.props.loadingStart();
        const url = configs.API_URL + 'prime/clinic' + '/' + this.props.userDataResponse.clinics[0].uniqueId + '/billing-subsciption/';
        Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then((success) => {
            if (success.data && success.data.billingData.length > 0) {
                this.setState({ billingList: success.data.billingData });
                this.props.billingList(success.data.billingData);
                this.props.loadingEnd(false);
            }
        }).catch((error: any) => {
            this.props.loadingEnd(false);
            return ''
        });
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (this.props !== nextProps) {
            if (this.props.billingState !== nextProps.billingState) {
                if (nextProps.billingState.error !== null) {
                    this.props.loadingEnd(false);
                    if (nextProps.billingState.billingResponse && nextProps.billingState.billingResponse.status
                        && nextProps.billingState.billingResponse.status.error === true) {
                        toast.error(nextProps.billingState.billingResponse.status.msg);
                        this.props.loadingEnd(false);
                    } else if (nextProps.billingState.addCardResponse && nextProps.billingState.addCardResponse.status
                        && nextProps.billingState.addCardResponse.status.error === true) {
                        toast.error(nextProps.billingState.addCardResponse.status.msg);
                    } else if (nextProps.billingState.removeCardResponse && nextProps.billingState.removeCardResponse.status
                        && nextProps.billingState.removeCardResponse.status.error === true) {
                        toast.error(nextProps.billingState.removeCardResponse.status.msg);
                    } else {
                        toast.error('Something went wrong');
                        this.props.loadingEnd(false);
                    }
                } else {
                    if (nextProps.billingState.pending === false && nextProps.billingState.billingResponse
                        && nextProps.billingState.billingResponse.status && nextProps.billingState.billingResponse.status.error === false) {
                        // fetch billing success
                        this.setState({
                            billingResponse: nextProps.billingState.billingResponse
                        });
                        this.props.loadingEnd(false);
                    }
                    if (nextProps.billingState.pending === false && nextProps.billingState.addCardResponse &&
                        nextProps.billingState.addCardResponse.status && nextProps.billingState.addCardResponse.status.error === false) {
                        this.props.loadingEnd(false);
                        this.setState({
                            cardHolderName: '',
                            cardNumberValidation: { isInvalid: false, msg: '' },
                            cardCvvValidation: { isInvalid: false, msg: '' },
                            cardHolderNameValidation: { isInvalid: false, msg: '' },
                            cardExpiryValidation: { isInvalid: false, msg: '' },
                            cardError: { isInvalid: false, msg: '' },
                            isAddCard: false
                        });
                        toast.success(nextProps.billingState.addCardResponse.status.msg ? nextProps.billingState.addCardResponse.status.msg : 'Successfully added.');
                    }
                    if (nextProps.billingState.pending === false && nextProps.billingState.removeCardResponse &&
                        nextProps.billingState.removeCardResponse.status && nextProps.billingState.removeCardResponse.status.error === false) {
                        toast.success(nextProps.billingState.removeCardResponse.status.msg ? nextProps.billingState.removeCardResponse.status.msg : 'Successfully added.');
                    }
                }
            }
        }
        return true;
    }
    createStripeToken = () => {
        try {
            this.props.loadingStart();
            stripePromise.then((success: Stripe | null) => {
                if (success) {
                    const stripe = success;
                    stripe.createToken(this.cardNumberElement, { name: this.state.cardHolderName }).then((success) => {
                        if (success.error && (success.error.code === "incomplete_number" || success.error.code === "invalid_number")) {
                            this.setState({
                                cardNumberValidation: { isInvalid: true, msg: success.error.message ? success.error.message : '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            this.props.loadingEnd(false);
                        } else if (this.state.cardHolderName === "") {
                            this.setState({
                                cardHolderNameValidation: { isInvalid: true, msg: 'Please enter card holder name.' },
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            this.props.loadingEnd(false);
                            return;
                        } else if (success.error && (success.error.code === "incomplete_expiry" ||
                            success.error.code === "invalid_expiry_year_past" ||
                            success.error.code === "invalid_expiry_year")) {
                            this.setState({
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: true, msg: success.error.message ? success.error.message : '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            this.props.loadingEnd(false);
                        } else if (success.error && (success.error.code === "incomplete_cvc")) {
                            this.setState({
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: true, msg: success.error.message ? success.error.message : '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            this.props.loadingEnd(false);
                        } else if (success.error) {
                            this.setState({
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: true, msg: success.error.message ? success.error.message : '' }
                            });
                            this.props.loadingEnd(false);
                        } else if (success.token) {
                            this.setState({
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            const formData = new FormData();
                            formData.append('stripeToken', success.token.id);
                            const url = configs.API_URL + '/user/card/';
                            Axios.post(url, formData, { headers: attachHeader(true), withCredentials: true }).then((success) => {
                                if (success && success.data.status && success.data.status.msg) {
                                    toast.success(success.data.status.msg);
                                    this.setState({
                                        cardHolderName: '',
                                        cardNumberValidation: { isInvalid: false, msg: '' },
                                        cardCvvValidation: { isInvalid: false, msg: '' },
                                        cardHolderNameValidation: { isInvalid: false, msg: '' },
                                        cardExpiryValidation: { isInvalid: false, msg: '' },
                                        cardError: { isInvalid: false, msg: '' },
                                        isAddCard: false
                                    });
                                    this.props.fetchBilling({});
                                }
                            }).catch((error: any) => {
                                this.props.loadingEnd(false);
                                return ''
                            });

                        }
                    });
                }
            }, (error) => {
                this.props.loadingEnd(false);

            });
        } catch (err) {
            this.props.loadingEnd(false);
        }
    }
    cardNumberRef = (element: any) => {
        if (element) {
            this.cardNumberElement = element;
            this.setState({ stripeReady: true });
        }
    };
    removeCard = (card: StripeCard) => {
        this.props.loadingStart();
        const url = configs.API_URL + '/user/card/remove/' + card.id + '/';
        Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then((success) => {
            if (success && success.data && success.data.status.msg) {
                toast.success(success.data.status.msg);
                if (this.state.selectedCard === card.id) {
                    this.props.selectedCard('');
                }
                this.props.fetchBilling({});
            }
        }).catch((error: any) => {
            this.props.loadingEnd(false);
            return ''
        });
    }
    validationForm = () => {
        if (this.state.name === '') {
            toast.warn('Please enter name');
            return;
        }
        if (this.state.address1 === '') {
            toast.warn('Please enter AddressLine1');
            return;
        }
        if (this.state.city === '') {
            toast.warn('Please enter City');
            return;
        }
        if (this.state.zipCode === '') {
            toast.warn('Please enter ZipCode');
            return;
        }
        if (this.state.state === '') {
            toast.warn('Please enter State');
            return;
        }
        if (this.state.country === '') {
            toast.warn('Please enter Country');
            return;
        }
    }
    createBillInfo = () => {
        this.validationForm();
        this.props.loadingStart();
        let data = {
            "name": this.state.name,
            "addressLine1": this.state.address1,
            "addressLine2": this.state.address2 ? this.state.address2 : '',
            "city": this.state.city,
            "zipCode": this.state.zipCode,
            "state": this.state.state,
            "country": this.state.country,
            "default": this.state.selectedBillInfo
        }
        const url = configs.API_URL + 'prime/clinic' + '/' + this.props.userDataResponse.clinics[0].uniqueId + '/create-billing-subsciption/';
        Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then((success) => {
            if (success && success.data && success.data.status && success.data.status.msg) {
                toast.success(success.data.status.msg);
                this.fetchBillingInfoList()
                // this.props.loadingEnd(false);
                this.setState({ name: '', address1: '', address2: '', city: '', zipCode: '', state: '', country: '', selectedBillInfo: false });

            }
        }).catch((error: any) => {
            this.props.loadingEnd(false);
            return ''
        });
    }
    clickEditButton = (value: any) => {
        this.setState({
            isEdit: true, id: value.id, name: value.name, address1: value.addressLine1,
            address2: value.addressLine2, city: value.city, state: value.state, zipCode: value.zipCode,
            country: value.country, selectedBillInfo: value.default_status === "active" ? true : false
        });
    }
    updateBillInfo = () => {
        this.validationForm();
        this.props.loadingStart();
        let data = {
            "id": this.state.id,
            "name": this.state.name,
            "addressLine1": this.state.address1,
            "addressLine2": this.state.address2 ? this.state.address2 : '',
            "city": this.state.city,
            "zipCode": this.state.zipCode,
            "state": this.state.state,
            "country": this.state.country,
            "default": this.state.selectedBillInfo
        }
        const url = configs.API_URL + 'prime/clinic' + '/' + this.props.userDataResponse.clinics[0].uniqueId + '/update-billing-subsciption/';
        Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then((success) => {
            if (success && success.data && success.data.status && success.data.status.msg) {
                toast.success(success.data.status.msg);
                this.fetchBillingInfoList()
                this.setState({ isEdit: false, name: '', address1: '', address2: '', city: '', zipCode: '', state: '', country: '', selectedBillInfo: false });
            }
        }).catch((error: any) => {
            this.props.loadingEnd(false);
            return ''
        });
    }
    deleteBillInfo = (value: any) => {
        let data = {
            "billingAddressId": value.id,
        }
        const url = configs.API_URL + 'prime/clinic' + '/' + this.props.userDataResponse.clinics[0].uniqueId + '/delete-billing-subsciption/';
        Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then((success) => {
            if (success && success.data && success.data.status && success.data.status.msg) {
                toast.success(success.data.status.msg);
                this.fetchBillingInfoList();
                this.setState({ isEdit: false, name: '', address1: '', address2: '', city: '', zipCode: '', state: '', country: '', selectedBillInfo: false });
            }
        }).catch((error: any) => {
            this.props.loadingEnd(false);
            return ''
        });
    }
    billInfoChangeStatus = (value: any) => {

        this.props.loadingStart();
        let data = {
            "billingAddressId": value.id,
        }
        const url = configs.API_URL + 'prime/clinic' + '/' + this.props.userDataResponse.clinics[0].uniqueId + '/change-status-subsciption/';
        Axios.post(url, data, { headers: attachHeader(true), withCredentials: true }).then((success) => {
            if (success && success.data && success.data.status && success.data.status.msg) {
                toast.success(success.data.status.msg);
                this.fetchBillingInfoList();
            }
        }).catch((error: any) => {
            this.props.loadingEnd(false);
            return ''
        });
    }
    selectCard = (Id: any) => {
        this.props.selectedCard(Id);
        this.setState({ selectedCard: Id });
    }
    selectBillInfo = (Id: any) => {
        this.props.selectedBillInfo(Id);
    }
    render() {
        return (
            <>
                <div className="col-xl-8 col-xxl-9">
                    {
                        <>
                            {(!this.state.isValidCard) &&
                                <div className="my-4 border px-4 py-3 border-1 rounded d-flex bg-danger error-box justify-content-between">
                                    <div className="me-4">
                                        Selected card is not valid please add new card
                                    </div>
                                    <img src="./images/error-icon.svg" className="align-self-center" />
                                </div>
                            }
                            {(this.state.billingResponse && this.state.billingResponse.stripeCards.length > 0) &&
                                this.state.billingResponse.stripeCards.map((item: any) => {
                                    if (item.status === "Active") {
                                        return (
                                            <div key={item.id} className={item.id === this.state.selectedCard ? "default-card border-1 saved-card rounded d-flex p-4 justify-content-between border-1 border" : "default-card border-1 rounded d-flex p-4 justify-content-between border-1 border"}>
                                                <div className="data-box">
                                                    <div className="card-no text-muted"><img src={VisaImg} className="me-3" />
                                                    **** **** **** {item.last4}
                                                    </div>
                                                    <div className="d-flex mt-4">
                                                        <div className="holder-name me-5">
                                                            <small className="text-muted">Card holder name</small>
                                                            <div>{item.name}</div>
                                                        </div>
                                                        <div className="ex-date">
                                                            <small className="text-muted">Expiration date </small>
                                                            <div>{item.expMonth}/{item.expYear}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btn-box text-center align-self-center">
                                                    <button onClick={() => { this.selectCard(item.id) }} className="btn btn-primary py-0 d-block rounded-pill my-3 border-1 border px-4">Use this card</button>
                                                    <button onClick={() => { this.removeCard(item) }} className="btn btn-light py-0 d-block rounded-pill my-3 border-1 border mx-auto px-4">Delete</button>
                                                </div>
                                            </div>
                                        )
                                    }
                                })

                            }
                            {(this.state.billingResponse && this.state.billingResponse.stripeCards.length > 0) &&
                                <div className="text-end">
                                    <button onClick={() => { this.setState({ isAddCard: true }) }} className="btn bnt-link text-primary mt-3">+ New card</button>
                                </div>
                            }

                        </>
                    }
                    {(this.state.billingResponse && this.state.billingResponse.stripeCards.length <= 0) &&
                        <div className="my-4 border px-4 py-3 border-1 rounded d-flex warning-box">
                            <img src={ReportProblemIcon} className="align-self-center" />
                            <div className="ms-4">
                                <div>You don't have any saved credit card, please add.</div>
                                <small>Your credit card details are encrypted</small>
                            </div>
                        </div>
                    }
                    {(this.state.isAddCard) &&
                        <Elements stripe={stripePromise}>
                            <div className="default-card card my-4 form-card">
                                <h5 className="card-header fs-5 fw-bolder p-4">Credit card information</h5>
                                <div className="card-body">
                                    {/* <form className="needs-validation px-3"> */}
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <label className="form-label">Card number</label>
                                            <div className="input-group has-validation">
                                                <span className="input-group-text bg-white pe-0"><img src={VisaImg} /></span>
                                                <CardNumberElement className={"form-control  border-start-0"}
                                                    options={{ showIcon: true, placeholder: "1234 1234 1234 1234" }}
                                                    onReady={this.cardNumberRef}></CardNumberElement>
                                                {
                                                    (this.state.cardNumberValidation.isInvalid) &&
                                                    <label className="text-danger">{this.state.cardNumberValidation.msg}</label>
                                                }
                                                {/* <input type="text" className="form-control border-start-0" placeholder="4242 4242 4242 4242" required />  */}
                                                <div className="invalid-feedback">
                                                    Required.
                                                    </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <label className="form-label">Name on card</label>
                                            <input type="text" className="form-control" placeholder="Rakesh desai"
                                                onInput={(e: any) => {
                                                    if (allowOnlyAlpha(e.target.value)) {
                                                        this.setState({ cardHolderName: e.target.value });
                                                    } else if (e.target.value === "") {
                                                        this.setState({ cardHolderName: "" });
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    if (allowOnlyAlpha(e.target.value)) {
                                                        this.setState({ cardHolderName: e.target.value });
                                                    } else if (e.target.value === "") {
                                                        this.setState({ cardHolderName: "" });
                                                    }
                                                }} value={this.state.cardHolderName} required />
                                            <div className="invalid-feedback">
                                                Required.
                                                </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="cc-expiration" className="form-label">Expiration date (mm/yy)</label>
                                            <CardExpiryElement className="form-control"></CardExpiryElement>
                                            {/* <input type="text" className="form-control" id="cc-expiration" placeholder="" required /> */}
                                            <div className="invalid-feedback">
                                                Expiration date required
                                                </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="cc-expiration" className="form-label">CVV</label>
                                            <CardCvcElement options={{ placeholder: "CVV" }} className="form-control"></CardCvcElement>
                                            {/* <input type="text" className="form-control" id="cc-expiration" placeholder="" required /> */}
                                            <div className="invalid-feedback">
                                                CVV is required
                                                </div>
                                        </div>
                                        <div className="col-12">
                                            <button disabled={!this.state.stripeReady || !this.state.isAddCard} onClick={(e) => {
                                                this.createStripeToken();
                                            }} className="btn btn-secondary d-block my-3 px-3">Save and continue <img src={SpinnerIcon} className="btn-img" /></button>
                                            <small className="text-muted"><i>XCare doesn't store your payment card information. We are use stripe as our payment processor.</i></small>
                                        </div>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </Elements>
                    }
                    {(this.state.billingList && this.state.billingList.length > 0) &&
                        this.state.billingList.map((item: any) => {
                            return (
                                <div key={item.id}
                                    className={item.default_status === "active" ? "default-card save-address border-1 rounded d-flex p-4 justify-content-between border-1 border" : "default-card border-1 rounded d-flex p-4 justify-content-between border-1 border"}
                                >
                                    <div className="data-box">
                                        <div className="">
                                            <div>{item.name}</div>
                                            <small>{item.addressLine1 + ' ' + item.addressLine2}</small>
                                        </div>
                                        <div className="mt-4">
                                            <input type="checkbox" className="form-check-input" id="saveBill" checked={item.default_status === "active" ? true : false} onClick={() => { this.billInfoChangeStatus(item) }} />
                                            <label className="form-check-label ms-2" htmlFor="saveBill">Use this billing information</label>
                                        </div>
                                    </div>
                                    <div className="btn-box text-center align-self-center">
                                        <button onClick={() => { this.clickEditButton(item) }} className="btn btn-light py-0 d-block rounded-pill my-3 border-1 border">Edit</button>
                                        <button onClick={() => { this.deleteBillInfo(item) }} className="btn btn-light py-0 d-block rounded-pill my-3 border-1 border">Delete</button>
                                    </div>
                                </div>
                            )
                        })

                    }
                    <div className="default-card card my-4 form-card">
                        <h5 className="card-header fs-5 fw-bolder p-4">Billing information</h5>
                        <div className="card-body">
                            {/* <form className="needs-validation mx-3"> */}
                            <div className="row g-3">
                                <div className="col-12">
                                    <label className="form-label">Name</label>
                                    <input type="text" className="form-control" placeholder="Name" value={this.state.name}
                                        onChange={(e) => {
                                            if (allowOnlyAlpha(e.target.value)) {
                                                this.setState({ name: e.target.value })
                                            } else if (e.target.value === "") {
                                                this.setState({ name: "" })
                                            }
                                        }} />
                                    <div className="invalid-feedback">
                                        Required.
                                        </div>
                                </div>
                                <div className="col-12">
                                    <label className="form-label">Address line 1</label>
                                    <input type="text" className="form-control" placeholder="AddressLine1" value={this.state.address1} onChange={(e) => { this.setState({ address1: e.target.value }) }} />
                                    <div className="invalid-feedback">
                                        Required.
                                        </div>
                                </div>
                                <div className="col-12">
                                    <label className="form-label">Address line 2 (optional)</label>
                                    <input type="text" className="form-control" value={this.state.address2} placeholder="" onChange={(e) => { this.setState({ address2: e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">City</label>
                                    <input type="text" className="form-control" value={this.state.city} placeholder="" onChange={(e) => { this.setState({ city: e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Zip code</label>
                                    <input maxLength={6} type="text" className="form-control" value={this.state.zipCode} placeholder=""
                                        onChange={(e) => {
                                            if (allowOnlyDigit(e.target.value)) {
                                                this.setState({ zipCode: e.target.value })
                                            } else if (e.target.value === "") {
                                                this.setState({ zipCode: "" })
                                            }

                                        }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">State</label>
                                    <input type="text" className="form-control" value={this.state.state} placeholder="" onChange={(e) => { this.setState({ state: e.target.value }) }} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">Country</label>
                                    <input type="text" className="form-control" value={this.state.country} placeholder="" onChange={(e) => { this.setState({ country: e.target.value }) }} />
                                </div>
                                <div className="col-12">
                                    <input type="checkbox" className="form-check-input" id="saveBill" checked={this.state.selectedBillInfo === true} onChange={(e) => { this.setState({ selectedBillInfo: !this.state.selectedBillInfo }) }} />
                                    <label className="form-check-label ms-2" htmlFor="saveBill">Save this as a default billing address </label>
                                </div>
                                <div className="col-12">
                                    <button onClick={() => { this.state.isEdit ? this.updateBillInfo() : this.createBillInfo() }} className="btn btn-secondary d-block my-3 px-3">Save and continue <img src={SpinnerIcon} className="btn-img" /></button>
                                </div>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    billingState: state.billing,
    subscriptionState: state.subscriptionPlan,
    userDataResponse: state.userData.userDataResponse,
});
const mapDispatchToProps = (dispatch: any) => ({
    loadingStart: () => {
        dispatch(fetchLoadingPending());
    },
    loadingEnd: (status: any) => {
        dispatch(fetchLoadingSuccess(status));
    },
    addCard: (request: AddCardRequest) => {
        dispatch(addCardPendingAction(request));
    },
    fetchBilling: (request: BillingRequest) => {
        dispatch(fetchBillingPendingAction(request));
    },
    removeCard: (request: RemoveCardRequest) => {
        dispatch(removeCardPendingAction(request));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(CardInfo);