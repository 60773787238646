import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import { getSettings, saveTax } from '../../services/payment';
export const Tax = ({ }) => {
    const [tax, setTax] = useState(false);
    const [settings, setSettings]: any = useState(null);
    const [isLoading, setIsLoading]: any = useState(false);
    const userdata = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    useEffect(() => {
        getSettingsFunc();
    }, [userdata]);
    const getSettingsFunc = () => {
        setIsLoading(true);
        getSettings({ type: 'clinic', uniqueId: userdata.clinics[0].uniqueId }).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                console.log("Response", success);
                setSettings(success);
                if (success.metadata && success.metadata.isTaxable) {
                    setTax(true);
                } else {
                    setTax(false);
                }
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }
    const handleChangeTax = (e: any) => {
        setIsLoading(true);
        saveTax({
            uniqueId: userdata.clinics[0].uniqueId, requestData: {
                ...settings.metadata,
                isTaxable: !tax
            }
        }).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                toast.success(success.status.msg);
                getSettingsFunc();
            }
        }).catch((err) => {
            setIsLoading(false);
            toast.error(err.msg);
        })
    }
    return (
        <div className="card bg-light rounded my-4 p-4">
            <div className="d-flex justify-content-between">
                <div>
                    <div className="fw-medium  mb-1">TAX</div>
                    <small className="text-muted"><i className="bi bi-info-circle me-1"></i>You can select tax enable invoice creation</small>
                </div>
                <div className="form-check form-switch">
                    {
                        !isLoading &&
                        <input className="form-check-input m-0" type="checkbox" id="flexSwitchCheckDefault" checked={tax} onChange={handleChangeTax} />
                    }
                    {
                        isLoading &&
                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    }
                </div>
            </div>
        </div>
    )
}